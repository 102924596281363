import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from 'styled-components';
import { Button, Row, Col, VideoModal } from "components/common";
import MainBanner from "assets/images/main-image.png";
import BgImg from 'assets/images/banner-bg.png';

export default function BannerSection() {
  const myUser = useSelector((state) => state.auth.user);
  const [isPlaying, setIsPlaying] = useState(false);

  const goTraining = () => {
    // history.push('/training/watchList/2', { params: data })
  };

  return (
    <Wrapper>
      <div className="banner-home">
        <Row gutter={[20, 40]}>
          <Col xs={24} lg={12}>
            <div className="banner-content">
              <div>
                <div className="banner-title">
                  Welcome back, {myUser?.first_name}👋
                </div>
                <div className="banner-description">
                  We built the industry leading back office system for our MDC family. <br/>
                  Learning all the features 100% is very important to success in MDC. <br/>
                  Please check our back office features.
                </div>
              </div>
              {/* <div className="banner-button">
                <Button onClick={() => setIsPlaying(true)}>Get Started</Button>
                {isPlaying && (
                  <VideoModal
                    onCancel={() => setIsPlaying(false)}
                    src="835947173"
                    isVimeo
                    width={900}
                  />
                )}
              </div> */}
            </div>
          </Col>
          <Col xs={24} lg={12}>
            <div className="banner-r">
              <img src={MainBanner} alt="mainBanner.png" />
            </div>
          </Col>
        </Row>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .banner-home {
    border-radius: 16px;
    padding: 50px;
    background-image: url("${BgImg}");
    .banner-r {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 100%;
      img {
        max-width: 310px;
      }
      @media (max-width: 450px) {
        img {
          width: 100%;
        }
      }
    }
    .banner-content {
      border-radius: 16px;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .banner-title {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: #003768;
        margin-bottom: 10px;
      }
      .banner-description {
        color: #006c9ca3;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
      }
      .banner-button {
        button {
          background: #1ea9ea;
          border-radius: 8px !important;
          margin-top: 23px;
          border: none !important;
        }
      }
    }
  }
  @media (max-width: 992px) {
    .banner-home {
      padding: 40px 24px !important;
    }
    .banner-r {
      justify-content: center !important;
    }
    .banner-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .banner-title {
      text-align: center;
    }
    .banner-description {
      text-align: center;
    }
  }
`;

import styled from "styled-components";

export const Wrapper  = styled.div`
  padding-bottom: 80px;
  .fraud-section {
    width: 100%;
    padding: 70px 20px;
    background-color: #ffffff;
  }
  .fraud-section .fraud-result-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .fraud-section .fraud-result-icon img {
    width: 70px;
  }
  .fraud-section .fraud-result-message {
    margin-top: 20px;
  }
  .fraud-section .fraud-result-message h5 {
    text-align: center;
    font-weight: 600;
    color: #555;
  }
  .fraud-section .fraud-result-message p {
    text-align: center;
    max-width: 600px;
    margin: 20px auto 0;
    font-size: 16px;
  }
  .fraud-section .fraud-verify-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }
  .fraud-section .fraud-verify-upload {
    width: 50%;
    max-width: 400px;
    height: 370px;
    padding: 30px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 15px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
  }
  .fraud-section .fraud-verify-upload h5 {
    font-weight: 600;
    color: #555;
  }
  .fraud-section .fraud-verify-permitted-icon {
    color: #52c41a;
  }
  .fraud-section .fraud-verify-upload label {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    width: 250px;
    margin-top: 30px;
  }
  .fraud-section .fraud-verify-upload ul {
    font-size: 16px;
    text-align: left;
    width: 250px;
    min-height: 120px;
    margin-top: 20px;
  }
  .fraud-section .fraud-verify-upload ul li {
    display: flex;
    align-items: flex-start;
    margin-bottom: 5px;
  }
  .fraud-section .fraud-verify-upload ul li .fraud-verify-permitted-icon {
    margin-right: 10px;
    margin-top: 5px;
  }
  .fraud-section .fraud-verify-upload .fraud-verify-upload-btn {
    width: 250px;
  }
  .fraud-section .fraud-verify-upload .fraud-verify-image {
    width: 250px;
    border: 1px solid #d9d9d9;
    padding: 5px;
    margin-bottom: 20px;
  }
  .fraud-section .fraud-verify-submit {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }
  .fraud-section .fraud-verify-submit button {
    height: 37px;
    font-size: 16px;
    padding: 0 30px;
  }
  @media only screen and (max-width: 767px) {
    .fraud-section .fraud-verify-section {
      flex-direction: column;
    }
    .fraud-section .fraud-verify-upload {
      width: 95%;
      margin: 0 0 20px;
    }
    .fraud-section .fraud-verify-upload label {
      width: auto;
    }
    .fraud-section .fraud-verify-upload ul {
      width: auto;
      padding-left: 10px;
    }
    .fraud-section .fraud-verify-upload h5 {
      text-align: center;
    }
    .fraud-section .fraud-verify-upload .fraud-verify-upload-btn {
      width: auto;
    }
  }
`
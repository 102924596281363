import React from 'react'
import styled from 'styled-components'
import TableView from './table/TableView'

export default function BinaryQualPage() {
  return (
    <Wrapper>
      <TableView />
    </Wrapper>
  )
}

const Wrapper = styled.div`
padding-bottom: 80px;
`
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  TablePanel, Checkbox, Button, 
  Popconfirm, SuccessNotification, message
} from 'components/common'
import { callGetApiWithAuth, callPostApiWithAuth } from 'utils/api'
import { asDateTime } from 'utils/text'
import NotificationDetailModal from "layouts/authed/topHeader/notifications/NotificationDetailModal";
import { useDispatch } from 'react-redux'

export default function NotificationPage() {
  const dispatch = useDispatch()
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [paginationParam, setPaginationParam] = useState({
    currentPage: 1,
    perPage: 10,
    total: 0
  })
  const [searchParam, setSearchParam] = useState({
  })
  const [isCheckedAll, setIsCheckedAll] = useState(false)
  const [readIds, setReadIds] = useState([])

  const onGetTableData = (res) => {
    setTableData(res.data.data)
    setIsLoading(false)
    setPaginationParam({
      ...paginationParam,
      currentPage: res.data.current_page,
      total: res.data.total,
    })
    setReadIds(res.data.read_ids)
    let unreadNotifications = [];
    res.data.data.map((el) => {
      if (res.data.read_ids.indexOf(`,${el.id},`) < 0) {
        unreadNotifications = [
          ...unreadNotifications,
          el
        ]
      }
    });
    dispatch({ 
      type: 'notification.SET_UNREAD_NOTIFICATIONS', 
      payload: { 
        unreadNotifications: unreadNotifications
      }
    });
  }
  const onFailTableData = () => {
    setIsLoading(false)
  }
  const loadTableData = (paginationParam0, searchParam0) => {
    const params = {
      ...searchParam0,
      page: paginationParam0.currentPage,
      per_page: paginationParam0.perPage
    }
    const q = Object.keys(params).map(k=>k+'='+params[k]).join('&')
    setIsLoading(true)
    callGetApiWithAuth(`notification/list?${q}`, onGetTableData, onFailTableData)
  }

  const handleSearch = () => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: 1
    }
    loadTableData(paginationParam0, searchParam)
  }

  const onPageChange = (currentPage) => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage
    }
    loadTableData(paginationParam0, searchParam)
  }

  const onCheckAll = (checked) => {
    setIsCheckedAll(checked)
    let tableData0 = [...tableData]
    tableData0.forEach(el => {
      el.is_checked = checked
    })
    setTableData(tableData0)
  }
  const onCheckCell = (index, checked) =>{
    let tableData0 = [...tableData]
    tableData0[index]['is_checked'] = checked
    setTableData(tableData0)
  }

  useEffect(() => {
    handleSearch()
  }, [])

  return (
    <Wrapper>
      <TablePanel
        data={tableData}
        loading={isLoading}
        onPageChange={onPageChange}
        paginationParam={paginationParam}
        toolbar={
          <div className='d-flex align-items-center'>
            <MarkReadBtn handleSearch={handleSearch} 
              tableData={tableData}
            />
            <span style={{width: 8}} />
            <MarkUnreadBtn handleSearch={handleSearch} 
              tableData={tableData}
            />
          </div>
        }
        columns={[
          {
            title: () => (
              <div className='d-flex align-items-center'>
                <Checkbox checked={isCheckedAll} 
                  onChange={e=>onCheckAll(e.target.checked)}
                />&nbsp;&nbsp;&nbsp;
                <span className={''}>
                  Subject
                </span>
              </div>
            ),
            key: 'title',
            render: (_, record, index) => (
              <div className='d-flex align-items-center'>
                <Checkbox checked={record.is_checked} 
                  onChange={e=>onCheckCell(index, e.target.checked)}
                />&nbsp;&nbsp;&nbsp;
                <TitleText data={record} 
                  className={`${readIds.indexOf(record.id)>=0?'readed':''}`}
                />
              </div>
            )
          },
          {
            title: 'Description',
            key: 'description',
            render: (_, record) => (
              <span className={`description ${readIds.indexOf(record.id)>=0?'readed':''}`}
                dangerouslySetInnerHTML={{
                  __html: `${record.description.substring(0, 60)}${record.description.length>60?'...':''}`
                }}
              />
            )
          },
          {
            title: 'Date',
            key: 'created_at',
            render: (_, record) => (
              <span className={`${readIds.indexOf(record.id)>=0?'readed':''}`}>
                {asDateTime(record.created_at)}
              </span>
            )
          }
        ]}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .title {
    cursor: pointer;
  }
  .description {
    p {
      margin-bottom: 0;
    }
  }
  .readed {
    color: ${props=>props.theme.palette.secondaryText};
    text-decoration: line-through;
  }
  @media (max-width: 1200px) {
    .table-container {
      .ant-table {
        overflow-x: scroll;
        .ant-table-container {
          width: 1000px;
        }
      }
    }
  }
`

const TitleText = ({ data, className }) => {
  const [modalMode, setModalMode] = useState(undefined)

  return (
    <span>
      <span className={`title ${className}`}
        onClick={() => setModalMode('detail')}
      >
        {data.title}
      </span>
      {modalMode==='detail' && 
        <NotificationDetailModal
          notificationData={data}
          handleCloseNotificationDetailModal={() => {
            setModalMode(undefined)
          }}
        />
      }
    </span>
  )
}

const MarkReadBtn = (props) => {
  const [isUpdating, setIsUpdating] = useState(false)

  const onSubmitDone = (res) => {
    SuccessNotification(res.message)
    props.handleSearch()
    setIsUpdating(false)
  }
  const onSubmitFail = () => {
    setIsUpdating(false)
  }
  const handleSubmit = () => {
    // Validation
    let notificationIds = []
    props.tableData.forEach(el => {
      if (el.is_checked) notificationIds.push(el.id)
    })
    if (notificationIds.length===0) {
      message.error('Please select notifications')
      return
    }

    const body = {
      notification_ids: notificationIds.toString(),
      read_status: 1
    }
    setIsUpdating(true)
    callPostApiWithAuth('notification/change_status', body, 
      onSubmitDone, onSubmitFail
    );
  }

  return (
    <Popconfirm onConfirm={handleSubmit}>
      <Button loading={isUpdating}>
        Mark as Read
      </Button>
    </Popconfirm>
  )
}

const MarkUnreadBtn = (props) => {
  const [isUpdating, setIsUpdating] = useState(false)

  const onSubmitDone = (res) => {
    SuccessNotification(res.message)
    props.handleSearch()
    setIsUpdating(false)
  }
  const onSubmitFail = () => {
    setIsUpdating(false)
  }
  const handleSubmit = () => {
    // Validation
    let notificationIds = []
    props.tableData.forEach(el => {
      if (el.is_checked) notificationIds.push(el.id)
    })
    if (notificationIds.length===0) {
      message.error('Please select notifications')
      return
    }

    const body = {
      notification_ids: notificationIds.toString(),
      read_status: 2
    }
    setIsUpdating(true)
    callPostApiWithAuth('notification/change_status', body, 
      onSubmitDone, onSubmitFail
    );
  }

  return (
    <Popconfirm onConfirm={handleSubmit}>
      <Button loading={isUpdating}>
        Mark as Unread
      </Button>
    </Popconfirm>
  )
}

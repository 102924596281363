
import styled from "styled-components"
import BottomBgImg from "assets/images/ticket/bottom_bg.png"
import MaskImg from "assets/images/ticket/mask_image.png"

export const Wrapper = styled.div`
  min-height: 100%;
  padding-bottom: 80px;
  .main-container {
    background-color: #ffffff;
    background-image: url(${BottomBgImg});
    background-position: bottom right;
    background-size: contain;
    background-repeat: no-repeat;

    .main-info-section {
      position: relative;
      z-index: 0;

      .top-bg-image {
        height: 120%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        z-index: -1;
      }

      .info-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        .title-image {
          max-width: 400px;
          margin: 40px 0 20px;
        }

        h3 {
          text-transform: uppercase;
          font-weight: bold;
        }

        h3, h5 {
          line-height: 24px;
          color: #ffffff;
          text-align: center;
          text-shadow: 0px 4px 4px rgb(0 0 0 / 40%);
        }

        .ticket-price {
          margin-top: 20px;
          font-weight: bolder;
          font-size: 54px;
          background: linear-gradient(to bottom, transparent, transparent, transparent),url(${MaskImg});
          background-attachment: fixed;
          -webkit-text-fill-color: transparent;
          -webkit-background-clip: text;
        }

        .ticket-type {
          .ant-select-selector {
            width: 300px;
          }
        }

        .ticket-actions {
          margin: 20px 0;
          width: 300px;
          display: flex;
          justify-content: space-between;

          .action-btn {
            font-weight: 600;
            width: 145px;
            height: 40px;
          }

          .add-ticket-btn {
            background-color: #00b2ff;
            border-color: #00b2ff;
          }

          .checkout-btn {
            background-color: #BFBFBF;
            color: #232838;
            border-color: #BFBFBF;
          }
        }

        .customer-names-section {
          margin: 20px 0 10px;

          .customer-name-row {
            display: flex;
            width: 200px;
            align-items: center;

            .remove-customer-icon {
              cursor: pointer;
              width: 25px;
              height: 25px;
              margin-left: 10px;
            }
          }
        }

        .ticket-features {
          width: 100%;
          max-width: 1200px;
          padding: 20px;

          .feature-title {
            color: #ffffff;
            text-align: center;
            margin-bottom: 30px;
            text-shadow: 0px 4px 4px rgb(0 0 0 / 40%);
          }

          .feature {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            max-width: 170px;
            margin: 0 auto;

            .feature-icon {
              height: 50px;
              margin-bottom: 5px;
            }

            .feature-desc {
              color: #ffffff;
              text-align: center;
              text-shadow: 0px 4px 4px rgb(0 0 0 / 40%);
            }
          }
        }
      }
    }

    .video-section {
      position: relative;
      max-width: 800px;
      padding: 55px 50px;
      z-index: 0;
      margin: 0 auto;

      .video-bg-image {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
      }

      .video-thumbnail-section {
        position: relative;

        .video-thumbnail {
          width: 100%;
        }

        .play-icon {
          width: 50px;
          position: absolute;
          top: calc(50% - 25px);
          left: calc(50% - 25px);
          cursor: pointer;
        }
      }
    }

    .book-section {
      margin-top: 50px;
      padding: 30px;
      display: flex;
      justify-content: center;

      .book-image-section {
        width: 50%;
        padding: 30px;

        .book-image {
          width: 100%;
          max-width: 600px;
        }
      }

      .book-info-section {
        width: 50%;
        max-width: 400px;
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        h5 {
          margin-bottom: 40px;
          font-weight: 700;
          color: #232838;
        }

        .book-btn {
          width: 145px;
          height: 40px;
          background-color: #00b2ff;
          border-color: #00b2ff;
          padding: 4px 15px !important;
        }

        p {
          margin-top: 30px;
        }
      }
    }

    @media only screen and (max-width: 1200px) {
      .video-section {
        padding: 5%;
      }
    }

    @media only screen and (max-width: 767px) {
      .main-info-section {
        .top-bg-image {
          height: 110%;
        }

        .info-section {
          .title-image {
            width: 250px;
          }

          .ticket-type {
            .ant-select-selector {
              width: 250px;
            }
          }

          .ticket-actions {
            width: 250px;

            .action-btn {
              width: 120px;
            }
          }
        }
      }

      .book-section {
        flex-direction: column;

        .book-image-section {
          width: 100%;
          padding: 0;

          .book-image {
            max-width: 100%;
          }
        }

        .book-info-section {
          width: 100%;
          max-width: 100%;
          padding: 0;
        }
      }
    }

    @media only screen and (max-width: 575px) {
      .main-info-section {
        .info-section {
          .customer-names-section {
            .customer-name-row {
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
`
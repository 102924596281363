import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  TablePanel,
  Input,
  Button,
  Select,
  UserAvatar,
  RankBadge,
  CountryFlag,
} from "components/common";
import { callGetApiWithAuth } from "utils/api";
import DetailModal from "./DetailModal";
import { BsChatDots } from "react-icons/bs";
import { LoadingOutlined } from "@ant-design/icons";
import { ReactComponent as SearchOutlinedIcon } from "assets/icons/search-icon.svg";

export default function TableView() {
  const [tableData, setTableData] = useState([]);
  const [paginationParam, setPaginationParam] = useState({
    currentPage: 1,
    perPage: 15,
    total: 0,
  });
  const [searchParam, setSearchParam] = useState({
    uuid: "",
    sort_by: "level_asc",
    active_only: "",
    pay_rank_id: "",
    rank_id: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState(undefined);
  const [ranks, setRanks] = useState([]);

  const onFormInput = (field, value) => {
    setSearchParam({
      ...searchParam,
      [field]: value,
    });
  };

  const onGetRanks = (res) => {
    setRanks(res.data);
  };
  const onFailRanks = () => {};
  const loadRanks = () => {
    callGetApiWithAuth("common/ranks", onGetRanks, onFailRanks);
  };

  const onGetTableData = (res) => {
    setIsLoading(false);
    setTableData(res.data.data);
    setPaginationParam({
      ...paginationParam,
      currentPage: res.data.current_page,
      total: res.data.total,
    });
  };
  const onFailTableData = () => {
    setIsLoading(false);
  };
  const loadTableData = (paginationParam0, searchParam0) => {
    setIsLoading(true);
    const params = {
      ...searchParam0,
      page: paginationParam0.currentPage,
      per_page: paginationParam0.perPage,
    };
    const q = Object.keys(params)
      .map((k) => k + "=" + params[k])
      .join("&");
    callGetApiWithAuth(
      `report/rank_report/list?${q}`,
      onGetTableData,
      onFailTableData
    );
  };

  const onPageChange = (page) => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: page,
    };
    loadTableData(paginationParam0, searchParam);
  };

  const handleSearch = () => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: 1,
    };
    loadTableData(paginationParam0, searchParam);
  };

  useEffect(() => {
    handleSearch();
    loadRanks();
  }, []);

  return (
    <Wrapper>
      <TablePanel
        data={tableData}
        loading={isLoading}
        paginationParam={paginationParam}
        onPageChange={onPageChange}
        toolbar={
          <div className="searchActionContainer">
            <div className="searchField search_bar">
              <Input
                value={searchParam.uuid}
                onChange={(e) => onFormInput("uuid", e.target.value)}
                label={"Search by User ID"}
                inputPrefix={<SearchOutlinedIcon />}
              />
            </div>
            <div className="searchField">
              <Select
                label="Filter by"
                value={searchParam.active_only}
                onChange={(v) => onFormInput("active_only", v)}
                options={[
                  { label: "All", value: "" },
                  { label: "Active Only", value: "y" },
                ]}
              />
            </div>
            <div className="searchField">
              <Select
                label="Select Current Rank"
                value={searchParam.rank_id}
                onChange={(v) => onFormInput("rank_id", v)}
                options={[
                  { label: "Select Current Rank", value: "" },
                  ...ranks.map((el) => ({
                    label: el.name,
                    value: el.id,
                  })),
                ]}
              />
            </div>
            <div className="searchField">
              <Select
                label="Select Paid Rank"
                value={searchParam.pay_rank_id}
                onChange={(v) => onFormInput("pay_rank_id", v)}
                options={[
                  { label: "Select Paid Rank", value: "" },
                  ...ranks.map((el) => ({
                    label: el.name,
                    value: el.id,
                  })),
                ]}
              />
            </div>
            <div className="searchField">
              <Select
                label="Sort By"
                value={searchParam.sort_by}
                onChange={(v) => onFormInput("sort_by", v)}
                options={[
                  { label: "Level Desc", value: "level_desc" },
                  { label: "Level Asc", value: "level_asc" },
                  { label: "Current Rank Desc", value: "rank_desc" },
                  { label: "Current Rank Asc", value: "rank_asc" },
                  { label: "Paid Rank Desc", value: "pay_rank_desc" },
                  { label: "Paid Rank Asc", value: "pay_rank_asc" },
                  { label: "Country Desc", value: "country_desc" },
                  { label: "Country Asc", value: "country_asc" },
                ]}
              />
            </div>
            <Button loading={isLoading} onClick={handleSearch}>
              Search
            </Button>
          </div>
        }
        columns={[
          {
            title: "User ID",
            key: "uuid",
            render: (_, record) => <span>{record.user.uuid}</span>,
          },
          {
            title: "Name",
            key: "user",
            render: (_, record) => (
              <div className="d-flex align-items-center">
                <UserAvatar
                  image={record.user.image}
                  title={`${record.user.first_name} ${record.user.last_name}`}
                />{" "}
                <ChatIcon user={record.user} />
              </div>
            ),
          },
          {
            title: "Country",
            key: "country",
            render: (_, record) => (
              <CountryFlag country={record.user.country} />
            ),
          },
          {
            title: "Level",
            key: "level",
            render: (_, record) => <span>{record.level}</span>,
          },
          {
            title: "Current Rank",
            key: "rank_id",
            render: (_, record) => <RankBadge label={record.user.rank.name} />,
          },
          {
            title: "Paid Rank",
            key: "pay_rank",
            render: (_, record) => (
              <RankBadge label={record.user.pay_rank.name} />
            ),
          },
          {
            title: "Highest Rank",
            key: "max_rank",
            render: (_, record) =>
              record.max_rank ? <RankBadge label={record.max_rank.name} /> : "",
          },
          {
            title: "Rank Qualification",
            key: "rank_qualification",
            render: (_, record) => (
              <div className="d-flex align-items-center">
                <Button success onClick={() => setSelected(record)}>
                  Check
                </Button>
              </div>
            ),
          },
        ]}
      />
      {selected && (
        <DetailModal selected={selected} setSelected={setSelected} />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .panelHead {
    flex-wrap: wrap;
    .searchActionContainer {
      display: flex;
      align-items: center;
      gap: 16px;
      flex-wrap: wrap;
      .searchField {
        .input-prefix {
          svg {
            width: 15px;
            height: 15px;
            margin-bottom: 5px;
          }
        }
        .ant-input {
          height: 40px !important;
        }
        .ant-select-selector {
          height: 40px !important;
          width: 180px !important;
          padding-top: 4px !important;
        }
        .ant-select {
          height: 40px !important;
          color: #212b36;
        }
      }
    }
  }
  @media (max-width: 600px) {
    .toolbar {
      .searchActionContainer {
        gap: 24px !important;
        .searchField,
        .actionButton {
          width: 100% !important;
        }
        button {
          width: 100%;
        }
      }
    }
    .ant-select-selector {
      width: 100% !important;
    }
  }
  .chat_icon {
    margin-left: 6px;
    margin-top: -5px;
    cursor: pointer;
    color: #1ea9ea;
    font-size: 16px;
  }
`;

const ChatIcon = ({ user }) => {
  const history = useHistory();
  const [isLoadingDownliner, setIsLoadingDownliner] = useState(false);

  const onSuccessGetDownliner = ({ data }) => {
    setIsLoadingDownliner(false);
    if (data && data.length > 0) {
      history.push({
        pathname: "/team_chat",
        state: {
          user: data,
        },
      });
    }
  };

  const onClickChat = () => {
    setIsLoadingDownliner(true);
    callGetApiWithAuth(
      `common/search_enroll_downline_affiliate/${user.username}`,
      onSuccessGetDownliner,
      () => {
        setIsLoadingDownliner(false);
      }
    );
  };

  return (
    <span className="chat_icon">
      {isLoadingDownliner ? (
        <LoadingOutlined />
      ) : (
        <BsChatDots onClick={() => onClickChat(user)} />
      )}
    </span>
  );
};

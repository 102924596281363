import styled from "styled-components";

export const Wrapper = styled.div`
  .payment-header {
    margin-bottom: 20px;
    span {
      font-weight: 500;
      font-size: 18px;
      position: relative;
      z-index: 500;
      display: inline-block;
      background: #eff3f6;
      padding-right: 9px;
    }
    .main-divider {
      height: 1px;
      background: #b1bbc7;
      position: relative;
      top: -18px;
    }
  }
  .payment-product-image {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    img {
      width: 100%;
      border-radius: 8px;
      height: 100%;
      object-fit: cover;
    }
  }
  .price-container {
    .payment-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
      margin-bottom: 16px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    .payment-tag {
    }
    .payment-price-container {
      margin-top: 10px;
      display: flex;
      align-items: center;
      .payment-price {
        .label {
          font-weight: 500;
          font-size: 14px;
          color: #7f8fa3;
          margin-bottom: 2px;
        }
        .price {
          font-size: 14px;
          color: #00b4ee;
        }
      }
      .payment-quantity {
        border-left: 1px solid #7f8fa34d;
        padding-left: 20px;
        margin-left: 20px;
        .label {
          font-weight: 500;
          font-size: 14px;
          color: #7f8fa3;
          margin-bottom: 2px;
        }
        .quantity {
          font-size: 14px;
          color: #00b4ee;
        }
      }
    }
  }
  .billing-container {
    font-size: 14px;
    margin-top: 5px;
    span {
      color: #7f8fa3;
      font-weight: 500;
      margin-left: 5px;
    }
  }
  .date-row {
    margin-top: 15px;
    .date-container {
      background: #f3f5f8;
      padding: 10px;
      .date-label {
        font-weight: 500;
        font-size: 14px;
        color: #7f8fa3;
      }
      .date-value {
        font-size: 14px;
        color: #00b4ee;
      }
    }
  }
  .action-buttons-container {
    padding: 0px 50px;
    .edit {
      width: 100%;
      border-radius: 2px !important;
      background: #1b92fb;
      font-size: 14px;
    }
    .cancel {
      width: 100%;
      border-radius: 2px !important;
      font-size: 14px;
      background: white;
      border-color: #384971;
      color: #384971;
    }
  }
  .ant-list {
    .ant-col {
      height: 100%;
      .ant-list-item {
        height: 100%;
      }
    }
  }
  .past-card {
    padding: 24px 24px 3px 24px;
    height: 100%;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2),
      0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
    .bv-container {
      .bv-title {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #212b36;
      }
      .bv-value {
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: #36b37e;
        margin-top: 4px;
      }
    }
    .pro-c {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      .pro-c-1 {
        width: 132px;
        height: 132px;
      }
      .pro-c-2 {
        width: calc(100% - 132px);
        padding-left: 16px;
        .date-container-n {
          .date-label-n {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #212b36;
            margin-bottom: 2px;
          }
          .date-value-n {
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: #ff5630;
          }
        }
      }
    }
  }
  @media (max-width: 1350px) {
    .past-list > div > div > div > div {
      width: 50% !important;
      max-width: 50% !important;
    }
  }

  @media (max-width: 1050px) {
    .past-list > div > div > div > div {
      width: 100% !important;
      max-width: 100% !important;
    }
  }
  @media (max-width: 900px) {
    .past-list > div > div > div > div {
      width: 50% !important;
      max-width: 50% !important;
    }
  }
  @media (max-width: 700px) {
    .past-list > div > div > div > div {
      width: 100% !important;
      max-width: 100% !important;
    }
  }
`;

import styled from "styled-components";

export const Wrapper = styled.div`
  .modal-new-title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #212b36;
    margin-bottom: 20px;
  }
  .label {
    display: none;
  }
  .ant-input,
  .ant-select-selector {
    height: 40px !important;
  }
  .action-btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .cancel-btn {
      border-radius: 8px !important;
      height: 40px;
      padding: 0px 15px;
      margin-left: 12px;
      border: 1px solid rgba(145, 158, 171, 0.32);
      background: white;
      color: black;
    }
    .create-btn {
      background: #1ea9ea;
      border-radius: 8px !important;
      height: 40px;
      padding: 0px 15px;
      margin-left: 12px;
    }
  }
  .credit-input-box {
    div {
      width: 100%;
    }
    .credit-input-container {
      border: 1px solid #d9d9d9;
      height: 40px;
      border-radius: 8px;
      img + label {
        width: 94% !important;
      }
    }
  }
  .ant-form-item-label {
    top: 8px;
  }
  .ant-form-item-label-active {
    transform: translateY(-16px) scale(1);
  }
  .errorWrapper {
    p {
      margin: 0px;
    }
  }
`;

import styled from "styled-components";

export const Wrapper = styled.div`
  .cardHeader {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }
  h4 {
    font-size: 18px;
    font-weight: 700;
    color: #212b36;
    margin-bottom: 0;
  }

  .table-container {
    width: 100%;
    overflow-x: auto;
    table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0;
      @media (max-width: 1400px) {
        width: 1200px;
      }

      thead {
        th {
          :first-child {
            border-radius: 8px 0px 0px 0px;
          }
          :last-child {
            border-radius: 0px 8px 0px 0px;
          }
        }
      }
      tbody {
        tr {
          td {
            :first-child {
              border-radius: 0px 0px 0px 8px;
            }
            :last-child {
              border-radius: 0px 0px 8px 0px;
            }
          }
        }
        .stepSubtitle {
          color: ${(props) => props.theme.palette.secondary};
          font-size: 12px;
        }
        .stepSubtitle2 {
          color: ${(props) => props.theme.palette.secondaryText};
          font-size: 12px;
        }
      }

      th {
        position: relative;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        text-align: left;
        background: #fafafa;
        border: 1px solid #f2f2f2;
        transition: background 0.3s ease;
        padding: 4px 8px;
        text-align: center;
        font-size: 13px;
      }

      td {
        position: relative;
        text-align: center;
        border-bottom: 1px solid #f2f2f2;
        border-left: 1px solid #f2f2f2;
        border-right: 1px solid #f2f2f2;
        transition: background 0.3s;
        padding: 4px 0;
        color: #212b36;
        font-weight: 700;
      }
      .tickIcon {
        width: 16px;
        height: 16px;
        margin-left: 4px;
      }
    }
  }
`;
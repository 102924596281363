import styled from "styled-components";

export const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2),
    0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  border-radius: 16px;
  padding: 24px;
  .label-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0px;
    .title {
      font-weight: 500;
      font-size: 22px;
    }
  }
  .enrollment-settings-description {
    margin-bottom: 40px;
    .ant-alert-with-description .ant-alert-message {
      font-weight: 600;
      margin-bottom: 12px;
    }
  }
  .checking-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 70px);
    svg {
      max-width: 100%;
    }
  }
  .enorllment-container {
    .select-container {
      .label {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #212b36;
        margin-bottom: 8px;
      }
      .select-box {
        font-weight: 500;
        font-size: 15px;
        color: black;
        dispalay: flex;
        align-items: center;
        .ant-select {
          width: 374px;
        }
        .ant-switch {
          margin-left: 10px;
        }
      }
    }
  }
  @media (max-width: 530px) {
    .select-box {
      .ant-select {
        width: 100% !important;
      }
    }
  }
  .alert-container-section {
    margin-bottom: 0px;
  }
`;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Tooltip, Progress, Select } from "components/common";
import { asVolume } from "utils/text";
import { callGetApiWithAuth } from 'utils/api';
import { ReactComponent as ThreeTickFrameIcon } from "assets/icons/three-circle-frame-3.svg";
import { ReactComponent as InfoIcon } from "assets/icons/dashboard/info.svg";

export default function BinaryDiagram(props) {
  const [qualData, setQualData] = useState({
    pv: 0, pecv: 0,
    l_bpev: 0, r_bpev: 0,
    l_bgv: 0, r_bgv: 0,
    is_qualified: false,
  })

  const onGetQualData = (res) => {
    setQualData(res.data)
  }
  const onFailQualData = () => {}
  const getQualData = () => {
    const params = {
      period: props.period
    }
    const q = Object.keys(params).map(k=>k+'='+params[k]).join('&')
    callGetApiWithAuth(
      `dashboard/binary_diagram?${q}`,
      onGetQualData, onFailQualData
    )
  }

  useEffect(() => {
    getQualData();
  }, [props.period])

  return (
    <Wrapper>
      <div className="binary-container">
        <div className="binary-label">
          Binary Qualified
          <Tooltip title="To be Binary Qualified, you must have a minimum of 40 Personal Volume (PV), 90 Business Volume (BV) from personally enrolled Affiliates/Preferred Customers on each your left and right leg, and a minimum of 300 Business Volume (BV) on your lesser leg.">
            <InfoIcon />
          </Tooltip>
        </div>
        <div className='period-select'>
          <Select value={props.period}
            onChange={v=>props.setPeriod(v)}
            options={[
              { value: 'last', label: 'Last Month' },
              { value: 'current', label: 'This Month' },
            ]}
          />
        </div>
      </div>
      <div className="unilevel-box">
        <div className="binary-frame-container">
          <div className="you-text"></div>
          <div className="top-text">
            <Tooltip
              title={() => (
                <div>
                  Personal Volume: {asVolume(qualData.pv)}
                  <br />
                  Personal Customer Volume:{" "}
                  {asVolume(qualData.pecv)}
                </div>
              )}
            >
              <div>
                {qualData.pv*1 >= 40
                ? `${asVolume(qualData.pv)}/40`
                : qualData.pecv*1 >= 80
                ? `${asVolume(qualData.pecv)}/80`
                : qualData.pv*1 >= qualData.pecv*1
                ? `${asVolume(qualData.pv)}/40`
                : `${asVolume(qualData.pecv)}/80`}
              </div>
            </Tooltip>
          </div>
          <ThreeTickFrameIcon className="three-svg" />
          <div className="left-text">
            <Tooltip title={"Left Binary Personal Volume"}>
              <div>{asVolume(qualData.l_bpev)}/90</div>
            </Tooltip>
            {/*
            <Tooltip title={'Left Binary Business Volume'}>  
              <div>
                {asVolume(qualData.l_bgv)}/300
              </div>
            </Tooltip>
            */}
          </div>
          <div className="right-text">
            <Tooltip title={"Right Binary Personal Volume"}>
              <div>{asVolume(qualData.r_bpev)}/90</div>
            </Tooltip>
            {/*
            <Tooltip title={'Right Binary Business Volume'}>  
              <div>
                {asVolume(qualData.r_bgv)}/300
              </div>
            </Tooltip>
            */}
          </div>
        </div>
        <div className="binary-progress-chart">
          <div className="line-progress-container">
            <div className="line-progress-box">
              <div className="line-progress-title">
                Lesser Leg:{" "}
                {qualData.l_bgv * 1 <= qualData.r_bgv * 1
                  ? `Left`
                  : `Right`}
              </div>
              <div className="line-progress-value">
                BV:&nbsp;
                {qualData.l_bgv*1<=qualData.r_bgv*1
                ? asVolume(qualData.l_bgv)
                : asVolume(qualData.r_bgv)
                }/300
              </div>
            </div>
            <Progress
              percent={
                (Math.min(
                  qualData.l_bgv * 1 <= qualData.r_bgv * 1
                  ? qualData.l_bgv*1
                  : qualData.r_bgv*1
                  , 300
                ) / 300) * 100
              }
              size="small"
              strokeWidth={10}
              strokeColor="#00B8D9"
              showInfo={false}
            />
          </div>
        </div>
        {qualData.is_qualified && (
          <div className="congratulation-text">
            <span>Congratulations!</span> You are now Binary Qualified!
          </div>
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .ant-select {
    width: 150px;
  }
  .ant-select-selector {
    height: 40px !important;
    .ant-select-selection-item {
      position: relative;
      top: -2px;
    }
  }
  .binary-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .binary-label {
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      color: #212b36;
      display: flex;
      align-items: center;
      svg {
        margin-left: 12px;
      }
    }
    .binary-tick {
    }
    .type-label {
      cursor: pointer;
      background: rgba(145, 158, 171, 0.16);
      border-radius: 14px;
      padding: 5px 10px;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #637381;
      margin-left: 12px;
      &.active {
        background: #1ea9ea;
        color: #ffffff;
      }
    }
  }
  .binary-frame-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .you-text {
      font-weight: normal;
      font-size: 14px;
      margin: 20px 0px -10px;
    }
    .top-text {
      color: #212b36;
      font-weight: normal;
      font-size: 14px;
      position: relative;
      top: 72px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      line-height: 1.2em;
    }
    .left-text {
      color: #212b36;
      font-weight: normal;
      font-size: 14px;
      position: relative;
      left: -128px;
      top: -50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .right-text {
      color: #212b36;
      font-weight: normal;
      font-size: 14px;
      position: relative;
      right: -131px;
      top: -68px;
      margin-bottom: -10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  .unilevel-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100% - 20px);
  }
  .congratulation-text {
    font-weight: 600;
    font-size: 14px;
    color: #637381;
    width: 100%;
    margin-top: 4px;
    span {
      font-weight: 600;
      color: #1ea9ea;
    }
  }
  .bottom-chart-root {
    width: 100%;
    .line-chart {
      background-color: #e4f1ff;
      height: 10px;
      border-radius: 10.5px;
      overflow: hidden;
      .chart-progressed {
        background-color: rgb(116, 68, 196);
        height: 100%;
      }
    }
  }
  .binary-progress-chart {
    width: 100%;
    .line-progress-container {
      .line-progress-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: -4px;
        .line-progress-title {
          font-weight: 600;
          font-size: 14px;
          line-height: 22px;
          color: #212b36;
        }
        .line-progress-value {
          font-weight: 600;
          font-size: 14px;
          line-height: 22px;
          text-align: right;
          color: #212b36;
        }
      }
    }
  }
  @media (max-width: 550px) {
    .three-svg {
      width: 281px;
    }
    .top-text {
      top: 99px !important;
    }
    .left-text {
      left: -98px !important;
      top: -80px !important;
    }
    .right-text {
      right: -100px !important;
      top: -102px !important;
    }
  }
`;

import styled from "styled-components";

export const Wrapper = styled.div`
  .modalHeader {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 24px;
  }
  .action-btn {
    display: flex;
    justify-content: end;
    gap: 12px;
  }
  .input-container {
    .ant-input {
      height: 40px !important;
      font-size: 14px !important;
    }
    .ant-form-item-label {
      top: 8px !important;
    }
    .ant-form-item-label-active {
      transform: translateY(-18px) scale(1) !important;
    }
    .ant-picker {
      width: 100% !important;
    }
    .label {
      label {
        display: none;
      }
    }
    .ant-select-selector {
      height: 40px !important;
      .ant-select-selection-item {
        font-size: 14px !important;
      }
    }
  }
  .input-container > div {
    height: 100% !important;
  }
  .icon-container {
    display: flex;
    align-items: center;
    justify-content: end;
    height: 100%;
    svg {
      cursor: pointer;
      :nth-child(2) {
        margin-left: 12px;
        path {
          fill: #1ea9ea;
        }
      }
      :nth-child(1) {
        path {
          fill: #ff5630;
        }
      }
    }
  }
`;

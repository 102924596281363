import React, { useState } from "react";
import { Row, Col, Alert } from "components/common";
import YourWebsite from "./yourWebsite/YourWebsite";
import { Wrapper } from "./BrandSitePage.styled";

export default function BrandSitePage() {
  const [reloadData, setReloadData] = useState(false);
  return (
    <Wrapper>
      <div className="main-container">
        <Row gutter={[20, 20]}>
          {/* <Col xs={24}>
            <Alert
              type="info"
              showIcon
              message={
                <div
                  dangerouslySetInnerHTML={{
                    __html: `MyDailyChoice makes it easy for Affiliates to drive traffic and get credit for sales. Please write down and save your replicated website exactly as it is written. Sending your traffic into the wrong website could result in incorrect enrollments. For questions, please email <a style="text-decoration: none" href="mailto:support@mydailychoice.com">support@mydailychoice.com</a>.`,
                  }}
                />
              }
              style={{ alignItems: "baseline" }}
            />
          </Col> */}
          {[
            { 
              title: 'Akashx',
              url: process.env.REACT_APP_AKASHX_CORP_URL,
              image: 'https://mdc-assets.s3.us-east-2.amazonaws.com/akashx.png'
            },
            { 
              title: 'HempWorx',
              url: process.env.REACT_APP_HEMPWORX_CORP_URL,
              image: 'https://mdc-assets.s3.us-east-2.amazonaws.com/hempworx.png'
            },
            { 
              title: 'Fuel Factor X',
              url: process.env.REACT_APP_FFX_CORP_URL,
              image: 'https://mdc-assets.s3.us-east-2.amazonaws.com/ffx.png'
            },
            { 
              title: "O'Snap",
              url: process.env.REACT_APP_OSNAP_CORP_URL,
              image: 'https://mdc-assets.s3.us-east-2.amazonaws.com/osnap-meta.png'
            },
            { 
              title: "SAVVI",
              url: process.env.REACT_APP_SAVVI_CORP_URL,
              image: 'https://mdc-assets.s3.us-east-2.amazonaws.com/savvi.png'
            },
            { 
              title: "DreamTrips International",
              url: process.env.REACT_APP_DTI_CORP_URL,
              image: 'https://mdc-assets.s3.us-east-2.amazonaws.com/dti.png'
            },
          ].map(el => 
          <Col xs={24} xl={8}>
            <YourWebsite data={el} />{" "}
          </Col>
          )}
        </Row>
      </div>
    </Wrapper>
  );
}

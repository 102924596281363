import React, { useState } from 'react'
import { 
  Button, Popconfirm, 
  SuccessNotification, message,
} from 'components/common'
import {
  callPutApiWithAuth
} from 'utils/api'
import { useSelector } from 'react-redux'

export default function ActionTd(props) {
  const [isUpdating, setIsUpdating] = useState(false)
  const myUser = useSelector(state=>state.auth.user)

  const onSubmitDone = (res) => {
    setIsUpdating(false)
    SuccessNotification(res.message)
    setTimeout(() => {
      props.loadTableData()
    }, [500])
  }
  const onSubmitFail = () => {
    setIsUpdating(false)
  }
  const handleSubmit = () => {
    if (!props.data.pos_type) {
      message.error('Please input position')
      return
    }
    if (props.data.pos_type=='placement' && 
      !props.data.placement
    ) {
      message.error('Please input placement')
      return
    }
    if (props.data.pos_type=='placement' && 
      !props.data.power_leg
    ) {
      message.error('Please input power leg')
      return
    }
    const body = {
      user_id: props.data.id,
      pos_type: props.data.pos_type,
      placement_id: props.data.placement?.id,
      new_power_leg: props.data.new_power_leg
    }
    setIsUpdating(true)
    callPutApiWithAuth(`my_team/holding_tank/update_placement`, 
      body, onSubmitDone, onSubmitFail
    )
  }

  return (
    <Popconfirm onConfirm={handleSubmit} 
      disabled={(!myUser.parent_id && myUser.id*1 !== 1) || props.data.is_autoplacing}
      title={
        <div style={{width: 300}}>
          I agree and understand that holding tank placements are final once placed.<br/>
          Once <span className={'ant-typography ant-typography-danger'}>{props.data.first_name} {props.data.last_name}</span> been placed, they cannot be moved again.
        </div>
      }
    >
      <Button className="submitButton" 
        loading={isUpdating}
        disabled={(!myUser.parent_id && myUser.id*1 !== 1) || props.data.is_autoplacing}
      >
        Submit
      </Button>
    </Popconfirm>
  )
}

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import copy from 'copy-to-clipboard';
import { 
  Row, Col, Button, Select,
  Tooltip, Table, Pagination, 
  Spin, SuccessNotification,
} from 'components/common';
import { asKNumber, asNumber, asDate } from 'utils/text';
import { BsQuestionCircle } from "react-icons/bs";
import { BiCopy } from "react-icons/bi";
import TaxCreditImg from 'assets/images/tax-credit.jpg';
import { callGetApiWithAuth } from 'utils/api';

export default function SharePage() {
  const myUser = useSelector(state=>state.auth.user)
  const [statData, setStatData] = useState({
    total_count: 0,
    pe_count: 0,
    pe_pv: 0,
    total_pv: 0,
  })
  const [tableData, setTableData] = useState([])
  const [paginationParam, setPaginationParam] = useState({
    currentPage: 1,
    perPage: 15,
    total: 0,
  })
  const [isLoading, setIsLoading] = useState(false)
  const [isExporting, setIsExporting] = useState(false)
  const [searchParam, setSearchParam] = useState({
    status: '',
  })

  const onGetTableData = ({ data }) => {
    setTableData(data.data)
    setPaginationParam({
      ...paginationParam,
      currentPage: data.current_page,
      total: data.total
    })
    setIsLoading(false)
  }
  const onFailTableData = () => {
    setIsLoading(false)
  }
  const loadTableData = (paginationParam0, searchParam0) => {
    const params = {
      ...searchParam0,
      page: paginationParam0.currentPage,
      per_page: paginationParam0.perPage
    }
    setIsLoading(true)
    const q = Object.keys(params).map(k=>k+'='+params[k]).join('&')
    callGetApiWithAuth(`setc/pes?${q}`, onGetTableData, onFailTableData)
  }
  const handleSearch = () => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: 1
    }
    loadTableData(paginationParam0, searchParam)
  }
  const onPageChange = (page) => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: page
    }
    loadTableData(paginationParam0, searchParam)
  }
  const onGetStatData = ({ data }) => {
    setStatData({
      ...statData,
      ...data,
    })
  }
  const getStatData = () => {
    callGetApiWithAuth(`setc/stat`, onGetStatData)
  }

  const onExportDone = ({ data }) => {
    window.open(data.csv_file, '_blank')
    setIsExporting(false)
  }
  const handleExport = () => {
    setIsExporting(true)
    const params = {
      ...searchParam
    }
    const q = Object.keys(params).map(k=>k+'='+params[k]).join('&')
    callGetApiWithAuth(`setc/export_pes?${q}`, onExportDone)
  }

  useEffect(() => {
    getStatData()
  }, [])

  useEffect(() => {
    handleSearch()
  }, [searchParam])

  return (
    <Wrapper>
      <div className='s1-root'>
        <div className='container'>
          <div className='stat-list'>
            <div className='stat-card'>
              <div className='tp-wrap'>
                <span className='stat-span'>
                  {asKNumber(statData.total_count)}
                </span>
                <span>
                  <Tooltip
                    title={'Total applicants refers to the number of people who have completed the questionnaire either in their MDC back office or on step 2 of the SETC Tax Credits capture page.'}
                  >
                    <BsQuestionCircle />
                  </Tooltip>
                </span>
              </div>
              <div className='btm-wrap'>
                Total Applicants
              </div>
            </div>
            <div className='stat-card'>
              <div className='tp-wrap'>
                <span className='stat-span'>
                  {asKNumber(statData.pe_count)}
                </span>
                <span>
                  <Tooltip
                    title={'Personally enrolled refers to the number of people in your front line (level 1) who have completed the questionnaire.'}
                  >
                    <BsQuestionCircle />
                  </Tooltip>
                </span>
              </div>
              <div className='btm-wrap'>
                Personally Enrolled
              </div>
            </div>
            <div className='stat-card'>
              <div className='tp-wrap'>
                <span className='stat-span'>
                  {asKNumber(statData.pe_pv)}
                </span>
                <span>
                  <Tooltip
                    title={'Personally enrolled volume refers to the volume generated from the filing fees from your front line (level 1).'}
                  >
                    <BsQuestionCircle />
                  </Tooltip>
                </span>
              </div>
              <div className='btm-wrap'>
                Personally Enrolled Volume
              </div>
            </div>
            <div className='stat-card'>
              <div className='tp-wrap'>
                <span className='stat-span'>
                  {asKNumber(statData.total_pv)}
                </span>
                <span>
                  <Tooltip
                    title={'Total business volume refers to the volume generates from the filing fees from your enrollment tree.'}
                  >
                    <BsQuestionCircle />
                  </Tooltip>
                </span>
              </div>
              <div className='btm-wrap'>
                Total Business Volume
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='s2-root'>
        <div className='container'>
          <h4>Your Website Link</h4>
          <h3>www.SETCTaxCredit.com/{myUser?.username}</h3>
          <div className='action-group'>
            <button onClick={() => {
              copy(`https://www.setctaxcredit.com/${myUser.username}`);
              SuccessNotification("Successfully copied to clipboard");
            }}>
              <BiCopy />&nbsp;
              COPY LINK
            </button>
          </div>
          <h3>v2.SETCTaxCredit.com/{myUser?.username}</h3>
          <div className='action-group'>
            <button onClick={() => {
              copy(`https://v2.setctaxcredit.com/${myUser.username}`);
              SuccessNotification("Successfully copied to clipboard");
            }}>
              <BiCopy />&nbsp;
              COPY LINK
            </button>
          </div>
        </div>
      </div>
      {/* <div className='s4-root'>
        <div className='container'>
          <Row gutter={[48, 48]}>
            <Col xs={24} sm={12}>
              <h2>
                Were You Self-Employed During Covid?
              </h2>
              <h4>
                You may be eligible for up to $32,200 from the IRS. 
              </h4>
              <h4>
                Find out if you qualify NOW.
              </h4>
              <p>
                We built the first-ever tool to help you get the federal 
                FFCRA tax credits that you deserve – safely, quickly, and easily.
              </p>
              <Link to='/setc/apply'>
                CHECK MY FFCRA ELIGIBILITY
              </Link>
            </Col>
            <Col xs={24} sm={12}>
              <iframe src="https://player.vimeo.com/video/893483592?h=c14c1c90e3&badge=0&autopause=0&quality_selector=1&player_id=0&app_id=58479" width="100%" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen title={'TaxCredit Video'}></iframe>
            </Col>
          </Row>
        </div>
      </div> */}
      {/* <div className='s5-root'>
        <div className='container'>
          <Row gutter={[48, 48]}>
            <Col xs={24} sm={12}>
              <img src={TaxCreditImg} />
            </Col>
            <Col xs={24} sm={12}>
              <h2>
                What Is The FFCRA Tax Credit —Families First Coronavirus Response Act?
              </h2>
              <p>
                In March 2020, the Families First Coronavirus Response Act  (FFCRA) was signed into law to help companies offer paid sick leave and unemployment benefits caused by COVID-19.  Initially the FFCRA focused on employers with W-2 employees to help them weather the economic impact caused by the pandemic.
              </p>
              <p>
                In December 2020 Congress passed the Coronavirus Aid, Relief, and Economic Security (CARES) Act which expanded the FFCRA to cover not only employers, but the self-employed. Thanks to the FFCRA expansion self-employed individuals, freelancers, independent contractors, and gig workers are now eligible for tax credits that pay you back for the time you would’ve normally spent earning money that was lost because of COVID.
              </p>
            </Col>
          </Row>
        </div>
      </div> */}
      <div className='s3-root'>
        <div className='container'>
          <div className='d-flex flex-wrap justify-content-between'
            style={{ marginBottom: 12 }}
          >
            <div className='status-select'>
              <Select value={searchParam.status}
                onChange={v=>setSearchParam({...searchParam, status: v})}
                options={[
                  { label: 'Select Status', value: '' },
                  ...[
                    'Waiting on a signed Engagement Letter',
                    'In process waiting on documents',
                    'In process with Account Manager',
                    'In process waiting on additional information',
                    'In process waiting on client response',
                    'In progress with Doc Specialist',
                    'Further documentation needed',
                    'In review',
                    'Waiting to be Assigned',
                    'Invoice Sent',
                    '1040X Sent',
                    'Client Call Requested',
                    'Hold File Per Client Request',
                    'Hold File per Internal Request',
                    'Working with Another Department',
                    'Final Review and Document Filing',
                    'Filed with IRS',
                    'DNP ( Do Not Process)',
                    'Client Complete'
                  ].map(el => ({
                    label: el, value: el,
                  })),
                ]}
              />
            </div>
            <div className='d-flex flex-wrap'>
              <Button loading={isExporting}
                onClick={handleExport}
              >
                Export
              </Button>
              <div style={{ width: 12 }} />
              <Button 
                onClick={() => {
                  window.open('https://mdc-assets.s3.us-east-2.amazonaws.com/admin/SETC+STATUS.pdf', '_blank')
                }}
              >
                SETC Definitions
              </Button>
            </div>
          </div>
          <Table 
            pagination={false}
            loading={{
              spinning: isLoading,
              indicator: <Spin spinning={true} className={`spinner`} />,
            }}
            columns={[
              { 
                title: 'Name', 
                render: (_, record) => (
                  <span>{record.first_name+' '+record.last_name}</span>
                )
              },
              {
                title: 'Email',
                render: (_, record) => (
                  <span>
                    {record.email}
                  </span>
                )
              },
              {
                title: 'Phone',
                render: (_, record) => (
                  <span>
                    {record.phone}
                  </span>
                )
              },
              {
                title: 'Application Date',
                render: (_, record) => (
                  <span>
                    {asDate(record.applied_at)}
                  </span>
                )
              },
              {
                title: 'Status',
                render: (_, record) => (
                  <span className='status-badge'>
                    {record.current_department?
                      record.current_department+': '
                    : ''}
                    {record.status}
                  </span>
                )
              },
              {
                title: 'Filling Fee',
                render: (_, record) => (
                  <span>
                    {'Pending'}
                  </span>
                )
              },
              {
                title: 'Business Volume',
                render: (_, record) => (
                  <span>
                    {'Pending'}
                  </span>
                )
              },
            ]}
            dataSource={tableData}
          />
          <div className='d-flex justify-content-end'>
            <Pagination
              current={paginationParam.currentPage}
              pageSize={paginationParam.perPage}
              total={paginationParam.total}
              onChange={onPageChange}
              defaultPageSize="15"
              responsive
              hideOnSinglePage={true}
            />
          </div>
          <div style={{ height: 40 }} />
          <Row gutter={[48, 24]}>
            <Col xs={24} sm={12}>
              <h2>How do we calculate your commissions?</h2>
              <div style={{ height: 60 }} />
              <h4>The filing fees for SETC for 2020 and 2021:</h4>
              <ul className='pv-list'>
                <li>
                  $500 for Personal Sick Leave 2020 (150BV)
                </li>
                <li>
                  $250 for Family Credit Filing 2020 (100BV)
                </li>
                <li>
                  $500 for Personal Sick Leave 2021 (150BV)
                </li>
                <li>
                  $250 for Family Credit Filing 2020 (100BV)
                </li>
              </ul>
              <p>
                If your existing Affiliates/Customers apply for SETC,
                you will receive Business Volume using the above calculation. 
                The max filing fee someone will pay is $1,500 which will generate 
                a total of 500BV. If you send out your SETCTaxCredit.com/{myUser?.username}&nbsp;
                replicated funnel, those signups will be treated as Customers.
              </p>
            </Col>
            <Col xs={24} sm={12}>
              <h4>Volume Projection:</h4>
              <div style={{ height: 10 }} />
              <Table 
                columns={[
                  { 
                    title: 'Paid Applicants', 
                    align: 'center',
                    render: (_, record) => (
                      <span>{asNumber(record.count)}</span>
                    )
                  },
                  { 
                    title: 'Business Volume', 
                    align: 'center',
                    render: (_, record) => (
                      <span>{asNumber(record.pv)}</span>
                    )
                  }
                ]}
                dataSource={[
                  {
                    count: 10,
                    pv: 5000,
                  },
                  {
                    count: 20,
                    pv: 10000,
                  },
                  {
                    count: 50,
                    pv: 25000,
                  },
                  {
                    count: 100,
                    pv: 50000,
                  },
                  {
                    count: 200,
                    pv: 100000,
                  },
                  {
                    count: 500,
                    pv: 250000,
                  },
                  {
                    count: 1000,
                    pv: 500000,
                  },
                  {
                    count: 2000,
                    pv: 1000000,
                  },
                ]}
                pagination={false}
              />
              <div style={{ height: 15 }} />
              <p>
                *The above projections assume everyone qualifies for 
                the max eligibility and pays a $1,500 filing fee.
              </p>
            </Col>
          </Row>
        </div>
      </div>
      <div className="dis-root">
        <div className='container'>
          <h4>Disclaimer</h4>
          <p>
            Please note that MyDailyChoice is an independent entity and is not
            associated with, endorsed by, or officially affiliated with the
            Internal Revenue Service (IRS) or any of its subsidiaries or
            affiliates. For official IRS information, please visit www.irs.gov.
            The term "IRS," along with any related names, logos, symbols, and
            images, is the property of their respective owners and is registered
            as their trademark. Any mentions of the IRS on our website are solely
            for the purpose of providing information and commentary on relevant
            topics and are intended for informational use only. We do not assert
            any representation or special relationship with the IRS. If you have
            inquiries or issues related to taxes or the IRS, we recommend
            contacting the IRS directly.
          </p>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: -24px -40px;
  @media (max-width: 768px) {
    margin: 0;
  }
  .s1-root {
    background-color: #EDF9FE;
    padding: 100px 0 0 0;
    .stat-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .stat-card {
        width: calc(25% - 12px);
        border: 1px solid #919EAB3D;
        background: #fff;
        padding: 24px;
        border-radius: 12px;
        margin: 12px 0;
        @media (max-width: 1366px) {
          width: calc(50% - 12px);
        }
        @media (max-width: 768px) {
          width: 100%;
        }
        .tp-wrap {
          display: flex;
          justify-content: space-between;
        }
        .stat-span {
          font-size: 48px;
          font-weight: 700;
          line-height: 1.1em;
          color: #262261;
        }
        .btm-wrap {
          font-size: 16px;
          font-weight: 700;
          line-height: 1.2em;
          color: #121212;
          margin-top: 8px;
        }
      }
    }
  }
  .s2-root {
    background-color: #EDF9FE;
    padding: 20px 0 80px 0;
    img {
      width: 100%;
    }
    h4 {
      font-size: 20px;
      font-weight: 700;
      line-height: 1.2em;
      text-align: center;
      margin-top: 20px;
      @media (max-width: 768px) {
        font-size: 16px;
      }
    }
    h3 {
      font-size: 24px;
      font-weight: 700;
      line-height: 1.2em;
      text-align: center;
      margin-top: 20px;
      margin-bottom: 20px;
      @media (max-width: 768px) {
        font-size: 16px;
      }
    }
    .action-group {
      display: flex;
      justify-content: center;
      button {
        background: #079ACD;
        color: #fff;
        font-weight: bold;
        padding: 10px 40px;
        border-radius: 100px;
        border: 0 none;
      }
    }
  }
  .s4-root {
    background: #000;
    padding: 80px 0;
    h2 {
      color: #ffffff;
      font-size: 41px;
      font-weight: 400;
      margin-bottom: 19px;
      @media (max-width: 768px) {
        font-size: 30px;
      }
    }
    h4 {
      color: #ffffff;
      font-size: 26px;
      font-weight: 400;
      margin-bottom: 19px;
      @media (max-width: 768px) {
        font-size: 22px;
      }
    }
    p {
      color: #fff;
      font-size: 18px;
    }
    a {
      font-weight: 600;
      text-transform: uppercase;
      fill: #FFFFFF;
      color: #FFFFFF;
      background-color: #C10933;
      border-radius: 50px 50px 50px 50px;
      padding: 15px 45px 15px 45px;
      text-decoration: none;
      display: inline-block;
      margin: 50px 0 0;
    }
  }
  .s5-root {
    padding: 80px 0 0 0;
    img {
      width: 100%;
    }
    h2 {
      color: #000000;
      font-size: 45px;
      margin-bottom: 15px;
      font-weight: 600;
      line-height: 1em;
      @media (max-width: 768px) {
        font-size: 30px;
      }
    }
    p {
      color: #000000;
      font-size: 16px;
      font-weight: 300;
      line-height: 1.8em;
      margin-bottom: 20px;
    }

  }
  .s3-root {
    background-color: #EDF9FE;
    padding: 80px 0;
    .ant-table-thead > tr > th {
      background-color: #079ACD;
      color: #fff;
    }
    h2 {
      font-size: 32px;
      font-weight: 700;
      line-height: 1.1em;
      @media (max-width: 768px) {
        font-size: 24px;
      }
    }
    h4 {
      font-size: 24px;
      font-weight: 700;
      line-height: 1.2em;
      @media (max-width: 768px) {
        font-size: 20px;
      }
    }
    p {
      font-size: 20px;
      color: ${props=>props.theme.palette.secondaryText};
      @media (max-width: 768px) {
        font-size: 16px;
      }
    }
    ul.pv-list {
      padding-left: 0;
      margin: 30px 0;
      width: 100%;
      list-style: none;
      li {
        padding: 24px;
        color: #414141;
        font-size: 16px;
        line-height: 1.33em;
        background: #fff;
        border: 1px solid #E7E7E7;
        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
    }
    .ant-table-container {
      overflow-x: auto;
    }
    .status-badge {
      text-transform: capitalize;
    }
    .status-select {
      width: 480px;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
  .dis-root {
    padding: 60px 0;
  }
`

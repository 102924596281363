import { Col, Row } from 'antd'
import CustomerVolumeCard from 'components/common/customerVolumeCard/CustomerVolumeCard'
import React, { useEffect, useState } from 'react'
import { asDate, asDateTime, asPrice, asVolume } from 'utils/text'
import { ReactComponent as Coin } from "assets/icons/earning-icon.svg";
import { ReactComponent as Subscription } from "assets/icons/home/subscribe-icon.svg";
import { ReactComponent as VolumeYellow } from "assets/icons/customer_volume/customerVolume_Yellow.svg";
import { ReactComponent as VolumeGreen } from "assets/icons/customer_volume/customerVolume_Green.svg";
import styled from 'styled-components';
import { callGetApiWithAuth } from 'utils/api';
import { TablePanel } from 'components/common';

export default function DTIPointsReportPage() {

  const [stat, setStat] = useState({
    'subscription': '',
    'monthly_points': 0,
    'balance': 0
  });

  const [orders, setOrders] = useState([])
  const [paginationParam, setPaginationParam] = useState({
    currentPage: 1,
    perPage: 15,
    total: 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  const onGetStat = (res) => {
    setStat(res.data)
  }

  const onGetOrders = (res) => {
    setOrders(res.data)
    setIsLoading(false);
  }

  const onPageChange = (page) => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: page,
    };
    loadOrders(paginationParam0);
  }

  const loadOrders = (paginationParam0) => {
    setIsLoading(true);
    const params = {
      page: paginationParam0.currentPage,
      per_page: paginationParam0.perPage,
    };
    const q = Object.keys(params)
      .map((k) => k + "=" + params[k])
      .join("&");
    callGetApiWithAuth(
      `report/dti_points_report/orders?${q}`,
      onGetOrders,
    );
  };

  useEffect(() => {
    callGetApiWithAuth('report/dti_points_report/stat', onGetStat);
    setIsLoading(true);
    loadOrders(paginationParam)
  }, [])

  return (
    <>
      <div className="customerVolumeCard">
        <Row gutter={[24, 24]}>
          <Col xs={24} xl={6}>
            <Wrapper>
              <div className="volumeRoot">
                <div className="icon">{<Subscription />}</div>
                <div className="data">
                  <div className="value">{stat.subscription}</div>
                  <div className="title">Active Subscription</div>
                </div>
              </div>
            </Wrapper>
          </Col>
          <Col xs={24} xl={6}>
            <Wrapper>
              <div className="volumeRoot">
                <div className="icon">{<VolumeYellow />}</div>
                <div className="data">
                  <div className="value">{asPrice(stat.monthly_points)}</div>
                  <div className="title">Monthly Points</div>
                </div>
              </div>
            </Wrapper>
          </Col>
          <Col xs={24} xl={6}>
            <Wrapper>
              <div className="volumeRoot">
                <div className="icon">{<Coin />}</div>
                <div className="data">
                  <div className="value">{asPrice(stat.balance)}</div>
                  <div className="title">Current Balance</div>
                </div>
              </div>
            </Wrapper>
          </Col>
          <Col xs={24} xl={6}>
            <Wrapper>
              <div className="volumeRoot">
                <div className="icon">{<VolumeGreen />}</div>
                <div className="data">
                  <div className="value">2025-07-31</div>
                  <div className="title">Maturity Date</div>
                </div>
              </div>
            </Wrapper>
          </Col>
        </Row>
        <Wrapper style={{margin:"20px 0px"}}>
          <TablePanel
            title={"Orders"}
            data={orders}
            paginationParam={paginationParam}
            onPageChange={onPageChange}
            loading={isLoading}
            columns={[
              {
                title: "Order ID",
                key: "order_number",
                render: (_, record) => <span>{record.order_number}</span>,
              },
              {
                title: "Subscription",
                key: "subscription",
                render: (_, record) => <span>{record.details[0].product.title}</span>,
              },
              {
                title: "Price",
                key: "price",
                render: (_, record) => <span>{asPrice(record.details[0].product.member_price)}</span>,
              },
              {
                title: "Points",
                key: "points",
                render: (_, record) => <span>{record.details[0].product.sku == 'DT_WelcomePack' ? 700 : asPrice(Math.ceil(record.details[0].product.member_price / 10) * 10)}</span>,
              },
              {
                title: "Date",
                key: "date",
                render: (_, record) => <span>{asDateTime(record.created_at)}</span>,
              },
            ]}
          />
        </Wrapper>
      </div></>
  )
}

const Wrapper = styled.div`
  height: 100%;
  .volumeRoot {
    height: 100%;
    padding: 20px 10px 20px 20px;
    display: flex;
    align-items: center;
    gap: 20px;
    border: 1px solid rgba(145, 158, 171, 0.24);
    border-radius: 8px;
    .data {
      .value {
        font-weight: 700;
        font-size: 18px;
        color: #212b36;
      }
      .title {
        color: #637381;
      }
    }
  }
`;


import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import SmsIcon from "assets/icons/header/sms.svg";
import NoUserImage from "assets/images/user.svg";
import styled, { keyframes } from "styled-components";
import { callGetApiWithAuth } from "utils/api";
import SendBird from "sendbird";
import { Badge } from "antd";

export default function MessageBadge(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const unreadCountRedux = useSelector(
    (state) => state.message.unreadMessageCount
  );
  const history = useHistory();
  const [isStarteddAnimation, setIsStartedAnimation] = useState(true);

  const handleMessageClick = () => {
    history.push("/team_chat");
  };

  setTimeout(() => {
    setIsStartedAnimation(false);
  }, 5000);

  const getUnreadMessageCount = async (sbKey) => {
    // let sb = new SendBird({appId: sbKey.appId})
    // sb.connect(props.userData.uuid, function(user, error) {
    //   if (error) {
    //     return
    //   }
    //   sb.getTotalUnreadMessageCount()
    //   .then((res) => {
    //     dispatch({
    //       type: 'message.SET_UNREAD_MESSAGES',
    //       payload: { unreadMessageCount: res }
    //     })
    //   })
    //   .catch((err) => {
    //   })
    // });
    // await axios.get(
    //   `${sbKey.apiUrl}/v3/users/${props.userData.uuid}?include_unread_count=true`,
    //   {
    //     headers: {
    //       'Api-Token': `${sbKey.apiToken}`
    //     }
    //   }
    // ).then(function(res){
    //   dispatch({
    //     type: 'message.SET_UNREAD_MESSAGES',
    //     payload: { unreadMessageCount: (res && res.data && res.data.unread_message_count) ? res.data.unread_message_count : 0 }
    //   })
    // }).catch(function(err){
    //   dispatch({
    //     type: 'message.SET_UNREAD_MESSAGES',
    //     payload: { unreadMessageCount: 0 }
    //   })
    // })
  };

  const onGetSbKey = (data) => {
    getUnreadMessageCount(data.data);
  };

  const onFailSbKey = () => {};

  const getSbKey = () => {
    callGetApiWithAuth(`chat/get_key`, onGetSbKey, onFailSbKey);
  };

  useEffect(() => {
    if (!props.userData) return;
    getSbKey();
  }, [location.pathname]);

  return (
    <Wrapper>
      <div className={props?.className && props.className}>
        <AnimatedMessage started={unreadCountRedux > 0 && isStarteddAnimation}>
          <Badge
            max={99}
            count={unreadCountRedux}
            className="notification-badge"
          >
            <img
              src={SmsIcon}
              style={{ width: 24 }}
              className="avatar-icons-hidden-sms"
              onClick={handleMessageClick}
            />
          </Badge>
        </AnimatedMessage>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .avatar-icons-hidden-sms {
    width: 35px;
    display: inline;
    margin-left: 24px;
  }
  .notification-badge {
    color: #000000;
    "& .muibadge-badge": {
      background-color: #ff632c;
      color: #ffffff;
    }
  }
  @media (max-width: 900px) {
    display: none;
  }
`;

const blinkingEffect = () => {
  return keyframes`
    50% {
      opacity: 0;
    }
  `;
};

const AnimatedMessage = styled.div`
  animation: ${(props) => props.started && blinkingEffect} 1s linear infinite;
`;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Carousel from "react-multi-carousel";
import { Input, Button, Spin } from "components/common";
import { callGetApiWithAuth } from "utils/api";
import { asUsdPrice, asVolume } from "utils/text";
import {
  PlusOutlined as PlusIcon,
  MinusOutlined as MinusIcon,
  LoadingOutlined,
} from "@ant-design/icons";
import SliderRight from "assets/images/sliderright.png";
import SliderLeft from "assets/images/sliderleft.png";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1800 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1800, min: 1500 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1500, min: 1260 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 1260, min: 0 },
    items: 1,
  },
};

export default function HltProductList() {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hltAmount, setHltAmount] = useState(0);
  const [isLoadingAmount, setIsLoadingAmount] = useState(false);

  const onGetProducts = (res) => {
    setProducts(res.data);
    setIsLoading(false);
  };
  const onFailProducts = () => {
    setIsLoading(false);
  };
  const loadProducts = () => {
    setProducts([]);
    setIsLoading(true);
    callGetApiWithAuth(`hlt/hlt_products`, onGetProducts, onFailProducts);
  };

  const onGetHltAmount = (res) => {
    setIsLoadingAmount(false);
    setHltAmount(res.data.amount);
  };
  const onFailHltAmount = () => {
    setIsLoadingAmount(false);
  };
  const getHltAmount = () => {
    setIsLoadingAmount(true);
    callGetApiWithAuth(`hlt/hlt_amount`, onGetHltAmount, onFailHltAmount);
  };

  useEffect(() => {
    loadProducts();
    getHltAmount();
  }, []);

  return (
    <Wrapper>
      <div className={"title-root"}>
        <h4 className={"title"}>Travel Packs</h4>
        <p className="d-flex align-items-center">
          Travel Savings Balance&nbsp;
          {isLoadingAmount ? (
            <LoadingOutlined />
          ) : (
            <span className="balance">{asUsdPrice(hltAmount)}</span>
          )}
        </p>
      </div>
      <div className={"slider-box"}>
        <Carousel responsive={responsive}>
          {products.map((el, index) => (
            <div key={index}>
              <ProductCard data={el} />
            </div>
          ))}
        </Carousel>
        {products.length === 0 && <div className="emptyRoot">&nbsp;</div>}
        {isLoading && (
          <div className="loadingRoot">
            <Spin spinning={true} />
          </div>
        )}
      </div>
    </Wrapper>
  );
}

const ProductCard = ({ data }) => {
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(1);

  const decreaseQty = () => {
    if (quantity > 1) setQuantity(quantity - 1);
  };
  const increaseQty = () => {
    setQuantity(quantity * 1 + 1);
  };

  const handleAdd = () => {
    dispatch({
      type: "checkout.ADD_CART",
      payload: {
        product: data,
        quantity: quantity,
        pay_cycle: 0,
        contained_products: [],
      },
    });
    dispatch({
      type: "ui.SHOW_SIDE_CART",
    });
  };

  return (
    <ProductWrapper>
      <div className="product-img">
        <img src={data.image_path} />
      </div>
      <div className="pd-container">
        <div className="product-title">{data.title}</div>
        <div className="price">
          {`${asUsdPrice(data.member_price)}`}
          &nbsp; ({`${asVolume(data.pv)}BV`})
        </div>
      </div>
      <div className="action-root">
        <div className="qty-root">
          <MinusIcon onClick={decreaseQty} />
          <Input
            type={"number"}
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
          />
          <PlusIcon className={"plus-icon"} onClick={increaseQty} />
        </div>
        <Button onClick={handleAdd} bgColor={"#1EA9EA"} color="white">
          Add To Cart
        </Button>
      </div>
    </ProductWrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2),
    0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  border-radius: 16px;
  height: 100%;
  padding: 24px;
  .title-root {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    p {
      margin: 0;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: #637381;
    }
  }
  .title {
    margin: 0px;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #212b36;
  }
  .balance {
    color: #ff5630;
    font-size: 18px;
    font-weight: bold;
  }
  .slider-box {
    margin: 0 -12px;
  }
  .react-multiple-carousel__arrow--left {
    background: url(${SliderRight});
    background-size: contain;
    left: 0;
    background-repeat: no-repeat;
    min-width: 35px;
    min-height: 35px;
  }
  .react-multiple-carousel__arrow--right {
    background: url(${SliderLeft});
    background-size: contain;
    right: 0;
    background-repeat: no-repeat;
  }
  .react-multiple-carousel__arrow::before {
    display: none;
  }
  .emptyRoot {
    width: 100%;
    height: 512px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loadingRoot {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 600px) {
    .title-root {
      flex-direction: column !important;
      align-items: self-start !important;
    }
  }
`;

const ProductWrapper = styled.div`
  background: #ffffff;
  border: 1px solid rgba(145, 158, 171, 0.24);
  border-radius: 16px;
  padding: 8px 8px 16px;
  margin: 0px 12px;
  .pd-container {
    padding: 16px 8px;
  }
  .product-img {
    img {
      width: 100%;
      height: 285px;
      object-fit: cover;
      border-radius: 12px;
    }
  }
  .product-title {
    height: 48px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #212b36;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  .price {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #1ea9ea;
    text-align: end;
  }
  .action-root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 8px;
    flex-wrap: wrap;
    button {
      padding: 0px !important;
    }
  }
  .qty-root {
    position: relative;
    width: calc(50% - 5px);
    .anticon {
      cursor: pointer;
      position: absolute;
      top: 12px;
      left: 12px;
      z-index: 12;
      &.plus-icon {
        left: auto;
        right: 12px;
      }
    }
    .ant-input {
      text-align: center;
      padding: 0 20px 0px 20px;
      height: 40px !important;
    }
  }
  .ant-btn {
    width: calc(50% - 5px);
  }
`;

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  Row, Col,
} from 'components/common'
import {
  callGetApiWithAuth
} from 'utils/api'

export default function StatCard() {
  const [statData, setStatData] = useState({
    personal_affiliates: 0,
    personal_preferreds: 0,
    total_affiliates: 0,
    total_preferreds: 0
  })

  const onGetStatData = (res) => {
    setStatData(res.data)
  }
  const getStat = () => {
    callGetApiWithAuth(`my_team/unilevel_list/stat`, onGetStatData)
  }

  useEffect(() => {
    getStat()    
  }, [])

  return (
    <Wrapper>
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={12} xl={6}>
          <div className={'statRoot'}>
            <p className={'title'}>
              {'Personally Enrolled Affiliates'}
            </p>
            <p className={'value'}>
              {statData.personal_affiliates}
            </p>
          </div>
        </Col>
        <Col xs={24} sm={12} xl={6}>
          <div className={'statRoot'}>
            <p className={'title'}>
              {'Personally Enrolled Customers'}
            </p>
            <p className={'value'}>
              {statData.personal_preferreds}
            </p>
          </div>
        </Col>
        <Col xs={24} sm={12} xl={6}>
          <div className={'statRoot'}>
            <p className={'title'}>
              {'Total Affiliates'}
            </p>
            <p className={'value'}>
              {statData.total_affiliates}
            </p>
          </div>
        </Col>
        <Col xs={24} sm={12} xl={6}>
          <div className={'statRoot'}>
            <p className={'title'}>
              {'Total Customers'}
            </p>
            <p className={'value'}>
              {statData.total_preferreds}
            </p>
          </div>
        </Col>
      </Row>
    </Wrapper>
  )
}

const Wrapper = styled.div`
margin-bottom: 24px;
  .statRoot {
    background: white;
    padding: 24px;
    box-shadow: 0px 0px 2.13372px rgba(145, 158, 171, 0.2), 0px 12.8023px 25.6047px -4.26744px rgba(145, 158, 171, 0.12);
    border-radius: 17.0698px;
    .title {
      font-weight: 600;
      font-size: 14px;
      color: #637381;
      margin-bottom: 0;
    }
    .value {
      font-weight: 700;
      font-size: 32px;
      color: #212B36;
      margin-bottom: 0;
    }
  }
`

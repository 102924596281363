import React, { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import {
  Card,
  TablePanel,
  Alert,
  Spin,
  UserAvatar,
  StepBar,
  MonthPicker,
} from "components/common";
import { callGetApiWithAuth } from "utils/api";
import { asDate, asUsdPrice, asVolume } from "utils/text";

export default function GpStatCard(props) {
  const [statData, setStatData] = useState([
    {
      title: "Enrollments",
      name: `0`,
    },
    {
      title: "Shares",
      name: `0`,
    },
  ]);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [paginationParam, setPaginationParam] = useState({
    currentPage: 1,
    perPage: 5,
    total: 0,
  });
  const [searchParam, setSearchParam] = useState({
    month: `${moment().format("YYYY-MM-DD")}`,
  });
  const [lastUnit, setLastUnit] = useState(0);
  const [currentShares, setCurrentShares] = useState(0);
  const [currentAmount, setCurrentAmount] = useState(0);

  const onMonthChange = (v) => {
    let month = moment().format("YYYY-MM-DD");
    if (v) {
      month = moment(v).format("YYYY-MM-DD");
    }
    const searchParam0 = {
      ...searchParam,
      month,
    };
    const paginationParam0 = {
      ...paginationParam,
      currentPage: 1,
    };
    setSearchParam(searchParam0);
    loadStatData(searchParam0);
    loadTableData(paginationParam0, searchParam0);
  };

  const onGetStatData = (res) => {
    setStatData([
      {
        title: "Enrollments",
        name: res.data.pes,
        is_active: true,
      },
      {
        title: "Shares",
        name: res.data.shares,
      },
    ]);
    setLastUnit(res.data.last_unit);
    setCurrentShares(res.data.current_shares);
    setCurrentAmount(res.data.current_amount);
    props.onSetTotalData("gpAmount", res.data.current_amount);
    setIsLoading2(false);
  };
  const onFailStatData = () => {
    setIsLoading2(false);
  };
  const loadStatData = (searchParam0) => {
    const params = {
      ...searchParam0,
    };
    const q = Object.keys(params)
      .map((k) => k + "=" + params[k])
      .join("&");
    setIsLoading2(true);
    callGetApiWithAuth(
      `report/bonus_tracker/gp_stat?${q}`,
      onGetStatData,
      onFailStatData
    );
  };

  const onGetTableData = (res) => {
    setPaginationParam({
      ...paginationParam,
      currentPage: res.data.current_page,
      total: res.data.total,
    });
    setTableData(res.data.data);
    setIsLoading(false);
  };
  const onFailTableData = () => {
    setIsLoading(false);
  };
  const loadTableData = (paginationParam0, searchParam0) => {
    setIsLoading(true);
    const params = {
      ...searchParam0,
      page: paginationParam0.currentPage,
      per_page: paginationParam0.perPage,
    };
    const q = Object.keys(params)
      .map((k) => k + "=" + params[k])
      .join("&");
    callGetApiWithAuth(
      `report/bonus_tracker/gp_list?${q}`,
      onGetTableData,
      onFailTableData
    );
  };

  const onPageChange = (page) => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: page,
    };
    loadTableData(paginationParam0, searchParam);
  };

  useEffect(() => {
    loadStatData(searchParam);
    loadTableData(paginationParam, searchParam);
  }, []);

  return (
    <Wrapper>
      <div className="white-rounded-bg">
        <div className="top-panel">
          <h4>Global Bonus Pool</h4>
          <Alert
            type="info"
            message={
              <>
                Your current shares in the pool are{" "}
                <strong>{currentShares}</strong> and the last month's share
                value was <strong>{asUsdPrice(lastUnit)}</strong>. Your current
                global bonus pool estimation is{" "}
                <strong className="ant-typography ant-typography-success">
                  {asUsdPrice(currentAmount)}
                </strong>
                .
              </>
            }
            showIcon
            style={{ alignItems: "baseline" }}
          />
          <div className="topBar">
            <div className="topStep">
              {isLoading2 ? (
                <div className="statLoading">
                  <Spin spinning={true} />
                </div>
              ) : (
                ""
              )}
              {statData?.map((item, index) => {
                return (
                  <div key={index} className="pool-stats">
                    <div className="stats-name">{item.title}</div>
                    <div className="stats-value">{item.name}</div>
                  </div>
                );
              })}
            </div>
            <MonthPicker
              date={searchParam.month}
              onChange={onMonthChange}
              format={"MMM, YYYY"}
            />
          </div>
        </div>
        <TablePanel
          data={tableData}
          loading={isLoading}
          paginationParam={paginationParam}
          onPageChange={onPageChange}
          columns={[
            {
              title: "User",
              key: "user",
              render: (_, record) => (
                <UserAvatar
                  image={record.image}
                  title={`${record.first_name} ${record.last_name}`}
                />
              ),
            },
            {
              title: "User ID",
              key: "uuid",
              render: (_, record) => <span>{record.uuid}</span>,
            },
            {
              title: "Last Order Number",
              key: "order_number",
              render: (_, record) => (
                <span>
                  {record.last_order ? record.last_order.order_number : ""}
                </span>
              ),
            },
            {
              title: "BV",
              key: "order_total_cv",
              render: (_, record) => <span>{asVolume(record.pv)}</span>,
            },
            {
              title: "Last Order Date",
              key: "paid_at",
              render: (_, record) => (
                <span>
                  {record.last_order ? asDate(record.last_order.paid_at) : ""}
                </span>
              ),
            },
          ]}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .white-rounded-bg {
    padding: 0px !important;
    .top-panel {
      padding: 24px;
      h4 {
        font-size: 18px;
        font-weight: 700;
        color: #212b36;
        margin-bottom: 24px;
      }
    }
  }
  .table-card {
    .panelRoot {
      margin-bottom: 0px !important;
    }
  }
  .panelHead {
    display: none !important;
  }
  .table-new-wrapper {
    .ant-table-container {
      @media (max-width: 1350px) {
        width: 800px;
      }
    }
    overflow-x: auto;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    .ant-table {
      border-radius: 0px !important;
    }
    .ant-table-content {
      thead {
        th:first-child,
        th:last-child {
          border-radius: 0px !important;
        }
      }
    }
  }
  .topBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 24px;
    margin-top: 24px;
    .topStep {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      align-items: center;
      @media (max-width: 1388px) {
        width: 100%;
        justify-content: space-between;
      }
      .pool-stats {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 4px;
        .stats-name {
          color: #637381;
        }
        .stats-value {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: #212b36;
          font-size: 16px;
          font-weight: 600;
          border: 1px solid rgba(145, 158, 171, 0.24);
          border-radius: 8px;
          height: 54px;
          width: 144px;
        }
      }
      @media (max-width: 1388px) {
        .pool-stats {
          flex-direction: column;
          width: 100%;
        }
        .stats-value {
          width: 100% !important;
        }
      }
    }
    .ant-picker {
      height: 54px !important;
      width: 200px !important;
      border: 1px solid rgba(145, 158, 171, 0.24);
      border-radius: 8px;
      @media (max-width: 1388px) {
        width: 100% !important;
      }
    }
  }
  .statLoading {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.5);
  }
`;
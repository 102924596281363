import React, { useState } from "react";
import { Row, Col, Alert } from "components/common";
import { ReactComponent as CcIcon } from "assets/icons/account/cc.svg";
import { ReactComponent as ProfileIcon } from "assets/icons/account/ic_profile.svg";
import { ReactComponent as LocationIcon } from "assets/icons/account/location.svg";
import { ReactComponent as MessengerIcon } from "assets/icons/account/messenger.svg";
import { ReactComponent as ProIcon } from "assets/icons/account/profile.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/account/settings.svg";
import { ReactComponent as SheildIcon } from "assets/icons/account/shield.svg";
import ShippingAddressSubPage from "./shippingAddress/ShippingAddressSubPage";
import CreditCardSubPage from "./creditCard/CreditCardSubPage";
import CheckingSubPage from "./checking/CheckingSubPage";
import EnrollSettingSubPage from "./enrollSetting/EnrollSettingSubPage";
import ProfileSubPage from "./profile/ProfileSubPage";
import EnrollerCardSubPage from "./enrollerCard/EnrollerCardSubPage";
import LegacyContactSubPage from "./legacyContact/LegacyContactSubPage";
import SocialMessengerSubPage from "./socialMessenger/SocialMessengerSubPage";
import { Wrapper } from "./AccountSettingPage.styled";
import { getUser } from "utils/auth";

export default function AccountSettingPage() {
  const myUser = getUser();
  const [isSelected, setIsSelected] = useState("account");
  return (
    <Wrapper>
      <div className="main-container">
        <Row gutter={[20, 24]} className="table-row">
          <Col xs={24} xl={7} xxl={4}>
            <div className="acc-settings-tab">
              <Row gutter={[20, 0]}>
                <Col span={24}>
                  <div
                    className={
                      isSelected === "account"
                        ? "settings-tabs setting-active"
                        : "settings-tabs"
                    }
                    onClick={() => setIsSelected("account")}
                  >
                    <ProfileIcon />
                    Account Setting
                  </div>
                </Col>
                <Col span={24}>
                  <div
                    className={
                      isSelected === "enroller"
                        ? "settings-tabs setting-active"
                        : "settings-tabs"
                    }
                    onClick={() => setIsSelected("enroller")}
                  >
                    <ProIcon />
                    My Profile Card
                  </div>
                </Col>
                <Col span={24}>
                  <div
                    className={
                      isSelected === "shipping"
                        ? "settings-tabs setting-active"
                        : "settings-tabs"
                    }
                    onClick={() => setIsSelected("shipping")}
                  >
                    {" "}
                    <LocationIcon />
                    Shipping Address
                  </div>
                </Col>
                <Col span={24}>
                  <div
                    className={
                      isSelected === "credit"
                        ? "settings-tabs setting-active"
                        : "settings-tabs"
                    }
                    onClick={() => setIsSelected("credit")}
                  >
                    {" "}
                    <CcIcon />
                    Credit Card
                  </div>
                </Col>
                {/* {myUser.country === "US" && ( */}
                <Col span={24}>
                  <div
                    className={
                      isSelected === "checking"
                        ? "settings-tabs setting-active"
                        : "settings-tabs"
                    }
                    onClick={() => setIsSelected("checking")}
                  >
                    {" "}
                    <SettingsIcon />
                    Checking
                  </div>
                </Col>
                {/* )} */}
                <Col span={24}>
                  <div
                    className={
                      isSelected === "enrollment"
                        ? "settings-tabs setting-active"
                        : "settings-tabs"
                    }
                    onClick={() => setIsSelected("enrollment")}
                  >
                    {" "}
                    <SettingsIcon />
                    Enrollment Setting
                  </div>
                </Col>
                <Col span={24}>
                  <div
                    className={
                      isSelected === "legacy-contact"
                        ? "settings-tabs setting-active"
                        : "settings-tabs"
                    }
                    onClick={() => setIsSelected("legacy-contact")}
                  >
                    {" "}
                    <SheildIcon />
                    Legacy Contact
                  </div>
                </Col>
                <Col span={24}>
                  <div
                    className={
                      isSelected === "social-messenger"
                        ? "settings-tabs setting-active"
                        : "settings-tabs"
                    }
                    onClick={() => setIsSelected("social-messenger")}
                  >
                    {" "}
                    <MessengerIcon />
                    Social Messenger
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={24} xl={17} xxl={20}>
            {isSelected === "shipping" && <ShippingAddressSubPage />}
            {isSelected === "credit" && <CreditCardSubPage />}
            {isSelected === "checking" && <CheckingSubPage />}
            {isSelected === "enrollment" && <EnrollSettingSubPage />}
            {isSelected === "account" && <ProfileSubPage />}
            {isSelected === "enroller" && <EnrollerCardSubPage />}
            {isSelected === "legacy-contact" && <LegacyContactSubPage />}
            {isSelected === "social-messenger" && <SocialMessengerSubPage />}
          </Col>
        </Row>
      </div>
    </Wrapper>
  );
}

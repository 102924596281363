import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Spin } from "components/common";
import OrganizationChart from "@dabeng/react-orgchart";
import TreeNode from "./TreeNode";
import DetailBox from "./DetailBox";
import SearchBar from "./SearchBar";
import { useDispatch } from "react-redux";

export default function TreeView({
  treeData,
  isLoading,
  loadRoot,
  loadParent,
  search,
}) {
  const dispatch = useDispatch();
  const closeDetail = () => {
    dispatch({ type: "binaryTree.CLOSE_DETAIL_BOX" });
  };
  return (
    <Wrapper>
      {treeData && (
        <>
          <SearchBar
            treeData={treeData}
            loadRoot={loadRoot}
            loadParent={loadParent}
            search={search}
          />
          <OrganizationChart
            datasource={treeData}
            pan={true}
            zoom={false}
            collapsible={false}
            draggable={false}
            NodeTemplate={TreeNode}
          />
          <div onMouseLeave={closeDetail}>
            <DetailBox />
          </div>
        </>
      )}
      {isLoading && (
        <div className={"loadingRoot"}>
          <Spin spinning={true} className={`spinner`} />
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2),
    0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  border-radius: 16px;
  width: 100%;
  position: relative;
  padding-top: 0;
  min-height: 400px;
  align-items: center;
  justify-content: center;
  .orgchart-container {
    background-color: transparent;
    border: 0 none;
    height: calc(100vh - 185px);
    width: calc(100% - 20px);
  }
  ,
  .orgchart {
    background-image: none;
    cursor: move !important;
    ul li .oc-node:not(:only-child)::after {
      height: 25px;
    }
    ul > li > ul li {
      &::before {
        border: 1px solid rgba(53, 64, 82, 0.25);
      }
      & > .oc-node {
        &::before {
          background-color: rgba(53, 64, 82, 0.25);
        }
      }
    }
    ul {
      li {
        .oc-node {
          &:hover,
          &:focus,
          &:active,
          &.selected {
            background-color: transparent;
          }
        }
      }
    }
  }
  .orgchart ul li .oc-node:not(:only-child)::after {
    background-color: rgba(53, 64, 82, 0.25);
  }
  .loadingRoot {
    width: 100%;
    height: 100%;
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-form-item-label {
    top: 13px !important;
    left: 48px !important;
  }
  .ant-form-item-label-active {
    transform: translateY(-22px) scale(1) !important;
  }
`;

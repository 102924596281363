import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button } from "components/common";
import BannerImg from "assets/images/renewal.jpg";

export default function OnlineMeetingPage() {
  const goLiveStream = () => {
    window.open("https://Renewallivestream.com", "_blank");
  };

  return (
    <Wrapper className="white-rounded-bg">
      <div className="main-container">
        <div className="main-card">
          <div className="banner-section">
            <img src={BannerImg} />
          </div>
          <p className="line-pwd">Password: Renewal2022</p>
          <div className="action-root">
            <Button size={"large"} onClick={goLiveStream}>Access Live Stream</Button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-bottom: 80px;
  .banner-section {
    img {
      width: 100%;
    }
  }
  .line-pwd {
    text-align: center;
    margin: 24px 0px;
    font-size: 16px;
  }
  .action-root {
    text-align: center;
  }
`;

import React, { useEffect, useState } from "react";
import moment from "moment";
import copy from 'copy-to-clipboard';
import {
  Row, Col, Pagination, NoData,
  AutoComplete, Spin, Badge,
  Input, Select, Button,
  SuccessNotification,
} from "components/common";
import { uiVarLabel, uiVarList, uiVarOptions } from "common/uiVar";
import { varKey, varLabel, varIsIn, varValue } from "common/var";
import { getUser } from "utils/auth";
import { callGetApiWithAuth } from "utils/api";
import VideoModal from "./VideoModal";
import { Wrapper } from "./AffiliateStreamSubPage.styled";
import { PlayCircleOutlined } from "@ant-design/icons";
import DummyUserImage from "assets/images/user.svg";

export default function AffiliateStreamSubPage() {
  const [streamList, setStreamList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParam, setSearchParam] = useState({
    user_id: "",
    keyword: "",
    topic: "",
    lang: "",
    record_status: "",
  });
  const [paginationParam, setPaginationParam] = useState({
    currentPage: 1,
    perPage: 12,
    total: 0,
  });

  const onGetLiveStreams = ({ data }) => {
    setIsLoading(false);
    setStreamList(data.data);
    setPaginationParam({
      ...paginationParam,
      total: data.total,
    });
  };

  const onFailLiveStreams = () => {
    setIsLoading(false);
  };

  const loadLiveStreams = () => {
    let params = {
      ...searchParam,
      page: paginationParam.currentPage,
      per_page: paginationParam.perPage,
    };

    let query = Object.keys(params)
      .map((k) => k + "=" + params[k])
      .join("&");

    setIsLoading(true);
    callGetApiWithAuth(
      `live_stream/affiliate/list?${query}`,
      onGetLiveStreams,
      onFailLiveStreams
    );
  };

  const onPageChange = (page, pageSize) => {
    setPaginationParam({
      ...paginationParam,
      currentPage: page,
      perPage: pageSize,
    });
  };

  useEffect(() => {
    loadLiveStreams();
  }, [searchParam, paginationParam.currentPage, paginationParam.perPage]);

  return (
    <Wrapper>
      <div className="header-filters">
        <Row gutter={[24, 24]}>
          <HeaderFilter
            searchParam={searchParam}
            setSearchParam={setSearchParam}
          />
        </Row>
      </div>
      {isLoading ? (
        <Row gutter={[20, 20]}>
          <Col xs={24}>
            <Spin spinning={true} />
          </Col>
        </Row>
      ) : (
        <Row gutter={[20, 20]}>
          {streamList.length === 0 ? (
            <div className="no-stream">
              <NoData title="There are no items." />
            </div>
          ) : (
            streamList?.map((el, index) => (
              <Col
                xs={24}
                md={12}
                xl={8}
                xxl={6}
                className="responsive-payment-cards"
                key={index}
              >
                <StreamCard data={el} />
              </Col>
            ))
          )}
        </Row>
      )}
      {streamList.length > 0 && (
        <div className="header-pagination">
          <Row gutter={[20, 20]}>
            <Col xs={24}>
              <Pagination
                current={paginationParam.currentPage}
                pageSize={paginationParam.perPage}
                total={paginationParam.total}
                onChange={onPageChange}
                defaultPageSize="12"
                responsive
                showQuickJumper
                showSizeChanger
              />
            </Col>
          </Row>
        </div>
      )}
    </Wrapper>
  );
}

const StreamCard = ({ data }) => {
  const myUser = getUser();
  const [modalMode, setModalMode] = useState(undefined);
  const [hasVideo, setHasVideo] = useState(false);

  const handleCopyUrl = (url) => {
    copy(`${process.env.REACT_APP_LIVE_STREAM_URL}/${url}/${myUser.username}`)
    SuccessNotification("Successfully copied to clipboard");
  };

  const onClickTitle = () => {
    if (hasVideo) {
      setModalMode("video");
    }
  };

  useEffect(() => {
    if (
      varIsIn("stream.status", data.status, ["recorded"]) &&
      (data.video_link || data.vimeo_record_id)
    ) {
      setHasVideo(true);
    }
  }, [data]);

  return (
    <div className="payment-cards white-rounded-bg">
      <div
        className={`training-background ${hasVideo ? "has-video" : ""}`}
        style={{ backgroundImage: `url(${data.preview_image})` }}
        onClick={onClickTitle}
      ></div>
      <div
        className={`training-title ${hasVideo ? "has-video" : ""}`}
        onClick={onClickTitle}
      >
        {hasVideo ? <PlayCircleOutlined style={{ marginRight: 8 }} /> : ""}
        {data.title}
      </div>
      <div className="name-details-container">
        <div className="user-name">
          <img src={data.user.image || DummyUserImage} />
          <div className="name-position">
            <span>
              {data.user.first_name} {data.user.last_name}
            </span>
            <div>{uiVarLabel("liveStream.topics", data.topic)}</div>
          </div>
        </div>
        <div className="language-title">
          {uiVarLabel("liveStream.languages", data.lang)}
        </div>
      </div>
      <div className="start-row">
        <div className="start-value">
          {moment(new Date(data.start_at.replace(" ", "T"))).format("LL")}&nbsp;
        </div>
        <div className="start-value">
          {moment(new Date(data.start_at.replace(" ", "T"))).format("LT")}&nbsp;
          {
            uiVarList("timezones.list").filter(
              (item) => item.text === data.timezone
            )[0]?.abbr
          }
          &nbsp;{"-"}&nbsp;
          {moment(new Date(data.end_at.replace(" ", "T"))).format("LT")}&nbsp;
          {
            uiVarList("timezones.list").filter(
              (item) => item.text === data.timezone
            )[0]?.abbr
          }
        </div>
      </div>
      <div className="training-content">{data.description}</div>
      <div className="copy-container">
        <Row gutter={[10, 10]}>
          <Col span={18}>
            <div className="copy-url">
              {process.env.REACT_APP_LIVE_STREAM_URL}/{data.path}/
              {myUser.username}
            </div>
          </Col>
          <Col span={6}>
            <Button onClick={() => handleCopyUrl(data.path)}>Copy</Button>
          </Col>
        </Row>
      </div>
      {modalMode === "video" && (
        <VideoModal data={data} setModalMode={setModalMode} />
      )}
    </div>
  );
};

const HeaderFilter = (props) => {
  const [users, setUsers] = useState([]);
  const [formData, setFormData] = useState(props.searchParam);

  const onChangeSearchParam = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
    props.setSearchParam({
      ...props.searchParam,
      [field]: value,
    });
  };

  const onSearchDone = (res) => {
    setUsers(res.data);
  };

  const onSearchUser = (v) => {
    callGetApiWithAuth(`common/search_affiliate/${v}`, onSearchDone);
  };

  const onSelectUser = (_, option) => {
    let receiver = null;
    users.forEach((el) => {
      if (el.id == option.key) {
        receiver = el;
      }
    });
    onChangeSearchParam("user_id", receiver.id);
  };

  return (
    <>
      <Col xs={24} sm={8} xl={5}>
        <AutoComplete
          onSearch={onSearchUser}
          options={users.map((el) => ({
            key: el.id,
            label: `${el.first_name} ${el.last_name}`,
            value: `${el.first_name} ${el.last_name}`,
          }))}
          style={{ width: "100%" }}
          onSelect={onSelectUser}
          label="Username or User ID"
        />
      </Col>
      <Col xs={24} sm={8} xl={5}>
        <Input
          label="Title"
          value={formData.keyword}
          onChange={(e) => onChangeSearchParam("keyword", e.target.value)}
        />
      </Col>
      <Col xs={24} sm={8} xl={5}>
        <Select
          value={formData.topic}
          label="All Topics"
          options={[
            {
              label: "All Topics",
              value: "",
              key: "",
            },
            ...uiVarOptions("liveStream.topics"),
          ]}
          onChange={(value) => onChangeSearchParam("topic", value)}
          style={{ width: "100%" }}
        />
      </Col>
      <Col xs={24} sm={8} xl={5}>
        <Select
          label="All Languages"
          value={formData.lang}
          options={[
            {
              label: "All Languages",
              value: "",
              key: "",
            },
            ...uiVarOptions("liveStream.languages"),
          ]}
          onChange={(value) => onChangeSearchParam("lang", value)}
          style={{ width: "100%" }}
        />
      </Col>
      <Col xs={24} sm={8} xl={4}>
        <Select
          label="All Statuses"
          value={formData.record_status}
          options={[
            { label: "All Statuses", value: "" },
            {
              label: "Scheduled",
              value: varValue("stream.recordStatus", "scheduled"),
            },
            {
              label: "Recorded",
              value: varValue("stream.recordStatus", "recorded"),
            },
          ]}
          onChange={(value) => onChangeSearchParam("record_status", value)}
          style={{ width: "100%" }}
        />
      </Col>
    </>
  );
};

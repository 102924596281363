import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux'
import styled from "styled-components";
import { 
  Row, Col, Modal, 
  LiquidPlot, Progress, Spin 
} from "components/common";
import { asVolume } from 'utils/text'
import { callGetApiWithAuth } from "utils/api";

export default function QualModal(props) {
  const [isLoaded, setIsLoaded] = useState(false)
  const [statData, setStatData] = useState({
    rank: { name: 'Unranked' },
    next_rank: { name: 'Director' },
    has_qv_limit: false, 
    pv: 0, next_pv: 40, pv_percent: 0,
    pecv: 0, next_pecv: 80, pecv_percent: 0,
    pe: 0, next_pe: 0, pe_percent: 0,
    qv: 0, next_qv: 0, qv_percent: 0,
    total_percent: 0
  })

  const onGetStatData = (res) => {
    setStatData(res.data)
    setIsLoaded(true)
  }
  const onFailStatData = () => {
    setIsLoaded(true)
  }

  useEffect(() => {
    setIsLoaded(false)
    callGetApiWithAuth(`report/rank_report/rank_stats?user_id=${props.selectedQual.id}`, onGetStatData, onFailStatData)
  }, [])

  return (
    <Modal title={'Rank Qualification'}
      onCancel={() => props.setSelectedQual(undefined)}
      width={800}
    >
      <Wrapper>
        {isLoaded?
          <Row gutter={[15, 15]}>
            <Col span={24} className="responsive-rank">
              <div className="rank-container">
                <Row gutter={[15, 15]}>
                  <Col xs={24} sm={12}>
                    <div className="rank-label">Current Rank</div>
                    <div className="rank-badge">
                      {statData.rank.badge && 
                        <img src={statData.rank.badge} />
                      }
                      {statData.rank.name}
                    </div>
                  </Col>
                  <Col xs={24} sm={12}>
                    <div className="rank-label">Next Rank</div>
                    <div className="rank-badge">
                      {statData.next_rank.badge && 
                        <img src={statData.next_rank.badge} />
                      }
                      {statData.next_rank.name}
                    </div>
                  </Col>
                  <Col xs={24} sm={12}>
                    <div className="rank-progress-container">
                      <div className="progress-label-container">
                        <div className="label">Personal Volume</div>
                        <div className="value">({asVolume(statData.pv)}/{asVolume(statData.next_pv)})</div>
                      </div>
                      <div className="rank-progress">
                        <Progress
                          percent={100*statData.pv_percent}
                          showInfo={false}
                          strokeColor="#08B86E"
                          strokeWidth={10}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} sm={12}>
                    <div className="rank-progress-container">
                      <div className="progress-label-container">
                        <div className="label">Personal Customer Volume</div>
                        <div className="value">({asVolume(statData.pecv)}/{asVolume(statData.next_pecv)})</div>
                      </div>
                      <div className="rank-progress">
                        <Progress
                          percent={100*statData.pecv_percent}
                          showInfo={false}
                          strokeColor="#08B86E"
                          strokeWidth={10}
                        />
                      </div>
                    </div>
                  </Col>
                  {statData.has_qv_limit?
                  <Col xs={24}>
                    <div className="rank-progress-container">
                      <div className="progress-label-container">
                        <div className="label">Personally Enrolled</div>
                        <div className="value">({statData.pe}/{statData.next_pe})</div>
                      </div>
                      <div className="rank-progress">
                        <Progress
                          percent={100*statData.pe_percent}
                          showInfo={false}
                          strokeColor="#08B86E"
                          strokeWidth={10}
                        />
                      </div>
                    </div>
                  </Col>
                  : ''}
                  {statData.has_qv_limit?
                  <Col xs={24}>
                    <div className="rank-progress-container">
                      <div className="progress-label-container">
                        <div className="label">Qualified Rank Volume</div>
                        <div className="value">({asVolume(statData.qv)}/{asVolume(statData.next_qv)})</div>
                      </div>
                      <div className="rank-progress">
                        <Progress
                          percent={100*statData.qv_percent}
                          showInfo={false}
                          strokeColor="#FFC427"
                          strokeWidth={10}
                        />
                      </div>
                    </div>
                  </Col>
                  : '' }
                </Row>
              </div>
            </Col>
          </Row>
        : 
          <div style={{height: 300}}>
            <Spin spinning={true} className="loading-icon" /> 
          </div>
        }
      </Wrapper>
    </Modal>
  );
}

const Wrapper = styled.div`
  .liquid-container > div {
    height: 240px;
  }
  .liquid-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .rank-container {
    height: 100%;
    .rank-label {
      font-weight: normal;
      font-size: 14px;
      margin-bottom: 5px;
      color: #7f8fa3;
    }
    .rank-badge {
      font-weight: 600;
      font-size: 22px;
      color: #00b4ee;
      display: flex;
      align-items: center;
      img {
        width: 22px;
        height: 22px;
        object-fit: cover;
        margin-right: 8px;
      }
    }
  }
  .rank-progress-container {
    margin-top: 20px;
    .progress-label-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .label {
        font-weight: normal;
        font-size: 14px;
        color: #7f8fa3;
      }
      .value {
        font-weight: 600;
        font-size: 14px;
        color: #00b4ee;
      }
    }
    .rank-progress {
      .ant-progress-inner,
      .ant-progress-bg {
        border-radius: 0px;
      }
    }
  }
  @media (max-width: 1130px) {
    .responsive-rank {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
    .rank-container {
      padding-left: 0px;
      border-left: none;
    }
  }
`;


import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import styled, { createGlobalStyle } from 'styled-components'
import { callGetApiWithAuth, callPostApiWithAuth } from 'utils/api'
import { Modal, SuccessNotification, FireworksConfetti } from 'components/common'
import BgImg from 'assets/images/rankupWheel/modal-bg.png'
import PanelTop1Img from 'assets/images/rankupWheel/panel-top1.png'
import PanelTop2Img from 'assets/images/rankupWheel/panel-top2.png'
import CloseImg from 'assets/images/rankupWheel/close-btn.png'
import StartBtnImg from 'assets/images/rankupWheel/start-btn.png'
import Panel1kImg from 'assets/images/rankupWheel/panel-1k.png'
import Panel5kImg from 'assets/images/rankupWheel/panel-5k.png'
import Panel10kImg from 'assets/images/rankupWheel/panel-10k.png'
import Panel25kImg from 'assets/images/rankupWheel/panel-25k.png'
import Panel50kImg from 'assets/images/rankupWheel/panel-50k.png'
import Panel100kImg from 'assets/images/rankupWheel/panel-100k.png'
import Panel250kImg from 'assets/images/rankupWheel/panel-250k.png'
import Panel500kImg from 'assets/images/rankupWheel/panel-500k.png'
import PanelSuperImg from 'assets/images/rankupWheel/panel-super.png'
import PanelMasterImg from 'assets/images/rankupWheel/panel-master.png'

export default function RankupWheelModal(props) {
  const history = useHistory()
  const dispatch = useDispatch()
  const [isShow, setIsShow] = useState(false)
  const [rank, setRank] = useState(undefined)
  const [listData, setListData] = useState([])
  const [isFiAnim, setIsFiAnim] = useState(false)
  const [isStAnim, setIsStAnim] = useState(false)

  const onCheckDone = (res) => {
    if (res.data.bonus_able) {
      setIsShow(true)
      setRank(res.data.rank)
      const listData0 = res.data.list
      setListData(listData0)
      setIsFiAnim(true)
      setTimeout(() => {
        setIsFiAnim(false)
      }, [5000])
    }
  }

  const onUpdateDone = (res) => {
    SuccessNotification(res.message)
    setIsFiAnim(true)
    dispatch({
      type: 'auth.RELOAD'
    })
    setTimeout(() => {
      setIsShow(false)
      setIsFiAnim(false)
      if (res.data.type === 'loyalty_wallet') {
        history.push('/my_account/loyalty_wallet')
      } else if (res.data.type === 'tgift_wallet') {
        history.push('/hlt')
      }
    }, [3000])
  }
  const onUpdateFail = () => {
    setTimeout(() => {
      setIsShow(false)
    }, [500])
  }
  const handleSubmit = (ind) => {
    const body = {
      rank_id: rank.id,
      amount: listData[ind].amount,
      comment: listData[ind].comment,
      type: listData[ind].type,
    }
    setTimeout(() => {
      callPostApiWithAuth('home/rankup_wheel/update_bonus', body, 
        onUpdateDone, onUpdateFail
      )
    }, [5000])
  }

  useEffect(() => {
    callGetApiWithAuth(`home/rankup_wheel/check_able`, onCheckDone)
  }, [])

  return (
    <>
      {isShow && 
        <Modal width={622}
          onCancel={() => setIsShow(false)}
          wrapClassName={'wheel-modal'}
          closeIcon={(
            !isStAnim?
              <img src={CloseImg} alt={'Close'} />
            : <span/>
          )}
        >
          <WheelCanvas list={listData} 
            onSelect={handleSubmit}
            rank={rank}
            isStAnim={isStAnim}
            setIsStAnim={setIsStAnim}
          />
        </Modal>
      }
      <FireworksConfetti start={isFiAnim} />
    </>
  )
}

const WheelCanvas = (props) => {
  const [curIdx, setCurIdx] = useState(null)

  const handleSelect = (curIdx0) => {
    if (curIdx0 === null) {
      const curIdx2 = Math.floor(Math.random() * props.list.length);
      setCurIdx(curIdx2)
      props.onSelect(curIdx2)
    } else {
      // setCurIdx(null)
      // setTimeout(() => handleSelect(null), 500);
    }
  }

  useEffect(() => {
    props.setIsStAnim(true)
    setTimeout(() => {
      props.setIsStAnim(false)
    }, [5500])
  }, [])

  return (
    <Wrapper>
      <GlobalStyle />
      <div className='heading'>
        {props.isStAnim?
          <div className={`congrat-text`}>
            <span className={`animate-text`}>
              <div>
                <span className='anim-1'>C</span>
                <span className='anim-2'>o</span>
                <span className='anim-3'>n</span>
                <span className='anim-4'>g</span>
                <span className='anim-5'>r</span>
                <span className='anim-6'>a</span>
                <span className='anim-7'>t</span>
                <span className='anim-8'>u</span>
                <span className='anim-9'>l</span>
                <span className='anim-10'>a</span>
                <span className='anim-11'>t</span>
                <span className='anim-12'>i</span>
                <span className='anim-13'>o</span>
                <span className='anim-14'>n</span>
                <span className='anim-15'>s</span>
              </div>&nbsp;
              <div>
                <span className='anim-16'>o</span>
                <span className='anim-17'>n</span>
              </div>&nbsp;
              <div>
                <span className='anim-18'>a</span>
                <span className='anim-19'>c</span>
                <span className='anim-20'>h</span>
                <span className='anim-21'>i</span>
                <span className='anim-22'>e</span>
                <span className='anim-23'>v</span>
                <span className='anim-24'>i</span>
                <span className='anim-25'>n</span>
                <span className='anim-26'>g</span>
              </div>
            </span>
          </div>
        : 
          <div className={`congrat-text`}>
            <p>
              Congratulations on achieving
            </p>
          </div>
        }
        {!props.isStAnim && 
          <div className="rankt-root">
            <div className="diamond">
            </div>
            <img src={props.rank?.badge} 
              className={'rank-badge'}
            />
            {/* <div className="sparkles">
              <div className="one">✨</div>
              <div className="two">✨</div>
            </div> */}
          </div>
        }
      </div>
      {!props.isStAnim && 
        <div className="wheel-container">
          <div className='wheel-top2' />
          <div className={`wheel ${curIdx !== null ? 'spinning' : ''} rank-${props.rank?.id}`} 
            style={{
              '--nb-item': props.list.length,
              '--selected-item': curIdx,
            }} 
          >
            {props.list.map((item, index) => (
              <div className="wheel-item" 
                key={index} 
                style={{ '--item-nb': index - 2 }}
              >
                &nbsp;
              </div>
            ))}
          </div>
          <div className='wheel-top1' />
        </div>
      }
      {props.isStAnim && 
        <div className='badge-main-root'>
          <div className='badge-main'>
            <img src={props.rank?.badge} />
            {/* <div className="sparkles">
              <div className="one">✨</div>
              <div className="two">✨</div>
            </div> */}
          </div>
        </div>
      }
      <div className='action-root'>
        <img src={StartBtnImg}
          onClick={() => handleSelect(curIdx)}
          alt={'Click to Start'}
          style={{
            visibility: props.isStAnim?'hidden':'visible'
          }}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-image: url("${BgImg}");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 15px;
  overflow: hidden;
  -webkit-box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.25);
  box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.25);
  .heading {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 20px 40px 20px;
    .rank-badge {
      width: 60px;
      margin-left: 12px;
    }
  }
  .congrat-text {
    font-family: 'Gotham Black';
    font-weight: 900;
    font-style: italic;
    font-size: 24px;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    p {
      margin-bottom: 0;
    }
  }
  .animate-text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    span {
      opacity: 0;
      transform: translate(-150px, 0) scale(.3);
      animation: animLeftRight 1.25s forwards;
      display: inline-block;
      &.anim-1 {
        animation-delay: .05s;
      }
      &.anim-2 {
        animation-delay: .1s;
      }
      &.anim-3 {
        animation-delay: .15s;
      }
      &.anim-4 {
        animation-delay: .2s;
      }
      &.anim-5 {
        animation-delay: .25s;
      }
      &.anim-6 {
        animation-delay: .3s;
      }
      &.anim-7 {
        animation-delay: .35s;
      }
      &.anim-8 {
        animation-delay: .4s;
      }
      &.anim-9 {
        animation-delay: .45s;
      }
      &.anim-10 {
        animation-delay: .5s;
      }
      &.anim-11 {
        animation-delay: .55s;
      }
      &.anim-12 {
        animation-delay: .6s;
      }
      &.anim-13 {
        animation-delay: .65s;
      }
      &.anim-14 {
        animation-delay: .7s;
      }
      &.anim-15 {
        animation-delay: .75s;
      }
      &.anim-16 {
        animation-delay: .8s;
      }
      &.anim-17 {
        animation-delay: .85s;
      }
      &.anim-18 {
        animation-delay: .9s;
      }
      &.anim-19 {
        animation-delay: .95s;
      }
      &.anim-20 {
        animation-delay: 1s;
      }
      &.anim-21 {
        animation-delay: 1.05s;
      }
      &.anim-22 {
        animation-delay: 1.1s;
      }
      &.anim-23 {
        animation-delay: 1.15s;
      }
      &.anim-24 {
        animation-delay: 1.2s;
      }
      &.anim-25 {
        animation-delay: 1.25s;
      }
      &.anim-26 {
        animation-delay: 1.3s;
      }
    }
  }
  .wheel-container {
    display: block;
    position: relative;
    box-sizing: content-box;
    width: calc(var(--wheel-size) + 2 * var(--wheel-border-size));
    height: calc(var(--wheel-size) + 2 * var(--wheel-border-size));
    padding: 3px;
    margin: auto;
    background-color: var(--neutral-color);
    border-radius: 50%;
    user-select: none;
  }

  .wheel-container::before,
  .wheel-container::after {
    content: '';
    display: block;
    position: absolute;
    height: 0;
    width: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    // border: solid transparent 20px;
    border-left-width: 0;
  }

  .wheel-container::before {
    // right: 0px;
    // border-right-color: var(--wheel-color);
  }

  .wheel-container::after {
    right: -5px;
    border-right-color: var(--neutral-color);
  }

  .wheel {
    display: block;
    position: relative;
    box-sizing: content-box;
    margin: auto;
    width: var(--wheel-size);
    height: var(--wheel-size);
    overflow: hidden;
    border-radius: 50%;
    // border: solid var(--wheel-color) var(--wheel-border-size);
    background-color: var(--wheel-color);
    transition: transform var(--reset-duration);
    transform: rotate(0deg);
    background-size: cover;
    background-position: center center;
    -webkit-box-shadow: 0px 4px 45px 25px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 4px 45px 25px rgba(0,0,0,0.25);
    box-shadow: 0px 4px 45px 25px rgba(0,0,0,0.25);
    border-radius: 50%;
    &.rank-5 {
      background-image: url("${Panel1kImg}");
    }
    &.rank-6 {
      background-image: url("${Panel5kImg}");
    }
    &.rank-7 {
      background-image: url("${Panel10kImg}");
    }
    &.rank-8 {
      background-image: url("${Panel25kImg}");
    }
    &.rank-9 {
      background-image: url("${Panel50kImg}");
    }
    &.rank-10 {
      background-image: url("${Panel100kImg}");
    }
    &.rank-11 {
      background-image: url("${Panel250kImg}");
    }
    &.rank-12 {
      background-image: url("${Panel500kImg}");
    }
    &.rank-13 {
      background-image: url("${PanelSuperImg}");
    }
    &.rank-14 {
      background-image: url("${PanelMasterImg}");
    }
  }

  .wheel.spinning {
    transition: transform var(--spinning-duration);
    transform: rotate(calc(var(--nb-turn) * 360deg + (-360deg * var(--selected-item) / var(--nb-item, 1))));
  }

  .wheel::after {
    display: block;
    position: absolute;
    content: '';
    width: 34px;
    height: 58px;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }

  .wheel-item {
    display: block;
    position: absolute;
    box-sizing: border-box;

    top: 50%;
    left: 50%;
    width: 50%;
    transform-origin: center left;
    transform: translateY(-50%) rotate(calc(var(--item-nb) * (360deg / var(--nb-item, 1))));

    color: var(--neutral-color);
    text-align: right;
    padding: 0 25px 0 50px;
    font-family: var(--wheel-font);
  }

  .wheel-item:before {
    content: ' ';
    display: block;
    position: absolute;
    box-sizing: border-box;
    z-index: -1;
    width: 0;
    height: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-left: 0px;
    opacity: 0.25;

    --slice-max-width: calc(var(--PI) * var(--wheel-size) + var(--wheel-size) / 2);
    --slice-width: calc((var(--slice-max-width) / var(--nb-item)) - var(--wheel-slice-spacing));
    // border: solid transparent calc(var(--slice-width) / 2);
    border-left: solid transparent 0;
    border-right: solid var(--neutral-color) calc(var(--wheel-size) / 2);
  }
  .wheel-top1 {
    width: 120px;
    height: 80px;
    background-image: url("${PanelTop1Img}");
    background-size: cover;
    background-position: center center;
    position: absolute;
    left: calc(50% - 60px);
    top: -15px;
  }
  .wheel-top2 {
    width: 122px;
    height: 36px;
    background-image: url("${PanelTop2Img}");
    background-size: cover;
    background-position: center center;
    position: absolute;
    left: calc(50% - 61px);
    top: -5px;
  }
  .badge-main-root {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .badge-main {
      position: relative;
      img {
        max-width: 360px;
        width: 100%;
      }
      .sparkles {
        z-index:2;
        position: absolute;
        right: 0;
        top: 0;
        .one {
          position: absolute;
          font-size: 70px;
          color: transparent;
          text-shadow: -28px 286px white,-360px -20px white;
          animation: animShine .5s linear infinite;
        }
        .two {
          position: absolute;
          color: transparent;
          font-size: 55px;
          left:-65px;
          top:45px;
          text-shadow: -259px 243px white,11px -55px white;
          animation: animShine .5s linear infinite .25s;
        }
      }
    }
    
  }
  
  .rankt-root {
    position: relative;
  }
  .diamond {
    position: relative;
    left:-100px;
    top:-50px;
    filter: drop-shadow(0 0 100px white);
  }  
  .sparkles {
    z-index:2;
    position: absolute;
    .one {
      position: absolute;
      font-size: 40px;
      color: transparent;
      text-shadow: 53px -19px white,-10px -89px white;
      animation: animShine .5s linear infinite;
    }
    .two {
      position: absolute;
      color: transparent;
      font-size: 25px;
      left:-65px;
      top:45px;
      text-shadow: 71px -75px white,109px -124px white;
      animation: animShine .5s linear infinite .25s;
    }
  }
  
  .action-root {
    display: flex;
    justify-content: center;
    padding: 10px 0 30px 0;
    img {
      cursor: pointer;
      max-width: 80%;
      -webkit-box-shadow: 0px 10px 15px 0px rgba(0,0,0,0.25);
      -moz-box-shadow: 0px 10px 15px 0px rgba(0,0,0,0.25);
      box-shadow: 0px 10px 15px 0px rgba(0,0,0,0.25);
      border-radius: 32px;
    }
  }
`
const GlobalStyle = createGlobalStyle`
  :root {
    --wheel-font: 'Lato', 'Quicksand', sans-serif;
    --wheel-size: 400px;
    @media (max-width: 600px) {
      --wheel-size: 320px;
    }
    --wheel-slice-spacing: 0px;
    --wheel-border-size: 5px;
    --wheel-color: transparent;
    --neutral-color: transparent;
    --PI: 3.14159265358979;
    --nb-item: 0;
    --item-nb: 0;
    --selected-item: 0;
    --nb-turn: 5;
    --spinning-duration: 4s;
    --reset-duration: 0.25s;
  }
  .wheel-modal {
    .ant-modal-body {
      padding: 0;
    }
    .ant-modal-content {
      background-color: transparent;
      box-shadow: none;
    }
  }
  @keyframes animLeftRight {
    40% {
      transform: translate(50px, 0) scale(.7);
      opacity: 1;
      color: #00b4ee;
    }
  
    60% {
      color: #00b4ee;
    }
  
    80% {
      transform: translate(0) scale(2);
      opacity: 0;
    }
  
    100% {
      transform: translate(0) scale(1);
      opacity: 1;
    }
  }
  @keyframes animShine {
    from {opacity:1;}
    to {opacity:0;}
  }
`;

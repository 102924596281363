import React, { useEffect, useState } from "react";
import { Row, Col, Spin, NoData } from "components/common";
import { Wrapper } from "./TrainingPage.styled";
import { callGetApiWithAuth } from "utils/api";
import GroupsCard from "./groupsCard/GroupsCard";
import TopCard from "./topCard/TopCard";

export default function IndexPage() {
  const [isLoadingTrainingData, setIsLoadingTrainingData] = useState(false);
  const [featureData, setFeatureData] = useState(undefined);
  const [groupData, setGroupData] = useState([]);

  // Featured Data

  const onGetSuccessFeaturedData = ({ data }) => {
    setIsLoadingTrainingData(false);
    setFeatureData(data);
  };

  const onGetFailedFeaturedData = () => {
    setIsLoadingTrainingData(false);
  };

  const loadFeaturedData = () => {
    setIsLoadingTrainingData(true);
    callGetApiWithAuth(
      `training_video/featured`,
      onGetSuccessFeaturedData,
      onGetFailedFeaturedData
    );
  };

  // Group Data

  const onGetSuccessGroupData = ({ data }) => {
    setIsLoadingTrainingData(false);
    setGroupData(data);
  };

  const onGetFailedGroupData = () => {
    setIsLoadingTrainingData(false);
  };

  const loadGroupData = () => {
    setIsLoadingTrainingData(true);
    callGetApiWithAuth(
      `training_video/groups`,
      onGetSuccessGroupData,
      onGetFailedGroupData
    );
  };

  useEffect(() => {
    loadGroupData();
    loadFeaturedData();
  }, []);

  return (
    <Wrapper isLoadingTrainingData>
      <Row gutter={[24, 24]}>
        {featureData && (
          <Col span={24}>
            <TopCard data={featureData} recommended />
          </Col>
        )}

        {isLoadingTrainingData ? (
          <Spin spinning={true} className="loading-spin" />
        ) : groupData && groupData.length > 0 ? (
          groupData?.map((el) => {
            return (
              <Col xs={24} md={12} xl={8} xxl={6}>
                <GroupsCard data={el} />
              </Col>
            );
          })
        ) : (
          <NoData title="There is no data." />
        )}
      </Row>
    </Wrapper>
  );
}

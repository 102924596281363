import styled from "styled-components";
import BgImg from "assets/images/live_stream/lvs_bg.png";

export const Wrapper = styled.div`
  margin-top: 20px;
  .lvs-logo-title-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .logo-section {
      img {
        width: 150px;
      }
    }
    .title-section {
      h5 {
        font-weight: 600;
        margin-top: 10px;
      }
    }
  }
  .lvs-video-container {
    img {
      width: 100%;
      cursor: pointer;
    }
  }
  .description-container {
    margin-top: 24px;
  }
  .description-card {
    height: 100%;
    border-radius: 8px;
    .title-section {
      padding: 28px;
      display: flex;
      flex-direction: row;
      align-items: center;
      svg {
        width: 30px;
      }
      h4 {
        margin: 0 0 0 10px;
        font-weight: 600;
      }
    }
    .detail-section {
      padding: 0px 28px 28px 28px;
      ul {
        padding-left: 20px;
        li {
          margin-bottom: 4px;
        }
      }
    }
  }
  .tips-card {
    background: linear-gradient(0deg, #d8fbde, #d8fbde), #cafdf5;
    .title-section {
      h4 {
        color: #0a5554;
      }
    }
    .detail-section {
      color: #0a5554;
    }
  }
  .prohibited-card {
    background: #ffe9d5;
    .title-section {
      h4 {
        color: #7a0916;
      }
    }
    .detail-section {
      color: #7a0916;
    }
  }
  .footer-description {
    margin-top: 24px;
  }
  @media only screen and (max-width: 767px) {
    .lvs-logo-title-container {
      padding-left: 0;
    }
    .description-container {
      margin-top: 50px;
    }
  }
  @media only screen and (max-width: 575px) {
    padding: 20px;
  }
`;

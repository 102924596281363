import styled from "styled-components";

export const Wrapper = styled.div`
  padding-bottom: 12px;
  .container {
    width: 180px;
    height: 229px;
    background: #fff;
    border: 1px solid #f5f0f0 !important;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 12;
    margin: 0 0;
    position: relative;
    border-color: #fff;
    border-width: 0 1px 1px 1px;
    border-radius: 18px;
    .chat-icon {
      color: #00b8d9;
      margin-top: 13px;
      margin-left: 5px;
    }
  }
  .container.hasSummary {
    height: 240px;
  }
  .container.isSpillOver {
    background-color: #fff;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2);
    border-radius: 18px;
  }
  .emptyContainer {
    width: 110px;
    height: 170px;
    background: transparent;
    border: 1px dashed rgba(53, 64, 82, 0.25);
    box-sizing: border-box;
    border-radius: 18px;
    z-index: 12;
    margin: 0 0;
    overflow: hidden;
  }
  .coloredTopBorder {
    position: absolute;
    width: 100%;
    height: 60px;
    left: 0;
    top: 0;
    border-radius: 18px 18px 0 0;
    background-color: ${(props) => props.theme.palette.primary};
  }
  .coloredTopBorderForCustomer {
    background-color: ${(props) => props.theme.palette.secondary};
  }
  .showMoreButton {
    width: 20px;
    height: 14px;
    position: absolute;
    bottom: -30px;
    left: calc(50% - 10px);
    cursor: pointer;
    filter: invert(44%) sepia(57%) saturate(2818%) hue-rotate(165deg)
      brightness(93%) contrast(96%);
  }
  .searchBackButton {
    width: 20px;
    height: 20px;
    position: absolute;
    top: -30px;
    left: calc(50% - 10px);
    cursor: pointer;
  }
  .photoWrapper {
    cursor: pointer;
    background-color: white;
    width: 70px;
    height: 70px;
    bottom: -10px;
    border-radius: 50%;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  .statusBadge {
    position: absolute;
    right: 4px;
    bottom: 8px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #bfbfbf;
    &.active {
      background-color: #52c41a;
    }
    &.hold {
      background-color: #fa8c16;
    }
    &.inactive {
      background-color: #f5222d;
    }
  }
  .userRow {
    font-size: 16px;
    max-width: 110px;
    color: #212b36;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    margin: 20px 0px 0px 0px;
  }
  .userid {
    font-size: 12px;
    color: #637381;
    font-size: 14px;
    margin-bottom: 0px;
  }
  .aboutInfo {
    border-top: 1px dashed rgba(145, 158, 171, 0.24);
    color: #637381;
    font-size: 14px;
    margin-top: 20px;
    padding-top: 10px;
    width: 100%;
    cursor: pointer;
  }
  .rankTag {
    min-width: 100% !important;
    padding: 2px 5px;
  }
  .summarySection {
    width: 100%;
    background: white;
    padding: 4px;
    margin-top: 4px;
  }
  .summaryRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 11px;
    margin: 0;
  }
  .bottomLinkRoot {
    position: absolute;
    bottom: -28px;
    width: calc(100% + 50px);
  }
  .bottomLink {
    font-size: 10px;
    cursor: pointer;
    color: #00b2ff;
  }
  .qualRow {
    font-size: 9px;
    cursor: pointer;
    color: ${(props) => props.theme.palette.link};
    margin-bottom: 0;
  }
  .socialMessenger {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .messengerIcon {
      margin: 0 4px;
      color: ${(props) => props.theme.palette.primary};
    }
  }
  .no-rank-qualification,
  .no-show-rank {
    visibility: hidden;
  }
`;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col } from "components/common";
import { asUsdPrice } from "utils/text";
import { callGetApiWithAuth } from "utils/api";
import { Wrapper } from "./BalanceSubPage.styled";
import { ReactComponent as Wallet3Icon } from "assets/icons/wallet-3.svg";
import { ReactComponent as Wallet4Icon } from "assets/icons/wallet-4.svg";

export default function BalanceSubPage() {
  const myUser = useSelector((state) => state.auth.user);
  const [statData, setStatData] = useState({
    bonus_amount: 0,
  })

  const onGetCurrentData = ({ data }) => {
    setStatData({
      ...statData,
      ...data
    })
  }

  useEffect(() => {
    callGetApiWithAuth(`my_account/loyalty_wallet/current_level`, 
      onGetCurrentData
    )
  }, [])

  return (
    <Wrapper>
      <Row gutter={[20, 20]}>
        <Col xs={24} lg={12} xl={9} xxl={6}>
          <div className="balance-stats">
            <div className="stats-icon">
              <div className="stats-value">
                {asUsdPrice(myUser.loyalty_wallet.amount)}
              </div>
              <div className="stats-title">Current Balance</div>
            </div>
            <Wallet4Icon />
          </div>
        </Col>
        <Col xs={24} lg={12} xl={9} xxl={6}>
          <div className="balance-stats">
            <div className="stats-icon">
              <div className="stats-value">
                {asUsdPrice(myUser.loyalty_wallet.consumed_amount)}
              </div>
              <div className="stats-title">Total Dollars Redeemed</div>
            </div>
            <Wallet3Icon />
          </div>
        </Col>
        <Col xs={24} lg={12} xl={9} xxl={6}>
          <div className="balance-stats">
            <div className="stats-icon">
              <div className="stats-value">
                {asUsdPrice(statData.bonus_amount)}
              </div>
              <div className="stats-title">Current Level</div>
            </div>
            <Wallet3Icon />
          </div>
        </Col>
      </Row>
    </Wrapper>
  );
}

import { useEffect, useState } from "react";
import { Card, Spin } from "components/common";
import ArrowUp from "assets/icons/arrow_up_black.svg";
import ArrowDown from "assets/icons/arrow_down_black.svg";
import { callGetApiWithAuth } from "utils/api";
import styled from "styled-components";

export default function GiftFaqsSection() {
  const [isLoading, setIsLoading] = useState(false);
  const [faqs, setFaqs] = useState([]);

  const onGetSuccessFaqs = ({ data }) => {
    setIsLoading(false);
    setFaqs(data && data["Gift Wallet"] ? data["Gift Wallet"] : []);
  };

  const onGetFailedFaqs = () => {
    setIsLoading(false);
  };

  const loadFaqs = () => {
    setIsLoading(true);
    callGetApiWithAuth(`faqs?is_gift=1`, onGetSuccessFaqs, onGetFailedFaqs);
  };

  useEffect(() => {
    loadFaqs();
  }, []);

  return (
    <Wrapper>
      <div className="row align-items-center justify-content-center">
        <div className="col-12">
          <h4 className="inner-h3">Gift Wallet FAQ</h4>
        </div>
        {faqs.map((el, index) => (
          <div className="col-12" key={index}>
            <div className="faq">
              <div
                className={`faq-title ${index === 0 ? "" : "collapsed"}`}
                data-bs-toggle="collapse"
                href={`#faqCollapse${index}`}
                role="button"
                aria-expanded="false"
                aria-controls={`faqCollapse${index}`}
              >
                <h5>{el.title}</h5>
              </div>
              <div
                className={`faq-content collapse ${
                  index === 0 ? "active show" : ""
                }`}
                id={`faqCollapse${index}`}
              >
                <span dangerouslySetInnerHTML={{ __html: el.content }} />
              </div>
            </div>
          </div>
        ))}
      </div>
      {isLoading && <Spin spinning={true} />}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  box-shadow: 0px 0px 2px rgb(145 158 171 / 20%),
    0px 12px 24px -4px rgb(145 158 171 / 12%);
  border-radius: 16px;
  padding: 24px;
  h4 {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #212b36;
    margin-bottom: 24px;
  }

  h5 {
    margin-bottom: 0;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.7);
    font-weight: 600;
  }

  .faq-title.collapsed h5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #212b36;
  }

  .faq-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border: unset;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #212b36;
    background: #f4f6f8;
    border-radius: 8px 8px 0px 0px;
    h5 {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #212b36;
    }
  }

  .faq-content {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #212b36;
    padding: 16px;
    p {
      margin: 0px;
    }
  }

  .faq-title.collapsed {
    background: white;
    border-radius: 0px;
    border-top: 1px solid rgba(145, 158, 171, 0.24);
  }

  .faq-title:after {
    content: "";
    background: url(${ArrowUp});
    width: 10px;
    height: 10px;
    background-size: 100%;
    background-repeat: no-repeat;
    float: right;
  }

  .faq-title.collapsed:after {
    content: "";
    background: url(${ArrowDown});
    background-size: 100%;
    background-repeat: no-repeat;
    width: 10px;
    height: 10px;
  }
  .faq-content.show {
    background: #f4f6f8;
    border-radius: 0px 0px 8px 8px;
    margin-bottom: 16px;
  }
  .faq {
    padding: 0;
  }

  .faqs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

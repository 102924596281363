import React, { useState } from "react";
import { Button, VideoModal } from "components/common";
import { ReactComponent as PlayIcon } from "assets/icons/play.svg";
import styled from "styled-components";

export default function CardView({ data }) {
  const [isPlaying, setIsPlaying] = useState(false);

  const handleWatchVideo = () => {
    setIsPlaying(true);
  };

  const handleDownload = () => {
    setIsPlaying(false);
    window.open(data.file, "_blank");
  };

  return (
    <Wrapper>
      <div className="white-rounded-bg training-cards-container">
        <div>
          <div className="training-background">
            <img src={data.thumbnail} alt={"Market Asset Video"} />
            {data.file_type * 1 === 4 && (
              <PlayIcon onClick={() => setIsPlaying(true)} />
            )}
          </div>
          <div className="training-title">{data.title}</div>
          <div className="training-content">{data.description}</div>
        </div>
        <div className="watch-now-container">
          <Button
            onClick={
              data.file_type * 1 === 4 ? handleWatchVideo : handleDownload
            }
          >
            {data.file_type * 1 === 4 ? "Watch Now" : "Download"}
          </Button>
        </div>
      </div>
      {data.file_type * 1 === 4 && isPlaying && (
        <VideoModal
          onCancel={() => setIsPlaying(false)}
          src={data.file}
          width={1200}
          isVimeo
        />
      )}
    </Wrapper>
  );
}

export const Wrapper = styled.div`
  height: 100%;
  .training-cards-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    height: 100%;
    .watch-now-container {
      button {
        width: 100%;
      }
    }
    .training-background {
      height: 202px;
      position: relative;
      svg {
        opacity: 0.5;
        position: absolute;
        left: calc(50% - 27px);
        top: calc(50% - 27px);
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }
    }
    .training-title {
      font-weight: 600;
      font-size: 16px;
      margin-top: 24px;
      color: #212b36;
      overflow: hidden;
      white-space: nowrap !important;
      text-overflow: ellipsis;
    }
    .training-content {
      font-size: 14px;
      line-height: 22px;
      color: #637381;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 6;
      overflow: hidden;
    }
  }
`;

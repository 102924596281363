import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {
  Radio
} from 'components/common'
import RotatorSubPage from './rotator/RotatorSubPage'
import TrackingSubPage from './tracking/TrackingSubPage'

export default function TrafficRotatorPage() {
  const [viewMode, setViewMode] = useState('rotator')

  return (
    <div>
      <div>
        <Radio.Group buttonStyle="solid"
          value={viewMode}
          onChange={e=>setViewMode(e.target.value)}
        >
          <Radio.Button value={'rotator'}>
            Traffic Rotators
          </Radio.Button>
          <Radio.Button value={'tracking'}>
            Trackings
          </Radio.Button>
        </Radio.Group>
      </div>
      <div style={{marginTop: 12}}>
        {viewMode==='rotator' && 
          <RotatorSubPage />
        }
        {viewMode==='tracking' && 
          <TrackingSubPage />
        }
      </div>
    </div>
  )
}

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {
  Row, Col, TablePanel,
  StartEndDatePicker, Input,
  Button,
} from "components/common";
import { callGetApiWithAuth } from "utils/api";
import { varLabel } from 'common/var';
import { asDate } from 'utils/text';

export default function RefundedReportPage() {
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [searchParam, setSearchParam] = useState({
    date_range: `${moment().startOf('month').format('YYYY-MM-DD')}|${moment().format('YYYY-MM-DD')}`,
    startDate: moment().startOf('month'),
    endDate: moment(),
    uuid: '',
    order_number: '',
  })
  const [paginationParam, setPaginationParam] = useState({
    currentPage: 1,
    perPage: 15,
    total: 0,
  })

  const loadTableData = (paginationParam0, searchParam0) => {
    setIsLoading(true)
    const params = {
      ...searchParam0,
      page: paginationParam0.currentPage,
      per_page: paginationParam0.perPage,
    }
    const q = Object.keys(params).map(k=>k+'='+params[k]).join('&')
    callGetApiWithAuth(`report/refunded_report/list?${q}`,
      ({ data }) => {
        setTableData(data.data)
        setPaginationParam({
          ...paginationParam,
          currentPage: data.current_page,
          total: data.total,
        })
        setIsLoading(false)
      },
      () => {
        setIsLoading(false)
      }
    )
  }
  const handleSearch = () => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: 1,
    }
    loadTableData(paginationParam0, searchParam)
  }
  const onPageChange = (page) => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: page,
    }
    loadTableData(paginationParam0, searchParam)
  }

  useEffect(() => {
    handleSearch()
  }, [])

  return (
    <Wrapper>
      <TablePanel
        data={tableData}
        loading={isLoading}
        paginationParam={paginationParam}
        onPageChange={onPageChange}
        toolbar={
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} xl={7}>
              <StartEndDatePicker
                startDate={searchParam.startDate}
                endDate={searchParam.endDate}
                onChange={v=>{
                  if (v) {
                    setSearchParam({ 
                      ...searchParam,
                      startDate: v[0],
                      endDate: v[1],
                      date_range: `${moment(v[0]).format('YYYY-MM-DD')}|${moment(v[1]).format('YYYY-MM-DD')}`
                    })
                  } else {
                    setSearchParam({ 
                      ...searchParam,
                      startDate: '',
                      endDate: '',
                      date_range: ''
                    })
                  }
                }}
              />
            </Col>
            <Col xs={24} sm={24} xl={6}>
              <Input value={searchParam.uuid}
                placeholder={'User ID'}
                onChange={e=>{
                  setSearchParam({
                    ...searchParam,
                    uuid: e.target.value,
                  })
                }}
              />
            </Col>
            <Col xs={24} sm={24} xl={6}>
              <Input value={searchParam.order_number}
                placeholder={'Order Number'}
                onChange={e=>{
                  setSearchParam({
                    ...searchParam,
                    order_number: e.target.value,
                  })
                }}
              />
            </Col>
            <Col xs={24} sm={24} xl={5}>
              <Button onClick={handleSearch}>
                Search
              </Button>
            </Col>
          </Row>
        }
        columns={[
          {
            title: 'User ID',
            render: (_, record) => (
              <span>
                {record.user.uuid}
              </span>
            )
          },
          {
            title: 'Name',
            render: (_, record) => (
              <span>
                {record.user.first_name}&nbsp;
                {record.user.last_name}
              </span>
            )
          },
          {
            title: 'Level',
            render: (_, record) => (
              <span>
                {record.level}
              </span>
            )
          },
          {
            title: 'Order Number',
            render: (_, record) => (
              <span>
                {record.order_number}
              </span>
            )
          },
          {
            title: 'Type',
            render: (_, record) => (
              <span>
                {varLabel('order.status', record.status)}
              </span>
            )
          },
          {
            title: 'Refunded On',
            render: (_, record) => (
              <span>
                {asDate(record.refunded_at)}
              </span>
            )
          },
          {
            title: 'Qualified On',
            render: (_, record) => (
              <span>
                {asDate(record.qualified_at)}
              </span>
            )
          }
        ]}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .ant-picker {
    height: 40px !important;
    width: 100% !important;
    border: 1px solid rgba(145, 158, 171, 0.32);
    border-radius: 8px;
  }
  .ant-input {
    height: 40px !important;
  }
  .ant-btn {
    width: 100%;
  }
`

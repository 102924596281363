import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { ReactComponent as CardIcon } from "assets/icons/gift-card/cards.svg";
import { ReactComponent as SendIcon } from "assets/icons/gift-card/send.svg";
import { ReactComponent as TreeIcon } from "assets/icons/gift-card/tree.svg";
import { varIs } from "common/var";

export default function TipSection(props) {
  const myUser = useSelector((state) => state.auth.user);
  return (
    <Wrapper>
      <div className={"d-flex justify-content-center"}>
        <CardIcon />
      </div>
      <div className={"d-flex justify-content-center flex-column"}>
        <h4>How do Gift Cards work?</h4>
        <p>
          When purchasing an MDC gift card, it will be added to your gift
          balance. With your gift balance, you can place orders or send a
          portion of your balance to your friends so they can shop for MDC
          products.
        </p>
      </div>
      <div className="send-gift-btn">
        <button
          onClick={() => {
            if (myUser && !varIs("user.status", myUser.status, "hold_temp")) {
              props.setModalMode("send");
            }
          }}
        >
          <SendIcon />
          Send Gift Card
        </button>
      </div>
      <div className="tree-container">
        <TreeIcon />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2),
    0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  border-radius: 16px;
  height: 100%;
  padding: 24px;
  h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #212b36;
    margin: 40px 0px 16px;
    text-align: center;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #637381;
    margin: 0px 0px 40px;
  }
  .send-gift-btn {
    button {
      background: #1ea9ea;
      border-radius: 8px;
      width: 100%;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 15px;
      line-height: 26px;
      color: #ffffff;
      padding: 11px;
      svg {
        margin-right: 10px;
      }
    }
  }
  .tree-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
`;

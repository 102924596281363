import { Button, Col, Progress, Row, TablePanel } from "components/common";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { callGetApiWithAuth } from "utils/api";
import { asPrice } from "utils/text";

export default function DtiVipPromoReportPage() {
  const [tableData, setTableData] = useState([]);
  const [totalQE, setTotalQE] = useState(0);
  const [nextLimit, setNextLimit] = useState(10000);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationParam, setPaginationParam] = useState({
    currentPage: 1,
    perPage: 15,
    total: 0,
  });

  const onGetTableData = (res) => {
    setIsLoading(false);
    setTableData(res.data.personal_enrollments.data);
    setTotalQE(res.data.my_next_qe);
    setNextLimit(res.data.next_level_qe_limit);
    setPaginationParam({
      ...paginationParam,
      currentPage: res.data.data.current_page,
      total: res.data.data.total,
    });
  };

  const onFailTableData = () => {
    setIsLoading(false);
  };

  const loadTableData = (paginationParam0) => {
    setIsLoading(true);

    const params = {
      page: paginationParam0.currentPage,
      per_page: paginationParam0.perPage,
    };

    const q = Object.keys(params)
      .map((k) => k + "=" + params[k])
      .join("&");

    callGetApiWithAuth(
      `report/dti_vip_promo_report/list?${q}`,
      onGetTableData,
      onFailTableData
    );
  };

  useEffect(() => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: 1,
    };

    loadTableData(paginationParam0);
  }, []);

  const onPageChange = (page) => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: page,
    };
    loadTableData(paginationParam0);
  };

  return (
    <Wrapper>
      <div className="stats-section">
        <Row gutter={[15, 15]}>
          <Col xs={24} sm={24}>
            <div className="rank-progress-container">
              <div className="progress-label-container">
                <div className="label">$10,000 VIP Travel</div>
                <div className="value">
                  (
                  {totalQE >= 1000 ? 1000 : totalQE}
                  /1000)
                </div>
              </div>
              <div className="rank-progress">
                <Progress
                  percent={
                    100 *
                    (totalQE >= 1000
                      ? 1
                      : totalQE / 1000)
                  }
                  showInfo={false}
                  strokeColor="#08B86E"
                  strokeWidth={10}
                />
              </div>
            </div>
          </Col>

          <Col xs={24} sm={24}>
            <div className="rank-progress-container">
              <div className="progress-label-container">
                <div className="label">$20,000 VIP Travel</div>
                <div className="value">
                  (
                  {totalQE >= 2000 ? 2000 : totalQE}
                  /2000)
                </div>
              </div>
              <div className="rank-progress">
                <Progress
                  percent={
                    100 *
                    (totalQE >= 2000
                      ? 1
                      : totalQE / 2000)
                  }
                  showInfo={false}
                  strokeColor="#08B86E"
                  strokeWidth={10}
                />
              </div>
            </div>
          </Col>

          <Col xs={24} sm={24}>
            <div className="rank-progress-container">
              <div className="progress-label-container">
                <div className="label">$30,000 VIP Travel</div>
                <div className="value">({totalQE}/3000)</div>
              </div>
              <div className="rank-progress">
                <Progress
                  percent={
                    100 *
                    (totalQE >= 3000
                      ? 1
                      : totalQE / 3000)
                  }
                  showInfo={false}
                  strokeColor="#08B86E"
                  strokeWidth={10}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Row gutter={[24, 24]}>
        <Col xs={24}>
          <TablePanel
            data={tableData}
            loading={isLoading}
            onPageChange={onPageChange}
            // toolbar={
            //   <div
            //     className="d-flex align-items-center flex-wrap"
            //     style={{ gap: "8px" }}
            //   >
            //     <Button loading={isLoading}>Export</Button>
            //   </div>
            // }
            columns={[
              {
                title: "User ID",
                key: "uuid",
                render: (_, record) => <span>{record.user.uuid}</span>,
              },
              {
                title: "Name",
                key: "user",
                render: (_, record) => <span>{record.user.first_name + " " + record.user.last_name}</span>,
              },
              {
                title: "Username",
                key: "username",
                render: (_, record) => <span>{record.user.username}</span>,
              },
              {
                title: "Total DTI Memberships",
                key: "ge",
                render: (_, record) => <span>{record.ge}</span>,
              },
              {
                title: "Qualified DTI Memberships",
                key: "qe",
                render: (_, record) => <span>{Math.min(nextLimit, record.qe)}</span>,
              },
            ]}
          />
        </Col>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-bottom: 80px;
  .stats-section {
    box-shadow: 0px 0px 2px rgb(145 158 171 / 20%),
      0px 12px 24px -4px rgb(145 158 171 / 12%);
    border-radius: 16px;
    padding: 24px;
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .rank-progress-container {
    margin-top: 20px;
    .progress-label-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .label {
        font-weight: normal;
        font-size: 14px;
        color: #7f8fa3;
      }
      .value {
        font-weight: 600;
        font-size: 14px;
        color: #00b4ee;
      }
      .payment-tag {
        .active {
          background: rgba(0, 171, 85, 0.08) !important;
          border-radius: 6px;
          font-weight: 700;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          color: #36b37e;
        }
        .failed {
          background: rgba(255, 86, 48, 0.08) !important;
          border-radius: 6px;
          font-weight: 700;
          border: none;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          color: #ff5630;
        }
      }
    }
    .rank-progress {
      .ant-progress-inner,
      .ant-progress-bg {
        border-radius: 0px;
      }
    }
  }
`;
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { Spin } from "components/common";
import { callGetApiWithAuth } from "utils/api";
import { asUsdPrice, asDate } from "utils/text";
import TickIcon from "assets/icons/tick.svg";
import { Wrapper } from "./RaListCard.styled";

export default function RaListCard({ bg, ...props }) {
  const myUser = useSelector((state) => state.auth.user);
  const [listData, setListData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const onGetListData = (res) => {
    setListData(res.data);
    setIsLoading(false);
    let totalAmount = 0;
    res.data.forEach((el) => {
      if (el.is_able) {
        totalAmount += el.amount * 1;
      }
    });
    props.onSetTotalData("raAmount", totalAmount);
  };
  const onFailListData = () => {
    setIsLoading(false);
  };
  const loadListData = () => {
    setIsLoading(true);
    callGetApiWithAuth(
      `report/bonus_tracker/ra_list`,
      onGetListData,
      onFailListData
    );
  };

  useEffect(() => {
    loadListData();
  }, []);

  return (
    <Wrapper className="white-rounded-bg">
      {isLoading ? (
        <div className="loadingRoot">
          <Spin spinning={true} />
        </div>
      ) : (
        <>
          <div className="cardHeader">
            <h4>Rank Achievement Bonus (One Time)</h4>
          </div>
          <div className="table-container">
            <table>
              <thead>
                {listData.map((item, index) => {
                  return (
                    <th key={index} align={"center"}>
                      {item.rank.name}
                    </th>
                  );
                })}
              </thead>
              <tbody>
                <tr>
                  {listData.map((item, index) => (
                    <td key={index} align={"center"}>
                      {asUsdPrice(item.amount)}
                      {item.is_paid && (
                        <div className="stepSubtitle2">
                          {moment(item.earned_at).format("YYYY-MM-DD") ===
                          "2022-02-28"
                            ? `Earned on old system`
                            : `Earned on: ${asDate(item.earned_at)}`}
                        </div>
                      )}
                      {item.is_able && (
                        <img src={TickIcon} className={"tickIcon"} />
                      )}
                      {(!item.is_paid && !item.is_kept &&
                        myUser.rank_id >= item.rank.id
                      ) && (
                        <div className="stepSubtitle">
                          {`Should keep ${item.keep_months} months`}
                          {item.kept_months > 0
                            ? `, currently kept ${item.kept_months} months`
                            : ""}
                        </div>
                      )}
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </Wrapper>
  );
}
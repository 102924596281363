import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Card, TablePanel, Alert, UserAvatar } from "components/common";
import { callGetApiWithAuth } from "utils/api";
import { asDate, asUsdPrice, asVolume } from "utils/text";

export default function CaListCard(props) {
  const myUser = useSelector((state) => state.auth.user);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationParam, setPaginationParam] = useState({
    currentPage: 1,
    perPage: 5,
    total: 0,
  });
  const [searchParam, setSearchParam] = useState({});
  const [period, setPeriod] = useState({
    from: "",
    to: "",
  });
  const [totalAmount, setTotalAmount] = useState(0);

  const onGetTableData = (res) => {
    setPaginationParam({
      ...paginationParam,
      currentPage: res.data.current_page * 1,
      total: res.data.total * 1,
    });
    setTableData(res.data.data);
    setPeriod({
      from: res.data.period_from,
      to: res.data.period_to,
    });
    setTotalAmount(res.data.total_amount);
    props.onSetTotalData("caAmount", res.data.total_amount);
    props.onSetTotalData("weekFrom", res.data.period_from);
    props.onSetTotalData("weekTo", res.data.period_to);
    setIsLoading(false);
  };
  const onFailTableData = () => {
    setIsLoading(false);
  };
  const loadTableData = (paginationParam0, searchParam0) => {
    setIsLoading(true);
    const params = {
      ...searchParam0,
      page: paginationParam0.currentPage,
      per_page: paginationParam0.perPage,
    };
    const q = Object.keys(params)
      .map((k) => k + "=" + params[k])
      .join("&");
    callGetApiWithAuth(
      `report/bonus_tracker/ca_list?${q}`,
      onGetTableData,
      onFailTableData
    );
  };

  const onPageChange = (page) => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: page,
    };
    loadTableData(paginationParam0, searchParam);
  };

  useEffect(() => {
    loadTableData(paginationParam, searchParam);
  }, []);

  return (
    <Wrapper>
      <div className="white-rounded-bg">
        <div className="top-panel">
          <div>
            <div className="title d-flex justify-content-between align-items-center">
              <h4>Customer Acquisition Bonus</h4>
              <p>{`${period.from} ~ ${period.to}`}</p>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <Alert
              type="info"
              message={
                <>
                  Your paid rank is <strong>{myUser?.pay_rank.name}</strong>,
                  which is eligible for a&nbsp;
                  <strong>
                    {myUser && myUser.pay_rank_id * 1 === 1
                      ? "25% "
                      : myUser && myUser.pay_rank_id * 1 === 2
                      ? "30% "
                      : myUser && myUser.pay_rank_id * 1 === 3
                      ? "35% "
                      : "50% "}
                  </strong>
                  commission on BV counting in the Customer Acquisition Payout
                  from your personally enrolled customer's orders. Total
                  estimated customer aqusition bonus is{" "}
                  <strong className="ant-typography ant-typography-success">
                    {asUsdPrice(totalAmount)}
                  </strong>
                  .
                </>
              }
              showIcon
              style={{ alignItems: "baseline" }}
            />
            {myUser && myUser.pay_rank_id * 1 < 4 ? (
              <Alert
                type="warning"
                message={
                  <>
                    If you achieve Executive or higher rank, you will receive
                    50% commission from your personally enrolled customer's
                    orders.
                  </>
                }
                showIcon
                style={{ alignItems: "baseline" }}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <TablePanel
          data={tableData}
          loading={isLoading}
          paginationParam={paginationParam}
          onPageChange={onPageChange}
          columns={[
            {
              title: "User",
              key: "user",
              render: (_, record) => (
                <UserAvatar
                  image={record.user.image}
                  title={`${record.user.first_name} ${record.user.last_name}`}
                />
              ),
            },
            {
              title: "User ID",
              key: "uuid",
              render: (_, record) => <span>{record.user.uuid}</span>,
            },
            {
              title: "BV",
              key: "pv",
              render: (_, record) => <span>{asVolume(record.pv)}</span>,
            },
            {
              title: "CAB Volume",
              key: "pv2",
              render: (_, record) => <span>{asVolume(record.pv2)}</span>,
            },
            {
              title: "Percentage",
              key: "percent",
              render: (_, record) => <span>{record.percent * 100 + "%"}</span>,
            },
            {
              title: "Bonus Amount",
              key: "amount",
              render: (_, record) => <span>{asUsdPrice(record.amount)}</span>,
            },
          ]}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .white-rounded-bg {
    padding: 0px !important;
    .top-panel {
      padding: 24px;
      .title {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 24px;
        h4 {
          font-size: 18px;
          font-weight: 700;
          color: #212b36;
        }
        p {
          margin-bottom: 0;
          color: #637381;
        }
      }
    }
  }

  .table-card {
    .panelRoot {
      margin-bottom: 0px !important;
    }
  }
  .panelHead {
    display: none !important;
  }
  .table-new-wrapper {
    .ant-table-container {
      @media (max-width: 1350px) {
        width: 800px;
      }
    }
    overflow-x: auto;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    .ant-table {
      border-radius: 0px !important;
    }
    .ant-table-content {
      thead {
        th:first-child,
        th:last-child {
          border-radius: 0px !important;
        }
      }
    }
  }
`;
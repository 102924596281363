import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Row,
  Col,
  Modal,
  Button,
  Input,
  Select,
  FormItem,
  Popconfirm,
  SuccessNotification,
  message,
} from "components/common";
import { countryStates, statesByCountry } from "common/country";
import { callPutApiWithAuth } from "utils/api";
import { Wrapper } from "./EditModal.styled";

export default function EditModal(props) {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    contact_email: "",
    contact_phone: "",
    contact_first_name: "",
    contact_last_name: "",
    shipping_address: "",
    shipping_address_line2: "",
    shipping_city: "",
    shipping_zipcode: "",
    shipping_country: "",
    shipping_state: "",
  });
  const [isUpdating, setIsUpdating] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  const onSubmitDone = (res) => {
    setIsUpdating(false);
    SuccessNotification(res.message);
    dispatch({ type: "auth.RELOAD" });
    props.setModalMode(undefined);
  };
  const onSubmitFail = () => {
    setIsUpdating(false);
  };
  const handleSubmit = () => {
    // Validation
    const regex =
      /^[\u4e00-\u9fa5_A-Za-z0-9\s!@#$%^&*()_+=\-`~\\\]\[{}|';:/.,?><]*$/;
    const email_regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    let errorMessages0 = [];
    if (!formData.contact_email) {
      errorMessages0.push({
        field: "contact_email",
        message: "Required",
      });
    } else if (email_regex.exec(formData.contact_email) == null) {
      errorMessages0.push({
        field: "contact_email",
        message: "Please input only English",
      });
    }
    if (!formData.contact_phone) {
      errorMessages0.push({
        field: "contact_phone",
        message: "Required",
      });
    }
    if (!formData.contact_first_name) {
      errorMessages0.push({
        field: "contact_first_name",
        message: "Required",
      });
    } else if (regex.exec(formData.contact_first_name) == null) {
      errorMessages0.push({
        field: "contact_first_name",
        message: "Please input only English",
      });
    }
    if (!formData.contact_last_name) {
      errorMessages0.push({
        field: "contact_last_name",
        message: "Required",
      });
    } else if (regex.exec(formData.contact_last_name) == null) {
      errorMessages0.push({
        field: "contact_last_name",
        message: "Please input only English",
      });
    }
    if (!formData.shipping_address) {
      errorMessages0.push({
        field: "shipping_address",
        message: "Required",
      });
    } else if (regex.exec(formData.shipping_address) == null) {
      errorMessages0.push({
        field: "shipping_address",
        message: "Please input only English",
      });
    }
    if (
      formData.shipping_address_line2 &&
      regex.exec(formData.shipping_address_line2) == null
    ) {
      errorMessages0.push({
        field: "shipping_address_line2",
        message: "Please input only English",
      });
    }
    if (!formData.shipping_city) {
      errorMessages0.push({
        field: "shipping_city",
        message: "Required",
      });
    } else if (regex.exec(formData.shipping_city) == null) {
      errorMessages0.push({
        field: "shipping_city",
        message: "Please input only English",
      });
    }
    if (!formData.shipping_zipcode) {
      errorMessages0.push({
        field: "shipping_zipcode",
        message: "Required",
      });
    }
    if (!formData.shipping_country) {
      errorMessages0.push({
        field: "shipping_country",
        message: "Required",
      });
    }
    if (
      !formData.shipping_state &&
      statesByCountry(formData.shipping_country).length > 0
    ) {
      errorMessages0.push({
        field: "shipping_state",
        message: "Required",
      });
    }
    setErrorMessages(errorMessages0);

    if (errorMessages0.length > 0) {
      return;
    }

    setIsUpdating(true);
    callPutApiWithAuth(
      `my_account/setting/shipping_detail/${props.data.id}`,
      formData,
      onSubmitDone,
      onSubmitFail
    );
  };

  useEffect(() => {
    setFormData({
      ...formData,
      ...props.data,
    });
  }, [props.data]);

  return (
    <Modal width={600} onCancel={() => props.setModalMode(undefined)}>
      <Wrapper>
        <div className="modal-new-title">Edit Shipping Address</div>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12}>
            <div className="input-container">
              <FormItem
                errorMessages={errorMessages.filter(
                  (el) => el.field === "contact_email"
                )}
              >
                <Input
                  label={"Contact Email*"}
                  value={formData.contact_email}
                  onChange={(e) =>
                    setFormData({ ...formData, contact_email: e.target.value })
                  }
                />
              </FormItem>
            </div>
          </Col>{" "}
          <Col xs={24} sm={12}>
            <div className="input-container">
              <FormItem
                errorMessages={errorMessages.filter(
                  (el) => el.field === "contact_phone"
                )}
              >
                <Input
                  label={"Contact Phone*"}
                  value={formData.contact_phone}
                  onChange={(e) =>
                    setFormData({ ...formData, contact_phone: e.target.value })
                  }
                />
              </FormItem>
            </div>
          </Col>{" "}
          <Col xs={24} sm={12}>
            <div className="input-container">
              <FormItem
                errorMessages={errorMessages.filter(
                  (el) => el.field === "contact_first_name"
                )}
              >
                <Input
                  label={"First Name*"}
                  value={formData.contact_first_name}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      contact_first_name: e.target.value,
                    })
                  }
                />
              </FormItem>
            </div>
          </Col>{" "}
          <Col xs={24} sm={12}>
            <div className="input-container">
              <FormItem
                errorMessages={errorMessages.filter(
                  (el) => el.field === "contact_last_name"
                )}
              >
                <Input
                  label={"Last Name*"}
                  value={formData.contact_last_name}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      contact_last_name: e.target.value,
                    })
                  }
                />
              </FormItem>
            </div>
          </Col>{" "}
          <Col xs={24} sm={12}>
            <div className="input-container">
              <FormItem
                errorMessages={errorMessages.filter(
                  (el) => el.field === "shipping_address"
                )}
              >
                <Input
                  label={"Address Line 1*"}
                  value={formData.shipping_address}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      shipping_address: e.target.value,
                    })
                  }
                />
              </FormItem>
            </div>
          </Col>{" "}
          <Col xs={24} sm={12}>
            <div className="input-container">
              <FormItem
                errorMessages={errorMessages.filter(
                  (el) => el.field === "shipping_address_line2"
                )}
              >
                <Input
                  label={"Address Line 2"}
                  value={formData.shipping_address_line2}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      shipping_address_line2: e.target.value,
                    })
                  }
                />
              </FormItem>
            </div>
          </Col>{" "}
          <Col xs={24} sm={12}>
            <div className="input-container">
              <FormItem
                errorMessages={errorMessages.filter(
                  (el) => el.field === "shipping_city"
                )}
              >
                <Input
                  label={"City*"}
                  value={formData.shipping_city}
                  onChange={(e) =>
                    setFormData({ ...formData, shipping_city: e.target.value })
                  }
                />
              </FormItem>
            </div>
          </Col>{" "}
          <Col xs={24} sm={12}>
            <div className="input-container">
              <FormItem
                errorMessages={errorMessages.filter(
                  (el) => el.field === "shipping_zipcode"
                )}
              >
                <Input
                  label={"Zip/Postal code*"}
                  value={formData.shipping_zipcode}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      shipping_zipcode: e.target.value,
                    })
                  }
                />
              </FormItem>
            </div>
          </Col>{" "}
          <Col xs={24} sm={12}>
            <div className="input-container">
              <FormItem
                errorMessages={errorMessages.filter(
                  (el) => el.field === "shipping_country"
                )}
              >
                <Select
                  value={formData.shipping_country}
                  label={"Country*"}
                  options={[
                    {
                      label: "Select Country",
                      value: "",
                    },
                    ...countryStates.map((el) => ({
                      label: el.name,
                      value: el.code2,
                    })),
                  ]}
                  style={{ width: "100%" }}
                  onChange={(v) =>
                    setFormData({
                      ...formData,
                      shipping_country: v,
                      shipping_state: "",
                    })
                  }
                  showSearch
                  optionFilterProp="label"
                />
              </FormItem>
            </div>
          </Col>{" "}
          <Col xs={24} sm={12}>
            {statesByCountry(formData.shipping_country).length > 0 ? (
              <div className="input-container">
                <FormItem
                  errorMessages={errorMessages.filter(
                    (el) => el.field === "shipping_state"
                  )}
                >
                  <Select
                    value={formData.shipping_state}
                    label={"State/Province*"}
                    options={[
                      {
                        label: "Select State",
                        value: "",
                      },
                      ...statesByCountry(formData.shipping_country).map(
                        (el) => ({
                          label: el.name,
                          value: el.name,
                        })
                      ),
                    ]}
                    style={{ width: "100%" }}
                    onChange={(v) =>
                      setFormData({ ...formData, shipping_state: v })
                    }
                    showSearch
                  />
                </FormItem>
              </div>
            ) : (
              ""
            )}
          </Col>{" "}
          <Col span={24}>
            <div className="action-btn">
              <Button
                className="cancel-btn"
                onClick={() => props.setModalMode(undefined)}
              >
                Cancel
              </Button>
              <Popconfirm onConfirm={handleSubmit}>
                <Button loading={isUpdating} className="create-btn">
                  Update
                </Button>
              </Popconfirm>
            </div>
          </Col>
        </Row>
      </Wrapper>
    </Modal>
  );
}

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Input,
  Button,
  Radio,
  Popconfirm,
  SuccessNotification,
  message,
} from "components/common";
import { varValue, varIs } from "common/var";
import { asUsdPrice } from "utils/text";
import { callPutApiWithAuth, callPostApiWithAuth } from "utils/api";
import { ReactComponent as Wallet11con } from "assets/icons/wallet-1.svg";
import { ReactComponent as Wallet12con } from "assets/icons/wallet-2.svg";
import Banner from "assets/images/cd-wallet.png";
import { Wrapper } from "./BalanceSubPage.styled";

export default function BalanceSubPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const myUser = useSelector((state) => state.auth.user);
  const [formData, setFormData] = useState({
    type: "",
    keep_percent: "",
  });
  const [isUpdating, setIsUpdating] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);
  const [amount, setAmount] = useState("");

  const onSaveDone = (res) => {
    SuccessNotification(res.message);
    setIsUpdating(false);
    dispatch({ type: "auth.RELOAD" });
  };
  const onSaveFail = () => {
    setIsUpdating(false);
    setTimeout(() => {
      history.push("/my_account/payment_option");
    }, 500);
  };
  const handleSave = () => {
    // Validation
    if (varIs("creditWithdrawSetting.type", formData.type, "partial_payout")) {
      if (!formData.keep_percent) {
        message.error("Please input keep amount");
        return;
      }
      if (isNaN(formData.keep_percent)) {
        message.error("Please input keep amount as numeric");
        return;
      }
    }

    setIsUpdating(true);
    callPutApiWithAuth(
      `my_account/wallet/withdraw_setting`,
      formData,
      onSaveDone,
      onSaveFail
    );
  };

  const onRequestDone = (res) => {
    SuccessNotification(res.message);
    setIsRequesting(false);
    dispatch({ type: "auth.RELOAD" });
  };
  const onRequestFail = () => {
    setIsRequesting(false);
  };
  const handleRequest = () => {
    // validation
    if (isNaN(amount)) {
      message.error("Please input amount as number");
      return;
    }
    if (amount <= 25) {
      message.error("Please input number greater than $25");
      return;
    }

    //
    setIsRequesting(true);
    callPostApiWithAuth(
      `my_account/wallet/request_withdraw`,
      {
        amount,
      },
      onRequestDone,
      onRequestFail
    );
  };

  useEffect(() => {
    dispatch({
      type: "auth.RELOAD",
    });
  }, []);

  useEffect(() => {
    setFormData({
      type: myUser.withdraw_setting.type,
      keep_percent: myUser.withdraw_setting.keep_percent,
    });
  }, [myUser]);

  return (
    <Wrapper>
      <Row gutter={[20, 20]}>
        <Col xs={24} lg={12} xl={9} xxl={6}>
          <div className="balance-stats">
            <div className="stats-icon">
              <div className="stats-value">
                {asUsdPrice(myUser.wallet.current_balance)}
              </div>
              <div className="stats-title">Current Balance</div>
            </div>
            <Wallet12con />
          </div>
        </Col>
        <Col xs={24} lg={12} xl={9} xxl={6}>
          <div className="balance-stats">
            <div className="stats-icon">
              <div className="stats-value">
                {asUsdPrice(myUser.wallet.withdrawn_amount)}
              </div>
              <div className="stats-title">Total Withdrawn</div>
            </div>
            <Wallet11con />
          </div>
        </Col>
        <Col xs={24} lg={12} xl={9} xxl={6}>
          <div className="balance-stats">
            <div className="stats-icon">
              <div className="stats-value">
                {asUsdPrice(myUser.wallet.total_amount)}
              </div>
              <div className="stats-title">Total Amount</div>
            </div>
            <Wallet12con />
          </div>
        </Col>
      </Row>
      <div className="payment-header">
        <span>About Credit Wallet</span>
      </div>
      <div className="credit-wallet-content">
        <div className="content">
          MyDailyChoice gives you the opportunity to use your commissions to
          purchase product for yourself or for others in your team. Simply
          select how much of your future commissions you would like to allocate
          toward your credit account and it will automatically be added each
          Thursday as commissions are paid.
        </div>
        <div className="content">
          In addition, you may fill your credit wallet by sending MyDailyChoice,
          Inc. a bank wire. Below are the bank wire details:
        </div>
        <div className="banner-credit">
          <img src={Banner} />
          <div className="content-container">
            <div className="content-label">
              Financial Institution:
              <span>First Citizens Bank</span>
            </div>
            <div className="content-label">
              Bank Address:
              <span>
                Raleigh, NC USA
              </span>
            </div>
            <div className="content-label">
              Routing / ABA Number:
              <span>053100300</span>
            </div>
            <div className="content-label">
              Beneficiary Name:
              <span>My Daily Choice, Inc</span>
            </div>
            <div className="content-label">
              Beneficiary Account Number:
              <span>001064971417</span>
            </div>
            <div className="content-label">
              Beneficiary Address:
              <span>6713 S Eastern Ave Las Vegas, NV 89119</span>
            </div>
          </div>
        </div>

        <div className="content">
          Please include your ID# in the reference line to ensure proper funds
          are allocated to your credit wallet
        </div>
        <div className="content">
          Once you have sent a wire, please email{" "}
          <span>accounting@MyDailyChoice.com</span> with your wire receipt and
          then your wallet will be funded.
        </div>
      </div>
      <div className="radio-container">
        <Radio.Group
          onChange={(e) =>
            setFormData({
              ...formData,
              type: e.target.value,
            })
          }
          value={formData.type}
        >
          <div className="radio-label">
            <Radio value={varValue("creditWithdrawSetting.type", "payout")}>
              Do not credit ANY of my earnings to my Credit Wallet.
            </Radio>
          </div>
          <div className="radio-label">
            <Radio
              value={varValue("creditWithdrawSetting.type", "partial_payout")}
            >
              <div className="checktext">
                <span>
                  I would like to credit the next
                  <Input
                    value={formData.keep_percent}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        keep_percent: e.target.value,
                      })
                    }
                  />
                  <span>of my earnings to my Credit Wallet.</span>
                </span>
              </div>
            </Radio>
          </div>
          <div className="radio-label">
            <Radio
              value={varValue("creditWithdrawSetting.type", "credit_wallet")}
            >
              I would like to credit ALL of my earnings to my Credit Wallet.
            </Radio>
          </div>
        </Radio.Group>
      </div>
      <div className="action-root">
        <button onClick={handleSave} loading={isUpdating}>
          Save
        </button>
      </div>
      <div className="payment-header">
        <span>Withdraw</span>
        <div className="main-divider" />
      </div>
      <div className="manual-container">
        <Row>
          <Col className="responsive-label col-for-mbl">
            <div className="amount-label">Amount:</div>
          </Col>
          <Col className="responsive-input">
            <Input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </Col>
          <Col className="responsive-button">
            {myUser && !varIs("user.status", myUser.status, "hold_temp") ? (
              <Popconfirm onConfirm={handleRequest}>
                <button loading={isRequesting}>Withdraw</button>
              </Popconfirm>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </div>
      <div className="credit-wallet-content">
        <div className="content c1">
          * Withdrawals are processed every Thursday at 4PM PST and may take 5-7
          business days to reach your payment method on file.
        </div>
      </div>
    </Wrapper>
  );
}

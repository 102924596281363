import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {
  TablePanel
} from 'components/common'
import { 
  callGetApiWithAuth,
} from 'utils/api'
import { asNumber } from 'utils/text'

export default function TrackingSubPage() {
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [paginationParam, setPaginationParam] = useState({
    currentPage: 1, 
    perPage: 15,
    total: 0,
  })

  const onGetTableData = (res) => {
    setIsLoading(false)
    setTableData(res.data.data)
    setPaginationParam({
      ...paginationParam,
      currentPage: res.data.current_page,
      total: res.data.total,
    })
  }
  const onFailTableData = () => {
    setIsLoading(false)
  }
  const loadTableData = (paginationParam0) => {
    setIsLoading(true)
    const params = {
      page: paginationParam0.currentPage,
      per_page: paginationParam0.perPage
    }
    const q = Object.keys(params).map(k=>k+'='+params[k]).join('&')
    callGetApiWithAuth(`tool/traffic_rotator/trackings?${q}`, 
      onGetTableData, onFailTableData
    )
  }
  const onPageChange = (currentPage) => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage,
    }
    loadTableData(paginationParam0)
  }

  const handleSearch = () => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: 1,
    }
    loadTableData(paginationParam0)
  }

  useEffect(() => {
    handleSearch()
  }, [])

  return (
    <Wrapper>
      <TablePanel title='Trackings'
        data={tableData}
        loading={isLoading}
        paginationParam={paginationParam}
        onPageChange={onPageChange}
        columns={[
          {
            title: 'Path',
            key: 'path',
            render: (_, record) => (
              <span>
                {`${process.env.REACT_APP_CORP_URL}/traffic-rotator/${record.path}`}
              </span>
            )
          },
          {
            title: 'Username',
            key: 'username',
            render: (_, record) => (
              <span>
                {record.rotate_user.username}
              </span>
            )
          },
          {
            title: 'Clicks',
            key: 'total_clicks',
            render: (_, record) => (
              <span>
                {asNumber(record.total_clicks)}
              </span>
            )
          },
          {
            title: 'Rotated Clicks',
            key: 'rotated_clicks',
            render: (_, record) => (
              <span>
                {asNumber(record.rotated_clicks)}
              </span>
            )
          }
        ]}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
`

import React from "react";
import copy from 'copy-to-clipboard';
import { Button, SuccessNotification } from "components/common";
import { getUser } from "utils/auth";
import { Wrapper } from "./YourWebsite.styled";
import { ReactComponent as CopyIcon } from "assets/icons/copy-icon.svg";
import WebsiteImage from "assets/images/website.png";
import LinkedinIcon from "assets/icons/linkedin-icon.svg";
import TwitterIcon from "assets/icons/twitter-icon.svg";
import FacebookIcon from "assets/icons/facebook-icon.svg";
import MailIcon from "assets/icons/email-icon.svg";

export default function YourWebsite() {
  const myUser = getUser();

  const copyWebsiteLink = () => {
    SuccessNotification("Successfully copied to clipboard");
    copy(`${process.env.REACT_APP_CORP_URL}/${myUser?.username}`);
  };

  const shareLinkedin = () => {
    const url = `${process.env.REACT_APP_CORP_URL}/${myUser?.username}`;
    const shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
      url
    )}`;
    window.open(shareUrl, "_blank");
  };

  const shareTwitter = () => {
    const url = `${process.env.REACT_APP_CORP_URL}/${myUser?.username}`;
    const shareUrl = `https://twitter.com/intent/tweet?&url=${encodeURIComponent(
      url
    )}`;
    window.open(shareUrl, "_blank");
  };

  const shareFacebook = () => {
    const url = `${process.env.REACT_APP_CORP_URL}/${myUser?.username}`;
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      url
    )}`;
    window.open(shareUrl, "_blank");
  };

  const shareEmail = () => {
    const body = `${process.env.REACT_APP_CORP_URL}/${myUser?.username}`;
    const shareUrl = `mailto:?subject=Invitation URL&body=${body}`;
    window.open(shareUrl);
  };

  return (
    <Wrapper className="white-rounded-bg">
      <div className="website-details">
        <img src={WebsiteImage} className="website-image" />
        <div className="website-title">Your Website Link</div>
        <div className="website-url">
          {process.env.REACT_APP_CORP_URL}/{myUser?.username}
        </div>
        <div className="sharable-container">
          <div className="sharable-links">
            <span className="social-icon" onClick={() => shareLinkedin()}>
              <img src={LinkedinIcon} />
            </span>
            <span className="social-icon" onClick={() => shareTwitter()}>
              <img src={TwitterIcon} />
            </span>
            <span className="social-icon" onClick={() => shareFacebook()}>
              <img src={FacebookIcon} />
            </span>
            <span className="social-icon" onClick={() => shareEmail()}>
              <img src={MailIcon} />
            </span>
          </div>
        </div>
      </div>
      <div className="website-button">
        <Button icon={<CopyIcon />} onClick={copyWebsiteLink}>
          Website Link
        </Button>
      </div>
    </Wrapper>
  );
}

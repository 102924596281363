import styled from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 101;
  width: 100%;
  display: flex;
  justify-content: space-between;
  .searchRoot {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .searchInput {
    width: 400px;
  }
  .searchBtn_Top,
  .searchBtn_Level {
    padding: 0px 30px;
  }
  .searchResults {
    position: absolute;
    left: 0;
    top: 34px;
    width: 100%;
    margin-top: 0;
    padding-left: 0;
    background: #fff;
    z-index: 12;
    list-style: none;
    cursor: pointer;
    li {
      background: transparent;
      padding: 8px 8px;
    }
  }
  .descRoot {
    position: absolute;
    right: 40px;
    top: 0px;
    z-index: 101;
    padding: 0px 18px;
    width: 450;
    margin-left: 16px;
    @media (max-width: 480px) {
      margin-left: 0px;
      margin-top: 16px;
    }
  }
  .descBox {
    display: flex;
    margin-left: 16px;
    align-items: center;
    padding: 15px 30px 0px 0px;
  }
  .descTextRoot {
    display: flex;
    align-items: center;
    margin-left: 12px;
    margin-right: 12px;
  }
  .descTextIcon {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    margin-right: 8px;
  }
  .userType1Color {
    background-color: ${(props) => props.theme.palette.primary};
  }
  .userType2Color {
    background-color: ${(props) => props.theme.palette.secondary};
  }
  .userStatusAColor {
    background-color: #52c41a;
  }
  .userStatusIColor {
    background-color: #f5222d;
  }
  .userStatusHColor {
    background-color: #fa8c16;
  }
  @media (max-width: 1300px) {
    flex-direction: column !important;
    .descRoot {
      position: static !important;
      padding: 0px !important;
      margin-left: 0px !important;
      .descBox {
        margin-left: 0px;
      }
    }
  }
  @media (max-width: 1080px) {
    .searchRoot {
      flex-direction: column;
      align-items: start;
      .searchInput {
        width: 100%;
      }
    }
  }
`;

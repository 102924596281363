import styled from "styled-components";

export const Wrapper = styled.div`
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2),
    0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  border-radius: 16px;
  padding: 24px;
  .credit-wallet-title {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 30px;
  }
  .balance-stats {
    border: 1px solid rgba(145, 158, 171, 0.24);
    border-radius: 16px;
    padding: 0px 16px 0px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .stats-title {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #637381;
      margin-top: 5px;
    }
    .stats-value {
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      color: #212b36;
    }
    svg {
      max-width: 97px;
    }
  }
  .payment-header {
    margin: 40px 0px 24px;
    span {
      font-weight: 700;
      font-size: 18px;
      position: relative;
      display: inline-block;
      padding-right: 40px;
    }
  }
  .credit-wallet-content {
    .content {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #637381;
      margin-bottom: 7px;
      span {
        color: #1ea9ea;
      }
    }
  }
  .content-container {
    position: relative;
    background: transparent;
    margin: 0;
    padding: 24px;
    .content-label {
      margin: 0px;
      display: flex;
      flex-direction: column;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #637381;
      margin-bottom: 8px;
      span {
        padding: 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #212b36;
      }
    }
  }
  .manual-container {
    margin-bottom: 20px;
    .ant-col {
      display: flex;
      align-items: center;
    }
    .amount-label {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #637381;
      margin-right: 8px;
    }
    .ant-input {
      width: 171px;
      height: 40px !important;
    }
    button {
      background: #1ea9ea;
      border-radius: 8px;
      border: none;
      font-weight: 700;
      font-size: 14px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #ffffff;
      height: 40px;
      padding: 0px 16px;
      margin-left: 8px;
    }
  }
  .radio-container {
    margin-top: 20px;
    .radio-label {
      font-weight: 400;
      font-size: 14px;
      color: #637381;
      display: flex;
      align-items: center;
      margin: 8px 0px;
      span {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
      .ant-input {
        width: 130px;
        margin: 0px 10px;
        height: 40px !important;
      }
    }
    .ant-radio-wrapper {
      margin-right: 11px;
    }
  }
  .checktext {
    display: flex;
    align-items: center;
  }
  @media (max-width: 720px) {
    .content-label {
      display: flex;
      flex-direction: column;
      span {
        padding-left: 0px;
      }
    }
  }

  @media (max-width: 450px) {
    .radio-label {
      .ant-input {
        width: 70px !important;
      }
    }
  }

  .action-root {
    button {
      background: #1ea9ea;
      border-radius: 8px;
      border: none;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #ffffff;
      height: 40px;
      padding: 0px 16px;
      margin-top: 10px;
    }
  }
  .c1 {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #00b8d9 !important;
    margin-top: -10px;
  }
  .banner-credit {
    position: relative;
    width: 460px;
    min-height: 352px;
    margin-bottom: 16px;
    margin-top: 16px;
    img {
      width: 100%;
      position: absolute;
      height: 100%;
      margin-left: -4px;
    }
  }
  @media (max-width: 700px) {
    .checktext {
      div {
        width: 100% !important;
        .ant-input {
          width: 100% !important;
          margin: 5px 0px !important;
        }
      }
    }
    .action-root {
      button {
        width: 100%;
        justify-content: center;
      }
    }
    .col-for-mbl {
      width: 100% !important;
    }
    .responsive-input {
      width: 50% !important;
      div {
        width: 100% !important;
        .ant-input {
          width: 100% !important;
        }
      }
    }
    .responsive-button {
      width: 50% !important;
      button {
        width: 100% !important;
        justify-content: center !important;
      }
    }
  }
  @media (max-width: 700px) {
    .banner-credit {
      position: relative;
      width: 100% !important;
    }
  }
`;

import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  TablePanel, UserAvatar
} from 'components/common'
import {
  callGetApiWithAuth
} from 'utils/api'
import { 
  asVolume
} from 'utils/text'

export default function PeQvCard(props) {
  const myUser = useSelector(state=>state.auth.user)
  const [rank, setRank] = useState(undefined)
  const [nextRank, setNextRank] = useState(undefined)
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [paginationParam, setPaginationParam] = useState({
    currentPage: 1, perPage: 100, total: 0
  })

  const onGetRankData = (res) => {
    setRank(res.data.rank)
    setNextRank(res.data.next_rank)
  }
  const onFailRankDdata = () => {}
  const loadRankData = () => {
    callGetApiWithAuth(`my_rank/rank_snapshot/rank?view_mode=${props.viewMode}`, onGetRankData, onFailRankDdata)
  }

  const onGetListData = (res) => {
    setIsLoading(false)
    setTableData(res.data.data)
    setPaginationParam({
      ...paginationParam,
      currentPage: res.data.current_page,
      total: res.data.total
    })
  }
  const onFailListData = () => {
    setIsLoading(false)
  }
  const loadListData = (paginationParam0) => {
    const params = {
      page: paginationParam0.currentPage,
      per_page: paginationParam0.perPage,
    }
    const q = Object.keys(params).map(k=>k+'='+params[k]).join('&')
    setIsLoading(true)
    if (props.viewMode=='current') {
      callGetApiWithAuth(`my_rank/rank_snapshot/pes_gv?${q}`, 
        onGetListData, onFailListData
      )
    } else if (props.viewMode == 'last') {
      callGetApiWithAuth(`my_rank/rank_snapshot/last_pes_gv?${q}`, 
        onGetListData, onFailListData
      )
    }    
  }
  const onPageChange = (page) => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: page
    }
    loadListData(paginationParam0)
  }

  useEffect(() => {
    if (props.viewMode) {
      const paginationParam0 = {
        ...paginationParam, 
        currentPage: 1
      }
      loadListData(paginationParam0)
      loadRankData()
    }
  }, [props.viewMode])

  return (
    <TablePanel
      title={'My Enroller Tree'}
      loading={isLoading}
      data={tableData}
      paginationParam={paginationParam}
      onPageChange={onPageChange}
      columns={[
        {
          title: 'User ID',
          render: (_, record) => (
            <span>#{record.uuid}</span>
          )
        },
        {
          title: 'Name',
          render: (_, record) => (
            <UserAvatar 
              image={record.image}
              title={`${record.first_name} ${record.last_name}`}
            />
          )
        },
        {
          title: 'Personal Volume',
          align: 'center',
          render: (_, record) => (
            <span>
              {asVolume(record.pv)}
            </span>
          )
        },
        {
          title: 'Total Current Volume',
          align: 'center',
          render: (_, record) => (
            <span>
              {asVolume(record.gv*1+record.pv*1)}
            </span>
          )
        },
        {
          title: `Adjusted Volume (${(rank && rank.settings.qv_limit)?asVolume(rank.settings.qv_limit):0} BV Max)`,
          align: 'center',
          render: (_, record) => (
            <span>
              {(rank && rank.settings.qv_limit)?
                asVolume(Math.min(
                  record.gv*1+record.pv*1, 
                  rank.settings.qv_limit
                ))
              : 0}
            </span>
          )
        },
        {
          title: `Next Rank(${nextRank?.name}) Adjusted Volume (${nextRank?.settings.qv_limit?asVolume(nextRank.settings.qv_limit):0} BV Max)`,
          align: 'center',
          render: (_, record) => (
            <span>
              {nextRank?.settings.qv_limit?
                asVolume(Math.min(
                  record.gv*1+record.pv*1, 
                  nextRank.settings.qv_limit
                ))
              : 0}
            </span>
          )
        }
      ]}
    />
  )
}

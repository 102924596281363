import React, { useState } from "react";
import { Row, Col, VideoModal } from "components/common";
import VideoPreviewImg from "assets/images/live_stream/video_preview.png";
import LogoImg from "assets/images/loginLogo.png";
import { ReactComponent as TipsIcon } from "assets/icons/tips-icon.svg";
import { ReactComponent as ProbIcon } from "assets/icons/prohibited-icon.svg";
import { Wrapper } from "./GuideSubPage.styled";

export default function GuideSubPage() {
  const [isOpenVideo, setIsOpenVideo] = useState(false);

  const handleOpenVideoModal = () => {
    setIsOpenVideo(true);
  };

  const handleCloseVideoModal = () => {
    setIsOpenVideo(false);
  };

  return (
    <Wrapper className="white-rounded-bg">
      <div className="video-title-container">
        <Row gutter={[20, 20]}>
          <Col xs={24} md={24} className="order-md-1">
            <div className="lvs-logo-title-container">
              <div className="logo-section">
                <img src={LogoImg} />
              </div>
              <div className="title-section">
                <h5>Livestreams</h5>
                <p>
                  Livestream is an exciting tool to stream to your audience and
                  get live sales during your stream. View the tutorial video to
                  learn how to use live stream.
                </p>
                <b>Step 1: Create a WEBINAR in your Zoom account. </b> Zoom
                Webinars will only show you as the streamer and all other
                attendees will not be able to use video or audio. You can have
                panelists on your Zoom webinar if you have multiple presenters.
                IMPORTANT: YOU MUST CREATE A ZOOM WEBINAR, NOT A MEETING.
                <br />
                <br />
                <b>
                  Step 2: Schedule a live stream in your MDC user back office.{" "}
                </b>{" "}
                IMPORTANT: Be as clear as possible about what your Zoom is about
                and choose the products you want to feature. YOU MUST INCLUDE A
                ZOOM WEBINAR ID AND PASSWORD OR YOUR STREAM WILL NOT WORK
                PROPERLY.
                <br />
                <br />
                <b>
                  Step 3: Share the Live Stream URL with YOUR prospects only.{" "}
                </b>{" "}
                Your team and others who want to promote your stream will need
                to use the URL in THEIR back office.
                <br />
                <br />
                <b>
                  Step 4: When ready, start your Zoom Webinar from the Zoom
                  Application.{" "}
                </b>{" "}
                You will not need to use the user back office after you have
                started your Zoom Webinar. Your viewers will see the products
                appear and be able to see you on the stream.
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="description-container">
        <Row gutter={[20, 20]}>
          <Col xs={24} md={12}>
            <div className="tips-card description-card">
              <div className="title-section">
                <TipsIcon className="star-icon" />
                <h4>Tips for Success</h4>
              </div>
              <div className="detail-section">
                <ul>
                  <li>Always use company approved marketing materials</li>
                  <li>Always be professional</li>
                  <li>Always be truthful and respectful</li>
                  <li>Always know the information you’re going to present</li>
                  <li>Be mindful of your audio, lighting, and background</li>
                  <li>Always stream from a quiet place</li>
                  <li>Look at the camera and engage your audience</li>
                  <li>Always have fun</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col xs={24} md={12}>
            <div className="prohibited-card description-card">
              <div className="title-section">
                <ProbIcon className="block-icon" />
                <h4>Prohibited Uses</h4>
              </div>
              <div className="detail-section">
                <ul>
                  <li>Nudity</li>
                  <li>Bullying or harassment</li>
                  <li>Violence</li>
                  <li>Excessive profanity</li>
                  <li>Self-injury</li>
                  <li>Hate speech</li>
                  <li>Criminal activity</li>
                  <li>Cross recruiting</li>
                  <li>Improper health claims</li>
                  <li>Improper income claims</li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="footer-description">
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <p>
              Violation of the above will result in loss of live stream access
              and/or account termination. For questions, please email{" "}
              <a href="mailto:compliance@mydailychoice.com">
                compliance@mydailychoice.com
              </a>
              .
            </p>
          </Col>
        </Row>
      </div>
      {isOpenVideo && (
        <VideoModal
          src="https://vimeo.com/694624165"
          onCancel={handleCloseVideoModal}
          width={1200}
          isVimeo
        />
      )}
    </Wrapper>
  );
}

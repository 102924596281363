import styled from "styled-components";

export const Wrapper = styled.div`
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2),
    0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  border-radius: 16px;
  padding: 24px;
  .left-details-container {
    border-right: 0.1px solid #919eab14;
  }
  .profile-p-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      cursor: pointer;
    }
    .profile-title {
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      color: #212b36;
    }
  }
  .right-details-container {
    border-left: 0.1px solid #919eab14;
  }
  .left-box {
    padding-right: 10px;
  }
  .right-box {
    padding-left: 15px;
  }
  .binary-qual {
    color: ${(props) => props.theme.palette.success};
    font-stretch: expanded;
    display: flex;
    align-items: center;
    &.inactive {
      color: ${(props) => props.theme.palette.error};
    }
  }

  .v-divider {
    width: 1px;
    height: 100%;
    background: #7f8fa3;
    opacity: 0.2;
  }

  .personal-details-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img {
      width: 106px;
      height: 106px;
      border: 2px solid #1ea9ea;
      margin-top: 20px;
      border-radius: 50%;
      object-fit: cover;
    }
    .user-title {
      margin: 8px 0px 0px;
      font-weight: 700;
      font-size: 18px;
      text-align: center;
      color: #212b36;
    }
    .user-id {
      margin-bottom: 16px;
      font-weight: 500;
      font-size: 14px;
      text-align: center;
      color: #919eab;
    }
  }
  .personal-extra-info {
    background: #fafbfc;
    padding: 1px 15px;
    .personal-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 21px 0px;
      .label {
        font-weight: normal;
        font-size: 14px;
        color: #005d7b;
      }
      .value {
        font-weight: 600;
        font-size: 14px;
        color: #00b4ee;
      }
    }
  }

  .plot-container {
    margin-bottom: 5px;
    .iquid-container {
      max-width: 200px;
      margin: auto;
      position: relative;
      top: -15px;
      .g2-html-annotation {
        top: 154px !important;
        padding-bottom: 10px !important;
        z-index: 4 !important;
      }
    }
    .iquid-container > div {
      height: 180px;
    }
    .liquid-setting-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      .liquid-settings {
        border: 1px solid rgba(127, 143, 163, 0.4);
        width: 100%;
        padding: 7px 20px;
        color: #212529;
        font-weight: 500;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        cursor: pointer;
        svg {
          margin-right: 10px;
        }
        a {
          color: #212529;
        }
      }
    }
  }
  .account-and-profile-container {
    margin-bottom: 20px;
    .account-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: #212b36;
      }
      .percentage {
        color: white;
        display: flex;
        align-items: center;
        .incompleted {
          background: #ff5630;
          border-radius: 6px;
          padding: 2px 8px;
          font-weight: 700;
          font-size: 12px;
        }
        .completed {
          background: #36b37e;
          border-radius: 6px;
          padding: 2px 8px;
          font-weight: 700;
          font-size: 12px;
        }
      }
      .mbl-percentage {
        display: none;
      }
      .web-percentage {
        display: flex;
      }
    }
  }
  .skeleton-section {
    position: relative;
  }
  .loading-spin-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(256, 256, 256, 0.8);
  }
  .rank-badge {
    width: 22px;
    height: 22px;
    object-fit: cover;
    margin-right: 8px;
  }
  @media (max-width: 1200px) {
    .left-details-container {
      border-right: none;
    }
    .right-details-container {
      border-left: none;
    }
    .left-box {
      padding-right: 0px;
      border-bottom: 1px solid rgba(145, 158, 171, 0.24);
      padding-bottom: 29px;
    }
    .right-box {
      padding-left: 0px;
    }
  }
  .rank-plus-section {
    .rank-plus-box {
      border: 0.62642px solid rgba(145, 158, 171, 0.24);
      border-radius: 10.0227px;
      padding: 16px;
      display: flex;
      height: 100%;
      justify-content: space-between;
      .rank-plus-flex {
        .rank-plus-title {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #919eab;
        }
        .rank-plus-value {
          font-weight: 700;
          font-size: 14px;
          line-height: 22px;
          color: #1ea9ea;
          &.green {
            color: #36B37E;
          }
          &.yellow {
            color: #FFAB00;
          }
          &.red {
            color: #FF5630;
          }
        }
      }
    }
  }
  .account-c-section {
    display: flex;
    align-items: center;
    .profile-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0px;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #919eab;
      .title {
        margin: 0px;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #919eab;
      }
    }
    svg {
      margin-right: 16px;
    }
  }
  @media (max-width: 900px) {
    .mbl-percentage {
      display: flex !important;
    }
    .title-mbl{
      margin-bottom: 4px;
    }
    .web-percentage {
      display: none !important;
    }
    .account-c-section {
      width: 100%;
    }
    .account-c-section  >div{
      width: 100%;

    }
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
`;

import styled from "styled-components";

export const Wrapper = styled.div`
  .rank-box {
    border: 1px solid rgba(145, 158, 171, 0.24);
    border-radius: 16px;
    padding: 18px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .rank-para {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #637381;
      mix-blend-mode: normal;
      opacity: 0.72;
    }
    .rank-head {
      font-weight: 700;
      font-size: 23px;
      line-height: 36px;
      color: #212b36;
    }
  }

  .line-progress-container {
    .line-progress-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: -4px;
      .line-progress-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: #212b36;
      }
      .line-progress-value {
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        text-align: right;
        color: #212b36;
      }
    }
  }

  .liquid-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .ant-progress-text {
      font-weight: 700;
      font-size: 27px;
      text-align: center;
      color: #212b36;
    }
    .ant-progress-inner {
      width: 230px !important;
      height: 230px !important;
    }
  }
  /* @media (max-width: 1130px) {
    .responsive-rank {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
    .rank-container {
      padding-left: 0px;
      border-left: none;
    }
  } */
`;

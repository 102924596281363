import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Row, Col,
  Alert, message,
  SuccessNotification,
  Input, Radio, Select,
  Popconfirm, Button,
} from "components/common";
import { callPutApiWithAuth } from "utils/api";
import { countryStates, statesByCountry } from "common/country";

export default function NgBatchOptionCard({
  isActive,
  provider,
  loadListData,
}) {
  const sudoName = useSelector((state) => state.auth.sudo_name);
  const myUser = useSelector((state) => state.auth.user);
  const [formData, setFormData] = useState({
    name: '',
    bank_name: '',
    bank_branch: '',
    account_number: '',
    phone: '',
  });
  const [isUpdating, setIsUpdating] = useState(false);

  const onFormInput = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const onSaveDone = () => {
    setIsUpdating(false);
    SuccessNotification("Nigeria Payout has been set as your payout provider.");
    loadListData();
  };

  const handleSave = () => {
    // check validation
    if (!formData.name || 
      !formData.bank_name || 
      !formData.bank_branch || 
      !formData.account_number || 
      !formData.phone
    ) {
      message.error("Input all required fields");
      return;
    }

    setIsUpdating(true);
    const body = {
      form_info: {
        ...formData,
      },
      sudo_name: sudoName,
    }
    callPutApiWithAuth(
      `my_account/payment_option/${provider.id}/activate`,
      body,
      onSaveDone,
      () => setIsUpdating(false)
    );
  };

  useEffect(() => {
    if (provider && myUser) {
      if (provider.form_info) {
        setFormData({
          ...formData,
          ...provider.form_info,
        });
      } else {
        setFormData({
          ...formData,
          name: myUser.first_name+' '+myUser.last_name,
          phone: myUser.phone,
        });
        // if (myUser.shipping_details.length > 0) {
        //   const shippingDetail = myUser.shipping_details[0];
          
        // } else if (myUser.billing_details.length > 0) {
        //   const billingDetail = myUser.billing_details[0];
        //   setFormData({
        //     ...formData,
        //     phone: myUser.phone,
        //     country: billingDetail.billing_country || "",
        //     state: billingDetail.billing_state || "",
        //     city: billingDetail.billing_city || "",
        //     address: billingDetail.billing_address || "",
        //     address_line2: billingDetail.billing_address_line2 || "",
        //     zipcode: billingDetail.billing_zipcode || "",
        //   });
        // }
      }
    }
  }, [provider, myUser]);

  return (
    <div className="payment-card-section">
      <div className="payment-title">
        <span>Direct Deposit - Nigeria Only</span>
        {isActive && <span className="active-badge">ACTIVE</span>}
      </div>
      <div className="payment-form-text" style={{ marginBottom: 10 }}>
        Please provide your bank account information.
      </div>
      <div className="payment-form">
        <Row gutter={[12, 8]}>
          <Col xs={24} sm={8}>
            <div className="form-group">
              <Input
                label="Name on Account*"
                value={formData.name}
                onChange={(e) => onFormInput("name", e.target.value)}
              />
            </div>
          </Col>
          <Col xs={24} sm={8}>
            <div className="form-group">
              <Input
                label="Account Number*"
                value={formData.account_number}
                onChange={(e) => onFormInput("account_number", e.target.value)}
              />
            </div>
          </Col>
          <Col xs={24} sm={8}>
            <div className="form-group">
              <Input
                label="Phone*"
                value={formData.phone}
                onChange={(e) => onFormInput("phone", e.target.value)}
              />
            </div>
          </Col>
          <Col xs={24} sm={8}>
            <div className="form-group">
              <Input
                label="Bank Name*"
                value={formData.bank_name}
                onChange={(e) => onFormInput("bank_name", e.target.value)}
              />
            </div>
          </Col>
          <Col xs={24} sm={8}>
            <div className="form-group">
              <Input
                label="Bank Branch*"
                value={formData.bank_branch}
                onChange={(e) => onFormInput("bank_branch", e.target.value)}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col xs={24}>
            <div style={{ height: 24 }} />
            <div className="action-group">
              <Popconfirm onConfirm={handleSave}>
                <button loading={isUpdating}>
                  {provider.account ? `Activate` : `Save & Activate`}
                </button>
              </Popconfirm>
            </div>
          </Col>
        </Row>
      </div>
      <div className="alert-container-section">
        <div className="alert-section">
          <Alert
            style={{ margin: "5px 0px 5px", alignItems: "baseline" }}
            message={`Please note: Make sure ALL of your information is 100% accurate. If you input the wrong account information, your commissions may get sent to the wrong bank and we will be unable to recover your funds. If you decide to receive your funds in Naira, your commissions will be sent at a 980N to $1 USD exchange rate. Please allow 5-7 business days to receive your funds.`}
            type={"info"}
            showIcon
          />{" "}
        </div>
      </div>
    </div>
  );
}

import React, { useState } from 'react'
import { Select } from 'components/common'

export default function PositionTd(props) {
  const [posType, setPosType] = useState('')

  const onChangePosType = (v) => {
    setPosType(v)
    let tableData0 = [...props.tableData]
    tableData0[props.index] = {
      ...props.data,
      pos_type: v
    }
    props.setTableData(tableData0)
  }
  
  return (
    <div>
      <Select
        options={[
          { label: 'Select Position', value: '' },
          { label: 'Bottom Left', value: 'bottom_left' },
          { label: 'Bottom Right', value: 'bottom_right' },
          { label: 'Enter Placement Sponsor', value: 'placement' },
        ]}
        value={posType}
        onChange={v=>onChangePosType(v)}
        style={{ width: 220 }}
      />
    </div>
  )
}

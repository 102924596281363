import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { notification, Table } from 'antd'
import axios from 'axios'
import { varLabel, varIs, varIsIn } from 'common/var'
import { asLocalPrice, asUsdPrice, asNumber, asDate, asCurrencyPrice } from 'utils/text'
import { CheckCircleOutlined, IssuesCloseOutlined, InfoCircleOutlined } from '@ant-design/icons'

export default function Confirmation(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const [subtotalPrice, setSubtotalPrice] = useState(0)

  const columns = [
    {
      title: 'Title',
      key: 'title',
      dataIndex: 'title'
    },
    {
      title: 'Unit price',
      key: 'unit_price',
      dataIndex: 'unit_price',
      render: (text) => <>{asLocalPrice(text)}</>
    },
    {
      title: 'Quantity',
      key: 'quantity',
      dataIndex: 'quantity'
    },
    {
      title: 'Frequency',
      dataIndex: 'pay_cycle',
      key: 'pay_cycle'
    },
    {
      title: 'Line Total',
      key: 'line_total_price',
      dataIndex: 'line_total_price'
    },
  ]

  const [tableData, setTableData] = useState([])

  const paySunTech = () => {
    document.getElementById('suntech_cnbform').submit();
  }

  const payMaxiCash = () => {
    document.getElementById('maxicash_cnbform').submit();
  }

  const paySkrill = () => {
    window.open(props.confirmResult.shopper_url, '_self')
  }

  const payBlueSnapHosted = () => {
    window.open(props.confirmResult.checkout_url, '_blank')
  }

  const payIpayAfrica = () => {
    window.open(props.confirmResult.checkout_url, '_blank')
  }

  useEffect(() => {
    if (props.confirmResult) {
      const orderDetails = props.confirmResult.order.details
      let subtotalPrice = 0
      let tData = []
      orderDetails.map(el=>{
        subtotalPrice += el.local_price*el.quantity
        tData.push({
          title: el.title,
          quantity: el.quantity,
          pay_cycle: el.pay_cycle,
          unit_price: asCurrencyPrice(el.local_price, props.confirmResult.order.currency_code),
          line_total_price: asCurrencyPrice(el.local_price * el.quantity, props.confirmResult.order.currency_code),
        })
      })
      setSubtotalPrice(subtotalPrice)
      setTableData(tData)
    }
  }, [props.confirmResult])  

  useEffect(() => {
    dispatch({
      type: 'checkout.CLEAR_CART'
    })
  }, [])

  return (
    <div className='wrapper order-completion'>
      <div className='d-flex flex-column align-items-center result-content'>
        {props.confirmResult && 
          <>
            {(
              varIs('order.isFlagged', 
                props.confirmResult.order.is_flagged, 'yes'
              )
            ) && 
              <>
                <InfoCircleOutlined className='confirmed-icon flagged' />
                <h2>We have detected fraud activity.</h2>
                <p>            
                  Please go to your account and upload picture of your identity card and credit card.
                </p>
              </>
            }
            {(
              varIs('order.isFlagged', 
                props.confirmResult.order.is_flagged, 'no'
              ) && 
              varIsIn('order.payType', 
                props.confirmResult.order.pay_type, 
                [
                  'cc', 'wallet', 
                  'free', 'gift_wallet', 'loyalty_wallet',
                  'cc+wallet', 'cc+gift_wallet',
                  'cc+loyalty_wallet', 'cash'
                ]
              )
            ) && 
              <>
                <CheckCircleOutlined className='confirmed-icon' />
                <h2>Your order is complete!</h2>
              </>
            }
            {(
              varIs('order.isFlagged', 
                props.confirmResult.order.is_flagged, 'no'
              ) && 
              varIsIn('order.payType', 
                props.confirmResult.order.pay_type, 
                [ 'bank' ]
              )
            ) && 
              <>
                <IssuesCloseOutlined className='confirmed-icon bitcoin' />
                <h2>Your order will be completed once bank payment settled!</h2>
              </>
            }
            {(
              varIs('order.isFlagged', 
                props.confirmResult.order.is_flagged, 'no'
              ) && 
              varIsIn('order.payType', 
                props.confirmResult.order.pay_type, 
                ['coin']
              )
            ) && 
              <>
                <IssuesCloseOutlined className='confirmed-icon bitcoin' />
                <h2>Please complete your order by paying BTC to the below address!</h2>
                <div>
                  IMPORTANT: <strong>Please pay the EXACT amount on your invoice within the next 24 hours to complete your order. If we do not receive your payment, your order will be canceled. Use the wallet address by manually entering it or scan the QR code below.</strong>
                </div>
                <div className='text-center'>
                  <h4 className='errorWrapper'>
                    <strong>Total Amount:&nbsp;
                    {`${props.confirmResult.order.merchant_amount} (${props.confirmResult.order.coin_type.toUpperCase()})`}</strong>
                  </h4>
                </div>
                <div className="text-center">
                  Wallet Address: {props.confirmResult?.coin_wallet}
                </div>
                <br/>
                <img
                  className="img-fluid checkout-confirm__image mt-3"
                  alt="checkoutImage"
                  src={props.confirmResult?.transaction_urls?.qrcode_url}
                /> 
                {/* <h2>Please complete your order in BitPay!</h2> */}
                {/*
                <div className="text-center checkout-confirm__warning">
                  <strong>We will remove your account if you don't send payment within 8 hours after you registered.</strong>
                </div>
                */}
                {/* <img
                  className="img-fluid checkout-confirm__image mt-3"
                  alt="checkoutImage"
                  src={props.confirmResult?.transaction_urls?.qrcode_url}
                /> */}
                {/* <div style={{height: 12}} />
                <div className='d-flex justify-content-center align-items-center'>
                  <a className='btn btn-primary' 
                    href={props.confirmResult?.transaction_urls?.checkout_url} 
                    target="_blank"
                    rel="noreferrer"
                  >
                    Pay Coin Now
                  </a>
                </div> */}
              </>
            }
            {(
              varIs('order.isFlagged', 
                props.confirmResult.order.is_flagged, 'no'
              ) && 
              varIsIn('order.payType', 
                props.confirmResult.order.pay_type, 
                ['manual']
              ) && 
              props.confirmResult.user.billing_detail.manual_method=='SunTech'
            ) && 
              <>
                <IssuesCloseOutlined className='confirmed-icon bitcoin' />
                <h2>Please complete your order in SunTech!</h2>
                {asCurrencyPrice(props.confirmResult.order.merchant_amount, props.confirmResult.order.currency_code)}
                <div className='d-flex justify-content-center align-items-center'>
                  <button className='btn btn-primary' onClick={paySunTech}>
                    Pay Now
                  </button>
                </div>
                <div dangerouslySetInnerHTML={{ 
                  __html: props.confirmResult.cnb_form
                }} />
              </>
            }
            {(
              varIs('order.payType', 
                props.confirmResult.order.pay_type, 
                'manual'
              ) && 
              props.confirmResult.user.billing_detail.manual_method==='BlueSnapGooglePay'
            ) && 
              <>
                <IssuesCloseOutlined className='confirmed-icon bitcoin' />
                <h2>Please complete your order in Google Pay!</h2>
                <div className='d-flex justify-content-center align-items-center'>
                  <button className='btn btn-primary' onClick={payBlueSnapHosted}>
                    Pay Now
                  </button>
                </div>
              </>
            }
            {(
              varIs('order.payType', 
                props.confirmResult.order.pay_type, 
                'manual'
              ) && 
              props.confirmResult.user.billing_detail.manual_method==='BlueSnapSkrill'
            ) && 
              <>
                <IssuesCloseOutlined className='confirmed-icon bitcoin' />
                <h2>Please complete your order in Skrill!</h2>
                <div className='d-flex justify-content-center align-items-center'>
                  <button className='btn btn-primary' onClick={payBlueSnapHosted}>
                    Pay Now
                  </button>
                </div>
              </>
            }
            {(
              varIs('order.payType', 
                props.confirmResult.order.pay_type, 
                'manual'
              ) && 
              props.confirmResult.user.billing_detail.manual_method==='BlueSnapApplePay'
            ) && 
              <>
                <IssuesCloseOutlined className='confirmed-icon bitcoin' />
                <h2>Please complete your order in Apple Pay!</h2>
                <div className='d-flex justify-content-center align-items-center'>
                  <button className='btn btn-primary' onClick={payBlueSnapHosted}>
                    Pay Now
                  </button>
                </div>
              </>
            }
            {(
              varIs('order.payType', 
                props.confirmResult.order.pay_type, 
                'manual'
              ) && 
              props.confirmResult.user.billing_detail.manual_method==='Skrill'
            ) && 
              <>
                <IssuesCloseOutlined className='confirmed-icon bitcoin' />
                <h2>Please complete your order in Skrill!</h2>
                {asCurrencyPrice(props.confirmResult.order.merchant_amount, props.confirmResult.order.currency_code)}
                <div className='d-flex justify-content-center align-items-center'>
                  <button className='btn btn-primary' onClick={paySkrill}>
                    Pay Now
                  </button>
                </div>
              </>
            }
            {(
              varIs('order.payType', 
                props.confirmResult.order.pay_type, 
                'manual'
              ) && 
              props.confirmResult.user.billing_detail.manual_method==='BlueSnapCc'
            ) && 
              <>
                <IssuesCloseOutlined className='confirmed-icon bitcoin' />
                <h2>Please complete your order in Secured Payment Page!</h2>
                <div className='d-flex justify-content-center align-items-center'>
                  <button className='btn btn-primary' onClick={payBlueSnapHosted}>
                    Pay Now
                  </button>
                </div>
              </>
            }
            {(
              varIs('order.payType', 
                props.confirmResult.order.pay_type, 
                'manual'
              ) && 
              props.confirmResult.user.billing_detail.manual_method=='IpayAfrica'
            ) && 
              <>
                <IssuesCloseOutlined className='confirmed-icon bitcoin' />
                <h2>Please complete your order in iPayAfrica!</h2>
                {asCurrencyPrice(props.confirmResult.order.merchant_amount, props.confirmResult.order.currency_code)}
                <div className='d-flex justify-content-center align-items-center'>
                  <button className='btn btn-primary' onClick={payIpayAfrica}>
                    Pay Now
                  </button>
                </div>
              </>
            }
            {(
              varIs('order.payType', 
                props.confirmResult.order.pay_type, 
                'manual'
              ) && 
              props.confirmResult.user.billing_detail.manual_method=='BlueSnapLbt'
            ) && 
              <>
                <IssuesCloseOutlined className='confirmed-icon bitcoin' />
                <div style={{width: '100%'}}>
                  <p>
                    <strong>
                    Dear {props.confirmResult.user.first_name}&nbsp;
                    {props.confirmResult.user.last_name},<br/>
                    Your order (#{props.confirmResult.order.order_number}) has been successfully created.
                    </strong>
                  </p>
                  <p>
                    Please continue and follow the instructions below to complete your order by Bank Transfer.
                    <br/>
                    If you have any questions, you can contact customer support at any time.
                  </p>
                </div>
                <h4>Payment By Bank Transfer</h4>
                <div className='confirm-box confirm-box-2' style={{width: '100%'}}>
                  <div className='row'>
                    <div className='col-sm-12'>
                      <p>To finalize your purchase, please transfer&nbsp;
                        {asCurrencyPrice(props.confirmResult.order.merchant_amount, props.confirmResult.order.currency_code)}&nbsp;
                        to the bank account details presented below.&nbsp;
                        After you transfer funds, please allow 1-5 business days for your order to be completed.&nbsp;
                        Once your funds are received, your product(s) will be delivered immediately.
                      </p>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-12 col-md-6 customer-container'>
                      <p>
                        <strong>Amount to Transfer:</strong>
                      </p>
                      <p>
                        <span>{asCurrencyPrice(props.confirmResult.order.merchant_amount, props.confirmResult.order.currency_code)}</span>
                      </p>
                    </div>
                    <div className='col-sm-12 col-md-6 customer-container'>
                      <p>
                        <strong>Account Number:</strong>
                      </p>
                      <p>
                        <span>{props.confirmResult.cnb_form.bankAccountNumber}</span>
                      </p>
                    </div>
                    <div className='col-sm-12 col-md-6 customer-container'>
                      <p>
                        <strong>Bank Name:</strong>
                      </p>
                      <p>
                        <span>{props.confirmResult.cnb_form.bankName}</span>
                      </p>
                    </div>
                    <div className='col-sm-12 col-md-6 customer-container'>
                      <p>
                        <strong>Bank Country:</strong>
                      </p>
                      <p>
                        <span>{props.confirmResult.cnb_form.countryDescription}</span>
                      </p>
                    </div>
                    <div className='col-sm-12 col-md-6 customer-container'>
                      <p>
                        <strong>Bank City:</strong>
                      </p>
                      <p>
                        <span>{props.confirmResult.cnb_form.bankCity}</span>
                      </p>
                    </div>
                    <div className='col-sm-12 col-md-6 customer-container'>
                      <p>
                        <strong>Bank Code:</strong>
                      </p>
                      <p>
                        <span>{props.confirmResult.cnb_form.bankCode}</span>
                      </p>
                    </div>
                    <div className='col-sm-12 col-md-6 customer-container'>
                      <p>
                        <strong>IBAN:</strong>
                      </p>
                      <p>
                        <span>{props.confirmResult.cnb_form.iban}</span>
                      </p>
                    </div>
                    <div className='col-sm-12 col-md-6 customer-container'>
                      <p>
                        <strong>Swiftcode:</strong>
                      </p>
                      <p>
                        <span>{props.confirmResult.cnb_form.swiftCode}</span>
                      </p>
                    </div>
                    <div className='col-sm-12 customer-container'>
                      <p>
                        <strong>Payment Reference #:</strong>
                      </p>
                      <p>
                        <strong>
                        <span className={'alert-text'}>{props.confirmResult.cnb_form.paymentReference}</span>
                        </strong>
                        <br/>
                        <strong>
                        <span className={'alert-text'}>
                          * You must include the payment reference number when you perform the transfer.
                        </span>
                        </strong>
                      </p>
                    </div>
                    <div className='col-sm-12'>
                      <p><u>Important Notes</u></p>
                      <p>
                        <strong>1. Tell your bank that 'Sender pays all fees.'</strong><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;Since the fees charged by your bank or any other intermediate bank(s)&nbsp;
                        are NOT included in your order, it's important that you ask&nbsp;
                        your bank for additional fees that should be added to your order total.
                      </p>
                      <p>
                        <span className={'alert-text'}>
                          <strong>2. Include your payment reference number on the bank transfer form.</strong><br/>
                          &nbsp;&nbsp;&nbsp;&nbsp;Your payment reference number is: [#{props.confirmResult.cnb_form.paymentReference}]
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </>
            }
            {(
              varIs('order.payType', 
                props.confirmResult.order.pay_type, 
                'manual'
              ) && 
              props.confirmResult.user.billing_detail.manual_method=='MaxiCash'
            ) && 
              <>
                <IssuesCloseOutlined className='confirmed-icon bitcoin' />
                <h2>Please complete your order in MaxiCash!</h2>
                {asCurrencyPrice(props.confirmResult.order.merchant_amount, props.confirmResult.order.currency_code)}
                <div className='d-flex justify-content-center align-items-center'>
                  <button className='btn btn-primary' onClick={payMaxiCash}>
                    Pay Now
                  </button>
                </div>
                <div dangerouslySetInnerHTML={{ 
                  __html: props.confirmResult.cnb_form
                }} />
              </>
            }
            <div style={{ width: '100%' }}>
              <div className='confirm-box'>
                <p><strong>Order Details</strong></p>
                <Table 
                  className='table-line-items'
                  columns={columns} 
                  dataSource={tableData} 
                  pagination={false} 
                />
              </div>
              <div className='d-flex justify-content-between'>
                <p>Order Number</p>
                <p>{props.confirmResult.order.order_number}</p>
              </div>
              <div className='d-flex justify-content-between'>
                <p>Order Date</p>
                <p>{asDate(props.confirmResult.order.created_at)}</p>
              </div>
              <div className='d-flex justify-content-between'>
                <p>Subtotal</p>
                <p>{asCurrencyPrice(subtotalPrice, props.confirmResult.order.currency_code)}</p>
              </div>
              <div className='d-flex justify-content-between'>
                <p>PV</p>
                <p>{asNumber(props.confirmResult.order.order_total_pv)}</p>
              </div>
              <div className='d-flex justify-content-between'>
                <p>Shipping</p>
                <p>{asCurrencyPrice(props.confirmResult.order.local_shipping_price, props.confirmResult.order.currency_code)}</p>
              </div>
              <div className='d-flex justify-content-between'>
                <p>Tax</p>
                <p>{asCurrencyPrice(props.confirmResult.order.local_tax_amount, props.confirmResult.order.currency_code)}</p>
              </div>
              {(
                varIs('order.payType', 
                  props.confirmResult.order.pay_type, 
                  'manual'
                ) && 
                (
                  props.confirmResult.user.billing_detail.manual_method=='BlueSnapCc' || 
                  props.confirmResult.user.billing_detail.manual_method=='BlueSnapGooglePay' || 
                  props.confirmResult.user.billing_detail.manual_method=='BlueSnapSkrill' || 
                  props.confirmResult.user.billing_detail.manual_method=='BlueSnapApplePay'
                )
              ) ? '' 
              : 
                <div className='d-flex justify-content-between total-price'>
                  <p>
                    Total
                  </p>
                  <p>
                    {varIs('order.payType', props.confirmResult.order.pay_type, 'coin')?
                      `${props.confirmResult.order.merchant_amount} (${props.confirmResult.order.coin_type.toUpperCase()})`
                    : asCurrencyPrice(props.confirmResult.order.local_order_total_amount, props.confirmResult.order.currency_code)}
                  </p>
                </div>
              }
              {/*
              <>
              {(props.confirmResult.order.is_flagged===2) &&           
                <div className='d-flex justify-content-center  mt-3'>
                  {(varIs('order.payType', props.confirmResult.order.pay_type, 'coin')) && 
                    <a className='btn btn-primary' href={props.confirmResult?.transaction_urls?.checkout_url} target="_blank">
                      Pay Coin Now
                    </a>
                  }
                  {(varIs('order.payType', props.confirmResult.order.pay_type, 'manual') && 
                    props.confirmResult.user.billing_detail.manual_method=='LiqPay'
                  ) && 
                    <div dangerouslySetInnerHTML={{ 
                      __html: props.confirmResult.cnb_form
                    }} />
                  }
                  {(varIs('order.payType', props.confirmResult.order.pay_type, 'manual') && 
                    props.confirmResult.user.billing_detail.manual_method=='SunTech'
                  ) && 
                  <>
                    
                  </>
                  }
                </div>
              }
              </>
              */}
            </div>
          </>
        }
      </div>
    </div>
  )
}
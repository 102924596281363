import React from "react";
import { Gauge } from "@ant-design/plots";
import styled from "styled-components";

export default function GuagePlot(props) {
  const config = {
    percent: (props.percent * 1) / 100 || 0,
    range: {
      color: "l(0) 0:#7FCFF4 1:#1EA9EA",
      width: 20,
    },
    gaugeStyle: {
      lineCap: "round",
    },
    startAngle: Math.PI,
    endAngle: 2 * Math.PI,
    indicator: null,
    statistic: {
      title: {
        offsetY: -36,
        style: {
          fontSize: "20px",
          color: "#212B36",
          fontWeight: 800,
        },
        formatter: () => `${props.percent || 0}%`,
      },
      content: {
        style: {
          fontSize: "12px",
          lineHeight: "44px",
          fontWeight: 400,
          color: "#919EAB",
          lineHeight: "80px",
        },
        formatter: () => (props.percent == 100 ? "Completed" : "Incomplete"),
      },
    },
  };
  return (
    <Wrapper>
      <Gauge {...config} />
    </Wrapper>
  );
}
export const Wrapper = styled.div``;

import styled from "styled-components";

export const Wrapper = styled.div`
  .main-container {
    padding-bottom: 80px;
    .filter-options {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .page-title {
        font-weight: 700;
        font-size: 18px;
        color: #212B36;
      }
      .select-box {
        min-width: 200px;
        height: 40px !important;
        .ant-select-selector {
          height: 40px !important;
          padding-top: 4px !important;
        }
      }
      .select-video-section {
        display: flex;
        align-items: center;
        label {
          margin-right: 4px;
          color: #7f8fa3;
        }
        .play-icon {
          font-size: 28px;
          color: #1890ff;
          margin-left: 6px;
          cursor: pointer;
        }
        .loading-icon {
          font-size: 28px;
          color #1890ff;
          margin-left: 6px;
        }
      }
    }
  }
  .loading-root {
    width: 100%;
    height: 400px;
  }
  @media (max-width: 575px) {
    .filter-options {
      .select-wrapper {
        min-width: 152px !important;
      }
      .select-box {
        min-width: 152px !important;
      }
    }
  }
`;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Row, Col } from "antd";
import { asUsdPrice, asNumber } from "utils/text";
import { callGetApiWithAuth } from "utils/api";
import { ReactComponent as PlantTreeIcon } from "assets/icons/home/plant-tree.svg";
import { ReactComponent as DonateIcon } from "assets/icons/home/donate.svg";
import { ReactComponent as TreeIcon } from "assets/icons/home/tree.svg";

export default function OnetreeSection() {
  const [searchParam, setSearchParam] = useState({
    scope: "you",
  });
  const [statData, setStatData] = useState({
    total_point: 0,
    total_amount: 0,
  });

  const onGetStatData = (res) => {
    setStatData(res.data);
  };
  const onFailStatData = () => {};
  const loadStatData = () => {
    const params = {
      ...searchParam,
    };
    const q = Object.keys(params)
      .map((k) => k + "=" + params[k])
      .join("&");
    callGetApiWithAuth(
      `home/onetree_donate?${q}`,
      onGetStatData,
      onFailStatData
    );
  };

  const goPlantTree = () => {
    window.open(`${process.env.REACT_APP_CORP_URL}/plant-tree`, '_blank')
  }

  useEffect(() => {
    loadStatData();
  }, [searchParam]);

  return (
    <Wrapper>
      <div className="w-head">
        <h2>House of Impact</h2>
        <div className="w-action">
          <span 
            className={`link-btn ${searchParam.scope == "you" ? "active" : ""}`}
            onClick={() => setSearchParam({ ...searchParam, scope: "you" })}
          >
            Personal Impact
          </span>
          <span
            className={`link-btn ${searchParam.scope == "company" ? "active" : ""}`}
            onClick={() => setSearchParam({ ...searchParam, scope: "company" })}
          >
            Company Impact
          </span>
        </div>
      </div>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12}>
          <div className="w-card">
            <div>
              <h4>Total Trees Planted</h4>
              <p className="green">{asNumber(statData.total_point)}</p>
            </div>
            <PlantTreeIcon />
          </div>
        </Col>
        <Col xs={24} sm={12}>
          <div className="w-card">
            <div>
              <h4>Total Amount Donated</h4>
              <p className="">{asUsdPrice(statData.total_amount)}</p>
            </div>
            <DonateIcon />
          </div>
        </Col>
        <Col xs={24}>
          <div className="w2-card">
            <TreeIcon />
            <h4>Creating Change with Every Purchase</h4>
            <p>
              Our goal is to plant 250,000 trees and combat deforestation. Buy
              Brand Bucks or Round Up your next purchase to start planting
              trees.
            </p>
            <a href='#' onClick={goPlantTree}>
              Learn More
            </a>
          </div>
        </Col>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  box-shadow: 0px 0px 2px rgb(145 158 171 / 20%),
    0px 12px 24px -4px rgb(145 158 171 / 12%);
  border-radius: 16px;
  height: 100%;
  padding: 24px;
  .w-head {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  h2 {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #212b36;
    margin-bottom: 0;
  }
  .w-action {
    @media (max-width: 768px) {
      margin-top: 12px;
    }
    .link-btn {
      font-size: 14px;
      font-weight: 600;
      line-height: 40px;
      color: #232838;
      width: 120px;
      padding: 11px;
      text-align: center;
      border: 1px solid #E5E8EB;
      cursor: pointer;
      &.active {
        color: #fff;
        background: #4ABAEF;
      }
      &:first-child {
        border-radius: 10px 0 0 10px;
      }
      &:last-child {
        border-radius: 0 10px 10px 0;
      }
    }
  }
  .w-card {
    border: 0.62642px solid rgba(145, 158, 171, 0.24);
    border-radius: 10.0227px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h4 {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #919eab;
    }
    p {
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      margin: 0;
      color: #3AC7FF;
      &.green {
        color: #48BC5B;
      }
    }
    svg {
    }
  }
  .w2-card {
    border: 0.62642px solid rgba(145, 158, 171, 0.24);
    border-radius: 10.0227px;
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    h4 {
      font-weight: 700;
      font-size: 20px;
      text-align: center;
      margin: 14px 0;
    }
    p {
      font-size: 14px;
      opacity: 0.5;
      margin: 0;
      text-align: center;
    }
    svg {
    }
    a {
      background: #48BC5B;
      width: 200px;
      border-radius: 4px;
      color: #fff;
      font-size: 14px;
      padding: 13px 0;
      text-align: center;
      text-transform: uppercase;
      margin-top: 30px;
    }
  }
`;

import styled from "styled-components";

export const Wrapper = styled.div`
  .modal-new-title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #212b36;
    margin-bottom: 20px;
  }
  .label {
    display: none;
  }
  .ant-input,
  .ant-select-selector {
    height: 40px !important;
  }
  .action-btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .cancel-btn {
      border-radius: 8px !important;
      height: 40px;
      padding: 0px 15px;
      margin-left: 12px;
      border: 1px solid rgba(145, 158, 171, 0.32);
      background: white;
      color: black;
    }
    .create-btn {
      background: #1ea9ea;
      border-radius: 8px !important;
      height: 40px;
      padding: 0px 15px;
      margin-left: 12px;
    }
  }
  .input-container {
    .ant-picker {
      width: 100% !important;
    }
  }
  .input-container > div {
    height: 100% !important;
  }
  .icon-container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    height: 100%;
    padding-bottom: 16px;
    svg {
      cursor: pointer;
      width: auto;
      height: 24px;
      :nth-child(2) {
        margin-left: 12px;
      }
    }
  }
  .product-row {
    margin-bottom: 10px;
  }
  .dob-outlined {
    .ant-picker {
      padding: 0px;
      .ant-picker-input {
        border: 1px solid rgba(145, 158, 171, 0.32);
        border-radius: 8px !important;
        height: 40px !important;
        padding: 0px 14px;
      }
    }
    .ant-picker-clear {
      right: 12px !important;
    }
    position: relative;
    .ant-input {
      height: 40px !important;
      border: 1px solid rgba(145, 158, 171, 0.32);
      border-radius: 8px !important;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #212b36;
      padding: 12px 14px;
    }
    .ant-input-affix-wrapper.ant-input-affix-wrapper-outlined {
      border-radius: 4px;
      position: relative;
    }

    .ant-input-affix-wrapper.ant-input-affix-wrapper-outlined
      .ant-input:not(:first-child) {
      border-radius: 4px;
      padding-top: 26px;
    }

    .ant-input-affix-wrapper.ant-input-affix-wrapper-outlined .ant-input:focus,
    .ant-input-affix-wrapper.ant-input-affix-wrapper-outlined
      .ant-input-focused {
      border-color: #1890ff;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }

    .ant-input-affix-wrapper.ant-input-affix-wrapper-outlined .ant-input:hover {
      border-color: #40a9ff;
    }

    .ant-input-affix-wrapper.ant-input-affix-wrapper-outlined
      .ant-input:not(:first-child)::placeholder {
      opacity: 0.6;
    }

    .ant-input-affix-wrapper.ant-input-affix-wrapper-outlined
      .ant-input:not(:first-child):focus::placeholder,
    .ant-input-affix-wrapper.ant-input-affix-wrapper-outlined
      .ant-input-focused::placeholder {
      opacity: 0.4;
    }

    .ant-form-item-label {
      position: absolute;
      top: 12px;
      left: 14px;
      transition: all 0.2s ease-out;
      pointer-events: none;
      color: #919eab;
    }

    .ant-form-item-label-active {
      transform: translateY(-15px) scale(1);
      font-weight: 400;
      font-size: 12px;
      color: #919eab;
      background: white;
      z-index: 4;
      padding: 0px 1px;
    }
  }
  .dob-input {
    width: 100% !important;
    height: 47px !important;
  }
`;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Card,
  Spin,
  FormItem,
  Input,
  Button,
  OutlineBtn,
  Popconfirm,
  SuccessNotification,
} from "components/common";
import {
  callGetApiWithAuth,
  callPutApiWithAuth,
  callPostApiWithAuth,
} from "utils/api";
import { ReactComponent as TickIcon } from "assets/icons/tick.svg";
import MDCGuide from "assets/images/mdc-guide.png";
import MaBgImg from "assets/images/maBg.png";

export default function FormView() {
  const [formData, setFormData] = useState({
    chat_id: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isTesting, setIsTesting] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  const onGetFormData = (res) => {
    if (res.data) {
      setFormData(res.data);
    }
    setIsLoading(false);
  };
  const onFailFormData = () => {
    setIsLoading(false);
  };

  const onFormInput = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const onUpdateDone = (res) => {
    setIsUpdating(false);
    SuccessNotification(res.message);
  };
  const onUpdateFail = () => {
    setIsUpdating(false);
  };
  const handleSave = () => {
    // Validation
    let errorMessages0 = [];
    if (!formData.chat_id) {
      errorMessages0.push({
        field: "chat_id",
        message: "Please input Chat ID",
      });
    } else {
      if (!formData.chat_id.match(/^\d+$/)) {
        errorMessages0.push({
          field: "chat_id",
          message: "Your Chat ID is wrong",
        });
      }
    }

    setErrorMessages(errorMessages0);
    if (errorMessages0.length > 0) {
      return;
    }

    setIsUpdating(true);
    const data = {
      ...formData,
    };
    callPutApiWithAuth(
      `report/telegram_report/telegram_id`,
      data,
      onUpdateDone,
      onUpdateFail
    );
  };

  const onTestDone = (res) => {
    SuccessNotification(res.message);
    setIsTesting(false);
  };
  const onTestFail = () => {
    setIsTesting(false);
  };
  const handleTest = () => {
    setIsTesting(true);
    const body = {
      chat_id: formData.chat_id,
    };
    callPostApiWithAuth(
      `report/telegram_report/test_connection`,
      body,
      onTestDone,
      onTestFail
    );
  };

  useEffect(() => {
    setIsLoading(true);
    callGetApiWithAuth(
      "report/telegram_report/telegram_id",
      onGetFormData,
      onFailFormData
    );
  }, []);

  return (
    <Wrapper>
      <div className="header-bar">
        <h4>Telegram Report</h4>
        <p>
          Have your sales reports, volume, and rank data sent to you daily on
          Telegram. These reports include: Paid Rank, Current Rank, Binary
          Qualified, Qualified Volume, Enroller Tree Volume, Daily Paid
          Enrollments, Daily Free Enrollments, Daily Prospects, Daily New
          Volume, and Daily Subscription Orders.
        </p>
      </div>

      <div className="white-rounded-bg main-description">
        <div className="row">
          <div className="left-guide col-xs-12 col-sm-6">
            <div>
              <h4>Installation Guidelines:</h4>
              <div className="alert-text">
                * Please make sure you have @Username in telegram. You can check
                it in Telegram &gt; Settings &gt; Edit Profile.
              </div>
            </div>
            <div className="step-no">
              <div>
                <div className="step-heading">
                  <TickIcon /> <span>Step 1</span>
                </div>
                <div className="step-content">
                  Please click this &nbsp;
                  <a
                    href="https://t.me/userinfobot"
                    target="_blank"
                    rel="noreferrer"
                  >
                    link
                  </a>
                  &nbsp; to retrieve your Telegram Chat ID.
                </div>
              </div>
              <div>
                <div className="step-heading">
                  <TickIcon /> <span>Step 2</span>
                </div>
                <div className="step-content">
                  Copy Chat ID to this page and click “save”.
                </div>
              </div>
              <div>
                <div className="step-heading">
                  <TickIcon /> <span>Step 3</span>
                </div>
                <div className="step-content">
                  Click on our &nbsp;
                  <a
                    href="https://t.me/mdc_v2_report_bot"
                    target="_blank"
                    rel="noreferrer"
                  >
                    MDC Optimus
                  </a>
                  &nbsp; to start getting your reports.
                </div>
              </div>
            </div>
            <div>
              <h4>Telegram Report Installation:</h4>
              <div className="reg_form">
                <div className="form-group">
                  <Input
                    label="Chat ID"
                    value={formData.chat_id}
                    onChange={(e) => onFormInput("chat_id", e.target.value)}
                  />
                </div>
                <div className="form-action">
                  <Popconfirm onConfirm={handleSave}>
                    <Button loading={isUpdating}>Save</Button>
                  </Popconfirm>
                  {formData.chat_id ? (
                    <Popconfirm onConfirm={handleTest}>
                      <Button loading={isTesting}>Test Connection</Button>
                    </Popconfirm>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 mdc_guide">
            <img src={MDCGuide} />
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .header-bar {
    background: rgba(255, 171, 0, 0.16);
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2),
      0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
    padding: 24px;
    margin-bottom: 24px;
    h4 {
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      color: #ffab00;
      margin-bottom: 0px;
    }
    p {
      color: #7a4100;
      line-height: 22px;
      margin-bottom: 0px;
    }
  }

  .row {
    .left-guide {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .main-description {
    h4 {
      font-size: 18px;
      font-weight: 700;
      line-height: 28px;
      color: #212b36;
      margin-bottom: 0px;
    }
    .alert-text {
      color: ${(props) => props.theme.palette.error};
    }
    .step-no {
      margin: 24px 0px;
      .step-heading {
        margin: 8px 0px;
        span {
          margin-left: 8px;
        }
      }
      .step-content {
        padding: 16px 16px 16px 24px;
        margin-left: 12px;
        border-left: 1px solid rgba(145, 158, 171, 0.24);
        a {
          color: #1ea9ea;
          text-decoration: underline;
        }
      }
    }
  }
  .mdc_guide {
    display: flex;
    justify-content: center;
    @media (max-width: 560px) {
      img {
        width: 300px;
      }
    }
  }
  .reg_form {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 12px;

    @media (max-width: 560px) {
      flex-direction: column;
      .form-group, .form-action {
        width: 100%;
      }
      button {
        width: 100%;
      }
    }
    .ant-form-item-label {
      top: 9px !important;
    }
    .ant-form-item-label-active {
      transform: translateY(-18px) scale(1);
    }
    .ant-input {
      height: 40px !important;
    }
  }
  .form-action {
    display: flex;
    align-items: center;
    gap: 12px;
  }
`;

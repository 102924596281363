import React from 'react'
import styled from 'styled-components'
import { 
  Modal, Row, Col, CountryFlag,
  Tag, Tooltip
} from "components/common"
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube, FaLinkedinIn } from "react-icons/fa"
import { MdEmail, MdPhone } from "react-icons/md"
import BannerImg from "assets/images/profile_card_banner.png"
import NoPhotoIcon from "assets/images/user.svg"
import { countryName } from 'common/country'
import { uiVarOptions } from 'common/uiVar'

export default function PreviewModal(props) {
  const colors = uiVarOptions("profileCard.colors")
  return (
    <Modal
      width={900}
      onCancel={()=>props.setShowPreview(false)}
      className="preview-modal"
    >
      <Wrapper>
        <div className="banner-section">
          <img src={props.data.banner ? props.data.banner : BannerImg} />
        </div>
        <div className="modal-main-container">
          <Row gutter={[20, 20]}>
            <Col xs={24} md={16}>
              <div className="main-info">
                <div className="image-name-section">
                  <img src={props.userInfo.image ? props.userInfo.image : NoPhotoIcon} className="avatar-image" />
                  <div className="name-section">
                    <h5>{`${props.userInfo.first_name} ${props.userInfo.last_name}`}</h5>
                    <div className="country-section">
                      <span>{countryName(props.userInfo.country)}</span>
                      <CountryFlag country={props.userInfo.country} />
                    </div>
                  </div>
                </div>
                <div className="my-story-section">
                  <h5>My Story</h5>
                  <p>{props.data.my_story}</p>
                </div>
                {props.data.p_youtube && 
                <div className='pyoutube-section'>
                  <iframe title="pyoutube-player" 
                    type="text/html" width="360" height="240"
                    src={props.data.p_youtube}
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                    allowfullscreen
                  />
                </div>
                }
                <div className="interests-section">
                  <h5>Top Interests</h5>
                  <div className="interests">
                    {props.data.interests.map((el, index) => (
                      <div className="interest" key={index}>
                        <Tag
                          className="interest-tag"
                          style={{
                            color: uiVarOptions("profileCard.colors")[index % colors.length].value,
                            backgroundColor: uiVarOptions("profileCard.colors")[index % colors.length].value + "33"
                          }}
                        >
                          <span className="emoji-icon">{el.icon}</span>
                          <span>{el.title}</span>
                        </Tag>
                      </div>
                    ))}
                  </div>
                </div>
                {(props.data.twitter || props.data.facebook || props.data.instagram || props.data.linkedin || props.data.youtube) &&
                  <div className="connect-section">
                    <h5>Connect</h5>
                    <div className="connect-social-icons">
                      {props.data.twitter &&
                        <a className="social-icon" href={props.data.twitter} target="_blank">
                          <FaTwitter />
                        </a>
                      }
                      {props.data.facebook &&
                        <a className="social-icon" href={props.data.facebook} target="_blank">
                          <FaFacebookF />
                        </a>
                      }
                      {props.data.instagram &&
                        <a className="social-icon" href={props.data.instagram} target="_blank">
                          <FaInstagram />
                        </a>
                      }
                      {props.data.linkedin &&
                        <a className="social-icon" href={props.data.linkedin} target="_blank">
                          <FaLinkedinIn />
                        </a>
                      }
                      {props.data.youtube &&
                        <a className="social-icon" href={props.data.youtube} target="_blank">
                          <FaYoutube />
                        </a>
                      }
                    </div>
                  </div>
                }
              </div>
            </Col>
            <Col xs={24} md={8}>
              <div className="other-info">
                {(props.data.show_rank_info*1 === 1 && props.data.ranks && props.data.ranks.length > 0) &&
                  <div className="rank-section">
                    <h5>My Achievements</h5>
                    <div className="ranks">
                      <Row gutter={[5, 5]}>
                        {props.data.ranks.map((el, index) => (
                          el.badge ?
                            <Col xs={8} key={index}>
                              <Tooltip
                                title={el.name}
                              >
                                <img src={el.badge} className="rank-image" />
                              </Tooltip>
                            </Col>
                            :
                            ""
                        ))}
                      </Row>
                    </div>
                  </div>
                }
                <div className="products-section">
                  <h5>My Top 3 Products</h5>
                  <div className="products">
                    <Row gutter={[5, 5]}>
                      <Col xs={8}>
                        <Tooltip
                          title={props.productOptions.filter((el) => el.id*1 === props.data.product1_id*1)[0].title}
                        >
                          <div className="product-image-section">
                            <a href={`${process.env.REACT_APP_CORP_URL}/shop/${props.productOptions.filter((el) => el.id*1 === props.data.product1_id*1)[0].path}`} target="_blank">
                              <img src={props.productOptions.filter((el) => el.id*1 === props.data.product1_id*1)[0].image_path} className="product-image" />
                            </a>
                          </div>
                        </Tooltip>
                      </Col>
                      <Col xs={8}>
                        <Tooltip
                          title={props.productOptions.filter((el) => el.id*1 === props.data.product2_id*1)[0].title}
                        >
                          <div className="product-image-section">
                            <a href={`${process.env.REACT_APP_CORP_URL}/shop/${props.productOptions.filter((el) => el.id*1 === props.data.product2_id*1)[0].path}`} target="_blank">
                              <img src={props.productOptions.filter((el) => el.id*1 === props.data.product2_id*1)[0].image_path} className="product-image" />
                            </a>
                          </div>
                        </Tooltip>
                      </Col>
                      <Col xs={8}>
                        <Tooltip
                          title={props.productOptions.filter((el) => el.id*1 === props.data.product3_id*1)[0].title}
                        >
                          <div className="product-image-section">
                            <a href={`${process.env.REACT_APP_CORP_URL}/shop/${props.productOptions.filter((el) => el.id*1 === props.data.product3_id*1)[0].path}`} target="_blank">
                              <img src={props.productOptions.filter((el) => el.id*1 === props.data.product3_id*1)[0].image_path} className="product-image" />
                            </a>
                          </div>
                        </Tooltip>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="contact-section">
                  <h5>Contact</h5>
                  <div className="contacts">
                    <a href={`mailto:${props.userInfo.email}`}><MdEmail />{props.userInfo.email}</a>
                    <a href={`tel:${props.userInfo.phone}`}><MdPhone />{props.userInfo.phone}</a>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Wrapper>
    </Modal>
  )
}

const Wrapper = styled.div`
  h5 {
    font-weight: 600;
  }
  .banner-section img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  .modal-main-container .main-info {
    padding: 30px;
  }
  .modal-main-container .main-info .image-name-section {
    display: flex;
    margin-top: -30px;
  }
  .modal-main-container .main-info .image-name-section .avatar-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-top: -75px;
    background-color: #ffffff;
  }
  .modal-main-container .main-info .image-name-section .name-section {
    margin-top: 15px;
    margin-left: 15px;
  }
  .modal-main-container .main-info .image-name-section .name-section .country-section {
    display: flex;
    align-items: center;
  }
  .modal-main-container .main-info .image-name-section .name-section .country-section img {
    height: 20px !important;
    width: 25px !important;
    margin-left: 5px;
  }
  .my-story-section {
    margin-top: 40px;
  }
  .interests-section {
    margin-top: 40px;
  }
  .interests-section .interests {
    display: flex;
    flex-wrap: wrap;
  }
  .interests-section .interest .interest-tag {
    border-radius: 15px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    border: unset;
    font-size: 14px;
    font-weight: 600;
    text-shadow: unset;
    padding: 5px 10px;
  }
  .interests-section .interest .interest-tag .emoji-icon {
    margin-right: 5px;
  }
  .connect-section {
    margin-top: 40px;
  }
  .connect-section .connect-social-icons {
    display: flex;
  }
  .connect-section .connect-social-icons .social-icon {
    font-size: 30px;
    color: #00b2ff;
    margin-right: 15px;
    cursor: pointer;
  }
  .other-info {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .other-info h5 {
    color: #00b2ff;
  }
  .other-info .rank-section {
    background-color: rgba(154, 198, 249, 0.1);
    padding: 30px;
    height: 100%;
  }
  .other-info .rank-section .rank-image {
    width: 100%;
  }
  .other-info .rank-section .ranks {
    margin-top: 20px;
  }
  .other-info .products-section {
    background-color: rgba(154, 198, 249, 0.1);
    padding: 30px;
    height: 100%;
  }
  .other-info .products-section .products {
    margin-top: 20px;
  }
  .other-info .products-section .products .product-image-section {
    width: 100%;
    padding-top: 100%;
    position: relative;
  }
  .other-info .products-section .products .product-image-section a {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .other-info .products-section .products .product-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top;
  }
  .other-info .contact-section {
    background-color: rgba(181, 181, 181, 0.1);
    padding: 30px;
    height: 100%;
  }
  .other-info .contact-section .contacts {
    display: flex;
    flex-direction: column;
  }
  .other-info .contact-section .contacts a {
    margin-bottom: 10px;
    color: rgba(0, 0, 0, 0.85);
  }
  .other-info .contact-section .contacts a svg {
    font-size: 18px;
    color: #00b2ff;
    margin-right: 10px;
  }
  .pyoutube-section {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
  }
  .pyoutube-section iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  @media only screen and (max-width: 500px) {
    .modal-main-container .main-info .image-name-section {
      flex-direction: column;
    }
    .modal-main-container .main-info .image-name-section .avatar-image {
      width: 98px;
      height: 98px;
    }
    .modal-main-container .main-info .image-name-section .name-section {
      margin-left: 0;
    }
  }
`

import React from "react";
import styled from "styled-components";

function StatsCard({ title, icon, amount, iconColor, ...props }) {
  return (
    <Wrapper iconColor={iconColor}>
      <div className="stats-container">
        <div>
          <div className="stats-label">{title}</div>
          <div className="stats-value">{amount}</div>
        </div>
        <div className="stats-icon">{icon}</div>
      </div>
    </Wrapper>
  );
}

export default StatsCard;

export const Wrapper = styled.div`
  .stats-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    height: 122px;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2),
      0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 17.0698px;
    padding: 24px 34px 24px 24px;
    .stats-label {
      color: #637381;
      font-weight: 600;
      font-size: 14px;
    }
    .stats-value {
      font-weight: 700;
      font-size: 32px;
      color: #212b36;
    }
    .stats-icon path {
      fill: ${(props) => (props.iconColor ? props.iconColor : "")};
    }
  }
`;

import { Button as Button0 } from "antd";
import styled from "styled-components";

export function Button({ bgColor, size, border, color, icon, ...props }) {
  return (
    <Wrapper
      {...props}
      bgColor={bgColor}
      size={size}
      color={color}
      border
      icon={icon}
    />
  );
}

const Wrapper = styled(Button0)`
  display: ${(props) => (props.icon ? "flex" : "")};
  gap: ${(props) => (props.icon ? "8px" : "")};
  background: ${(props) => props.bgColor || "#1EA9EA"};
  height: ${(props) =>
    props.size === "small" ? "34px" : props.size === "large" ? "48px" : "40px"};
  border: ${(props) =>
    props.border ? "1px solid rgba(145,158,171,0.32)" : "none"};
  color: ${(props) => props.color || "white"};
  border-radius: 8px !important;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  font-weight: 700;
  padding: 0px 15px;
  svg {
    width: 14px;
    path {
      fill: ${(props) => props.color || "#fff"};
    }
  }

  &:hover {
    background: ${(props) => props.bgColor || "#1EA9EA"};
    color: ${(props) => props.color || "white"};
    border: ${(props) =>
      props.border ? "1px solid rgba(145,158,171,0.32)" : "none"};
  }
  &:focus {
    background: ${(props) => props.bgColor || "#1EA9EA"};
    color: ${(props) => props.color || "white"};
    border: ${(props) =>
      props.border ? "1px solid rgba(145,158,171,0.32)" : "none"};
  }
`;

import React, { useState } from "react";
import styled from "styled-components";
import { PlayCircleFilled, DownloadOutlined } from "@ant-design/icons";
import { Button, VideoModal } from "components/common";
import { Row, Col } from "components/common";
function WatchCard({ data }) {
  const [isPlaying, setIsPlaying] = useState(false);

  const handleWatchVideo = () => {
    setIsPlaying(true);
  };

  const handleDownload = () => {
    setIsPlaying(false);
    window.open(data.file, "_blank");
  };

  const handleDownload2 = () => {
    window.open(data.file2, "_blank");
  }

  return (
    <Wrapper>
      <Row gutter={[12, 12]} className="watchCard">
        <Col xs={24} xl={20}>
          <Row gutter={[24, 24]} className="left-portion">
            <Col xs={24} xl={4}>
              <div className="thumbnail">
                <img src={data?.thumbnail} alt="thumbnail" />
              </div>
            </Col>
            <Col xs={24} xl={20}>
              <div className="details">
                <div className="watchCard-title">{data?.title}</div>
                <div className="watchCard-description">{data?.description}</div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={24} xl={4}>
          <div className="right-portion">
            {data.file2 && 
              <div
                className="actionButton download-icon"
                onClick={handleDownload2}
                style={{ marginRight: 8 }}
              >
                <DownloadOutlined />
              </div>
            }
            {data.file_type * 1 === 4 ? (
              <div
                className="actionButton play-icon"
                onClick={handleWatchVideo}
              >
                <PlayCircleFilled />
              </div>
            ) : (
              <div
                className="actionButton download-icon"
                onClick={handleDownload}
              >
                <DownloadOutlined />
              </div>
            )}
          </div>
        </Col>
      </Row>
      {/* Video Modal */}
      {data.file_type * 1 === 4 && isPlaying && (
        <VideoModal
          onCancel={() => setIsPlaying(false)}
          src={data.file}
          width={1200}
          isVimeo
        />
      )}
    </Wrapper>
  );
}

export default WatchCard;

const Wrapper = styled.div`
  .watchCard {
    display: flex;
    align-items: center;
    padding: 24px;
    background: #ffffff;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2),
      0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
    .left-portion {
      display: flex;
      align-items: center;
      .thumbnail {
        height: 90px;
        border-radius: 8px;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }
      }
      .watchCard-title {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #212b36;
        margin-bottom: 6px;
      }
      .watchCard-description {
        font-weight: 400;
        font-size: 16px;
        color: #637381;
      }
    }
    .right-portion {
      display: flex;
      gap: 12px;
      justify-content: end;
      align-items: center;
      .actionButton {
        cursor: pointer;
        width: 50px;
        height: 50px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        svg {
          width: 25px;
          height: 25px;
          path {
            fill: white;
          }
        }
      }
      .play-icon {
        background: #1ea9ea;
      }
      .download-icon {
        background: #637381;
      }
    }
    @media (max-width: 1200px) {
      .right-portion {
        justify-content: start;
      }
      .thumbnail {
        height: 150px !important;
      }
    }
  }
`;

import React, { useState, useEffect } from 'react'
import {
  Modal, Table, AutoComplete, 
  Button, OutlineBtn, Input, 
  Popconfirm, SuccessNotification, message,
} from 'components/common'
import { 
  callGetApiWithAuth,
  callPostApiWithAuth,
  callPutApiWithAuth,
} from 'utils/api'

export default function CreateModal(props) {
  const [listData, setListData] = useState([])
  const [userData, setUserData] = useState(undefined)
  const [userList, setUserList] = useState([])
  const [userInput, setUserInput] = useState('')
  const [isUpdating, setIsUpdating] = useState(false)
  const [mode, setMode] = useState('add')
  const [path, setPath] = useState('')

  const onSearchDone = (res) => {
    setUserList([
      res.data
    ])
  }
  const onSearchFail = () => {
    setUserList([])
  }
  const onSearchUser = (value) => {
    setUserInput(value)
    callGetApiWithAuth(
      `tool/traffic_rotator/search_rotate_user?username=${value}`,
      onSearchDone, onSearchFail
    )
  }
  const onSelectUser = (_, option) =>{
    userList.forEach(el => {
      if (el.id===option.key) {
        setUserData(el)
      }
    })
  }
  const onAddRow = () => {
    // Validation
    if (!userData) {
      message.error('Please select rotate user')
      return
    }
    let isAlready = false
    listData.forEach(el => {
      if (el.id===userData.id) {
        isAlready = true
      }
    })
    if (isAlready) {
      message.error('It is already added')
      return
    }

    let listData0 = [...listData]
    listData0.push(userData)
    setListData(listData0)
    setUserData(undefined)
    setUserInput('')
    setUserList([])
  }

  const onDeleteRow = (index) => {
    let listData0 = [...listData]
    listData0.splice(index, 1)
    setListData(listData0)
  }

  const onSubmitDone = (res) => {
    SuccessNotification(res.message)
    setIsUpdating(false)
    props.handleSearch()
    props.setModalMode(undefined)
  }
  const onSubmitFail = () => {
    setIsUpdating(false)
  }
  const handleSubmit = () => {
    // Validation
    if (listData.length===0) {
      message.error('Please input rotate users')
      return
    }
    if (!path) {
      message.error('Please input path')
      return
    }

    const body = {
      rotate_users: listData.map(el => ({
        user_id: el.id
      })),
      path: path.trim().replace(/\s/g, '-').replace(/[^a-z0-9-]/gi, '')
    }
    if (mode==='add') {
      setIsUpdating(true)
      callPostApiWithAuth(
        `tool/traffic_rotator/store`,
        body, onSubmitDone, onSubmitFail
      )
    } else if (mode==='edit') {
      setIsUpdating(true)
      callPutApiWithAuth(
        `tool/traffic_rotator/${props.data.path}/update`,
        body, onSubmitDone, onSubmitFail
      )
    }
  }

  useEffect(() => {
    if (props.data) {
      setMode('edit')
      setListData(props.data.rotate_users)
      setPath(props.data.path)
    }
  }, [props.data])

  return (
    <Modal 
      title={
        mode==='add'?'Create Traffic Rotator':
        mode==='edit'?'Update Traffic Rotator':''
      }
      onCancel={() => props.setModalMode(undefined)}
    >
      <div className='d-flex'>
        <AutoComplete 
          onSearch={onSearchUser}
          options={userList.map(el => ({
            key: el.id,
            label: `${el.first_name} ${el.last_name}`,
            value: `${el.first_name} ${el.last_name}`,
          }))}
          style={{width: 250}}
          onSelect={onSelectUser}
          placeholder={'Search User...'}
          value={userInput}
        />
        <span style={{width: 8}} />
        <Button onClick={onAddRow}>
          Add
        </Button>
      </div>
      <div style={{marginTop: 12}}>
        <Table
          dataSource={listData}
          pagination={false}
          columns={[
            {
              title: 'Username',
              key: 'username',
              render: (_, record) => (
                <span>{record.username}</span>
              )
            },
            {
              title: 'Action',
              key: 'action',
              render: (_, record, index) => (
                <div className='d-flex align-items-center'>
                  <Button danger 
                    onClick={() => onDeleteRow(index)}
                  >
                    Delete
                  </Button>
                </div>
              )
            }
          ]}
        />
      </div>
      <div className='d-flex justify-content-between'
        style={{marginTop: 12}}
      >
        <div className='d-flex align-items-center'
          style={{ width: 220 }}
        >
          <label>Path:</label>
          <span style={{width: 12}} />
          <Input value={path}
            onChange={e=>setPath(e.target.value)}
          />
        </div>
        <div>
          <Popconfirm onConfirm={handleSubmit}>
            <Button success loading={isUpdating}>
              {mode==='add' && 'Create'}
              {mode==='edit' && 'Update'}
            </Button>
          </Popconfirm>
        </div>
      </div>
    </Modal>
  )
}

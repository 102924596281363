import React from "react";
import styled from "styled-components";
import moment from "moment";
import { Alert } from "components/common";
import { asUsdPrice } from "utils/text";

export default function TotalCard(props) {
  return (
    <Wrapper>
      <Alert
        type="warning"
        message={
          <>
            These projections are an approximate estimation, not a guarantee.
            All estimations are subject to adjustments, fluctuations and may not
            be 100% accurate.
          </>
        }
        showIcon
        style={{ alignItems: "baseline" }}
      />
      <Alert
        type="info"
        message={
          <>
            Total Estimated Monthly Commissions:{" "}
            <strong className="ant-typography ant-typography-success">
              {asUsdPrice(
                props.raAmount * 1 +
                  props.reAmount * 1 +
                  props.cmAmount * 1 +
                  props.biAmount * 1 +
                  props.maAmount * 1 +
                  props.gpAmount * 1
              )}
            </strong>
            &nbsp;&nbsp; Current Month:{" "}
            <strong className="ant-typography ant-typography-danger">
              {moment().clone().format("MMMM")}
            </strong>
            <br />
            Rank Achievement: <strong>{asUsdPrice(props.raAmount)}</strong>
            &nbsp;&nbsp; Monthly Rank Bonus:{" "}
            <strong>{asUsdPrice(props.reAmount)}</strong>&nbsp;&nbsp; Check
            Matching: <strong>{asUsdPrice(props.cmAmount)}</strong>&nbsp;&nbsp;
            Binary Commissions: <strong>{asUsdPrice(props.biAmount)}</strong>
            &nbsp;&nbsp; Customer Mastery:{" "}
            <strong>{asUsdPrice(props.maAmount)}</strong>&nbsp;&nbsp; Global
            Bonus Pool: <strong>{asUsdPrice(props.gpAmount)}</strong>
          </>
        }
        showIcon
        style={{ alignItems: "baseline" }}
      />
      <Alert
        type="info"
        message={
          <>
            Total Estimated Weekly Commissions:{" "}
            <strong className="ant-typography ant-typography-success">
              {asUsdPrice(props.jsAmount * 1 + props.caAmount * 1)}
            </strong>
            &nbsp;&nbsp;
            {props.weekFrom && (
              <>
                Current Week:{" "}
                <strong className="ant-typography ant-typography-danger">
                  {moment(props.weekFrom).format("MMMM Do")} ~{" "}
                  {moment(props.weekTo).format("MMMM Do")}
                </strong>
                <br />
              </>
            )}
            Jump Start Bonus: <strong>{asUsdPrice(props.jsAmount)}</strong>
            &nbsp;&nbsp; Customer Acquisition Bonus:{" "}
            <strong>{asUsdPrice(props.caAmount)}</strong>
          </>
        }
        showIcon
        style={{ alignItems: "baseline" }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Alert, Radio } from "components/common";
import RankCard from "./rank/RankCard";
import PeQvCard from "./peQv/PeQvCard";
import styled from "styled-components";

export default function RankSnapshotPage() {
  const myUser = useSelector((state) => state.auth.user);
  const [viewMode, setViewMode] = useState("current");

  return (
    <Wrapper>
      <Row gutter={[24, 24]}>
        <Col xs={24}>
          <Alert
            style={{ margin: "5px 0px 5px", alignItems: "baseline" }}
            message={
              <div className="rank-alert">
                Your paid rank is{" "}
                <span className={"emphasize"}>{myUser?.pay_rank.name}</span>{" "}
                which will be used to calculate your commissions this month.
                Your current rank for this month is{" "}
                <span className={"emphasize"}>{myUser?.rank.name}</span>. Please
                make sure to place a personal order or generate personal
                customers to keep your account active. Keep up the great work!
              </div>
            }
            type={"info"}
            showIcon
          />
        </Col>
        <Col xs={24}>
          <Row gutter={[24, 24]} className="rank-snapshots">
            <Col xs={24} lg={12} xl={12}>
              <div className="cell-heading">&nbsp;</div>
            </Col>
            <Col xs={24} lg={12} xl={12}>
              <Radio.Group
                buttonStyle="solid"
                value={viewMode}
                onChange={(e) => setViewMode(e.target.value)}
              >
                <Radio.Button value={"current"}>Current Month</Radio.Button>
                <Radio.Button value={"last"}>Last Month</Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <RankCard viewMode={viewMode} />
        </Col>
        <Col xs={24}>
          <PeQvCard viewMode={viewMode} />
        </Col>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-bottom: 80px;
  table {
    @media (max-width: 1200px) {
      width: 1000px;
    }
  }
  .cell-heading {
    font-weight: 700;
    font-size: 18px;
    color: #212b36;
  }
  .emphasize {
    color: #ff5630;
  }
  .rank-snapshots {
    align-items: center;
    .ant-radio-group {
      display: flex;
      justify-content: end;
      width: 100%;
      @media (max-width: 600px) {
        justify-content: start;
        .ant-radio-button-wrapper {
          width: 50%;
          text-align: center;
        }
      }
    }
    .ant-radio-button-wrapper:hover {
      color: #1ea9ea !important;
    }
    .ant-radio-button-wrapper-checked {
      background-color: #1ea9ea !important;
      border-color: #1ea9ea !important;
      :hover {
        color: white !important;
      }
      ::before {
        background-color: #1ea9ea !important;
      }
      :focus,
      :focus-within {
        box-shadow: none !important;
      }
    }
    .ant-radio-button-wrapper {
      padding: 5px 22px 0px 22px;
      height: 40px !important;

      :first-child {
        border-radius: 8px 0px 0px 8px !important;
      }
      :last-child {
        border-radius: 0px 8px 8px 0px !important;
      }
    }
  }
`;

import React, { useState } from "react";
import styled from "styled-components";
import { Alert, Row, Col } from "components/common";
import HltProductList from "./product/HltProductList";
import TgiftProductList from "./product/TgiftProductList";
import HistorySection from "./history/HistorySection";
import BalanceSection from "./sidebar/BalanceSection";
import SendBtnSection from "./sidebar/SendBtnSection";
import SendModal from "./sidebar/SendModal";
import GrantModal from "./sidebar/GrantModal";
import AccessPortal from "./sidebar/AccessPortal";

export default function HltPage() {
  const [shouldLoad, setShouldLoad] = useState(false);
  const [modalMode, setModalMode] = useState(undefined);

  return (
    <Wrapper>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Alert
            type="info"
            showIcon
            style={{ alignItems: "baseline" }}
            message={
              <>
                <p>
                  Prepaid Travel Packages will create a savings balance that you
                  can use personally in your booking engine. Simply click
                  “Access Now” to book trips and use your savings balance.
                </p>
                <p>
                  Your savings balance can be used on hotels, resorts, and
                  condos. All other bookings discounted and you can use those
                  discounts however many times you want without deducting from
                  your savings balance. Travel Cards are for sending out to new
                  prospects and existing users. If you send to a new prospect,
                  they will get an invite to create a free account. Transfer
                  Cards to team will transfer cards to your downline of
                  Affiliates to send out themselves.{" "}
                </p>
                <div>
                  <em>
                    *Before you purchase Travel cards please be advised that
                    Travel Cards are considered gifts and are non-refundable.
                  </em>
                </div>
              </>
            }
          />
        </Col>
        <Col xs={24} xl={16} xxl={18}>
          <Row gutter={[24, 24]} style={{ height: "100%" }}>
            <Col span={24}>
              <HltProductList />
            </Col>
            <Col span={24}>
              <TgiftProductList />
            </Col>
          </Row>
        </Col>
        <Col xs={24} xl={8} xxl={6}>
          <AccessPortal />
          <BalanceSection />
          <SendBtnSection setModalMode={setModalMode} />
        </Col>
        <Col span={24}>
          <HistorySection
            shouldLoad={shouldLoad}
            setShouldLoad={setShouldLoad}
          />
        </Col>
      </Row>

      {modalMode === "grant" && (
        <GrantModal setModalMode={setModalMode} setShouldLoad={setShouldLoad} />
      )}
      {modalMode === "send" && (
        <SendModal setModalMode={setModalMode} setShouldLoad={setShouldLoad} />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div``;

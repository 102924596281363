import React from 'react'
import styled from 'styled-components'
import BadgeCard from './badge/BadgeCard'
import RecogCard from './recog/RecogCard'

export default function RankRecognitionPage() {
  return (
    <Wrapper>
      <BadgeCard />
      <div style={{height: 24}} />
      <RecogCard />
      <div style={{height: 24}} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding-bottom: 60px;
`
import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100%;
  .box-row {
    height: 100%;
  }
  .box-row-1 {
    height: calc(100% - 50px);
  }
  .box-row-2 {
    height: 100%;
  }
  .social-boxes {
    box-shadow: 0px 0px 2px rgb(145 158 171 / 20%),
      0px 12px 24px -4px rgb(145 158 171 / 12%);
    border-radius: 16px;
    height: 100%;
    padding: 24px;
    .store-box {
      height: 100%;
      padding: 16px;
      border: 1px solid rgba(145, 158, 171, 0.24);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .social-para {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: #637381;
      }
      svg {
        width: 48px;
        height: 48px;
      }
    }
    .app-box {
      svg {
        width: auto;
        height: auto;
      }
    }
    .social-boxes-title {
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      color: #212b36;
      margin-bottom: 24px;
    }
  }
`;

import React, { useEffect, useState } from 'react';
import {
  TablePanel, Alert, UserAvatar
} from 'components/common';
import { asNumber, asPrice } from 'utils/text';
import { callGetApiWithAuth } from 'utils/api';

export default function LeaderboardPage() {
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const onGetTableData = ({ data }) => {
    setTableData(data)
    setIsLoading(false)
  }
  const onFailTableData = () => {
    setIsLoading(false)
  }
  const handleSearch = () => {
    setIsLoading(true)
    callGetApiWithAuth(`setc/leaderboard`, 
      onGetTableData, onFailTableData
    )
  }

  useEffect(() => {
    handleSearch()
  }, [])

  return (
    <>
      <Alert
        message={
          'The potential bonus is calculated assuming that 100% of the personally enrolled applicants are eligible for all tax credits and pay the $1,500 filing fee. This number represents Jump Start Bonuses and/or Customer Acquisition Bonuses and does not include any other payouts.'
        }
      />
      <div style={{ height: 20 }} />
      <TablePanel title={'Leaderboard'}
        data={tableData}
        loading={isLoading}
        columns={[
          {
            title: '#',
            key: 'no',
            render: (_, __, ind) => (
              <span>
                {ind+1}
              </span>
            )
          },
          {
            title: 'Name',
            key: 'name',
            render: (_, record) => (
              <UserAvatar
                image={record.user.image}
                title={`${record.user.first_name} ${record.user.last_name}`}
              />
            )
          },
          {
            title: 'Applicants',
            key: 'count',
            render: (_, record) => (
              <span>
                {asNumber(record.cnt)}
              </span>
            )
          },
          {
            title: 'Potential Bonus',
            key: 'potential_bonus',
            render: (_, record) => (
              <span>
                {asPrice(record.cnt*200)}
              </span>
            )
          }
        ]}
      />
    </>
  )
}

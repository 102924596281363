import React, { useState } from "react";
import styled from "styled-components";
import { Drawer } from "components/common";
import TableView from "./table/TableView";
import CommentDrawer from "./drawer/CommentDrawer";

export default function DeclineReportPage() {
  const [selectedUser, setSelectedUser] = useState(undefined);

  return (
    <Wrapper>
      <TableView setSelectedUser={setSelectedUser} />
      {selectedUser && (
        <Drawer
          title={null}
          size={"large"}
          placement="right"
          onClose={() => setSelectedUser(undefined)}
          visible={true}
          className="contant-manager-drawer"
        >
          <CommentDrawer
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            onClose={() => setSelectedUser(undefined)}
          />
        </Drawer>
      )}
    </Wrapper>
  );
}

export const Wrapper = styled.div`
  padding-bottom: 80px;
  .username {
    cursor: pointer;
    color: #1ea9ea;
  }
  .searchField {
    display: flex;
    align-items: center;
    gap: 16px;
    .ant-input {
      height: 40px !important;
    }
    .input-prefix {
      svg {
        width: 15px;
        height: 15px;
        margin-bottom: 5px;
      }
    }
    .ant-form-item-label {
      top: 9px !important;
      left: 44px !important;
    }
    .ant-form-item-label-active {
      transform: translateY(-17px) scale(1) !important;
    }
  }

  @media (max-width: 600px) {
    .toolbar {
      width: 100% !important;
    }
    .searchField {
      flex-direction: column;
      align-items: flex-start;
      width: 100% !important;
      .jjGCjX .input-prefix-container,
      button {
        width: 100% !important;
      }
    }
  }
`;

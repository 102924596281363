import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  Select,
  Button,
  Popconfirm,
  Alert,
  message,
  SuccessNotification,
} from "components/common";
import { varIs, varValue } from "common/var";
import { callGetApiWithAuth, callPutApiWithAuth } from "utils/api";
import { ReactComponent as AddAccountIcon } from "assets/icons/add-account.svg";
import AddModal from "../../add/AddModal";

export default function SettingSection(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const myUser = useSelector((state) => state.auth.user);
  const [payType, setPayType] = useState("");
  const [payTypeOptions, setPayTypeOptions] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [upcomings, setUpcomings] = useState([]);
  const [faileds, setFaileds] = useState([]);
  const [isOpenAddSubscriptionModal, setIsOpenSubscriptionModal] =
    useState(false);

  const onGetUpcomings = (res) => {
    setUpcomings(res.data);
  };

  const onGetFaileds = (res) => {
    setFaileds(res.data);
  };

  const loadUpComingSubscriptions = () => {
    callGetApiWithAuth("my_account/subscription/upcomings", onGetUpcomings);
  };

  const loadFailedSubscriptions = () => {
    callGetApiWithAuth("my_account/subscription/faileds", onGetFaileds);
  };

  const onUpdateDone = (res) => {
    SuccessNotification(res.message);
    setIsUpdating(false);
    dispatch({ type: "auth.RELOAD" });
  };
  const onUpdateFail = () => {
    setIsUpdating(false);
  };
  const handleUpdate = () => {
    // Validation
    // if (!payType) {
    //   message.error('Please select payment option')
    //   return
    // }

    setIsUpdating(true);
    callPutApiWithAuth(
      "my_account/subscription/subscription_setting",
      { pay_type: payType },
      onUpdateDone,
      onUpdateFail
    );
  };

  const handlePay = () => {
    const upcomings0 = upcomings.filter((el) => {
      return varIs("product.isSpecialDc", el.product.is_special_dc, "no");
    });
    const subscriptionIds = upcomings0.map((el) => el.id);

    if (upcomings0.length == 0) {
      message.error("Please pay special subscriptions individually");
      return;
    }

    dispatch({
      type: "checkout.ADD_SUBPAY_ALL_CART",
      payload: {
        orderDetails: upcomings0,
        subscriptionIds,
      },
    });
    history.push("/checkout");
  };

  const handleResume = () => {
    const faileds0 = faileds.filter((el) => {
      return varIs("product.isSpecialDc", el.product.is_special_dc, "no");
    });
    const subscriptionIds = faileds0.map((el) => el.id);

    if (faileds0.length == 0) {
      message.error("Please pay special subscriptions individually");
      return;
    }

    dispatch({
      type: "checkout.ADD_RESUME_ALL_CART",
      payload: {
        orderDetails: faileds0,
        subscriptionIds,
      },
    });
    history.push("/checkout");
  };

  const handleOpenAddSubscription = () => {
    setIsOpenSubscriptionModal(true);
  };

  const handleCloseAddSubscriptionModal = () => {
    setIsOpenSubscriptionModal(false);
  };

  const handleSuccessCreate = () => {
    setIsOpenSubscriptionModal(false);
    loadUpComingSubscriptions();
    loadFailedSubscriptions();
    props.setShouldLoad(true);
  };

  useEffect(() => {
    if (myUser.subscription_setting) {
      setPayType(myUser.subscription_setting.pay_type);
    }
    const payTypeOptions0 = [
      {
        label: "Credit Card",
        value: varValue("userSubscriptionSetting.payType", "cc"),
      },
    ];
    if (myUser.country === "US") {
      payTypeOptions0.push({
        label: "ACH",
        value: varValue("userSubscriptionSetting.payType", "bank"),
      });
    }
    if (varIs("user.type", myUser.type, "affiliate")) {
      payTypeOptions0.push({
        label: "Credit Wallet + Credit Card",
        value: varValue("userSubscriptionSetting.payType", "cc+wallet"),
      });
    }
    payTypeOptions0.push({
      label: "Gift Wallet + Credit Card",
      value: varValue("userSubscriptionSetting.payType", "cc+gift_wallet"),
    });
    payTypeOptions0.push({
      value: "",
      label: "Manual Payment",
    });

    setPayTypeOptions(payTypeOptions0);
  }, [myUser]);

  useEffect(() => {
    loadUpComingSubscriptions();
    loadFailedSubscriptions();
  }, []);

  return (
    <Wrapper>
      <div className="alert-1">
        {varIs("userSubscriptionSetting.payType", payType, "cc") && (
          <div className="alert-section">
            <Alert
              type={"warning"}
              showIcon
              style={{ alignItems: "baseline", marginTop: 12 }}
              message={
                "When using this option, your order will be paid by your credit card on file."
              }
            />
          </div>
        )}
        {varIs("userSubscriptionSetting.payType", payType, "bank") && (
          <div className="alert-section">
            <Alert
              type={"info"}
              showIcon
              style={{ alignItems: "baseline", marginTop: 12 }}
              message={
                "When using this option, your order will be paid by your bank account on file. ACH payments take 5-7 business days to clear. "
              }
            />
          </div>
        )}
        {varIs("userSubscriptionSetting.payType", payType, "cc+wallet") && (
          <div className="alert-section">
            <Alert
              type={"info"}
              showIcon
              style={{ alignItems: "baseline", marginTop: 12 }}
              message={
                "When using this option, we will apply your credit wallet balance first and the remaining amount will be paid by your credit card on file."
              }
            />
          </div>
        )}
        {varIs(
          "userSubscriptionSetting.payType",
          payType,
          "cc+gift_wallet"
        ) && (
          <div className="alert-section">
            <Alert
              type={"info"}
              showIcon
              style={{ alignItems: "baseline", marginTop: 12 }}
              message={
                "When using this option, we will apply gift wallet balance first and the remaining amount will be paid by your credit card on file."
              }
            />
          </div>
        )}
        {payType === "" && (
          <div className="alert-section">
            <Alert
              type={"warning"}
              showIcon
              style={{ alignItems: "baseline", marginTop: 12 }}
              message={
                "Subscriptions will not be paid automatically with this option. Our system will notify you to pay for your subscription 7 days before the next billing date."
              }
            />
          </div>
        )}
      </div>
      <div className="select-root">
        <div className="select-root-box">
          <div className="payment-select-label">Preferred Payment Method</div>
          <div className="select-box">
            <Select
              value={payType}
              options={payTypeOptions}
              onChange={setPayType}
            />
          </div>
          <div className="actions">
            <Button
              loading={isUpdating}
              onClick={handleUpdate}
              size={"medium"}
              className="save-button"
            >
              Save
            </Button>
            {upcomings.length > 0 && (
              <>
                <span style={{ width: 8 }} />
                <Popconfirm onConfirm={handlePay}>
                  <Button className="pay-button" size={"medium"}>
                    Pay All
                  </Button>
                </Popconfirm>
              </>
            )}
            {faileds.length > 0 && (
              <>
                <span style={{ width: 8 }} />
                <Popconfirm onConfirm={handleResume}>
                  <Button className="resume-button" size={"medium"}>
                    Resume All
                  </Button>
                </Popconfirm>
              </>
            )}
            {faileds.length == 0 &&
              (myUser && !varIs("user.status", myUser.status, "hold_temp") ? (
                <>
                  <span style={{ width: 8 }} />
                  <Button
                    onClick={handleOpenAddSubscription}
                    className="add-subscription-button show-btn-top"
                    icon={<AddAccountIcon />}
                    size={"medium"}
                  >
                    Add Subscription
                  </Button>
                </>
              ) : (
                ""
              ))}
          </div>
        </div>
        {myUser && !varIs("user.status", myUser.status, "hold_temp") ? (
          <Button
            onClick={handleOpenAddSubscription}
            className={
              faileds.length == 0
                ? "add-subscription-button show-btn-bottom"
                : "add-subscription-button"
            }
            icon={<AddAccountIcon />}
            size={"medium"}
          >
            Add Subscription
          </Button>
        ) : (
          ""
        )}
      </div>
      {isOpenAddSubscriptionModal && (
        <AddModal
          handleSuccessCreate={handleSuccessCreate}
          handleCloseAddSubscriptionModal={handleCloseAddSubscriptionModal}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .alert-1 {
  }
  .select-box {
    .ant-select-selector {
      height: 40px !important;
      padding-top: 4px !important;
    }
  }
  .select-root {
    margin-top: 24px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .payment-select-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #637381;
    margin-right: 8px;
  }
  .ant-select {
    width: 260px;
  }
  .actions {
    display: flex;
    margin-left: 8px;
  }
  .save-button {
    border: 1px solid rgba(145, 158, 171, 0.24) !important;
    border-radius: 8px !important;
    padding: 8px 16px 8px !important;
    background: white !important;
    color: #212b36 !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 24px !important;
    height: 42px;
  }
  .pay-button {
    border: none;
    border-radius: 8px !important;
    padding: 8px 16px 8px !important;
    background: #36b37e !important;
    height: 40px;
    color: white !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 24px !important;
  }
  .resume-button {
    border: none;
    border-radius: 8px !important;
    padding: 8px 16px 8px !important;
    background: #ff5630 !important;
    color: white !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 24px !important;
  }
  .add-subscription-button {
    border: none;
    border-radius: 8px !important;
    padding: 8px 16px 8px !important;
    background: #1ea9ea !important;
    color: white !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 24px !important;
    svg {
      margin-right: 14px;
    }
  }
  .select-root-box {
    display: flex;
    align-items: center;
  }
  .show-btn-bottom {
    display: block;
  }
  .show-btn-top {
    display: none;
  }
  @media (max-width: 1300px) {
    .select-root {
      align-items: flex-end;
      position: relative;
      .select-root-box {
        flex-direction: column;
        align-items: start;
        width: 100%;
      }
      .payment-select-label {
        width: 100%;
        margin-bottom: 5px;
      }
      .select-box {
        width: 100%;
        .ant-select {
          width: 100%;
          margin-bottom: 16px;
        }
      }
      .actions {
        margin: 0px;
      }
      .add-subscription-button {
        position: absolute;
        right: 0px;
      }
    }
  }

  @media (max-width: 600px) {
    .select-root {
      align-items: flex-end;
      position: relative;
      justify-content: end;
      .actions {
        width: 100%;
        .save-button {
          width: 19%;
        }
        .pay-button {
          width: 25%;
        }
        .resume-button {
          width: 52%;
        }
      }
      .add-subscription-button {
        position: static;
        right: 0px;
        width: 100%;
        margin-top: 16px;
      }
    }
    .show-btn-bottom {
      display: none !important;
    }
    .show-btn-top {
      display: block !important;
      margin: 0px !important;
      width: 52% !important;
    }
  }
  .ant-select-selection-item {
    color: black;
  }
`;

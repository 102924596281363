import React, { useState } from "react";
import { useSelector } from "react-redux";
import { message, NoData, Alert } from "components/common";
import { ReactComponent as AddIcon } from "assets/icons/add.svg";
import { Wrapper } from "./ShippingAddressSubPage.styled";
import AddModal from "./add/AddModal";
import EditModal from "./edit/EditModal";
import TableView from "./table/TableView";

export default function ShippingAddressSubPage() {
  const myUser = useSelector((state) => state.auth.user);
  const [modalMode, setModalMode] = useState(undefined);
  const [selected, setSelected] = useState(undefined);
  const [shouldLoad, setShouldLoad] = useState(false);

  const openAdd = () => {
    if (myUser.shipping_details.length >= 2) {
      message.error("You cant add shipping address more than 2");
      return;
    }
    setModalMode("add");
  };

  return (
    <Wrapper>
      <div className="alert-container-section" style={{ marginBottom: 24 }}>
        <div className="alert-section">
          <Alert
            type={"info"}
            showIcon
            style={{ alignItems: "baseline", marginBottom: 12 }}
            message={
              "Your primary shipping address is used for your subscriptions and one-time purchases. If you have active subscriptions, you cannot delete your primary shipping address or change the primary shipping country. You may change your secondary shipping address at any time."
            }
          />
        </div>
      </div>
      <div className="label-header-container">
        <div className="title">
          Shipping Addresses ({myUser.shipping_details.length})
        </div>
        <div className="add-container">
          <button className="icon-span" onClick={openAdd}>
            <AddIcon />
            New Address
          </button>
        </div>
      </div>
      {myUser.shipping_details.length > 0 ? (
        <div className="contact-table">
          <TableView
            shouldLoad={shouldLoad}
            setShouldLoad={setShouldLoad}
            setSelected={setSelected}
            setModalMode={setModalMode}
          />
        </div>
      ) : (
        <div className="checking-container">
          <NoData
            title="You do not have a shipping address."
            className="my-account-checking-no-data"
          />
        </div>
      )}
      {modalMode === "add" && <AddModal setModalMode={setModalMode} />}
      {modalMode === "edit" && selected && (
        <EditModal setModalMode={setModalMode} data={selected} />
      )}
    </Wrapper>
  );
}

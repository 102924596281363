import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import {
  Row, Col,
  List, Card,
  Spin, Tag,
  Button, Popconfirm,
  SuccessNotification,
} from "components/common";
import { asDate, asPrice } from "utils/text";
import { callGetApiWithAuth, callDeleteApiWithAuth } from "utils/api";
import { varIs, varLabel } from "common/var";
import EditModal from "../edit/EditModal";
import { Wrapper } from "./PendingSection.styled";

export default function PendingSection(props) {
  const [listData, setListData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [akadtUpProduct, setAkadtUpProduct] = useState(undefined);
  const [akadtAbleProducts, setAkadtAbleProducts] = useState([]);

  const onGetListData = (res) => {
    setIsLoading(false);
    setListData(res.data);
    props.setShouldLoad(false);
  };
  const onFailListData = () => {
    setIsLoading(false);
    props.setShouldLoad(false);
  };
  const loadListData = () => {
    setIsLoading(true);
    callGetApiWithAuth(
      `my_account/subscription/pendings`,
      onGetListData,
      onFailListData
    );
  };

  useEffect(() => {
    if (props.shouldLoad) {
      loadListData();
      callGetApiWithAuth(
        `my_account/subscription/check_akadt_upgrade`,
        ({ data }) => {
          setAkadtAbleProducts(data.able_products);
          setAkadtUpProduct(data.up_product);
        },
      )
    }
  }, [props.shouldLoad]);

  return (
    <Wrapper>
      {isLoading ? (
        <div className="loadingRoot">
          <Spin spinning={true} className={`spinner`} />
        </div>
      ) : (
        <div>
          <div className="payment-header">
            <span>Upcoming Payments</span>
          </div>
          {listData.map((item) => (
            <div key={item.next_date} className="pend-subs" style={{marginBottom: 24}}>
              <div className="payment-header">
                <span>{asDate(item.next_date)}</span>
              </div>
              <List
                className="subsc-list"
                grid={{ gutter: 24, xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 4 }}
                dataSource={item.subscriptions}
                renderItem={(item2) => (
                  <List.Item key={item2.id}>
                    <SubscriptionCard
                      data={item2}
                      isUpcoming={item.is_upcoming}
                      loadListData={loadListData}
                      setShouldLoad={props.setShouldLoad}
                      akadtAbleProducts={akadtAbleProducts}
                      akadtUpProduct={akadtUpProduct}
                    />
                  </List.Item>
                )}
              />
            </div>
          ))}
        </div>
      )}
    </Wrapper>
  );
}

const SubscriptionCard = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isEdition, setIsEdition] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const [isAkadtAble, setIsAkadtAble] = useState(false);

  const onCancelDone = (res) => {
    setIsCancelling(false);
    SuccessNotification(res.message);
    props.setShouldLoad(true);
  };
  const onCancelFail = () => {
    setIsCancelling(false);
  };
  const handleCancel = () => {
    setIsCancelling(true);
    callDeleteApiWithAuth(
      `my_account/subscription/${props.data.id}/cancel`,
      onCancelDone,
      onCancelFail
    );
  };

  const handlePay = () => {
    dispatch({
      type: "checkout.ADD_SUBPAY_CART",
      payload: {
        orderDetail: props.data,
        subscriptionIds: [props.data.id],
      },
    });
    history.push("/checkout");
  };

  const handleUpAkadt = () => {
    dispatch({
      type: 'checkout.SET_ORDER_DETAILS',
      payload: [
        {
          product: props.akadtUpProduct,
          quantity: 1,
          pay_cycle: '',
          contained_products: [],
        }
      ]
    })
    history.push("/checkout");
  }

  useEffect(() => {
    if (props.data && props.akadtUpProduct) {
      let isSku = false;
      props.akadtAbleProducts.forEach(product => {
        if (product.sku === props.data.product.sku) {
          isSku = true;
        }
      });
      if (props.akadtUpProduct && isSku) {
        setIsAkadtAble(true)
      } else {
        setIsAkadtAble(false)
      }
    }
  }, [props.data, props.akadtAbleProducts, props.akadtUpProduct])

  return (
    <div className="past-card">
      <div>
        <div className="pro-c">
          <div className="pro-c-1">
            <div className="payment-product-image">
              <img src={props.data.product.image_path} />
            </div>
          </div>
          <div className="pro-c-2">
            <div className="price-container">
              <div className="payment-title">{props.data.product.title}</div>
              <div className="payment-tag">
                {varIs("subscription.status", props.data.status, "active") ? (
                  <Tag color="#87d068" className="active">
                    Active
                  </Tag>
                ) : (
                  <Tag color="red" className="failed">
                    Failed
                  </Tag>
                )}
              </div>
            </div>
          </div>
        </div>
        <Row gutter={[10, 10]}>
          <Col span={8}>
            <div className="bv-container">
              <div className="bv-title">Vol. (BV)</div>
              <div className="bv-value">
                {props.data.product.recurring_pv * props.data.quantity}
              </div>
            </div>
          </Col>
          <Col span={8}>
            <div className="bv-container">
              <div className="bv-title">Quantity</div>
              <div className="bv-value">{props.data.quantity}</div>
            </div>
          </Col>
          <Col span={8}>
            <div className="bv-container">
              <div className="bv-title">Price</div>
              <div className="bv-value">
                {asPrice(props.data.product.recurring_price)}
              </div>
            </div>
          </Col>

          <Col span={8}>
            <div className="bv-container">
              <div className="bv-title">Next Bill Date:</div>
              <div className="bv-value-1">
                {moment(props.data.next_date).format("MMM D, YYYY")}
              </div>
            </div>
          </Col>
          <Col span={8}>
            <div className="bv-container">
              <div className="bv-title">Frequency:</div>
              <div className="bv-value-1">
                {varLabel("subscription.payCycle", props.data.pay_cycle)}
              </div>
            </div>
          </Col>
          <Col span={8}>
            <div className="bv-container">
              <div className="bv-title">Date Created</div>
              <div className="bv-value-2">
                {moment(props.data.created_at).format("MMM D, YYYY")}
              </div>
            </div>
          </Col>
        </Row>
        {props.data.contained_products.length > 0 ? (
          <div className="billing-container">
            Details:
            <span>
              {props.data.contained_products.map((el, index) => (
                <span>
                  {el.product.title}
                  {` x `}
                  {el.quantity}
                  {index < props.data.contained_products.length - 1 ? ", " : ""}
                </span>
              ))}
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="action-buttons-container">
        {props.isUpcoming &&
        varIs("subscription.isWaiting", props.data.is_waiting, "no") ? (
          <Popconfirm onConfirm={handlePay}>
            <Button className="pay-button" bgColor="#36b37e" size="small">
              Pay
            </Button>
          </Popconfirm>
        ) : (
          <Button
            className="edit"
            size="small"
            onClick={() => setIsEdition(true)}
          >
            Edit
          </Button>
        )}
        <Popconfirm onConfirm={handleCancel}>
          <Button
            className="cancel"
            size="small"
            bgColor="white"
            border
            color="black"
            loading={isCancelling}
          >
            Cancel
          </Button>
        </Popconfirm>
      </div>
      {isAkadtAble && 
      <>
        <div className="action-buttons-container">
          <Popconfirm onConfirm={handleUpAkadt}>
            <Button className="pay-button" 
              bgColor="#36b37e" size="small"
            >
              Upgrade to Akashx + DreamTrips
            </Button>
          </Popconfirm>
        </div>
      </>}
      {isEdition && (
        <EditModal
          data={props.data}
          setIsEdition={setIsEdition}
          setShouldLoad={props.setShouldLoad}
        />
      )}
    </div>
  );
};

import React from "react";
import { useEffect } from "react";

//Zoho Sales Iq Script:
const useScript = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.setAttribute("type", "text/javascript");

    let code = `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "siq2d148cc50334e9e0c7fa43d7433f7d250723fb855ce76997ea4c7034291b377c", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zohopublic.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);`;

    script.appendChild(document.createTextNode(code));
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  });
};

export default function SalesIQ() {
  return <React.Fragment>{useScript()}</React.Fragment>;
}

import styled from "styled-components";

export const Wrapper = styled.div`
  padding-bottom: 80px;
  .main-container {
    margin: -24px -40px 24px;
    background: rgba(145, 158, 171, 0.08);
    padding: 24px 40px 0px;
    @media (max-width: 900px) {
      margin: -24px -20px 24px;
      padding: 24px 20px 0px;
    }
    .subscriptions-tabs {
      .ant-tabs-nav {
        margin-bottom: 0px;
        margin-top: 0px;
      }
      .ant-tabs-tab-active {
        padding: 12px;
        background: white;
        border-radius: 8px 8px 0px 0px;
      }
      .active-tab {
        font-weight: 600 !important;
        font-size: 15px !important;
        line-height: 24px !important;
        color: #1ea9ea !important;
      }
      .inactive-tab {
        font-weight: 600 !important;
        font-size: 15px !important;
        line-height: 24px !important;
        color: #637381 !important;
      }
      .ant-tabs-ink-bar {
        background: transparent !important;
      }
    }
  }
  .training-conta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    .training-title {
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      color: #212b36;
    }
    .header-search {
      display: flex;
      align-items: center;
      .ant-select {
        .ant-select-selector {
          height: 40px !important;
          width: 282px;
          .ant-select-selection-item {
            position: relative;
            top: -2px;
          }
        }
      }
    }
    .header-search > div {
      margin-left: 16px;
    }
  }

  .watchList_topCard {
    .topCard-details {
      justify-content: center;
      .topCard-content {
        margin: 0px;
      }
    }
  }

  .watchList {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 24px 0px;
    .watchList-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .watchList-title {
      font-weight: 700;
      font-size: 18px;
      color: #212b36;
      padding-bottom: 12px;
    }
  }

  @media (max-width: 750px) {
    .training-conta {
      flex-direction: column !important;
      .training-title {
        width: 100% !important;
      }
      .header-search {
        width: 100% !important;
        flex-direction: column !important;
        .ant-select {
          .ant-select-selector {
            height: 40px !important;
            width: 282px;
            .ant-select-selection-item {
              position: relative;
              top: -2px;
            }
          }
        }
      }
      .header-search > div {
        width: 100% !important;
        margin-left: 0px;
        margin-top: 24px;
      }
    }
  }

  @media (max-width: 1200px) {
    .topCard-details {
        padding: 20px 0px
      }
  }
`;

import React from "react";
import styled from "styled-components";

function CustomerVolumeCard({ icon, title, value }) {
  return (
    <Wrapper>
      <div className="volumeRoot">
        <div className="icon">{icon}</div>
        <div className="data">
          <div className="value">{value}</div>
          <div className="title">{title}</div>
        </div>
      </div>
    </Wrapper>
  );
}

export default CustomerVolumeCard;

const Wrapper = styled.div`
  .volumeRoot {
    padding: 20px 10px 20px 20px;
    display: flex;
    align-items: center;
    gap: 20px;
    border: 1px solid rgba(145, 158, 171, 0.24);
    border-radius: 8px;
    .data {
      .value {
        font-weight: 700;
        font-size: 18px;
        color: #212b36;
      }
      .title {
        color: #637381;
      }
    }
  }
`;

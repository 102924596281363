import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { TablePanel, StartEndDatePicker, Button } from "components/common";
import { varIs, varIsIn, varKey, varLabel } from "common/var";
import { callGetApiWithAuth } from "utils/api";
import { asCurrencyPrice, asDate } from "utils/text";
import DetailModal from "./DetailModal";
import { ReactComponent as RecieptIcon } from "assets/icons/new-rec.svg";

export default function TableView() {
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationParam, setPaginationParam] = useState({
    currentPage: 1,
    perPage: 15,
    total: 0,
  });
  const [searchParam, setSearchParam] = useState({
    startDate: "",
    endDate: "",
    date_range: "",
  });
  const [selectedOrder, setSelectedOrder] = useState(undefined);

  const onGetTableData = (res) => {
    setTableData(res.data.data);
    setPaginationParam({
      ...paginationParam,
      currentPage: res.data.current_page,
      total: res.data.total,
    });
    setIsLoading(false);
  };
  const onFailTableData = () => {
    setIsLoading(false);
  };
  const loadTableData = (paginationParam0, searchParam0) => {
    setIsLoading(true);
    const params = {
      ...searchParam0,
      page: paginationParam0.currentPage,
      per_page: paginationParam0.perPage,
    };
    const q = Object.keys(params)
      .map((k) => k + "=" + params[k])
      .join("&");
    callGetApiWithAuth(
      `my_account/order_history/orders?${q}`,
      onGetTableData,
      onFailTableData
    );
  };

  const onPageChange = (page) => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: page,
    };
    loadTableData(paginationParam0, searchParam);
  };

  const handleSearch = () => {
    loadTableData(paginationParam, searchParam);
  };

  const onDateRange = (v) => {
    if (v) {
      setSearchParam({
        ...searchParam,
        startDate: v[0],
        endDate: v[1],
        date_range: `${moment(v[0]).format("YYYY-MM-DD")}|${moment(v[1]).format(
          "YYYY-MM-DD"
        )}`,
      });
    } else {
      setSearchParam({
        ...searchParam,
        startDate: "",
        endDate: "",
        date_range: "",
      });
    }
  };

  useEffect(() => {
    loadTableData(paginationParam, searchParam);
  }, []);

  return (
    <>
      <TablePanel
        toolbar={
          <div className="d-flex align-items-center">
            <StartEndDatePicker
              startDate={searchParam.startDate}
              endDate={searchParam.endDate}
              onChange={onDateRange}
            />
            <span style={{ width: 12 }} />
            <Button onClick={handleSearch}>Search</Button>
          </div>
        }
        data={tableData}
        loading={isLoading}
        paginationParam={paginationParam}
        onPageChange={onPageChange}
        columns={[
          {
            title: "Order Number",
            dataIndex: "order_number",
            render: (text, record) => (
              <div className="d-flex align-items-center">
                <a
                  href="javascript:void(0)"
                  style={{ textDecoration: "underline", color: "#212B36" }}
                  onClick={() => setSelectedOrder(record)}
                >
                  {text}
                </a>
                {varIsIn("order.orderFrom", record.order_from, [
                  "reship",
                  "custom_ship",
                ]) && <OrderFromBadge data={record} />}
                {varIs("order.status", record.status, "pending") && (
                  <div className="order-pending-badge">Pending</div>
                )}
              </div>
            ),
          },
          {
            title: "Date",
            key: "paid_at",
            render: (_, record) => (
              <span>{record.paid_at ? asDate(record.paid_at) : "-"}</span>
            ),
          },
          {
            title: "Total Amount",
            key: "local_order_total_amount",
            render: (_, record) => (
              <span>
                {varIs("userBillingDetail.payType", record.pay_type, "coin")
                  ? `${record.merchant_amount || ""} ${
                      record.coin_type ? record.coin_type.toUpperCase() : ""
                    }`
                  : asCurrencyPrice(
                      record.local_order_total_amount,
                      record.currency_code
                    )}
              </span>
            ),
          },
          {
            title: "Tracking ID",
            dataIndex: "tracking_number",
            key: "tracking_number",
            render: (text, record) =>
              text ? (
                <a
                  style={{ textDecoration: "underline" }}
                  href={record.tracking_url ? record.tracking_url : "#"}
                  target="_blank"
                >
                  {text}
                </a>
              ) : (
                <span>{"-"}</span>
              ),
          },
          {
            title: "Receipt",
            dataIndex: "id",
            render: (_, record) => (
              <Link
                to={`/my_account/order_receipt/${record.id}`}
                target="_blank"
              >
                <span className="receipt-box">
                  <RecieptIcon />
                </span>
              </Link>
            ),
          },
        ]}
      />
      {selectedOrder && (
        <DetailModal
          selectedOrder={selectedOrder}
          setSelectedOrder={setSelectedOrder}
        />
      )}
    </>
  );
}

const OrderFromBadge = (props) => {
  return (
    <div
      className={`order-from-badge ${varKey(
        "order.orderFrom",
        props.data.order_from
      )}`}
    >
      {varLabel("order.orderFrom", props.data.order_from)}
    </div>
  );
};

import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Card, Button, OutlineBtn, Dropzone,
  message, Popconfirm, Popover, SuccessNotification,
} from 'components/common'
import styled from 'styled-components'
import { callGetApiWithAuth, callPostApiWithAuth } from 'utils/api'
import { UploadOutlined, CheckOutlined } from "@ant-design/icons";
import FraudIcon from 'assets/images/fraud_icon.png'

export default function VerificationModal() {
  const dispatch = useDispatch()
  const [ccSuspectList, setCcSuspectList] = useState([])
  const [isUpdating, setIsUpdating] = useState(false)
  const [isAlready, setIsAlready] = useState(false)
  const [idData, setIdData] = useState({
    preview: null,  image: null
  })

  const onCheckDone = (res) => {
    if (res.data.is_hold) {
      if (res.data.suspect_list.length>0) {
        setCcSuspectList(res.data.suspect_list.map(el => ({
          ...el,
          front_preview: null, 
          front_image: null,
          back_preview: null,
          back_image: null
        })))
      } else {
        setCcSuspectList([{
          last_cc_4: '',
          front_preview: null, 
          front_image: null,
          back_preview: null,
          back_image: null
        }])
      }
      setIsAlready(res.data.is_already)
    }
  }
  const onCheckFail = () => { 
  }

  const onIdFile = (files) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);
    fileReader.onload = () => {
      setIdData({
        ...idData,
        preview: fileReader.result,
        image: files[0]
      })
    };
  }

  const onCcFrontFile = (ind, files) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);
    fileReader.onload = () => {
      let ccSuspectList0 = [...ccSuspectList]
      ccSuspectList0[ind]['front_preview'] = fileReader.result
      ccSuspectList0[ind]['front_image'] = files[0]
      setCcSuspectList(ccSuspectList0)
    };
  }

  const onCcBackFile = (ind, files) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);
    fileReader.onload = () => {
      let ccSuspectList0 = [...ccSuspectList]
      ccSuspectList0[ind]['back_preview'] = fileReader.result
      ccSuspectList0[ind]['back_image'] = files[0]
      setCcSuspectList(ccSuspectList0)
    };
  }

  const onUpdateDone = (res) => {
    SuccessNotification(res.message)
    setIsUpdating(false)
    setIsAlready(true)
  }
  const onUpdateFail = () => {
    setIsUpdating(false)
  }
  const handleUpload = () => {
    // Validation
    if (!idData.image) {
      message.error('Please upload ID image')
      return
    }
    let isCcEmpty = false
    ccSuspectList.forEach(el => {
      if (!el.front_image || !el.back_image) {
        isCcEmpty = true
      }
    })
    if (isCcEmpty) {
      message.error('Please upload all CC images')
      return
    }

    setIsUpdating(true)
    const body = new FormData()
    body.append('id_image', idData.image)
    ccSuspectList.forEach((el, ind) => {
      body.append(`cc_images[${2*ind}]`, el.front_image)
      body.append(`cc_images[${2*ind+1}]`, el.back_image)
    })
    callPostApiWithAuth('verification/upload_cc', body, 
      onUpdateDone, onUpdateFail
    )
  }

  useEffect(() => {
    callGetApiWithAuth('verification/check_hold', 
      onCheckDone, onCheckFail
    )
  }, [])

  return (
    <Wrapper>
      <Card>      
        <div className="fraud-result-icon">
          <img src={FraudIcon} />
        </div>
        <div className="fraud-result-message">
          <h5>There is a problem with your account.</h5>
          <p><strong>Note: </strong> Our Anti-Fraud system is to ensure that you are not a victim of credit card fraud, and its an extra layer of security to protect the company. It is completely safe to send in your Photo ID &amp; Credit Card to have your account activated.</p>
        </div>
        <div className="fraud-verify-section">
          <div className="fraud-verify-upload">
            <h5>Photo ID</h5>
            <label>Permitted</label>
            <ul>
              <li><CheckOutlined className="fraud-verify-permitted-icon" />Driver's License</li>
              <li><CheckOutlined className="fraud-verify-permitted-icon" />Passport</li>
              <li><CheckOutlined className="fraud-verify-permitted-icon" />Must Match Name On Account</li>
            </ul>
          </div>
          <div className="fraud-verify-upload">
            <h5>Picture of Credit Card</h5>
            <label>Permitted</label>
            <ul>
              <li><CheckOutlined className="fraud-verify-permitted-icon" />Front of Credit Card</li>
              <li><CheckOutlined className="fraud-verify-permitted-icon" />Back of Credit Card</li>
            </ul>
          </div>
        </div>
        <div className='upload-section'>
          <div className='upload-row'>
            <div className='upload-label'>
              <CheckOutlined className="fraud-verify-permitted-icon" />
              &nbsp;
              Photo ID
            </div>
            <div className="upload-btn-wrapper">
              <div className="fraud-verify-upload-btn">
                <Dropzone
                  onDrop={(files) => onIdFile(files)}
                  accept='image/*'
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <OutlineBtn icon={<UploadOutlined />}>Upload ID Image</OutlineBtn>
                    </div>
                  )}
                </Dropzone>
              </div>
              <span style={{width: 8}} />
              {idData.preview?
              <div>
                <Popover 
                  title={'ID Card'}
                  content={
                    <>
                    <img className={'ccImage'}
                      src={idData.preview}
                      style={{
                        height: 210
                      }}
                    />
                    </>
                  }
                >
                  <img className={'ccImage'}
                    src={idData.preview}
                    style={{
                      height: 42,
                    }}
                  />
                </Popover>
              </div>
              : ''}
            </div>
          </div>
          {ccSuspectList.map((el, ind) => (
            <div className='upload-row' key={ind}>
              <div className='upload-label'>
                <CheckOutlined className="fraud-verify-permitted-icon" />
                &nbsp;
                Credit Card&nbsp;
                {el.last_cc_4?`(****${el.last_cc_4})`:''}
              </div>
              <div className="upload-btn-wrapper">
                <div className="fraud-verify-upload-btn">
                  <Dropzone
                    onDrop={(files) => onCcFrontFile(ind, files)}
                    accept='image/*'
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <OutlineBtn icon={<UploadOutlined />}>Upload Front Image</OutlineBtn>
                      </div>
                    )}
                  </Dropzone>
                </div>
                <span style={{width: 8}} />
                {el.front_preview?
                <div>
                  <Popover 
                    title={'Credit Card(Front)'}
                    content={
                      <>
                      <img className={'ccImage'}
                        src={el.front_preview}
                        style={{
                          height: 210
                        }}
                      />
                      </>
                    }
                  >
                    <img className={'ccImage'}
                      src={el.front_preview}
                      style={{
                        height: 42,
                      }}
                    />
                  </Popover>
                </div>
                : ''}
              </div>
              <span style={{width: 8}} />
              <div className="upload-btn-wrapper">
                <div className="fraud-verify-upload-btn">
                  <Dropzone
                    onDrop={(files) => onCcBackFile(ind, files)}
                    accept='image/*'
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <OutlineBtn icon={<UploadOutlined />}>Upload Back Image</OutlineBtn>
                      </div>
                    )}
                  </Dropzone>
                </div>
                <span style={{width: 8}} />
                {el.back_preview?
                <div>
                  <Popover 
                    title={'Credit Card(Back)'}
                    content={
                      <>
                      <img className={'ccImage'}
                        src={el.back_preview}
                        style={{
                          height: 210
                        }}
                      />
                      </>
                    }
                  >
                    <img className={'ccImage'}
                      src={el.back_preview}
                      style={{
                        height: 42,
                      }}
                    />
                  </Popover>
                </div>
                : ''}
              </div>
            </div>
          ))}
        </div>
        <div className="fraud-verify-submit">
          <Popconfirm
            onConfirm={handleUpload}
            disabled={isUpdating}
            okText="Yes" cancelText="No"
            title="Are you sure?"
          >
            <Button
              type="primary"
              loading={isUpdating}
              disabled={isUpdating}
            >
              Submit
            </Button>
          </Popconfirm>
        </div>
      </Card>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding-bottom: 40px;
  .fraud-result-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .fraud-result-icon img {
    width: 70px;
  }
  .fraud-result-message {
    margin-top: 20px;
  }
  .fraud-result-message h5 {
    text-align: center;
    font-weight: 600;
    color: #555;
  }
  .fraud-result-message p {
    text-align: center;
    max-width: 600px;
    margin: 20px auto 0;
    font-size: 16px;
  }
  .fraud-verify-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 50px;
  }
  .upload-section {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .upload-row {
    width: 100%;
    max-width: 800px;
    display: flex;
    align-items: center;
    @media(max-width: 768px) {
      display: block;
    } 
  }
  .upload-label {
    width: 180px;
    display: flex;
    align-items: center;
  }
  .fraud-verify-upload {
    width: 50%;
    max-width: 400px;
    padding: 30px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin: 15px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
  }
  @media(max-width: 768px) {
    .fraud-verify-upload {
        width: 100%;
    }
  }
  .fraud-verify-upload h5 {
    font-weight: 600;
    color: #555;
  }
  .fraud-verify-permitted-icon {
    color: #52c41a;
  }
  .fraud-verify-upload label {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    width: 250px;
    margin-top: 30px;
  }
  .fraud-verify-upload ul {
    font-size: 16px;
    text-align: left;
    width: 250px;
    min-height: 120px;
    margin-top: 20px;
    padding-left: 0;
  }
  .fraud-verify-upload ul li {
    display: flex;
    align-items: flex-start;
    margin-bottom: 5px;
  }
  .fraud-verify-upload ul li .fraud-verify-permitted-icon {
    margin-right: 10px;
    margin-top: 5px;
  }
  .fraud-verify-upload-btn {
    margin: 8px 0;
  }
  .upload-btn-wrapper {
    display: flex;
    align-items: center;
  }
  .fraud-verify-upload .fraud-verify-image {
    width: 250px;
    border: 1px solid #d9d9d9;
    padding: 5px;
    margin-bottom: 20px;
  }
  .fraud-verify-submit {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }
  .fraud-verify-submit button {
    height: 37px;
    font-size: 16px;
    padding: 0 30px;
  }
`

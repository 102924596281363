import React from 'react'
import { CommonWrapper } from './templates/CommonTemplate.styled'
import Flyer1KTemplate from './templates/Flyer1KTemplate'
import Flyer5KTemplate from './templates/Flyer5KTemplate'
import Flyer10KTemplate from './templates/Flyer10KTemplate'
import Flyer25KTemplate from './templates/Flyer25KTemplate'
import Flyer50KTemplate from './templates/Flyer50KTemplate'
import Flyer100KTemplate from './templates/Flyer100KTemplate'
import Flyer250KTemplate from './templates/Flyer250KTemplate'
import Flyer500KTemplate from './templates/Flyer500KTemplate'
import FlyerSuperTemplate from './templates/FlyerSuperTemplate'
import FlyerMasterTemplate from './templates/FlyerMasterTemplate'

export default function Preview(props) {

  return (
    <CommonWrapper id='recogPreview'
      style={{fontSize: '1px'}}
    >
      <div style={{width: '100%', height: '100%'}}>
        {props.maxRank.id === 5 && 
        <Flyer1KTemplate
          user={props.user}
          photo={props.photo}
        />
        }
        {props.maxRank.id === 6 && 
        <Flyer5KTemplate
          user={props.user}
          photo={props.photo}
        />
        }
        {props.maxRank.id === 7 && 
        <Flyer10KTemplate
          user={props.user}
          photo={props.photo}
        />
        }
        {props.maxRank.id === 8 && 
        <Flyer25KTemplate
          user={props.user}
          photo={props.photo}
        />
        }
        {props.maxRank.id === 9 && 
        <Flyer50KTemplate
          user={props.user}
          photo={props.photo}
        />
        }
        {props.maxRank.id === 10 && 
        <Flyer100KTemplate
          user={props.user}
          photo={props.photo}
        />
        }
        {props.maxRank.id === 11 && 
        <Flyer250KTemplate
          user={props.user}
          photo={props.photo}
        />
        }
        {props.maxRank.id === 12 && 
        <Flyer500KTemplate
          user={props.user}
          photo={props.photo}
        />
        }
        {props.maxRank.id === 13 && 
        <FlyerSuperTemplate
          user={props.user}
          photo={props.photo}
        />
        }
        {props.maxRank.id === 14 && 
        <FlyerMasterTemplate
          user={props.user}
          photo={props.photo}
        />
        }
      </div>
    </CommonWrapper>
  )
}

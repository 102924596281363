import React, { useState, useEffect } from "react";
import styled from "styled-components";
import copy from 'copy-to-clipboard';
import { Row, Col, Alert } from "components/common";
import { getUser } from "utils/auth";
import { callGetApiWithAuth } from "utils/api";
import { CopyFilled, CheckOutlined } from "@ant-design/icons";

export default function Recommend() {
  const myUser = getUser();
  const [checkedLabel, setCheckedLabel] = useState("");
  const [copiedUrl, setCopiedUrl] = useState("");
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const onGetTableData = (res) => {
    setTableData(res.data.map(el => ({
      ...el,
      path: `${el.path.replace('#RefUsername', myUser.username)}`
    })))
    setIsLoading(false)
  }
  const onFailTableData = () => {
    setIsLoading(false)
  }
  const loadTableData = () => {
    setIsLoading(true)
    callGetApiWithAuth(`my_website/replicated/recommend_urls`, 
      onGetTableData, onFailTableData
    )
  }

  useEffect(() => {
    loadTableData()
  }, [])

  return (
    <Wrapper className="white-rounded-bg">
      <Row gutter={[24, 24]}>
        <Col xs={24}>
          <p className="url_heading">Recommended Urls</p>
          <Alert
            message={
              <label>
                Use the below URLs to pre-filter the shopping cart to display only certain brands.
              </label>
            }
            type="info"
            style={{
              marginBottom: 20,
            }}
            showIcon
          />
        </Col>
        {tableData.map((data) => (
          <Col className="copy-url-line" xs={24} xl={12}>
            <div className="label-container">
              <span>{data.title}:</span>
            </div>
            <div className="url-container">
              <div className="url-text">
                {data.path}
              </div>
              <div className="url-copy">
                <CopyFilled
                  onClick={() => {
                    copy(data.path);
                    setCheckedLabel(data.title);
                    setCopiedUrl(data.path);
                  }}
                  style={{ fontSize: 16 }}
                />
                {checkedLabel === data.title && (
                  <>
                    <CheckOutlined
                      style={{ fontSize: 16, color: "#52c41a" }}
                    />
                    <span className="small">&nbsp;Copied!</span>
                  </>
                )}
              </div>
            </div>
          </Col>
        ))}
        {/*
        <Col className="webPreview" xs={24} xl={14}>
          checkedLabel && (
            <div>
              <p className="url_heading">Website Preview</p>
              <div className="website-preview">
                <iframe
                  src={copiedUrl}
                  height="500px"
                  width="80%"
                  title="Website Preview"
                ></iframe>
              </div>
            </div>
          )
        </Col>
        */}
      </Row>
    </Wrapper>
  );
}

export const Wrapper = styled.div`
  .url_heading {
    font-size: 18px;
    font-weight: 700;
    color: #212b36;
    margin-bottom: 24px;
  }
  .copy-url-line {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .label-container {
      width: 240px;
      font-weight: 700;
      padding: 8px 16px;
      font-weight: 600;
      background: rgba(30, 169, 234, 0.08);
      color: #1ea9ea;
      white-space: nowrap;
      border: 1px solid rgba(145, 158, 171, 0.24);
      border-radius: 8px 0px 0px 8px;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: center;
    }
    .url-container {
      width: calc(100% - 242px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid rgba(145, 158, 171, 0.24);
      border-radius: 0px 8px 8px 0px;
      .url-text {
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 8px 16px;
        font-size: 14px;
        color: #637381;
      }
      .url-copy {
        display: flex;
        align-items: center;
        path {
          fill: #1ea9ea;
        }
        .small {
          margin-right: 10px;
        }
        .anticon-copy {
          margin: 10px;
        }
      }
    }
  }
  .website-preview {
    background: rgba(145, 158, 171, 0.08);
    border: 1px solid rgba(145, 158, 171, 0.24);
    border-radius: 16px;
    width: 100%;
    height: calc(100% - 52.28px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
      text-align: left;
    }
    iframe {
      box-shadow: 0px 0px 50px #ccc;
    }
  }
  @media (max-width: 576px) {
    .webPreview {
      display: none;
    }
    .copy-url-line {
      .label-container {
        width: 100%;
        border-radius: 8px 8px 0px 0px !important;
      }
      .url-container {
        width: 100%;
        border-radius: 0px 0px 8px 8px !important;
      }
    }
  }
`;

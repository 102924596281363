import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .website-details {
    .website-image {
      width: 100%;
      object-fit: cover;
      border: 1px solid aliceblue;
    }
    .website-title {
      font-size: 14px;
      margin-bottom: 4px;
      color: #637381;
      text-align: center;
      margin-top: 24px;
    }
    .website-url {
      margin-bottom: 16px;
      text-align: center;
      font-weight: normal;
      font-size: 14px;
      color: #212b36;
    }
  }
  .website-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 24px;
    border-top: 1px dashed rgba(145, 158, 171, 0.24);
  }
  .sharable-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    .sharable-links {
      .social-icon {
        margin: 0px 10px;
        cursor: pointer;
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  @media (max-width: 600px) {
    .website-button {
      margin-top: 30px;
      button {
        width: 100%;
      }
    }
  }
`;

import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100%;
  .slider-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    .slider-title {
      font-weight: normal;
      font-size: 18px;
    }
    .slider-arrows {
      svg {
        margin-left: 22px;
        cursor: pointer;
      }
    }
  }
  .slider-box {
    height: 100%;
    .slick-slider {
      height: 100%;
      .slick-list {
        height: 100%;
        .slick-track {
          height: 100%;
          .slick-slide > div {
            height: 100%;
          }
          .slick-slide > div > div {
            height: 100%;
          }
        }
      }
    }
  }
  .training-cards-container {
    height: 100%;
    .training-background {
      min-height: 328px;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
      border-radius: 16px;
      background-position: center;
    }
    .training-background-shade {
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 61.82%,
        rgba(0, 0, 0, 0.5) 75.59%,
        #000000 90.8%
      );
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      height: 100%;
      border-radius: 16px;
      width: 100%;
    }
    .training-desc {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 100%;
      padding: 20px 24px;
    }
    .training-title {
      overflow: hidden;
      white-space: nowrap !important;
      text-overflow: ellipsis;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      color: #ffffff;
      margin-bottom: 8px;
      a {
        color: inherit;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        svg {
          margin-right: 10px;
        }
      }
    }
    .training-content {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      max-width: 360px;
      color: #ffffff;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      overflow: hidden;
      a {
        color: inherit;
      }
    }
  }
  .slick-slider {
    .slick-prev {
      display: block;
      width: 20px;
      height: 20px;
      background: white;
      padding: 5px;
      border-radius: 50%;
      position: absolute;
      top: 18.5px;
      z-index: 4;
      left: calc(100% - 79px);
      transform: rotate(180deg);
    }
    .slick-next {
      display: block;
      width: 20px;
      height: 20px;
      background: white;
      padding: 5px;
      border-radius: 50%;
      position: absolute;
      top: 29px;
      right: 22px;
    }
  }
  .skeleton-section {
    position: relative;
    .training-background {
      background: #d9d9d9;
    }
  }
  .loading-spin-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(256, 256, 256, 0.8);
    top: 0;
    left: 0;
  }
  @media (max-width: 1600px) {
    .slider-box {
      margin: 0px -12px;
      .training-cards-container {
        margin: 0px 12px;
      }
    }
  }
  @media (max-width: 600px) {
    .slider-box {
      margin: 0px 0px;
      .training-cards-container {
        margin: 0px 0px;
      }
    }
  }
`;

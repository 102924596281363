import React, { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import {
  Row,
  Col,
  Select,
  Input,
  StartEndDatePicker,
  Button,
} from "components/common";
import { callGetApiWithAuth } from "utils/api";
import { ReactComponent as SearchOutlinedIcon } from "assets/icons/search-icon.svg";

export default function SearchBar(props) {
  const [formData, setFormData] = useState({
    uuid: "",
    rank_id: "",
    pay_rank_id: "",
    created_at_range: "",
    product_id: "",
    createdFrom: "",
    createdUntil: "",
  });
  const [ranks, setRanks] = useState([]);
  const [products, setProducts] = useState([]);

  const onGetRanks = (res) => {
    setRanks(res.data);
  };

  const onGetProducts = (res) => {
    setProducts(res.data);
  };

  const onFormInput = (field, value) => {
    const formData0 = {
      ...formData,
      [field]: value,
    };
    setFormData(formData0);
  };

  const onFormDate = (v) => {
    let formData0 = {
      ...formData,
      createdFrom: "",
      createdUntil: "",
      created_at_range: "",
    };
    if (v) {
      formData0 = {
        ...formData,
        createdFrom: v[0],
        createdUntil: v[1],
        created_at_range: `${moment(v[0]).format("YYYY-MM-DD")}|${moment(
          v[1]
        ).format("YYYY-MM-DD")}`,
      };
    }
    setFormData(formData0);
  };

  useEffect(() => {
    setFormData({
      ...formData,
      ...props.searchParam,
    });
  }, [props.searchParam]);

  useEffect(() => {
    callGetApiWithAuth(`common/ranks`, onGetRanks);
    callGetApiWithAuth(`report/ticket_report/products`, onGetProducts);
  }, []);

  return (
    <Wrapper>
      <Row gutter={[24, 24]}>
        <Col xs={24} lg={8} xl={5}>
          <div className="search_bar">
            <Input
              value={formData.uuid}
              onChange={(e) => onFormInput("uuid", e.target.value)}
              label={"User ID, Username, Name"}
              inputPrefix={<SearchOutlinedIcon />}
            />
          </div>
        </Col>
        <Col xs={24} lg={8} xl={5}>
          <StartEndDatePicker
            startDate={formData.createdFrom}
            endDate={formData.createdUntil}
            onChange={(v) => onFormDate(v)}
          />
        </Col>
        <Col xs={24} lg={8} xl={4}>
          <Select
            label="Select Rank"
            value={formData.rank_id}
            onChange={(v) => onFormInput("rank_id", v)}
            options={[
              { label: "Select Rank", value: "" },
              ...ranks.map((el) => ({
                label: el.name,
                value: el.id,
              })),
            ]}
          />
        </Col>
        <Col xs={24} lg={8} xl={4}>
          <Select
            label="Select Pay Rank"
            value={formData.pay_rank_id}
            onChange={(v) => onFormInput("pay_rank_id", v)}
            options={[
              { label: "Select Pay Rank", value: "" },
              ...ranks.map((el) => ({
                label: el.name,
                value: el.id,
              })),
            ]}
          />
        </Col>
        <Col xs={24} lg={8} xl={4}>
          <Select
            label="Select Ticket"
            value={formData.product_id}
            onChange={(v) => onFormInput("product_id", v)}
            options={[
              { label: "Select Ticket", value: "" },
              ...products.map((el) => ({
                label: el.title,
                value: el.id,
              })),
            ]}
          />
        </Col>
        <Col xs={24} lg={8} xl={2}>
          <Button
            icon={<SearchOutlinedIcon />}
            onClick={() => props.handleSearch(formData)}
          >
            Search
          </Button>
        </Col>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div``;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  TablePanel,
  Select,
  RankBadge,
  UserAvatar,
  CountryFlag,
} from "components/common";
import { callGetApiWithAuth } from "utils/api";
import { asDate, asVolume } from "utils/text";
import { varIs, varKey, varLabel } from "common/var";
import UplineBar from "./UplineBar";

export default function ListTable(props) {
  const history = useHistory();
  const myUser = useSelector((state) => state.auth.user);
  const [tableData, setTableData] = useState([]);
  const [paginationParam, setPaginationParam] = useState({
    currentPage: 1,
    perPage: 15,
    total: 0,
  });
  const [searchParam, setSearchParam] = useState({
    status: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [uplines, setUplines] = useState([]);

  const onGetTableData = (res) => {
    setTableData(res.data.childrenData.data);
    setPaginationParam({
      ...paginationParam,
      currentPage: res.data.childrenData.current_page,
      total: res.data.childrenData.total,
    });
    setUplines(res.data.uplines);
    setIsLoading(false);
  };
  const onFailTableData = () => {
    setIsLoading(false);
  };
  const loadTableData = (paginationParam0, searchParam0) => {
    setIsLoading(true);
    const params = {
      user_id: props.selectedId,
      ...searchParam0,
      page: paginationParam0.currentPage,
      per_page: paginationParam0.perPage,
    };
    const q = Object.keys(params)
      .map((k) => k + "=" + params[k])
      .join("&");
    callGetApiWithAuth(
      `report/ticket_report/table?${q}`,
      onGetTableData,
      onFailTableData
    );
  };
  const onPageChange = (page) => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: page,
    };
    loadTableData(paginationParam0, searchParam);
  };

  useEffect(() => {
    if (myUser) {
      const paginationParam0 = {
        ...paginationParam,
        currentPage: 1,
      };
      setPaginationParam(paginationParam0);
      loadTableData(paginationParam0, searchParam);
    }
  }, [myUser, props.selectedId, searchParam]);

  return (
    <>
      <TablePanel
        title={
          <div className="d-flex align-items-center">
            <StatusSelectWrapper>
              <Select
                label="Enroller List"
                value={searchParam.status}
                options={[
                  { label: "All", value: "" },
                  { label: "Active", value: "active" },
                  { label: "Inactive", value: "inactive" },
                  { label: "Closed", value: "closed" },
                  { label: "Purged", value: "purged" },
                ]}
                onChange={(v) =>
                  setSearchParam({
                    ...searchParam,
                    status: v,
                  })
                }
              />
            </StatusSelectWrapper>
          </div>
        }
        data={tableData}
        loading={isLoading}
        paginationParam={paginationParam}
        onPageChange={onPageChange}
        toolbar={<UplineBar uplines={uplines} goUser={props.goUser} />}
        columns={[
          {
            title: "ID",
            key: "uuid",
            render: (_, record) => <span>#{record.uuid}</span>,
          },
          {
            title: "Level",
            key: "level",
            render: (_, record) => <span>{record.level}</span>,
          },
          {
            title: "Username",
            key: "username",
            render: (_, record) => (
              <div className="d-flex align-items-center">
                {record.has_children ? (
                  <a
                    href="javascript:void(0)"
                    onClick={() => props.goUser(record)}
                    style={{color: "#1EA9EA"}}
                  >
                    {record.username}
                  </a>
                ) : (
                  <span>{record.username}</span>
                )}
              </div>
            ),
          },
          {
            title: "Name",
            key: "name",
            render: (_, record) => (
              <div className="d-flex align-items-center">
                <UserAvatar
                  image={record.image}
                  title={`${record.first_name} ${record.last_name}`}
                  status={varKey("user.status", record.status)}
                  type={varKey("user.type", record.type)}
                  typeLabel={varLabel("user.type", record.type)}
                  joinDate={asDate(record.created_at)}
                />
              </div>
            ),
          },
          {
            title: "Country",
            key: "country",
            render: (_, record) => <CountryFlag country={record.country} />,
          },
          {
            title: "Currrent Rank",
            key: "rank_id",
            render: (_, record) => (
              <span>
                {varIs("user.type", record.type, "affiliate") ? (
                  <RankBadge label={record.rank.name} />
                ) : (
                  ""
                )}
              </span>
            ),
          },
          {
            title: "Paid Rank",
            key: "pay_rank_id",
            render: (_, record) => (
              <span>
                {varIs("user.type", record.type, "affiliate") ? (
                  <RankBadge label={record.pay_rank.name} />
                ) : (
                  ""
                )}
              </span>
            ),
          },
          {
            title: "Tickets",
            key: "ticket",
            render: (_, record) => (
              <div style={{ width: "250px" }}>{record.tickets}</div>
            ),
          },
        ]}
      />
    </>
  );
}

const StatusSelectWrapper = styled.div`
  .ant-form-item-label {
    top: 6px !important;
  }
`;

import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import AuthedLayout from "layouts/authed/AuthedLayout";
import AuthLayout from "layouts/auth/AuthLayout";

import LogoutRoute from "views/auth/logout/LogoutRoute";
import LoginPage from "views/auth/login/LoginPage";
import HomePage from "views/home/HomePage";
import SubscriptionPage from "views/myAccount/subscription/SubscriptionPage";
import OrderHistoryPage from "views/myAccount/orderHistory/OrderHistoryPage";
import OrderReceiptPage from "views/myAccount/orderReceipt/OrderReceiptPage";
import CreditWalletPage from "views/myAccount/creditWallet/CreditWalletPage";
import GiftWalletPage from "views/myAccount/giftWallet/GiftWalletPage";
import LoyaltyWalletPage from "views/myAccount/loyaltyWallet/LoyaltyWalletPage";
import BucketWalletPage from "views/myAccount/bucketWallet/BucketWalletPage";
import SavviBuyPage from "views/myAccount/savviWallet/BuyPage";
import PaymentOptionPage from "views/myAccount/paymentOption/PaymentOptionPage";
import AccountVerificationPage from "views/myAccount/verification/VerificationPage";
import HoldingTankPage from "views/myTeam/holdingTank/HoldingTankPage";
import UplinePage from "views/myTeam/upline/UplinePage";
import UnilevelListPage from "views/myTeam/unilevelList/UnilevelListPage";
import BinaryListPage from "views/myTeam/binaryList/BinaryListPage";
import UnilevelTreePage from "views/myTeam/unilevelTree/UnilevelTreePage";
import BinaryTreePage from "views/myTeam/binaryTree/BinaryTreePage";
import AccountSettingPage from "views/myAccount/accountSetting/AccountSettingPage";
import LiveStreamPage from "views/liveStream/LiveStreamPage";
import PublisherPage from "views/publisher/PublisherPage";
import ViewerPage from "views/viewer/ViewerPage";
import ContactManagerPage from "views/contactManager/ContactManagerPage";
import TrainingPage from "views/training/TrainingPage";
import MarketingAssetsPage from "views/tool/marketingAsset/MarketingAssetPage";
import ComplianceCornerPage from "views/tool/complianceCorner/ComplianceCornerPage";
import TrafficRotatorPage from "views/tool/trafficRotator/TrafficRotatorPage";
import LeaderboardPage from "views/leaderboard/LeaderboardPage";
import EarningPage from "views/earning/EarningPage";
import ReplicatedPage from "views/myWebsite/replicated/ReplicatedPage";
import BrandSitePage from "views/myWebsite/brand/BrandSitePage";
import ShareCartPage from "views/myWebsite/shareCart/ShareCartPage";
import ProductFunnelPage from "views/myWebsite/product/ProductFunnelPage";
import RotatorPage from "views/myWebsite/rotator/RotatorPage";
import NotificationPage from "views/notification/NotificationPage";
import CheckoutPage from "views/checkout/CheckoutPage";
import ChatPage from "views/teamChat/ChatPage";
import SupportPage from "views/support/SupportPage";
import LeadsAndTrafficPage from "views/tool/leadsAndTraffic/LeadsAndTrafficPage";
import CapturePage from "views/myWebsite/capture/CapturePage";
import HltOldPage from "views/hltOld/HltOldPage";
import HltPage from "views/hlt/HltPage";
import HltAccessPage from "views/hlt/HltAccessPage";
import RankSnapshotPage from "views/myRank/rankSnapshot/RankSnpashotPage";
import RankRecognitionPage from "views/myRank/rankRecognition/RankRecognitionPage";
import TeamSalePage from "views/report/teamSale/TeamSalePage";
import CommissionCalculatorPage from "views/report/commissionCalculator/CommissionCalculatorPage";
import VolumeReportPage from "views/report/volumeReport/VolumeReportPage";
import DeclineReportPage from "views/report/declineReport/DeclineReportPage";
import TopEarnerPage from "views/report/topEarner/TopEarnerPage";
import RankReportPage from "views/report/rankReport/RankReportPage";
import NewRankUpPage from "views/report/newRankUp/NewRankUpPage";
import BinaryQualPage from "views/report/binaryQual/BinaryQualPage";
import CustomerMasteryPage from "views/report/customerMastery/CustomerMasteryPage";
import TelegramReportPage from "views/report/telegramReport/TelegramReportPage";
import PromoTicketReportPage from "views/report/promoTicketReport/PromoTicketReportPage";
import TicketReportPage from "views/report/ticketReport/TicketReportPage";
import AbandonCartPage from "views/report/abandonCart/AbandonCartPage";
import CustomerReportPage from 'views/report/customerReport/CustomerReportPage';
import AffiliateReportPage from 'views/report/affiliateReport/AffiliateReportPage';
import RefundedReportPage from 'views/report/refundedReport/Page';
import Dti4freeReportPage from 'views/report/dti4freeReport/Dti4freeReportPage';
import IdAndCreditVerificationPage from "views/idAndCreditVerification/IdAndCreditVerificationPage";
import TaxVerificationPage from "views/taxVerification/TaxVerificationPage";
import BuyTicketPage from "views/ticket/buyTicket/BuyTicketPage";
import MyTicketPage from "views/ticket/myTicket/MyTicketPage";
import OnlineMeetingPage from "views/ticket/online/OnlineMeetingPage";
import SupportChatPage from "views/supportChat/SupportChatPage";
import MdcLivePage from "views/mdcLive/MdcLivePage";
import WatchList from "views/training/watchList/WatchList";
import EventAudioPage from "views/eventAudio/EventAudioPage";
import SetcAboutPage from 'views/setc/AboutPage';
import SetcApplyPage from 'views/setc/ApplyPage';
import SetcSharePage from 'views/setc/SharePage';
import SetcLeaderboardPage from 'views/setc/LeaderboardPage';
import IncentiveTripPage from "views/event/incentiveTrip/IncentiveTripPage";
import RunwayTripPage from 'views/event/runway/RunwayTripPage';
import DTIPointsReportPage from "views/report/dtiPointsReport/DTIPointsReportPage";
import DtiVipPromoReportPage from "views/report/dtiVipPromoReport/DtiVipPromoReportPage";

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <AuthRoute path="/login" component={LoginPage} />
        <Route path="/logout" component={LogoutRoute} />
        <AuthedRoute path="/" component={HomePage} fullLayout exact={true} />
        <AuthedRoute
          publicRoute={false}
          path="/my_account/subscription"
          component={SubscriptionPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/my_account/order_history"
          component={OrderHistoryPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/my_account/setting"
          component={AccountSettingPage}
          fullLayout
        />
        <Route
          path="/my_account/order_receipt/:orderId"
          component={OrderReceiptPage}
        />
        <AuthedRoute
          publicRoute={false}
          path="/my_account/credit_wallet"
          component={CreditWalletPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/my_account/gift_wallet"
          component={GiftWalletPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/my_account/loyalty_wallet"
          component={LoyaltyWalletPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/my_account/brand_buck_wallet"
          component={BucketWalletPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/my_account/savvi_buy"
          component={SavviBuyPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/my_account/payment_option"
          component={PaymentOptionPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/my_account/verification"
          component={AccountVerificationPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/my_team/holding_tank"
          component={HoldingTankPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/my_team/upline"
          component={UplinePage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/my_team/unilevel_list"
          component={UnilevelListPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/my_team/binary_list"
          component={BinaryListPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/my_team/unilevel_tree"
          component={UnilevelTreePage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/my_team/binary_tree"
          component={BinaryTreePage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/team_chat"
          component={ChatPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/support_chat"
          component={SupportChatPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/live_stream"
          component={LiveStreamPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/publisher"
          component={PublisherPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/viewer"
          component={ViewerPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/contact_manager"
          component={ContactManagerPage}
          fullLayout
        />
        <AuthedRoute
          exact
          publicRoute={false}
          path="/training"
          component={TrainingPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/training/watchList/:id"
          component={WatchList}
          fullLayout
          exact={true}
        />
        <AuthedRoute
          publicRoute={false}
          path="/tool/marketing_asset"
          component={MarketingAssetsPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/tool/compliance_corner"
          component={ComplianceCornerPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/tool/traffic_rotator"
          component={TrafficRotatorPage}
          fullLayout
        />
        {/* <AuthedRoute
          publicRoute={false}
          path="/tool/leads_and_traffic"
          component={LeadsAndTrafficPage}
          fullLayout
        /> */}
        <AuthedRoute
          publicRoute={false}
          path="/leaderboard"
          component={LeaderboardPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/earning"
          component={EarningPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/my_website/replicated"
          component={ReplicatedPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/my_website/brand"
          component={BrandSitePage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/my_website/capture"
          component={CapturePage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/my_website/product"
          component={ProductFunnelPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/my_website/share_cart"
          component={ShareCartPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/my_website/rotator"
          component={RotatorPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/notification"
          component={NotificationPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/hlt_old"
          component={HltOldPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/hlt"
          component={HltPage}
          fullLayout
        />
        <AuthedRoute path="/hlt_access" component={HltAccessPage} />
        <AuthedRoute
          publicRoute={false}
          path="/support"
          component={SupportPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/checkout"
          component={CheckoutPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/my_rank/rank_snapshot"
          component={RankSnapshotPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/my_rank/rank_recognition"
          component={RankRecognitionPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/report/commission_calculator"
          component={CommissionCalculatorPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/report/volume_report"
          component={VolumeReportPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/report/decline_report"
          component={DeclineReportPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/report/top_earner"
          component={TopEarnerPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/report/rank_report"
          component={RankReportPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/report/new_rank_up"
          component={NewRankUpPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/report/binary_qual"
          component={BinaryQualPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/report/customer_mastery"
          component={CustomerMasteryPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/report/telegram_report"
          component={TelegramReportPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/report/promo_ticket_report"
          component={PromoTicketReportPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/report/ticket_report"
          component={TicketReportPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/report/abandon_cart"
          component={AbandonCartPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/report/customer_report"
          component={CustomerReportPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/report/affiliate_report"
          component={AffiliateReportPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/report/refunded_report"
          component={RefundedReportPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/report/dti_4free_report"
          component={Dti4freeReportPage}
          fullLayout
        />
         <AuthedRoute
          publicRoute={false}
          path="/report/dti_points_report"
          component={DTIPointsReportPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/ticket/buy_ticket"
          component={BuyTicketPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/ticket/my_ticket"
          component={MyTicketPage}
          fullLayout
        />
          <AuthedRoute
          publicRoute={false}
          path="/report/dti_vip_promo_report"
          component={DtiVipPromoReportPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/ticket/online"
          component={OnlineMeetingPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/verification"
          component={IdAndCreditVerificationPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/tax_form"
          component={TaxVerificationPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/mdc_live"
          component={MdcLivePage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/ticket/event_audio"
          component={EventAudioPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/setc/about"
          component={SetcAboutPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/setc/apply"
          component={SetcApplyPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/setc/share"
          component={SetcSharePage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/setc/leaderboard"
          component={SetcLeaderboardPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/event/incentive_trip"
          component={IncentiveTripPage}
          fullLayout
        />
        <AuthedRoute
          publicRoute={false}
          path="/event/runway_trip"
          component={RunwayTripPage}
          fullLayout
        />
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;

const AuthRoute = ({ component: Component, ...props }) => {
  return (
    <Route
      {...props}
      render={(props) => (
        <AuthLayout pathname={props.location.pathname}>
          <Component {...props} />
        </AuthLayout>
      )}
    />
  );
};

const AuthedRoute = ({
  component: Component,
  fullLayout,
  publicRoute,
  ...props
}) => {
  return (
    <Route
      {...props}
      render={(props) => (
        <AuthedLayout
          fullLayout={fullLayout}
          pathname={props.location.pathname}
          publicRoute={publicRoute}
        >
          <Component {...props} />
        </AuthedLayout>
      )}
    />
  );
};

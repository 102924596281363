import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Button } from 'components/common'
import { varIs } from 'common/var'

export default function SendBtnSection(props) {  
  const myUser = useSelector(state => state.auth.user)

  return (
    <Wrapper>
      <Button onClick={() => {
        if (myUser && !varIs('user.status', myUser.status, 'hold_temp')) {
          props.setModalMode('send')
        }
      }}>
        Send Gift Card
      </Button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  button {
    height: 67px;
    width: 100%;
  }
`

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { TablePanel, UserAvatar } from "components/common";
import { callGetApiWithAuth } from "utils/api";
import { asVolume } from "utils/text";

export default function TableView(props) {
  const [tableData, setTableData] = useState([]);
  const [paginationParam, setPaginationParam] = useState({
    currentPage: 1,
    perPage: 15,
    total: 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  const onGetTableData = (res) => {
    setPaginationParam({
      ...paginationParam,
      currentPage: res.data.current_page,
      total: res.data.total,
    });
    setTableData(res.data.data);
    setIsLoading(false);
  };
  const onFailTableData = () => {
    setIsLoading(false);
  };

  const loadTableData = (paginationParam0) => {
    setIsLoading(true);
    const params = {
      page: paginationParam0.currentPage,
      per_page: paginationParam0.perPage,
    };
    const q = Object.keys(params)
      .map((k) => k + "=" + params[k])
      .join("&");
    callGetApiWithAuth(
      `report/customer_mastery/pes?${q}`,
      onGetTableData,
      onFailTableData
    );
  };

  const loadLastTableData = (paginationParam0) => {
    setIsLoading(true);
    const params = {
      page: paginationParam0.currentPage,
      per_page: paginationParam0.perPage,
    };
    const q = Object.keys(params)
      .map((k) => k + "=" + params[k])
      .join("&");
    callGetApiWithAuth(
      `report/customer_mastery/last_pes?${q}`,
      onGetTableData,
      onFailTableData
    );
  };

  const onPageChange = (page) => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: page,
    };
    if (props.viewMode === "Current Month") {
      loadTableData(paginationParam0);
    } else if (props.viewMode === "Last Month") {
      loadLastTableData(paginationParam0);
    }
  };

  useEffect(() => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: 1,
    };
    if (props.viewMode === "Current Month") {
      loadTableData(paginationParam0);
    } else if (props.viewMode === "Last Month") {
      loadLastTableData(paginationParam0);
    }
  }, [props.viewMode]);

  return (
    <Wrapper>
      <TablePanel
        title={"Enroller Tree"}
        data={tableData}
        paginationParam={paginationParam}
        onPageChange={onPageChange}
        loading={isLoading}
        columns={[
          {
            title: "ID",
            key: "uuid",
            render: (_, record) => <span>{record.uuid}</span>,
          },
          {
            title: "Username",
            key: "username",
            render: (_, record) => <span>{record.username}</span>,
          },
          {
            title: "Name",
            key: "name",
            render: (_, record) => (
              <div className="d-flex align-items-center">
                <UserAvatar
                  image={record.image}
                  title={`${record.first_name} ${record.last_name}`}
                />
              </div>
            ),
          },
          {
            title: "Total Customer Volume",
            key: "tcv",
            render: (_, record) => <span>{asVolume(record.tcv)}</span>,
          },
          {
            title: "Current Qualified Tier",
            key: "child_cv",
            render: (_, record) => <span>{asVolume(record.child_cv)}</span>,
          },
          {
            title: "Next Qualified Tier",
            key: "child_cv2",
            render: (_, record) => <span>{asVolume(record.child_cv2)}</span>,
          },
        ]}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background: #ffffff !important;
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2),
    0px 12px 24px -4px rgba(145, 158, 171, 0.12) !important;
  border-radius: 16px !important;
  .cardRoot {
    // background: transparent;
    box-shadow: none;
  }
`;

import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .website-details {
    .website-image {
      width: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
    .website-title {
      font-size: 14px;
      color: #637381;
      text-align: center;
      margin-top: 24px;
    }
    .website-url {
      text-align: center;
      font-weight: normal;
      font-size: 14px;
      color: #212B36;
    }
  }
  .website-button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;
    padding-top: 24px;
    border-top: 1px dashed rgba(145, 158, 171, 0.24);
  }
  .sharable-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 24px 0px;
    .sharable-links {
      .social-icon {
        margin: 0 10px;
        cursor: pointer;
      }
      img {
          width: 20px;
          height: 20px;
        }
    }
  }
  .tour-video {
    margin-top: 16px;
    label {
      color: #7f8fa3;
      margin-right: 8px;
    }
    .ant-select-selector {
      height: 40px !important;
      padding-top: 4px !important;
    }
  }
`;

import React, { useState } from "react";
import { Tabs } from "components/common";
import BalanceSubPage from "./balance/BalanceSubPage";
import LogSubPage from "./log/LogSubPage";
import { Wrapper } from "./LoyaltyWalletPage.styled";
import LoyaltyDescriptionSection from "./faq/LoyaltyDescriptionSection";
import PendingSubPage from './pending/PendingSubPage'

const { TabPane } = Tabs;

export default function LoyaltyWalletPage() {
  const [selectedTab, setSelecetedTab] = useState("Loyalty Rewards Balance");
  return (
    <Wrapper>
      <div className="main-container">
        <div className="subscriptions-tabs">
          <Tabs activeKey={selectedTab} onChange={(e) => setSelecetedTab(e)}>
            <TabPane
              tab={
                <span
                  className={
                    selectedTab == "Loyalty Rewards Balance"
                      ? "active-tab"
                      : "inactive-tab"
                  }
                >
                  Loyalty Rewards Balance
                </span>
              }
              key="Loyalty Rewards Balance"
            />
            <TabPane
              tab={
                <span
                  className={
                    selectedTab == "Current Loyalty Rewards" ? "active-tab" : "inactive-tab"
                  }
                >
                  Current Loyalty Rewards
                </span>
              }
              key="Current Loyalty Rewards"
            />
            <TabPane
              tab={
                <span
                  className={
                    selectedTab == "Loyalty Rewards Log" ? "active-tab" : "inactive-tab"
                  }
                >
                  Loyalty Rewards Log
                </span>
              }
              key="Loyalty Rewards Log"
            />
          </Tabs>
        </div>
      </div>
      {selectedTab == "Loyalty Rewards Balance" && 
        <div className="loyal-rewar-bal">
          <BalanceSubPage />
          <LoyaltyDescriptionSection />
        </div>
      }
      {selectedTab == "Loyalty Rewards Log" && <LogSubPage />}
      {selectedTab == "Current Loyalty Rewards" && <PendingSubPage />}
    </Wrapper>
  );
}

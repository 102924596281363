import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Card, Select, SingleLineChart } from "components/common";
import { callGetApiWithAuth } from "utils/api";
import { asDate, asUsdPrice } from "utils/text";

export default function ChartView() {
  const [chartData, setChartData] = useState([]);

  const config = {
    xField: "date",
    yField: "amount",
    yAxis: {
      label: {
        formatter: (v) => asUsdPrice(v),
      },
    },
    xAxis: {
      label: {
        formatter: (v) => asDate(v),
      },
    },
    height: 290,
  };

  const onGetChartData = (res) => {
    setChartData(
      res.data.map((el) => ({
        date: el.from,
        amount: el.amount * 1,
      }))
    );
  };
  const onFailChartData = () => {};
  const loadChartData = () => {
    callGetApiWithAuth(`earning/graph`, onGetChartData, onFailChartData);
  };

  useEffect(() => {
    loadChartData();
  }, []);

  return (
    <Card className="white-rounded-bg">
      <Wrapper>
        <div className="title-container">
          <div className="title">
            <div className="chart-heading">Earnings Growth</div>
            {/* <div className="percentage">(+43%) than last year</div> */}
          </div>
        </div>
        <SingleLineChart data={chartData} {...config} />
      </Wrapper>
    </Card>
  );
}

const Wrapper = styled.div`
  .title-container {
    margin-bottom: 48px;
    .title {
      .chart-heading {
        font-weight: 700;
        font-size: 18px;
      }
      .percentage {
        color: #637381;
      }
    }
    .year-filter {
      .ant-select-selector {
        height: 40px !important;
        padding-top: 6px !important;
      }
    }
  }
`;

import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  .website-details {
    .website-title {
      font-weight: 700;
      font-size: 18px;
      color: #212b36;
      margin-bottom: 24px;
    }
    .website-description {
      font-weight: normal;
      font-size: 14px;
      margin-bottom: 24px;
      color: #637381;
      padding-right: 70px;
    }
    .website-description.example-url-title {
      margin-bottom: 4px;
      font-weight: 700;
      color: #212b36;
      font-size: 16px;
    }
    .website-description.example-url {
      margin-bottom: 24px;
      color: #1ea9ea;
    }
    .url-guide {
      font-size: 14px;
      margin-bottom: 8px;
      color: #637381;
    }
    .referer-container {
      .label {
        display: none;
      }
      .errorWrapper {
        margin-top: 2px;
      }
    }
    .referral-url-copy-icon {
      font-size: 22px;
      cursor: pointer;
      margin-left: 20px;
    }
  }
  .input-container {
    width: 100%;
    .label-title {
      font-weight: 700;
      color: #637381;
      font-size: 14px;
    }
    .ant-input {
      width: 360px;
    }
  }
  @media (max-width: 600px) {
    .input-container {
      .label-title {
        margin-bottom: 8px;
      }
      .ant-input {
        width: 100%;
      }
    }
  }

  .referer-title {
    height: 100%;
    display: flex;
    align-items: flex-end;
    font-weight: 500;
    font-size: 14px;
    color: #7f8fa3;
  }
  .website-button {
    margin-bottom: 28px;
    margin-top: 40px;
  }
  .autocomplete-container {
    .ant-select-selector {
      height: 34px;
      input {
        padding-top: 4px;
      }
    }
  }
  @media (max-width: 1400px) {
    .website-description {
      padding-right: 0px !important;
    }
  }
  @media (max-width: 900px) {
    .website-button {
      margin: 0px;
    }
  }
  @media (max-width: 600px) {
    .website-button {
      margin-top: 16px;
      button {
        width: 100%;
      }
    }
  }
  .title {
    color: ${(props) => props.theme.palette.secondaryText};
    font-size: 16px;
  }
  .divider {
    width: calc(100% - 120px);
    height: 1px;
    background-color: #e5e5e5;
  }
`;

/* eslint-disable react/prop-types */
import { DatePicker as DatePicker0 } from "antd";
import React, { useState, useEffect } from "react";
import moment from "moment";
import styled from "styled-components";

const DatePicker = ({ date, height, width, ...props }) => {
  const [active, setActive] = useState(false);
  const handleFocus = () => {
    setActive(true);
  };

  const handleBlur = (e) => {
    if (props.value) {
      setActive(true);
    } else {
      setActive(false);
    }
  };

  useEffect(() => {
    if (props.value) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [props.value]);

  return (
    <Wrapper>
      {props?.label && (
        <label
          className={
            active
              ? "ant-form-item-label ant-form-item-label-active"
              : "ant-form-item-label"
          }
          htmlFor="outlined-input"
        >
          {props?.label}
        </label>
      )}
      <DatePicker0
        {...props}
        value={props.value ? moment(props.value) : ""}
        style={{
          height: height || "34px",
          width: width || "auto",
        }}
        className="dob-input"
        placeholder=""
        id="outlined-input"
        bordered={false}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </Wrapper>
  );
};

export default DatePicker;

const Wrapper = styled.div`
  .dob-input {
    width: 100% !important;
    height: 48px !important;
  }
  .ant-picker {
    padding: 0px;
    .ant-picker-input {
      border: 1px solid rgba(145, 158, 171, 0.32);
      border-radius: 8px !important;
      height: 48px !important;
      padding: 0px 14px;
      input {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #919eab;
      }
    }
  }
  .ant-picker-clear {
    right: 12px !important;
  }
  position: relative;
  .ant-input {
    height: 48px !important;
    border: 1px solid rgba(145, 158, 171, 0.32);
    border-radius: 8px !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #212b36;
    padding: 12px 14px;
  }
  .ant-input-affix-wrapper.ant-input-affix-wrapper-outlined {
    border-radius: 4px;
    position: relative;
  }

  .ant-input-affix-wrapper.ant-input-affix-wrapper-outlined
    .ant-input:not(:first-child) {
    border-radius: 4px;
    padding-top: 26px;
  }

  .ant-input-affix-wrapper.ant-input-affix-wrapper-outlined .ant-input:focus,
  .ant-input-affix-wrapper.ant-input-affix-wrapper-outlined .ant-input-focused {
    border-color: #1890ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  .ant-input-affix-wrapper.ant-input-affix-wrapper-outlined .ant-input:hover {
    border-color: #40a9ff;
  }

  .ant-input-affix-wrapper.ant-input-affix-wrapper-outlined
    .ant-input:not(:first-child)::placeholder {
    opacity: 0.6;
  }

  .ant-input-affix-wrapper.ant-input-affix-wrapper-outlined
    .ant-input:not(:first-child):focus::placeholder,
  .ant-input-affix-wrapper.ant-input-affix-wrapper-outlined
    .ant-input-focused::placeholder {
    opacity: 0.4;
  }

  .ant-form-item-label {
    position: absolute;
    top: 12px;
    left: 14px;
    transition: all 0.2s ease-out;
    pointer-events: none;
    color: #919eab;
  }

  .ant-form-item-label-active {
    transform: translateY(-20px) scale(1);
    font-weight: 400;
    font-size: 12px;
    color: #919eab;
    background: white;
    z-index: 6;
    padding: 0px 1px;
  }
`;

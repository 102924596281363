import React, { useEffect, useState } from "react";
import { Spin, Select } from "components/common";
import { Wrapper } from "./BestProductList.styled";
import { callGetApiWithAuth } from "utils/api";
import { asPrice } from "utils/text";
import { getToken } from "utils/auth";
import Carousel from "react-multi-carousel";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1400 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 1400, min: 1100 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1100, min: 800 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 800, min: 0 },
    items: 1
  }
};

export default function BestProductList() {
  const [type, setType] = useState('best');
  const [bestProducts, setBestProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleGoProductDetailPage = (path) => {
    const token = getToken();
    if (token) {
      window.open(
        `${process.env.REACT_APP_CORP_URL}/login?auto-token-login&pathName=shop/${path}&token=${token}`,
        "_blank"
      );
    }
  };

  const onGetSuccessBestProducts = ({ data }) => {
    setIsLoading(false);
    setBestProducts(data);
  };

  const onGetFailedBestProducts = (err) => {
    setIsLoading(false);
  };

  const loadBestProducts = () => {
    setIsLoading(true);
    if (type === 'best') {
      callGetApiWithAuth(
        "home/best_products",
        onGetSuccessBestProducts,
        onGetFailedBestProducts
      );
    } else if (type === 'promotion') {
      callGetApiWithAuth(
        "home/promotion_products",
        onGetSuccessBestProducts,
        onGetFailedBestProducts
      );
    } else if (type === 'new') {
      callGetApiWithAuth(
        "home/new_products",
        onGetSuccessBestProducts,
        onGetFailedBestProducts
      );
    }
  };

  useEffect(() => {
    loadBestProducts();
  }, [type]);

  return (
    <Wrapper>
      <div className="slider-title-container">
        <div className='slider-main-title'>
          Best Selling Product
        </div>
        <div className="slider-title">
          <span className={`slider-title-tab ${type==='best'?'active':''}`}
            onClick={()=>setType('best')}
          >
            Best Sellers
          </span>
          <span className={`slider-title-tab ${type==='promotion'?'active':''}`}
            onClick={()=>setType('promotion')}
          >
            Daily Deals
          </span>
          {/* <span className={`slider-title-tab ${type==='new'?'active':''}`}
            onClick={()=>setType('new')}
          >
            New Arrivals
          </span> */}
        </div>
        <div className="slider-mobile-title">
          <Select value={type}
            onChange={v=>setType(v)}
            options={[
              {
                value: 'best', label: 'Best Selling Products'
              },
              {
                value: 'promotion', label: 'Daily Deals'
              },
              // {
              //   value: 'new', label: 'New Arrivals'
              // },
            ]}
          />
        </div>
        {/* <div className="slider-arrows"></div> */}
      </div>
      <div className="slider-box">
        <Carousel responsive={responsive}>
          {bestProducts.map((el, index) => (
            <div className="products-list" key={index}>
              <div className="products-img">
                <a onClick={() => handleGoProductDetailPage(el.path)}>
                  <img src={el.image_path} />
                </a>
              </div>
              <div className="products-heading">
                <h3>{el.title}</h3>
                <h5>
                  <span>{asPrice(el.member_price)}</span>
                </h5>
              </div>
              {/* <div className="products-cart">
                <ul>
                  <li>
                    <a onClick={() => handleGoProductDetailPage(el.path)}>
                      Learn More
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
          ))}
        </Carousel>
        {isLoading && (
          <div className="loading-spin-container">
            <Spin spinning={true} />
          </div>
        )}
      </div>
    </Wrapper>
  );
}

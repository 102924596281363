import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Spin } from 'components/common'
import { callGetApiWithAuth } from "utils/api";

export default function HltAccessPage() {
  const history = useHistory()
  const myUser = useSelector(state=>state.auth.user)

  const onLoginDone = (res) => {
    const winUrl = URL.createObjectURL(
      new Blob([res.data.html_form], { type: "text/html" })
    );
    window.open(
      winUrl,
      "_self",
    );
  }
  const onLoginFail = () => {
    history.push('/hlt')
  }
  const handleLogin = () => {
    callGetApiWithAuth(`hlt/tra_login`, 
      onLoginDone, onLoginFail
    )
  }

  useEffect(() => {
    if (myUser) {
      handleLogin()
    } else {
      history.push('/')
    }
  }, [])

  return (
    <div className='d-flex justify-content-center align-items-center'
      style={{ 
        width: '100%', minHeight: 400,
      }}
    >
      <Spin spinning={true} 
        description={'Loading...'}
      />
    </div>
  )
}

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  Row, Col, TablePanel, Button,
  Progress, Carousel, Spin,
} from 'components/common';
import { callGetApiWithAuth, callPostApiWithAuth } from 'utils/api';
import { asDate, asVolume } from 'utils/text';
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";

export default function RunwayTripPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [banners, setBanners] = useState([]);
  const [statData, setStatData] = useState({
    rank_id: 1,
    lv1_qv: 0,
    lv2_qv: 0,
    lv3_qv: 0,
    lv4_qv: 0,
    has_bb_order: false,
    has_bb_recur: false,
    has_lv1_order: false,
    has_lv2_order: false,
    has_lv3_order: false,
    has_lv4_order: false,
  });
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const onAddCart = (sku) => {
    let product0 = null;
    products.forEach(product => {
      if (product.sku === sku) {
        product0 = product;
      }
    })
    if (product0) {
      dispatch({
        type: "checkout.SET_ORDER_DETAILS",
        payload: [
          {
            product: product0,
            quantity: 1,
            pay_cycle: 0,
            contained_products: [],
          }
        ],
      });
      history.push('/checkout')
    }
  }

  useEffect(() => {
    callGetApiWithAuth(
      `event/runway_trip/banners`,
      ({ data }) => {
        setBanners(data.slice(0, 1))
      }
    );
    setIsLoading(true);
    callGetApiWithAuth(
      `event/runway_trip/stat`,
      ({ data }) => {
        setStatData({
          ...statData,
          ...data,
        });
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
      }
    );
    callGetApiWithAuth(
      `event/runway_trip/products`,
      ({ data }) => {
        setProducts(data)
      }
    )
  }, [])
  
  return (
    <Wrapper>
      {banners.length>0 && 
      <div className='ban-cont'>
        {banners.map(el => (
          <div className='ban-item'>
            <div className='ban-image'>
              <img src={el.image} />
            </div>
            <div className='ban-desc'>
              <h3>{el.title}</h3>
              <div className='ban-content'
                dangerouslySetInnerHTML={{
                  __html: el.content
                }}
              />
              <div className='d-flex align-items-center'
                style={{ marginTop: 24 }}
              >
                {el.button_url &&
                <a href='#' className='link-btn'
                  onClick={()=>{
                    window.open(el.button_url, '_blank');
                  }
                }>
                  {el.button_title}
                </a>}
                <div style={{ width: 20 }} />
                <a className='link-btn'
                  target='_blank'
                  href='https://mdc-assets.s3.us-east-2.amazonaws.com/other/V1_24_3525_MDC_Runway+to+25K+Updated.pdf'
                >
                  DOWNLOAD ROADMAP
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>}
      <div className='qual-cont'>
        <h2>
          Qualification Tracker
        </h2>
        <Row gutter={[20, 20]}>
          <Col xs={24}>
            <div className='qual-brow'>
              <label>
                {`Qualification Period (Start Date : ${statData.from_date} -> End Date : ${statData.to_date})`}
              </label><br/>
              <Progress percent={Math.ceil((new Date() - new Date(statData.from_date))/(new Date(statData.to_date) - new Date(statData.from_date))* 100)}
                size="small"
                strokeWidth={20}
                strokeColor="#10B856"
                showInfo={false}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col xs={24}>
            <div className='qual-brow'>
              <label>
                Rank should be Executive or higher
              </label><br/>
              <Progress percent={statData.rank_id*1>=4?100:0}
                size="small"
                strokeWidth={20}
                strokeColor="#10B856"
                showInfo={false}
              />
            </div>
          </Col>
        </Row>
        <div className={`qual-row ${
          (statData.lv1_qv>=1000&&
            statData.rank_id*1>=4
          )?'fullfiled':''
        } ${statData.lv1_qv>0?'working':''}`}>
          <div className='qual-lbl'>
            <label>
              {'Level 1: Enroll 1,000BV'}
            </label>
            <label className='lbl2'>
              {`Earn: $250`}
            </label>
          </div>
          <div className='qual-bar'>
            <Progress percent={statData.lv1_qv/1000*100}
              size="small"
              strokeWidth={20}
              strokeColor="#10B856"
              showInfo={false}
            />
            {/* {statData.lv1_qv>=1000 && 
            <div className='qual-act'>
              <Button onClick={()=>onAddCart('RunwaySave25%')}
                disabled={
                  statData.rank_id*1<4||
                  statData.has_lv1_order
                }
              >
                {statData.has_lv1_order
                ? 'Already Paid'
                : 'Pay Now'}
              </Button>
            </div>
            } */}
          </div>
          <div className='qual-lbl2'>
            <label>
              {asVolume(statData.lv1_qv)}
              {`/1,000BV`}
            </label>
          </div>
        </div>
        <div className={`qual-row ${
          (statData.lv1_qv>=1000&&
            statData.lv2_qv>=3000&&
            statData.rank_id*1>=4
          )?'fullfiled':''
        } ${statData.lv1_qv>0?'working':''}`}>
          <div className='qual-lbl'>
            <label>
              {'Level 2: Enroll 3,000BV'}
            </label>
            <label className='lbl2'>
              {`Earn: $500`}
            </label>
          </div>
          <div className='qual-bar'>
            <Progress percent={statData.lv2_qv/3000*100}
              size="small"
              strokeWidth={20}
              strokeColor={
                statData.lv1_qv>=1000
                ? "#10B856"
                : "#7f8fa4"
              }
              showInfo={false}
            />
            {/* {(
              statData.lv1_qv>=1000 && 
              statData.lv2_qv>=3000
            ) && 
            <div className='qual-act'>
              <Button onClick={()=>onAddCart('RunwaySave50%')}
                disabled={
                  statData.rank_id*1<4||
                  statData.has_lv2_order
                }
              >
                {statData.has_lv2_order
                ? 'Already Paid'
                : 'Pay Now'}
              </Button>
            </div>
            } */}
          </div>
          <div className='qual-lbl2'>
            <label>
              {asVolume(statData.lv2_qv)}
              {`/3,000BV`}
            </label>
          </div>
        </div>
        <div className={`qual-row ${
          (statData.lv1_qv>=1000&&
            statData.lv2_qv>=3000&&
            statData.lv3_qv>=6000&&
            statData.rank_id*1>=4
          )?'fullfiled':''
        } ${statData.lv1_qv>0?'working':''}`}>
          <div className='qual-lbl'>
            <label>
              {'Level 3: Enroll 6,000BV'}
            </label>
            <label className='lbl2'>
              {`Earn: $750`}
            </label>
          </div>
          <div className='qual-bar'>
            <Progress percent={statData.lv3_qv/6000*100}
              size="small"
              strokeWidth={20}
              strokeColor={
                (statData.lv1_qv>=1000 &&
                  statData.lv2_qv>=3000
                ) ? "#10B856"
                : "#7f8fa4"
              }
              showInfo={false}
            />
            {/* {(statData.lv1_qv>=1000 && 
              statData.lv2_qv>=3000 && 
              statData.lv3_qv>=6000
            ) && 
            <div className='qual-act'>
              <Button onClick={()=>onAddCart('RunwaySave75%')}
                disabled={
                  statData.rank_id*1<4||
                  statData.has_lv3_order
                }
              >
                {statData.has_lv3_order
                ? 'Already Paid'
                : 'Pay Now'}
              </Button>
            </div>
            } */}
          </div>
          <div className='qual-lbl2'>
            <label>
              {asVolume(statData.lv3_qv)}
              {`/6,000BV`}
            </label>
          </div>
        </div>
        <div className={`qual-row ${
          (statData.lv1_qv>=1000&&
            statData.lv2_qv>=3000&&
            statData.lv3_qv>=6000&&
            statData.lv4_qv>=15000&&
            statData.rank_id*1>=4
          )?'fullfiled':''
        } ${statData.lv1_qv>0?'working':''}`}>
          <div className='qual-lbl'>
            <label>
              {'Level 4: Enroll 15,000BV'}
            </label>
            <label className='lbl2'>
              {`Earn: $1500`}
            </label>
          </div>
          <div className='qual-bar'>
            <Progress percent={statData.lv4_qv/15000*100}
              size="small"
              strokeWidth={20}
              strokeColor={
                (statData.lv1_qv>=1000 &&
                  statData.lv2_qv>=3000 && 
                  statData.lv3_qv>=6000
                ) ? "#10B856"
                : "#7f8fa4"
              }
              showInfo={false}
            />
            {/* {(statData.lv1_qv>=1000 && 
              statData.lv2_qv>=3000 && 
              statData.lv3_qv>=6000 && 
              statData.lv4_qv>=15000
            ) && 
            <div className='qual-act'>
              <Button onClick={()=>onAddCart('RunwaySave100%')}
                disabled={
                  statData.rank_id*1<4||
                  statData.has_lv4_order
                }
              >
                {statData.has_lv4_order
                ? 'Already Paid'
                : 'Pay Now'}
              </Button>
            </div>
            } */}
          </div>
          <div className='qual-lbl2'>
            <label>
              {asVolume(statData.lv4_qv)}
              {`/15,000BV`}
            </label>
          </div>
        </div>
        {isLoading && 
        <div className='spin-wrap'>
          <Spin spinning={true} />
        </div>}
      </div>
      <div className='pe-cont'>
        <PeList />
      </div>
    </Wrapper>
  )
}

const PeList = () => {
  const [tableData, setTableData] = useState([])
  const [paginationParam, setPaginationParam] = useState({
    currentPage: 1,
    perPage: 10, 
    total: 0,
  })
  const [searchParam, setSearchParam] = useState({

  })
  const [isLoading, setIsLoading] = useState(false)
  const [isExporting, setIsExporting] = useState(false)

  const loadTableData = (paginationParam0, searchParam0) => {
    const params = {
      ...searchParam0,
      page: paginationParam0.currentPage,
      per_page: paginationParam0.perPage,
    }
    const q = Object.keys(params).map(k=>k+'='+params[k]).join('&');
    setIsLoading(true)
    callGetApiWithAuth(
      `event/runway_trip/pes?${q}`,
      ({ data }) => {
        setTableData(data.data);
        setPaginationParam({
          ...paginationParam,
          currentPage: data.current_page,
          total: data.total
        });
        setIsLoading(false);
      }, 
      () => {
        setIsLoading(false);
      }
    )
  }
  const handleSearch = () => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: 1,
    }
    loadTableData(paginationParam0, searchParam)
  }
  const onPageChange = (page) => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: page,
    }
    loadTableData(paginationParam0, searchParam)
  }
  const handleExport = () => {
    setIsExporting(true)
    callGetApiWithAuth(
      `event/runway_trip/export_pes`, 
      ({ data }) => {
        setIsExporting(false)
        window.open(data.csv_file, '_blank')
      }
    )
  }

  useEffect(() => {
    handleSearch();
  }, [])

  return (
    <TablePanel
      loading={isLoading}
      data={tableData}
      paginationParam={paginationParam}
      onPageChange={onPageChange}
      toolbar={
        <Button loading={isExporting}
          onClick={handleExport}
        >
          Export
        </Button>
      }
      columns={[
        {
          title: 'Level',
          key: 'level',
          render: (_, record) => (
            <span>
              {record.level}
            </span>
          )
        },
        {
          title: 'Name',
          key: 'name',
          render: (_, record) => (
            <span>
              {record.user.first_name}&nbsp;
              {record.user.last_name}
            </span>
          )
        },
        {
          title: 'ID',
          key: 'uuid',
          render: (_, record) => (
            <span>{record.user.uuid}</span>
          )
        },
        {
          title: 'Date',
          key: 'paid_at',
          render: (_, record) => (
            <span>
              {asDate(record.paid_at)}
            </span>
          )
        },
        {
          title: 'Business Volume (BV)',
          key: 'order_total_pv',
          render: (_, record) => (
            <span className='pv-td'>
              {record.order_total_pv}&nbsp;BV
            </span>
          )
        }
      ]}
    />
  )
}

const Wrapper = styled.div`
  .ban-cont {
    border: 1px solid #919EAB3D;
    padding: 24px;
    border-radius: 20px;
    // overflow: hidden;
    position: relative;
    .ban-item {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .ban-image {
      width: calc(50% - 24px);
      @media (max-width: 768px) {
        width: 100%;
      }
      img {
        width: 100%;
      }
    }
    .ban-desc {
      width: calc(50% - 24px);
      color: ${props=>props.theme.palette.secondaryText};
      font-size: 18px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h3 {
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        font-family: "Public Sans",sans-serif;
        color: #212b36;
        margin-bottom: 24px;
      }
      .ban-content {
        font-weight: 400;
        font-size: 20px;
        line-height: 35px;
        color: #637381;
        font-family: "Public Sans",sans-serif;
        p {
          margin-bottom: 0;
        }
      }
      @media (max-width: 768px) {
        width: 100%;
        margin-top: 24px;
      }
    }
  }
  .desc-cont {
    border: 1px solid #919EAB3D;
    padding: 24px;
    border-radius: 20px;
    margin-top: 60px;
    color: ${props=>props.theme.palette.secondaryText};
    h2 {
      font-size: 32px;
      color: ${props=>props.theme.palette.text};
    }
  }
  .qual-cont {
    margin-top: 60px;
    position: relative;
    .qual-brow {
      margin: 10px 0;
      .check-span {
        font-size: 48px;
        color: #ff4d4f;
        &.checked {
          color: #10B856;
        }
      }
    }
    .qual-row {
      margin: 20px 0;
    }
    .qual-lbl {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 8px 0 4px 0;
      color: ${props=>props.theme.palette.secondaryText};
      font-weight: bold;
    }
    .qual-row.working .qual-lbl {
      color: ${props=>props.theme.palette.text};
    }
    .qual-row.fullfiled .qual-lbl .lbl2 {
      color: #10B856;
    }
    .qual-lbl2 {
      margin: 4px 0 8px 0;
      color: ${props=>props.theme.palette.secondaryText};
    }
    .qual-row.working .qual-lbl2 {
      color: ${props=>props.theme.palette.text};
    }
    .qual-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .qual-act {
      margin-left: 12px;
    }
  }
  .pe-cont {
    margin-top: 60px;
    .tableWrapper .ant-table-container {
      min-width: 1000px;
    }
    .table-new-wrapper tr th {
      color: #fff;
      background-color: #079ACD;
    }
    .pv-td {
      color: #10B856;
    }
  }
  .link-btn {
    padding: 12px 36px;
    background-color: #079ACD;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.2em;
    text-align: center;
    text-transform: uppercase;
    display: inline-block;
    border-radius: 100px;
  }
  .spin-wrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`

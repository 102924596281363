import React, { useState, useEffect } from "react";
import { TablePanel } from "components/common";
import { callGetApiWithAuth } from 'utils/api'
import { asUsdPrice } from 'utils/text'
import { Wrapper } from "./PendingSubPage.styled";

export default function PendingSubPage() {
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const onGetTableData = ({ data }) => { 
    setIsLoading(false)
    setTableData(data)
  }
  const onFailTableData = () => {
    setIsLoading(false)
  }
  const loadTableData = () => {
    const params = {
    }
    const q = Object.keys(params).map(k=>k+'='+params[k]).join('&')
    setIsLoading(true)
    callGetApiWithAuth(
      `my_account/loyalty_wallet/pendings?${q}`, 
      onGetTableData, onFailTableData
    )
  }

  useEffect(() => {
    loadTableData()
  }, [])

  return (
    <Wrapper>
      <div className="container-box">
        <div className='header d-flex align-items-center justify-content-between'>
          <div className="title">Current Loyalty Rewards</div>
        </div>
        <div className="table-container">
          <TablePanel
            className="transfer-table-panel"
            data={tableData}
            loading={isLoading}
            columns={[
              {
                title: 'Month',
                key: 'month',
                render: (_, record) => (
                  <span>{record.month}</span>
                )
              },
              {
                title: 'Amount',
                key: 'amount',
                render: (_, record) => (
                  <span>{asUsdPrice(record.total)}</span>
                )
              },
              {
                title: 'Subscriptions',
                key: 'subscriptions',
                render: (_, record) => (
                  <span>
                    {record.orders.map(el => (
                      <>
                        {el.details.map(el2 => (
                          <>
                            {el2.product.title}
                            &nbsp;x&nbsp;
                            {el2.quantity}
                            &nbsp;,&nbsp;
                          </>
                        ))}
                      </>
                    ))}
                  </span>
                )
              },
            ]}
          />
        </div>
      </div>
    </Wrapper>
  );
}

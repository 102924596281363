import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import {
  TablePanel,
  UserAvatar,
  CountryFlag,
  RankBadge,
  Select,
  Input,
  Button,
  Radio,
} from "components/common";
import { callGetApiWithAuth } from "utils/api";
import { asDate, asVolume } from "utils/text";
import { varIs, varKey } from "common/var";
import { BsChatDots } from "react-icons/bs";
import { LoadingOutlined } from "@ant-design/icons";
import { MdOutlineComment } from "react-icons/md";
import RightOutlinedIcon from "@ant-design/icons/RightOutlined";
import { ReactComponent as SearchOutlinedIcon } from "assets/icons/search-icon.svg";
import {
  FaFacebookMessenger,
  FaTelegramPlane,
  FaWhatsapp,
} from "react-icons/fa";

export default function TableView(props) {
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [paginationParam, setPaginationParam] = useState({
    currentPage: 1,
    perPage: 15,
    total: 0,
  });
  const [searchParam, setSearchParam] = useState({
    search_type: "affiliate",
    user_id: "",
    uuid: "",
    rank_id: "",
    status: "",
    level: "",
    has_subscription: '',
  });
  const [columns, setColumns] = useState([]);
  const [ranks, setRanks] = useState([]);
  const [uplines, setUplines] = useState([]);
  const [maxLevel, setMaxLevel] = useState(0);

  const onGetRanks = (res) => {
    setRanks(res.data);
  };

  const onGetMaxLevel = (res) => {
    setMaxLevel(res.data.max_level);
  };

  const onGetTableData = (res) => {
    setTableData(res.data.tableData.data);
    setPaginationParam({
      ...paginationParam,
      currentPage: res.data.tableData.current_page,
      total: res.data.tableData.total,
    });
    setUplines(res.data.uplines);
    setIsLoading(false);
  };
  const onFailTableData = () => {
    setIsLoading(false);
  };
  const loadTableData = (paginationParam0, searchParam0) => {
    setIsLoading(true);
    const params = {
      ...searchParam0,
      page: paginationParam0.currentPage,
      per_page: paginationParam0.perPage,
    };
    const q = Object.keys(params)
      .map((k) => k + "=" + params[k])
      .join("&");
    callGetApiWithAuth(
      `contact_manager/list?${q}`,
      onGetTableData,
      onFailTableData
    );
  };
  const searchBy = (userId) => {
    const searchParam0 = {
      ...searchParam,
      user_id: userId,
      uuid: "",
      rank_id: "",
    };
    const paginationParam0 = {
      ...paginationParam,
      currentPage: 1,
    };
    loadTableData(paginationParam0, searchParam0);
  };

  const handleSearch = () => {
    if (searchParam.search_type === "affiliate") {
      setColumns([
        {
          title: "Level",
          render: (_, record) => <span>{record.level}</span>,
        },
        {
          title: "User ID",
          render: (_, record) => <span>#{record.uuid}</span>,
        },
        {
          title: "Username",
          key: "username",
          render: (_, record) => (
            <div className="d-flex align-items-center">
              <span>{record.username}</span>
              <span
                className="comment-icon"
                onClick={() => props.setSelectedUser(record)}
              >
                <MdOutlineComment />
              </span>
            </div>
          ),
        },
        {
          title: "Name",
          key: "name",
          render: (_, record) => (
            <div className="d-flex align-items-center name-icons">
              <UserAvatar
                image={record.image}
                title={`${record.first_name} ${record.last_name}`}
                status={varKey("user.status", record.status)}
              />{" "}
              <ChatIcon user={record} />
              {record.social_messenger &&
                record.social_messenger.facebook_enabled * 1 === 1 &&
                record.social_messenger.facebook_link && (
                  <span className="chat-icon">
                    <FaFacebookMessenger
                      onClick={() => {
                        window.open(
                          record.social_messenger.facebook_link,
                          "_blank"
                        );
                      }}
                    />
                  </span>
                )}
              {record.social_messenger &&
                record.social_messenger.telegram_enabled * 1 === 1 &&
                record.social_messenger.telegram_link && (
                  <span className="chat-icon">
                    <FaTelegramPlane
                      onClick={() => {
                        window.open(
                          record.social_messenger.telegram_link,
                          "_blank"
                        );
                      }}
                    />
                  </span>
                )}
              {record.social_messenger &&
                record.social_messenger.whatsapp_enabled * 1 === 1 &&
                record.social_messenger.whatsapp_link && (
                  <span className="chat-icon">
                    <FaWhatsapp
                      onClick={() => {
                        window.open(
                          record.social_messenger.whatsapp_link,
                          "_blank"
                        );
                      }}
                    />
                  </span>
                )}
            </div>
          ),
        },
        {
          title: "Country",
          key: "country",
          render: (_, record) => <CountryFlag country={record.country} />,
        },
        {
          title: "Rank",
          key: "rank_id",
          render: (_, record) => <RankBadge label={record.rank.name} />,
        },
        {
          title: "PV",
          key: "pv",
          render: (_, record) => (
            <span>{asVolume(record.qualification.pv)}</span>
          ),
        },
        {
          title: "Subscription",
          key: "subscription",
          render: (_, record) =>
            record.subscription_count * 1 > 0 ? (
              <span className="subscription-yes">Yes</span>
            ) : (
              <span className="subscription-no">No</span>
            ),
        },
      ]);
    } else if (searchParam.search_type === "preferred") {
      setColumns([
        {
          title: "User ID",
          render: (_, record) => <span>#{record.uuid}</span>,
        },
        {
          title: "Username",
          key: "username",
          render: (_, record) => (
            <div className="d-flex align-items-center">
              <span>{record.username}</span>
              <span
                className="comment-icon"
                onClick={() => props.setSelectedUser(record)}
              >
                <MdOutlineComment />
              </span>
            </div>
          ),
        },
        {
          title: "Name",
          key: "name",
          render: (_, record) => (
            <div className="d-flex align-items-center">
              <UserAvatar
                image={record.image}
                title={`${record.first_name} ${record.last_name}`}
                status={varKey("user.status", record.status)}
              />
            </div>
          ),
        },
        {
          title: "Country",
          key: "country",
          render: (_, record) => <CountryFlag country={record.country} />,
        },
        {
          title: "PV",
          key: "pv",
          render: (_, record) => (
            <span>{asVolume(record.qualification.pv)}</span>
          ),
        },
        {
          title: "Subscription",
          key: "subscription",
          render: (_, record) =>
            record.subscription_count * 1 > 0 ? (
              <span className="subscription-yes">Yes</span>
            ) : (
              <span className="subscription-no">No</span>
            ),
        },
      ]);
    } else if (searchParam.search_type === "prospect") {
      setColumns([
        {
          title: "Name",
          render: (_, record) => (
            <span className={"username"}>
              {`${record.first_name} ${record.last_name}`}
              <span
                className="comment-icon"
                onClick={() => props.setSelectedUser(record)}
              >
                <MdOutlineComment />
              </span>
            </span>
          ),
        },
        {
          title: "Email",
          render: (_, record) => <span>{`${record.email}`}</span>,
        },
        {
          title: "Phone Number",
          render: (_, record) => (
            <span>{`${record.phone ? record.phone : "-"}`}</span>
          ),
        },
        {
          title: "Date Joined",
          render: (_, record) => <span>{asDate(record.created_at)}</span>,
        },
      ]);
    }
    setTableData([]);
    const paginationParam0 = {
      ...paginationParam,
      currentPage: 1,
    };
    loadTableData(paginationParam0, searchParam);
  };
  const onPageChange = (page) => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: page,
    };
    loadTableData(paginationParam0, searchParam);
  };

  const onExportDone = (res) => {
    setIsExporting(false);
    window.open(res.data.csv_file, "_blank");
  };
  const onExportFail = () => {
    setIsExporting(false);
  };
  const handleExport = () => {
    setIsExporting(true);
    const params = {
      ...searchParam,
    };
    const q = Object.keys(params)
      .map((k) => k + "=" + params[k])
      .join("&");
    callGetApiWithAuth(
      `contact_manager/export?${q}`,
      onExportDone,
      onExportFail
    );
  };

  const onFormInput = (field, value) => {
    setSearchParam({
      ...searchParam,
      [field]: value,
    });
  };

  useEffect(() => {
    if (props.shouldLoad) {
      handleSearch();
    }
  }, [props.shouldLoad]);

  useEffect(() => {
    handleSearch();
  }, [searchParam.search_type]);

  useEffect(() => {
    callGetApiWithAuth(`common/ranks`, onGetRanks);
    callGetApiWithAuth(`contact_manager/max_level`, onGetMaxLevel);
  }, []);

  return (
    <Wrapper>
      {uplines.length > 0 && (
        <div className="breadcrumb-bar">
          {uplines.map((el, index) => (
            <>
              <div className={"breadcrumb"}>
                <a href="javascript:void(0)" onClick={() => searchBy(el.id)}>
                  {el.username}
                </a>
              </div>
              {index < uplines.length - 1 && (
                <div className={"breadcrumb-split"}>
                  <RightOutlinedIcon />
                </div>
              )}
            </>
          ))}
        </div>
      )}
      <TablePanel
        title={
          <div className="d-flex align-items-center flex-wrap">
            <Radio.Group
              buttonStyle="solid"
              className={"userTypeSelect"}
              value={searchParam.search_type}
              onChange={(e) => onFormInput("search_type", e.target.value)}
            >
              <Radio.Button value={"affiliate"}>Affiliate</Radio.Button>
              <Radio.Button value={"preferred"}>Customer</Radio.Button>
              <Radio.Button value={"prospect"}>Prospect</Radio.Button>
            </Radio.Group>
          </div>
        }
        data={tableData}
        paginationParam={paginationParam}
        loading={isLoading}
        columns={columns}
        onPageChange={onPageChange}
        toolbar={
          <div className="searchActionContainer">
            <div className="searchField search_bar">
              <Input
                value={searchParam.uuid}
                onChange={(e) => onFormInput("uuid", e.target.value)}
                label={"By User ID, Name, Email"}
                inputPrefix={<SearchOutlinedIcon />}
              />
            </div>
            {searchParam.search_type === "affiliate" && (
              <>
                <div className="searchField">
                  <Select
                    value={searchParam.level}
                    onChange={(v) => onFormInput("level", v)}
                    label="Select Level"
                    options={[
                      { label: "Select Level", value: "" },
                      ...[...Array(maxLevel).keys()].map((i) => ({
                        label: `Level ${i + 1}`,
                        value: i + 1,
                      })),
                    ]}
                  />
                </div>
              </>
            )}
            {(searchParam.search_type==='affiliate' || 
              searchParam.search_type==='preferred'
            ) && 
              <div className="searchField">
                <Select
                  label="Select Rank"
                  value={searchParam.rank_id}
                  onChange={(v) => onFormInput("rank_id", v)}
                  options={[
                    { label: "Select Rank", value: "" },
                    ...ranks.map((el) => ({
                      label: el.name,
                      value: el.id,
                    })),
                  ]}
                />
              </div>
            }
            {(searchParam.search_type==='affiliate' || 
              searchParam.search_type==='preferred'
            ) && 
              <div className="searchField">
                <Select
                  value={searchParam.status}
                  label="Select Status"
                  onChange={(v) => onFormInput("status", v)}
                  options={[
                    { label: "Any", value: "" },
                    { label: "Active", value: "active" },
                    { label: "Inactive", value: "inactive" },
                    { label: "Closed", value: "closed" },
                    { label: "Purged", value: "purged" },
                  ]}
                  style={{ width: "150px" }}
                />
              </div>
            }
            {(searchParam.search_type==='affiliate' || 
              searchParam.search_type==='preferred'
            ) && 
            <div className='searchField'>
              <Select value={searchParam.has_subscription}
                label="Has Subscription"
                onChange={v=>onFormInput('has_subscription', v)}
                options={[
                  { label: 'Any', value: '' },
                  { label: 'Yes', value: 1 },
                  { label: 'No', value: 2 },
                ]}
                style={{ width: "150px" }}
              />
            </div>
            }
            <div className="actionButton">
              <Button onClick={handleSearch}>Search</Button>
              <Button onClick={handleExport} loading={isExporting}>
                Export
              </Button>
            </div>
          </div>
        }
      />
    </Wrapper>
  );
}

const ChatIcon = ({ user }) => {
  const history = useHistory();
  const [isLoadingDownliner, setIsLoadingDownliner] = useState(false);

  const onSuccessGetDownliner = ({ data }) => {
    setIsLoadingDownliner(false);
    if (data && data.length > 0) {
      history.push({
        pathname: "/team_chat",
        state: {
          user: data,
        },
      });
    }
  };

  const onClickChat = () => {
    setIsLoadingDownliner(true);
    callGetApiWithAuth(
      `common/search_enroll_downline_affiliate/${user.username}`,
      onSuccessGetDownliner,
      () => {
        setIsLoadingDownliner(false);
      }
    );
  };

  return (
    <span className="chat-icon">
      {isLoadingDownliner ? (
        <LoadingOutlined />
      ) : (
        <BsChatDots onClick={() => onClickChat(user)} />
      )}
    </span>
  );
};

const Wrapper = styled.div`
  .ant-radio-button-wrapper:hover {
    color: #1ea9ea !important;
  }
  .ant-radio-button-wrapper-checked {
    background-color: #1ea9ea !important;
    border-color: #1ea9ea !important;
    :hover {
      color: white !important;
    }
    ::before {
      background-color: #1ea9ea !important;
    }
    :focus,
    :focus-within {
      box-shadow: none !important;
    }
  }
  .ant-radio-button-wrapper {
    padding: 5px 22px 0px 22px;
    height: 40px !important;
    .ant-radio-button {
    }
    :first-child {
      border-radius: 8px 0px 0px 8px !important;
    }
    :last-child {
      border-radius: 0px 8px 8px 0px !important;
    }
  }

  .content-root {
    .title {
      font-weight: 600;
    }
  }

  .panelHead {
    flex-wrap: wrap;
    .ant-radio-group {
      margin-top: 2px;
      margin-bottom: 2px;
    }
    .searchActionContainer {
      display: flex;
      align-items: center;
      gap: 16px;
      flex-wrap: wrap;
    }
  }
  .searchField {
    display: flex;
    align-items: center;
    .input-prefix {
      svg {
        width: 15px;
        height: 15px;
        margin-bottom: 5px;
      }
    }
    .ant-input {
      height: 40px !important;
    }
    .ant-select-selector {
      height: 40px !important;
      padding-top: 4px !important;
    }
    .ant-select {
      height: 40px !important;
      color: #212b36;
    }
  }
  .name-icons {
    path {
      color: #1ea9ea;
    }
  }

  .actionButton {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .search_bar {
    .ant-form-item-label {
      top: 9px !important;
      left: 44px !important;
    }
    .ant-form-item-label-active {
      transform: translateY(-17px) scale(1) !important;
    }
  }

  @media (max-width: 1494px) {
    .panelHead {
      flex-direction: column !important;
      align-items: start !important;
      gap: 24px !important;
      .ant-select-selector {
        width: auto !important;
      }
      .searchField {
        width: auto !important;
      }
    }
  }
  @media (max-width: 1100px) {
    .panelHead {
      h4 {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
    .toolbar {
      .searchActionContainer {
        gap: 24px !important;
        .searchField,
        .actionButton {
          width: 100% !important;
        }
        .actionButton {
          button {
            width: 50%;
          }
        }
        .sc-ksdxgE,
        .sc-iqseJM {
          width: 100% !important;
        }
      }
    }

    .ant-select-selector {
      width: 100% !important;
    }
  }
`;

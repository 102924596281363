import styled from "styled-components";

export const Wrapper = styled.div`
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2),
    0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  border-radius: 16px;
  margin-bottom: 24px;
  padding: 24px;
  .image-upload-section {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    padding-left: 60px;
    img {
      object-fit: cover;
      border-radius: 50%;
      border: 1px dashed rgba(145, 158, 171, 0.32);
      width: 144px;
      height: 144px;
      padding: 8px;
      margin: 0;
    }
    .user-text-drop {
      max-width: 174px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .allowed-text {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        color: #637381;
        margin-top: 24px;
      }
    }
    .user-upload .ant-upload > button {
      border-radius: 2px;
      background: #ffffff;
      color: #384971;
      border: 1px solid rgba(127, 143, 163, 0.4);
      display: flex;
      align-items: center;
      font-size: 14px;
      padding: 5px 27px;
      height: 38px;
    }
    .user-upload {
      .ant-upload-list {
        display: none;
      }
    }
  }
  .user-text-drop {
    .allowed-text {
    }
  }
  .select-container {
    .label {
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      text-align: start;
      text-transform: uppercase;
      color: #637381;
      margin-bottom: 8px;
    }
    .select-box {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #212b36;
      width: 100%;
      .ant-switch {
        margin-right: 12px;
      }
      .ant-switch-checked {
        background-color: #1ea9ea;
      }
    }
    .select-box > div {
      width: 100%;
    }
  }
  .username-container {
    display: flex;
    align-items: center;
    .username {
      width: 128px;
      font-size: 15px;
      color: #7f8fa3;
    }
    .username-input {
      width: 100%;
      svg {
        width: 13px;
      }
      .date-picker-dob {
        width: 100% !important;
      }
    }
  }
  .settings-row {
    margin-top: 24px;
  }
  .email-verify-btn {
    background: #1ea9ea;
    border-radius: 8px !important;
    height: 40px;
    margin-left: 11px;
  }
  .dob-outlined {
    .ant-picker {
      padding: 0px;
      .ant-picker-input {
        border: 1px solid rgba(145, 158, 171, 0.32);
        border-radius: 8px !important;
        height: 48px !important;
        padding: 0px 14px;
      }
    }
    .ant-picker-clear {
      right: 12px !important;
    }
    position: relative;
    .ant-input {
      height: 48px !important;
      border: 1px solid rgba(145, 158, 171, 0.32);
      border-radius: 8px !important;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #212b36;
      padding: 12px 14px;
    }
    .ant-input-affix-wrapper.ant-input-affix-wrapper-outlined {
      border-radius: 4px;
      position: relative;
    }

    .ant-input-affix-wrapper.ant-input-affix-wrapper-outlined
      .ant-input:not(:first-child) {
      border-radius: 4px;
      padding-top: 26px;
    }

    .ant-input-affix-wrapper.ant-input-affix-wrapper-outlined .ant-input:focus,
    .ant-input-affix-wrapper.ant-input-affix-wrapper-outlined
      .ant-input-focused {
      border-color: #1890ff;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }

    .ant-input-affix-wrapper.ant-input-affix-wrapper-outlined .ant-input:hover {
      border-color: #40a9ff;
    }

    .ant-input-affix-wrapper.ant-input-affix-wrapper-outlined
      .ant-input:not(:first-child)::placeholder {
      opacity: 0.6;
    }

    .ant-input-affix-wrapper.ant-input-affix-wrapper-outlined
      .ant-input:not(:first-child):focus::placeholder,
    .ant-input-affix-wrapper.ant-input-affix-wrapper-outlined
      .ant-input-focused::placeholder {
      opacity: 0.4;
    }

    .ant-form-item-label {
      position: absolute;
      top: 12px;
      left: 14px;
      transition: all 0.2s ease-out;
      pointer-events: none;
      color: #919eab;
    }

    .ant-form-item-label-active {
      transform: translateY(-20px) scale(1);
      font-weight: 400;
      font-size: 12px;
      color: #919eab;
      background: white;
      z-index: 4;
      padding: 0px 1px;
    }
  }
  .dob-input {
    width: 100% !important;
    height: 48px !important;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: rgba(145, 158, 171, 0.32);
  }
  .s-container0 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;
    .save-button {
      button {
        background: #1ea9ea;
        border-radius: 8px !important;
        width: auto;
        height: 48px;
        padding: 0px 15px;
        margin-left: 16px;
      }
    }
    .change-button {
      button {
        background: white;
        border-radius: 8px !important;
        width: auto;
        height: 50px !important;
        padding: 0px 15px;
        border: 1px solid rgba(145, 158, 171, 0.24);
        border-radius: 8px;
        color: black;
      }
    }
  }
  @media (max-width: 1500px) {
    .image-upload-section {
      padding: 0px !important;
      justify-content: center !important;
    }
  }
  @media (max-width: 600px) {
    .s-container0 {
      flex-direction: column-reverse !important;
      .change-button {
        width: 100%;
        button {
          width: 100%;
        }
      }
      .save-button {
        width: 100%;
        button {
          width: 100%;
          margin: 0px;
          margin-bottom: 24px !important;
        }
      }
    }
  }
`;

import React, { useState } from "react";
import styled from "styled-components";
import { Tabs } from "components/common";
import EarningSubPage from "./earning/EarningSubPage";
import PayoutHistorySubPage from "./payoutHistory/PayoutHistorySubPage";
import NegativeBonusSubPage from "./negativeBonus/NegativeBonusSubPage";

export default function EarningPage() {
  const [selectedTab, setSelecetedTab] = useState("earning");
  return (
    <Wrapper>
      <div className="main-container">
        <div className="tabs-root">
          <Tabs activeKey={selectedTab} onChange={(e) => setSelecetedTab(e)}>
            <Tabs.TabPane
              tab={
                <span
                  className={
                    selectedTab === "earning" ? "active-tab" : "inactive-tab"
                  }
                >
                  Earning
                </span>
              }
              key="earning"
            />

            <Tabs.TabPane
              tab={
                <span
                  className={
                    selectedTab === "payout_history"
                      ? "active-tab"
                      : "inactive-tab"
                  }
                >
                  Payout History
                </span>
              }
              key="payout_history"
            />
            <Tabs.TabPane
              tab={
                <span
                  className={
                    selectedTab === "negative_bonus"
                      ? "active-tab"
                      : "inactive-tab"
                  }
                >
                  Commission Adjustments
                </span>
              }
              key="negative_bonus"
            />
          </Tabs>
        </div>
      </div>

      {selectedTab === "earning" && <EarningSubPage />}
      {selectedTab === "payout_history" && <PayoutHistorySubPage />}
      {selectedTab === "negative_bonus" && <NegativeBonusSubPage />}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-bottom: 80px;
  .main-container {
    margin: -24px -40px 24px;
    background: rgba(145, 158, 171, 0.08);
    padding: 24px 40px 0px;
    @media (max-width: 900px) {
      margin: -24px -20px 24px;
      padding: 24px 20px 0px;
    }
    .tabs-root {
      .ant-tabs-nav {
        margin-bottom: 0px;
        margin-top: 0px;
      }
      .ant-tabs-tab-active {
        padding: 12px;
        background: white;
        border-radius: 8px 8px 0px 0px;
      }
      .active-tab {
        font-weight: 600 !important;
        font-size: 15px !important;
        line-height: 24px !important;
        color: #1ea9ea !important;
      }
      .inactive-tab {
        font-weight: 600 !important;
        font-size: 15px !important;
        line-height: 24px !important;
        color: #637381 !important;
      }
      .ant-tabs-ink-bar {
        background: transparent !important;
      }
    }
  }
  @media (max-width: 900px) {
    .main-container {
      margin: -24px -20px 24px;
    }
  }
`;

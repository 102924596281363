import React from "react";
import { Row, Col } from "components/common";
import { ReactComponent as AppleIcon } from "assets/icons/home/apple-logo.svg";
import { ReactComponent as PlaystoreIcon } from "assets/icons/home/playstore-logo.svg";
import { ReactComponent as YoutubeIcon } from "assets/icons/home/youtube.svg";
import { ReactComponent as FacebookIcon } from "assets/icons/home/facebook.svg";
import { ReactComponent as InstaIcon } from "assets/icons/home/insta.svg";
import { ReactComponent as TwitterIcon } from "assets/icons/home/twitter.svg";
import { Wrapper } from "./StoreSection.styled";

export default function StoreSection() {
  const handleGoApp = (link) => {
    window.open(link);
  };

  return (
    <Wrapper>
      <Row gutter={[24, 24]} className="box-row">
        <Col xs={24}>
          <div className="social-boxes">
            <div className="social-boxes-title">Follow Us</div>
            <Row gutter={[16, 16]} className="box-row-1">
              <Col xs={12} xl={12}>
                <div
                  className="store-box"
                  onClick={() =>
                    handleGoApp(
                      "https://instagram.com/officialmydailychoice?utm_medium=copy_link"
                    )
                  }
                >
                  <InstaIcon />
                  <div className="social-para">Instagram</div>
                </div>
              </Col>
              <Col xs={12} xl={12}>
                <div
                  className="store-box"
                  onClick={() =>
                    handleGoApp(
                      "https://www.facebook.com/officialmydailychoice/"
                    )
                  }
                >
                  <FacebookIcon />
                  <div className="social-para">FaceBook</div>
                </div>
              </Col>
              <Col xs={12} xl={12}>
                <div
                  className="store-box"
                  onClick={() =>
                    handleGoApp(
                      "https://www.youtube.com/c/MyDailyChoiceOfficial"
                    )
                  }
                >
                  <YoutubeIcon />
                  <div className="social-para">YouTube</div>
                </div>
              </Col>
              <Col xs={12} xl={12}>
                <div
                  className="store-box"
                  onClick={() => handleGoApp("https://t.me/MyDailyChoiceNews")}
                >
                  <TwitterIcon />
                  <div className="social-para">Telegram</div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs={24}>
          <div className="social-boxes">
            <Row gutter={[16, 16]} className="box-row-2">
              <Col xs={12} xl={12}>
                <div
                  className="store-box app-box"
                  onClick={() =>
                    handleGoApp(
                      "https://play.google.com/store/apps/details?id=com.mlmprotec.mydailychoice"
                    )
                  }
                >
                  <PlaystoreIcon />
                </div>
              </Col>
              <Col xs={12} xl={12}>
                <div
                  className="store-box app-box"
                  onClick={() =>
                    handleGoApp(
                      "https://apps.apple.com/us/app/mydailychoice-inc/id1615307285"
                    )
                  }
                >
                  <AppleIcon />
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Wrapper>
  );
}

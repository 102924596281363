import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router"
import { 
  Row, Col, VideoModal, 
  Select, Button, Input, 
  WarningNotification, SuccessNotification
} from "components/common"
import CheckoutModal from "./CheckoutModal"
import TopBgImg from "assets/images/ticket/top_bg.png"
import TitleImg from "assets/images/ticket/title_image.png"
import StarImg from "assets/images/ticket/star.png"
import HeartImg from "assets/images/ticket/heart.png"
import HandsImg from "assets/images/ticket/hands.png"
import SpeakerImg from "assets/images/ticket/speaker.png"
import PenHandImg from "assets/images/ticket/pen_hand.png"
import BadgeImg from "assets/images/ticket/badge.png"
import VideoBgImg from "assets/images/ticket/video_bg.png"
import VideoThumbImg from "assets/images/ticket/video_thumbnail.png"
import PlayImg from "assets/images/ticket/play.png"
import BookImg from "assets/images/ticket/book.png"
import { ReactComponent as RemoveIcon } from "assets/icons/deleteRed.svg";
import { callPostApiWithAuth } from "utils/api"
import { Wrapper } from "./BuyTicketPage.styled"

export default function BuyTicketPage() {
  const history = useHistory()
  const myUser = useSelector((state) => state.auth.user)
  const [isShowCheckoutModal, setIsShowCheckoutModal] = useState(false)
  const [customerNames, setCustomerNames] = useState([])
  const [isPurchasing, setIsPurchasing] = useState(false)
  const [totalPrice, setTotalPrice] = useState(249)
  const [selectedProduct, setSelectedProduct] = useState("TICKET-6-IN-PERSON")
  const [isPlaying, setIsPlaying] = useState(false)
  const productOptions = [
    {
      value: "TICKET-6-IN-PERSON",
      key: "TICKET-6-IN-PERSON",
      label: "In-Person Ticket = $249"
    },
    {
      value: "TICKET-6-ONLINE",
      key: "TICKET-6-ONLINE",
      label: "Live Stream = $99"
    }
  ]
  const [creditFormData, setCreditFormData] = useState({
    id: '',
    billing_address: '', 
    billing_address_line2: '',
    billing_city: '', 
    billing_state: '',
    billing_zipcode: '', 
    billing_country: '',
    cc_name: '', 
    cc_type: '',
    cc_number: '', 
    cc_cvv: '',
    cc_expiry_month: '', 
    cc_expiry_year: '',
    pay_type: 1
  })

  const onSuccessCheckout = () => {
    setIsPurchasing(false)
    setIsShowCheckoutModal(false)
    SuccessNotification("You have purchased successfully")
    setTimeout(() => {
      history.push("/ticket/my_ticket")
    }, 2000)
  }

  const onFailedCheckout = (err) => {
    setIsPurchasing(false)
  }

  const handleCheckout = () => {
    const data = {
      order_from: 2,
      is_ticket: 1,
      subscription_ids: "",
      shippingDetail: {
        id: ""
      },
      billingDetail: {
        id: creditFormData.id,
        pay_type: "1",
        coin_type: "btc"
      },
      orderDetails: [
        {
          product_id: "",
          sku: selectedProduct,
          quantity: customerNames.length,
          pay_cycle: 0,
          contained_products: [],
          customer_names: customerNames
        }
      ]
    }

    setIsPurchasing(true)
    callPostApiWithAuth("shop/checkout", data, onSuccessCheckout, onFailedCheckout)
  }

  const handleOpenCheckoutModal = () => {
    if (!customerNames || customerNames.length === 0) {
      WarningNotification("Please add ticket")
      return
    } else if (customerNames && customerNames.length > 0 && customerNames.filter((el) => !el.trim()).length > 0) {
      WarningNotification("Please input all customer names")
      return
    }

    setIsShowCheckoutModal(true)
  }

  const handleCloseCheckoutModal = () => {
    setIsShowCheckoutModal(false)
  }
  
  const handleChangeCustomerName = (index, value) => {
    const arrayData = [ ...customerNames ];
    arrayData[index] = value;
    setCustomerNames(arrayData);
  };

  const onAddCustomerNameRow = () => {
    if (selectedProduct === "TICKET-6-IN-PERSON" && customerNames && customerNames.length > 1) {
      WarningNotification("You can add at most 2 tickets.")
      return
    } else if (selectedProduct === "TICKET-6-ONLINE" && customerNames && customerNames.length > 0) {
      WarningNotification("You can add at most 1 ticket.")
      return
    }

    const arrayData = [
      ...customerNames,
      customerNames.length === 0 ? `#${myUser.uuid} / ${myUser.first_name} ${myUser.last_name}` : ""
    ];
    setCustomerNames(arrayData);
  };

  const onRemoveCustomerNameRow = (index) => {
    const arrayData = customerNames.filter((_, idx) => idx*1 !== index*1)
    setCustomerNames(arrayData);
  };

  useEffect(() => {
    if (customerNames && customerNames.length > 0) {
      if (selectedProduct === "TICKET-6-IN-PERSON") {
        setTotalPrice(249 * customerNames.length)
      } else if (selectedProduct === "TICKET-6-ONLINE") {
        setTotalPrice(99 * customerNames.length)
      }
    } else {
      if (selectedProduct === "TICKET-6-IN-PERSON") {
        setTotalPrice(249)
      } else if (selectedProduct === "TICKET-6-ONLINE") {
        setTotalPrice(99)
      }
    }
  }, [customerNames])

  useEffect(() => {
    setCustomerNames([])
  }, [selectedProduct])

  useEffect(() => {
    if (myUser) {
      setCreditFormData({
        ...creditFormData, 
        billing_state: myUser.state,
        billing_country: myUser.country,
      });
    }
  }, [myUser]);

  return (
    <Wrapper>
      <div className="main-container">
        <Row gutter={[20, 20]}>
          <Col xs={24}>
            <div className="main-info-section">
              <img src={TopBgImg} className="top-bg-image" />
              <div className="info-section">
                <img src={TitleImg} className="title-image" />
                <h3>MyDailyChoice 6th Annual Convention</h3>
                <h5>November 3rd - November 5th, 2022</h5>
                <h5>Orlando World Center Marriott</h5>
                <h1 className="ticket-price">${totalPrice}</h1>
                <Select
                  value={selectedProduct}
                  options={productOptions}
                  onChange={(value) => setSelectedProduct(value)}
                  className="ticket-type"
                  size="large"
                />
                <div className="ticket-actions">
                  <Button
                    className="action-btn add-ticket-btn"
                    onClick={onAddCustomerNameRow}
                  >
                    ADD TICKET
                  </Button>
                  <Button
                    className="action-btn checkout-btn"
                    onClick={handleOpenCheckoutModal}
                  >
                    CHECKOUT
                  </Button>
                </div>
                {(customerNames && customerNames.length > 0) &&
                  <div className="customer-names-section">
                    <Row gutter={[20, 20]}>
                      {customerNames.map((el, index) => (
                        <Col xs={24} sm={12} key={index}>
                          <div className="customer-name-row">
                            <Input
                              className="customer-name"
                              value={el}
                              onChange={(e) => handleChangeCustomerName(index, e.target.value)}
                              disabled={index === 0}
                              placeholder={index !== 0 ? "Enter guest name" : ""}
                            />
                            {(customerNames.length === 1 || (customerNames.length > 1 && index !== 0)) &&
                              <RemoveIcon
                                className="remove-customer-icon"
                                onClick={() => onRemoveCustomerNameRow(index)}
                              />
                            }
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                }
                <div className="ticket-features">
                  <p className="feature-title">Here are just a few reasons <strong>WHY</strong> you should attend this event:</p>
                  <Row gutter={[10, 10]}>
                    <Col xs={24} sm={12} md={8} lg={4}>
                      <div className="feature">
                        <img src={StarImg} className="feature-icon" />
                        <span className="feature-desc">Meet the CEO and <br />Founders</span>
                      </div>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={4}>
                      <div className="feature">
                        <img src={HeartImg} className="feature-icon" />
                        <span className="feature-desc">Meet the <br />Corporate Staff</span>
                      </div>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={4}>
                      <div className="feature">
                        <img src={HandsImg} className="feature-icon" />
                        <span className="feature-desc">Network with <br />other TOP earners</span>
                      </div>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={4}>
                      <div className="feature">
                        <img src={SpeakerImg} className="feature-icon" />
                        <span className="feature-desc">Discover new products, <br />tech rollouts, and exciting <br />enhancements</span>
                      </div>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={4}>
                      <div className="feature">
                        <img src={PenHandImg} className="feature-icon" />
                        <span className="feature-desc">Get trained by the BEST in <br />the industry</span>
                      </div>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={4}>
                      <div className="feature">
                        <img src={BadgeImg} className="feature-icon" />
                        <span className="feature-desc">Get recognized for your <br />achievements</span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col xs={24}>
            <div className="video-section">
              <img src={VideoBgImg} className="video-bg-image" />
              <div className="video-thumbnail-section">
                <img src={VideoThumbImg} className="video-thumbnail" />
                <img src={PlayImg} onClick={()=>setIsPlaying(true)} className="play-icon" />
              </div>
            </div>
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col xs={24}>
            <div className="book-section">
              <div className="book-info-section order-md-2">
                <h5 className="book-title">Book your room here with our discounted pricing:</h5>
                <Button
                  className="book-btn"
                  href="https://book.passkey.com/go/MDC22"
                  target="_blank"
                >
                  BOOK
                </Button>
                <p>Within 30 minutes of our venue, you are close to outlet stores, SeaWorld, Universal Studios, Disney World, Golf Courses, and Luxury Pools.</p>
              </div>
              <div className="book-image-section order-md-1">
                <img src={BookImg} className="book-image" />
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {isShowCheckoutModal &&
        <CheckoutModal
          myUser={myUser}
          handleCloseCheckoutModal={handleCloseCheckoutModal}
          creditFormData={creditFormData}
          setCreditFormData={setCreditFormData}
          handleCheckout={handleCheckout}
          isPurchasing={isPurchasing}
        />
      }
      {isPlaying && 
        <VideoModal onCancel={()=>setIsPlaying(false)} 
          src="https://www.youtube.com/embed/1vaLoZk3Pjk"
          width={1200}
        />
      }
    </Wrapper>
  );
};

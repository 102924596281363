import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import styled from 'styled-components';
import clsx from "clsx";
import { countryStates, statesByCountry } from "common/country";
import { 
  Row, Col, 
  Select, Input,
  Button, Popconfirm,
  Carousel, Progress, 
  SuccessNotification, message,
} from "components/common";
import { 
  callGetApi,
  callGetApiWithAuth, callPostApiWithAuth 
} from "utils/api";
import BillingForm from "views/checkout/forms/BillingForm";
import CheckoutSideCart from "views/checkout/forms/CheckoutSideCart";
import { Wrapper as CheckoutWrap } from "views/checkout/CheckoutPage.styled";
import { 
  CheckCircleTwoTone, LoadingOutlined 
} from "@ant-design/icons";
import Slider1Img from "assets/images/incentive_trip/slider2.png";

export default function IncentiveTripPage() {
  const history = useHistory()
  const quals = [
    {
      rank_id: 5,
      qual_cond: "1k",
      detail: "$1,000 to be paid vs $750",
      description:
        "*Achieve or maintain 1k in any month during the qualification period",
    },
    {
      rank_id: 6,
      qual_cond: "5k",
      detail: "Pay $750/person",
      description:
        "*Achieve or maintain 5K in any month during the qualification period",
    },
    {
      rank_id: 7,
      qual_cond: "10k",
      detail: "Pay $500/person",
      description:
        "*Achieve or maintain 10K for 2 months during the qualification period",
    },
    {
      rank_id: 8,
      qual_cond: "25k",
      detail: "Resort Paid for 2",
      description:
        "*Achieve or maintain 25K for 2 months during the qualification period",
    },
    {
      rank_id: 9,
      qual_cond: "50k",
      detail: "Resort Paid for 2, $1000 Airline Credit",
      description:
        "*Achieve or maintain 50K for 2 months during the qualification period",
    },
    {
      rank_id: 10,
      qual_cond: "100k",
      detail: "Resort Paid for 2, $2000 Airline Credit",
      description:
        "*Achieve or maintain 100K for 3 months during the qualification period",
    },
    {
      rank_id: 11,
      qual_cond: "250k",
      detail: "Resort Paid for 2, $2000 Airline Credit,and $2000 spending money",
      description:
        "*Achieve or maintain 250K for 3 months during the qualification period",
    },
    {
      rank_id: 12,
      qual_cond: "500k",
      detail: "Resort Paid for 2, $2,000 airline credit, and $2,000 spending money",
      description:
        "*Achieve or maintain 500K for 3 months during the qualification period",
    },
  ];
  const [rankId, setRankId] = useState('')
  const [product, setProduct] = useState(undefined)
  const [booking, setBooking] = useState(undefined)
  const [viewMode, setViewMode] = useState('main')
  const [guestCnt, setGuestCnt] = useState(1)

  const getCurState = () => {
    callGetApiWithAuth(`event/incentive_trip/cur_state`,
      ({ data }) => {
        setRankId(data.rank_id);
        setProduct(data.product)
        setBooking(data.booking)
      }
    )
  }

  useEffect(() => {
    getCurState()
  }, [])

  useEffect(() => {
    if (rankId && rankId>7) {
      setGuestCnt(2)
    }
  }, [rankId])

  useEffect(() => {
    if (booking) {
      setGuestCnt(booking.items.length)
    }
  }, [booking])

  return (
    <Wrapper>
      {viewMode === 'main' && 
      <div className="main-container">
        <div className="d-flex flex-column" style={{ rowGap: "60px" }}>
          <div className='desc-cont'>
            <Row gutter={[24, 24]}>
              <Col xl={12}>
                <Carousel autoplay>
                  <div>
                    <img src={Slider1Img} alt={"incentive trip ad1"} />
                  </div>
                </Carousel>
              </Col>
              <Col xl={12}>
                <div className="d-flex justify-content-center align-items-center h-100">
                  <div className="text-info">
                    <div style={{ marginBottom: "10px" }}>
                      <div className="duration-tag">{"June 20 - 24, 2024"}</div>
                    </div>
                    <div className="title">
                      Come celebrate with Top MDC Leaders at an All-inclusive vacation at Grand Fiesta Resort in Cabo!
                    </div>
                    <div className="bullet-para">
                      <ul>
                        <li>
                          <span>4 Night, 5 Day Double Occupancy Accommodations</span>
                        </li>
                        <li>
                          <span>
                            All Meals & Drinks are Included
                          </span>
                        </li>
                        <li>
                          <span>Enjoy a Welcome Reception & Farewell Party</span>
                        </li>
                        {/* <li>
                          <span>Airport Transfer Transportation Included</span>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          {rankId && 
          <div className='myqual-cont'>
            <Row gutter={[24, 24]}>
              {quals.filter(qual => 
                qual.rank_id*1===rankId*1
              ).map(qual => 
                <Col lg={12}>
                  <div className="qualification">
                    <Progress
                      type="circle"
                      percent={100}
                      strokeWidth={7}
                      size="large"
                      format={(_percent) => {
                        return (
                          <div>
                            <div className="rank-name">{qual.qual_cond}</div>
                            <div className="rank-description">Current Rank</div>
                          </div>
                        );
                      }}
                      strokeColor={{
                        "0%": "#10B856",
                        "100%": "#10B856",
                      }}
                    />
                    <div>
                      <div className="status">
                        <CheckCircleTwoTone /> &nbsp; Qualified to go
                      </div>
                      <div className="title">{qual.detail}</div>
                      <div className="description">
                        {qual.description}
                      </div>
                    </div>
                  </div>
                </Col>
              )}
              {quals.filter(qual => 
                qual.rank_id*1>rankId
              ).slice(0, 1)
              .map(qual => 
                <Col lg={12}>
                  <div className="qualification">
                    <Progress
                      type="circle"
                      percent={0}
                      strokeWidth={7}
                      format={(_percent) => {
                        return (
                          <div>
                            <div className="rank-name" style={{ color: "#595959" }}>
                              {qual.qual_cond}
                            </div>
                            <div className="rank-description">Next Goal</div>
                          </div>
                        );
                      }}
                      strokeColor={{
                        "0%": "#10B856",
                        "100%": "#10B856",
                      }}
                    />
                    <div>
                      <div className="status">Qualification to next goal</div>
                      <div className="title">{qual.detail}</div>
                      <div className="description">
                        {qual.description}
                      </div>
                    </div>
                  </div>
                </Col>
              )}
            </Row>
          </div>}
          <Row gutter={[24, 24]}>
            <Col lg={12} xs={24}>
              <div className='report-cont'>
                <div className="title">See Rank Requirements</div>
                <Button className="incentive_trip_but"
                  onClick={() => history.push('/report/rank_report')}
                >
                  RANK REPORT
                </Button>
              </div>
            </Col>
            {/* <Col lg={12} xs={24}>
              <div className='report-cont'>
                <div className="title">Airport Transfer Transportation</div>
                <Button className="incentive_trip_but"
                  onClick={() => {
                    window.open('https://lizzy5.wixsite.com/mydailychoicetrans', '_blank')
                  }}
                >
                  BOOK NOW
                </Button>
              </div>
            </Col> */}
          </Row>
          <div className='qual-cont'>
            <div className="title" style={{ marginBottom: "60px" }}>
              Rank Qualifications
            </div>

            <Row gutter={[24, 24]}>
              {quals.map((row, ind) => (
                <Col lg={12} xl={24} key={`rank-qualification-card-${ind}`}>
                  <QualCard data={row} 
                    rankId={rankId} 
                    setViewMode={setViewMode}
                    booking={booking}
                    guestCnt={guestCnt}
                  />
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </div>}
      {viewMode==='book' && 
        <BookSubPage rankId={rankId} 
          setViewMode={setViewMode} 
          getCurState={getCurState}
          guestCnt={guestCnt}
        />
      }
      {viewMode==='pay' && 
        <PaySubPage product={product}
          setViewMode={setViewMode}
          getCurState={getCurState}
          guestCnt={guestCnt}
          setGuestCnt={setGuestCnt}
        />
      }

      <style>
        @import
        url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
        @import
        url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
        @import
        url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap');
      </style>
    </Wrapper>
  );
};

const QualCard = ({ data, rankId, booking, setViewMode }) => {
  // ! HLT disabled now
  // const [isUpdating, setIsUpdating] = useState(false)
  
  // const onSubmitDone = (res) => {
  //   SuccessNotification(res.message)
  //   setIsUpdating(false)
  //   const winUrl = URL.createObjectURL(
  //     new Blob([res.data.html_form], { type: "text/html" })
  //   );
  //   window.open(winUrl, "_blank");
  // }
  // const onSubmitFail = () => {
  //   setIsUpdating(false)
  // }
  // const giveJtgift = () => {
  //   let body = {
  //     rank_id: rankId
  //   }
  //   setIsUpdating(true)
  //   callPostApiWithAuth(
  //     `event/incentive_trip/give_jtgift`,
  //     body, onSubmitDone, onSubmitFail
  //   )
  // }

  return (
    <div
      className={clsx({
        "rank-qualification-card": true,
        "grayed-background": data.rank_id*1 !== rankId*1,
      })}
    >
      <div className="rank-qualification">
        <div
          className={clsx({
            "rank-badge": true,
            "qualified-rank-badge": data.rank_id*1 < rankId*1,
            "current-rank-badge": data.rank_id*1 === rankId*1,
            "future-rank-badge": data.rank_id*1 > rankId*1,
          })}
        >
          {data.qual_cond}
        </div>

        <div className="rank-info">
          <div className="status" style={{ marginBottom: "12px" }}>
            {data.rank_id*1 <= rankId*1 ? (
              <>
                <CheckCircleTwoTone /> &nbsp; Qualified to go
              </>
            ) : (
              "Qualify to go"
            )}
          </div>
          <div
            className={clsx({
              title: true,
              "grayed-title": data.rank_id*1 !== rankId*1,
            })}
          >
            {data.detail}
          </div>
        </div>
      </div>
      <div className="rank-description">{data.description}</div>
      <Button
        className={clsx({
          "pay-but": true,
          "grayed-but": data.rank_id*1 !== rankId*1,
        })}
        onClick={() => {
          if (booking && 
            booking.rank_id*1===rankId*1 && 
            booking.status*1===1
          ) {
            // Already booked
            return;
          }
          if (booking && 
            !booking.rank_id && 
            booking.status*1===2
          ) {
            setViewMode('book')
          } else {
            if (rankId*1>7) {
              setViewMode('book')
            } else {
              setViewMode('pay')
            }
          }
        }}
        disabled={data.rank_id*1!==rankId*1}
      >
        <div className='d-flex justify-content-center align-items-center'>
          {(data.rank_id*1===rankId*1 && 
            booking && 
            booking.rank_id*1===rankId*1 && 
            booking.status*1===1
          ) ?
            'REGISTERED'
          : (data.rank_id*1===rankId*1 && 
            booking && 
            !booking.rank_id && 
            booking.status*1===2
          ) ?
            'BOOK NOW'
          : data.rank_id*1 > 7 ? 
            "BOOK NOW" 
          : "PAY NOW"}
          {}
        </div>
      </Button>
    </div>
  );
};

const BookSubPage = (props) => {
  const [formData, setFormData] = useState([])
  const [isUpdating, setIsUpdating] = useState(false)
  const [isFilled, setIsFilled] = useState(false)

  const onFormInput = (ind, key, value) => {
    let formData0 = [...formData]
    formData0[ind][key] = value;
    setFormData(formData0)
  }

  const onSubmitDone = (res) => {
    SuccessNotification(res.message)
    setIsUpdating(false)
    props.setViewMode('main')
    props.getCurState()
  }
  const onSubmitFail = () => {
    setIsUpdating(false)
  }
  const handleSubmit = () => {
    if (!isFilled) return;
    const body = {
      rank_id: props.rankId,
      items: formData,
    }
    setIsUpdating(true)
    callPostApiWithAuth('event/incentive_trip/book', body,
      onSubmitDone, onSubmitFail
    )
  }

  useEffect(() => {
    let formData0 = [];
    for (var i=0;i<props.guestCnt;i++) {
      if (typeof formData[i]==='undefined') {
        formData0.push({
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
          country: '',
          state: '',
          city: '',
          zipcode: '',
          address: '',
          address_line2: ''
        })
      } else {
        formData0.push({
          ...formData[i]
        })
      }
    }
    setFormData(formData0)
  }, [props.guestCnt])

  useEffect(() => {
    let isFilled0 = true;
    formData.forEach(custData => {
      [
        'first_name', 'last_name',
        'email', 'phone',
        'country', 'state',
        'city', 'zipcode',
        'address', 
      ].forEach(k => {
        if (!custData[k]) {
          isFilled0 = false;
        }
      })
    })
    setIsFilled(isFilled0)
  }, [formData])

  return (
    <div className='book-cont'>
      <h2>
        Secure Your Spot! Fill out the form below to complete your booking.
      </h2>
      <Row gutter={[24, 24]} className='book-form'>
        {formData.map((_, ind) => (
          <Col xs={24} lg={12}>
            <h4>Guest #{ind+1}</h4>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12}>
                <Input label={'First Name'}
                  value={formData[ind].first_name}
                  onChange={e=>onFormInput(ind, 'first_name', e.target.value)}
                />
              </Col>
              <Col xs={24} sm={12}>
                <Input label={'Last Name'}
                  value={formData[ind].last_name}
                  onChange={e=>onFormInput(ind, 'last_name', e.target.value)}
                />
              </Col>
              <Col xs={24} sm={12}>
                <Input label={'Email Address'}
                  value={formData[ind].email}
                  onChange={e=>onFormInput(ind, 'email', e.target.value)}
                />
              </Col>
              <Col xs={24} sm={12}>
                <Input label={'Cell Phone Number'}
                  value={formData[ind].phone}
                  onChange={e=>onFormInput(ind, 'phone', e.target.value)}
                />
              </Col>
              <Col xs={24} sm={12}>
                <Select label={'Country'}
                  showSearch
                  value={formData[ind].country}
                  options={countryStates.map(el => ({
                    label: el.name,
                    value: el.code2,
                  }))}
                  onChange={v=>onFormInput(ind, 'country', v)}
                />
              </Col>
              <Col xs={24} sm={12}>
                <Select label={'State'}
                  showSearch
                  value={formData[ind].state}
                  options={statesByCountry(formData[ind].country).map(el => ({
                    label: el.name,
                    value: el.name
                  }))}
                  onChange={v=>onFormInput(ind, 'state', v)}
                />
              </Col>
              <Col xs={24} sm={12}>
                <Input label={'City'}
                  value={formData[ind].city}
                  onChange={e=>onFormInput(ind, 'city', e.target.value)}
                />
              </Col>
              <Col xs={24} sm={12}>
                <Input label={'Postal Code'}
                  value={formData[ind].zipcode}
                  onChange={e=>onFormInput(ind, 'zipcode', e.target.value)}
                />
              </Col>
              <Col xs={24} sm={12}>
                <Input label={'Address'}
                  value={formData[ind].address}
                  onChange={e=>onFormInput(ind, 'address', e.target.value)}
                />
              </Col>
              <Col xs={24} sm={12}>
                <Input label={'Address 2'}
                  value={formData[ind].address_line2}
                  onChange={e=>onFormInput(ind, 'address_line2', e.target.value)}
                />
              </Col>
            </Row>
          </Col>
        ))}
        <Col span={24}>
          <div className='d-flex align-items-center'>
            <Popconfirm onConfirm={handleSubmit}
              disabled={!isFilled}
            >
              <Button success
                loading={isUpdating}
                disabled={!isFilled}
              >
                Submit
              </Button>
            </Popconfirm>
            <div style={{ width: 12 }} />
            <p className='book-hint'>
              First name, last name must match ID/Passport
            </p>
          </div>
        </Col>
      </Row>
    </div>
  )
}

const PaySubPage = (props) => {
  const dispatch = useDispatch();
  const orderFrom = useSelector((state) => state.checkout.orderFrom);
  const orderDetails = useSelector((state) => state.checkout.orderDetails);
  const myUser = useSelector((state) => state.auth.user);
  const [payType, setPayType] = useState("1|");
  const [cCLimitPrice, setCcLimitPrice] = useState(undefined);
  const [distCenter, setDistCenter] = useState(undefined);
  const [bankFormData, setBankFormData] = useState({
    id: "",
    billing_address: "",
    billing_address_line2: "",
    billing_city: "",
    billing_state: "",
    billing_zipcode: "",
    billing_country: "",
    bk_name: "",
    bk_bank: "",
    bk_account: "",
    bk_routing: "",
    pay_type: 3,
  });
  const [creditFormData, setCreditFormData] = useState({
    id: "",
    billing_address: "",
    billing_address_line2: "",
    billing_city: "",
    billing_state: "",
    billing_zipcode: "",
    billing_country: "",
    cc_name: "",
    cc_type: "",
    cc_number: "",
    cc_cvv: "",
    cc_expiry_month: "",
    cc_expiry_year: "",
    pay_type: 1,
  });
  const [shippingData, setShippingData] = useState({
    id: "",
  });
  const [tax, setTax] = useState(undefined);
  const [shippingPrice, setShippingPrice] = useState(undefined);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isSubmiting, setIsSubmiting] = useState(false);

  const loadCreditLimitPrice = () => {
    callGetApi(
      "common/cc_max_amount",
      ({ data }) => {
        setCcLimitPrice(data);
      },
      (err) => {
        setCcLimitPrice(0);
      },
      { isCorp: true }
    );
  };

  const onSuccessCheckout = (data) => {
    setIsSubmiting(false);
    dispatch({
      type: "checkout.CLEAR_CART",
    });
    dispatch({ type: "auth.CHECK_HOLD" });
    dispatch({ type: "auth.RELOAD" });
    props.setViewMode('book')
    props.getCurState()
  };

  const onFailCheckout = (errMessage) => {
    setIsSubmiting(false);
    message.error(errMessage);
    dispatch({ type: "auth.CHECK_HOLD" });
  };

  const handleCheckout = () => {
    const payType2 = payType.split("|")[0];
    let data = {
      order_from: orderFrom,
      dist_center_id: distCenter?.id,
      subscription_ids: [], 
      shippingDetail: {
        id: shippingData.id,
      },
      billingDetail: {
        id:
          payType === "1|" ||
          payType === "9|" ||
          payType === "10|" ||
          payType === "11|"
            ? creditFormData.id
            : payType === "3|"
            ? bankFormData.id
            : "",
        pay_type: payType2,
        coin_type: "btc",
      },
    };
    if (payType2 * 1 == 4 || payType2 * 1 == 5) {
      data.billingDetail.billing_country = myUser.country;
    }
    if (payType2 * 1 == 4) {
      data.billingDetail.manual_method = payType.split("|")[1];
    }
    if (payType2 * 1 == 5) {
      data.billingDetail.coin_method = payType.split("|")[1];
      if (data.billingDetail.coin_method == "Crypto(USDTERC20)") {
        data.billingDetail.coin_type = "USDTERC20";
      } else if (data.billingDetail.coin_method == "Crypto(USDTTRC20)") {
        data.billingDetail.coin_type = "USDTTRC20";
      }
    }
    let orderDetails_ = [];
    for (let item of orderDetails) {
      orderDetails_.push({
        product_id: item.product.id,
        quantity: item.quantity,
        pay_cycle: item.pay_cycle,
        contained_products:
          item.contained_products.length > 0
            ? item.contained_products.map((el) => ({
                product_id: el.product_id,
                quantity: el.quantity,
              }))
            : [],
      });
    }
    data.orderDetails = orderDetails_;
    setIsSubmiting(true);
    callPostApiWithAuth(
      `shop/checkout`,
      data,
      onSuccessCheckout,
      onFailCheckout
    );
  };

  useEffect(() => {
    if (myUser) {
      setCreditFormData({
        ...creditFormData,
        billing_state: myUser.state,
        billing_country: myUser.country,
      });
      setBankFormData({
        ...bankFormData,
        billing_state: myUser.state,
        billing_country: myUser.country,
      });
    }
  }, [myUser]);

  useEffect(() => {
    if (props.product) {
      dispatch({
        type: "checkout.SET_ORDER_DETAILS",
        payload: [
          {
            product: props.product,
            quantity: props.guestCnt,
            pay_cycle: 0,
            contained_products: [],
          }
        ],
      });
    }
  }, [props.product, props.guestCnt]);

  useEffect(() => {
    loadCreditLimitPrice();
  }, [])

  return (
    <CheckoutWrap>
      <Row gutter={[24, 24]} className="main-container">
        <Col
          xs={24}
          lg={12}
          xl={12}
          xxl={16}
          className="checkout-cart-for"
        >
          <div className="checkout-form">
            <h4 className="checkout-title">
              How Many Guests Are Checking In?
            </h4>
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 selectdiv no-padding">
                <Select 
                  value={props.guestCnt}
                  onChange={v=>{
                    props.setGuestCnt(v)
                  }}
                  options={[
                    { label: '1', value: 1 },
                    { label: '2', value: 2 }
                  ]}
                />
              </div>
            </div>
          </div>
          <div style={{ height: 24 }} />
          <BillingForm
            myUser={myUser}
            payType={payType}
            setPayType={setPayType}
            bankFormData={bankFormData}
            setBankFormData={setBankFormData}
            creditFormData={creditFormData}
            setCreditFormData={setCreditFormData}
            handleCheckout={handleCheckout}
            setStep={()=>{}}
            tax={tax}
            isSubmiting={isSubmiting}
            hasPhysicalOrder={false}
            totalPrice={totalPrice}
            cCLimitPrice={cCLimitPrice}
            shippingPrice={shippingPrice}
            getDigitalTaxPrice={()=>{}}
          />
        </Col>
        <Col
          xs={24}
          lg={12}
          xl={12}
          xxl={8}
          className="checkout-cart-for"
        >
          <CheckoutSideCart
            shippingPrice={shippingPrice}
            setShippingPrice={setShippingPrice}
            tax={tax}
            setTax={setTax}
            setTotalPrice={setTotalPrice}
            step={'billing'}
            setStep={()=>{}}
            hasPhysicalOrder={false}
          />
        </Col>
      </Row>
    </CheckoutWrap>
  )
}

const Wrapper = styled.div`
  .desc-cont {
    border: 1px solid #919eab3d;
    border-radius: 20px;
    padding: 30px;
    .text-info {
      max-width: 593px;
      font-family: "Public Sans", sans-serif;
      .duration-tag {
        display: flex;
        justify-content: center;
        width: 156px;
        height: 32px;
        border-radius: 8px;
        background: #149c4d26;
        font-size: 16px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: -0.5px;
        text-align: left;
        color: #149c4d;
      }
      .title {
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        color: #212b36;
        margin-bottom: 24px;
      }
      .bullet-para {
        li {
          font-weight: 400;
          font-size: 20px;
          line-height: 35px;
          color: #079acd;
          span {
            color: #637381;
          }
        }
      }
    }
    @media screen and (max-width: 768px) {
      padding: 16px;
      .text-info {
        .duration-tag {
          font-size: 14px;
        }
        .title {
          font-size: 22px;
          line-height: 30px;
        }
        .bullet-para {
          li {
            font-size: 16px;
            line-height: 28px;
          }
        }
      }
    }
  }
  .myqual-cont {
    border: 1px solid #919eab3d;
    border-radius: 20px;
    padding: 30px;
    .qualification {
      display: flex;
      align-items: center;
      gap: 35px;
      font-family: "Plus Jakarta Sans", sans-serif;
      .ant-progress-circle .ant-progress-inner {
        width: 150px !important;
        height: 150px !important;
      }
      .status {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 24px;
        line-height: 28.8px;
        color: #595959;
        margin-bottom: 22px;
      }
      .title {
        font-weight: 700;
        font-size: 32px;
        line-height: 35.2px;
        color: #212b36;
        margin-bottom: 14px;
      }
      .description {
        font-family: "DM Sans", sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #637381;
      }
      @media screen and (max-width: 1200px) {
        flex-direction: column;
        align-items: start;
      }
    }
    .rank-name {
      font-family: "Public Sans", sans-serif;
      font-size: 40px;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: -2px;
      text-align: center;
      color: #10b856;
      margin-bottom: 10px;
    }
    .rank-description {
      font-family: "Plus Jakarta Sans", sans-serif;
      font-size: 14px;
      font-weight: 700;
      line-height: 17px;
      text-align: center;
      color: #595959;
    }
    @media screen and (max-width: 768px) {
      padding: 16px;
      .qualification {
        gap: 16px;
        align-items: center;
        justify-content: center;
        text-align: center;
        .ant-progress-circle .ant-progress-inner {
          width: 120px !important;
          height: 120px !important;
        }
        .status {
          justify-content: center;
          font-size: 18px;
          margin-bottom: 8px;
        }
        .title {
          font-size: 24px;
          margin-bottom: 8px;
        }
        .description {
          font-size: 13px;
        }
      }
      .rank-name {
        font-size: 30px;
        margin-bottom: 4px;
      }
      .rank-description {
        font-size: 12px;
      }
    }
  }
  .report-cont {
    border: 1px solid #919eab3d;
    border-radius: 20px;
    padding: 30px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-family: Plus Jakarta Sans;
      font-size: 32px;
      font-weight: 700;
      line-height: 35.2px;
      letter-spacing: 0em;
      text-align: left;
      color: #212b36;
    }

    .incentive_trip_but {
      width: 204px;
      height: 51px;
      border-radius: 100px !important;
      background: linear-gradient(93.33deg, #079acd 0%, #3bcaf2 100%);

      display: flex;
      justify-content: center;

      font-family: DM Sans;
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;
      letter-spacing: 0.1em;
      text-align: left;
    }

    @media screen and (max-width: 768px) {
      padding: 16px;

      flex-direction: column;
      justify-content: center;
      align-items: center;

      row-gap: 16px;

      .title {
        text-align: center;
        font-size: 24px;
      }

      .incentive_trip_but {
        width: 160px;
        height: 48px;
        font-size: 13px;
      }
    }
  }
  .qual-cont {
    .title {
      font-family: Plus Jakarta Sans;
      font-size: 32px;
      font-weight: 700;
      line-height: 35.2px;
      letter-spacing: 0em;
      text-align: left;
      color: #212b36;
    }
    .rank-qualification-card {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #919eab3d;
      border-radius: 20px;
      padding: 30px 28px 30px 28px;
      @media screen and (max-width: 1200px) {
        flex-direction: column;
        align-items: start;
        justify-content: start;
        row-gap: 15px;
      }
      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
    }
    .rank-qualification {
      display: flex;
      align-items: center;
      gap: 35px;
      @media screen and (max-width: 1200px) {
        flex-direction: column;
        align-items: start;
        gap: 20px;
      }
      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
    .rank-badge {
      width: 105px;
      height: 105px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Public Sans;
      font-size: 26px;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: -2px;
      background: #f8f8f8;
      border-radius: 150px;
    }
    .rank-info {
      display: flex;
      flex-direction: column;
      font-family: "Plus Jakarta Sans", sans-serif;
      .status {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #595959;
        display: flex;
        align-items: center;
        @media screen and (max-width: 768px) {
          font-size: 18px;
          justify-content: center;
        }
      }
      .title {
        font-weight: 700;
        font-size: 24px;
        line-height: 28.8px;
        color: #212b36;
        width: 575px;
        @media screen and (min-width: 1201px) and (max-width: 1440px) {
          max-width: 200px;
          width: auto;
        }
        @media screen and (max-width: 1200px) {
          width: auto;
          max-width: auto;
        }
        @media screen and (max-width: 768px) {
          text-align: center;
        }
      }
    }
  
    .rank-description {
      max-width: 323px;
      font-family: "DM Sans", sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #637381;
      @media screen and (max-width: 768px) {
        font-size: 14px;
      }
    }
    .pay-but {
      width: 162px;
      height: 51px;
      border-radius: 150px !important;
      background: linear-gradient(93.33deg, #079acd 0%, #3bcaf2 100%);
      display: flex;
      justify-content: center;
      font-family: DM Sans;
      font-size: 16px;
      font-weight: 700;
      line-height: 19.2px;
      letter-spacing: 0.1em;
      text-align: left;
      @media screen and (max-width: 768px) {
        width: 140px;
        height: 48px;
        font-size: 13px;
      }
    }
    .qualified-rank-badge {
      border: 2px solid #ffab00;
      color: #ffab00;
    }
    .current-rank-badge {
      border: 2px solid #10b856;
      color: #10b856;
    }
    .future-rank-badge {
      border: 2px solid #717171;
      color: #221d1f;
    }
    .grayed-background {
      background: #f5f5f5;
    }
    .grayed-title {
      color: #717171 !important;
    }
    .grayed-but {
      background: #e7e7e7;
      color: #221d1f;
    }
  }
  .book-cont {
    border: 1px solid #919eab3d;
    border-radius: 20px;
    padding: 30px;
    h2 {
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      color: #212b36;
      margin-bottom: 24px;
    }
    .book-hint {
      font-family: "DM Sans",sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #637381;
      margin: 0;
    }
  }
`

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Card } from "components/common";
import { asUsdPrice } from "utils/text";
import { ReactComponent as CoinIcon } from "assets/icons/gift-card/coin.svg";

export default function BalanceSection(props) {
  const dispatch = useDispatch();
  const myUser = useSelector((state) => state.auth.user);
  const [wallet, setWallet] = useState({
    amount: 0,
  });

  const handleGetBalance = () => {
    dispatch({
      type: "auth.RELOAD",
    });
  };

  useEffect(() => {
    handleGetBalance();
  }, []);

  useEffect(() => {
    if (myUser) {
      setWallet(myUser.gift_wallet);
    }
  }, [myUser]);

  return (
    <Wrapper>
      <div>
        <CoinIcon />
      </div>
      <div>
        <span className={"amount"}>{asUsdPrice(wallet.amount)}</span>
        <h6>MY BALANCE</h6>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background: #b7effb;
  border-radius: 16px;
  padding: 40px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h6 {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #054b73;
    opacity: 0.72;
  }
  .amount {
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    color: #054b73;
    margin: 8px 0px;
  }
`;

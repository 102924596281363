import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  Row, Col, Modal,
  Button, FormItem,
  Input, Select, TextArea, SingleImageUploader, 
  StartEndTimePicker, DatePicker,
  Popconfirm, SuccessNotification
} from "components/common";
import { varIs, varValue } from "common/var";
import { uiVarList, uiVarOptions } from "common/uiVar";
import { callGetApiWithAuth, callPostApiWithAuth } from "utils/api";
import { Wrapper } from "./EditModal.styled";

export default function CreateModal(props) {
  const myUser = useSelector((state) => state.auth.user);
  const [formData, setFormData] = useState({
    title: "",
    // path: "",
    description: "",
    preview_image: "",
    timezone: "",
    topic: "",
    lang: "",
    products: [],
    start_on: "",
    start_at: "",
    end_at: "",
    video_type: varValue('stream.videoType', 'vimeo'),
    zoom_number: "",
    zoom_password: "",
    vimeo_id: '',
  });
  const [productsList, setProductsList] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [isPublishing2, setIsPublishing2] = useState(false);
  const [errors, setErrors] = useState([]);

  const onSelectImageFile = ({ data }) => {
    setFormData({
      ...formData,
      preview_image: data.file,
    });
  };

  const onChangeDateRange = (value) => {
    if (value) {
      setFormData({
        ...formData,
        start_on: moment(value).format("YYYY-MM-DD"),
      });
    } else {
      setFormData({
        ...formData,
        start_on: "",
      });
    }
  };

  const onChangeTimeRange = (time, timeRange) => {
    if (timeRange[0] && timeRange[1]) {
      setFormData({
        ...formData,
        start_at: timeRange[0],
        end_at: timeRange[1],
      });
    } else {
      setFormData({
        ...formData,
        start_at: "",
        end_at: "",
      });
    }
  };

  const onChangeFormData = (field, value) => {
    let formData0 = { ...formData };
    if (field === "title" && value && value.trim()) {
      // const regex = /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g;
      // let path = '';
      // if (value.match(regex)) {
      //   path = value
      //   .match(regex)
      //   .map((x) => x.toLowerCase())
      //   .join('-');
      // }

      formData0["title"] = value;
      // formData0['path'] = path
    } else {
      formData0[field] = value;
    }
    if (field === "topic" && value !== "product-training") {
      formData0["products"] = [];
    }

    setFormData(formData0);
  };

  const onCreateDone = () => {
    setIsPublishing(false);
    setIsCreating(false);
    setIsPublishing2(false);
    SuccessNotification("New live stream has been created successfully");
    setTimeout(() => {
      props.setModalMode(undefined);
    }, 1000);
    props.handleSearch();
  };

  const onCreateFail = () => {
    setIsPublishing(false);
    setIsCreating(false);
    setIsPublishing2(false);
  };

  const handleCreate = (status) => {
    // Validation
    let errors0 = [];

    if (!formData.title.trim()) {
      errors0.push({
        field: "title",
        message: "Please enter title",
      });
    }
    // if (!formData.path.trim()) {
    //   errors0.push({
    //     field: 'path',
    //     message: 'Please select path'
    //   })
    // }
    if (!formData.preview_image) {
      errors0.push({
        field: "preview_image",
        message: "Please select preview image",
      });
    }
    if (!formData.start_on) {
      errors0.push({
        field: "start_on",
        message: "Please select start date",
      });
    }
    if (!formData.start_at && !formData.end_at) {
      errors0.push({
        field: "start_end_at",
        message: "Please select start and end time",
      });
    }
    if (!formData.timezone) {
      errors0.push({
        field: "timezone",
        message: "Please select timezone",
      });
    }
    if (!formData.description.trim()) {
      errors0.push({
        field: "description",
        message: "Please enter description",
      });
    }
    if (!formData.topic.trim()) {
      errors0.push({
        field: "topic",
        message: "Please select topic",
      });
    }
    if (!formData.lang.trim()) {
      errors0.push({
        field: "lang",
        message: "Please select language",
      });
    }
    if (varIs('stream.videoType', formData.video_type, 'zoom')) {
      if (!formData.zoom_number.trim()) {
        errors0.push({
          field: "zoom_number",
          message: "Please input Zoom webinar number",
        });
      }
      if (!formData.zoom_password.trim()) {
        errors0.push({
          field: "zoom_password",
          message: "Please input Webinar password",
        });
      }
    } else if (varIs('stream.videoType', formData.video_type, 'vimeo')) {
      if (!formData.vimeo_id.trim()) {
        errors0.push({
          field: "vimeo_id",
          message: "Please input Vimeo link",
        });
      }
    }
    if (
      formData.topic === "product-training" &&
      formData.products.length === 0
    ) {
      errors0.push({
        field: "products",
        message: "Please select product",
      });
    }
    if (formData.products.length > 20) {
      errors0.push({
        field: "products",
        message: "You can not select more 20 products",
      });
    }
    setErrors(errors0);

    if (errors0.length > 0) {
      return;
    }

    let body = new FormData();

    for (let key of Object.keys(formData)) {
      if (
        key !== "products" &&
        key !== "start_on" &&
        key !== "start_at" &&
        key !== "end_at"
      ) {
        body.append(key, formData[key]);
      }
    }

    formData.products.forEach((el, index) => {
      body.append(`products[${index}]`, el);
    });

    body.append("status", status);

    let startAt =
      formData.start_on +
      " " +
      moment(formData.start_at, "hh:mm a").format("HH:mm:ss");
    let endAt =
      formData.start_on +
      " " +
      moment(formData.end_at, "hh:mm a").format("HH:mm:ss");

    body.append("start_at", startAt);
    body.append("end_at", endAt);

    uiVarList("timezones.list").forEach((el) => {
      if (el.text === formData.timezone) {
        body.append("timezone_abbr", el.utc[0]);
      }
    });

    if (varIs("stream.status", status, "draft")) {
      setIsCreating(true);
    } else if (varIs("stream.status", status, "public")) {
      setIsPublishing(true);
    } else if (varIs("stream.status", status, "private")) {
      setIsPublishing2(true);
    }

    callPostApiWithAuth("live_stream/create", body, onCreateDone, onCreateFail);
  };

  const onGetProducts = ({ data }) => {
    setProductsList(
      data.map((el) => ({
        value: el.id,
        key: el.id,
        label: el.title,
      }))
    );
  };
  const onFailProducts = () => {};
  const loadProducts = () => {
    callGetApiWithAuth("live_stream/products", onGetProducts, onFailProducts);
  };

  useEffect(() => {
    loadProducts();
  }, []);

  return (
    <Modal width={800} onCancel={() => props.setModalMode(undefined)}>
      <Wrapper>
        <div className="modal-title-container">Schedule New Livestream</div>
        <Row gutter={[20, 10]}>
          <Col xs={24} md={6}>
            <div className="input-container single-container-uploader">
              <FormItem
                label={"Preview Image"}
                errorMessages={errors.filter(
                  (el) => el.field === "preview_image"
                )}
              >
                <SingleImageUploader
                  className="upload-container-product"
                  image={formData.preview_image}
                  onChangeFile={onSelectImageFile}
                  width="100%"
                />
              </FormItem>
            </div>
          </Col>
          <Col xs={24} md={18}>
            <Row gutter={[10, 10]}>
              <Col xs={24}>
                <div className="input-container">
                  <FormItem
                    errorMessages={errors.filter((el) => el.field === "title")}
                  >
                    <Input
                      label="Enter Title"
                      value={formData.title}
                      onChange={(e) =>
                        onChangeFormData("title", e.target.value)
                      }
                    />
                  </FormItem>
                </div>
              </Col>
              {/* <Col xs={24} sm={12}>
                <div className="input-container">
                  <FormItem
                    label={"Path"}
                    errorMessages={errors.filter(el => 
                      el.field==='path'
                    )}
                  >
                    <Input
                      placeholder="Path"
                      value={formData.path}
                      onChange={(e) => onChangeFormData("path", e.target.value)}
                    />
                  </FormItem>
                </div>
              </Col> */}
              <Col span={24}>
                <Row gutter={[20, 20]}>
                  <Col xs={24} sm={12}>
                    <div className="input-container">
                      <FormItem
                        errorMessages={errors.filter(
                          (el) => el.field === "start_on"
                        )}
                      >
                        <DatePicker
                          label={"Start And End At"}
                          value={formData.start_on}
                          onChange={onChangeDateRange}
                        />
                      </FormItem>
                    </div>
                  </Col>
                  <Col xs={24} sm={12}>
                    <div className="input-container">
                      <FormItem
                        errorMessages={errors.filter(
                          (el) => el.field === "start_end_at"
                        )}
                      >
                        <StartEndTimePicker
                          startTime={formData.start_at}
                          endTime={formData.end_at}
                          onChange={onChangeTimeRange}
                          format={"hh:mm a"}
                          minuteStep={15}
                        />
                      </FormItem>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <div className="input-container">
                  <FormItem
                    errorMessages={errors.filter(
                      (el) => el.field === "timezone"
                    )}
                  >
                    <Select
                      label={"Timezone"}
                      value={formData.timezone}
                      options={[
                        {
                          label: "Select Timezone",
                          value: "",
                          key: "",
                        },
                        ...uiVarList("timezones.list").map((el) => ({
                          value: el.text,
                          key: el.text,
                          label: el.text,
                        })),
                      ]}
                      onChange={(value) => onChangeFormData("timezone", value)}
                      style={{ width: "100%" }}
                      showSearch
                    />
                  </FormItem>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <div className="input-container">
              <FormItem
                errorMessages={errors.filter(
                  (el) => el.field === "description"
                )}
              >
                <TextArea
                  label={"Description"}
                  rows={5}
                  maxLength={500}
                  value={formData.description}
                  onChange={(e) =>
                    onChangeFormData("description", e.target.value)
                  }
                />
              </FormItem>
            </div>
          </Col>
          {varIs('stream.videoType', formData.video_type, 'zoom')?
          <>
            <Col xs={24} sm={12}>
              <div className="input-container">
                <FormItem
                  errorMessages={errors.filter(
                    (el) => el.field === "zoom_number"
                  )}
                >
                  <Input
                    label={"Zoom Webinar Number"}
                    value={formData.zoom_number}
                    onChange={(e) =>
                      onChangeFormData("zoom_number", e.target.value)
                    }
                  />
                </FormItem>
              </div>
            </Col>
            <Col xs={24} sm={12}>
              <div className="input-container">
                <FormItem
                  errorMessages={errors.filter(
                    (el) => el.field === "zoom_password"
                  )}
                >
                  <Input
                    label={"Webinar Password"}
                    value={formData.zoom_password}
                    onChange={(e) =>
                      onChangeFormData("zoom_password", e.target.value)
                    }
                  />
                </FormItem>
              </div>
            </Col>
          </>
          : 
          <>
            <Col xs={24}>
              <Row gutter={[20, 20]}>
                <Col xs={24} sm={12}>
                  <div className="input-container">
                    <FormItem
                      errorMessages={errors.filter(
                        (el) => el.field === "vimeo_id"
                      )}
                    >
                      <Input
                        label={"Vimeo Link"}
                        value={formData.vimeo_id}
                        onChange={(e) =>
                          onChangeFormData("vimeo_id", e.target.value)
                        }
                      />
                    </FormItem>
                  </div>
                </Col>
              </Row>
            </Col>
          </>}
          <Col xs={24} sm={12} md={6}>
            <div className="input-container">
              <FormItem
                errorMessages={errors.filter((el) => el.field === "topic")}
              >
                <Select
                  label={"Topic"}
                  value={formData.topic}
                  options={[
                    {
                      label: "Select Topic",
                      value: "",
                      key: "",
                    },
                    ...uiVarOptions("liveStream.topics"),
                  ]}
                  onChange={(value) => onChangeFormData("topic", value)}
                  style={{ width: "100%" }}
                />
              </FormItem>
            </div>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <div className="input-container">
              <FormItem
                errorMessages={errors.filter((el) => el.field === "lang")}
              >
                <Select
                  label={"Language"}
                  value={formData.lang}
                  options={[
                    {
                      label: "Select Language",
                      value: "",
                      key: "",
                    },
                    ...uiVarOptions("liveStream.languages"),
                  ]}
                  onChange={(value) => onChangeFormData("lang", value)}
                  style={{ width: "100%" }}
                />
              </FormItem>
            </div>
          </Col>
          <Col xs={24} md={12}>
            <div className="input-container">
              <FormItem
                errorMessages={errors.filter((el) => el.field === "products")}
              >
                <Select
                  label={"Products"}
                  placeholder="Select Products"
                  mode="multiple"
                  value={formData.products}
                  options={productsList}
                  onChange={(value) => onChangeFormData("products", value)}
                  style={{ width: "100%" }}
                  optionFilterProp="label"
                />
              </FormItem>
            </div>
          </Col>
          <Col span={24}>
            <div className="action-btn">
              <Popconfirm
                onConfirm={() =>
                  handleCreate(varValue("stream.status", "draft"))
                }
              >
                <Button loading={isCreating}>Save and Draft</Button>
              </Popconfirm>
              {myUser.pay_rank_id >= 8 || // 25K rank
              varIs(
                "user.isStreamSpecialCreator",
                myUser.is_stream_special_creator,
                "yes"
              ) ||
              varIs(
                "user.isStreamCompanyUser",
                myUser.is_stream_company_user,
                "yes"
              ) ? (
                <Popconfirm
                  onConfirm={() =>
                    handleCreate(varValue("stream.status", "public"))
                  }
                >
                  <Button loading={isPublishing}>Publish as Public</Button>
                </Popconfirm>
              ) : (
                ""
              )}
              <Popconfirm
                onConfirm={() =>
                  handleCreate(varValue("stream.status", "private"))
                }
              >
                <Button loading={isPublishing2}>Publish as Private</Button>
              </Popconfirm>
            </div>
          </Col>
        </Row>
      </Wrapper>
    </Modal>
  );
}

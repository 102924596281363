import React from "react";
import styled from "styled-components";

export default function Badge(props) {
  return (
    <Wrapper>
      <div
        className={`statusTag ${props.label?.toLowerCase()} ${props.type}_${
          props.keyName
        }`}
      >
        {props.label}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .statusTag {
    display: inline-block;
    width: auto;
    min-width: 100px !important;
    text-align: center;
    background: rgba(0, 108, 156, 0.16);
    border-radius: 6px;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #006c9c;
    padding: 4px 10px 4px;
  }
  .userStatus_active {
    background-color: rgba(54, 179, 126, 0.16);
    color: #1b806a;
  }
  .userStatus_hold {
    background-color: rgba(0, 108, 156, 0.16);
    color: #006c9c;
  }
  .userStatus_suspended {
    background-color: rgba(183, 29, 24, 0.16);
    color: #b71d18;
  }
  .userStatus_pending {
    background: #1890ff;
  }
  .subscriptionStatus_active {
    background-color: rgba(54, 179, 126, 0.16);
    color: #1b806a;
  }
  .subscriptionStatus_inactive {
    background-color: rgba(0, 108, 156, 0.16);
    color: #006c9c;
  }
  .subscriptionStatus_cancelled {
    background-color: rgba(183, 29, 24, 0.16);
    color: #b71d18;
  }
  .orderStatus_paid {
    background-color: rgba(54, 179, 126, 0.16);
    color: #1b806a;
  }
  .orderStatus_pending {
    background-color: rgba(0, 108, 156, 0.16);
    color: #006c9c;
  }
  .orderStatus_refunded {
    background-color: rgba(183, 29, 24, 0.16);
    color: #b71d18;
  }
  .orderStatus_partial_refunded {
    background-color: rgba(183, 29, 24, 0.16);
    color: #b71d18;
  }
  .orderStatus_chargebacked {
    background-color: rgba(183, 29, 24, 0.16);
    color: #b71d18;
  }
  .orderShipStatus_none {
  }
  .orderShipStatus_pending {
    background-color: rgba(183, 29, 24, 0.16);
    color: #b71d18;
  }
  .orderShipStatus_shipped {
    background-color: rgba(54, 179, 126, 0.16);
    color: #1b806a;
  }
  .orderShipStatus_failed {
    background-color: rgba(183, 29, 24, 0.16);
    color: #b71d18;
  }
  .orderShipStatus_cancelled {
    background-color: rgba(183, 29, 24, 0.16);
    color: #b71d18;
  }
  .orderMerchantResponse_success {
    background: rgba(54, 179, 126, 0.16);
    color: #1b806a;
  }
  .orderMerchantResponse_pending {
    background: rgba(183, 29, 24, 0.16);
    color: #b71d18;
  }
  .creditWithdrawRequestStatus_pending {
    background-color: rgba(183, 29, 24, 0.16);
    color: #b71d18;
  }
  .creditWithdrawRequestStatus_paid {
    background-color: rgba(54, 179, 126, 0.16);
    color: #1b806a;
  }
  .creditWithdrawRequestStatus_rejected {
    background-color: rgba(0, 108, 156, 0.16);
    color: #006c9c;
  }
  .creditWithdrawRequestStatus_failed {
    background-color: rgba(183, 29, 24, 0.16);
    color: #b71d18;
  }
  .giftTransferHistoryStatus_pending {
    background-color: rgba(183, 29, 24, 0.16);
    color: #b71d18;
  }
  .giftTransferHistoryStatus_accepted {
    background-color: rgba(54, 179, 126, 0.16);
    color: #1b806a;
  }
  .giftTransferHistoryStatus_cancelled {
    background-color: rgba(183, 29, 24, 0.16);
    color: #b71d18;
  }
  .tgiftTransferRequestStatus_pending {
    background-color: rgba(183, 29, 24, 0.16);
    color: #b71d18;
  }
  .tgiftTransferRequestStatus_accepted {
    background-color: rgba(54, 179, 126, 0.16);
    color: #1b806a;
  }
  .tgiftTransferRequestStatus_cancelled {
    background-color: rgba(183, 29, 24, 0.16);
    color: #b71d18;
  }
  .userBonusStatus_pending {
    background-color: rgba(183, 29, 24, 0.16);
    color: #b71d18;
  }
  .userBonusStatus_paid {
    background-color: rgba(54, 179, 126, 0.16);
    color: #1b806a;
  }
  .userBonusStatus_rejected {
    background-color: rgba(183, 29, 24, 0.16);
    color: #b71d18;
  }
  .streamStatus_public {
    background-color: rgba(54, 179, 126, 0.16);
    color: #1b806a;
    min-width: 80px !important;
  }
  .streamStatus_private {
    background-color: rgba(0, 108, 156, 0.16);
    color: #006c9c;
    min-width: 80px !important;
  }
  .streamStatus_draft {
    background-color: rgba(183, 29, 24, 0.16);
    color: #b71d18;
    min-width: 80px !important;
  }
  .streamStatus_ended {
    min-width: 80px !important;
  }
  .streamRecordStatus_scheduled {
    background-color: rgba(183, 29, 24, 0.16);
    color: #b71d18;
    min-width: 80px !important;
  }
  .streamRecordStatus_in_review {
    background-color: rgba(0, 108, 156, 0.16);
    color: #006c9c;
    min-width: 80px !important;
  }
  .streamRecordStatus_recorded {
    background-color: rgba(183, 29, 24, 0.16);
    color: #b71d18;
    min-width: 80px !important;
  }
  // .confirmed {
  //   background: #79b779;
  // }
  .active {
    background: #79b779;
  }
  .accepted {
    background-color: rgba(54, 179, 126, 0.16);
    color: #1b806a;
  }
  // .resolved {
  //   background: #79b779;
  // }
  .inactive {
    background: #f56b6e;
  }
  // .urgent {
  //   background: #f56b6e;
  // }
  // .resolved {
  //   background: #45b854;
  // }
  // .ended {
  //   background: #f56b6e;
  // }
  // .failed {
  //   background: #f56b6e;
  // }

  // .saved {
  //   background: #1890ff;
  // }
  // .hidden {
  //   background: #f7d250;
  // }
  // .low {
  //   background: #f7d250;
  // }
  // .resolving {
  //   background: #f7d250;
  // }
  // .open {
  //   background: #f7d250;
  // }
  // .success {
  //   background: #79b779;
  // }
  // .resent {
  //   background: #79b779;
  // }
  // .shipped {
  //   background: #45b854;
  // }
  // .refunded {
  //   background: #f7d250;
  // }
  // .partial_refunded {
  //   background: #f7d250;
  // }
  // .cancelled {
  //   background: #f7d250;
  // }
  // .completed {
  //   background: #45b854;
  // }

  // .deleted {
  //   background: #f56b6e;
  // }
  // .requested {
  //   background: #1890ff;
  // }
  // .chargebacked {
  //   background: #1890ff;
  // }
  // .accepted {
  //   background: #45b854;
  // }
  // .rejected {
  //   background: #f56b6e;
  // }
  // .draft {
  //   background: #f56b6e;
  // }
  // .in_progress {
  //   background: #45b854;
  // }
  // .verified {
  //   background: #79b779;
  // }
  // .none {
  //   background: rgba(0, 0, 0, 0.25);
  // }
  // .uploaded {
  //   background: #45b854;
  // }
  // .processed {
  //   background: #45b854;
  // }
  // .unprocessed {
  //   background: #f7d250;
  // }
  // .yes {
  //   background: #f56b6e;
  // }
  // .waiting {
  //   background: #7e6bf5;
  // }
  // .pending {
  //   background: #7e6bf5;
  // }
  // .medium {
  //   background: #7e6bf5;
  // }
  // .paid {
  //   background: #45b854;
  // }
`;

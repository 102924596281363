import React, { useEffect, useState } from "react";
import moment from "moment";
import styled from "styled-components";
import {
  Card,
  Table,
  Select,
  TablePanel,
  MonthPicker,
  UserAvatar,
  CountryFlag,
} from "components/common";
import { callGetApiWithAuth } from "utils/api";
import { asVolume } from "utils/text";

export default function TableView() {
  const [tableData, setTableData] = useState([]);
  const [searchParam, setSearchParam] = useState({
    search_type: "",
    from: moment().startOf("month").format("YYYY-MM-DD"),
    to: moment().endOf("month").format("YYYY-MM-DD"),
  });
  const [isLoading, setIsLoading] = useState(false);

  const onGetTableData = (res) => {
    setTableData(res.data);
    setIsLoading(false);
  };
  const onFailTableData = () => {
    setIsLoading(false);
  };
  const loadTableData = (searchParam0) => {
    setIsLoading(true);
    const params = {
      ...searchParam0,
    };
    const q = Object.keys(params)
      .map((k) => k + "=" + params[k])
      .join("&");
    callGetApiWithAuth(
      `leaderboard/list?${q}`,
      onGetTableData,
      onFailTableData
    );
  };

  const onChangeType = (v) => {
    const searchParam0 = {
      ...searchParam,
      search_type: v,
    };
    setSearchParam(searchParam0);
    loadTableData(searchParam0);
  };
  const onChangeDate = (v) => {
    let searchParam0 = {
      ...searchParam,
      from: moment().startOf("month").format("YYYY-MM-DD"),
      to: moment().endOf("month").format("YYYY-MM-DD"),
    };
    if (v) {
      searchParam0 = {
        ...searchParam,
        from: moment(v).startOf("month").format("YYYY-MM-DD"),
        to: moment(v).endOf("month").format("YYYY-MM-DD"),
      };
    }
    setSearchParam(searchParam0);
    loadTableData(searchParam0);
  };

  useEffect(() => {
    loadTableData(searchParam);
  }, []);

  return (
    <Wrapper>
      <TablePanel
        title={
          <div className="top-header">
            <Select
              value={searchParam.search_type}
              onChange={(v) => onChangeType(v)}
              options={[
                {
                  label: "Company",
                  value: "",
                },
                {
                  label: "My Team",
                  value: "my_team",
                },
              ]}
            />
          </div>
        }
        data={tableData}
        loading={isLoading}
        toolbar={
          <MonthPicker
            date={searchParam.from}
            onChange={(v) => onChangeDate(v)}
          />
        }
        columns={[
          {
            title: "#",
            key: "s_no",
            render: (_, __, index) => <span>{index + 1}</span>,
          },
          {
            title: "Name",
            key: "name",
            render: (_, record) => (
              <UserAvatar
                image={record.user.image}
                title={`${record.user.first_name} ${record.user.last_name}`}
              />
            ),
          },
          {
            title: "Country",
            key: "county",
            render: (_, record) => (
              <div>
                <CountryFlag country={record.user.country} />
              </div>
            ),
          },
          {
            title: "New Enrollments",
            key: "pe",
            render: (_, record) => <span>{record.npe}</span>,
          },
          {
            title: "New Business Volume",
            key: "pev",
            render: (_, record) => <span>{asVolume(record.npev)}</span>,
          },
        ]}
      />
    </Wrapper>
  );
}

export const Wrapper = styled.div`
  .top-header {
    .ant-select {
      width: 220px;
      .ant-select-selector {
        height: 40px !important;
        padding-top: 4px !important;
      }
    }
    .ant-select-selection-item, .ant-picker-input > input {
      color: #919eab !important;
    }
  }

  .ant-picker {
    height: 40px !important;
    border-radius: 8px !important;
    .ant-picker-input > input {
      color: #919eab !important;
    }
  }

  @media (max-width: 1250px) {
    .ant-table {
      overflow-x: scroll;
      .ant-table-container {
        width: 900px;
      }
    }
  }
  @media (max-width: 600px) {
    .panelHead {
      display: block;
      h4 {
        padding-bottom: 24px;
      }
      .ant-select,
      .ant-picker {
        width: 100% !important;
      }
    }
  }

  /* @media (max-width: 576px) {
    .date-picker {
      flex-direction: column;
      width: 100%;
      div {
        width: 100%;
      }
      .ant-select {
        width: 100% !important;
        margin-left: 0px !important;
        margin-bottom: 10px;
      }
    }
  }
  .user-name {
    img {
      width: 28px;
      height: 28px;
      margin-right: 7px;
    }
  }
  @media (max-width: 1024px) {
    .table-container {
      .ant-table {
        overflow-x: scroll;
        .ant-table-container {
          width: 800px;
        }
      }
    }
  } */
`;

import React, { useState, useEffect } from "react";
import moment from "moment";
import { Row, Col, List, Tag, Spin } from "components/common";
import { asPrice } from "utils/text";
import { callGetApiWithAuth } from "utils/api";
import { Wrapper } from "./ListSection.styled";

export default function ListSection() {
  const [listData, setListData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const onGetListData = (res) => {
    setIsLoading(false);
    setListData(res.data);
  };
  const onFailListData = () => {
    setIsLoading(false);
  };
  const loadListData = () => {
    setIsLoading(true);
    callGetApiWithAuth(
      `my_account/subscription/pasts`,
      onGetListData,
      onFailListData
    );
  };

  useEffect(() => {
    loadListData();
  }, []);

  return (
    <Wrapper>
      {listData.length > 0 ? (
        <>
          <List
            grid={{ gutter: 24, xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 4 }}
            className="past-list"
            dataSource={[...listData]}
            renderItem={(item) => (
              <List.Item>
                <SubscriptionCard data={item} loadListData={loadListData} />
              </List.Item>
            )}
            loading={{
              spinning: isLoading || false,
              indicator: <Spin spinning={true} className={`spinner`} />,
            }}
          />
        </>
      ) : (
        ""
      )}
    </Wrapper>
  );
}

const SubscriptionCard = (props) => {
  return (
    <div className="past-card">
      <div className="pro-c">
        <div className="pro-c-1">
          <div className="payment-product-image">
            <img src={props.data.product.image_path} />
          </div>
        </div>
        <div className="pro-c-2">
          <div className="price-container">
            <div className="payment-title">{props.data.product.title}</div>
            <div className="date-container-n">
              <div className="date-label-n">Date Created</div>
              <div className="date-value-n">
                {moment(props.data.created_at).format("MMMM D, YYYY")}
              </div>
            </div>
            {/* <div className="payment-tag">
              <Tag color="red">Cancelled</Tag>
            </div>
            */}
          </div>
        </div>
      </div>
      <Row gutter={[10, 10]}>
        <Col span={8}>
          <div className="bv-container">
            <div className="bv-title">Quantity</div>
            <div className="bv-value">{props.data.quantity}</div>
          </div>
        </Col>
        <Col span={8}>
          <div className="bv-container">
            <div className="bv-title">Vol. (BV)</div>
            <div className="bv-value">
              {props.data.product.recurring_pv * props.data.quantity}
            </div>
          </div>
        </Col>
        <Col span={8}>
          <div className="bv-container">
            <div className="bv-title">Price</div>
            <div className="bv-value">
              {asPrice(props.data.product.recurring_price)}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { TablePanel, Input, Button, CountryFlag } from "components/common";
import { varLabel } from "common/var";
import { countryName } from "common/country";
import { callGetApiWithAuth } from "utils/api";
import { asDate } from "utils/text";
import { ReactComponent as SearchOutlinedIcon } from "assets/icons/search-icon.svg";

export default function TableView() {
  const [tableData, setTableData] = useState([]);
  const [paginationParam, setPaginationParam] = useState({
    currentPage: 1,
    perPage: 15,
    total: 0,
  });
  const [searchParam, setSearchParam] = useState({
    username: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const onFormInput = (field, value) => {
    setSearchParam({
      ...searchParam,
      [field]: value,
    });
  };

  const onGetTableData = (res) => {
    setIsLoading(false);
    setTableData(res.data.data);
    setPaginationParam({
      ...paginationParam,
      currentPage: res.data.current_page,
      total: res.data.total,
    });
  };
  const onFailTableData = () => {
    setIsLoading(false);
  };
  const loadTableData = (paginationParam0, searchParam0) => {
    setIsLoading(true);
    const params = {
      ...searchParam0,
      page: paginationParam0.currentPage,
      per_page: paginationParam0.perPage,
    };
    const q = Object.keys(params)
      .map((k) => k + "=" + params[k])
      .join("&");
    callGetApiWithAuth(
      `report/abandon_cart/list?${q}`,
      onGetTableData,
      onFailTableData
    );
  };

  const onPageChange = (page) => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: page,
    };
    loadTableData(paginationParam0, searchParam);
  };

  const handleSearch = () => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: 1,
    };
    loadTableData(paginationParam0, searchParam);
  };

  const onExportDone = (res) => {
    setIsExporting(false);
    window.open(res.data.csv_file, "_blank");
  };
  const onExportFail = () => {
    setIsExporting(false);
  };
  const handleExport = () => {
    setIsExporting(true);
    callGetApiWithAuth(
      `report/abandon_cart/export`,
      onExportDone,
      onExportFail
    );
  };

  useEffect(() => {
    handleSearch();
  }, []);

  return (
    <Wrapper>
      <TablePanel
        data={tableData}
        loading={isLoading}
        paginationParam={paginationParam}
        onPageChange={onPageChange}
        toolbar={
          <div className="searchField">
            <Input
              value={searchParam.username}
              onChange={(e) => onFormInput("username", e.target.value)}
              label={"Username, Email, Name"}
              inputPrefix={<SearchOutlinedIcon />}
            />
            <Button loading={isLoading} onClick={handleSearch}>
              Search
            </Button>
            <Button loading={isExporting} onClick={handleExport}>
              Export
            </Button>
          </div>
        }
        columns={[
          {
            title: "Username",
            key: "username",
            render: (_, record) => (
              <span>
                {record.user ? record.user.username : record.username}
              </span>
            ),
          },
          {
            title: "Name",
            key: "name",
            render: (_, record) => (
              <span>
                {record.user
                  ? `${record.user.first_name} ${record.user.last_name}`
                  : `${record.first_name} ${record.last_name}`}
              </span>
            ),
          },
          {
            title: "Email",
            key: "email",
            render: (_, record) => (
              <span>{record.user ? record.user.email : record.email}</span>
            ),
          },
          {
            title: "Phone",
            key: "phone",
            render: (_, record) => (
              <span>{record.user ? record.user.phone : record.phone}</span>
            ),
          },
          {
            title: "Date",
            key: "created_at",
            render: (_, record) => <span>{asDate(record.created_at)}</span>,
          },
          {
            title: "Last Step",
            key: "last_step",
            render: (_, record) => (
              <span className="stepLabel">
                {varLabel("abandonCart.lastStep", record.last_step)}
              </span>
            ),
          },
          {
            title: "Country",
            key: "country",
            render: (_, record) => (
              <span>
                <CountryFlag country={record.country} />
              </span>
            ),
          },
          {
            title: "Cart Details",
            key: "cart_details",
            render: (_, record) => (
              <span>
                {record.details.map((el, ind) => (
                  <span>
                    {`${el.product.title} x ${el.quantity}`}
                    {ind < record.details.length ? <br /> : ""}
                  </span>
                ))}
              </span>
            ),
          },
          {
            title: "Comment",
            key: "comment",
            render: (_, record) => (
              <div>{record.comment}</div>
            ),
          },
        ]}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
padding-bottom: 80px;
  .stepLabel {
    background-color: rgba(0, 184, 217, 0.16);
    color: #006C9C;
    font-weight: 700;
    font-size: 12px;
    padding: 6px 12px;
    border-radius: 8px;
  }
  .searchField {
    display: flex;
    align-items: center;
    gap: 16px;
    .ant-input {
      height: 40px !important;
    }
    .input-prefix {
      svg {
        width: 15px;
        height: 15px;
        margin-bottom: 5px;
      }
    }
    .ant-form-item-label {
      top: 9px !important;
      left: 44px !important;
    }
    .ant-form-item-label-active {
      transform: translateY(-17px) scale(1) !important;
    }
  }

  @media (max-width: 600px) {
    .toolbar {
      width: 100% !important;
    }
    .searchField {
      flex-direction: column;
      align-items: flex-start;
      width: 100% !important;
      .jjGCjX, .input-prefix-container,
      button {
        width: 100% !important;
      }
    }
  }
`;

import styled from "styled-components";

export const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(44, 39, 56, 0.06);
  padding: 20px;
  .transfer-container {
    max-width: 769px;
  }
  .issue-credit-title {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 30px;
  }
  .issue-label {
    font-weight: 500;
    font-size: 18px;
    color: #7f8fa3;
    width: 206px;
  }
  .issue-input-comment {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #ff5630;
    margin-top: 5px;
  }
  .amount-row {
    margin: 30px 0px;
  }
  .send-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
      background: #1ea9ea;
      border-radius: 8px;
      width: 81px;
      padding: 8px 0px;
      border: none;
      color: white;
      font-weight: 700;
      font-size: 15px;
      margin-top: 24px;
    }
  }
  .ant-select {
    width: 100% !important;
    input {
      height: 34px !important;
    }
  }
  @media (max-width: 600px) {
    .ant-col {
      width: 100%;
    }
    .issue-input-container {
      width: 100%;
    }
    .send-btn {
      button {
        width: 100%;
      }
    }
    .amount-row {
      margin: 15px 0px;
    }
  }
`;

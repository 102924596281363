import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { useSelector } from 'react-redux'
import styled from "styled-components"
import copy from 'copy-to-clipboard';
import { varIs } from 'common/var';
import { 
  Empty, Button, Spin,
  SuccessNotification, 
} from "components/common";
import { callGetApiWithAuth } from "utils/api";
import ProductList from "./product/ProductList";
import SplashImg from "assets/images/live_stream_splash.jpg";

export default function PublisherPage() {
  const location = useLocation();
  const myUser = useSelector(state => state.auth.user)
  const [streamDetail, setStreamDetail] = useState(undefined)
  const [isLoading, setIsLoading] = useState(false)

  const onGetStreamDetail = ({ data }) => {
    setIsLoading(false);
    setStreamDetail(data);
  };

  const onFailStreamDetail = () => {
    setIsLoading(false);
  };

  const loadStreamDetail = (streamId) => {
    setIsLoading(true);
    callGetApiWithAuth(
      `publisher/stream/${streamId}`,
      onGetStreamDetail,
      onFailStreamDetail
    );
  };

  const handleCopyUrl = (url) => {
    copy(`${process.env.REACT_APP_LIVE_STREAM_URL}/${url}/${myUser?myUser.username:''}`);
    SuccessNotification("Successfully copied to clipboard");
  }

  useEffect(() => {
    if (location?.state?.streamData) {
      loadStreamDetail(location.state.streamData.id);
    }
  }, [location]);

  return (
    <Wrapper>
      <div className="main-container publisher-chat">
        {isLoading ? (
          <Spin spinning={true} />
        ) : (
          <>
            {streamDetail? (
            <>
              <div className={'d-flex justify-content-between'}>
                <div className={'product_list_root'}>
                  <ProductList
                    data={streamDetail}
                  />
                </div>
                <div className={'zoom_root'}>
                  {streamDetail && 
                  <div className={'zoom_inwrap'}>
                    <p className=''>
                      Please start your webinar from the zoom application.
                    </p>
                    <p>
                      Here is your zoom number and password.<br/>
                      Zoom Number: <strong>{streamDetail.zoom_number}</strong><br/>
                      Password: <strong>{streamDetail.zoom_password}</strong>
                    </p>
                    <p>
                      Invite Link:<br/>
                      <p className={'copy-url'}>
                        {process.env.REACT_APP_LIVE_STREAM_URL}/{streamDetail.path}/{myUser?.username}
                        &nbsp;&nbsp;
                        <Button onClick={() => handleCopyUrl(streamDetail.path)}>
                          Copy Link
                        </Button>
                      </p>
                    </p>
                  </div>
                  }
                </div>
              </div>
            </>
            ) : (
            <>
              <div className="skeleton-root">
                <img src={SplashImg} />
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={"No Chat"}
                  className="no-products-skeleton"
                />
              </div>
            </>
            )}
          </>
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
  .main-container {
    height: 100%;
    padding-bottom: 20px;
  }
  .product_list_root {
    width: 200px;
  }
  .zoom_root {
    width: calc(100% - 220px);
    height: calc(100vh - 112px);
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    p {
      font-size: 18px;
    }
  }
  .copy-url {
    border: 1px solid #e6eaee;
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .skeleton-root {
    background: white;    
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      max-width: 800px;
    }
  }
`

import React, { useState } from "react";
import styled from "styled-components";
import ListTable from "./ListTable";
import SearchedTable from "./SearchedTable";
// import BadgeColorCard from './BadgeColorCard'

export default function TableView() {
  const [searchParam, setSearchParam] = useState({
    uuid: "",
    rank_id: "",
    created_at_range: "",
  });
  const [tableMode, setTableMode] = useState("list"); // list/searched
  const [selectedId, setSelectedId] = useState("");

  const goUser = (user) => {
    setTableMode("list");
    setSelectedId(user.id);
  };

  const handleSearch = (searchParam0) => {
    setTableMode("searched");
    setSearchParam(searchParam0);
  };

  return (
    <Wrapper>
      {tableMode === "list" && (
        <ListTable
          selectedId={selectedId}
          goUser={goUser}
          searchParam={searchParam}
          setSearchParam={setSearchParam}
          handleSearch={handleSearch}
        />
      )}
      {tableMode === "searched" && (
        <SearchedTable searchParam={searchParam} goUser={goUser} />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2),
    0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  border-radius: 16px;
  .panelHead {
    padding: 24px;
    h4 {
      width: 510px;
    }
  }
  .table-new-wrapper {
    tr {
      th:first-child {
        border-radius: 0px !important;
      }
      th:last-child {
        border-radius: 0px !important;
      }
    }
    .ant-table-cell {
      .content-root {
        .title {
          font-weight: 600;
        }
        .join-date {
          color: #637381;
        }
      }
    }
  }
  .chat-icon {
    margin-left: 12px;
    margin-top: -5px;
    cursor: pointer;
    color: ${(props) => props.theme.palette.link};
    font-size: 16px;
  }
  .subscription-yes {
    color: #3ad33a;
  }
  .subscription-no {
    color: #ff6565;
  }
  @media (max-width: 1400px) {
    table {
      width: 1200px;
    }
  }
  @media (max-width: 1200px) {
    .panelHead {
      h4 {
        width: 100% !important;
        button {
          width: 100% !important;
        }
      }
    }
  }
`;

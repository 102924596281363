import React, { useEffect } from "react";
import { Row, Col } from "components/common";
import { Divider } from "antd";
import Helmet from "react-helmet";
import ReactCountryFlag from "react-country-flag";
import { FaTelegram, FaYoutube } from "react-icons/fa";
import { ImFacebook2 } from "react-icons/im";
import ContactImage from "assets/images/support/contact_us.png";
import CallImg from "assets/images/support/call.svg";
import MapImg from "assets/images/support/map.svg";
import EmailImg from "assets/images/support/email.svg";
import { IoIosChatboxes } from "react-icons/io";
import styled from "styled-components";

export default function IndexPage() {
  useEffect(() => {
    var iframes = document.querySelectorAll("iframe");
    for (var i = 0; i < iframes.length; i++) {
      iframes[i].style.display = "block";
    }
    return () => {
      var iframes = document.querySelectorAll("iframe");
      for (var i = 0; i < iframes.length; i++) {
        iframes[i].style.display = "none";
      }
    };
  }, []);
  return (
    <Wrapper>
      <div className="main-container">
        <Row gutter={[20, 20]}>
          <Col xs={24}>
            <div className="white-rounded-bg">
              <img src={ContactImage} className="contact-us-banner" alt="" />
            </div>
          </Col>
          <Col xs={24}>
            <div className="white-rounded-bg">
              <Row container gutter={[20, 20]} className="customer-support-row">
                <Col xs={24}>
                  <div className="info-container">
                    <ReactCountryFlag
                      svg
                      countryCode={"US"}
                      style={{ fontSize: 40 }}
                    />
                    <h3 className="title">USA Home Office</h3>
                  </div>
                </Col>
                <Col xs={24} sm={12} lg={8} xl={6}>
                  <div className="info-text">
                    <IoIosChatboxes />
                    <strong>Chat Support</strong>
                    {/* <p>+1 844-806-3599</p> */}
                    <p>M-F 8am to 5pm (PST)</p>
                    <p>Click the chat button below</p>
                  </div>
                </Col>
                <Col xs={24} sm={12} lg={8} xl={6}>
                  <div className="info-text">
                    <img src={MapImg} alt="" />
                    <strong>Home Office</strong>
                    <p>6713 S Eastern Ave</p>
                    <p>Las Vegas, NV 89119</p>
                  </div>
                </Col>
                <Col xs={24} sm={12} lg={8} xl={6}>
                  <div className="info-text">
                    <img src={EmailImg} alt="" />
                    <strong>Email</strong>
                    <p>support@mydailychoice.com</p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={24}>
            <div className="white-rounded-bg return-addresses">
              <Row container gutter={[20, 20]} className="customer-support-row">
                <Col xs={24}>
                  <div className="info-container">
                    <h3 className="title">Customer Support</h3>
                    <Divider className="divider" />
                  </div>
                </Col>
                <Col xs={24} sm={12} lg={8} xl={6}>
                  <div className="info-text">
                    <ReactCountryFlag
                      svg
                      countryCode={"CA"}
                      style={{ fontSize: 40 }}
                    />
                    <strong>Canada Support</strong>
                    <label>Chat Support:</label>
                    {/* <p>+1 844-806-3599</p> */}
                    <p>M-F 8am to 5pm PST</p>
                    <p>support@mydailychoice.com</p>
                  </div>
                </Col>
                <Col xs={24} sm={12} lg={8} xl={6}>
                  <div className="info-text">
                    <ReactCountryFlag
                      svg
                      countryCode={"GB"}
                      style={{ fontSize: 40 }}
                    />
                    <strong>UK Support</strong>
                    <label>Phone Support:</label>
                    <p>+44-808-296-8011</p>
                    <p>8am to 6pm</p>
                    <p>europesupport@mydailychoice.com</p>
                  </div>
                </Col>
                <Col xs={24} sm={12} lg={8} xl={6}>
                  <div className="info-text">
                    <ReactCountryFlag
                      svg
                      countryCode={"HU"}
                      style={{ fontSize: 40 }}
                    />
                    <strong>Hungary Support</strong>
                    <label>Phone Support:</label>
                    <p>36 80080587</p>
                    <p>8am to 6pm (Kyiv Standard Time)</p>
                  </div>
                </Col>
                <Col xs={24} sm={12} lg={8} xl={6}>
                  <div className="info-text">
                    <ReactCountryFlag
                      svg
                      countryCode={"EU"}
                      style={{ fontSize: 40 }}
                    />
                    <strong>EU Support</strong>
                    <label>Phone Support:</label>
                    <p>+36-1-234-4592</p>
                    <p>Monday-Friday 8am to 5pm</p>
                    <p>(GMT +2)</p>
                  </div>
                </Col>
                {/* <Col xs={24} sm={12} lg={8} xl={6}>
                  <div className="info-text">
                    <ReactCountryFlag svg countryCode={'TW'} style={{fontSize: 40}} />
                    <strong>Taiwan Support</strong>
                    <label>Phone Support:</label>
                    <p>886-2-27168567</p>
                    <label>Office Hours:</label>
                    <p>早上10點到下午6點</p>
                    <label>Office Address:</label>
                    <p>105 台北市松山區復興北路</p>
                    <p>181號13樓之1</p>
                  </div>
                </Col> */}
                <Col xs={24} sm={12} lg={8} xl={6}>
                  <div className="info-text">
                    <ReactCountryFlag
                      svg
                      countryCode={"NG"}
                      style={{ fontSize: 40 }}
                    />
                    <strong>Nigeria Support</strong>
                    <label>Phone Support:</label>
                    <p>+234 9065867432</p>
                    <p>ngactivations@mydailychoice.com</p>
                  </div>
                </Col>
                <Col xs={24} sm={12} lg={8} xl={6}>
                  <div className="info-text">
                    <strong>Hablamos Español!</strong>
                    <label className="text-center">
                      Para asistencia en español, llama al:{" "}
                    </label>
                    <p>+1 844-806-3599, opción 2.</p>
                  </div>
                </Col>
                <Col xs={24} sm={12} lg={8} xl={6}>
                  <div className="info-text">
                    <ReactCountryFlag
                      svg
                      countryCode={"PH"}
                      style={{ fontSize: 40 }}
                    />
                    <strong>Philippines Support</strong>
                    <p>Philippines@mydailychoice.com</p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={24}>
            <div className="info-container social">
              <h3 className="title">Stay Connected</h3>
              <Divider className="divider" />
              <div className="social-icons">
                <a
                  href="https://www.facebook.com/officialmydailychoice/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <ImFacebook2 />
                </a>
                <a
                  href="https://t.me/MyDailyChoiceNews"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaTelegram />
                </a>
                <a
                  href="https://www.youtube.com/c/MyDailyChoiceOfficial"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaYoutube />
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Helmet>
        <script
          id="ze-snippet"
          src="https://static.zdassets.com/ekr/snippet.js?key=65b733ca-6f80-4823-830c-482d5fddf522"
        >
          {" "}
        </script>
        {/* <script>{`
          (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:2912584,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `}</script> */}
      </Helmet>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-bottom: 80px;
  .main-container {
  }
  .divider {
    margin: 0;
    width: 50px;
    min-width: 50px;
    border-top: 5px solid #00b2ff;
  }
  .contact-us-banner {
    width: 100%;
    border-radius: 8px;
    max-height: 300px;
    object-fit: cover;
    object-position: left;
  }
  .info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      height: 25px;
      width: 40px;
    }
  }
  .title {
    align-items: center;
    margin: 10px 0;
    font-size: 22px;
    font-weight: 600;
  }
  p {
    font-size: 14px;
    margin: 2px;
    text-align: center;
  }
  strong {
    font-size: 18px;
    margin: 10px 0 5px;
    text-align: center;
    color: #232838;
  }
  .customer-support-row {
    justify-content: center;
  }
  .info-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    max-width: 250px;
    margin: 0 auto;
    height: 100%;
    min-height: 200px;
    padding: 20px;
    background: #f5fcff;
    border: 1px solid rgba(0, 178, 255, 0.1);
    border-radius: 8px;
    img {
      height: 40px;
      width: 40px;
    }
    h5 {
      margin-bottom: 20px;
    }
    a {
      overflow-wrap: anywhere;
      color: #05c1ff;
    }
    svg {
      color: #05c1ff;
      font-size: 40px;
    }
  }
  .return-addresses {
    .customer-support-row {
      justify-content: flex-start;
    }
    .info-text {
      background: rgba(56, 74, 114, 0.1);
      border: 1px solid rgba(56, 74, 114, 0.1);
      border-radius: 8px;
      padding: 30px 10px;
      max-width: 500px;
      label {
        font-weight: 600;
      }
    }
  }
  .info-container.social {
    background: rgba(204, 240, 255, 20%);
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2),
      0px 12px 24px -4px rgba(145, 158, 171, 0.12) !important;
    border-radius: 16px !important;
    padding: 24px !important;
  }
  .social-icons {
    font-size: 36px;
    margin-top: 20px;
    svg {
      margin: 0 12px;
      cursor: pointer;
      color: #05c1ff;
    }
  }
  @media only screen and (max-width: 767px) {
    .contact-us-banner {
      min-height: 170px;
    }
  }
`;

import React, { useState } from "react";
import { Link } from 'react-router-dom';
import styled from "styled-components";
import { VideoModal } from "components/common";
import { BsChevronUp, BsChevronDown } from "react-icons/bs";
import VideoThumb from 'assets/images/setc-video.png';
import PlayImg from 'assets/images/play-btn.png';
import DocIcon from 'assets/images/doc-icon.png';
import CovidIcon from 'assets/images/covid-icon.png';

export default function AboutPage() {
  const [modalMode, setModalMode] = useState(undefined);

  return (
    <Wrapper>
      <div className="s1-root">
        <div className='container'>
          <h2>What is SETC?</h2>
          <h4>
            Self-employed and still getting back on track from COVID? We can help.
            You may qualify for up to $32,200!
          </h4>
          <p>
            The SETC is a specialized tax credit designed to provide support to
            self-employed individuals during the COVID-19 pandemic. It
            acknowledges the unique challenges faced by those who work for
            themselves, especially during times of illness, caregiving
            responsibilities, quarantine, and related circumstances. This credit
            can be a valuable resource for eligible individuals to help bridge
            financial gaps caused by unforeseen disruptions.
          </p>
          <div className="vid-wrap">
            {/* <iframe src="https://player.vimeo.com/video/893483592?h=c14c1c90e3&badge=0&autopause=0&quality_selector=1&player_id=0&app_id=58479" width="100%" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen title={'TaxCredit Video'}></iframe> */}
            <img src={VideoThumb} className="vid-thumb" />
            <img src={PlayImg} className="play-btn" 
              onClick={()=>setModalMode('video')}
            />
            {modalMode === 'video' && 
              <VideoModal
                onCancel={() => setModalMode(undefined)}
                src="https://player.vimeo.com/video/893483592?h=c14c1c90e3&badge=0&autopause=0&quality_selector=1&player_id=0&app_id=58479"
                width={900}
              />
            }
          </div>
        </div>
      </div>
      <div className="s2-root">
        <div className='container'>
          <h2>Eligibility Requirements</h2>
          <h4>
            <img src={DocIcon} />&nbsp;
            Self-Employment Status
          </h4>
          <p>If you were self-employed in 2020 and/or 2021. Including:</p>
          <ul>
            <li>Sole proprietors who run businesses with employees</li>
            <li>1099 subcontractors, and single-member LLCs.</li>
            <li>
              If you filed a “Schedule C” or a Partnership (1065) on your federal
              tax returns for 2020 and/or 2021
            </li>
          </ul>
          <h4>
            <img src={CovidIcon} />&nbsp;
            COVID Impacts
          </h4>
          <p>
            Whether you battled COVID, experienced COVID-like symptoms, needed to
            quarantine, underwent testing, or cared for a family member affected
            by the virus, the SETC could be your financial relief.
          </p>
          <div style={{ height: 80 }} />
          <h2>Simple 4 Step Process</h2>
          <div className="list-wrap">
            <div className="card-wrap">
              <div className="li-wrap">01</div>
              <h4>Fill Out Questionnaire</h4>
              <p>
                <Link to={'/setc/apply'}>Click here</Link> to complete the questionnaire to
                determine your eligibility.
              </p>
              <div className='btm-circle' />
            </div>
            <div className="card-wrap">
              <div className="li-wrap">02</div>
              <h4>Sign Engagement Letter</h4>
              <p>
                Our accounting firm will collect data from you and tell you your
                available tax credit.
              </p>
              <div className='btm-circle' />
            </div>
            <div className="card-wrap">
              <div className="li-wrap">03</div>
              <h4>Pay Filing Fee</h4>
              <p>
                Once you pay your filing fee, you will be in the queue to collect
                your money.
              </p>
              <div className='btm-circle' />
            </div>
            <div className="card-wrap">
              <div className="li-wrap">04</div>
              <h4>Receive Your Money</h4>
              <p>
                The US Treasury will send you a check in the mail to the address on your
                returns.
              </p>
              <div className='btm-circle' />
            </div>
          </div>
        </div>
      </div>
      <div className="s3-root">
        <div className='container'>
          <h2>Frequently Asked Questions</h2>
          <FaqCard
            title="What is the FFCRA?"
            content={
              <>
                <p>
                  The Families First Coronavirus Response Act (FFCRA) was passed
                  in 2020 and was one of the earliest pieces of legislation
                  designed to help small business owners afford the sick leave
                  their employees had to take because of COVID-19.
                </p>
                <p>
                  The FFCRA originally focused only on employees of certain small
                  businesses but had been expanded in 2021 to cover US citizens
                  who were self-employed during the COVID-19 pandemic and suffered
                  losses in business due to lockdowns or illnesses for themselves
                  or family members.
                </p>
              </>
            }
          />
          <FaqCard title="What dates are eligible for FFCRA income tax credits?"
            content={
              <>
                <p>
                  The dates you can claim under FFCRA income tax credit are between April 1, 2020 – March 31, 2021 and up to 10 days for dates between April 1, 2021 – September 30, 2021.
                </p>
                <p>
                  Here is a breakdown of the days:
                </p>
                <p>
                  Childcare related time off – up to 110 days
                </p>
                <ul>
                  <li>50 days between April 1,2020 and March 31, 2021</li>
                  <li>60 days between April 1, 2021 and September 30, 2021</li>
                </ul>
                <p>
                  Yourself or loved one (other than child) – up to 20 days
                </p>
                <ul>
                  <li>10 days between April 1,2020 and March 31, 2021</li>
                  <li>10 days between April 1, 2021 and September 30, 2021</li>
                </ul>
              </>
            }
          />
          <FaqCard title="What qualifies as a reason for claiming  FFCRA?"
            content={
              <>
                <p>
                  To qualify for FFCRA credits you must have missed work because of COVID-related issues. If you were unable to work because of one of these reasons, you may be eligible:
                </p>
                <ul>
                  <li>A government agency imposed a quarantine or isolation order.</li>
                  <li>Your doctor recommended you self-quarantine.</li>
                  <li>You were having COVID-19 symptoms while also waiting for an appointment with your doctor.</li>
                  <li>You were waiting for COVID-19-related test results.</li>
                  <li>You were getting vaccinated against COVID-19.</li>
                  <li>You were experiencing side effects from the COVID-19 vaccine.</li>
                  <li>You took care of your children who were affected by school or daycare shutdowns.</li>
                  <li>You took care of someone else/family members who had COVID-19 issues.</li>
                </ul>
              </>
            }
          />
          <FaqCard title="How is the credit amount determined?"
            content={
              <>
                <p>
                  Self-employed individuals are eligible for FFCRA credit if they are out of work (or telework) due to government quarantine orders, self-quarantine, COVID-19 symptoms and seeking medical diagnosis. The credit is calculated by multiplying the number of days on leave and taking whichever amount is smaller:
                </p>
                <ul>
                  <li>
                  Your average daily self-employment income of year or:
                  </li>
                  <li>
                  $511.
                  </li>
                </ul>
                <p>
                If you are unable to work (or telework) to take care of a family member who is under quarantine or to take care of a child whose child care is unavailable, you are still eligible for this credit.  The credit is calculated by multiplying the number of days on leave and taking whichever amount is smaller:
                </p>
                <ul>
                  <li>
                  ⅔ of your average daily self-employment income or :
                  </li>
                  <li>
                  $200.
                  </li>
                </ul>
                <p>
                We will use line 6 of the Schedule SE on your personal tax return to determine your annual pay, that is then divided by 260 (Considered the standard amount of working days in a year) to calculate your daily rate.
                </p>
                <p>
                From there, we must determine which reason the leave was taken and that will decide what rate can be paid for the dates being claimed. For self leave, we claim your full daily rate up to $511/day. Family or childcare leave is calculated as 2/3rds of your pay up to $200/day.
                </p>
              </>
            }
          />
          <FaqCard title="How long does it take to receive funds?"
            content={
              <>
                <p>
                It can take up to three weeks for the IRS to acknowledge the acceptance of your FFCRA credit application and up to 20 weeks from that acceptance to receive your refund via check or direct deposit.
                </p>
              </>
            }
          />
          <FaqCard title="How much is the FFCRA credit?"
            content={
              <>
                <p>
                The total FFCRA Tax credit can be up to $32,200.00 and is based on your net earnings in 2020 and in 2021.
                </p>
                <p>
                You will have to calculate your daily average of self-employment income. This is your net earnings for the taxable year divided by 260 (the standard recognized amount of working days in a year). This allows the IRS to estimate how much you lost in wages for every day you were not able to work.
                </p>
              </>
            }
          />
          <FaqCard title="What does it mean to be self-employed?"
            content={
              <>
                <p>
                A self-employed person in the United States, as defined by the Internal Revenue Service (IRS), is generally considered someone to who the following applies:
                </p>
                <ul>
                  <li>
                  You carry on a trade or business as a sole proprietor or an independent contractor.
                  </li>
                  <li>
                  You are a member of a partnership that carries on a trade or business.
                  </li>
                  <li>
                  You are otherwise in business for yourself (including a part-time business or a gig worker).
                  </li>
                </ul>
              </>
            }
          />
          <FaqCard title="What is the definition of a dependent?"
            content={
              <>
                <p>
                The IRS defines a dependent as either a qualifying child or relative of the taxpayer. The relative can be your child, stepchild, foster child, sibling, parent, grandparent, grandchild, aunt, uncle, niece, nephew, or certain in-law relationships.
                </p>
                <p>
                The Child Tax Credit helps families with qualifying children get a tax break. To have received a Child Tax credit or a credit for other dependents, you would have had to submit a Schedule 8821.
                </p>
                <p>
                A child must have lived with you for more than half of the tax year. Temporary absences, such as for education or medical care, are generally counted as periods of living with you. You must have provided more than half of the relative’s total support during the tax year. The relative’s gross income must be below a certain threshold determined annually by the IRS (subject to change). It’s important to note that these are just general guidelines, and there may be additional rules and exceptions. The IRS provides detailed information in publications such as IRS Publication 501.
                </p>
                <p>
                Examples of a Dependent:
                </p>
                <ul>
                  <li>
                  Child
                  </li>
                  <li>
                  Parent
                  </li>
                  <li>
                    Brother/Sister
                  </li>
                  <li>
                    Stepparent/Stepchild
                  </li>
                  <li>
                    Adoptive Daughter/Adoptive Son
                  </li>
                  <li>
                    Stepbrother/Stepsister
                  </li>
                  <li>
                    Half Brother/Half Sister
                  </li>
                  <li>
                    Grandparent/Grandchild
                  </li>
                  <li>
                    Son-in-law/Daughter-in-law
                  </li>
                  <li>
                    Mother-in-law/Father-in-law
                  </li>
                  <li>
                    Brother-in-law/Sister-in-law
                  </li>
                  <li>
                    Uncle/Aunt
                  </li>
                  <li>
                    Niece/Nephew
                  </li>
                </ul>
              </>
            }
          />
          <FaqCard title="Do I qualify for FFCRA income tax credit if I already received unemployment benefits?"
            content={
              <>
                <p>
                  No. You cannot claim double benefits on days you already received payments from unemployment insurance claims.
                </p>
              </>
            }
          />
          <FaqCard title="Do weekends count?"
            content={
              <>
                <p>
                  If your standard work day includes a weekend day, or your child was in school or daycare during a weekend, then you may include them.<br/>
                  If you normally don’t work on weekends or your child does not go to school on weekends, you cannot claim credits for weekends that they would not have worked or taken leave anyway. The credits are only available for the days that you would have worked or taken leave if not for the COVID-19-related reasons.
                </p>
              </>
            }
          />
          <FaqCard title="Do I still qualify if I did not pay myself sick leave?"
            content={
              <>
                <p>
                  Yes! This is what the FFCRA was designed to cover, especially since a lot of entrepreneurs fall into this category.
                </p>
              </>
            }
          />
          <FaqCard title="Can I use days I took care of a child other than my own child?"
            content={
              <>
                <p>
                  No. you can only use days you took care of your dependent.
                </p>
              </>
            }
          />
          <FaqCard title="What if my child’s school moved to online classes? Is ot still considered “closed” for the purpose of the credit?"
            content={
              <>
                <p>
                  Yes. If the physical location where your child received instruction or care is now closed, the school or place of care is “closed” for purposes of paid sick leave and expanded family and medical leave. This is true even if your child is still expected or required to complete assignments.
                </p>
              </>
            }
          />
          <FaqCard title="Will I get a check or will the refund be deposited in my account?"
            content={
              <>
                <p>
                  Refunds for 2020 and 2021 will be sent to you directly by the IRS via check to the address provided on your FFCRA application.
                </p>
              </>
            }
          />
          <FaqCard title="Can I claim FFCRA tax credits if I am also a W2 employee?"
            content={
              <>
                <p>
                  We’re sorry but Jorns is unable to help W2 employees with filing for the FFCRA. You may still qualify for credit depending on if your employer filed for the FFCRA on your behalf. Consult a CPA for your specific situation.
                </p>
              </>
            }
          />
          <FaqCard title="What is a “child or other dependent” tax credit?"
            content={
              <>
                <p>
                  The Child Tax Credit helps families with qualifying children get a tax break. To have received a Child Tax credit or a credit for other dependents, you would have had to submit a Schedule 8821.
                </p>
              </>
            }
          />
          <FaqCard title="Why do I have to have positive tax earnings to qualify for FFCRA income tax credit?"
            content={
              <>
                <p>
                  We understand Covid-19 pandemic effected everyone globally. If you did not have positive earnings in 2020 because of Covid-19 restrictions, we can use your 2019 net income.
                </p>
              </>
            }
          />
          <FaqCard title="Can more than one parent of guardian claim FFCRA tax credits simultaneously to care for my child whose school or place of care was closed  or went virtual due to COVID-19 related reasons?"
            content={
              <>
                <p>
                  Yes, but parents can not claim the same dates twice.
                </p>
              </>
            }
          />
          <FaqCard title="Is FFCRA a loan or a grant?"
            content={
              <>
                <p>
                  FFCRA is a tax credit not a loan. It is also not considered a grant as it’s a refund of taxes you’ve already paid.
                </p>
              </>
            }
          />
        </div>
      </div>
      <div className="dis-root">
        <div className='container'>
          <h4>Disclaimer</h4>
          <p>
            Please note that MyDailyChoice is an independent entity and is not
            associated with, endorsed by, or officially affiliated with the
            Internal Revenue Service (IRS) or any of its subsidiaries or
            affiliates. For official IRS information, please visit www.irs.gov.
            The term "IRS," along with any related names, logos, symbols, and
            images, is the property of their respective owners and is registered
            as their trademark. Any mentions of the IRS on our website are solely
            for the purpose of providing information and commentary on relevant
            topics and are intended for informational use only. We do not assert
            any representation or special relationship with the IRS. If you have
            inquiries or issues related to taxes or the IRS, we recommend
            contacting the IRS directly.
          </p>
        </div>
      </div>
    </Wrapper>
  );
}

const FaqCard = ({ title, content }) => {
  const [isOpened, setIsOpened] = useState(false)

  return (
    <div className='faq-card'>
      <div className='card-head'
        onClick={() => setIsOpened(!isOpened)}
      >
        <h4>{title}</h4>
        <div className='tog-wrap'>
          {isOpened?
            <BsChevronUp />
          : <BsChevronDown />
          }
        </div>
      </div>
      {isOpened && 
      <div className='card-content'>
        {content}
      </div>}
    </div>
  )
}

const Wrapper = styled.div`
  margin: -24px -40px;
  @media (max-width: 768px) {
    margin: 0;
  }
  .s1-root {
    background-color: #EDF9FE;
    padding: 100px 0;
    h2 {
      font-size: 32px;
      font-weight: 700;
      line-height: 1.1em;
      color: ${props=>props.theme.palette.text};
    }
    h4 {
      font-size: 24px;
      font-weight: 700;
      line-height: 1.2em;
      color: ${props=>props.theme.palette.text};
    }
    p {
      font-size: 20px;
      color: ${props=>props.theme.palette.secondaryText};
    }
    .vid-wrap {
      position: relative;
      img.vid-thumb {
        width: 100%;
      }
      img.play-btn {
        position: absolute;
        width: 94px;
        height: 94px;
        left: calc(50% - 47px);
        top: calc(50% - 47px);
        cursor: pointer;
      }
    }
  }
  .s2-root {
    padding: 100px 0;
    h2 {
      font-size: 32px;
      font-weight: 700;
      line-height: 1.1em;
    }
    h4 {
      font-size: 24px;
      font-weight: 700;
      line-height: 1.2em;
      img {
        height: 28px;
        object-fit: contain;
      }
    }
    p {
      font-size: 20px;
      color: ${props=>props.theme.palette.secondaryText};
    }
    ul {
      font-size: 20px;
      color: ${props=>props.theme.palette.secondaryText};
    }
    .list-wrap {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .card-wrap {
      width: calc(25% - 24px);
      padding: 0 20px 0 20px;
      margin-top: 40px;
      background-color: #F2F2F2;
      position: relative;
      @media (max-width: 768px) {
        width: calc(50% - 12px);
      }
      @media (max-width: 768px) {
        width: 100%;
      }
      .li-wrap {
        width: 80px;
        height: 80px;
        margin: -40px auto 20px auto;
        background-color: #079ACD;
        color: #fff;
        font-size: 32px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
      h4 {
        font-size: 20px;
        text-align: center;
        margin-bottom: 20px;
      }
      a {
        color: #079ACD;
      }
      p {
        color: ${props=>props.theme.palette.secondaryText};
        text-align: center;
        margin-bottom: 60px;
      }
      .btm-circle {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: #fff;
        position: absolute;
        left: calc(50% - 40px);
        bottom: -40px;
      }
    }
  }
  .s3-root {
    background-color: #EDF9FE;
    padding: 100px 0;
    h2 {
      font-size: 32px;
      font-weight: 700;
      line-height: 1.1em;
      margin-bottom: 20px;
    }
    h4 {
      font-size: 20px;
      font-weight: 700;
      line-height: 1.2em;
      letter-spacing: 0em;
    }
  }
  .faq-card {
    background-color: #fff;
    margin-bottom: 30px;
    .card-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px;
      cursor: pointer;
      .tog-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #F2F2F2;
        width: 36px;
        height: 36px;
        border-radius: 50%;
      }
      h4 {
        width: calc(100% - 42px);
      }
    }
    .card-content {
      padding: 12px;
    }
    h4 {
      margin: 0;
    }
    p {
      color: ${props=>props.theme.palette.secondaryText};
    }
    ul {
      color: ${props=>props.theme.palette.secondaryText};
    }
  }
  .dis-root {
    padding: 60px 0;
  }
`;

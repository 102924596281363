import React from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components'
import { varIs } from 'common/var'
import { 
  Modal, VideoPlayer
} from 'components/common'

export default function VideoModal(props){
  return (
    <Modal width={680}
      onCancel={()=>props.setModalMode(undefined)}
    >
      <Wrapper>
        <div className="modal-title-container">Recorded Video</div>
        {varIs('stream.videoType', props.data.video_type, 'zoom') ?
          <ReactPlayer
            className='react-player'
            url= {props.data.video_link}
            width='640px'
            height='400px'
            controls = {true}
          />
        : 
          <div style={{ width: 640, height: 420, marginTop: 12 }}>
            <VideoPlayer src={props.data.vimeo_record_id} />
          </div>
        }
      </Wrapper>
    </Modal>
  )
}

const Wrapper = styled.div`
  .modal-title-container {
    font-weight: normal;
    font-size: 22px;
    text-align: center;
    margin: -10px -24px 0;
    border-bottom: 1px solid #b1bbc7;
    padding-bottom: 12px;
  }
`

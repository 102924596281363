import styled from "styled-components";

export const Wrapper = styled.div`
  margin-top: 24px;
  .header-filters {
    margin: 24px 0px;
    .ant-form-item-label {
      top: 9px !important;
    }
    .ant-form-item-label-active {
      transform: translateY(-19px) scale(1) !important;
    }
    .ant-select,
    .ant-input {
      height: 40px !important;
    }
    .ant-select input {
      height: 25px !important;
    }
    .live-stream-action {
      button {
        width: 100%;
        padding: 0;
        text-align: center;
      }
    }
  }
  .header-pagination {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .payment-header {
    margin-bottom: 20px;
    span {
      font-weight: 500;
      font-size: 18px;
      position: relative;
      z-index: 99;
      background: #eff3f6;
      padding-right: 9px;
      display: inline-block;
    }
    .main-divider {
      height: 1px;
      background: #b1bbc7;
      position: relative;
      top: -22px;
    }
  }
  .subscriptions-tabs {
    display: inherit;
    padding: 0px 20px 0px 40px;
    .ant-tabs-nav {
      margin-bottom: 0px;
      margin-top: 3px;
    }
    .active-tab {
      font-weight: 600;
      font-size: 18px;
      color: #00b4ee;
      svg {
        margin-right: 15px;
      }
    }
    .inactive-tab {
      font-weight: 500;
      font-size: 18px;
      color: #7f8fa3;
      svg {
        margin-right: 15px;
      }
    }
    .ant-tabs-tab {
      padding: 12px 0 9px;
    }
    .ant-tabs-ink-bar {
      background: #00b4ee !important;
    }
  }
  .payment-cards {
    .three-dot-container {
      background: white;
      display: inline-block;
      position: absolute;
      right: 33px;
      top: 24px;
      padding: 3px 6px;
      cursor: pointer;
      .three-dot-box {
      }
    }
    .training-background {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      height: 180px;
      background-repeat: no-repeat;
      background-size: cover;
      svg {
        opacity: 0.5;
        cursor: pointer;
      }
    }
    .training-background.has-video {
      cursor: pointer;
    }
    .training-title {
      font-weight: 700;
      font-size: 18px;
      margin: 8px 0px;
      overflow: hidden;
      text-overflow: ellipsis !important;
      align-items: center;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .training-title.has-video:hover {
      color: #1b92fb;
    }
    .training-title.has-video {
      cursor: pointer;
    }
    .training-content {
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: #637381;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      min-height: 66px;
      margin-bottom: 24px;
    }
    .language-title {
      text-align: end;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      color: #1ea9ea;
    }
    .start-row {
      background: rgba(145, 158, 171, 0.16);
      border-radius: 8px;
      margin: 16px 0px 8px;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      .start-label {
        font-weight: normal;
        font-size: 14px;
        color: #637381;
      }
      .start-value {
        font-size: 11px;
        color: #7f8fa3;
        line-height: 19px;
      }
    }
  }
  .name-details-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 52px;
    .product-training {
      padding: 7px 16px;
      background: #f3f5f8;
      font-weight: normal;
      font-size: 12px;
      color: #384971;
    }
    .user-name {
      display: flex;
      align-items: center;
      gap: 8px;
      font-weight: normal;
      font-size: 14px;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
      }
      .name-position {
        span {
          font-weight: 600;
        }
        div {
          font-size: 12px;
          color: #637381;
        }
      }
    }
  }
  .copy-container {
    border-top: 1px dashed rgba(145, 158, 171, 0.24);
    padding-top: 24px;
  }
  .copy-url {
    color: #7f8fa3;
    border: 1px solid rgba(145, 158, 171, 0.24);
    padding: 8px;
    font-weight: normal;
    font-size: 14px;
    text-decoration-line: underline;
    border-radius: 8px;
    color: #000000;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: nowrap;
  }
  .no-stream {
    width: 100%;
    height: 100%;
    .no-data {
      width: 100%;
      height: 100%;
    }
  }
`;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  TablePanel,
  Spin,
  Modal,
  Button,
  OutlineBtn,
  Popconfirm,
  SuccessNotification,
} from "components/common";
import { varIs } from "common/var";
import { callPutApiWithAuth, callDeleteApiWithAuth } from "utils/api";
import DeleteIcon from "@ant-design/icons/DeleteFilled";
import EditIcon from "@ant-design/icons/EditFilled";
import CheckIcon from "@ant-design/icons/StarOutlined";
import CheckedIcon from "@ant-design/icons/StarFilled";

export default function TableView(props) {
  const myUser = useSelector((state) => state.auth.user);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(myUser.shipping_details);
  }, [myUser]);

  return (
    <TablePanel
      data={tableData}
      pagination={false}
      columns={[
        {
          title: "Name",
          key: "name",
          render: (_, record) => <span>{record.name}</span>,
        },
        {
          title: "Contact Email",
          key: "contact_email",
          dataIndex: "contact_email",
        },
        {
          title: "Contact Phone",
          key: "contact_phone",
          dataIndex: "contact_phone",
        },
        {
          title: "Contact Name",
          key: "contact_name",
          render: (_, record) => (
            <span>{`${record.contact_first_name || ""} ${
              record.contact_last_name || ""
            }`}</span>
          ),
        },
        {
          title: "Shipping Address",
          key: "shipping",
          render: (_, record) => (
            <div>
              <div>
                {`${record.shipping_address || ""} ${
                  record.shipping_address_line2 || ""
                }, ${record.shipping_city || ""}`}
              </div>
              <div>
                {`${record.shipping_state || ""}, ${
                  record.shipping_zipcode || ""
                }, ${record.shipping_country}`}
              </div>
            </div>
          ),
        },
        {
          title: "",
          key: "action",
          render: (_, record) => (
            <div className="comment-icons">
              {varIs(
                "userShippingDetail.isPrimary",
                record.is_primary,
                "yes"
              ) ? (
                <span className="actived-btn">
                  <CheckedIcon />
                </span>
              ) : (
                <ActiveBtn data={record} />
              )}
              <span
                className="edit-btn"
                onClick={() => {
                  props.setSelected(record);
                  props.setModalMode("edit");
                }}
              >
                <EditIcon />
              </span>
              <DeleteBtn data={record} />
            </div>
          ),
        },
      ]}
    />
  );
}

const ActiveBtn = (props) => {
  const dispatch = useDispatch();
  const [isUpdating, setIsUpdating] = useState(false);

  const onUpdateDone = (res) => {
    setIsUpdating(false);
    SuccessNotification(res.message);
    dispatch({ type: "auth.RELOAD" });
  };
  const onUpdateFail = () => {
    setIsUpdating(false);
  };
  const handleUpdate = () => {
    setIsUpdating(true);
    callPutApiWithAuth(
      `my_account/setting/shipping_detail/${props.data.id}/set_primary`,
      {},
      onUpdateDone,
      onUpdateFail
    );
  };

  return (
    <Popconfirm onConfirm={handleUpdate}>
      <span className="active-btn">
        {isUpdating ? <Spin spinning={true} /> : <CheckIcon />}
      </span>
    </Popconfirm>
  );
};

const DeleteBtn = (props) => {
  const dispatch = useDispatch();
  const myUser = useSelector((state) => state.auth.user);
  const sudoName = useSelector((state) => state.auth.sudo_name);
  const [isUpdating, setIsUpdating] = useState(false);
  const [needsConfirm, setNeedsConfirm] = useState(false);
  const [otherAddress, setOtherAddress] = useState(undefined);

  const onDeleteDone = (res) => {
    setIsUpdating(false);
    SuccessNotification(res.message);
    dispatch({ type: "auth.RELOAD" });
  };
  const onDeleteFail = () => {
    setIsUpdating(false);
  };
  const handleDelete = () => {
    setIsUpdating(true);
    let params = {};
    if (sudoName) {
      params = {
        ...params,
        sudo_name: sudoName,
      };
    }
    const q = Object.keys(params)
      .map((k) => k + "=" + params[k])
      .join("&");
    callDeleteApiWithAuth(
      `my_account/setting/shipping_detail/${props.data.id}?${q}`,
      onDeleteDone,
      onDeleteFail
    );
  };
  const onClickDelete = () => {
    // Validation
    /**
     * confirm another secondary address
     * will be primary address
     */
    // let otherAddress0 = null
    // myUser.shipping_details.forEach(el => {
    //   if (el.id != props.data.id) {
    //     otherAddress0 = el
    //   }
    // })
    // if (otherAddress0 &&
    //   varIs('userShippingDetail.isPrimary',
    //     props.data.is_primary, 'yes'
    //   )
    // ) {
    //   setNeedsConfirm(true)
    //   setOtherAddress(otherAddress0)
    //   return
    // }

    handleDelete();
  };

  return (
    <>
      <Popconfirm onConfirm={onClickDelete}>
        <span className="delete-btn">
          {isUpdating ? <Spin spinning={true} /> : <DeleteIcon />}
        </span>
      </Popconfirm>
      {needsConfirm ? (
        <Modal
          title={null}
          footer={
            <div className="d-flex align-items-center justify-content-end">
              <Button
                onClick={() => {
                  handleDelete();
                  setNeedsConfirm(false);
                }}
              >
                Ok
              </Button>
              <span style={{ width: 12 }} />
              <OutlineBtn onClick={() => setNeedsConfirm(false)}>
                Cancel
              </OutlineBtn>
            </div>
          }
        >
          <p>Your Primary Address will be changed to</p>
          <p>
            {otherAddress.shipping_address + "," || ""}&nbsp;
            {otherAddress.shipping_address_line2 || ""}&nbsp;
            {otherAddress.shipping_city || ""}&nbsp;
            {otherAddress.shipping_state || ""}&nbsp;
            {otherAddress.shipping_zipcode || ""}&nbsp;
            {otherAddress.shipping_country || ""}&nbsp;
          </p>
        </Modal>
      ) : (
        ""
      )}
    </>
  );
};

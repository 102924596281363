import React from "react";
import styled from "styled-components";
import { Table, Pagination, Spin, Card } from "components/common";

export default function TablePanel(props) {
  let startIndex = props.paginationParam
    ? props.paginationParam.currentPage
      ? (props.paginationParam.currentPage - 1) *
          props.paginationParam.perPage +
        1
      : 0
    : 1;
  let endIndex = props.paginationParam
    ? props.paginationParam.currentPage
      ? props.paginationParam.currentPage * props.paginationParam.perPage
      : 0
    : props.data.length;
  let total = props.paginationParam
    ? props.paginationParam.total
    : props.data.length;
  if (endIndex > total) {
    endIndex = total;
  }

  return (
    <div className="table-card">
      <Wrapper
        className={`panelRoot ${props.className ? props.className : ""}`}
      >
        <div className={`panelHead`}>
          <h4>{props.headerTabs ? props.headerTabs : props.title}</h4>
          <div className="toolbar">{props.toolbar || ""}</div>
        </div>
        <div className="table-new-wrapper">
          <div className={`tableWrapper`}>
            <Table
              columns={props.columns}
              dataSource={props.data}
              pagination={false}
              style={props.style}
              loading={{
                spinning: props.loading || false,
                indicator: <Spin spinning={true} className={`spinner`} />,
              }}
            />
          </div>
          {props.paginationParam ? (
            <div className={`tablePagination`}>
              <div className={`pagContainer`}>
                {props.data && props.data.length > 0 && (
                  <span>
                    Showing Results {startIndex}-{endIndex} of {total}
                  </span>
                )}
              </div>
              <div className={`paginationContainer`}>
                <Pagination
                  current={props.paginationParam.currentPage}
                  pageSize={props.paginationParam.perPage}
                  total={props.paginationParam.total}
                  onChange={props.onPageChange}
                  responsive={true}
                  showQuickJumper
                  showSizeChanger={false}
                  hideOnSinglePage={true}
                  size="small"
                />
              </div>
            </div>
          ) : props.data && props.data.length > 0 ? (
            <div className={`tablePagination`}>
              {props.data && props.data.length > 0 && (
                <span>
                  Showing Results {startIndex}-{endIndex} of {total}
                </span>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </Wrapper>
    </div>
  );
}
const Wrapper = styled.div`
  margin-bottom: -20px;
  .panelHead {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    padding-bottom: 24px;
    justify-content: space-between;
    align-items: center;
    h4 {
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      color: #212b36;
      margin: 0px;
    }
  }
  .table-new-wrapper {
    box-shadow: 0px 0px 2px rgb(145 158 171 / 20%),
      0px 12px 24px -4px rgb(145 158 171 / 12%);
    border-radius: 16px;
    .ant-table {
      border-radius: 16px !important;
    }
    tr {
      th:first-child {
        border-radius: 16px 0px 0px 0px !important;
      }
      th:last-child {
        border-radius: 0px 16px 0px 0px !important;
      }
      th {
        background: #f4f6f8;
        font-weight: 600;
        font-size: 13px;
        line-height: 24px;
        color: #637381;
        padding: 16px;
        border-bottom: none;
      }
      th::before {
        background: transparent !important;
      }
      td {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #212b36;
        padding: 21px 16px;
        border-bottom: none;
      }
    }
  }
  .tableWrapper {
    .ant-table {
      overflow-x: auto;
    }
    .ant-table-container {
      overflow-x: auto;
    }
    .ant-table-tbody > tr.ant-table-row:hover > td {
      background: #f4f6f8;
    }
  }
  .tablePagination {
    padding: 16px;
    border-top: 1px solid rgba(145, 158, 171, 0.24);
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 960px) {
      justify-content: center;
      flex-wrap: wrap-reverse;
    }
    .pagContainer {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      text-align: right;
      color: #212b36;
    }
  }
  .paginationContainer {
    .ant-pagination-item {
      margin-right: 0px !important;
      border: none !important;
    }
    .ant-pagination-prev {
      margin-right: 0px !important;
    }
    .ant-pagination-item-link {
      border: none !important;
    }
    .ant-pagination-item-active {
      border: 1px solid #1EA9EA !important;
    }
    li {
      margin-right: 0px !important;
      border: none !important;
    }
    button {
      margin: 0px !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

import styled from "styled-components";

export const Wrapper = styled.div`
  .container-box {
    .credit-container {
      margin-bottom: 24px;
    }
    .ant-select-selector {
      height: 40px !important;
      padding-top: 4px !important;
    }
  }
  .header {
    .title {
      font-weight: 500;
      font-size: 18px;
    }
    .date-picker {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .ant-select {
        width: 200px;
      }
    }
  }
  .user-name {
    img {
      width: 28px;
      height: 28px;
      margin-right: 7px;
    }
  }
  @media (max-width: 576px) {
    .date-picker > div {
      width: 100% !important;
    }
    .ant-select {
      width: 100% !important;
    }
  }
  @media (max-width: 1150px) {
    .table-container {
      .ant-table {
        overflow-x: scroll;
        .ant-table-container {
          width: 1000px;
        }
      }
    }
  }
  @media (max-width: 1400px) {
    .ant-table {
      overflow: auto;
      .ant-table-container {
        width: 1200px;
      }
    }
  }

  @media (max-width: 600px) {
    .credit-container {
      flex-direction: column !important;
      width: 100%;
      .title {
        width: 100% !important;
        margin-bottom: 24px;
      }
      .date-picker {
        width: 100% !important;
        div {
          width: 100% !important;
        }
      }
    }
  }
`;

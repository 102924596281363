import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  Row, Col,
} from 'components/common'
import {
  callGetApiWithAuth
} from 'utils/api'

export default function StatCard() {
  const [statData, setStatData] = useState({
    total: 0,
  })

  const onGetStatData = (res) => {
    setStatData(res.data)
  }
  const getStat = () => {
    callGetApiWithAuth(`report/ticket_report/stat`, onGetStatData)
  }

  useEffect(() => {
    getStat()
  }, [])

  return (
    <Wrapper>
      <div className={'statRoot'}>
        <p className={'title'}>
          {'Total Users Purchased Tickets'}
        </p>
        <p className={'value'}>
          {statData.total}
        </p>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .statRoot {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 8px;
    margin-bottom: 8px;
    .title {
      font-weight: normal;
      font-size: 14px;
      color: #7f8fa3;
      margin-bottom: 0;
      margin-right: 8px;
    }
    .value {
      font-weight: 600;
      font-size: 18px;
      color: #384971;
      margin-bottom: 0;
    }
  }
`

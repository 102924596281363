import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Modal,
  Row,
  Col,
  FormItem,
  Radio,
  Button,
  AutoComplete,
  Input,
  Popconfirm,
  SuccessNotification,
} from "components/common";
import { varValue, varIs } from "common/var";
import { callGetApiWithAuth, callPostApiWithAuth } from "utils/api";
import styled from "styled-components";

export default function SendModal(props) {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    receiver_type: varValue("giftTransferHistory.receiverType", "new"),
    receiver_first_name: "",
    receiver_last_name: "",
    receiver_email: "",
    receiver_phone: "",
    receiver_id: "",
    amount: "",
    message: "",
  });
  const [isUpdating, setIsUpdating] = useState(false);
  const [users, setUsers] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);

  const [active, setActive] = useState(false);

  const handleFocus = () => {
    setActive(true);
  };

  const handleBlur = (e) => {
    if (e.target.value) {
      setActive(true);
    } else {
      setActive(false);
    }
  };

  const onFormInput = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const onSearchDone = (res) => {
    setUsers(res.data);
  };
  const onSearchUser = (v) => {
    callGetApiWithAuth(
      `my_account/gift_wallet/search_receiver?uuid=${v}`,
      onSearchDone
    );
  };

  const onSelectUser = (_, option) => {
    let receiver = null;
    users.forEach((el) => {
      if (el.id === option.key) {
        receiver = el;
      }
    });
    setFormData({
      ...formData,
      receiver_id: receiver.id,
    });
  };

  const onSubmitDone = (res) => {
    setIsUpdating(false);
    SuccessNotification(res.message);
    dispatch({ type: "auth.RELOAD" });
    props.setShouldLoad(true);
    props.setModalMode(undefined);
  };
  const onSubmitFail = () => {
    setIsUpdating(false);
  };
  const handleSubmit = () => {
    // Validation
    let isValid = true;
    const errorMessages0 = [];
    if (isNaN(formData.amount) || formData.amount <= 0) {
      isValid = false;
      errorMessages0.push({
        type: "amount",
        message: "Please input amount as number",
      });
    }
    if (
      varIs("giftTransferHistory.receiverType", formData.receiver_type, "new")
    ) {
      if (!formData.receiver_first_name) {
        isValid = false;
        errorMessages0.push({
          type: "receiver_first_name",
          message: "Please input first name",
        });
      }
      if (!formData.receiver_last_name) {
        isValid = false;
        errorMessages0.push({
          type: "receiver_last_name",
          message: "Please input last name",
        });
      }
      if (!formData.receiver_phone) {
        isValid = false;
        errorMessages0.push({
          type: "receiver_phone",
          message: "Please input phone",
        });
      }
      if (!formData.receiver_email) {
        isValid = false;
        errorMessages0.push({
          type: "receiver_email",
          message: "Please input email",
        });
      }
    } else {
      if (!formData.receiver_id) {
        isValid = false;
        errorMessages0.push({
          type: "receiver_id",
          message: "Please select receiver",
        });
      }
    }
    // if (!formData.message) {
    //   isValid = false
    //   errorMessages0.push({
    //     type: 'message',
    //     message: 'Please input message'
    //   })
    // }

    setErrorMessages(errorMessages0);
    if (!isValid) return;

    const body = {
      ...formData,
      receiver_first_name: formData.receiver_first_name.trim(),
      receiver_last_name: formData.receiver_last_name.trim(),
      receiver_email: formData.receiver_email.toLowerCase().trim(),
      receiver_phone: formData.receiver_phone.trim(),
    };
    setIsUpdating(true);
    callPostApiWithAuth(
      `my_account/gift_wallet/transfer`,
      body,
      onSubmitDone,
      onSubmitFail
    );
  };

  return (
    <Modal onCancel={() => props.setModalMode(undefined)}>
      <Wrapper>
        <div className="modal-new-title">Send Gift</div>
        <Row gutter={[16, 15]}>
          <Col xs={24}>
            <div className="rece-text">Receiver Type</div>
            <FormItem label={"Receiver Type"}>
              <Radio.Group
                value={formData.receiver_type}
                onChange={(e) => onFormInput("receiver_type", e.target.value)}
              >
                <Radio
                  value={varValue("giftTransferHistory.receiverType", "new")}
                >
                  New
                </Radio>
                <Radio
                  value={varValue(
                    "giftTransferHistory.receiverType",
                    "existing"
                  )}
                >
                  Existing
                </Radio>
              </Radio.Group>
            </FormItem>
          </Col>
          {varIs(
            "giftTransferHistory.receiverType",
            formData.receiver_type,
            "new"
          ) && (
            <Col xs={24} sm={12}>
              <FormItem
                errorMessages={errorMessages.filter(
                  (el) => el.type === "receiver_first_name"
                )}
              >
                <Input
                  label={"Receiver First Name"}
                  value={formData.receiver_first_name}
                  onChange={(e) =>
                    onFormInput("receiver_first_name", e.target.value)
                  }
                />
              </FormItem>
            </Col>
          )}
          {varIs(
            "giftTransferHistory.receiverType",
            formData.receiver_type,
            "new"
          ) && (
            <Col xs={24} sm={12}>
              <FormItem
                errorMessages={errorMessages.filter(
                  (el) => el.type === "receiver_last_name"
                )}
              >
                <Input
                  label={"Receiver Last Name"}
                  value={formData.receiver_last_name}
                  onChange={(e) =>
                    onFormInput("receiver_last_name", e.target.value)
                  }
                />
              </FormItem>
            </Col>
          )}
          {varIs(
            "giftTransferHistory.receiverType",
            formData.receiver_type,
            "new"
          ) && (
            <Col xs={24} sm={12}>
              <FormItem
                errorMessages={errorMessages.filter(
                  (el) => el.type === "receiver_email"
                )}
              >
                <Input
                  label={"Receiver Email"}
                  value={formData.receiver_email}
                  onChange={(e) =>
                    onFormInput("receiver_email", e.target.value)
                  }
                />
              </FormItem>
            </Col>
          )}
          {varIs(
            "giftTransferHistory.receiverType",
            formData.receiver_type,
            "new"
          ) && (
            <Col xs={24} sm={12}>
              <FormItem
                errorMessages={errorMessages.filter(
                  (el) => el.type === "receiver_phone"
                )}
              >
                <Input
                  label={"Receiver Phone"}
                  value={formData.receiver_phone}
                  onChange={(e) =>
                    onFormInput("receiver_phone", e.target.value)
                  }
                />
              </FormItem>
            </Col>
          )}
          {varIs(
            "giftTransferHistory.receiverType",
            formData.receiver_type,
            "existing"
          ) && (
            <Col xs={24}>
              <div className="issue-input-container">
                <label
                  className={
                    active
                      ? "ant-form-item-label ant-form-item-label-active"
                      : "ant-form-item-label"
                  }
                  htmlFor="outlined-input"
                >
                  Receiver
                </label>
                <FormItem
                  errorMessages={errorMessages.filter(
                    (el) => el.type === "receiver_id"
                  )}
                >
                  <AutoComplete
                    onChange={onSearchUser}
                    options={users.map((el) => ({
                      key: el.id,
                      label: `${el.first_name} ${el.last_name}`,
                      value: `${el.first_name} ${el.last_name}`,
                    }))}
                    style={{ width: "100%" }}
                    onSelect={onSelectUser}
                    id="outlined-input"
                    bordered={false}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                </FormItem>
              </div>
            </Col>
          )}
          <Col xs={24}>
            <FormItem
              errorMessages={errorMessages.filter((el) => el.type === "amount")}
            >
              <Input
                label={"Amount"}
                type="number"
                value={formData.amount}
                onChange={(e) => onFormInput("amount", e.target.value)}
              />
            </FormItem>
          </Col>
          {/* <Col xs={24}>
          <FormItem label={'Message'}
            errorMessages={errorMessages.filter(el=>el.type==='message')}
          >
            <TextArea
              value={formData.message}
              onChange={e=>onFormInput('message', e.target.value)}
            />
          </FormItem>
        </Col> */}
        </Row>
        <div className="action-btn">
          <Button
            className="cancel-btn"
            onClick={() => props.setModalMode(undefined)}
          >
            Cancel
          </Button>
          <Popconfirm onConfirm={handleSubmit}>
            <Button loading={isUpdating} className="create-btn">
              Send
            </Button>
          </Popconfirm>
        </div>
      </Wrapper>
    </Modal>
  );
}

export const Wrapper = styled.div`
  .modal-new-title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #212b36;
    margin-bottom: 20px;
  }
  .rece-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #637381;
    margin: -5px 0px 3px;
  }
  .label {
    display: none;
  }
  .ant-input,
  .ant-select-selector {
    height: 40px !important;
  }
  .ant-form-item-label {
    top: 9px;
  }
  .ant-form-item-label-active {
    transform: translateY(-17px) scale(1) !important;
  }
  .action-btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 18px;
    .cancel-btn {
      border-radius: 8px !important;
      height: 40px;
      padding: 0px 15px;
      margin-left: 12px;
      border: 1px solid rgba(145, 158, 171, 0.32);
      background: white;
      color: black;
    }
    .create-btn {
      background: #1ea9ea;
      border-radius: 8px !important;
      height: 40px;
      padding: 0px 15px;
      margin-left: 12px;
    }
  }
  .issue-input-container {
    height: 48px;
    position: relative;
    .ant-select-selection-search-input {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #212b36;
      position: relative;
      top: 6px;
      left: 2px;
    }
    .ant-select {
      border: 1px solid rgba(145, 158, 171, 0.32);
      border-radius: 8px;
      position: relative;
      width: 100%;
    }

    .ant-select-selection-item {
      padding: 4px 0px 0px 2px;
    }

    .ant-select-selector:focus,
    .ant-select-focused .ant-select-selector {
      border-color: #1890ff;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }

    .ant-select-selector:hover {
      border-color: #40a9ff;
    }

    .ant-select-selector:not(:first-child)::before {
      opacity: 0.6;
    }

    .ant-select-selector:not(:first-child):focus::before,
    .ant-select-focused .ant-select-selector:not(:first-child)::before {
      opacity: 0.4;
    }

    .ant-form-item-label {
      position: absolute;
      top: 9px;
      left: 14px;
      transition: all 0.2s ease-out;
      pointer-events: none;
      color: #919eab;
    }

    .ant-form-item-label-active {
      transform: translateY(-20px) scale(1);
      font-weight: 400;
      font-size: 12px;
      color: #919eab;
      background: white;
      z-index: 888;
      padding: 0px 1px;
    }
    .ant-select-selector {
      height: 40px !important;
      padding-top: 6px !important;
    }
  }
`;

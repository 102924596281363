import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Alert } from "components/common";
import TableView from "./table/TableView";

export default function HoldingTankPage() {
  const myUser = useSelector((state) => state.auth.user);
  const [isPlacable, setIsPlacable] = useState(false);

  const checkPlacable = () => {
    let isPlacable = false;
    if (myUser.id == 1) {
      isPlacable = true;
    } else if (myUser.parent_id) {
      isPlacable = true;
    }
    setIsPlacable(isPlacable);
  };
  useEffect(() => {
    checkPlacable();
  }, []);

  return (
    <Wrapper>
      <Alert
        type={"info"}
        showIcon
        message={
          "Your Holding Tank is where your personally enrolled Affiliates will appear so you can assign a location in your downline. To place your Affiliates, simply enter the ID# of the person they go under, and click “place”. You have 7 days to place each Affiliate before they auto-flush to your lesser leg."
        }
      />
      <div style={{ height: 12 }} />
      <Alert
        type={"info"}
        showIcon
        message={
          "Please note that due to commission deadlines unplaced users will also automatically be flushed to the lesser leg on the first day of each month"
        }
      />
      {!isPlacable && (
        <Alert
          type={"error"}
          showIcon
          style={{ alignItems: "baseline" }}
          message={
            "Sorry! Once you have been placed by your Enroller, you may place your personally enrolled Affiliates."
          }
        />
      )}
      <TableView />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-bottom: 80px;
  .submitButton {
    background: #1ea9ea;
    border: 1px solid rgba(145, 158, 171, 0.32);
    border-radius: 8px !important;
    box-shadow: none !important;
    border: none;
  }
  .table-new-wrapper {
    .ant-table-cell {
      .content-root {
        .title {
          font-weight: 600;
        }
        .join-date {
          color: #637381;
        }
      }
    }
  }
  .panelHead {
    padding-top: 24px;
    padding-bottom: 12px;
  }
`;

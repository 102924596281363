import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment';
import { Alert, Spin } from "components/common";
import { callGetApiWithAuth } from 'utils/api'

export default function Alert2Section() {

  return (
    <Wrapper>
      <Alert
        type="info"
        message={
          <>
            Current volume in your back office is for <strong>{moment().subtract(1, 'month').format('MMMM')}</strong> volume. Your volume will display until <strong>{moment().format('MMMM')} 1st 9AM PST</strong>. After 9AM PST, the system will start displaying <strong>{moment().format('MMMM')}</strong> volume. After monthly commissions are paid, your carry over volume will be added back to your binary BV.
          </>
        }
        showIcon
        style={{ margin: "5px 0px 5px", alignItems: 'baseline' }}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .loadingRoot {
    display: flex;
    width: 100%;
    height: 200px;
    justify-content: center;
    align-items: center;
  }
`

import styled from "styled-components";

export const Wrapper = styled.div`
  box-shadow: 0px 0px 2px rgb(145 158 171 / 20%),
    0px 12px 24px -4px rgb(145 158 171 / 12%);
  border-radius: 16px;
  padding: 24px;
  .sales-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    position: relative;
    z-index: 4;
    .sales-title {
      font-weight: normal;
      font-size: 18px;
      display: flex;
      align-items: center;
      .sales-para {
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        color: #212b36;
        margin-right: 12px;
      }
      .tip-icon {
        width: 32px;
        height: 32px;
      }
    }
    .sales-date {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #637381;
    }
  }
  .sales-circle-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 24px;
    .sales-circle {
      width: 12px;
      height: 12px;
      background: #1ea9ea;
      border-radius: 50%;
    }
    .sales-circle-title {
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      color: #212b36;
      margin-left: 7px;
    }
  }
  .multi-line-container {
    position: relative;
    .loading-spin {
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(256, 256, 256, 0.8);
    }
  }
  @media (max-width: 600px) {
    .multi-line-container {
      margin-top: 0px;
    }
  }
`;

import styled from "styled-components";

export const Wrapper = styled.div`
  padding-bottom: 80px;
  .receipt-box {
    cursor: pointer;
  }
  .ant-picker {
    width: 100% !important;
    height: 40px !important;
  }
  @media (max-width: 1270px) {
    .table-container {
      .ant-table {
        overflow-x: scroll;
        .ant-table-container {
          width: 1000px;
        }
      }
    }
  }
  .order-from-badge {
    padding: 2px 8px;
    margin-left: 10px;
    background: rgba(0, 108, 156, 0.16);
    border-radius: 6px;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #006c9c;
  }
  .order-pending-badge {
    padding: 2px 8px;
    margin-left: 10px;
    background: rgba(183, 29, 24, 0.16);
    border-radius: 6px;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #b71d18;
  }
  @media (max-width: 1050px) {
    .ant-table {
      overflow: auto;
      .ant-table-container {
        width: 900px;
      }
    }
  }

  @media (max-width: 900px) {
    .panelHead {
      flex-direction: column;
      align-items: flex-start;
      h4 {
        margin-bottom: 24px;
      }
      .toolbar {
        width: 100%;
        div {
          width: 100%;
        }
        .d-flex {
          flex-direction: column;
          width: 100%;
          .ant-picker {
            width: 100% !important;
          }
          .ant-btn {
            width: 100%;
            margin-top: 24px;
          }
        }
      }
    }
  }
`;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Row, Col,
  Input, DatePicker,
  Button, Popconfirm,
  SuccessNotification,
  Alert, message,
} from "components/common";
import styled from "styled-components";
import { callPutApiWithAuth } from "utils/api";

export default function LegacyContactSubPage(props) {
  const dispatch = useDispatch();
  const myUser = useSelector((state) => state.auth.user);
  const sudoName = useSelector((state) => state.auth.sudo_name);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    birthday: "",
    relationship: "",
  });
  const [isUpdating, setIsUpdating] = useState(false);
  const [active, setActive] = useState(false);
  const handleFocus = () => {
    setActive(true);
  };

  const handleBlur = (e) => {
    setActive(e.target.value !== "");
  };

  const onFormInput = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const onUpdateDone = (res) => {
    setIsUpdating(false);
    SuccessNotification(res.message);
    dispatch({
      type: "auth.RELOAD",
    });
  };
  const onUpdateFail = () => {
    setIsUpdating(false);
  };
  const handleUpdate = () => {
    // Validation
    if (
      !formData.first_name ||
      !formData.last_name ||
      !formData.birthday ||
      !formData.relationship
    ) {
      message.error("Please input required fields");
      return;
    }

    let body = {
      ...formData,
    };
    if (sudoName) {
      body = {
        ...body,
        sudo_name: sudoName,
      };
    }
    setIsUpdating(true);
    callPutApiWithAuth(
      `my_account/setting/legacy_contact`,
      body,
      onUpdateDone,
      onUpdateFail
    );
  };

  useEffect(() => {
    if (myUser && myUser.legacy_contact) {
      setFormData({
        ...formData,
        ...myUser.legacy_contact,
      });
    }
  }, [myUser]);

  useEffect(() => {
    if (formData.birthday) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [formData.birthday]);

  return (
    <Wrapper>
      <Row gutter={[16, 16]} className="form-row">
        <Col xs={24}>
          <h2>Legacy Contact</h2>
        </Col>
        <Col xs={24}>
          <Alert
            message={
            <span>
              Please enter the information for who will own your MyDailyChoice Affiliate position if something happens to you. We will contact the legacy contact and transfer ownership to them. 
            </span>}
            type={"info"}
            showIcon
          />
        </Col>
        <Col xs={24} md={24} xxl={12}>
          <Input
            label="First name*"
            value={formData.first_name}
            onChange={(e) => onFormInput("first_name", e.target.value)}
          />
        </Col>
        <Col xs={24} md={24} xxl={12}>
          <Input
            label="Last name*"
            value={formData.last_name}
            onChange={(e) => onFormInput("last_name", e.target.value)}
          />
        </Col>
        <Col xs={24} md={24} xxl={12}>
          <div className="dob-outlined">
            <label
              className={
                active
                  ? "ant-form-item-label ant-form-item-label-active"
                  : "ant-form-item-label"
              }
              htmlFor="outlined-input"
            >
              Birthday*
            </label>
            <DatePicker
              date={formData.birthday}
              onChange={(v) => onFormInput("birthday", v)}
              className="dob-input"
              placeholder=""
              id="outlined-input"
              bordered={false}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
          </div>
        </Col>
        <Col xs={24} md={24} xxl={12}>
          <Input
            label="Relationship*"
            value={formData.relationship}
            onChange={(e) => onFormInput("relationship", e.target.value)}
          />
        </Col>
      </Row>
      <div className="form-action">
        <Popconfirm onConfirm={handleUpdate}>
          <button loading={isUpdating}>Submit</button>
        </Popconfirm>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2),
    0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  border-radius: 16px;
  padding: 24px;
  h2 {
    font-weight: 600;
    font-size: 16px;
  }
  .label-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
    .title {
      font-weight: 500;
      font-size: 22px;
    }
  }
  .form-group {
    display: flex;
    align-items: center;
    margin: 12px 0;
    label {
      width: 128px;
      font-size: 15px;
      color: #7f8fa3;
    }
    .form-input {
      width: 100%;
      svg {
        width: 13px;
      }
      .date-picker-dob {
        width: 100% !important;
      }
    }
  }
  .form-action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 24px 0px 0px 0px;
    button {
      width: auto;
      background: #1ea9ea;
      border-radius: 8px;
      border: none;
      color: white;
      height: 48px;
      padding: 0px 22px;
    }
  }
  .dob-input {
    width: 100% !important;
    height: 48px !important;
  }
  .dob-outlined {
    position: relative;
    .ant-picker {
      padding: 0px;
      .ant-picker-input {
        border: 1px solid rgba(145, 158, 171, 0.32);
        border-radius: 8px !important;
        height: 48px !important;
        padding: 0px 14px;
      }
    }
    .ant-picker-clear {
      right: 12px !important;
    }
    position: relative;
    .ant-input {
      height: 48px !important;
      border: 1px solid rgba(145, 158, 171, 0.32);
      border-radius: 8px !important;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #212b36;
      padding: 12px 14px;
    }
    .ant-input-affix-wrapper.ant-input-affix-wrapper-outlined {
      border-radius: 4px;
      position: relative;
    }

    .ant-input-affix-wrapper.ant-input-affix-wrapper-outlined
      .ant-input:not(:first-child) {
      border-radius: 4px;
      padding-top: 26px;
    }

    .ant-input-affix-wrapper.ant-input-affix-wrapper-outlined .ant-input:focus,
    .ant-input-affix-wrapper.ant-input-affix-wrapper-outlined
      .ant-input-focused {
      border-color: #1890ff;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }

    .ant-input-affix-wrapper.ant-input-affix-wrapper-outlined .ant-input:hover {
      border-color: #40a9ff;
    }

    .ant-input-affix-wrapper.ant-input-affix-wrapper-outlined
      .ant-input:not(:first-child)::placeholder {
      opacity: 0.6;
    }

    .ant-input-affix-wrapper.ant-input-affix-wrapper-outlined
      .ant-input:not(:first-child):focus::placeholder,
    .ant-input-affix-wrapper.ant-input-affix-wrapper-outlined
      .ant-input-focused::placeholder {
      opacity: 0.4;
    }

    .ant-form-item-label {
      position: absolute;
      top: 12px;
      left: 14px;
      transition: all 0.2s ease-out;
      pointer-events: none;
      color: #919eab;
    }

    .ant-form-item-label-active {
      transform: translateY(-20px) scale(1);
      font-weight: 400;
      font-size: 12px;
      color: #919eab;
      background: white;
      z-index: 4;
      padding: 0px 1px;
    }
  }
  @media (max-width: 500px) {
    .form-action{
      button{
        width: 100% !important;
      }
    }
  }
`;

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { callGetApiWithAuth } from 'utils/api';

export default function BuyPage() {
  const dispatch = useDispatch();
  const [product, setProduct] = useState(undefined)

  const onGetProduct = ({ data }) => {
    setProduct(data)
  }

  useEffect(() => {
    if (product) {
      const quantity = 1;
      dispatch({
        type: "checkout.ADD_CART",
        payload: {
          product,
          quantity,
          pay_cycle: 0,
          contained_products: [],
        },
      });
      dispatch({
        type: 'ui.SHOW_SIDE_CART'
      })
    }
  }, [product])

  useEffect(() => {
    callGetApiWithAuth(`my_account/savvi_wallet/product`, 
      onGetProduct
    )
  }, [])

  return ('')
}
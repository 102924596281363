import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { 
  TablePanel,
  StartEndDatePicker, Button 
} from 'components/common'
import { callGetApiWithAuth } from 'utils/api'
import { asDate } from 'utils/text'

export default function TelegramReportPage() {
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [paginationParam, setPaginationParam] = useState({
    currentPage: 1, total: 0, perPage: 15,
  })
  const [searchParam, setSearchParam] = useState({
    startDate: '', endDate: '',
    date_range: ''
  })

  const onGetTableData = (res) => {
    setTableData(res.data.data)
    setPaginationParam({
      ...paginationParam,
      total: res.data.total,
      currentPage: res.data.current_page,
    })
    setIsLoading(false)
  }
  const onFailTableData = () => {
    setIsLoading(false)
  }
  const loadTableData = (paginationParam0, searchParam0) => {
    const param = {
      ...searchParam0,
      page: paginationParam0.currentPage,
      per_page: paginationParam0.perPage
    }
    const q = Object.keys(param).map(k=>k+'='+param[k]).join('&')
    setIsLoading(true)
    callGetApiWithAuth(`report/telegram_report/list?${q}`, 
      onGetTableData, onFailTableData
    )
  }

  const onPageChange = (page) => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: page
    }
    loadTableData(paginationParam0, searchParam)
  }

  const onDateRange = (v) => {
    if (v) {
      setSearchParam({ 
        ...searchParam,
        startDate: v[0],
        endDate: v[1],
        date_range: `${moment(v[0]).format('YYYY-MM-DD')}|${moment(v[1]).format('YYYY-MM-DD')}`
      })
    } else {
      setSearchParam({ 
        ...searchParam,
        startDate: '',
        endDate: '',
        date_range: ''
      })
    }
  };

  const handleSearch = () => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: 1
    }
    loadTableData(paginationParam0, searchParam)
  }

  useEffect(() => {
    loadTableData(paginationParam, searchParam)
  }, [])

  return (
    <Wrapper>
      <TablePanel
        title={'Telegram Report'}
        data={tableData}
        loading={isLoading}
        paginationParam={paginationParam}
        onPageChange={onPageChange}
        toolbar={
          <div className='d-flex align-items-center'>
            <StartEndDatePicker 
              startDate={searchParam.startDate}
              endDate={searchParam.endDate}
              onChange={onDateRange}
            />
            <span style={{width: 12}} />
            <Button onClick={handleSearch}>
              Search
            </Button>
          </div>
        }
        columns={[
          {
            title: 'Date',
            key: 'date',
            render: (_, record) => (
              <span>{asDate(record.date)}</span>
            )
          },
          {
            title: 'Type',
            key: 'type',
            render: (_, record) => (
              <span>{record.type}</span>
            )
          },
          {
            title: 'Text',
            key: 'comment',
            render: (_, record) => (
              <div 
                dangerouslySetInnerHTML={{
                  __html: record.comment
                }}
              />
            )
          }
        ]}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
`

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import {
  TablePanel,
  RankBadge,
  Button,
  UserAvatar,
  CountryFlag,
} from "components/common";
import { callGetApiWithAuth } from "utils/api";
import { asDate, asVolume } from "utils/text";
import { varIs, varKey, varLabel } from "common/var";
import { BsChatDots } from "react-icons/bs";
import { LoadingOutlined } from "@ant-design/icons";
import {
  FaFacebookMessenger,
  FaTelegramPlane,
  FaWhatsapp,
} from "react-icons/fa";
import UplineBar from "./UplineBar";
import SearchBar from "./SearchBar";

export default function ListTable(props) {
  const history = useHistory();
  const myUser = useSelector((state) => state.auth.user);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [uplines, setUplines] = useState([]);
  const [isLoadingDownliner, setIsLoadingDownliner] = useState(false);
  const [clickedUser, setClickedUser] = useState([]);

  const onGetTableData = (res) => {
    setTableData(res.data.tableData);
    setUplines(res.data.uplines);
    setIsLoading(false);
  };
  const onFailTableData = () => {
    setIsLoading(false);
  };
  const loadTableData = () => {
    setIsLoading(true);
    const params = {
      user_id: props.selectedId,
    };
    const q = Object.keys(params)
      .map((k) => k + "=" + params[k])
      .join("&");
    callGetApiWithAuth(
      `my_team/binary_list/list?${q}`,
      onGetTableData,
      onFailTableData
    );
  };
  const onSuccessGetDownliner = ({ data }) => {
    setIsLoadingDownliner(false);
    if (data && data.length > 0) {
      history.push({
        pathname: "/team_chat",
        state: {
          user: data,
        },
      });
    }
  };

  const onClickChat = (user) => {
    setClickedUser(user);
    setIsLoadingDownliner(true);
    callGetApiWithAuth(
      `common/search_enroll_downline_affiliate/${user.username}`,
      onSuccessGetDownliner,
      () => {
        setIsLoadingDownliner(false);
      }
    );
  };

  useEffect(() => {
    if (myUser) {
      loadTableData();
    }
  }, [myUser, props.selectedId]);

  return (
    <>
      <TablePanel
        title={
          <SearchBar
            searchParam={props.searchParam}
            setSearchParam={props.setSearchParam}
            handleSearch={props.handleSearch}
          />
        }
        data={tableData}
        loading={isLoading}
        toolbar={<UplineBar uplines={uplines} goUser={props.goUser} />}
        columns={[
          {
            title: "ID",
            key: "uuid",
            render: (_, record) => <span>#{record.uuid}</span>,
          },
          // {
          //   title: 'Level',
          //   key: 'level',
          //   render: (_, record) => (
          //     <span>{record.level}</span>
          //   )
          // },
          {
            title: "Username",
            key: "username",
            render: (_, record) => (
              <div className="d-flex align-items-center" style={{color: "#1EA9EA",fontWeight:600}}>
                {record.has_children ? (
                  <a
                    href="javascript:void(0)"
                    onClick={() => props.goUser(record)}
                  >
                    {record.username}
                  </a>
                ) : (
                  <span>{record.username}</span>
                )}
              </div>
            ),
          },
          {
            title: "Name",
            key: "name",
            render: (_, record) => (
              <div className="d-flex align-items-center">
                <UserAvatar
                  image={record.image}
                  title={`${record.first_name} ${record.last_name}`}
                  status={varKey("user.status", record.status)}
                  type={varKey("user.type", record.type)}
                  typeLabel={varLabel("user.type", record.type)}
                  joinDate={asDate(record.created_at)}
                />
                {varIs("user.type", record.type, "affiliate") && (
                  <span className="chat-icon">
                    {isLoadingDownliner &&
                    record.id * 1 === clickedUser.id * 1 ? (
                      <LoadingOutlined />
                    ) : (
                      <BsChatDots style={{color:"#1EA9EA"}} onClick={() => onClickChat(record)} />
                    )}
                  </span>
                )}
                {record.social_messenger &&
                  record.social_messenger.facebook_enabled * 1 === 1 &&
                  record.social_messenger.facebook_link && (
                    <span className="chat-icon">
                      <FaFacebookMessenger
                        onClick={() => {
                          window.open(
                            record.social_messenger.facebook_link,
                            "_blank"
                          );
                        }}
                      />
                    </span>
                  )}
                {record.social_messenger &&
                  record.social_messenger.telegram_enabled * 1 === 1 &&
                  record.social_messenger.telegram_link && (
                    <span className="chat-icon">
                      <FaTelegramPlane
                        onClick={() => {
                          window.open(
                            record.social_messenger.telegram_link,
                            "_blank"
                          );
                        }}
                      />
                    </span>
                  )}
                {record.social_messenger &&
                  record.social_messenger.whatsapp_enabled * 1 === 1 &&
                  record.social_messenger.whatsapp_link && (
                    <span className="chat-icon">
                      <FaWhatsapp
                        onClick={() => {
                          window.open(
                            record.social_messenger.whatsapp_link,
                            "_blank"
                          );
                        }}
                      />
                    </span>
                  )}
              </div>
            ),
          },
          {
            title: "Country",
            key: "country",
            render: (_, record) => <CountryFlag country={record.country} />,
          },
          {
            title: "Enroller",
            key: "enroller",
            render: (_, record) => <span>{record.enroller?.username}</span>,
          },
          {
            title: "Parent",
            key: "parent",
            render: (_, record) => <span>{record.parent?.username}</span>,
          },
          {
            title: "Leg",
            key: "leg_position",
            render: (_, record) => (
              <span>{varLabel("user.legPosition", record.leg_position)}</span>
            ),
          },
          // {
          //   title: 'Current Rank',
          //   key: 'rank_id',
          //   render: (_, record) => (
          //     <span>
          //     {varIs('user.type', record.type, 'affiliate')?
          //       <RankBadge
          //         label={record.rank.name}
          //       />
          //     : ''}
          //     </span>
          //   )
          // },
          // {
          //   title: 'Paid Rank',
          //   key: 'pay_rank_id',
          //   render: (_, record) => (
          //     <span>
          //     {varIs('user.type', record.type, 'affiliate')?
          //       <RankBadge
          //         label={record.pay_rank.name}
          //       />
          //     : ''}
          //     </span>
          //   )
          // },
          // {
          //   title: 'Affiliates',
          //   key: 'affiliates',
          //   render: (_, record) => (
          //     <span>{record.qualification.pea}</span>
          //   )
          // },
          // {
          //   title: 'Customers',
          //   key: 'customers',
          //   render: (_, record) => (
          //     <span>{record.qualification.pep}</span>
          //   )
          // },
          {
            title: "Subscription",
            key: "subscription",
            render: (_, record) => (
              <span>
                {record.subscription_count * 1 > 0 ? (
                  <span className="subscription-yes">Yes</span>
                ) : (
                  <span className="subscription-no">No</span>
                )}
              </span>
            ),
          },
          {
            title: "PV",
            key: "pv",
            render: (_, record) => (
              <span>{asVolume(record.qualification.pv)}</span>
            ),
          },
          {
            title: "Left/Right Binary BV",
            key: "l_bgv",
            render: (_, record) => (
              <span>
                {asVolume(record.qualification.l_bgv)}
                &nbsp;{"/"}&nbsp;
                {asVolume(record.qualification.r_bgv)}
              </span>
            ),
          },
        ]}
      />
    </>
  );
}

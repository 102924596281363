import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from 'styled-components';
import { varKey, varLabel, varIs } from "common/var";
import { callGetApiWithAuth } from "utils/api";
import { asDate, asUsdPrice, asPrice } from "utils/text";
import { 
  TablePanel,
  Select, Button,
  Tooltip, Badge,
} from "components/common";
import CoinImg from 'assets/images/bucket/coin.svg';
import Coin2Img from 'assets/images/bucket/coin2.svg';
import BannerImg from 'assets/images/bucket/banner.png';
import Li1Icon from 'assets/images/bucket/li1.png';
import Li2Icon from 'assets/images/bucket/li2.png';
import Li3Icon from 'assets/images/bucket/li3.png';
import Li4Icon from 'assets/images/bucket/li4.png';
import Li5Icon from 'assets/images/bucket/li5.png';
import ArrowIcon from 'assets/images/bucket/arrow.svg';
import BucksImg from 'assets/images/bucket/bucks.png';
import PlusIcon from 'assets/images/bucket/plus.png';
import MinusIcon from 'assets/images/bucket/minus.png';
import Slide1Img from 'assets/images/bucket/slide1.png';
import Slide2Img from 'assets/images/bucket/slide2.svg';
import Slide3Img from 'assets/images/bucket/slide3.svg';
import Slide4Img from 'assets/images/bucket/slide4.svg';

export default function BucketWalletPage() {
  const dispatch = useDispatch();
  const myUser = useSelector(state => state.auth.user);
  const [statData, setStatData] = useState({
    amount: 0,
    consumed_amount: 0,
  })
  const [products, setProducts] = useState([])
  const [shouldLoad, setShouldLoad] = useState(false)
  const [faqs, setFaqs] = useState([])
  const [recurSku, setRecurSku] = useState('BUCK01')
  const [isPurchased, setIsPurchased] = useState(false)

  const onGetStatData = ({ data }) => {
    setStatData({
      ...statData,
      ...data,
    })
  }
  const onGetProducts = ({ data }) => {
    setProducts(data.data)
    setIsPurchased(data.is_purchased)
  }
  const onGetFaqs = ({ data }) => {
    setFaqs(data)
  }

  const handleAddCart = (product) => {
    dispatch({
      type: "checkout.ADD_CART",
      payload: {
        product: product,
        quantity: 1,
        pay_cycle: 0,
        contained_products: [],
      },
    });
    dispatch({
      type: 'ui.SHOW_SIDE_CART'
    });
  }

  const handleAddRecur = () => {
    let product = undefined;
    products.forEach(el => {
      if (el.sku === recurSku) {
        product = el;
      }
    })
    if (product) {
      dispatch({
        type: "checkout.ADD_CART",
        payload: {
          product: product,
          quantity: 1,
          pay_cycle: 30,
          contained_products: [],
        },
      });
      dispatch({
        type: 'ui.SHOW_SIDE_CART'
      });
    }
  }

  useEffect(() => {
    if (myUser) {
      callGetApiWithAuth(`my_account/bucket_wallet/stat`, onGetStatData)
      callGetApiWithAuth(`my_account/bucket_wallet/products`, onGetProducts)
    }
  }, [myUser])

  useEffect(() => {
    callGetApiWithAuth(`my_account/bucket_wallet/faqs`, onGetFaqs)
  }, [])

  return (
    <Wrapper>
      <div className='to-wrap'>
        <div className='coin-card-wrap'>
          <div className='coin-card'>
            <div className='coin-img'>
              <img src={CoinImg} />
            </div>
            <div className='price-span'>
              {asPrice(statData.amount)}
            </div>
            <div className='desc-span'>
              My Balance
            </div>
          </div>
          <div className='coin-card coin2-card'>
            <div className='coin-img'>
              <img src={Coin2Img} />
            </div>
            <div className='price-span'>
              {asUsdPrice(statData.consumed_amount)}
            </div>
            <div className='desc-span'>
              Total Spent
            </div>
          </div>
        </div>
        {products.filter(prod => 
          prod.sku === 'BUCKTC'
        ).map(prod => (
          <div className={`prod-card sku_${prod.sku}`}>
            <div className='top-img'>
              <img src={prod.image_path} />
            </div>
            <div className='price-wrap'>
              <span className={`price-span`}>
                {asUsdPrice(prod.member_price)}
              </span>
              <span className='pv-span'>
                ({prod.pv}BV)
              </span>
            </div>
            <div className='desc-wrap'>
              <div className='desc-line'>
                <img src={Li1Icon} />
                {`$5 to spend on brands`}
              </div>
              <div className='desc-line'>
                <img src={Li3Icon} />
                {`Save 5-25% on products`}
              </div>
              <div className='desc-line'>
                <img src={Li4Icon} />
                {`Deals on dining & shopping`}
              </div>
            </div>
            <div className='addcart-wrap'>
              <button onClick={() => handleAddCart(prod)}
                disabled={prod.sku==='BUCKTC' && !isPurchased}
              >
                Add to Cart
              </button>
            </div>
          </div>
        ))}
        <div className={`prod-desc-card ${products.length===0?'no-products':''}`}>
          <img src={BucksImg} />
          <h4>How do Brand Bucks work?</h4>
          <p>
            After purchasing Brand Bucks, 
            you can redeem your balance for MDC products. 
            Try one, a few, or all of our brands. 
            When you run out, you can load more. 
          </p>
          <div className='raddcart-wrap'>
            <div className='raddcart-select'>
              <Select value={recurSku}
                options={[
                  { label: '$99', value: 'BUCK04' },
                  { label: '$199', value: 'BUCK01' },
                  { label: '$399', value: 'BUCK02' },
                  { label: '$799', value: 'BUCK03' },
                ]}
                onChange={v=>setRecurSku(v)}
              />
            </div>
            <Button onClick={handleAddRecur}>
              Subscribe
            </Button>
          </div>
        </div>
        {/* <div className='banner-card'>
          <img src={BannerImg} />
        </div> */}
      </div>
      <div className={`md-wrap
        ${products.length>=4?'four-col'
        : 'no-col'}
      `}>
        {products.filter(prod => 
          prod.sku !== 'BUCKTC'
        ).map(prod => (
          <div className={`prod-card sku_${prod.sku}`}>
            <div className='top-img'>
              <img src={prod.image_path} />
            </div>
            <div className='price-wrap'>
              <span className={`price-span`}>
                {asUsdPrice(prod.member_price)}
              </span>
              <span className='pv-span'>
                ({prod.pv}BV)
              </span>
            </div>
            <div className='desc-wrap'>
              <div className='desc-line'>
                <img src={Li1Icon} />
                {prod.sku==='BUCK04'?
                  `$100 to spend on brands`
                : prod.sku==='BUCK01'?
                  `$200 to spend on brands`
                : prod.sku==='BUCK02'?
                  `$400 to spend on brands`
                : prod.sku==='BUCK03'?
                  `$800 to spend on brands`
                : prod.sku==='BUCKTC'?
                  `$5 to spend on brands`
                : ''}
              </div>
              {['BUCK01', 'BUCK02', 'BUCK03', 'BUCK04'].indexOf(prod.sku)>=0 && 
              <div className='desc-line'>
                <img src={Li2Icon} />
                {prod.sku==='BUCK04'?
                  `$100 in prepaid travel savings`
                : prod.sku==='BUCK01'?
                  `$200 in prepaid travel savings`
                : prod.sku==='BUCK02'?
                  `$400 in prepaid travel savings`
                : prod.sku==='BUCK03'?
                  `$800 in prepaid travel savings`
                : ''}
              </div>
              }
              <div className='desc-line'>
                <img src={Li3Icon} />
                {`Save 5-25% on products`}
              </div>
              {['BUCK01', 'BUCK02', 'BUCK03', 'BUCK04'].indexOf(prod.sku)>=0 && 
              <div className='desc-line'>
                <img src={Li4Icon} />
                {`Deals on dining & shopping`}
              </div>
              }
              {['BUCK01', 'BUCK02', 'BUCK03'].indexOf(prod.sku)>=0 && 
              <div className='desc-line'>
                <img src={Li5Icon} />
                {prod.sku==='BUCK01'?
                  `1 level of check matching`
                : prod.sku==='BUCK02'?
                  `2 levels of check matching`
                : prod.sku==='BUCK03'?
                  `4 levels of check matching`
                : ''}
              </div>
              }
            </div>
            <div className='addcart-wrap'>
              <button onClick={() => handleAddCart(prod)}
                disabled={prod.sku==='BUCKTC' && !isPurchased}
              >
                Add to Cart
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className='history-wrap'>
        <HistorySection 
          shouldLoad={shouldLoad}
          setShouldLoad={setShouldLoad}
        />
      </div>
      <div className='faq-wrap'>
        <h2>Frequently Asked Questions</h2>
        {faqs.map(el => (
          <FaqCard data={el} />
        ))}
      </div>
      <div className='started-wrap'>
        <h2>Get Started Today</h2>
        <div className='started-list'>
          <div className='started-card'>
            <img src={Slide1Img} style={{ width: 140 }} />
            <p>
              1. Buy Brand Bucks
            </p>
          </div>
          <div className='started-arrow'>
            <img src={ArrowIcon} />
          </div>
          <div className='started-card'>
            <img src={Slide2Img} />
            <p>
              2. Shop The Brands
            </p>
          </div>
          <div className='started-arrow'>
            <img src={ArrowIcon} />
          </div>
          <div className='started-card'>
            <img src={Slide3Img} />
            <p>
              3. Share Your Store
            </p>
          </div>
          <div className='started-arrow'>
            <img src={ArrowIcon} />
          </div>
          <div className='started-card'>
            <img src={Slide4Img} />
            <p>
              4. Earn Rewards
            </p>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const HistorySection = (props) => {
  const [tableMode, setTableMode] = useState('purchase')
  const [tableData, setTableData] = useState([])
  const [paginationParam, setPaginationParam] = useState({
    currentPage: 1,
    perPage: 15, 
    total: 0,
  })
  const [isLoading, setIsLoading] = useState(false)
  const [columns, setColumns] = useState([]);

  const onGetTableData = (res) => {
    setTableData(res.data.data);

    setPaginationParam({
      ...paginationParam,
      currentPage: res.data.current_page,
      total: res.data.total,
    });
    setIsLoading(false);
    props.setShouldLoad(false);
  };
  const onFailTableData = () => {
    setIsLoading(false);
    props.setShouldLoad(false);
  };
  const loadTableData = (paginationParam0) => {
    setIsLoading(true);
    const params = {
      page: paginationParam0.currentPage,
      per_page: paginationParam0.perPage,
    };
    const q = Object.keys(params)
      .map((k) => k + "=" + params[k])
      .join("&");
    if (tableMode === "purchase") {
      callGetApiWithAuth(
        `my_account/bucket_wallet/purchase_histories?${q}`,
        onGetTableData,
        onFailTableData
      );
    } else if (tableMode === "sent") {
      callGetApiWithAuth(
        `my_account/bucket_wallet/sent_histories?${q}`,
        onGetTableData,
        onFailTableData
      );
    } else if (tableMode === "received") {
      callGetApiWithAuth(
        `my_account/bucket_wallet/received_histories?${q}`,
        onGetTableData,
        onFailTableData
      );
    } else if (tableMode === "consume") {
      callGetApiWithAuth(
        `my_account/bucket_wallet/consume_histories?${q}`,
        onGetTableData,
        onFailTableData
      );
    }
  };

  const onPageChange = (page) => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: page,
    };
    loadTableData(paginationParam0);
  };

  const handleSearch = () => {
    loadTableData(paginationParam);
  };

  useEffect(() => {
    if (tableMode === "purchase") {
      setColumns([
        {
          title: "Date",
          key: "created_at",
          render: (_, record) => <span>{asDate(record.created_at)}</span>,
        },
        {
          title: "Product",
          key: "product",
          render: (_, record) => <span>{record.product.title}</span>,
        },
        {
          title: "Amount",
          key: "amount",
          render: (_, record) => <span>{asUsdPrice(record.amount)}</span>,
        },
        {
          title: "Description",
          key: "comment",
          render: (_, record) => (
            <Tooltip title={record.comment}>
              <span className="tooltip-description">{record.comment}</span>
            </Tooltip>
          ),
        },
      ]);
    } else if (tableMode === "sent") {
      setColumns([
        {
          title: "Date",
          key: "date",
          render: (_, record) => <span>{asDate(record.created_at)}</span>,
        },
        {
          title: "Amount",
          key: "amount",
          render: (_, record) => <span>{asUsdPrice(record.amount)}</span>,
        },
        {
          title: "Receiver",
          key: "receiver",
          render: (_, record) => (
            <span>
              {record.receiver
                ? `${record.receiver.first_name} ${record.receiver.last_name}`
                : `${record.receiver_first_name} ${record.receiver_last_name}`}
            </span>
          ),
        },
        {
          title: "Description",
          key: "s_comment",
          render: (_, record) => (
            <Tooltip title={record.s_comment}>
              <span className="tooltip-description">{record.s_comment}</span>
            </Tooltip>
          ),
        },
      ]);
    } else if (tableMode === "received") {
      setColumns([
        {
          title: "Date",
          key: "date",
          render: (_, record) => <span>{asDate(record.created_at)}</span>,
        },
        {
          title: "Amount",
          key: "amount",
          render: (_, record) => <span>{asUsdPrice(record.amount)}</span>,
        },
        {
          title: "Sender",
          key: "sender",
          render: (_, record) => (
            <span>
              {`${record.sender.first_name} ${record.sender.last_name}`}
            </span>
          ),
        },
        {
          title: "Description",
          key: "r_comment",
          render: (_, record) => (
            <Tooltip title={record.r_comment}>
              <span className="tooltip-description">{record.r_comment}</span>
            </Tooltip>
          ),
        },
      ]);
    } else if (tableMode === "consume") {
      setColumns([
        {
          title: "Date",
          key: "date",
          render: (_, record) => <span>{asDate(record.created_at)}</span>,
        },
        {
          title: "Type",
          key: "type",
          render: (_, record) => (
            <span>{varLabel("bucketConsumeHistory.type", record.type)}</span>
          ),
        },
        {
          title: "Amount",
          key: "amount",
          render: (_, record) => <span>{asUsdPrice(record.amount)}</span>,
        },
        {
          title: "Comment",
          key: "comment",
          render: (_, record) => (
            <Tooltip title={record.comment}>
              <span className="tooltip-description">{record.comment}</span>
            </Tooltip>
          ),
        },
      ]);
    }

    setTableData([]);
    const paginationParam0 = {
      ...paginationParam,
      currentPage: 1,
    };
    loadTableData(paginationParam0);
  }, [tableMode]);

  useEffect(() => {
    if (props.shouldLoad) {
      loadTableData(paginationParam);
    }
  }, [props.shouldLoad]);

  return (
    <TablePanel
      title={"History"}
      toolbar={
        <Select
          value={tableMode}
          onChange={(v) => setTableMode(v)}
          options={[
            { label: "Brand Bucks Purchases", value: "purchase" },
            // { label: "Brand Bucks Received", value: "received" },
            // { label: "Brand Bucks Sent", value: "sent" },
            { label: "Product Purchases", value: "consume" },
          ]}
          style={{ width: "240px" }}
        />
      }
      columns={columns}
      data={tableData}
      paginationParam={paginationParam}
      onPageChange={onPageChange}
      loading={isLoading}
    />
  )
}

const FaqCard = ({ data }) => {
  const [isOpened, setIsOpened] = useState(false)

  return (
    <div className='faq-card'>
      <div className='faq-title'
        onClick={()=>setIsOpened(!isOpened)}
      >
        <span className='title-span'>
          {data.title}
        </span>
        <span className='icon-span'>
          {isOpened?
            <img src={MinusIcon} alt={'Open'} />
          : <img src={PlusIcon} alt={'Close'} />}
        </span>
      </div>
      {isOpened && 
      <div className='faq-content'>
        {data.content}
      </div>
      }
    </div>
  )
}

const Wrapper = styled.div`
  .to-wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 12px;
    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
    .coin-card-wrap {
      width: calc(25% - 12px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media (max-width: 1366px) {
        width: calc(50% - 12px);
        margin-bottom: 24px;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    .coin-card {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-radius: 16px;
      background: #B7EFFB;
      height: 165px;
      @media (max-width: 1366px) {
        height: 156px;
      }
      @media (max-width: 768px) {
        margin-bottom: 12px;
      }
      &.coin2-card {
        background-color: #DFF3EA;
        .coin-img {
          background: linear-gradient(135deg, rgba(5, 115, 23, 0.00) 0%, rgba(5, 115, 23, 0.24) 97.35%);
        }
        .price-span, .desc-span {
          color: #057317;
        }
      }
      .coin-img {
        width: 64px;
        height: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border-radius: 50%;
        background: linear-gradient(135deg, rgba(30, 169, 234, 0.00) 0%, rgba(30, 169, 234, 0.24) 97.35%);
        img {
          width: 32px;
          height: 32px;
          object-fit: cover;
        }
      }
      .price-span {
        color: #054B73;
        font-family: 'Public Sans';
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 1.5em;
        margin-top: 8px;
      }
      .desc-span {
        color: #054B73;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
      }
    }
    .banner-card {
      width: calc(100% - 280px * 2 - 24px * 2);
      height: 188px;
      border-radius: 16px;
      overflow: hidden;
      margin-bottom: 24px;
      @media (max-width: 1680px) {
        width: calc(100% - 220px * 2 - 24px * 2);
      }
      @media (max-width: 1366px) {
        display: none;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: left;
      }
    }
  }
  .prod-desc-card {
    width: calc(50% - 12px);
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 24px;
    @media (max-width: 1366px) {
      margin-bottom: 24px;
      width: calc(100% - 12px);
    }
    @media (max-width: 768px) {
      width: 100%;
    }
    &.no-products {
      width: calc(75% - 12px);
    }
    img {
      max-width: 100%;
    }
    h4 {
      margin-top: 24px;
      margin-bottom: 8px;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px;
    }
    p {
      color: #637381;
    }
    .raddcart-wrap {
      width: 100%;
      .ant-btn {
        width: 100%;
        margin-top: 8px;
      }
      .ant-select-selector {
        height: 40px !important;
        .ant-select-selection-item {
          top: -2px;
        }
      }
    }
  }
  .prod-card {
    position: relative;
    width: calc(25% - 12px);
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20);
    @media (max-width: 1366px) {
      width: calc(50% - 12px);
      margin-bottom: 24px;
    }
    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 24px;
    }
    .top-img {
      width: 100%;
      height: 140px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #F6F6F6; 
      img {
        width: 160px;
        height: 100px;
        object-fit: cover;
        max-width: 100%;
      }
    }
    .price-wrap {
      margin-top: 14px;
      margin-bottom: 8px;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; 
      .price-span {
        margin-right: 4px;
        font-weight: 600;
      }
    }
    &.sku_BUCK01 {
      .price-wrap {
        .price-span {
          color: #108DF2;
        }
      }
    }
    &.sku_BUCK02 {
      .price-wrap {
        .price-span {
          color: #08B64E;
        }
      }
    }
    &.sku_BUCK03 {
      .price-wrap {
        .price-span {
          color: #D09E28;
        }
      }
    }
    .desc-wrap {
      margin: 12px 18px 64px 18px;
      .desc-line {
        display: flex;
        align-items: center;
        color: rgba(33, 43, 54, 0.60);
        font-family: Public Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.2em; 
        padding: 4px 0;
        img {
          margin-right: 4px;
          width: 16px;
          height: 16px;
          object-fit: contain;
        }
      }
    }
    .addcart-wrap {
      position: absolute;
      width: calc(100% - 36px);
      left: 18px;
      bottom: 18px;
      button {
        border-radius: 8px;
        background: #1EA9EA; 
        border: 0px none;
        color: #fff;
        width: 100%;
        height: 34px;
        font-family: Public Sans;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        &:disabled {
          background: #cfcfcf;
        }
      }
    }
  }
  .md-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    @media (max-width: 1366px) {
      flex-wrap: wrap;
    }
    &.four-col {
      .prod-desc-card {
        width: calc(100% - 280px * 4 - 24px * 4);
        @media (max-width: 1680px) {
          width: calc(100% - 220px * 4 - 24px * 4);
        }
        @media (max-width: 1366px) {
          width: 100%;
        }
      }
    }
    &.three-col {
      .prod-desc-card {
        width: calc(100% - 280px * 3 - 24px * 3);
        @media (max-width: 1680px) {
          width: calc(100% - 220px * 3 - 24px * 3);
        }
        @media (max-width: 1366px) {
          width: 100%;
        }
      }
    }
  }
  .history-wrap {
    @media (max-width: 768px) {
      margin-top: 24px;
    }
  }
  .faq-wrap {
    margin-top: 48px;
    h2 {
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px;
      margin-bottom: 18px;
    }
    .faq-card {
      border-radius: 16px;
      border: 1px solid #E5E8EB;
      background: #FFF;
      box-shadow: 0px 0px 2px 0px rgba(145, 158, 171, 0.20), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
      margin: 9px 0;
      padding: 18px;
      .faq-title {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .title-span {
        font-family: Public Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px; 
      }
      .faq-content {
        margin-top: 12px;
      }
    }
  }
  .started-wrap {
    margin: 24px 0;
    h2 {
      color: #000;
      font-family: Open Sans;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: normal; 
      text-align: center;
      margin-bottom: 24px;
    }
    .started-list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 768px) {
        flex-wrap: wrap;
        justify-content: center;
      }
      .started-card {
        border-radius: 12px;
        border: 2px solid #B0E7FF;
        background: #FFF; 
        box-shadow: 0px 2.76726px 2.21381px 0px rgba(0, 0, 0, 0.01), 0px 6.6501px 5.32008px 0px rgba(0, 0, 0, 0.015), 0px 12.52155px 10.01724px 0px rgba(0, 0, 0, 0.02), 0px 22.33631px 17.86905px 0px rgba(0, 0, 0, 0.02), 0px 41.77761px 33.42209px 0px rgba(0, 0, 0, 0.025), 0px 100px 80px 0px rgba(0, 0, 0, 0.035);
        padding: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 280px;
        @media (max-width: 768px) {
          margin-bottom: 24px;
        }
        img {
          width: 108px;
          height: 108px;
          object-fit: contain;
        }
        p {
          color: #000;
          text-align: center;
          font-family: Open Sans;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal; 
          margin-top: 24px;
        }
      }
      .started-arrow {
        @media (max-width: 768px) {
          display: none;
        }
      }
    }
  }
`

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import { Row, Col, Input, Button } from "components/common";
import { callGetApiWithAuth } from "utils/api";
import { ReactComponent as SearchOutlinedIcon } from "assets/icons/search-icon.svg";

export default function SearchBar(props) {
  const [formData, setFormData] = useState({
    uuid: "",
    rank_id: "",
    created_at_range: "",
    createdFrom: "",
    createdUntil: "",
  });
  const [ranks, setRanks] = useState([]);

  const onGetRanks = (res) => {
    setRanks(res.data);
  };

  const onFormInput = (field, value) => {
    const formData0 = {
      ...formData,
      [field]: value,
    };
    setFormData(formData0);
  };

  const onFormDate = (v) => {
    let formData0 = {
      ...formData,
      createdFrom: "",
      createdUntil: "",
      created_at_range: "",
    };
    if (v) {
      formData0 = {
        ...formData,
        createdFrom: v[0],
        createdUntil: v[1],
        created_at_range: `${moment(v[0]).format("YYYY-MM-DD")}|${moment(
          v[1]
        ).format("YYYY-MM-DD")}`,
      };
    }
    setFormData(formData0);
  };

  useEffect(() => {
    callGetApiWithAuth(`common/ranks`, onGetRanks);
  }, []);

  useEffect(() => {
    setFormData({
      ...formData,
      ...props.searchParam,
    });
  }, [props.searchParam]);

  return (
    <Wrapper>
      <Row gutter={[12, 24]} style={{ alignItems: "center" }}>
        <Col xs={24} sm={24} xl={20}>
          <Input
            value={formData.uuid}
            onChange={(e) => onFormInput("uuid", e.target.value)}
            label="Search By ID or Username"
            inputPrefix={<SearchOutlinedIcon />}
          />
        </Col>
        <Col xs={24} sm={6} xl={4}>
          <Button size="large" onClick={() => props.handleSearch(formData)}>
            Search
          </Button>
        </Col>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .input-prefix {
    top: 20% !important;
  }
  .ant-form-item-label {
    top: 10px !important;
    left: 48px !important;
    font-size: 14px !important;
  }
  .ant-form-item-label-active {
    transform: translateY(-24px) scale(1) !important;
    font-size: 12px !important;
  }
`;

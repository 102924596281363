import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import TableView from './table/TableView'
import FormView from './form/FormView'
import MaBgImg from 'assets/images/maBg.png'

export default function TelegramReportPage() {
  return (
    <Wrapper>
      <FormView />      
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding-bottom: 80px;
`
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RankBadge, Tooltip, Button } from "components/common";
import { varLabel, varKey, varIs } from "common/var";
import { callGetApiWithAuth } from "utils/api";
import { asNumber, asVolume } from "utils/text";
import { Wrapper } from "./TreeNode.styled";
import QualModal from "./QualModal";
import NoUserIcon from "assets/images/user.svg";
import { ReactComponent as ShowMoreIcon } from "assets/icons/eye.svg";
import { ReactComponent as BackIcon } from "assets/icons/back.svg";
import { BsChatDots } from "react-icons/bs";
import { LoadingOutlined } from "@ant-design/icons";
import {
  FaFacebookMessenger,
  FaTelegramPlane,
  FaWhatsapp,
} from "react-icons/fa";

export default function TreeNode({ nodeData, ...props }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const nodeRef = useRef(null);
  const previousSearchingUserIds = useSelector(
    (state) => state.binaryTree.previousSearchingUserIds
  );
  const treeType = useSelector((state) => state.binaryTree.treeType);
  const [isLoadingDownliner, setIsLoadingDownliner] = useState(false);
  const [clickedUser, setClickedUser] = useState([]);
  const [selectedQual, setSelectedQual] = useState(false);

  const openDetail = () => {
    let position_ = nodeRef.current.getBoundingClientRect();
    let detailBoxPosition = {
      top: position_.top,
      left: position_.left + 110,
    };
    dispatch({
      type: "binaryTree.OPEN_DETAIL_BOX",
      payload: { detailBoxPosition, detailData: nodeData },
    });
  };
  const closeDetail = () => {
    dispatch({ type: "binaryTree.CLOSE_DETAIL_BOX" });
  };
  const openAddDialog = () => {};
  const showMore = (user_id) => {
    dispatch({ type: "binaryTree.SEARCH", payload: { user_id } });
  };
  const searchBack = () => {
    dispatch({ type: "binaryTree.SEARCH_BACK" });
  };
  const searchBottom = (user_id, leg_position) => {
    dispatch({
      type: "binaryTree.SEARCH_BOTTOM",
      payload: { user_id, leg_position },
    });
  };

  const onSuccessGetDownliner = ({ data }) => {
    setIsLoadingDownliner(false);
    if (data && data.length > 0) {
      history.push({
        pathname: "/team_chat",
        state: {
          user: data,
        },
      });
    }
  };

  const onClickChat = (user) => {
    setClickedUser(user);
    setIsLoadingDownliner(true);
    callGetApiWithAuth(
      `common/search_enroll_downline_affiliate/${user.username}`,
      onSuccessGetDownliner,
      () => {
        setIsLoadingDownliner(false);
      }
    );
  };

  return (
    <Wrapper>
      {nodeData.is_empty ? (
        <>
          <div className={"emptyContainer"} />
          {/* <AddButton onClick={openAddDialog} /> */}
        </>
      ) : (
        <div
          className={`container ${nodeData.hasSummary ? "hasSummary" : ""} ${
            nodeData.isSpillOver ? "isSpillOver" : ""
          }`}
          ref={nodeRef}
        >
          {nodeData.level == 0 && previousSearchingUserIds.length > 0 && (
            <BackIcon
              className={"searchBackButton"}
              onClick={() => searchBack()}
            />
          )}
          <div
            className={`coloredTopBorder ${
              nodeData.type != 1 ? "coloredTopBorderForCustomer" : ""
            }`}
          ></div>
          <div className={"photoWrapper"}>
            <img src={nodeData.image || NoUserIcon} />
            <div
              className={`statusBadge ${varKey(
                "user.status",
                nodeData.status
              )}`}
            />
          </div>
          <Tooltip title={`${nodeData.first_name} ${nodeData.last_name}`}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p className={"userRow"}>
                {`${nodeData.first_name} ${nodeData.last_name}`}
              </p>
              {varIs("user.type", nodeData.type, "affiliate") && (
                <span className="chat-icon">
                  {isLoadingDownliner &&
                  nodeData.id * 1 === clickedUser.id * 1 ? (
                    <LoadingOutlined />
                  ) : (
                    <BsChatDots onClick={() => onClickChat(nodeData)} />
                  )}
                </span>
              )}
            </div>
          </Tooltip>
          <p className={"userid"}>#{nodeData.uuid}</p>
          {/* <Tooltip title={nodeData.username}>
            <p className={`userRow`}>{nodeData.username}</p>
          </Tooltip> */}

          {varIs("user.type", nodeData.type, "affiliate") &&
          treeType === "unilevel" ? (
            <p className={"userRow"}>
              <RankBadge label={nodeData.rank.name} />
            </p>
          ) : (
            <p className={"userRow no-show-rank"}>
              <RankBadge label={nodeData.rank.name} />
            </p>
          )}

          <div className="aboutInfo" onClick={openDetail}>
            Business Stats
          </div>
          {/* {nodeData.social_messenger ? (
            <div className={"socialMessenger"}>
              {nodeData.social_messenger.facebook_enabled * 1 === 1 &&
              nodeData.social_messenger.facebook_link ? (
                <span className="messengerIcon">
                  <FaFacebookMessenger
                    onClick={() => {
                      window.open(
                        nodeData.social_messenger.facebook_link,
                        "_blank"
                      );
                    }}
                  />
                </span>
              ) : (
                ""
              )}
              {nodeData.social_messenger.telegram_enabled * 1 === 1 &&
              nodeData.social_messenger.telegram_link ? (
                <span className="messengerIcon">
                  <FaTelegramPlane
                    onClick={() => {
                      window.open(
                        nodeData.social_messenger.telegram_link,
                        "_blank"
                      );
                    }}
                  />
                </span>
              ) : (
                ""
              )}
              {nodeData.social_messenger.whatsapp_enabled * 1 === 1 &&
              nodeData.social_messenger.whatsapp_link ? (
                <span className="messengerIcon">
                  <FaWhatsapp
                    onClick={() => {
                      window.open(
                        nodeData.social_messenger.whatsapp_link,
                        "_blank"
                      );
                    }}
                  />
                </span>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )} */}
          {nodeData.hasSummary && (
            <div className={"summarySection"}>
              <p className={"summaryRow"}>
                <span>Active</span>
                <span>{asNumber(nodeData.actives)}</span>
              </p>
              <p className={"summaryRow"}>
                <span>Inactive</span>
                <span>{asNumber(nodeData.inactives)}</span>
              </p>
              <p className={"summaryRow"}>
                <span>GV</span>
                <span>{asVolume(nodeData.qualification.gv)}</span>
              </p>
            </div>
          )}
          {varIs("user.type", nodeData.type, "affiliate") &&
          treeType == "unilevel" ? (
            <p className={"qualRow"} onClick={() => setSelectedQual(nodeData)}>
              Rank Qualification
            </p>
          ) : (
            <p className={"qualRow no-rank-qualification"} onClick={() => setSelectedQual(nodeData)}>
              Rank Qualification
            </p>
          )}
          {treeType == "binary" && (
            <div className="d-flex justify-content-between bottomLinkRoot">
              {nodeData.children &&
              nodeData.children.filter((el) => el.leg_position * 1 === 1)
                .length > 0 ? (
                <span
                  className="bottomLink"
                  onClick={() => searchBottom(nodeData.id, 1)}
                >
                  Bottom Left
                </span>
              ) : (
                <span>&nbsp;</span>
              )}
              &nbsp;&nbsp;&nbsp;&nbsp;
              {nodeData.children &&
              nodeData.children.filter((el) => el.leg_position * 1 === 2)
                .length > 0 ? (
                <span
                  className="bottomLink"
                  onClick={() => searchBottom(nodeData.id, 2)}
                >
                  Bottom Right
                </span>
              ) : (
                <span>&nbsp;</span>
              )}
            </div>
          )}
          {/*
        ((!nodeData.children || nodeData.children.length==0) && !nodeData.hasChildren) && 
          <AddButton onClick={openAddDialog} />
        */}
          {!nodeData.children && nodeData.hasChildren && (
            <ShowMoreIcon
              className={"showMoreButton"}
              onClick={() => showMore(nodeData.id)}
            />
          )}
        </div>
      )}
      {selectedQual && (
        <QualModal
          selectedQual={selectedQual}
          setSelectedQual={setSelectedQual}
        />
      )}
    </Wrapper>
  );
}

import styled from "styled-components";

export const Wrapper = styled.div`
  .main-container {
    padding-bottom: 60px;
    .cards-list {
      .ant-col {
        height: 100%;
        .card {
          height: 100%;
          border: none;
        }
      }
    }
  }
  .header-search {
    margin-bottom: 24px;
    .ant-select {
      width: 100%;
      height: 40px !important;
    }
    .marketing-title {
      margin-top: 10px;
      color: #212b36;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Button, Tooltip } from "components/common";
import { varIs } from "common/var";
import { ReactComponent as FilledIcon } from "assets/icons/filled-info.svg";

export default function SendBtnSection(props) {
  const myUser = useSelector((state) => state.auth.user);

  return (
    <Wrapper>
      <Button
        size="large"
        bgColor={"#FF5630"}
        onClick={() => {
          if (myUser && !varIs("user.status", myUser.status, "hold_temp")) {
            props.setModalMode("grant");
          }
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          Send Travel Card&nbsp;
          <Tooltip
            title={
              "Send a $100 travel savings card to a new prospect or existing users. New prospects must create a free account to accept their gift. If they do not accept, you can cancel it below and your card will be returned to your balance. Existing users will automatically receive $100 of prepaid savings in their booking engine."
            }
            overlayStyle={{
              maxWidth: 300,
            }}
            placement="topRight"
          >
            <FilledIcon />
          </Tooltip>
        </div>
      </Button>
      <div style={{ height: "24px" }} />
      <Button
        bgColor={"#FF5630"}
        size="large"
        onClick={() => {
          if (myUser && !varIs("user.status", myUser.status, "hold_temp")) {
            props.setModalMode("send");
          }
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          Transfer Cards&nbsp;
          <Tooltip
            title={
              "Transfer cards to Affiliates in your team. Use it as an incentive or promotion to reward your team. Sending them cards allows them to prospect more people for High Life Travel!"
            }
            overlayStyle={{
              maxWidth: 300,
            }}
            placement="topRight"
          >
            <FilledIcon />
          </Tooltip>
        </div>
      </Button>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  button {
    width: 100%;
    border: none;
    svg {
      margin-left: 5px;
    }
  }
`;

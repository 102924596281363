import React, { useState } from "react";
import styled from "styled-components";
import SearchBar from "./SearchBar";
import ListTable from "./ListTable";
import SearchedTable from "./SearchedTable";
import StatCard from "./StatCard";

export default function TableView() {
  const [searchParam, setSearchParam] = useState({
    uuid: "",
    rank_id: "",
    pay_rank_id: "",
    created_at_range: "",
    ticket_status: "",
  });
  const [tableMode, setTableMode] = useState("list"); // list/searched
  const [selectedId, setSelectedId] = useState("");

  const goUser = (user) => {
    setTableMode("list");
    setSelectedId(user.id);
  };

  const handleSearch = (searchParam0) => {
    setTableMode("searched");
    setSearchParam(searchParam0);
  };

  return (
    <Wrapper>
      <SearchBar
        searchParam={searchParam}
        setSearchParam={setSearchParam}
        handleSearch={handleSearch}
      />
      {tableMode === "list" && (
        <ListTable selectedId={selectedId} goUser={goUser} />
      )}
      {tableMode === "searched" && (
        <SearchedTable searchParam={searchParam} goUser={goUser} />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media (max-width: 1100px) {
    table {
      width: 900px;
    }
  }
  .chat-icon {
    margin-left: 12px;
    margin-top: -5px;
    cursor: pointer;
    color: ${(props) => props.theme.palette.link};
    font-size: 16px;
  }

  .ant-picker {
    width: 100% !important;
    height: 40px !important;
  }
  .input-prefix {
    svg {
      width: 15px;
      height: 15px;
      margin-bottom: 5px;
    }
  }
  .ant-input {
    height: 40px !important;
  }
  .ant-select-selector {
    height: 40px !important;
    width: 180px !important;
    padding-top: 4px !important;
  }
  .ant-select {
    height: 40px !important;
    color: #212b36;
  }
  button {
    width: 100%;
  }
`;

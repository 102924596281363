import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { 
  Row, Col, 
  Button, Input, Select, FormItem,
  Popconfirm, SuccessNotification, Divider
} from "components/common";
import { countryStates, statesByCountry } from "common/country";
import { callPostApiWithAuth } from 'utils/api';
import { Wrapper } from "./TaxVerificationPage.styled";
import { varIs, varOptions } from "common/var";

export default function TaxVerificationPage() {
  const myUser = useSelector((state) => state.auth.user);
  const history = useHistory();
  const [isUpdating, setIsUpdating] = useState(false);
  const [formData, setFormData] = useState({
    type: 1,
    first_name: '',
    middle_name: '',
    last_name: '',
    business_name: '',
    ssn_tax_id: '',
    phone: '',
    email: '',
    address: '',
    address_line2: '',
    state: '',
    city: '',
    zipcode: ''
  });
  const [errors, setErrors] = useState({
    type: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    business_name: '',
    ssn_tax_id: '',
    phone: '',
    email: '',
    address: '',
    address_line2: '',
    state: '',
    city: '',
    zipcode: ''
  });

  const validateFormData = () => {
    let errors0 = {
      type: '',
      first_name: '',
      middle_name: '',
      last_name: '',
      business_name: '',
      ssn_tax_id: '',
      phone: '',
      email: '',
      address: '',
      address_line2: '',
      state: '',
      city: '',
      zipcode: ''
    };
    
    if (!formData.ssn_tax_id.trim()) {
      errors0['ssn_tax_id'] = "Please input SSN /Tax ID";
    }
    
    if (!formData.type) {
      errors0['type'] = "Please select type";
    }

    if (varIs("userTaxDetail.type", formData.type, "individual")) {
      if (!formData.first_name.trim()) {
        errors0['first_name'] = "Please enter your first name";
      }

      if (!formData.last_name.trim()) {
        errors0['last_name'] = "Please enter your last name";
      }
    } else if (varIs("userTaxDetail.type", formData.type, "business")) {
      if (!formData.business_name.trim()) {
        errors0['business_name'] = "Please enter business name";
      }
    }

    let emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i 

    if (!formData.email.trim()) {
      errors0['email'] = "Please enter your email";
    } else if (emailRegex.exec(formData.email) == null) {
      errors0['email'] = "Please enter valid email";
    }

    if (!formData.phone.trim()) {
      errors0['phone'] = "Please enter your phone number";
    }

    if (!formData.address.trim()) {
      errors0['address'] = "Please enter address";
    }

    if (!formData.city.trim()) {
      errors0['city'] = "Please enter city";
    }

    if (!formData.zipcode.trim()) {
      errors0['zipcode'] = "Please enter zip code";
    }

    if (!formData.state) {
      errors0['state'] = "Please select state";
    }

    if (!formData.country) {
      errors0['country'] = "Please select country";
    }

    if (
      errors0.ssn_tax_id ||
      errors0.type ||
      errors0.first_name ||
      errors0.last_name ||
      errors0.business_name ||
      errors0.email ||
      errors0.phone ||
      errors0.address ||
      errors0.city ||
      errors0.zipcode ||
      errors0.state ||
      errors0.country
    )  {
      setErrors(errors0);
      return false;
    } else {
      setErrors(errors0)
      return true;
    }
  }

  const onSuccessUploaded = () => {
    setIsUpdating(false);
    SuccessNotification("Tax information has been uploaded successfully.");
    setTimeout(() => {
      history.push("/logout");
    }, 1500);
  };

  const onFailedUpload = (err) => {
    setIsUpdating(false);
  }

  const handleSubmit = () => {
    if (!validateFormData()) {
      return;
    }
    let requestData = new FormData();
    Object.keys(formData).map((key) => {
      requestData.append(key, formData[key]);
    });
    setIsUpdating(true);
    callPostApiWithAuth("tax_form/upload", requestData, onSuccessUploaded, onFailedUpload);
  };

  useEffect(() => {
    if (myUser) {
      setFormData({
        ...formData,
        country: myUser.country
      });
    }
  }, [myUser]);

  return (
    <Wrapper>
      <div className="tax-section">
        <h5 className="title">Add Tax Information</h5>
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={8}>
            <div className="input-container">
              <FormItem label={"SSN / Tax ID*"}
                errorMessages={[{ message: errors.ssn_tax_id }]}
              >
                <Input placeholder="Enter SSN / Tax ID"
                  value={formData.ssn_tax_id}
                  onChange={e=>setFormData({...formData, ssn_tax_id:e.target.value})}
                />
              </FormItem>
            </div>
          </Col>
          <Col xs={24} sm={8}>
            <div className="input-container">
              <FormItem label={"Type*"}
                errorMessages={[{ message: errors.type }]}
              >
                <Select
                  value={formData.type}
                  options={varOptions("userTaxDetail.type")}
                  style={{ width: "100%" }}
                  onChange={v=>setFormData({...formData, type:v})}
                />
              </FormItem>
            </div>
          </Col>
        </Row>
        <Divider className="divider" />
        {varIs("userTaxDetail.type", formData.type, "individual") ?
          <Row gutter={[20, 20]}>
            <Col xs={24} sm={8}>
              <div className="input-container">
                <FormItem label={"First Name*"}
                  errorMessages={[{ message: errors.first_name }]}
                >
                  <Input placeholder="Enter First Name" 
                    value={formData.first_name}
                    onChange={e=>setFormData({...formData, first_name:e.target.value})}
                  />
                </FormItem>
              </div>
            </Col>{" "}
            <Col xs={24} sm={8}>
              <div className="input-container">
                <FormItem label={"Middle Name"}>
                  <Input placeholder="Enter Middle Name" 
                    value={formData.middle_name}
                    onChange={e=>setFormData({...formData, middle_name:e.target.value})}
                  />
                </FormItem>
              </div>
            </Col>{" "}
            <Col xs={24} sm={8}>
              <div className="input-container">
                <FormItem label={"Last Name*"}
                  errorMessages={[{ message: errors.last_name }]}
                >
                  <Input placeholder="Enter Last Name" 
                    value={formData.last_name}
                    onChange={e=>setFormData({...formData, last_name:e.target.value})}
                  />
                </FormItem>
              </div>
            </Col>{" "}
          </Row>
          :
          <Row gutter={[20, 20]}>
            <Col xs={24} sm={8}>
              <div className="input-container">
                <FormItem label={"Business Name*"}
                  errorMessages={[{ message: errors.business_name }]}
                >
                  <Input placeholder="Enter Business Name" 
                    value={formData.business_name}
                    onChange={e=>setFormData({...formData, business_name:e.target.value})}
                  />
                </FormItem>
              </div>
            </Col>{" "}
          </Row>
        }
        <Divider className="divider" />
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={8}>
            <div className="input-container">
              <FormItem label={"Email Address*"}
                errorMessages={[{ message: errors.email }]}
              >
                <Input placeholder="Enter Email Address" 
                  value={formData.email}
                  onChange={e=>setFormData({...formData, email:e.target.value})}
                />
              </FormItem>
            </div>
          </Col>{" "}
          <Col xs={24} sm={8}>
            <div className="input-container">
              <FormItem label={"Phone Number*"}
                errorMessages={[{ message: errors.phone }]}
              >
                <Input placeholder="Enter Phone Number" 
                  value={formData.phone}
                  onChange={e=>setFormData({...formData, phone:e.target.value})}
                />
              </FormItem>
            </div>
          </Col>{" "}
        </Row>
        <Divider className="divider" />
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={12}>
            <div className="input-container">
              <FormItem label={"Address Line 1*"}
                errorMessages={[{ message: errors.address }]}
              >
                <Input placeholder="Enter Address Line 1" 
                  value={formData.address}
                  onChange={e=>setFormData({...formData, address:e.target.value})}
                />
              </FormItem>
            </div>
          </Col>{" "}
          <Col xs={24} sm={12}>
            <div className="input-container">
              <FormItem label={"Address Line 2"}>
                <Input placeholder="Enter Address Line 2" 
                  value={formData.address_line2}
                  onChange={e=>setFormData({...formData, address_line2:e.target.value})}
                />
              </FormItem>
            </div>
          </Col>{" "}
          <Col xs={24} sm={12}>
            <div className="input-container">
              <FormItem label={"City*"}
                errorMessages={[{ message: errors.city }]}
              >
                <Input placeholder="Enter City" 
                  value={formData.city}
                  onChange={e=>setFormData({...formData, city:e.target.value})}
                />
              </FormItem>
            </div>
          </Col>{" "}
          <Col xs={24} sm={12}>
            <div className="input-container">
              <FormItem label={"Zip/Postal code*"}
                errorMessages={[{ message: errors.zipcode }]}
              >
                <Input placeholder="Enter Zip/Postal code"
                  value={formData.zipcode}
                  onChange={e=>setFormData({...formData, zipcode:e.target.value})}
                />
              </FormItem>
            </div>
          </Col>{" "}
          <Col xs={24} sm={12}>
            <div className="input-container">
              <FormItem label={"Country*"}
                errorMessages={[{ message: errors.country }]}
              >
                <Select
                  value={formData.country}
                  options={[
                    { 
                      label: 'Select Country', 
                      value: ''
                    },
                    ...countryStates.map(el => ({
                      label: el.name,
                      value: el.code2
                    }))
                  ]}
                  style={{ width: "100%" }}
                  onChange={v=>setFormData({...formData, country:v})}
                  disabled
                />
              </FormItem>
            </div>
          </Col>{" "}
          <Col xs={24} sm={12}>
            <div className="input-container">
              <FormItem label={"State/Province*"}
                errorMessages={[{ message: errors.state }]}
              >
                <Select
                  value={formData.state}
                  options={[
                    { 
                      label: 'Select State', 
                      value: ''
                    },
                    ...statesByCountry(formData.country).map(el => ({
                      label: el.name,
                      value: el.name
                    }))
                  ]}
                  style={{ width: "100%" }}
                  onChange={v=>setFormData({...formData, state:v})}
                  showSearch
                />
              </FormItem>
            </div>
          </Col>{" "}
          <Col span={24}>
            <div className="action-btn">
              <Popconfirm onConfirm={handleSubmit} disabled={isUpdating}>
                <Button loading={isUpdating} disabled={isUpdating}>
                  Submit
                </Button>
              </Popconfirm>
            </div>
          </Col>
        </Row>
      </div>
    </Wrapper>
  )
}
import React from "react"
import { Row, Col } from "components/common"
import TableView from "./table/TableView"
import { Wrapper } from "./MyTicketPage.styled"

export default function MyTicketPage() {
  return (
    <Wrapper>
      <div className="main-container">
        <Row gutter={[20, 20]}>
          <Col xs={24}>
            <TableView />
          </Col>
        </Row>
      </div>
    </Wrapper>
  );
};
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Row, Col, GuagePlot } from "components/common";
import { asUsdPrice, asNumber } from "utils/text";
import { callGetApiWithAuth } from "utils/api";
import NoPhotoIcon from "assets/images/user.svg";
import { ReactComponent as SettingIcon } from "assets/icons/home/settings-icon.svg";
import AccountDetailsSectionSkeleton from "./AccountDetailsSection.skeleton";
import { Wrapper } from "./AccountDetailsSection.styled";
import { ReactComponent as AccountIcon } from "assets/icons/home/account-icon.svg";
import { ReactComponent as BRankIcon } from "assets/icons/home/b-rank.svg";
import { ReactComponent as CRankIcon } from "assets/icons/home/c-rank.svg";
import { ReactComponent as CvRankIcon } from "assets/icons/home/cv-rank.svg";
import { ReactComponent as PaidIcon } from "assets/icons/home/paid-rank.svg";
import { ReactComponent as PaymentIcon } from "assets/icons/home/payment-icon.svg";
import { ReactComponent as PcRankIcon } from "assets/icons/home/pc-rank.svg";
import { ReactComponent as ProfileIcon } from "assets/icons/home/profile-icon.svg";
import { ReactComponent as PvRankIcon } from "assets/icons/home/pv-rank.svg";
import { ReactComponent as SubscribeIcon } from "assets/icons/home/subscribe-icon.svg";
import { ReactComponent as AffIcon } from "assets/icons/home/total-aff.svg";
import { ReactComponent as RecurIcon } from "assets/icons/home/recur.svg";
import { ReactComponent as PiggyIcon } from "assets/icons/home/piggy.svg";

export default function AccountDetailsSection() {
  const myUser = useSelector((state) => state.auth.user);
  const [isQualified, setIsQualified] = useState(false);
  const [profileStatus, setProfileStatus] = useState({
    accountComplete: 0,
    payoutComplete: 0,
    profileComplete: 0,
    subscriptionComplete: 0,
    total: 0,
    totalEarning: 0,
  });
  const [isLoadingProfileStatus, setIsLoadingProfileStatus] = useState(false);

  const onGetSuccessProfileStatus = ({ data }) => {
    setIsLoadingProfileStatus(false);
    setProfileStatus({
      accountComplete: data.accountComplete,
      payoutComplete: data.payoutComplete,
      profileComplete: data.profileComplete,
      subscriptionComplete: data.subscriptionComplete,
      total: data.total,
      totalEarning: data.totalEarning,
    });
  };

  const onGetFailedProfileStatus = (err) => {
    setIsLoadingProfileStatus(false);
  };

  const loadProfileStatus = () => {
    setIsLoadingProfileStatus(true);
    callGetApiWithAuth(
      "home/get_profile_status",
      onGetSuccessProfileStatus,
      onGetFailedProfileStatus
    );
  };

  useEffect(() => {
    loadProfileStatus();
  }, []);

  useEffect(() => {
    if (
      (myUser.qualification.pv >= 40 || myUser.qualification.pecv >= 80) &&
      myUser.qualification.l_bpev >= 90 &&
      myUser.qualification.r_bpev >= 90 &&
      myUser.qualification.l_bgv * 1 + myUser.qualification.l_carry * 1 >=
        300 &&
      myUser.qualification.r_bgv * 1 + myUser.qualification.r_carry * 1 >= 300
    ) {
      setIsQualified(true);
    } else {
      setIsQualified(false);
    }
  }, [myUser]);

  return (
    <Wrapper>
      {isLoadingProfileStatus ? (
        <AccountDetailsSectionSkeleton isLoading={isLoadingProfileStatus} />
      ) : (
        <Row gutter={[10, 10]}>
          <Col xs={24}>
            <div className="profile-p-box">
              <div className="profile-title">Profile</div>
              <Link to="/my_account/setting">
                <SettingIcon />
              </Link>
            </div>
          </Col>
          <Col xs={24} xl={12} className="left-details-container">
            <div className="left-box">
              <div className="personal-details-container">
                <img src={myUser.image || NoPhotoIcon} />
                <div className="user-title">
                  {myUser.first_name} {myUser.last_name}
                </div>
                <div className="user-id">
                  #{myUser.uuid}
                  &nbsp;|&nbsp;
                  {myUser.username}
                </div>
              </div>

              <div className="rank-plus-section">
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={12}>
                    <div className="rank-plus-box">
                      <div className="rank-plus-flex">
                        <div className="rank-plus-title">Current Rank</div>
                        <div className="rank-plus-value">
                          {myUser.rank.name}
                        </div>
                      </div>
                      <CRankIcon />
                    </div>
                  </Col>
                  <Col xs={24} sm={12}>
                    <div className="rank-plus-box">
                      <div className="rank-plus-flex">
                        <div className="rank-plus-title">Paid Rank</div>
                        <div className="rank-plus-value">
                          {myUser.pay_rank.name}
                        </div>
                      </div>
                      <PaidIcon />
                    </div>
                  </Col>
                  <Col xs={24} sm={12}>
                    <div className="rank-plus-box">
                      <div className="rank-plus-flex">
                        <div className="rank-plus-title">Active Users</div>
                        <div className="rank-plus-value">
                          {asNumber(
                            myUser.qualification.l_tp*1+
                            myUser.qualification.r_tp*1+
                            myUser.qualification.l_ta*1+
                            myUser.qualification.r_ta*1
                          )}
                        </div>
                      </div>
                      <BRankIcon />
                    </div>
                  </Col>
                  <Col xs={24} sm={12}>
                    <div className="rank-plus-box">
                      <div className="rank-plus-flex">
                        <div className="rank-plus-title">Enroller Tree Volume</div>
                        <div className="rank-plus-value yellow">
                          {asNumber(myUser.qualification.gv)}
                        </div>
                      </div>
                      <AffIcon />
                    </div>
                  </Col>
                  <Col xs={24} sm={12}>
                    <div className="rank-plus-box">
                      <div className="rank-plus-flex">
                        <div className="rank-plus-title">Subscriptions</div>
                        <div className="rank-plus-value green">
                          {asNumber(
                            myUser.qualification.l_auto*1+
                            myUser.qualification.r_auto*1
                          )}
                        </div>
                      </div>
                      <RecurIcon />
                    </div>
                  </Col>
                  <Col xs={24} sm={12}>
                    <div className="rank-plus-box">
                      <div className="rank-plus-flex">
                        <div className="rank-plus-title">Commissions</div>
                        <div className="rank-plus-value green">
                          {asUsdPrice(profileStatus.totalEarning)}
                        </div>
                      </div>
                      <PiggyIcon />
                    </div>
                  </Col>
                  {/* <Col xs={24} sm={12}>
                    <div className="rank-plus-box">
                      <div className="rank-plus-flex">
                        <div className="rank-plus-title">Binary Qualified</div>
                        <div className="rank-plus-value">
                          {isQualified ? (
                            <span className="binary-qual active">Yes</span>
                          ) : (
                            <span className="binary-qual inactive">No</span>
                          )}
                        </div>
                      </div>
                      <BRankIcon />
                    </div>
                  </Col>
                  <Col xs={24} sm={12}>
                    <div className="rank-plus-box">
                      <div className="rank-plus-flex">
                        <div className="rank-plus-title">Personal Volume</div>
                        <div className="rank-plus-value">
                          ({asVolume(myUser.qualification.pv)}/
                          {asVolume(myUser.next_rank.settings.pv)})
                        </div>
                      </div>
                      <PvRankIcon />
                    </div>
                  </Col>
                  <Col xs={24} sm={12}>
                    <div className="rank-plus-box">
                      <div className="rank-plus-flex">
                        <div className="rank-plus-title">
                          Personal Customer Vol.
                        </div>
                        <div className="rank-plus-value">
                          {asVolume(myUser.qualification.pecv)}
                        </div>
                      </div>
                      <PcRankIcon />
                    </div>
                  </Col>
                  <Col xs={24} sm={12}>
                    <div className="rank-plus-box">
                      <div className="rank-plus-flex">
                        <div className="rank-plus-title">
                          Enroller Tree Volume
                        </div>
                        <div className="rank-plus-value">
                          {asVolume(myUser.qualification.gv)}
                        </div>
                      </div>
                      <CvRankIcon />
                    </div>
                  </Col> */}
                </Row>
              </div>
            </div>
          </Col>
          <Col xs={24} xl={12} className="right-details-container">
            <div>
              <Row gutter={[15, 10]} className="plot-container">
                <Col span={24}>
                  <div className="iquid-container">
                    <GuagePlot percent={profileStatus.total} />
                  </div>
                </Col>
              </Row>
              <div className="right-box">
                <div className="account-and-profile-container">
                  <div className="account-section">
                    <div className="account-c-section">
                      <AccountIcon />
                      <div>
                        <div className="title title-mbl">
                          <span>Account Details</span>
                          <div className="percentage mbl-percentage">
                            {profileStatus.accountComplete ? (
                              <span className="completed">Completed</span>
                            ) : (
                              <span className="incompleted">Incomplete</span>
                            )}
                          </div>
                        </div>
                        <div className="profile-section">
                          <div className="title">
                            Complete all of your account details and settings
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="percentage web-percentage">
                      {profileStatus.accountComplete ? (
                        <span className="completed">Completed</span>
                      ) : (
                        <span className="incompleted">Incomplete</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="account-and-profile-container">
                  <div className="account-section">
                    <div className="account-c-section">
                      <ProfileIcon />
                      <div>
                        <div className="title title-mbl">
                          <span>Your Profile</span>
                          <div className="percentage mbl-percentage">
                            {profileStatus.profileComplete ? (
                              <span className="completed">Completed</span>
                            ) : (
                              <span className="incompleted">Incomplete</span>
                            )}
                          </div>
                        </div>
                        <div className="profile-section">
                          <div className="title">
                            Upload a profile image and complete your profile
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="percentage web-percentage">
                      {profileStatus.profileComplete ? (
                        <span className="completed">Completed</span>
                      ) : (
                        <span className="incompleted">Incomplete</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="account-and-profile-container">
                  <div className="account-section">
                    <div className="account-c-section">
                      <PaymentIcon />
                      <div>
                        <div className="title title-mbl">
                          <span>Setup Payment Option</span>
                          <div className="percentage mbl-percentage">
                            {profileStatus.payoutComplete ? (
                              <span className="completed">Completed</span>
                            ) : (
                              <span className="incompleted">Incomplete</span>
                            )}
                          </div>
                        </div>
                        <div className="profile-section">
                          <div className="title">
                            Set your payment option to receive your commissions
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="percentage web-percentage">
                      {profileStatus.payoutComplete ? (
                        <span className="completed">Completed</span>
                      ) : (
                        <span className="incompleted">Incomplete</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="account-and-profile-container">
                  <div className="account-section">
                    <div className="account-c-section">
                      <SubscribeIcon />
                      <div>
                        <div className="title title-mbl">
                          <span>Subscribe To Save</span>
                          <div className="percentage mbl-percentage">
                            {profileStatus.subscriptionComplete ? (
                              <span className="completed">Completed</span>
                            ) : (
                              <span className="incompleted">Incomplete</span>
                            )}
                          </div>
                        </div>
                        <div className="profile-section">
                          <div className="title">
                            Maximize your savings with a subscription
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="percentage web-percentage">
                      {profileStatus.subscriptionComplete ? (
                        <span className="completed">Completed</span>
                      ) : (
                        <span className="incompleted">Incomplete</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </Wrapper>
  );
}

import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { Button } from "components/common";

export default function GroupsCard({ data }) {
  const history = useHistory();
  const handleOnClick = () => {
    history.push(`/training/watchList/${data?.id}`, { params: data });
  };
  return (
    <Wrapper>
      <div className="group-card-container">
        <div>
          <div
            className="card-background"
            style={{ backgroundImage: `url(${data?.image})` }}
          ></div>
          <div className="card-title">{data?.title}</div>
          <div className="card-content">{data?.description}</div>
        </div>
        <div className="watch-now-container">
          <Button onClick={handleOnClick}>Learn</Button>
        </div>
      </div>
    </Wrapper>
  );
}

export const Wrapper = styled.div`
  height: 100%;
  .group-card-container {
    background: white;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2),
      0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
    padding: 24px;
    .watch-now-container {
      padding-top: 24px;
      border-top: 1px dashed rgba(145, 158, 171, 0.24);
      button {
        width: 100%;
      }
    }
    .card-background {
      height: 180px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 8px;
    }
    .card-title {
      margin: 10px 0px 13px;
      overflow: hidden;
      white-space: nowrap !important;
      text-overflow: ellipsis;
      font-weight: 600;
      font-size: 16px;
      margin: 24px 0px 0px;
      color: #212b36;
      text-align: center;
    }
    .card-content {
      font-size: 14px;
      line-height: 22px;
      color: #637381;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      max-height: 64px;
      margin-bottom: 24px;
      text-align: center;
    }
  }
`;

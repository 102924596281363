import React, { useState } from "react";
import styled from "styled-components";
import ProductList from "./product/ProductList";
import HistorySection from "./history/HistorySection";
import BalanceSection from "./sidebar/BalanceSection";
import SendBtnSection from "./sidebar/SendBtnSection";
import SendModal from "./sidebar/SendModal";
import TipSection from "./sidebar/TipSection";
import GiftFaqsSection from "./faqs/GiftFaqsSection";
import { Row, Col } from "components/common";
import GiftIcon from "assets/icons/gift-card/gift-banner.png";
import GiftMblIcon from "assets/icons/gift-card/gift-banner-mbl.png";

export default function GiftWalletPage() {
  const [shouldLoad, setShouldLoad] = useState(false);
  const [modalMode, setModalMode] = useState(undefined);

  return (
    <Wrapper>
      <Row gutter={[24, 24]}>
        <Col xs={24} xl={7}>
          <BalanceSection />
        </Col>
        <Col xs={24} xl={17}>
          <img src={GiftIcon} className="gift-banner" />
          <img src={GiftMblIcon} className="gift-banner-mbl" />
        </Col>
        <Col xs={24} xl={17}>
          <div>
            <Row gutter={[24, 0]}>
              <Col xs={24}>
                <ProductList />
              </Col>
              <Col xs={24}>
                <HistorySection
                  shouldLoad={shouldLoad}
                  setShouldLoad={setShouldLoad}
                />
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs={24} xl={7}>
          <TipSection setModalMode={setModalMode} />
        </Col>
        <Col xs={24}>
          <GiftFaqsSection />
        </Col>
      </Row>
      {modalMode === "send" && (
        <SendModal setModalMode={setModalMode} setShouldLoad={setShouldLoad} />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-bottom: 80px;
  .gift-banner {
    width: 100%;
    border-radius: 16px;
    height: 100%;
    object-fit: cover;
    object-position: left;
  }
  .gift-banner-mbl {
    display: none;
  }
  .top-section {
    display: flex;
    justify-content: space-between;
  }
  .main-section {
    width: calc(100% - 360px);
    display: flex;
    flex-direction: column;
    .history-section {
      height: 100%;
      .cardRoot {
        height: 100%;
      }
    }
  }
  .side-section {
    width: 336px;
    display: flex;
    flex-direction: column;
    .tip-section {
      height: 100%;
      .cardRoot {
        height: 100%;
      }
    }
  }
  .gift-wallet-faqs {
    margin-top: 24px;
  }
  @media only screen and (max-width: 768px) {
    .top-section {
      flex-direction: column;
    }
    .main-section {
      width: 100%;
    }
    .side-section {
      width: 100%;
      margin-top: 24px;
    }
  }
  @media (max-width: 1050px) {
    .ant-table {
      overflow: auto;
      .ant-table-container {
        width: 900px;
      }
    }
  }
  @media (max-width: 600px) {
    .gift-banner {
      display: none !important;
    }
    .gift-banner-mbl {
      width: 100%;
    }
  }
  @media (max-width: 900px) {
    .panelHead {
      flex-direction: column;
      align-items: flex-start;
      h4 {
        margin-bottom: 24px;
      }
      .toolbar {
        width: 100%;
        .ant-select {
          width: 100% !important;
        }
      }
    }
  }
`;

import styled from "styled-components";

export const Wrapper = styled.div`
  .stats-container {
    background: white;
    box-shadow: 0px 0px 2px rgb(145 158 171 / 20%),
      0px 12px 24px -4px rgb(145 158 171 / 12%);
    border-radius: 17.0698px;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .stats-label {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #212b36;
      margin-bottom: 4px;
    }
    .stats-value {
      font-weight: 700;
      font-size: 32px;
      line-height: 48px;
    }
    svg {
      color: #1EA9EA;
      fill: #1EA9EA;
      &.red {
        color: #EA1ED6;
        fill: #EA1ED6;
      }
      &.yellow {
        color: #EAA51E;
        fill: #EAA51E;
      }
      &.green {
        color: #27B204;
        fill: #27B204;
      }
    }
  }

  .loading-icon {
    color: #00b2ff;
  }

  @media (max-width: 1400px) {
    .responsive-stats {
      flex: 0 0 50% !important;
      max-width: 50% !important;
    }
  }

  @media (max-width: 600px) {
    .responsive-stats {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
  }
`;

import { Col, Row } from "antd";
import React from "react";
import Slider from "react-slick";
import styled from "styled-components";

export default function SelectBannersSection({
  banners,
  setFormData,
  formData,
}) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <Wrapper>
      <div className="banner-slider">
        <Slider {...settings}>
          <div className="slider-1" key={1}>
            <Row gutter={[16, 16]}>
              {banners.map(
                (el, index) =>
                  index < 6 && (
                    <Col xs={8} md={8} key={index}>
                      <img
                        src={el.image}
                        className="slider-img"
                        onClick={() =>
                          setFormData({ ...formData, banner: el.image })
                        }
                      />
                    </Col>
                  )
              )}
            </Row>
          </div>
          <div className="slider-2" key={2}>
            <Row gutter={[16, 16]}>
              {banners.map(
                (el, index) =>
                  index >= 6 && (
                    <Col xs={12} md={8} key={index}>
                      <img
                        src={el.image}
                        className="slider-img"
                        onClick={() =>
                          setFormData({ ...formData, banner: el.image })
                        }
                      />
                    </Col>
                  )
              )}
            </Row>
          </div>
        </Slider>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 24px;
  .banner-slider {
    .slider-img {
      width: 100%;
      cursor: pointer;
      border-radius: 8px;
      height: 140px;
      object-fit: cover;
    }
  }
  @media (max-width: 600px) {
    .banner-slider {
      .slider-img {
        height: 100px;
      }
    }
  }

  @media (max-width: 500px) {
    .banner-slider {
      .slider-img {
        height: 80px;
      }
    }
  }
`;

import styled from "styled-components";

export const Wrapper = styled.div`
  padding-bottom: 80px;
  .main-container {
    padding-bottom: 20px;
  }
  .table-card {
    margin-top: 20px;
  }
`;

import React, { useState } from "react";
import { Alert } from "components/common";
import SettingSection from "./setting/SettingSection";
// import OldAkashxNotification from './setting/OldAkashxNotification'
import FailedSection from "./failed/FailedSection";
import PendingSection from "./pending/PendingSection";
import styled from "styled-components";

export default function CurrentSubscriptionSubPage() {
  const [shouldLoad, setShouldLoad] = useState(true);

  return (
    <Wrapper>
      <div className="alert-container-section">
        <div className="alert-section">
          <Alert
            type={"info"}
            showIcon
            style={{ alignItems: "baseline", marginBottom: 12 }}
            message={
              "Your primary shipping address is used for your subscriptions and one-time purchases. If you have active subscriptions, you cannot delete your primary shipping address or change the primary shipping country. You may change your secondary shipping address at any time."
            }
          />
        </div>
      </div>
      {/* <OldAkashxNotification /> */}
      <SettingSection setShouldLoad={setShouldLoad} />
      <PendingSection shouldLoad={shouldLoad} setShouldLoad={setShouldLoad} />
      <FailedSection shouldLoad={shouldLoad} setShouldLoad={setShouldLoad} />
    </Wrapper>
  );
}
const Wrapper = styled.div`
  .alert-container-section {
    margin-bottom: -3px;
  }
`;

const initState = {
  products: [],
  isStreamReady: false,
  isStreamStart: false,
  isPermissionDenied: true,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initState, action) => {
  switch (action.type) {
    case "SET_IS_STREAM_START":
      return {
        ...state,
        isStreamStart: action.payload.isStreamStart,
      };
    case "SET_IS_PERMISSION_DENIED":
      return {
        ...state,
        isPermissionDenied: action.payload.isPermissionDenied,
      };
    case "SET_STREAM_PRODUCTS":
      return {
        ...state,
        products: action.payload.products,
      };
    case "SET_IS_STREAM_READY":
      return {
        ...state,
        isStreamReady: action.payload.isStreamReady,
      };

    default:
      return state;
  }
};

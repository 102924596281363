import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Row, Col,
  Alert, message,
  SuccessNotification,
  Input, Radio, Select,
  Popconfirm, Button,
} from "components/common";
import { callPutApiWithAuth } from "utils/api";
import { countryStates, statesByCountry } from "common/country";

export default function TwBatchOptionCard({
  isActive,
  provider,
  loadListData,
}) {
  const sudoName = useSelector((state) => state.auth.sudo_name);
  const myUser = useSelector((state) => state.auth.user);
  const [formData, setFormData] = useState({
    account_number: "",
    routing_number: "",
    bank_name: "",
    name: "",
    id_no: "",
    country: "",
    state: "",
    city: "",
    zipcode: "",
    address: "",
    address_line2: "",
  });
  const [isUpdating, setIsUpdating] = useState(false);

  const onFormInput = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const onSaveDone = () => {
    setIsUpdating(false);
    SuccessNotification("Taiwan Payout has been set as your payout provider.");
    loadListData();
  };

  const handleSave = () => {
    // check validation
    if (
      !formData.account_number ||
      !formData.routing_number ||
      !formData.name ||
      !formData.id_no ||
      !formData.phone ||
      !formData.country ||
      !formData.state ||
      !formData.city ||
      !formData.address ||
      !formData.zipcode
    ) {
      message.error("Input all required fields");
      return;
    }

    setIsUpdating(true);
    const body = sudoName
      ? {
          form_info: {
            ...formData,
            sudo_name: sudoName,
          },
        }
      : {
          form_info: formData,
        };
    callPutApiWithAuth(
      `my_account/payment_option/${provider.id}/activate`,
      body,
      onSaveDone,
      () => setIsUpdating(false)
    );
  };

  useEffect(() => {
    if (provider && myUser) {
      if (provider.form_info) {
        setFormData({
          ...formData,
          account_number: provider.form_info.account_number,
          routing_number: provider.form_info.routing_number,
          bank_name: provider.form_info.bank_name,
          name: provider.form_info.name,
          phone: provider.form_info.phone,
          id_no: provider.form_info.id_no || "",
          country: provider.form_info.country || "",
          state: provider.form_info.state || "",
          city: provider.form_info.city || "",
          address: provider.form_info.address || "",
          address_line2: provider.form_info.address_line2 || "",
          zipcode: provider.form_info.zipcode || "",
        });
      } else {
        if (myUser.shipping_details.length > 0) {
          const shippingDetail = myUser.shipping_details[0];
          setFormData({
            ...formData,
            phone: myUser.phone,
            country: shippingDetail.shipping_country || "",
            state: shippingDetail.shipping_state || "",
            city: shippingDetail.shipping_city || "",
            address: shippingDetail.shipping_address || "",
            address_line2: shippingDetail.shipping_address_line2 || "",
            zipcode: shippingDetail.shipping_zipcode || "",
          });
        } else if (myUser.billing_details.length > 0) {
          const billingDetail = myUser.billing_details[0];
          setFormData({
            ...formData,
            phone: myUser.phone,
            country: billingDetail.billing_country || "",
            state: billingDetail.billing_state || "",
            city: billingDetail.billing_city || "",
            address: billingDetail.billing_address || "",
            address_line2: billingDetail.billing_address_line2 || "",
            zipcode: billingDetail.billing_zipcode || "",
          });
        }
      }
    }
  }, [provider, myUser]);

  return (
    <div className="payment-card-section">
      <div className="payment-title">
        <span>Direct Deposit - Taiwan Only</span>
        {isActive && <span className="active-badge">ACTIVE</span>}
      </div>
      <div className="payment-form-text" style={{ marginBottom: 10 }}>
        Please provide your bank account information.
      </div>
      <div className="payment-form">
        <Row gutter={[12, 8]}>
          <Col xs={24} sm={8}>
            <div className="form-group">
              <Input
                label="Account Number*"
                value={formData.account_number}
                onChange={(e) => onFormInput("account_number", e.target.value)}
              />
            </div>
          </Col>
          <Col xs={24} sm={8}>
            <div className="form-group">
              <Input
                label="Bank Name"
                value={formData.bank_name}
                onChange={(e) => onFormInput("bank_name", e.target.value)}
              />
            </div>
          </Col>
          <Col xs={24} sm={8}>
            <div className="form-group">
              <Input
                label="Routing Number*"
                value={formData.routing_number}
                onChange={(e) => onFormInput("routing_number", e.target.value)}
              />
            </div>
          </Col>
          <Col xs={24} sm={8}>
            <div className="form-group">
              <Input
                label="Name on Account*"
                value={formData.name}
                onChange={(e) => onFormInput("name", e.target.value)}
              />
            </div>
          </Col>
          <Col xs={24} sm={8}>
            <div className="form-group">
              <Input
                label="ID No*"
                value={formData.id_no}
                onChange={(e) => onFormInput("id_no", e.target.value)}
              />
            </div>
          </Col>
          <Col xs={24} sm={8}>
            <div className="form-group">
              <Input
                label="Phone*"
                value={formData.phone}
                onChange={(e) => onFormInput("phone", e.target.value)}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={[12, 8]}>
          <Col xs={24}>
            <div className="payment-form-text" style={{ marginBottom: 10 }}>
              Your Address
            </div>
          </Col>
          <Col xs={24} sm={8}>
            <div className="form-group">
              <Select
                label="Country*"
                value={formData.country}
                onChange={(v) => onFormInput("country", v)}
                options={countryStates.map((el) => ({
                  label: el.name,
                  value: el.code2,
                }))}
              />
            </div>
          </Col>
          <Col xs={24} sm={8}>
            <div className="form-group">
              <Select
                label="State*"
                value={formData.state}
                onChange={(v) => onFormInput("state", v)}
                options={statesByCountry(formData.country).map((el) => ({
                  label: el.name,
                  value: el.name,
                }))}
              />
            </div>
          </Col>
          <Col xs={24} sm={8}>
            <div className="form-group">
              <Input
                label="City*"
                value={formData.city}
                onChange={(e) => onFormInput("city", e.target.value)}
              />
            </div>
          </Col>
          <Col xs={24} sm={8}>
            <div className="form-group">
              <Input
                label="Address*"
                value={formData.address}
                onChange={(e) => onFormInput("address", e.target.value)}
              />
            </div>
          </Col>
          <Col xs={24} sm={8}>
            <div className="form-group">
              <Input
                label="Address 2"
                value={formData.address_line2}
                onChange={(e) => onFormInput("address_line2", e.target.value)}
              />
            </div>
          </Col>
          <Col xs={24} sm={8}>
            <div className="form-group">
              <Input
                label="Postal Code*"
                value={formData.zipcode}
                onChange={(e) => onFormInput("zipcode", e.target.value)}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col xs={24}>
            <div style={{ height: 24 }} />
            <div className="action-group">
              <Popconfirm onConfirm={handleSave}>
                <button loading={isUpdating}>
                  {provider.account ? `Activate` : `Save & Activate`}
                </button>
              </Popconfirm>
            </div>
          </Col>
        </Row>
      </div>
      <div className="alert-container-section">
        <div className="alert-section">
          <Alert
            style={{ margin: "5px 0px 5px", alignItems: "baseline" }}
            message={`Please note: Make sure all of your information is ACCURATE. If you put in the wrong checking account number or routing number, the payment could be sent to the wrong bank account and we will not be able to recover your funds.`}
            type={"info"}
            showIcon
          />{" "}
        </div>
        <div className="alert-section">
          <Alert
            style={{ margin: "5px 0px 5px", alignItems: "baseline" }}
            message={
              "If you have selected direct deposit, it could take 2-3 business days for you to receive your earnings depending on your bank."
            }
            type={"info"}
            showIcon
          />
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { 
  Row, Col, Modal, 
  FormItem, Input, Select, 
  Popconfirm, Button,
  SuccessNotification,
} from 'components/common'
import { countryStates, statesByCountry } from 'common/country'
import { 
  callPostApiWithAuth,  
} from 'utils/api'

export default function AmtSignupModal(props) {
  const dispatch = useDispatch()
  const myUser = useSelector(state => state.auth.user)
  const [formData, setFormData] = useState({
    first_name: '', last_name: '',
    email: '', phone: '',
    country: 'US', state: '', 
    city: '', address: ''
  })
  const [errorMessages, setErrorMessages] = useState([])
  const [isUpdating, setIsUpdating] = useState(false)

  const onUpdateDone = (res) => {
    SuccessNotification(res.message)
    setIsUpdating(false)
    dispatch({
      type: 'auth.RELOAD'
    })
    props.setIsOpenedAmtSignup(false)
  }
  const onUpdateFail = () => {
    setIsUpdating(false)
  }
  const handleSubmit = () => {
    // Validation
    let errorMessages0 = []
    if (!formData.first_name) {
      errorMessages0.push({
        type: 'first_name',
        message: '* Required field'
      })
    }
    if (!formData.last_name) {
      errorMessages0.push({
        type: 'last_name', 
        message: '* Required field'
      })
    }
    if (!formData.email) {
      errorMessages0.push({
        type: 'email',
        message: '* Required field'
      })
    }
    if (!formData.phone) {
      errorMessages0.push({
        type: 'phone',
        message: '* Required field'
      })
    }
    if (!formData.country) {
      errorMessages0.push({
        type: 'country',
        message: '* Required field'
      })
    }
    if (!formData.state) {
      errorMessages0.push({
        type: 'state',
        message: '* Required field'
      })
    }
    if (!formData.city) {
      errorMessages0.push({
        type: 'city',
        message: '* Required field'
      })
    }
    if (!formData.address) {
      errorMessages0.push({
        type: 'address',
        message: '* Required field'
      })
    }
    setErrorMessages(errorMessages0)
    if (errorMessages0.length>0) {
      return
    }

    const body = {
      form_info: formData
    }
    setIsUpdating(true)
    callPostApiWithAuth('hlt/create_amt_account', body, 
      onUpdateDone, onUpdateFail
    )
  }

  useEffect(() => {
    if (myUser) {
      setFormData({
        ...formData,
        first_name: myUser.first_name,
        last_name: myUser.last_name,
        email: myUser.email,
        phone: myUser.phone,
        country: myUser.country,
      })
    }
  }, [myUser])

  return (
    <Modal
      onCancel={() => props.setIsOpenedAmtSignup(false)}
      title={'Create HighLifeTravel Account'}
    >
      <Wrapper>
        <Row gutter={[8, 8]}>
          <Col xs={24} sm={12}>
            <FormItem label={'First Name *'}
              errorMessages={errorMessages.filter(el => 
                el.type==='first_name'
              )}
            >
              <Input value={formData.first_name}
                onChange={e=>setFormData({
                  ...formData, 
                  first_name: e.target.value
                })}
              />
            </FormItem>
          </Col>
          <Col xs={24} sm={12}>
            <FormItem label={'Last Name *'}
              errorMessages={errorMessages.filter(el => 
                el.type==='last_name'
              )}
            >
              <Input value={formData.last_name}
                onChange={e=>setFormData({
                  ...formData, 
                  last_name: e.target.value
                })}
              />
            </FormItem>
          </Col>
          <Col xs={24} sm={12}>
            <FormItem label={'Email *'}
              errorMessages={errorMessages.filter(el => 
                el.type==='email'
              )}
            >
              <Input value={formData.email}
                onChange={e=>setFormData({
                  ...formData, 
                  email: e.target.value
                })}
              />
            </FormItem>
          </Col>
          <Col xs={24} sm={12}>
            <FormItem label={'Phone *'}
              errorMessages={errorMessages.filter(el => 
                el.type==='phone'
              )}
            >
              <Input value={formData.phone}
                onChange={e=>setFormData({
                  ...formData, 
                  phone: e.target.value
                })}
              />
            </FormItem>
          </Col>
          <Col xs={24} sm={12}>
            <FormItem label={'Country *'}
              errorMessages={errorMessages.filter(el => 
                el.type==='country'
              )}
            >
              <Select value={formData.country}
                onChange={v=>setFormData({
                  ...formData, 
                  country: v
                })}
                options={countryStates.map(el => ({
                  label: el.name,
                  value: el.code2
                }))}
              />
            </FormItem>
          </Col>
          <Col xs={24} sm={12}>
            <FormItem label={'State *'}
              errorMessages={errorMessages.filter(el => 
                el.type==='state'
              )}
            >
              <Select value={formData.state}
                onChange={v=>setFormData({
                  ...formData, 
                  state: v
                })}
                options={statesByCountry(formData.country).map(el => ({
                  label: el.name,
                  value: el.name
                }))}
              />
            </FormItem>
          </Col>
          <Col xs={24} sm={12}>
            <FormItem label={'City *'}
              errorMessages={errorMessages.filter(el => 
                el.type==='city'
              )}
            >
              <Input value={formData.city}
                onChange={e=>setFormData({
                  ...formData, 
                  city: e.target.value
                })}
              />
            </FormItem>
          </Col>
          <Col xs={24} sm={12}>
            <FormItem label={'Address *'}
              errorMessages={errorMessages.filter(el => 
                el.type==='address'
              )}
            >
              <Input value={formData.address}
                onChange={e=>setFormData({
                  ...formData, 
                  address: e.target.value
                })}
              />
            </FormItem>
          </Col>
          <Col xs={24} sm={12}>
            <Popconfirm onConfirm={handleSubmit}>
              <Button loading={isUpdating}>
                Create Account
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      </Wrapper>
    </Modal>
  )
}

const Wrapper = styled.div`
  .ant-select {
    width: 100%;
  }
`

import React, { useEffect, useState } from "react";
import { CometChat } from "@cometchat-pro/chat";
import { useSelector } from "react-redux";
import { CometChatConversationListWithMessages } from "views/cometchat-pro-react-ui-kit/CometChatWorkspace/src";
import { Wrapper } from "./SupportChatPage.styled";

export default function IndexPage() {
  const isCometChatInitiliazed = useSelector(
    (state) => state.ui.isCometChatInitiliazed
  );
  const [chatWithUser, setChatWithUser] = useState("");
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    if (isCometChatInitiliazed) {
      let limit = 30;
      let conversationsRequest = new CometChat.ConversationsRequestBuilder()
        .setLimit(limit)
        .build();

      conversationsRequest.fetchNext().then(
        (conversationList) => {
          if (conversationList?.length > 0) {
            setChatWithUser(conversationList[0]?.conversationWith?.uid);
          }
        },
        (error) => {}
      );
    }
  }, [isCometChatInitiliazed]);

  useEffect(() => {
    function handleResize() {
      setHeight(window.innerHeight);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Wrapper
      style={{
        height: `calc(${height}px - 68px)`,
      }}
    >
      <div className="main-container">
        {isCometChatInitiliazed && (
          <CometChatConversationListWithMessages chatWithUser={chatWithUser} />
        )}
      </div>
    </Wrapper>
  );
}

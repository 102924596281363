import styled from "styled-components";

export const Wrapper  = styled.div`
  padding-bottom: 80px;
  .tax-section {
    width: 100%;
    padding: 50px;
    background-color: #ffffff;
  }
  .tax-section h5 {
    margin-bottom: 20px;
  }
`
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Row, Col, Pagination,
  Select, Input, Button,
  Spin, NoData,
} from "components/common";
import { varIs, varOptions } from "common/var";
import { uiVarOptions } from "common/uiVar";
import { callGetApiWithAuth } from "utils/api";
import CreateModal from "./CreateModal";
import StreamCard from "./StreamCard";
import { Wrapper } from "./MyStreamSubPage.styled";

export default function MyStreamSubPage() {
  const [streams, setStreams] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalMode, setModalMode] = useState(undefined);
  const [searchParam, setSearchParam] = useState({
    keyword: "",
    topic: "",
    lang: "",
    status: "",
  });
  const [paginationParam, setPaginationParam] = useState({
    currentPage: 1,
    perPage: 12,
    total: 0,
  });

  const onGetStreams = ({ data }) => {
    setIsLoading(false);
    setStreams(data.data);
    setPaginationParam({
      ...paginationParam,
      total: data.total,
    });
  };
  const onFailStreams = () => {
    setIsLoading(false);
  };
  const handleSearch = () => {
    const params = {
      ...searchParam,
      page: paginationParam.currentPage,
      per_page: paginationParam.perPage,
    };
    const query = Object.keys(params)
      .map((k) => k + "=" + params[k])
      .join("&");

    setIsLoading(true);
    callGetApiWithAuth(
      `live_stream/list?${query}`,
      onGetStreams,
      onFailStreams
    );
  };

  const onPageChange = (page, pageSize) => {
    setPaginationParam({
      ...paginationParam,
      currentPage: page,
      perPage: pageSize,
    });
  };

  useEffect(() => {
    handleSearch();
  }, [searchParam, paginationParam.currentPage, paginationParam.perPage]);

  return (
    <Wrapper>
      <div className="header-filters">
        <Row gutter={[24, 24]}>
          <HeaderFilter
            searchParam={searchParam}
            setSearchParam={setSearchParam}
            setModalMode={setModalMode}
          />
        </Row>
      </div>
      {isLoading ? (
        <Row gutter={[20, 20]}>
          <Col xs={24}>
            <Spin spinning={true} />
          </Col>
        </Row>
      ) : (
        <Row gutter={[20, 20]}>
          {streams.length === 0 ? (
            <div className="no-stream">
              <NoData title="There are no items." />
            </div>
          ) : (
            streams?.map((el, index) => (
              <Col key={index}
                xs={24} md={12} xl={8} xxl={6}
                className="responsive-payment-cards"
              >
                <StreamCard data={el} handleSearch={handleSearch} />
              </Col>
            ))
          )}
        </Row>
      )}
      {streams.length > 0 && (
        <div className="header-pagination">
          <Row gutter={[20, 20]}>
            <Col xs={24}>
              <Pagination
                current={paginationParam.currentPage}
                pageSize={paginationParam.perPage}
                total={paginationParam.total}
                onChange={onPageChange}
                defaultPageSize="12"
                responsive
                showQuickJumper
                showSizeChanger
              />
            </Col>
          </Row>
        </div>
      )}

      {modalMode === "create" && (
        <CreateModal setModalMode={setModalMode} handleSearch={handleSearch} />
      )}
    </Wrapper>
  );
}

const HeaderFilter = (props) => {
  const myUser = useSelector((state) => state.auth.user);
  const [formData, setFormData] = useState(props.searchParam);

  const onChangeSearchParam = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
    props.setSearchParam({
      ...props.searchParam,
      [field]: value,
    });
  };

  return (
    <>
      <Col xs={24} sm={8} xl={5}>
        <Input
          label="Title"
          value={formData.keyword}
          onChange={(e) => onChangeSearchParam("keyword", e.target.value)}
        />
      </Col>
      <Col xs={24} sm={8} xl={5}>
        <Select
          label="All Topics"
          value={formData.topic}
          options={[
            {
              label: "All Topics",
              value: "",
              key: "",
            },
            ...uiVarOptions("liveStream.topics"),
          ]}
          onChange={(value) => onChangeSearchParam("topic", value)}
          style={{ width: "100%" }}
        />
      </Col>
      <Col xs={24} sm={8} xl={5}>
        <Select
          label="All Languages"
          value={formData.lang}
          options={[
            {
              label: "All Languages",
              value: "",
              key: "",
            },
            ...uiVarOptions("liveStream.languages"),
          ]}
          onChange={(value) => onChangeSearchParam("lang", value)}
          style={{ width: "100%" }}
        />
      </Col>
      <Col xs={24} sm={8} xl={5}>
        <Select
          label="All Statuses"
          value={formData.status}
          options={[
            { label: "All Statuses", value: "" },
            ...varOptions("stream.status"),
          ]}
          onChange={(value) => onChangeSearchParam("status", value)}
          style={{ width: "100%" }}
        />
      </Col>
      {varIs("user.isStreamCreatable", myUser.is_stream_creatable, "yes") ? (
        <Col xs={24} sm={8} xl={4}>
          <div className="live-stream-action">
            <Button onClick={() => props.setModalMode("create")}>
              New Livestream
            </Button>
          </div>
        </Col>
      ) : (
        ""
      )}
    </>
  );
};

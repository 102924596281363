import React, { useEffect, useState } from "react";
import {
  Row, Col, Modal,  
  FormItem, Input, Button, 
  Popconfirm, SuccessNotification
} from "components/common";
import { callPutApiWithAuth } from "utils/api";
import { Wrapper } from "./EditModal.styled";

export default function RecordVimeoModal(props) {
  const [formData, setFormData] = useState({
    vimeo_record_id: '',
  })
  const [isUpdating, setIsUpdating] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])

  const onFormInput = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    })
  }
  const onSubmitDone = (res) => {
    setIsUpdating(false)
    SuccessNotification(res.message);
    props.handleSearch();
    props.setModalMode(undefined)
  }
  const onSubmitFail = () => {
    setIsUpdating(false)
  }
  const handleSubmit = (status) => {
    let errorMessages0 = [];
    ['vimeo_record_id'].forEach(k => {
      if (!formData[k]) {
        errorMessages0.push({
          field: 'vimeo_record_id',
          message: 'Required'
        })
      }
    })
    setErrorMessages(errorMessages0)
    if (errorMessages0.length>0) return;

    const body = {...formData};
    setIsUpdating(true)
    callPutApiWithAuth(
      `live_stream/${props.data.id}/set_vimeo_record`, 
      body, onSubmitDone, onSubmitFail
    );
  };

  return (
    <Modal width={600}
      onCancel={() => props.setModalMode(undefined)}
    >
      <Wrapper>
        <div className="modal-title-container">
          Update Recorded Video
        </div>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <div className="input-container single-container-uploader">
              <FormItem
                label={"Recorded Video"}
                errorMessages={errorMessages.filter(el => 
                  el.field==='vimeo_record_id'
                )}
              >
                <Input value={formData.vimeo_record_id}
                  onChange={e=>onFormInput('vimeo_record_id', e.target.value)}
                  placeholder={'Vimeo URL'}
                />
              </FormItem>
            </div>
          </Col>
          <Col span={24}>
            <div className="action-btn">
              <Popconfirm onConfirm={handleSubmit}>
                <Button loading={isUpdating}>
                  Update
                </Button>
              </Popconfirm>
            </div>
          </Col>
        </Row>
      </Wrapper>
    </Modal>
  );
}

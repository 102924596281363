import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Carousel from "react-multi-carousel";
import { Row, Col, Input, message } from "components/common";
import { callGetApiWithAuth } from "utils/api";
import { asUsdPrice } from "utils/text";
import {
  PlusOutlined as PlusIcon,
  MinusOutlined as MinusIcon,
} from "@ant-design/icons";
import SliderRight from "assets/images/sliderright.png";
import SliderLeft from "assets/images/sliderleft.png";
import { useDispatch } from "react-redux";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1800 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1800, min: 1500 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1500, min: 600 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
  },
};

export default function ProductList() {
  const [products, setProducts] = useState([]);

  const onGetProducts = (res) => {
    setProducts(res.data);
  };
  const onFailProducts = () => {};
  const loadProducts = () => {
    callGetApiWithAuth(
      `my_account/gift_wallet/products`,
      onGetProducts,
      onFailProducts
    );
  };

  useEffect(() => {
    loadProducts();
  }, []);

  return (
    <Wrapper>
      <div className={"slider-box"}>
        <Carousel responsive={responsive}>
          {products.map((el, index) => (
            <div key={index}>
              <ProductCard data={el} />
            </div>
          ))}
        </Carousel>
      </div>
    </Wrapper>
  );
}

const ProductCard = ({ data }) => {
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(1);

  const decreaseQty = () => {
    if (quantity > 1) setQuantity(quantity - 1);
  };
  const increaseQty = () => {
    setQuantity(quantity * 1 + 1);
  };

  const handleAdd = () => {
    if (isNaN(quantity) || 
      quantity*1<=0
    ) {
      message.error('Please input correct number')
      return;
    }
    dispatch({
      type: "checkout.ADD_CART",
      payload: {
        product: data,
        quantity: quantity,
        pay_cycle: 0,
        contained_products: [],
      },
    });
  };

  return (
    <ProductWrapper>
      <div className="product-img">
        <img src={data.image_path} />
      </div>
      <div className="new-product-cart">
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div className="product-title">{data.title}</div>
          </Col>
          <Col span={12}>
            <div className="price">{`${asUsdPrice(data.member_price)}`}</div>
          </Col>
          <Col span={12}>
            <div className="qty-root">
              <MinusIcon onClick={decreaseQty} />
              <Input
                type={"number"}
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
              />
              <PlusIcon className={"plus-icon"} onClick={increaseQty} />
            </div>
          </Col>
          <Col span={12}>
            <div className="action-root">
              <button onClick={handleAdd}>Add To Cart</button>
            </div>
          </Col>
        </Row>
      </div>
    </ProductWrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  .slider-box {
    margin: 0 -10px;
  }
  .react-multiple-carousel__arrow--left {
    background: url(${SliderRight});
    background-size: contain;
    left: 0;
    background-repeat: no-repeat;
    min-width: 35px;
    min-height: 35px;
  }
  .react-multiple-carousel__arrow--right {
    background: url(${SliderLeft});
    background-size: contain;
    right: 0;
    background-repeat: no-repeat;
    z-index: 4;
  }
  .react-multiple-carousel__arrow::before {
    display: none;
  }
`;

const ProductWrapper = styled.div`
  padding: 0px;
  margin: 0px 12px;
  margin-bottom: 24px;
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2),
    0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  border-radius: 16px;
  .product-img {
    img {
      width: 100%;
      border-radius: 16px;
    }
  }
  .product-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: left;
    color: #212b36;
    margin: 0px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .price {
    text-align: right;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    align-items: center;
    color: #36b37e;
    width: 100%;
    display: block;
  }
  .action-root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      background: #1ea9ea;
      border-radius: 8px;
      border: none;
      color: white;
      width: 100%;
      height: 34px;
      font-weight: 700;
      font-size: 13px;
      line-height: 22px;
      text-align: center;
      color: #ffffff;
    }
  }
  .qty-root {
    position: relative;
    width: 100%;
    .anticon {
      cursor: pointer;
      position: absolute;
      top: 10px;
      left: 12px;
      z-index: 12;
      &.plus-icon {
        left: auto;
        right: 12px;
      }
    }
    .ant-input {
      text-align: center;
      height: 34px !important;
      padding: 0px 0px 0px 14px;
    }
  }
  .ant-btn {
    width: calc(50% - 6px);
    background: #384971;
    border-radius: 2px;
    outline-color: #384971;
    border-color: #384971;
  }
  @media (min-width: 900px) and (max-width: 990px) {
    .action-root {
      flex-direction: column;
      .qty-root {
        width: 100%;
      }
      .ant-btn {
        width: 100%;
        margin-top: 20px;
      }
    }
  }
  @media only screen and (max-width: 360px) {
    .action-root {
      flex-direction: column;
      .qty-root {
        width: 100%;
      }
      .ant-btn {
        width: 100%;
        margin-top: 20px;
      }
    }
  }

  .new-product-cart {
    padding: 16px 24px 24px;
  }
`;

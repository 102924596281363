import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { notification, Radio, Space } from "antd";
import { EditOutlined, WarningOutlined } from "@ant-design/icons";
import { Popconfirm, Input, Select, Button } from "components/common";
import {
  callGetApiWithAuth,
  callPostApiWithAuth,
  callPutApiWithAuth,
} from "utils/api";
import { countryStates, statesByCountry } from "common/country";

export default function ShippingForm(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const myUser = useSelector((state) => state.auth.user);
  const [shippingTypesList, setShippingTypesList] = useState([]);
  const [distCentersList, setDistCentersList] = useState([]);
  const [shippingType, setShippingType] = useState(1);
  const [shippingAddressOptions, setShippingAddressOptions] = useState([]);
  const [formData, setFormData] = useState({ id: "" });
  const [states, setStates] = useState([]);
  const [isEditable, setIsEditable] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [errors, setErrors] = useState({
    shipping_country_error: "",
    shipping_state_error: "",
    shipping_city_error: "",
    shipping_address_error: "",
    shipping_address_line2_error: "",
    shipping_zipcode_error: "",
  });

  const onChangeForm = (field, value) => {
    let formData_ = { ...formData, [field]: value };
    if (field === "shipping_country") {
      formData_ = {
        ...formData_,
        shipping_state: "",
      };
    }
    setFormData(formData_);
  };

  const onGetDistCenter = ({ data }) => {
    const shippingTypesList0 = [];
    data.forEach((el) => {
      let isAlready = false;
      shippingTypesList0.forEach((el2) => {
        if (el.type * 1 === el2.value) {
          isAlready = true;
        }
      });
      if (!isAlready) {
        shippingTypesList0.push({
          value: el.type * 1 === 1 ? 1 : 2,
          key: el.id,
          label: el.type * 1 === 1 ? "Ship" : "Will Call",
        });
      }
    });
    setShippingTypesList(shippingTypesList0);
    if (data && data.length > 0) {
      if (data.filter((el) => el.type * 1 === 1).length > 0) {
        setShippingType(1);
      } else {
        setShippingType(2);
      }
    }
    setDistCentersList(data);
  };

  const onFailDistCenter = () => {
    notification.warning({
      message: "Some products are not available for your country.",
    });
  };

  const onChangeShippingType = (shippingType0) => {
    setShippingType(shippingType0);
    if (shippingType0 === 1) {
      props.setDistCenter(distCentersList.filter((el) => el.type * 1 === 1)[0]);
    } else {
      props.setDistCenter(distCentersList.filter((el) => el.type * 1 === 2)[0]);
    }
  };

  const onCheckWDistCenter = (distCenterId0) => {
    props.setDistCenter(
      distCentersList.filter((el) => el.id === distCenterId0)[0]
    );
  };

  const validateForm = () => {
    let errors_ = {
      shipping_country_error: "",
      shipping_state_error: "",
      shipping_city_error: "",
      shipping_address_error: "",
      shipping_address_line2_error: "",
      shipping_zipcode_error: "",
    };
    const englishRegex =
      /^[\u4e00-\u9fa5_A-Za-z0-9\s!@#$%^&*()_+=\-`~\\\]\[{}|';:/.,?><]*$/;

    if ((props.shippingData.id && isEditable) || !props.shippingData.id) {
      if (!formData.shipping_country.trim()) {
        errors_["shipping_country_error"] = "Required";
      }
      if (!formData.shipping_state.trim()) {
        errors_["shipping_state_error"] = "Required";
      }
      if (
        !formData.shipping_city.trim() &&
        statesByCountry(formData.shipping_country).length > 0
      ) {
        errors_["shipping_city_error"] = "Required";
      } else if (formData.shipping_city.length > 190) {
        errors_["shipping_city_error"] = "Shipping city length too long";
      } else if (
        formData.shipping_city &&
        englishRegex.exec(formData.shipping_city) == null
      ) {
        errors_["shipping_city_error"] = "Please enter only English";
      }
      if (!formData.shipping_address.trim()) {
        errors_["shipping_address_error"] = "Required";
      } else if (formData.shipping_address.length > 190) {
        errors_["shipping_address_error"] = "Shipping address length too long";
      } else if (
        formData.shipping_address &&
        englishRegex.exec(formData.shipping_address) == null
      ) {
        errors_["shipping_address_error"] = "Please enter only English";
      }
      if (
        formData.shipping_address_line2 &&
        englishRegex.exec(formData.shipping_address_line2) == null
      ) {
        errors_["shipping_address_line2_error"] = "Please enter only English";
      }
      if (!formData.shipping_zipcode.trim()) {
        errors_["shipping_zipcode_error"] = "Required";
      }
    }

    if (
      errors_.shipping_country_error ||
      errors_.shipping_state_error ||
      errors_.shipping_city_error ||
      errors_.shipping_address_error ||
      errors_.shipping_address_line2_error ||
      errors_.shipping_zipcode_error
    ) {
      setErrors(errors_);
      return true;
    }

    setErrors(errors_);
    return false;
  };

  const onSuccessUpdated = (res) => {
    setIsUpdating(false);
    setIsUpdated(true);
    if (res.data.shipping_country !== myUser.country) {
      notification.warning({
        message: "Warning",
        description:
          "Your country has been updated. Please add items to cart again.",
      });
      dispatch({
        type: "auth.RELOAD",
      });
      dispatch({
        type: "CLEAR_CART",
      });
      history.push("/");
    } else {
      notification.success({
        message: "Success",
        description: "Shipping Address has been updated.",
      });
      setErrors({
        shipping_country_error: "",
        shipping_state_error: "",
        shipping_city_error: "",
        shipping_address_error: "",
        shipping_address_line2_error: "",
        shipping_zipcode_error: "",
      });
      loadShippingAddresses();
    }
  };

  const onFailedUpdate = (err) => {
    setIsUpdating(false);
    notification.error({
      message: "Failed",
      description: err,
    });
  };

  const handleUpdate = () => {
    if (validateForm()) return;
    setIsUpdating(true);
    if (props.shippingData.id) {
      callPutApiWithAuth(
        `my_account/setting/shipping_detail/${props.shippingData.id}`,
        formData,
        onSuccessUpdated,
        onFailedUpdate
      );
    } else {
      callPostApiWithAuth(
        `my_account/setting/shipping_detail`,
        formData,
        onSuccessUpdated,
        onFailedUpdate
      );
    }
  };

  const handleContinue = () => {
    setErrors({
      shipping_country_error: "",
      shipping_state_error: "",
      shipping_city_error: "",
      shipping_address_error: "",
      shipping_address_line2_error: "",
      shipping_zipcode_error: "",
    });
    props.getShippingAndTaxPrice();
  };

  const handleClickEditIcon = () => {
    setIsEditable(!isEditable);
    setErrors({
      shipping_country_error: "",
      shipping_state_error: "",
      shipping_city_error: "",
      shipping_address_error: "",
      shipping_address_line2_error: "",
      shipping_zipcode_error: "",
    });
  };

  const onGetSuccessShippingAddresses = ({ data }) => {
    if (data && data.length === 2) {
      setShippingAddressOptions(
        data.map((el) => ({
          ...el,
          value: el.id,
          label: el.name,
        }))
      );
    } else {
      setShippingAddressOptions([
        ...data.map((el) => ({
          ...el,
          value: el.id,
          label: el.name,
        })),
        {
          value: "",
          key: "new",
          label: "New Shipping Address",
          id: "",
          shipping_address: "",
          shipping_address_line2: "",
          shipping_city: "",
          shipping_state: "",
          shipping_zipcode: "",
          shipping_country: "",
        },
      ]);
    }
  };

  const onGetFailedShippingAddresses = () => {
    setShippingAddressOptions([
      {
        value: "",
        key: "new",
        label: "New Shipping Address",
        id: "",
        shipping_address: "",
        shipping_address_line2: "",
        shipping_city: "",
        shipping_state: "",
        shipping_zipcode: "",
        shipping_country: "",
      },
    ]);
  };

  const loadShippingAddresses = () => {
    callGetApiWithAuth(
      "shop/get_shipping_details",
      onGetSuccessShippingAddresses,
      onGetFailedShippingAddresses
    );
  };

  useEffect(() => {
    setStates(statesByCountry(formData.shipping_country));
  }, [formData.shipping_country]);

  useEffect(() => {
    if (
      formData.shipping_country &&
      props.orderDetails &&
      props.orderDetails.length > 0
    ) {
      callPostApiWithAuth(
        `common/dist_center`,
        {
          country: formData.shipping_country,
          orderDetails: props.orderDetails.map((el) => ({
            product_id: el.product.id,
          })),
        },
        onGetDistCenter,
        onFailDistCenter
      );
    }
  }, [formData.shipping_country, props.orderDetails]);

  useEffect(() => {
    if (shippingAddressOptions.length > 0) {
      props.setShippingData({
        id:
          shippingAddressOptions.filter((el) => el.is_primary * 1 === 1)[0]
            ?.id || "",
      });
    } else {
      props.setShippingData({
        id: "",
      });
    }
  }, [shippingAddressOptions]);

  useEffect(() => {
    setIsEditable(false);
    if (shippingAddressOptions.length > 0 && props.shippingData.id) {
      let formData0 = shippingAddressOptions.filter(
        (el) => el.value * 1 === props.shippingData.id * 1
      )[0];
      setFormData(formData0);
    } else {
      setFormData({
        shipping_address: "",
        shipping_address_line2: "",
        shipping_city: "",
        shipping_state: "",
        shipping_zipcode: "",
        shipping_country: "",
      });
    }
  }, [shippingAddressOptions, props.shippingData.id]);

  useEffect(() => {
    if (isEditable) {
      setIsUpdated(false);
    } else {
      setIsUpdated(true);
    }
  }, [isEditable]);

  useEffect(() => {
    loadShippingAddresses();
  }, []);

  return (
    <div className="checkout-form">
      {shippingTypesList.length > 1 && (
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 selectdiv no-padding">
            <h4 className="checkout-title">Shipping Type</h4>
            <Select
              value={shippingType}
              onChange={(v) => onChangeShippingType(v)}
              options={shippingTypesList}
              size="large"
            />
          </div>
          <div style={{ height: 12 }} />
        </div>
      )}
      {shippingType * 1 === 1 && (
        <>
          <h4 className="checkout-title">Shipping Address</h4>
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 selectdiv no-padding">
              <Select
                label="Select Your Shipping Address*"
                value={props.shippingData.id}
                onChange={(value) => props.setShippingData({ id: value })}
                size="large"
                options={shippingAddressOptions}
              />
            </div>
            {props.shippingData.id && (
              <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 selectdiv no-padding edit">
                {isEditable ? (
                  <Button size="large" onClick={handleClickEditIcon}>
                    Cancel
                  </Button>
                ) : (
                  <Button
                    size="large"
                    onClick={handleClickEditIcon}
                    icon={<EditOutlined />}
                  >
                    Edit
                  </Button>
                )}
                <br />
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 no-padding">
              <Input
                label="Address Line 1*"
                type="text"
                value={formData.shipping_address}
                onChange={(e) =>
                  onChangeForm("shipping_address", e.target.value)
                }
                disabled={props.shippingData.id && !isEditable}
              />
              {errors.shipping_address_error && (
                <label className="input-error">
                  {errors.shipping_address_error}
                </label>
              )}
            </div>
            <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 no-padding">
              <Input
                label="Address Line 2"
                type="text"
                value={formData.shipping_address_line2}
                onChange={(e) =>
                  onChangeForm("shipping_address_line2", e.target.value)
                }
                disabled={props.shippingData.id && !isEditable}
              />
              {errors.shipping_address_line2_error && (
                <label className="input-error">
                  {errors.shipping_address_line2_error}
                </label>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 no-padding">
              <Input
                label="City*"
                type="text"
                value={formData.shipping_city}
                onChange={(e) => onChangeForm("shipping_city", e.target.value)}
                disabled={props.shippingData.id && !isEditable}
              />
              {errors.shipping_city_error && (
                <label className="input-error">
                  {errors.shipping_city_error}
                </label>
              )}
            </div>
            <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 no-padding">
              <Input
                label="Zip/Postal Code*"
                type="text"
                value={formData.shipping_zipcode}
                onChange={(e) =>
                  onChangeForm("shipping_zipcode", e.target.value)
                }
                size="large"
                disabled={props.shippingData.id && !isEditable}
              />
              {errors.shipping_zipcode_error && (
                <label className="input-error">
                  {errors.shipping_zipcode_error}
                </label>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 selectdiv no-padding">
              <Select
                label="Country*"
                value={formData.shipping_country}
                onChange={(value) => onChangeForm("shipping_country", value)}
                size="large"
                showSearch
                disabled={props.shippingData.id && !isEditable}
                options={[
                  {
                    label: "Select Country",
                    value: "",
                  },
                  ...countryStates.map((el) => ({
                    label: el.name,
                    value: el.code2,
                  })),
                ]}
                optionFilterProp="label"
              />
              {errors.shipping_country_error && (
                <label className="input-error">
                  {errors.shipping_country_error}
                </label>
              )}
            </div>
            {statesByCountry(formData.shipping_country).length > 0 ? (
              <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 selectdiv no-padding">
                <Select
                  label="State/Province*"
                  value={formData.shipping_state}
                  onChange={(value) => onChangeForm("shipping_state", value)}
                  size="large"
                  showSearch
                  disabled={props.shippingData.id && !isEditable}
                  options={[
                    {
                      label: "Select State",
                      value: "",
                    },
                    ...statesByCountry(formData.shipping_country).map((el) => ({
                      label: el.name,
                      value: el.name,
                    })),
                  ]}
                />
                {errors.shipping_state_error && (
                  <label className="input-error">
                    {errors.shipping_state_error}
                  </label>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      )}
      {shippingType * 1 === 2 && distCentersList.length > 0 && (
        <>
          <h6>Please select one of the following pick up points below:</h6>
          <div className="row">
            <div className="col-12">
              <div style={{ paddingLeft: 12 }}>
                <Radio.Group
                  value={props.distCenter?.id}
                  onChange={(e) => onCheckWDistCenter(e.target.value)}
                >
                  <Space direction="vertical">
                    {distCentersList
                      .filter((el) => el.type * 1 === 2)
                      .map((el) => (
                        <Radio value={el.id}>{el.name}</Radio>
                      ))}
                  </Space>
                </Radio.Group>
              </div>
              <div style={{ height: 24 }} />
              <div className="wdist-center-description">
                {distCentersList
                  .filter((el) => el.id === props.distCenter?.id)
                  .map((el) => (
                    <span
                      dangerouslySetInnerHTML={{ __html: el.description }}
                    />
                  ))}
              </div>
            </div>
          </div>
        </>
      )}
      <div className="row action-row">
        <div className="col-12 d-flex  j-end">
          {isUpdated && props.shippingData.id ? (
            <button
              loading={props.isLoading}
              className="purc-button"
              onClick={handleContinue}
            >
              Continue
            </button>
          ) : myUser &&
            formData.shipping_country &&
            myUser.country !== formData.shipping_country ? (
            <Popconfirm
              title={
                <>
                  Your country will be changed, and cart will be cleared.
                  <br />
                  You should add items on cart again.
                </>
              }
              onConfirm={handleUpdate}
              icon={<WarningOutlined style={{ color: "red" }} />}
            >
              <button loading={isUpdating} className="purc-button">
                Update
              </button>
            </Popconfirm>
          ) : (
            <button
              loading={isUpdating}
              className="purc-button"
              onClick={handleUpdate}
            >
              Update
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

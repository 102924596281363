import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Drawer } from "components/common";
import TableView from "./table/TableView";
import CommentDrawer from "./drawer/CommentDrawer";

export default function ContactManagerPage() {
  const [shouldLoad, setShouldLoad] = useState(true);
  const [selectedUser, setSelectedUser] = useState(undefined);

  return (
    <Wrapper>
      <TableView
        shouldLoad={shouldLoad}
        setShouldLoad={setShouldLoad}
        setSelectedUser={setSelectedUser}
      />
      {selectedUser && (
        <Drawer
          title={null}
          size={"large"}
          placement="right"
          onClose={() => setSelectedUser(undefined)}
          visible={true}
          className="contant-manager-drawer"
        >
          <CommentDrawer
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            onClose={() => setSelectedUser(undefined)}
            setShouldLoad={setShouldLoad}
          />
        </Drawer>
      )}
    </Wrapper>
  );
}

export const Wrapper = styled.div`
  padding-bottom: 80px;
  .chat-icon {
    margin-left: 6px;
    margin-top: -5px;
    cursor: pointer;
    color: ${(props) => props.theme.palette.link};
    font-size: 16px;
  }
  .comment-icon {
    margin-left: 6px;
    cursor: pointer;
    font-size: 18px;
    svg {
      transform: scaleX(-1) !important;
    }
    path {
      color: #1ea9ea;
    }
  }
  .subscription-yes {
    color: #3ad33a;
  }
  .subscription-no {
    color: #ff6565;
  }
  .breadcrumb-bar {
    display: flex;
  }
  .breadcrumb {
    font-size: 14px;
    cursor: pointer;
  }
  .breadcrumb-split {
    padding: 0 8px;
    font-size: 12px;
    line-height: 14px;
  }
`;

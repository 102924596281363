import React, { useEffect } from "react";
import { Row, Col, Button } from "components/common";
import { useSelector, useDispatch } from "react-redux";
import { Wrapper } from "./ProductList.styled";

export default function ProductList({
  data,
  height,
}) {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.publsiher.products);

  const onToggleProducts = (el) => {
    if (el.is_selected) {
      let products0 = [
        ...products?.filter((el2) => el2.product_id !== el.product_id),
        {
          ...el,
          is_selected: false,
        },
      ];
      dispatch({
        type: "SET_STREAM_PRODUCTS",
        payload: {
          products: products0,
        },
      });
    } else {
      let products0 = [
        {
          ...el,
          is_selected: true,
        },
        ...products?.filter((el2) => el2.product_id !== el.product_id),
      ];
      dispatch({
        type: "SET_STREAM_PRODUCTS",
        payload: {
          products: products0,
        },
      });
    }
  };

  useEffect(() => {
    if (data.products?.length > 0) {
      let products0 = data.products?.map((el) => {
        return {
          ...el,
          is_selected: false,
        };
      });
      dispatch({
        type: "SET_STREAM_PRODUCTS",
        payload: {
          products: products0,
        },
      });
    }
  }, [data.products]);

  return (
    <Wrapper
    >
      <div className="cart-box">
        <Row gutter={[20, 20]}>
          {products?.map((el) => {
            return (
              <Col span={24}>
                <div className="small-products-card">
                <img src={el.product.image_path} />
                <div className="product-details">
                  <div className="label">{el.product.title}</div>                        
                </div>
                 
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    </Wrapper>
  );
}

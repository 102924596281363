import styled from "styled-components";
import RightBgImg from "assets/images/hlt/Vecto22.png";

export const Wrapper = styled.div`
  padding-bottom: 80px;
  .main-container {
    background-color: #ffffff;
    background-image: url("${RightBgImg}");
    background-repeat: no-repeat;
    background-size: 15%;
    background-position: right center;
    
    .HighLifeTravel .high-life-block {
      padding: 100px 0;
      position: relative;
      display: inline-block;
      width: 100%;
      overflow: hidden;
    }
  
    .HighLifeTravel .high-life-bg {
      position: absolute;
      width: 380px;
      top: 0;
      margin: 0 auto;
      left: 0;
      right: 0;
    }
  
    .HighLifeTravel .high-life-bg img {
      width: 100%;
    }
  
    .HighLifeTravel .high-life-block-content {
      margin: 0 20px;
    }
  
    .HighLifeTravel .high-life-block-img img {
      width: 100%;
    }
  
    .HighLifeTravel .high-life-block-img {
      margin: 0 50px 0 0;
      position: relative;
    }
  
    .HighLifeTravel .high-life-block-img .video-play {
      position: absolute;
      top: calc(50% - 20px);
      left: calc(50% - 20px);
      width: 50px;
      height: 50px;
      cursor: pointer;
    }
  
    .HighLifeTravel .high-life-block-text.right-margi {
    }
  
    .HighLifeTravel .high-life-block-text .high-life {
      width: 140px;
      display: inline-block;
      margin-bottom: 15px;
    }

    .HighLifeTravel .high-life-block-text .high-life img {
      width: 100%;
    }
  
    .HighLifeTravel .high-life-block-text h3 {
      font-size: 28px;
      font-weight: 600;
      line-height: 28px;
      color: #30426A;
      margin-bottom: 15px;
    }
  
    .HighLifeTravel .high-life-block-text h3 span {
      color: #ff7200
    }
  
    .HighLifeTravel .high-life-block-text p {
      font-size: 16px;
      line-height: 24px;
      /* or 150% */
      color: #3C4356;
      opacity: 0.75;
    }
  
    .HighLifeTravel .p0 {
      padding: 0 !important
    }
  
    .HighLifeTravel .Discounts-block h2 {
      font-weight: bold;
      font-size: 36px;
      line-height: 36px;
      color: #30426A;
    }
  
    .HighLifeTravel .Discounts-block {
      text-align: center;
      background-size: 121px;
      background-repeat: no-repeat;
      background-position: right bottom;
    }
    .high-life-travel {
      padding: 20px 20px 80px;
      margin-top: 20px;
      text-align: center;
      h3 {
        font-size: 24px;
        font-weight: 600;
        margin-top: 40px;
        color: #30426A;
      }
      p {
        opacity: 0.75;
        color: #30426A;
        margin: 0 auto 30px;
        max-width: 400px;
      }
      a {
        background: #FF7200;
        color: #ffffff;
        padding: 10px 20px;
        border-radius: 2px;
      }
      .footer-bg {
        margin: 0 auto;
        display: block;
        width: 100%;
        max-width: 800px;
        padding: 0 30px;
      }
    }

    @media only screen and (max-width: 991px) {
      .HighLifeTravel .high-life-block-img {
        margin: 0;
      }
    }
  
    @media(min-width:240px) and (max-width:767px) {
      .HighLifeTravel .high-life-block-text.right-margi,
      .HighLifeTravel .high-life-block-img {
          margin: 0
      }
      .HighLifeTravel .Discounts-block h2,
      .HighLifeTravel .high-life-block-text h3 {
          font-size: 30px
      }
    }
  }
  .signupModal .ant-select {
    width: 100%;
  }
`;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import {
  Table,
  Button,
  UserAvatar,
  CountryFlag,
  RankBadge,
  MonthPicker,
  Spin,
  message,
} from "components/common";
import { varKey } from "common/var";
import { callGetApiWithAuth } from "utils/api";
import SendGiftModal from "./SendGiftModal";

export default function TableView({ setViewMode, setCurUser }) {
  const [listData, setListData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParam, setSearchParam] = useState({
    month: moment().startOf("month").format("YYYY-MM-DD"),
  });

  const onGetListData = (res) => {
    setIsLoading(false);
    setListData(res.data);
  };
  const onFailListData = () => {
    setIsLoading(false);
  };
  const loadListData = () => {
    if (!searchParam.month) {
      message.error("Please input month");
      return;
    }

    setIsLoading(true);
    const q = Object.keys(searchParam)
      .map((k) => k + "=" + searchParam[k])
      .join("&");
    callGetApiWithAuth(
      `report/new_rank_up/list?${q}`,
      onGetListData,
      onFailListData
    );
  };

  const onChangeMonth = (_, v) => {
    let month = "";
    if (v) {
      month = v;
    }
    setSearchParam({
      ...searchParam,
      month,
    });
  };

  useEffect(() => {
    loadListData();
  }, []);

  return (
    <Wrapper>
      <div className="d-flex align-items-center justify-content-end">
        <MonthPicker date={searchParam.month} onChange={onChangeMonth} />
        <span style={{ width: 8 }} />
        <Button onClick={loadListData}>Search</Button>
      </div>
      {isLoading ? (
        <div className={"loadingRoot"}>
          <Spin spinning={true} />
        </div>
      ) : (
        listData.map((el, index) =>
          el.users.length > 0 ? (
            <div key={index} className={"groupRoot"}>
              <div className={"rankTitle"}>
                {el.rank.badge && <img src={el.rank.badge} />}
                {el.rank.name}
              </div>
              <Table
                dataSource={el.users}
                pagination={false}
                columns={[
                  {
                    title: "Name",
                    key: "name",
                    render: (_, record) => (
                      <UserAvatar
                        image={record.image}
                        title={`${record.first_name} ${record.last_name}`}
                        status={varKey("user.status", record.status)}
                      />
                    ),
                  },
                  {
                    title: "Username",
                    key: "username",
                    render: (_, record) => <span>{record.username}</span>,
                  },
                  {
                    title: "Country",
                    key: "country",
                    render: (_, record) => (
                      <CountryFlag country={record.country} />
                    ),
                  },
                  {
                    title: "Rank",
                    key: "rank_id",
                    render: (_, record) => (
                      <RankBadge label={record.max_rank.name} />
                    ),
                  },
                  {
                    title: "Action",
                    key: "action",
                    render: (_, record) => (
                      <div className="d-flex align-items-center">
                        <WelcomeBtn data={record} />
                        <span style={{ width: 8 }} />
                        <SendGiftBtn data={record} />
                        <span style={{ width: 8 }} />
                        <Button
                          onClick={() => {
                            setViewMode("rank_recog");
                            setCurUser(record);
                          }}
                        >
                          Rank Recognition
                        </Button>
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          ) : (
            ""
          )
        )
      )}
    </Wrapper>
  );
}

const WelcomeBtn = ({ data }) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const onGetDownliner = ({ data }) => {
    setIsLoading(false);
    if (data && data.length > 0) {
      history.push({
        pathname: "/team_chat",
        state: {
          user: data,
        },
      });
    }
  };
  const onFailDownliner = () => {
    setIsLoading(false);
  };
  const onClickChat = () => {
    setIsLoading(true);
    callGetApiWithAuth(
      `common/search_enroll_downline_affiliate/${data.username}`,
      onGetDownliner,
      onFailDownliner
    );
  };

  return (
    <Button loading={isLoading} onClick={onClickChat}>
      Say Congratulations
    </Button>
  );
};

const SendGiftBtn = ({ data }) => {
  const [modalMode, setModalMode] = useState(undefined);

  return (
    <>
      <Button onClick={() => setModalMode("send")}>Send Gift</Button>
      {modalMode === "send" && (
        <SendGiftModal data={data} setModalMode={setModalMode} />
      )}
    </>
  );
};

const Wrapper = styled.div`
  .ant-picker {
    height: 40px !important;
    width: 200px !important;
    border: 1px solid rgba(145, 158, 171, 0.24);
    border-radius: 8px;
  }
  .loadingRoot {
    display: flex;
    height: 320px;
    justify-content: center;
    align-items: center;
  }
  .groupRoot {
    margin-bottom: 20px;
  }
  .rankTitle {
    font-weight: 600;
    font-size: 22px;
    color: #00b4ee;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    img {
      width: 22px;
      height: 22px;
      object-fit: cover;
      margin-right: 8px;
    }
  }
  .ant-table {
    overflow-y: auto;
  }
  @media (max-width: 768px) {
    .ant-table-container {
      width: 768px;
    }
  }
`;

import styled from 'styled-components'

export const Wrapper = styled.div`
  position: fixed;
  width: 270px;
  z-index: 130;
  background: #FFFFFF;
  box-shadow: -40px 40px 80px -8px rgba(145, 158, 171, 0.24);
  border-radius: 16px;
  box-sizing: border-box;
  // &:after, &:before {
  //   right: 100%;
  //   top: 40px;
  //   border: solid transparent;
  //   content: " ";
  //   height: 0;
  //   width: 0;
  //   position: absolute;
  //   pointer-events: none;
  // }
  // &:after {
  //   border-color: rgba(255, 255, 255, 0);
  //   border-right-color: #fff;
  //   border-width: 16px;
  //   margin-top: -16px;
  // }
  // &:before {
  //   border-color: rgba(230, 234, 238, 0);
  //   border-right-color: ${props=>props.theme.palette.border};
  //   border-width: 17px;
  //   margin-top: -17px;
  // }
  .headerBar {
    display: flex;
    align-items: center;
    padding: 12px 24px;
    gap: 10px;
    .userStatus {
      width: 10px;
      height: 10px;
      border-radius:50%;
      position: relative;
      left: 40px;
      bottom: 15px
    }
    .active {
      background-color: green;
    }
  }
  .photo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
  }
  .name {
    margin: 6px 0px 0px 0;
    font-weight: 600;
    font-size: 16px;
    color: #212B36;
  }
  .usernameLabel {
    font-weight: 600;
    font-size: 16px;
    color: #637381;
  }
  .username {
    font-size: 12px;
  }
  .userLink {
    font-size: 12px;
  }
  table {
    width: 100%;
    padding: 0 10px;
    th {
      background: #fafafa;
      // border-bottom: 1px solid #f0f0f0;
      font-weight: 500;
      padding: 4px 8px;
      font-size: 12px;
      &:first-child {
        padding-left: 12px;
        // border-right: 1px solid #f0f0f0;
      }
      &:last-child {
        padding-right: 12px;
        text-align: right;
      }
    }
    td {
      padding: 4px 8px;
      font-size: 12px;
      &:first-child {
        padding-left: 12px;
        color: #637381;
      }
      &:last-child {
        padding-right: 12px;
        text-align: right;
        color: #212B36;
      }
    }
    .subscription-yes {
      color: #52c41a;
    }
    .subscription-no {
      color: red;
    }
  }
  .subscription-yes {
    color: ${props=>props.theme.palette.success};
  }
  .subscription-no {
    color: ${props=>props.theme.palette.error};
  }
`

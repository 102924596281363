import styled from "styled-components";

export const Wrapper = styled.div`
  .main-container {
    padding-bottom: 24px;
    .billing-info {
      margin-top: 24px;
    }
    .j-end {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      .purc-button {
        border: none;
        background: #1ea9ea;
        border-radius: 8px;
        color: white;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #ffffff;
        padding: 12px 16px;
      }
    }
    .left-section {
      width: 100%;
      display: block;
      padding: 24px;
      box-shadow: 0px 0px 2px rgb(145 158 171 / 20%),
        0px 12px 24px -4px rgb(145 158 171 / 12%);
      border-radius: 16px;
      background: #ffffff;
    }
    .left-section .main-content {
      width: 100%;
    }
    .left-section .ant-picker {
      border-radius: unset;
      height: 40px;
      width: 100%;
    }
    h2 {
      font-weight: 600;
      margin-top: 50px;
      margin-bottom: 50px;
      font-size: 24px;
      color: #232838;
      text-align: center;
    }
    .left-section.checkout-section .btn-primary {
      width: fit-content;
      background: #00b2ff;
      border: none;
      color: #ffffff;
      font-size: 1rem;
      padding: 0.375rem 0.75rem;
      height: 40px;
    }
    .left-section.checkout-section .btn-primary:hover {
      box-shadow: 0 0 0 0.2rem rgb(0 178 255 / 60%) !important;
      opacity: 0.8;
    }
    .left-section.checkout-section .btn-primary {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .left-section.checkout-section .btn-secondary {
      width: fit-content;
    }
    .left-section.checkout-section .user-type-box.affiliate .btn-primary {
      background: transparent;
      border: 2px solid #00b2ff;
      color: #00b2ff;
      font-size: 14px;
      font-weight: 600;
      margin-top: 50px;
    }
    .left-section.checkout-section .user-type-box.customer .btn-primary {
      background: transparent;
      border: 2px solid #3c4356;
      color: #3c4356;
      font-size: 14px;
      font-weight: 600;
      margin-top: 50px;
    }
    .left-section.checkout-section .user-type-box.customer .btn-primary:hover {
      box-shadow: 0 0 0 0.2rem rgb(60 67 86 / 60%) !important;
    }
    .left-section.checkout-section ul {
      list-style: disc;
    }
    .left-section.checkout-section .checkout-form .edit {
      display: flex;
      align-items: flex-end;
      margin-bottom: 2px;
    }
    .left-section.checkout-section .checkout-title {
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      color: #212b36;
      margin-bottom: 19px;
    }
    .left-section.checkout-section .input-error {
      color: #ff4d4f;
      margin-top: 5px;
    }
    /*************** Choose Type Section **************/
    .choose-type-wrapper {
      margin-bottom: 30px;
      height: 700px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .choose-type-wrapper .confirm-change-usertype {
      margin-top: 20px;
      text-align: center;
    }
    .choose-type-wrapper .confirm-change-usertype button {
      width: 100px;
      margin-left: 30px;
    }
    .choose-type-wrapper .checkout-title {
      margin-bottom: 20px;
    }
    .choose-type-wrapper .type-title {
      color: #6c757d;
      font-size: 16px;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 30px;
    }
    .choose-type-wrapper .btn-primary {
      margin-top: 24px;
    }
    .choose-type-wrapper .user-type-box {
      width: 47%;
      max-width: 470px;
      padding: 50px 40px;
      border-radius: 6px;
      margin: 6px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }
    .choose-type-wrapper .user-type-box img {
      width: 60px;
      height: 60px;
    }
    .choose-type-wrapper .user-type-box.affiliate {
      background: #e6f8ff;
    }
    .choose-type-wrapper .user-type-box.customer {
      background: #e5e5e5;
    }
    .choose-type-wrapper .user-type-box h6 {
      margin: 20px 0;
      font-size: 18px;
      font-weight: 600;
      color: #232838;
    }
    .choose-type-wrapper .user-type-box p {
      font-size: 14px;
      color: #3c4356;
    }

    /**************** User Information Section **************/
    .left-section.checkout-section .password-visibility {
      cursor: pointer;
    }
    .checkout-form .action-row {
      margin-top: 24px;
    }

    /***************** Billing Form Section ***************/
    .checkout-form .bitcoin-description {
      margin-top: 12px;
    }
    .checkout-form .btn-checkout {
      width: 160px;
      margin-left: 12px;
    }
    .checkout-form .btn-checktax {
      width: 200px;
    }
    .checkout-form .agreement {
      margin-top: 12px;
    }
    .checkout-form input[type="checkbox"] {
      width: 18px;
      height: 18px;
      min-width: 18px;
      margin-right: 5px;
    }
    .checkout-form .action-row {
      flex-direction: row;
      margin-top: 24px;
    }
    .checkout-form .billing-same-shipping-checkbox {
      margin-top: 12px;
      margin-bottom: 12px;
    }
    .left-section.checkout-section .checkout-form .billing-checkbox {
      display: flex;
      align-items: flex-start;
      margin-top: 12px;
    }
    .left-section.checkout-section .checkout-form .billing-checkbox label {
      display: flex;
      align-items: flex-start;
      margin-top: 0;
    }
    .checkout-form .policy-hyperlink {
      color: #00b2ff;
    }
    .checkout-form .ant-spin-dot {
      font-size: 20px;
    }
    .left-section.checkout-section .checkout-form .edit {
      display: flex;
      align-items: flex-end;
      margin-bottom: 0;
    }

    /****************** Side Cart *****************/
    .side-cart {
      width: 100%;
      padding: 24px;
      box-shadow: 0px 0px 2px rgb(145 158 171 / 20%),
        0px 12px 24px -4px rgb(145 158 171 / 12%);
      border-radius: 16px;
      // height: 100%;
      position: relative;
      img {
        max-width: 100%;
      }
      .mobile-close-cart {
        display: none;
        position: absolute;
        top: 30px;
        right: 15px;
        padding: 3px;
      }
      h5 {
        font-size: 20px;
        font-weight: 700;
      }
      
      .order-type {
        display: block;
        margin-top: 24px;
        h6 {
          font-weight: 600;
          color: #344470;
          margin-bottom: 0px;
          margin-right: 5px;
          white-space: nowrap;
        }
        hr {
          width: 100%;
          margin-left: 50px;
        }
      }
      .order {
        margin: 24px 0px 0px 0px;
        display: flex;
        flex-direction: row;
        .order-image {
          width: 90px;
          height: 100%;
        }
        .order-info {
          width: 100%;
          padding-left: 12px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .order-title-remove {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            font-size: 14px;
            font-weight: 600;
            .order-remove {
              width: 20px;
              padding: 5px;
              border: 1px solid #9f9f9f;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
            }
          }
          .order-quantity-price {
            margin-top: 15px;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            .order-prices {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              .price-volume {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                min-width: 86px;
              }
              span {
                font-size: 12px;
                font-weight: 600;
                opacity: 0.6;
              }
            }
            .order-quantity {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              justify-content: center;
              label {
                margin-right: 14px;
                font-weight: 600;
                opacity: 0.6;
                font-size: 12px;
              }
            }
            .ant-input-number {
              width: 70px;
              border-radius: unset;
              .ant-input-number-handler-wrap {
                opacity: 1;
                border-radius: unset;
              }
              .ant-input-number-input {
                border-radius: unset;
              }
            }
          }
          .includes-products {
            width: 100%;
            margin-top: 12px;
            b {
              font-size: 12px;
              font-weight: 500;
              opacity: 0.6;
            }
            span {
              font-size: 12px;
              font-weight: 500;
              opacity: 0.6;
              margin-left: 10px;
            }
          }
          .total-prices {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            margin-top: 12px;
            .total-original-price {
              margin-right: 20px;
              b {
                opacity: 0.6;
              }
              strong {
                text-decoration: line-through;
                text-decoration-color: #00b2ff;
              }
            }
          }
        }
      }
      .move-order {
        margin-top: 12px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        label {
          font-size: 12px;
          color: #f14705;
          font-weight: 600;
          cursor: pointer;
        }
        label.not-subscription {
          color: #344470;
        }
        .cart-pulse {
          box-shadow: 0 0 0 0 rgb(241 71 5 / 40%);
          animation: cart-pulse 1.5s infinite;
          margin-right: 10px;
          border-radius: 50%;
          width: 5px;
          height: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .cart-pulse:hover {
          animation: none;
        }
        @-webkit-keyframes cart-pulse {
          0% {
            -webkit-box-shadow: 0 0 0 0 rgba(241, 71, 5, 0.4);
          }
          70% {
            -webkit-box-shadow: 0 0 0 10px rgba(241, 71, 5, 0);
          }
          100% {
            -webkit-box-shadow: 0 0 0 0 rgba(241, 71, 5, 0);
          }
        }
        @keyframes cart-pulse {
          0% {
            -moz-box-shadow: 0 0 0 0 rgba(241, 71, 5, 0.4);
            box-shadow: 0 0 0 0 rgba(241, 71, 5, 0.4);
          }
          70% {
            -moz-box-shadow: 0 0 0 10px rgba(241, 71, 5, 0);
            box-shadow: 0 0 0 10px rgba(241, 71, 5, 0);
          }
          100% {
            -moz-box-shadow: 0 0 0 0 rgba(241, 71, 5, 0);
            box-shadow: 0 0 0 0 rgba(241, 71, 5, 0);
          }
        }
      }
      .order-details {
      }
      .order-summary {
        .order-summary-details {
          border-bottom: 1px solid rgba(145, 158, 171, 0.24);
          padding-bottom: 8px;
          h5 {
            font-weight: 700;
            font-size: 24px;
            line-height: 36px;
            color: #212b36;
            margin-bottom: 24px;
          }
          .summary-label-value {
            display: flex;
            justify-content: space-between;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #637381;
            padding: 0px 0px 16px;
            b {
              text-align: right;
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              color: #212b36;
            }
          }
        }
        .order-summary-result {
          padding-top: 24px;
          .order-total-price {
            display: flex;
            justify-content: space-between;
            h6 {
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              margin-bottom: 3px;
              color: #212b36;
            }
            .total-p {
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              text-align: right;
              color: #ff5630;
            }
          }
        }
        .new-checkout-button {
          width: 100%;
          height: 38px;
          margin: 10px 0;
          padding: 5px;
          border: unset;
          border-radius: 2px;
          font-weight: 600;
          color: #ffffff;
          background: #344470;
        }
      }
    }
    @media (min-width: 240px) and (max-width: 576px) {
      .side-cart {
        .mobile-close-cart {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          cursor: pointer;
          img {
            padding: 3px;
            width: 20px;
            height: 20px;
            margin-right: 5px;
          }
        }
        .order-type {
          h6 {
            margin-right: 10px;
          }
        }
        .order {
          padding: 5px;
          .order-image {
            width: 60px;
          }
          .order-info {
            width: 210px;
            padding-left: 5px;
          }
        }
      }
    }

    /****************** Step Bar Section ****************/
    .step-bar {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 64px;
      font-family: 600;
    }
    .step-point {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: #e2e2e2;
      position: relative;
    }
    .step-label {
      position: absolute;
      top: 24px;
      left: 0;
      margin-left: -45px;
      width: 120px;
      text-align: center;
    }
    .step-point:last-child .step-label {
      text-align: left;
    }
    .step-point.active {
      background-color: #00b2ff;
    }
    .step-line {
      width: 100px;
      height: 4px;
      background-color: #e2e2e2;
    }
    .step-line.active {
      background-color: #00b2ff;
    }

    /***************** Confirmation ****************/

    .wrapper.order-completion {
      width: 100%;
      background: #ffffff;
      display: flex;
      justify-content: center;
      padding: 50px 20px;
    }
    .wrapper.order-completion .result-content {
      width: 100%;
      max-width: 700px;
    }
    .wrapper.order-completion h2 {
      font-size: 24px;
      margin-top: 12px;
    }
    .wrapper.order-completion h4 {
      font-size: 20px;
      margin-top: 24px;
    }
    .wrapper.order-completion .confirmed-img {
      width: 60px;
      height: 60px;
    }
    .wrapper.order-completion .btn-checkout {
      margin: 0 8px;
    }
    .wrapper.order-completion .order-complete-label {
      text-align: center;
    }
    .wrapper.order-completion .confirm-box {
      border: 1px solid #e2e2e2;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 24px;
      margin: 20px 0;
    }
    .wrapper.order-completion .confirm-box .ant-table-content {
      overflow-x: auto;
    }
    .wrapper.order-completion .confirm-box table {
      white-space: nowrap;
    }
    .wrapper.order-completion .total-price {
      font-size: 16px;
      font-weight: 700;
      color: #000;
    }
    .wrapper.order-completion .btn-checkout {
      width: 180px;
    }
    .wrapper.order-completion .btn-back {
      margin-right: 12px;
    }
    .wrapper.order-completion .checkout-confirm__warning {
      max-width: 68%;
    }
    .wrapper.order-completion .alert-text {
      color: #ff4d4f;
    }
    .confirmed-icon {
      font-size: 40px;
      color: #52c41a;
    }
    .confirmed-icon.bitcoin {
      color: #faad14;
    }
    .confirmed-icon.flagged {
      color: #ff4d4f;
    }
    .btn-back {
      margin-right: 12px;
      background: #d9d9d9;
    }

    .order-completion .go-cbo {
      min-width: 168px;
    }

    .wdist-center-description {
      padding: 12px;
      margin: 15px 0;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      display: flex;
      flex-direction: row;
      opacity: 0.8;
    }

    @media screen and (max-width: 1600px) {
      .checkout-form .col-xs-12,
      .checkout-form .col-sm-6,
      .checkout-form .col-md-12,
      .checkout-form .col-lg-6,
      .checkout-form .col-sm-9,
      .checkout-form .col-sm-3 {
        width: 100% !important;
      }
      .left-section.checkout-section .checkout-form .edit {
        margin-top: 0px !important;
        button {
          width: 100% !important;
        }
      }
      .action-row {
        margin: 0px !important;
        flex-direction: column !important;
        .prev-button {
          width: 100% !important;
          margin-bottom: 16px !important;
          button {
            width: 100% !important;
          }
        }
        .j-end {
          margin: 0px !important;
          padding: 0px !important;
          .ant-spin-nested-loading {
            width: 100% !important;
          }
          button {
            width: 100% !important;
            justify-content: center !important;
          }
        }
      }
    }
    @media screen and (max-width: 1170px) {
      .checkout-cart-for {
        max-width: 100% !important;
        width: 100% !important;
        flex: 0 0 100% !important;
      }
    }
  }
  .col-xs-12,
  .col-sm-6,
  .col-md-12,
  .col-lg-6 {
    padding: 0px 8px 16px;
  }
  .p-cont {
    background: #f4f6f8;
    margin-top: 24px;
    position: absolute;
    padding: 0px 24px;
    width: 100%;
    margin-left: -24px;
    .p-title {
      font-weight: 600;
      font-size: 13px;
      line-height: 24px;
      color: #637381;
      padding: 16px 0px;
    }
  }
  .p-cont-1 {
    .includes-products {
      margin-top: 3px;
    }
    .total-prices {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 3px;
    }
    .order1 {
      margin-bottom: 16px;
      .pro-list-1 {
        display: flex;
        align-items: center;
        .pro-list-image {
          width: 64px;
          height: 64px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 12px;
            object-fit: cover;
          }
        }
        .pro-list-cont {
          margin-left: 16px;
          label {
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            color: #212b36;
          }
          .each {
            display: flex;
            align-items: center;
            .each-1 {
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: #637381;
              margin-right: 8px;
              padding-right: 8px;
              border-right: 1px solid rgba(145, 158, 171, 0.24);
              span {
                color: #212b36;
              }
            }
            .each-2 {
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: #637381;
              span {
                color: #212b36;
              }
            }
          }
        }
      }
    }
    .order1:last-child {
      margin-bottom: 0px;
    }
  }
  .qty-root {
    position: relative;
    width: 96px;
    .anticon {
      cursor: pointer;
      position: absolute;
      top: 10px;
      left: 12px;
      z-index: 12;
      &.plus-icon {
        left: auto;
        right: 12px;
      }
    }
    .ant-input {
      text-align: center;
      padding: 0 30px 0px 39px;
      height: 36px !important;
    }
  }
  .p-cont-11 {
    margin-top: 16px;
  }
  .hide-cart-quantity {
    display: block;
  }
  .show-cart-quantity {
    display: none;
  }
  @media screen and (max-width: 576px) {
    .hide-cart-quantity {
      display: none !important;
    }
    .show-cart-quantity {
      display: block !important;
    }
  }
  .ant-btn > .anticon + span,
  .ant-btn > span + .anticon {
    margin-left: 0px;
  }
`;

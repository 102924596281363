import React from 'react'
import styled from 'styled-components'
import BadgeCard from './badge/BadgeCard'
import RecogCard from './recog/RecogCard'
import { DoubleLeftOutlined } from '@ant-design/icons'

export default function RankRecogView({ curUser, setViewMode }) {
  return (
    <Wrapper>
      <div className='top-action'>
        <a href='javascript:void(0)'
          onClick={() => setViewMode('table')}
          className={'back-btn'}
        >
          <DoubleLeftOutlined />Back
        </a>
      </div>
      <BadgeCard curUser={curUser} setViewMode={setViewMode} />
      <div style={{height: 24}} />
      <RecogCard curUser={curUser} />
      <div style={{height: 24}} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding-bottom: 80px;
  .top-action {
    margin-bottom: 20px;
    .back-btn {
      display: flex;
      align-items: center;
      font-size: 18px;
    }
  }
`
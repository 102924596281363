import React, { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import {
  Row,
  Col,
  Spin,
  Tabs,
  TextArea,
  Button,
  Popconfirm,
  SuccessNotification,
  message,
} from "components/common";
import { varLabel } from "common/var";
import {
  callGetApiWithAuth,
  callPostApiWithAuth,
  callDeleteApiWithAuth,
} from "utils/api";
import NoPhotoIcon from "assets/images/user.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";
import { CloseCircleFilled } from "@ant-design/icons";

export default function CommentDrawer({ selectedUser, onClose }) {
  const [comments, setComments] = useState([]);
  const [formData, setFormData] = useState({
    comment: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const onGetComments = (res) => {
    setComments(res.data);
    setIsLoading(false);
  };
  const onFailComments = () => {
    setIsLoading(false);
  };
  const loadComments = () => {
    setIsLoading(true);
    let params = {};
    if (selectedUser.type) {
      params.user_id = selectedUser.id;
    } else {
      params.prospect_id = selectedUser.id;
    }
    const q = Object.keys(params)
      .map((k) => k + "=" + params[k])
      .join("&");
    callGetApiWithAuth(
      `contact_manager/comments?${q}`,
      onGetComments,
      onFailComments
    );
  };

  const onSubmitDone = (res) => {
    SuccessNotification(res.message);
    setIsCreating(false);
    loadComments();
    setFormData({
      comment: "",
    });
  };
  const onSubmitFail = () => {
    setIsCreating(false);
  };
  const handleAdd = () => {
    // Validation
    if (!formData.comment) {
      message.error("Please input comment");
      return;
    }

    let body = {
      ...formData,
    };
    if (selectedUser.type) {
      body.user_id = selectedUser.id;
    } else {
      body.prospect_id = selectedUser.id;
    }
    callPostApiWithAuth(
      `contact_manager/comment`,
      body,
      onSubmitDone,
      onSubmitFail
    );
  };

  useEffect(() => {
    loadComments();
  }, [selectedUser]);

  return (
    <Wrapper>
      <div className="user-details">
        <div className="close-button" onClick={() => onClose()}>
          <CloseCircleFilled />
        </div>
        <div className="details-container">
          {selectedUser.show_contact_info * 1 === 1 ? (
            <img src={selectedUser.image || NoPhotoIcon} />
          ) : (
            <img src={NoPhotoIcon} />
          )}
          <div className="extra-info">
            <div className="name">
              {`${selectedUser.first_name} ${selectedUser.last_name}`}
            </div>
            <div className="type">
              {selectedUser.type
                ? varLabel("user.type", selectedUser.type)
                : "Prospect User"}
            </div>
          </div>
        </div>
        <div className="other-detail-box">
          {selectedUser.show_contact_info * 1 === 1 && (
            <div className="contact-info">
              <div className="detail-category">CONTACT INFORMATION</div>
              <div className="detail-tab">
                <strong>Email:</strong>
                <span>{selectedUser.email}</span>
              </div>
              <div className="detail-tab">
                <strong>Phone:</strong>
                <span>{selectedUser.phone}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {isLoading ? (
        <Spin spinning={true} />
      ) : (
        <div className="comment-container">
          <div className="detail-category">COMMENTS</div>
          <div className="comment-box">
            <Row gutter={[10, 10]}>
              {comments.map((el) => (
                <Col span={24} key={el.id}>
                  <CommentSection data={el} loadComments={loadComments} />
                </Col>
              ))}
            </Row>
          </div>
        </div>
      )}
      <div className="comment-input-container">
        <div className="detail-category">WRITE COMMENT</div>
        <TextArea
          rows={3}
          value={formData.comment}
          onChange={(e) =>
            setFormData({ ...formData, comment: e.target.value })
          }
        />
        <div className="action-root">
          <Popconfirm onConfirm={handleAdd}>
            <Button loading={isCreating}>Add Comment</Button>
          </Popconfirm>
        </div>
      </div>
    </Wrapper>
  );
}

const CommentSection = ({ data, loadComments }) => {
  const onDeleteDone = (res) => {
    loadComments();
    SuccessNotification(res.message);
  };
  const handleDelete = () => {
    callDeleteApiWithAuth(`contact_manager/comment/${data.id}`, onDeleteDone);
  };

  return (
    <>
      <div className="comment-col">
        <div className="comment">{data.comment}</div>
        <div className="comment-icons">
          {/* <EditIcon /> */}
          <Popconfirm onConfirm={handleDelete}>
            <DeleteIcon />
          </Popconfirm>
        </div>
      </div>
      <div className="comment-date">
        {moment(data.updated_at).format("MMM DD, YYYY")}
      </div>
    </>
  );
};

const Wrapper = styled.div`
  padding: 24px;
  .detail-category {
    font-weight: 700;
    font-size: 14px;
    color: #637381;
  }
  .user-details {
    .close-button {
      cursor: pointer;
      svg {
        width: 20px;
        height: 20px;
      }
    }
    .details-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 128px;
        height: 128px;
        object-fit: cover;
        border-radius: 50%;
        padding: 8px;
        border: 1px dashed rgba(145, 158, 171, 0.24);
      }
      .extra-info {
        width: 100%;
        padding: 24px 0px;
        text-align: center;
        border-bottom: 1px solid rgba(145, 158, 171, 0.24);
        .type {
          font-weight: normal;
          font-size: 14px;
          color: #637381;
        }
        .name {
          font-weight: 700;
          font-size: 16px;
          color: #212b36;
        }
      }
    }
    .other-detail-box {
      padding: 24px 0px;
      border-bottom: 1px solid rgba(145, 158, 171, 0.24);
      .detail-tab {
        padding-top: 8px;
        strong {
          color: #212b36;
          margin-right: 8px;
        }
        span {
          color: #212b36;
        }
      }
      .contact-info {
        padding: 24px 0px;
      }
      .order-detail {
        display: flex;
        justify-content: space-between;
        align-items: start;
        flex-wrap: wrap;
        span {
          display: block;
          line-break: anywhere;
          text-align: end;
          max-width: 300px;
        }
      }
    }
    .email-container {
      font-weight: normal;
      font-size: 16px;
      margin-top: 15px;
      span {
        color: #7f8fa3;
        margin-left: 7px;
      }
    }
  }
  .comment-container {
    padding: 24px 0px;
    border-bottom: 1px solid rgba(145, 158, 171, 0.24);
    .comment-box {
      height: 120px;
      background: rgba(145, 158, 171, 0.16);
      border-radius: 8px;
      background: #fafbfc;
      margin-top: 8px;
      .comment-col {
        background: #f2f2f2;
        padding: 12px 20px;
        .comment {
          font-weight: normal;
          font-size: 12px;
        }
        .comment-icons {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          svg {
            width: 10px;
            margin-left: 10px;
            margin-top: 3px;
            path {
              fill: #5b5b5b;
            }
          }
        }
      }
      .comment-date {
        text-align: end;
        font-weight: normal;
        font-size: 12px;
        color: #7f8fa3;
        margin-top: 5px;
      }
    }
    .modal-new-title {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      color: #212b36;
      margin-bottom: 20px;
    }
  }
  .comment-input-container {
    padding: 24px 0px;
    .ant-input {
      margin-top: 8px;
    }
    .action-root {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 24px;
    }
  }
`;

import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import copy from 'copy-to-clipboard';
import { 
  Modal, Button, 
  Popconfirm, SuccessNotification 
} from 'components/common'
import { 
  callDeleteApiWithAuth
} from 'utils/api'
import DownloadIcon from '@ant-design/icons/DownloadOutlined'
import LinkedinIcon from 'assets/icons/linkedin_website.svg'
import TwitterIcon from 'assets/icons/twitter_website.svg'
import FacebookIcon from 'assets/icons/facebook_website.svg'
import MailIcon from 'assets/icons/mail_website.svg'
import { DeleteOutlined } from '@ant-design/icons'

export default function Lightbox(props) {
  const myUser = useSelector(state => state.auth.user)
  const [isDeleting, setIsDeleting] = useState(false)

  const downloadImage = () => {
    window.open(props.selectedRecog.image, '_blank')
  }

  const shareLinkedin = () => {
    const url = encodeURIComponent(`${process.env.REACT_APP_MDC_ASSETS}/${props.selectedRecog.id}a${myUser.id}?type=rank`)
    const shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${url}`;
    window.open(shareUrl, '_blank')
  }

  const shareTwitter = () => {
    const url = encodeURIComponent(`${process.env.REACT_APP_MDC_ASSETS}/${props.selectedRecog.id}a${myUser.id}?type=rank`)
    const shareUrl = `https://twitter.com/intent/tweet?&url=${url}`
    window.open(shareUrl, '_blank')
  }

  const shareFacebook = () => {
    const url = encodeURIComponent(`${process.env.REACT_APP_MDC_ASSETS}/${props.selectedRecog.id}a${myUser.id}?type=rank`)
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`
    window.open(shareUrl, '_blank')
  }

  const shareEmail = () => {
    const url = `${process.env.REACT_APP_MDC_ASSETS}/${props.selectedRecog.id}a${myUser.id}?type=rank`
    const body = `
      <a href="${url}">
        Rank Recognition Image
      </a>
    `
    const shareUrl = `mailto:?subject=Rank%20Recognition&body=${body}`
    window.open(shareUrl)
  }

  const copyLink = () => {
    copy(`${process.env.REACT_APP_MDC_ASSETS}/${props.selectedRecog.id}a${myUser.id}?type=rank`);
    SuccessNotification("Successfully copied to clipboard");
  }

  const onDeleteDone = (res) => {
    SuccessNotification(res.message)
    setIsDeleting(false)
    props.loadRecogs()
    props.setSelectedRecog(undefined)
  }
  const onDeleteFail = () => {
    setIsDeleting(false)
  }
  const deleteRecog = () => {
    setIsDeleting(true)
    callDeleteApiWithAuth(`my_rank/rank_recognition/delete_recog/${props.selectedRecog.id}`, onDeleteDone, onDeleteFail)
  }

  return (
    <ModalStyled
      width={600}
      centered
      onCancel={()=>props.setSelectedRecog(undefined)}
    >
      <img src={props.selectedRecog.image} />
      <div className='social-links'>
        <span className='social-icon'
          onClick={shareLinkedin}
        >
          <img src={LinkedinIcon} />
        </span>
        <span className='social-icon'
          onClick={shareTwitter}
        >
          <img src={TwitterIcon} />
        </span>
        <span className='social-icon'
          onClick={shareFacebook}
        >
          <img src={FacebookIcon} />
        </span>
        <span className='social-icon'
          onClick={shareEmail}
        >
          <img src={MailIcon} />
        </span>
        <Button className='download-btn'
          onClick={copyLink}
        >
          Copy Link
        </Button>
        <span style={{width: 8}} />
        <Button className='download-btn'
          onClick={downloadImage}
        >
          <DownloadIcon />
          Download Image
        </Button>
        <span style={{width: 8}} />
        <Popconfirm onConfirm={deleteRecog}>
          <Button className='download-btn delete-btn'
            loading={isDeleting} 
          >
            <DeleteOutlined />
            Delete
          </Button>
        </Popconfirm>

      </div>
    </ModalStyled>
  )
}

const ModalStyled = styled(Modal)`
  .ant-modal-close {
    top: -16px; right: -16px;
    border-radius: 50%;
    background: white;
  }
  .ant-modal-close-x {
    width: 32px;
    height: 32px;
    line-height: 28px;
  }
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
  }
  img {
    width: 100%;
  }
  .social-links {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 20px;
    justify-content: center;
    padding-top: 10px;
    background: white;
    .social-icon {
      margin-right: 8px;
      margin-bottom: 8px;
      cursor: pointer;
      color: white;
      img {
        width: 32px;
        height: 32px;
      }
    }
    .download-btn {
      display: flex;
      align-items: center;
      background: transparent;
      color: #1C67FF;
      border: 1px solid #1C67FF;
      border-radius: 4px;
      font-size: 16px;
      height: 34px;
      margin-bottom: 8px;
    }
    .delete-btn {
      color: #ff4d4f;
      border: 1px solid #ff4d4f;
    }
  }
`

import styled from "styled-components";

export const Wrapper = styled.div`
  .main-container {
    padding-bottom: 80px;
    .replicate-alert {
      align-items: baseline;
    }
    .filter-options {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .select-box {
        min-width: 200px;
      }
      .select-video-section {
        display: flex;
        align-items: center;
        label {
          margin-right: 4px;
          color: #7f8fa3;
        }
        .play-icon {
          font-size: 28px;
          color: #1890ff;
          margin-left: 6px;
          cursor: pointer;
        }
        .loading-icon {
          font-size: 28px;
          color #1890ff;
          margin-left: 6px;
        }
      }
    }
  }
  .loading-root {
    width: 100%;
    height: 400px;
  }
  @media only screen and (max-width: 575px) {
    .filter-options {
      flex-direction: column;
      .select-wrapper {
        width: 100%;
      }
      .select-box {
        width: 100%;
        margin-top: 10px;
      }
    }
  }
`;

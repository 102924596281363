import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Modal,
  Input,
  Button,
  Popconfirm,
  SuccessNotification,
  ErrorNotification,
  message,
  Row,
  Col,
  DatePicker,
  PasswordInput,
  Radio,
  FormItem,
  Select,
  AutoComplete,
  Tag,
} from "components/common";
import {
  callGetApiWithAuth,
  callPostApiWithAuth,
  callPutApiWithAuth,
} from "utils/api";
import moment from "moment";
import { countryStates } from "common/country";
import { useSelector } from "react-redux";

export default function UpdateCustomersModal(props) {
  const [customerNames, setCustomerNames] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [mode, setMode] = useState("create");

  const handleModeChange = (e) => {
    setMode(e.target.value);
  };

  useEffect(() => {
    let customerNames0 = props.data.customer_names;
    setCustomerNames(customerNames0.split(","));
  }, [props.data]);

  return (
    <Modal onCancel={() => props.setModalMode(undefined)} width={800}>
      <Wrapper>
        <div className="modal-new-title">Update Customer Names</div>

        {/* <Radio.Group
          onChange={handleModeChange}
          value={mode}
          style={{ marginBottom: 8, borderRadius: "10px" }}
        >
          <Radio.Button value="asignee">Asignee User</Radio.Button>
          <Radio.Button value="create">Create User</Radio.Button>
        </Radio.Group> */}

        {mode === "asignee" ? (
          <AsigneeUser
            ticketId={props.data.id}
            setModalMode={(e) => props.setModalMode(e)}
            handleReload={() => props.handleSearch()}
            customerNames={customerNames}
          />
        ) : (
          <CreateUser
            ticketId={props.data.id}
            setModalMode={(e) => props.setModalMode(e)}
            handleReload={() => props.handleSearch()}
            customerNames={customerNames}
          />
        )}
      </Wrapper>
    </Modal>
  );
}

const AsigneeUser = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);

  const onSearchDone = (res) => {
    setUsers(res.data);
  };

  const onSearchUser = (v) => {
    callGetApiWithAuth(`event_ticket/search_user/${v}`, onSearchDone);
  };

  const onSelectUser = (_, option) => {
    setIsLoading(true);

    const body = {
      customer_names: [...props.customerNames, option.value],
    };

    callPutApiWithAuth(
      `event_ticket/${props.ticketId}/customer_names`,
      body,
      onUpdateDone,
      onUpdateFail
    );
  };

  const onUpdateDone = (res) => {
    setIsLoading(false);
    SuccessNotification(res.message);
    props.handleReload();
  };

  const onUpdateFail = () => {
    setIsLoading(false);
  };

  const onRemoveCustomerUser = (e, removeId) => {
    e.preventDefault();

    setIsLoading(true);

    const body = {
      customer_names: props.customerNames.filter((_, id) => id !== removeId),
    };

    callPutApiWithAuth(
      `event_ticket/${props.ticketId}/customer_names`,
      body,
      onUpdateDone,
      onUpdateFail
    );
  };

  return (
    <div>
      <div style={{ margin: "20px 0px" }}>
        {props.customerNames.map((row, id) => (
          <Tag
            key={id}
            closable
            onClose={(e) => onRemoveCustomerUser(e, id)}
            style={{
              // height: "42px",
              fontSize: "16px",
              borderRadius: "10px",
              alignItems: "center",
              padding: "12px 6px",
            }}
          >
            {row}
          </Tag>
        ))}
      </div>

      <AutoComplete
        onSearch={onSearchUser}
        options={users.map((el) => ({
          key: el.id,
          label: `${el.first_name} ${el.last_name}`,
          value: `${el.first_name} ${el.last_name}`,
        }))}
        style={{ width: "100%" }}
        onSelect={onSelectUser}
        label="Username or User ID"
        disabled={isLoading}
      />

      <div className="form-action">
        <Button
          onClick={() => props.setModalMode(undefined)}
          bgColor="white"
          color="#212B36"
          loading={isLoading}
        >
          {isLoading ? "Updating" : "Cancel"}
        </Button>
        {/* <Popconfirm onConfirm={handleSubmit}>
          <Button loading={isUpdating}>Update</Button>
        </Popconfirm> */}
      </div>
    </div>
  );
};
const CreateUser = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const myUser = useSelector((state) => state.auth.user);
  // const currentPosition = useSelector((state) => state.mlm.currentPosition);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    // userName: "",
    dateOfBirth: "",
    // country: "",
    // ownershipName: "",
    // password: "",
    // confirmPassword: "",
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    // userName: "",
    dateOfBirth: "",
    // country: "",
    // password: "",
    // confirmPassword: "",
  });

  const onChangeFormData = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const validateFormData = () => {
    let errors_ = {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      dateOfBirth: "",
      // userName: "",
      // country: "",
      // password: "",
      // confirmPassword: "",
    };

    if (!formData.firstName.trim()) {
      errors_["firstName"] = "Please enter your first name";
    }

    if (!formData.lastName.trim()) {
      errors_["lastName"] = "Please enter your last name";
    }

    let emailRegex =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!formData.email.trim()) {
      errors_["email"] = "Please enter your email";
    } else if (emailRegex.exec(formData.email) == null) {
      errors_["email"] = "Please enter valid email";
    }

    if (!formData.dateOfBirth.trim()) {
      errors_["lastName"] = "Please enter date of birth";
    }

    if (!formData.phone.trim()) {
      errors_["lastName"] = "Please enter your phone";
    }

    // if (!formData.userName.trim()) {
    //   errors_["userName"] = "Please enter your username";
    // }

    // if (!formData.country.trim()) {
    //   errors_["country"] = "Please enter your country";
    // }

    // let passwordRegex =
    //   /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/;
    // if (!formData.password) {
    //   errors_["password"] = "Please enter password";
    // } else if (passwordRegex.exec(formData.password) == null) {
    //   errors_["password"] =
    //     "Password should contain uppercase, lowercase, numeric, special characters and longer than 8 characters.";
    // }
    // if (formData.password !== formData.confirmPassword) {
    //   errors_["confirmPassword"] = "Confirm password does not match";
    // }

    if (
      errors_.firstName ||
      errors_.lastName ||
      errors_.email ||
      errors_.phone ||
      errors_.dateOfBirth
      // errors_.userName ||
      // errors_.country ||
      // errors_.password ||
      // errors_.confirmPassword
    ) {
      setErrors(errors_);
      return false;
    } else {
      setErrors(errors_);
      return true;
    }
  };

  const onUpdateCustomerUsers = (newCustomerName) => {
    setIsLoading(true);

    const body = {
      customer_names: [newCustomerName],
    };

    callPutApiWithAuth(
      `event_ticket/${props.ticketId}/customer_names`,
      body,
      onUpdateDone,
      onUpdateFail
    );
  };

  const onUpdateDone = (res) => {
    setIsLoading(false);
    SuccessNotification(res.message);
    props.handleReload();

    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      // userName: "",
      dateOfBirth: "",
      // country: "",
      // ownershipName: "",
      // password: "",
      // confirmPassword: "",
    });

    props.setModalMode(undefined);
  };

  const onUpdateFail = () => {
    setIsLoading(false);
  };

  const onSuccessFreeEnroll = (res) => {
    onUpdateCustomerUsers(
      `${res.data.user.first_name} ${res.data.user.last_name}`
    );
  };

  const onFailFreeEnroll = (errMessage) => {
    setIsLoading(false);
    ErrorNotification(errMessage);
  };

  const handleCreate = () => {
    if (!validateFormData()) return;

    setIsLoading(true);

    const data = {
      user: {
        first_name: formData.firstName,
        last_name: formData.lastName,
        company_name: formData.ownershipName,
        email: formData.email,
        dob: formData.dateOfBirth,
        phone: formData.phone,
        /*
        username: formData.userName,
        country: formData.country,
        state: null,
        password: formData.password,
        type: 2, // preferred customer
        enroller_id: myUser.id,
        placement_username: myUser.username,
        */
      },
    };

    callPostApiWithAuth(
      `event_ticket/free_enroll`,
      data,
      onSuccessFreeEnroll,
      onFailFreeEnroll
    );
  };

  return (
    <div>
      <Row>
        <Col sm={12}>
          <FormItem
            label="First Name*"
            errorMessages={[{ message: errors.firstName }]}
          >
            <Input
              value={formData.firstName}
              onChange={(e) => onChangeFormData("firstName", e.target.value)}
            />
          </FormItem>
        </Col>
        <Col sm={12}>
          <FormItem
            label="Last Name*"
            errorMessages={[{ message: errors.lastName }]}
          >
            <Input
              value={formData.lastName}
              onChange={(e) => onChangeFormData("lastName", e.target.value)}
            />
          </FormItem>
        </Col>
        <Col sm={12}>
          <FormItem label="Email*" errorMessages={[{ message: errors.email }]}>
            <Input
              value={formData.email}
              onChange={(e) => onChangeFormData("email", e.target.value)}
            />
          </FormItem>
        </Col>
        <Col sm={12}>
          <FormItem label="Phone">
            <Input
              value={formData.phone}
              onChange={(e) => onChangeFormData("phone", e.target.value)}
            />
          </FormItem>
        </Col>
        {/* <Col sm={12}>
          <FormItem
            label="Username*"
            errorMessages={[{ message: errors.userName }]}
          >
            <Input
              value={formData.userName}
              onChange={(e) => onChangeFormData("userName", e.target.value)}
            />
          </FormItem>
        </Col> */}
        <Col sm={12}>
          <FormItem label="Date of Birth">
            <DatePicker
              value={formData.dateOfBirth}
              onChange={(value) =>
                onChangeFormData(
                  "dateOfBirth",
                  moment(value).format("YYYY-MM-DD")
                )
              }
            />
          </FormItem>
        </Col>
        {/* <Col sm={12}>
          <FormItem
            label="Country*"
            errorMessages={[{ message: errors.country }]}
          >
            <Select
              value={formData.country}
              options={[
                ...countryStates.map((el) => ({
                  label: el.name,
                  value: el.code2,
                  key: el.code2,
                })),
              ]}
              onChange={(value) => onChangeFormData("country", value)}
            />
          </FormItem>
        </Col>
        <Col sm={12}>
          <FormItem label="Ownership Name">
            <Input
              value={formData.ownershipName}
              onChange={(e) =>
                onChangeFormData("ownershipName", e.target.value)
              }
            />
          </FormItem>
        </Col>
        <Col sm={12}>
          <FormItem
            label="Password"
            errorMessages={[{ message: errors.password }]}
          >
            <PasswordInput
              className="password"
              value={formData.password}
              onChange={(e) => onChangeFormData("password", e.target.value)}
            />
          </FormItem>
        </Col>
        <Col sm={12}>
          <FormItem
            label="Confirm Password"
            errorMessages={[{ message: errors.confirmPassword }]}
          >
            <PasswordInput
              className="password"
              value={formData.confirmPassword}
              onChange={(e) =>
                onChangeFormData("confirmPassword", e.target.value)
              }
            />
          </FormItem>
        </Col> */}
      </Row>

      <div className="form-action">
        <Button
          onClick={() => props.setModalMode(undefined)}
          bgColor="white"
          color="#212B36"
        >
          Cancel
        </Button>
        <Popconfirm onConfirm={handleCreate}>
          <Button loading={isLoading}>Create</Button>
        </Popconfirm>
      </div>
    </div>
  );
};

const Wrapper = styled.div`
  .modal-new-title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #212b36;
    margin-bottom: 20px;
  }

  .form-group {
    margin: 8px 0;
    .ant-form-item-label {
      top: 8px;
    }
    .ant-form-item-label-active {
      transform: translateY(-16px) scale(1);
    }
  }

  .form-action {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 16px;
    width: 100%;
    margin-top: 24px;
  }

  .password {
    height: 48px;
    border: 1px solid rgba(145, 158, 171, 0.32);
    border-radius: 8px !important;
  }

  .ant-col {
    width: 100%;
    padding: 0px 8px;
  }
`;

import React from "react";
import styled from "styled-components";
import NoPhoto from "assets/images/no_photo.png";
import LogoImg from 'assets/images/rank_templates/logo2.png';
import RankBgImg from 'assets/images/rank_templates/Flyer-5K.png'

export default function Flyer5KTemplate(props) {
  return (
    <Wrapper>
      <div className="content-cont">
        <div className="photo-wrap">
          <div className="photo-inwrap">
            <img src={props.photo || NoPhoto} />
          </div>
        </div>
        <div className="name-wrap">
          {`${props.user.first_name}`}&nbsp;
          {`${props.user.last_name}`}
        </div>
        <div className="congrat-wrap">
          Congratulations!
        </div>
        <div className="logo-wrap">
          <img src={LogoImg} />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${RankBgImg});
  background-size: cover;
  background-repeat: no-repeat;
  .content-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .photo-wrap {
    width: 63%;
    margin-top: 60px;
  }
  .photo-inwrap {
    width: 100%;
    padding-top: 100%;
    position: relative;
  }
  .photo-wrap img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  .name-wrap {
    font-family: 'Montserrat Bold';
    font-size: 48px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin-top: 50px;
  }
  .congrat-wrap {
    font-size: 90px;
    font-family: 'rocknessregular';
    font-weight: normal;
    line-height: 1.1em;
    color: #fed045;
  }
  .logo-wrap {
    margin-top: 20px;
    width: 12%;
  }
  .logo-wrap img {
    width: 100%;
  }
`;

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
  Row, Col,
  FormItem, Input, TextArea, Radio, Select,
  Button, Popconfirm,
  SuccessNotification
} from 'components/common';
import { callPostApiWithAuth } from 'utils/api';

export default function ApplyPage() {
  const dispatch = useDispatch();
  const myUser = useSelector(state=>state.auth.user)
  const [formData, setFormData] = useState({
    company_name: '',
    first_name: '', last_name: '',
    email: '', phone: '',
    is_self_employee: 1,
    is_filed: 1,
  })
  const [errorMessages, setErrorMessages] = useState([])
  const [isUpdating, setIsUpdating] = useState(false)

  const onFormInput = (field, value) => {
    setFormData({
      ...formData,
      [field]: value
    })
  }

  const onSubmitDone = (res) => {
    SuccessNotification(res.message)
    setIsUpdating(false)
    dispatch({ type: 'auth.RELOAD' })
    window.open(res.data.ref_url, '_blank')
  }
  const onSubmitFail = () => {
    setIsUpdating(false)
  }
  const handleSubmit = () => {
    // Validation
    let errorMessages0 = [];
    [
      'first_name', 'last_name',
      'email', 'phone',
      'is_self_employee', 'is_filed',
    ].forEach(k => {
      if (!formData[k]) {
        errorMessages0.push({
          field: k, 
          message: 'Required'
        })
      }
    });
    const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (formData['email'] && 
      emailRegex.exec(formData['email'])==null
    ) {
      errorMessages0.push({
        field: 'email',
        message: 'Invalid email'
      })
    }
    // const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    const phoneRegex = /^\d{10}$/;
    if (formData['phone'] && 
      phoneRegex.exec(formData['phone'])==null
    ) {
      errorMessages0.push({
        field: 'phone',
        message: 'Invalid phone number. Phone number should be 1234567890 format.'
      })
    }
    setErrorMessages(errorMessages0);
    if (errorMessages0.length>0) return;

    const body = {
      ...formData,
      email: formData.email.toLowerCase().trim(),
      phone: formData.phone.trim(),
    };
    setIsUpdating(true)
    callPostApiWithAuth(`setc/submit_form`, body,
      onSubmitDone, onSubmitFail
    )
  }

  useEffect(() => {
    if (myUser && 
      myUser.setc_setting
    ) {
      setFormData({
        ...formData,
        ...myUser.setc_setting,
      })
    } else if (myUser) {
      setFormData({
        ...formData,
        first_name: myUser.first_name,
        last_name: myUser.last_name,
        email: myUser.email,
        phone: myUser.phone,
      })
    }
  }, [myUser])

  return (
    <Wrapper>
      <div className='tax-root'>
        <div className='container'>
          <div className='tax-form'>
            <h2>
              See if you pre-qualify in 2 easy questions!
            </h2>
            <Row gutter={[15, 15]}>
              <Col xs={24} sm={12}>
                <FormItem label={'First Name*'}
                  errorMessages={errorMessages.filter(el => 
                    el.field === 'first_name'
                  )}
                >
                  <Input value={formData.first_name}
                    onChange={e=>onFormInput('first_name', e.target.value)}
                    placeholder={'Enter first name'}
                  />
                </FormItem>
              </Col>
              <Col xs={24} sm={12}>
                <FormItem label={'Last Name*'}
                  errorMessages={errorMessages.filter(el => 
                    el.field === 'last_name'
                  )}
                >
                  <Input value={formData.last_name}
                    onChange={e=>onFormInput('last_name', e.target.value)}
                    placeholder={'Enter last name'}
                  />
                </FormItem>
              </Col>
              <Col xs={24} sm={12}>
                <FormItem label={'Email*'}
                  errorMessages={errorMessages.filter(el => 
                    el.field === 'email'
                  )}
                >
                  <Input value={formData.email}
                    onChange={e=>onFormInput('email', e.target.value)}
                    placeholder={'Enter email address'}
                    disabled
                  />
                </FormItem>
              </Col>
              <Col xs={24} sm={12}>
                <FormItem label={'Phone*'}
                  errorMessages={errorMessages.filter(el => 
                    el.field === 'phone'
                  )}
                >
                  <Input value={formData.phone}
                    onChange={e=>onFormInput('phone', e.target.value)}
                    placeholder={'Enter phone number'}
                  />
                </FormItem>
              </Col>
              <Col xs={24}>
                <FormItem label={'Were you self-employed in 2020 and/or 2021?'}
                  errorMessages={errorMessages.filter(el => 
                    el.field === 'is_self_employee'
                  )}
                >
                  <Select value={formData.is_self_employee*1}
                    onChange={v=>onFormInput('is_self_employee', v)}
                    options={[
                      { value: 1, label: 'Yes' },
                      { value: 2, label: 'No' },
                    ]}
                  />
                </FormItem>
              </Col>
              <Col xs={24}>
                <FormItem label={'Did you file a schedule SE (Self-employment tax) form with your tax returns in 2020 or 2021?'}
                  errorMessages={errorMessages.filter(el => 
                    el.field === 'is_filed'
                  )}
                >
                  <Select value={formData.is_filed*1}
                    onChange={v=>onFormInput('is_filed', v)}
                    options={[
                      { value: 1, label: 'Yes' },
                      { value: 2, label: 'No' },
                    ]}
                  />
                </FormItem>
              </Col>
              <Col xs={24}>
                <div className='action-group'>
                  {(myUser && myUser.setc_setting) ?
                  <Button className='submitted-btn'>
                    Already Submitted
                  </Button>
                  : 
                  <Popconfirm onConfirm={handleSubmit}>
                    <Button loading={isUpdating}>
                      Submit
                    </Button>
                  </Popconfirm>}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className="dis-root">
        <div className='container'>
          <h4>Disclaimer</h4>
          <p>
            Please note that MyDailyChoice is an independent entity and is not
            associated with, endorsed by, or officially affiliated with the
            Internal Revenue Service (IRS) or any of its subsidiaries or
            affiliates. For official IRS information, please visit www.irs.gov.
            The term "IRS," along with any related names, logos, symbols, and
            images, is the property of their respective owners and is registered
            as their trademark. Any mentions of the IRS on our website are solely
            for the purpose of providing information and commentary on relevant
            topics and are intended for informational use only. We do not assert
            any representation or special relationship with the IRS. If you have
            inquiries or issues related to taxes or the IRS, we recommend
            contacting the IRS directly.
          </p>
        </div>
      </div>

    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: -24px -40px;
  @media (max-width: 768px) {
    margin: 0;
  }
  .tax-root {
    background-color: #EDF9FE;
    padding: 80px 0;
    .tax-form {
      padding: 100px 80px;
      background: #fff;
      @media (max-width: 768px) {
        padding: 0;
        background: transparent;
      }
      }
    }
    h2 {
      text-align: center;
      margin-bottom: 50px;
    }
    .action-group {
      width: 100%;
      button {
        width: 100%;
        &.submitted-btn {
          background-color: #52c41a;
        }
      }
    }
  }
  .dis-root {
    padding: 60px 0;
  }
`

import React from 'react'
import {
  Select,
} from 'components/common'

export default function PowerLegTd(props) {
  const onSelectPowerLeg = (v) =>{
    let tableData0 = [
      ...props.tableData
    ]
    tableData0[props.index] = {
      ...props.data,
      new_power_leg: v
    }
    props.setTableData(tableData0)
  }

  return (
    <div className='d-flex align-itmes-center'
      style={{width: 150}}
    >
      {(props.data.pos_type && 
        props.data.pos_type=='placement'
      ) && 
        <Select 
          value={props.data.new_power_leg}
          onChange={onSelectPowerLeg}
          options={[
            {
              label: 'Left',
              value: 1
            },
            {
              label: 'Right',
              value: 2
            }
          ]}
          style={{width: 100}}
        />
      }
    </div>    
  )
}

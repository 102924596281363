import React from 'react'
import { Modal, VideoPlayer, VimeoPlayer } from 'components/common'
import styled from "styled-components";

export default function PopUpModal ({
  data,
  closeEdition
}) {
  
  return (
    <Wrapper
      centered
      closable
      width={800}
      onCancel={closeEdition}
    >
      <div className="modal-root">
        <div
          style={{
            cursor: "pointer",
            width: "100%",
            height: "100%",
            objectFit: "cover"
          }}
        >
          {data.type*1 === 1 ?
            <a href={data.link_url ? data.link_url : "#"} target={data.link_url ? "_blank" : ""}><img alt="popup" src={data.image}  className="modal-image" /></a>
            :
            <VimeoPlayer sourceUrl={data.video} />
          }
          {data.content && 
            <div className={'content-root'}
              dangerouslySetInnerHTML={{ __html: data.content }} 
            />
          }
        </div>  
      </div>
    </Wrapper>
  )
}

const Wrapper = styled(Modal)`
  .ant-modal-content {
    background-color: #000000;
    box-shadow: unset;
  }
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-close {
    top: -30px;
    right: 0;
    background-color: #ffffff;
    border-radius: 50%;
  }
  .ant-modal-close-x {
    width: 30px;
    height: 30px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .play-icon {
    color: #ff632c;
    font-size: 80px;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .content-root {
    background: #fff;
  }
`

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ShippingForm from "./forms/ShippingForm";
import BillingForm from "./forms/BillingForm";
import CheckoutSideCart from "./forms/CheckoutSideCart";
import Confirmation from "./forms/Confirmation";
import { message } from "antd";
import { callGetApi, callPostApiWithAuth } from "utils/api";
import { varValue } from "common/var";
import { Wrapper } from "./CheckoutPage.styled";
import { NoData, Row, Col } from "components/common";

export default function IndexPage() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const orderFrom = useSelector((state) => state.checkout.orderFrom);
  const orderDetails = useSelector((state) => state.checkout.orderDetails);
  const subscriptionIds = useSelector(
    (state) => state.checkout.subscriptionIds
  );
  const myUser = useSelector((state) => state.auth.user);
  const [step, setStep] = useState("");
  const [hasPhysicalOrder, setHasPhysicalOrder] = useState(false);
  const [payType, setPayType] = useState("1|");
  const [cCLimitPrice, setCcLimitPrice] = useState(undefined);
  const [distCenter, setDistCenter] = useState(undefined);
  const [bankFormData, setBankFormData] = useState({
    id: "",
    billing_address: "",
    billing_address_line2: "",
    billing_city: "",
    billing_state: "",
    billing_zipcode: "",
    billing_country: "",
    bk_name: "",
    bk_bank: "",
    bk_account: "",
    bk_routing: "",
    pay_type: 3,
  });
  const [creditFormData, setCreditFormData] = useState({
    id: "",
    billing_address: "",
    billing_address_line2: "",
    billing_city: "",
    billing_state: "",
    billing_zipcode: "",
    billing_country: "",
    cc_name: "",
    cc_type: "",
    cc_number: "",
    cc_cvv: "",
    cc_expiry_month: "",
    cc_expiry_year: "",
    pay_type: 1,
  });
  const [shippingData, setShippingData] = useState({
    id: "",
  });
  const [tax, setTax] = useState(undefined);
  const [shippingPrice, setShippingPrice] = useState(undefined);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [confirmResult, setConfirmResult] = useState(undefined);

  const onGetSuccessShippingAndTaxPrice = (data) => {
    setIsLoading(false);
    setShippingPrice(data.data.shipping_price);
    setTax(data.data.tax_cost);
    setStep("billing-form");
  };

  const onGetFailedShippingAndTaxPrice = () => {
    setIsLoading(false);
  };

  const getShippingAndTaxPrice = () => {
    setShippingPrice(0);
    let data = {
      shippingDetail: {
        id: shippingData.id,
      },
      orderDetails: orderDetails.map((el) => ({
        ...el,
        product_id: el.product.id,
      })),
    };
    setIsLoading(true);
    callPostApiWithAuth(
      `shop/get_shipping_price`,
      data,
      onGetSuccessShippingAndTaxPrice,
      onGetFailedShippingAndTaxPrice
    );
  };

  // for digital only order
  const onGetDigitalTaxPrice = (res) => {
    setIsLoading(false);
    setTax(res.data.tax_cost);
  };
  const onFailDigitalTaxPrice = () => {
    setIsLoading(false);
  };
  const getDigitalTaxPrice = () => {
    setShippingPrice(0);

    let body = {
      orderDetails: orderDetails.map((el) => ({
        ...el,
        product_id: el.product.id,
      })),
      isBucketPay: false,
    };
    setIsLoading(true);
    callPostApiWithAuth(
      `shop/digital_tax_price`,
      body,
      onGetDigitalTaxPrice,
      onFailDigitalTaxPrice
    );
  };

  const onSuccessCheckout = (data) => {
    setIsSubmiting(false);
    setStep("confirmation");
    setConfirmResult(data.data);
    dispatch({
      type: "checkout.CLEAR_CART",
    });
    dispatch({ type: "auth.CHECK_HOLD" });
    dispatch({ type: "auth.RELOAD" });
  };

  const onFailCheckout = (errMessage) => {
    setIsSubmiting(false);
    message.error(errMessage);
    dispatch({ type: "auth.CHECK_HOLD" });
  };

  const handleCheckout = () => {
    const payType2 = payType.split("|")[0];
    let data = {
      order_from: orderFrom,
      dist_center_id: distCenter?.id,
      subscription_ids: subscriptionIds, // it will only be used on subscription_resume, subscription_manual
      shippingDetail: {
        id: shippingData.id,
      },
      billingDetail: {
        id:
          payType === "1|" ||
          payType === "9|" ||
          payType === "10|" ||
          payType === "11|"
            ? creditFormData.id
            : payType === "3|"
            ? bankFormData.id
            : "",
        pay_type: payType2,
        coin_type: "btc",
      },
    };
    if (payType2 * 1 == 4 || payType2 * 1 == 5) {
      data.billingDetail.billing_country = myUser.country;
    }
    if (payType2 * 1 == 4) {
      data.billingDetail.manual_method = payType.split("|")[1];
    }
    if (payType2 * 1 == 5) {
      data.billingDetail.coin_method = payType.split("|")[1];
      if (data.billingDetail.coin_method == "Crypto(USDTERC20)") {
        data.billingDetail.coin_type = "USDTERC20";
      } else if (data.billingDetail.coin_method == "Crypto(USDTTRC20)") {
        data.billingDetail.coin_type = "USDTTRC20";
      }
    }
    let orderDetails_ = [];
    for (let item of orderDetails) {
      orderDetails_.push({
        product_id: item.product.id,
        quantity: item.quantity,
        pay_cycle: item.pay_cycle,
        contained_products:
          item.contained_products.length > 0
            ? item.contained_products.map((el) => ({
                product_id: el.product_id,
                quantity: el.quantity,
              }))
            : [],
      });
    }
    data.orderDetails = orderDetails_;
    setIsSubmiting(true);
    callPostApiWithAuth(
      `shop/checkout`,
      data,
      onSuccessCheckout,
      onFailCheckout
    );
  };

  const onSuccessGetCcLimitPrice = ({ data }) => {
    setCcLimitPrice(data);
  };

  const onFailedGetCcLimitPrice = (err) => {
    setCcLimitPrice(0);
  };

  const loadCreditLimitPrice = () => {
    callGetApi(
      "common/cc_max_amount",
      onSuccessGetCcLimitPrice,
      onFailedGetCcLimitPrice,
      { isCorp: true }
    );
  };

  useEffect(() => {
    if (myUser) {
      setCreditFormData({
        ...creditFormData,
        billing_state: myUser.state,
        billing_country: myUser.country,
      });
      setBankFormData({
        ...bankFormData,
        billing_state: myUser.state,
        billing_country: myUser.country,
      });
    }
  }, [myUser]);

  useEffect(() => {
    if (
      orderDetails.length > 0 &&
      orderDetails.filter(
        (el) => el.product.type * 1 === varValue("product.type", "physical") * 1
      ).length > 0
    ) {
      setHasPhysicalOrder(true);
    } else if (
      orderDetails.length > 0 &&
      orderDetails.filter(
        (el) => el.product.type * 1 === varValue("product.type", "physical") * 1
      ).length === 0
    ) {
      setHasPhysicalOrder(false);
    }
  }, [orderDetails]);

  useEffect(() => {
    if (hasPhysicalOrder) {
      setStep("shipping-form");
    } else {
      setStep("billing-form");
    }
  }, [hasPhysicalOrder]);

  useEffect(() => {
    dispatch({
      type: "ui.HIDE_SIDE_CART",
    });
  }, []);

  useEffect(() => {
    loadCreditLimitPrice();
  }, []);

  useEffect(() => {
    let isBucketPay0 = false;
    if (payType === '14|') {
      isBucketPay0 = true;
    }
    dispatch({
      type: 'checkout.SET_IS_BUCKET_PAY',
      payload: {
        isBucketPay: isBucketPay0
      }
    })
  }, [payType])

  if (!confirmResult && orderDetails.length == 0) {
    return (
      <Wrapper>
        <div className="container">
          <NoData title="There are no items." />
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div className="main-container">
        {step != "confirmation" && (
          <>
            <Row gutter={[24, 24]}>
              <Col
                xs={24}
                lg={12}
                xl={12}
                xxl={16}
                className="checkout-cart-for"
              >
                <div className="left-section checkout-section">
                  <div className="main-content">
                    <div className="check-steps-details">
                      {step == "shipping-form" && (
                        <ShippingForm
                          distCenter={distCenter}
                          setDistCenter={setDistCenter}
                          orderDetails={orderDetails}
                          shippingData={shippingData}
                          setShippingData={setShippingData}
                          setStep={setStep}
                          getShippingAndTaxPrice={getShippingAndTaxPrice}
                          isLoading={isLoading}
                        />
                      )}
                      {step == "billing-form" && (
                        <BillingForm
                          myUser={myUser}
                          payType={payType}
                          setPayType={setPayType}
                          bankFormData={bankFormData}
                          setBankFormData={setBankFormData}
                          creditFormData={creditFormData}
                          setCreditFormData={setCreditFormData}
                          handleCheckout={handleCheckout}
                          setStep={setStep}
                          tax={tax}
                          isSubmiting={isSubmiting}
                          hasPhysicalOrder={hasPhysicalOrder}
                          totalPrice={totalPrice}
                          cCLimitPrice={cCLimitPrice}
                          shippingPrice={shippingPrice}
                          getDigitalTaxPrice={getDigitalTaxPrice}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                xs={24}
                lg={12}
                xl={12}
                xxl={8}
                className="checkout-cart-for"
              >
                <CheckoutSideCart
                  shippingPrice={shippingPrice}
                  setShippingPrice={setShippingPrice}
                  tax={tax}
                  setTax={setTax}
                  setTotalPrice={setTotalPrice}
                  step={step}
                  setStep={setStep}
                  hasPhysicalOrder={hasPhysicalOrder}
                />
              </Col>
            </Row>
          </>
        )}
        {step == "confirmation" && (
          <Confirmation
            myUser={myUser}
            confirmResult={confirmResult}
            hasPhysicalOrder={hasPhysicalOrder}
          />
        )}
      </div>
    </Wrapper>
  );
}

import React from 'react';
import styled from 'styled-components'

export default function MdcLivePage() {
  return (
    <Wrapper className='white-rounded-bg'>
      <div className='video-wrapper'>
        <iframe src="https://vimeo.com/event/2783043/embed"
          width="100%" height="100%" frameborder="0"
          title="MDC Live"
        />
      </div>
      <div className='chat-wrapper'>
        <iframe src="https://vimeo.com/event/2783043/chat/"
          width="100%" height="100%" frameborder="0"
          title="MDC Live Chat"
        />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 120px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  .video-wrapper {
    width: calc(100% - 370px);
    height: 100%;
  }
  .chat-wrapper {
    width: 350px;
    height: 100%;
  }
  @media(max-width: 768px) {
    .video-wrapper {
      width: 100%;
      height: 400px;
    }
    .chat-wrapper {
      width: 100%;
      height: 400px;
    }
  }
`

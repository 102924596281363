import React, { useEffect, useState } from "react";
import { 
  Row, Col, Select, 
  VideoModal, Spin, Alert, 
  SuccessNotification, ErrorNotification
} from "components/common";
import YourWebsite from "./yourWebsite/YourWebsite";
import { PlayCircleFilled, LoadingOutlined } from "@ant-design/icons";
import { Wrapper } from "./ProductFunnelPage.styled";
import { uiVarOptions } from "common/uiVar";
import { 
  callGetApiWithAuth, 
  callPutApiWithAuth 
} from "utils/api";

export default function ProductFunnelPage() {
  const [capturePages, setCapturePages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [languageId, setLanguageId] = useState(1);
  const [languages, setLanguages] = useState([])
  const [tourVideos, setTourVideos] = useState([]);
  const [videoId, setVideoId] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  const [isSetting, setIsSetting] = useState(false);

  const onGetLanguages = (res) => {
    setLanguages(res.data)
  }
  const onFailLanguages = () => { }
  const loadLanguages = () => {
    callGetApiWithAuth(`common/languages`, onGetLanguages, onFailLanguages)
  }

  const onGetSuccessTourVideos = ({ data }) => {
    setTourVideos(data);
  };

  const onGetFailedTourVideos = () => {
    ErrorNotification("There is no video.");
  };

  const loadTourVideos = () => {
    callGetApiWithAuth("my_website/product/tour_video_list", onGetSuccessTourVideos, onGetFailedTourVideos);
  };

  const onGetSuccessSetting = ({ data }) => {
    if (data && data.tour_video_id) {
      setVideoId(data.tour_video_id);
    } else {
      setVideoId("");
    }
  };

  const onGetFailedSetting = () => {

  };

  const loadSetting = () => {
    callGetApiWithAuth("my_website/product/tour_video_setting", onGetSuccessSetting, onGetFailedSetting);
  };

  const onSetSuccessSetting = () => {
    setIsSetting(false);
    SuccessNotification("Tour video has been set successfully.");
    loadSetting();
    loadCapturePages();
  };

  const onFailedSetSetting = (err) => {
    setIsSetting(false);
    ErrorNotification(err);
  };

  const handleChangeVideo = (videoId0) => {
    if (!videoId0) {
      ErrorNotification("Please select video");
      return;
    }
    setIsSetting(true);
    const body = {
      tour_video_id: videoId0,
      capture_page_id: null
    }
    callPutApiWithAuth("my_website/product/tour_video_setting", 
      body, onSetSuccessSetting, onFailedSetSetting
    );
  };

  const onGetCapturePages = (res) => {
    setIsLoading(false)
    setCapturePages(res.data)
  }
  const onFailCapturePages = () => {
    setIsLoading(false)
  }
  const loadCapturePages = () => {
    setIsLoading(true)
    setCapturePages([])
    callGetApiWithAuth(`my_website/product/page_list`, 
      onGetCapturePages, onFailCapturePages
    )
  }

  useEffect(() => {
    loadLanguages()
    loadTourVideos();
    loadSetting();
    loadCapturePages();
  }, []);
  
  return (
    <Wrapper>
      <div className="main-container">
        <Row gutter={[20, 20]}>
          <Col xs={24}>
            <div className="filter-options">
              {/* <Select
                value={languageId}
                options={languages.map(el => ({
                  label: el.title, 
                  value: el.id
                }))}
                onChange={(v) => setLanguageId(v)}
                wrapperClassName="select-wrapper"
                className="select-box"
              /> */}
              {/* <div className="select-video-section">
                <label>
                  Tour Video:
                </label>
                <Select
                  value={videoId}
                  options={[
                    {
                      value: "",
                      key: "",
                      label: "Select Tour Video"
                    },
                    ...tourVideos.map((el) => ({
                      value: el.id,
                      key: el.id,
                      label: el.title
                    }))
                  ]}
                  onChange={(value) => handleChangeVideo(value)}
                  wrapperClassName="select-wrapper"
                  className="select-box"
                />
                {(!isSetting && videoId) ?
                  <PlayCircleFilled className="play-icon" 
                    onClick={()=>setIsPlaying(true)} 
                  />
                : isSetting ?
                  <LoadingOutlined className="loading-icon" />
                : ""}
              </div> */}
            </div>
          </Col>
          {isLoading && 
          <div className='loading-root'>
            <Spin spinning={true} />
          </div>
          }
          {capturePages.map(el => 
            <Col xs={24} sm={24} md={12} xl={8} xxl={6} key={el.id}>
              <YourWebsite data={el} 
                tourVideos={tourVideos}
              />
            </Col>
          )}
        </Row>
      </div>
      {(videoId && 
        tourVideos.filter((el) => 
          el.id*1 === videoId*1
        ).length > 0 && 
        tourVideos.filter((el) => 
          el.id*1 === videoId*1
        )[0].file && 
        isPlaying
      ) && 
        <VideoModal
          onCancel={()=>setIsPlaying(false)} 
          src={tourVideos.filter((el) => 
            el.id*1 === videoId*1
          )[0].file}
          width={1200}
          isVimeo
        />
      }
    </Wrapper>
  );
}

import React from "react";
import { Row, Col, GuagePlot, Spin } from "components/common";
import NoUserImage from "assets/images/user.svg";
import { ReactComponent as SettingIcon } from "assets/icons/setting.svg";
import { ReactComponent as GraySettingIcon } from "assets/icons/gray-setting.svg";
import { ReactComponent as AccountIcon } from "assets/icons/home/account-icon.svg";
import { ReactComponent as BRankIcon } from "assets/icons/home/b-rank.svg";
import { ReactComponent as CRankIcon } from "assets/icons/home/c-rank.svg";
import { ReactComponent as CvRankIcon } from "assets/icons/home/cv-rank.svg";
import { ReactComponent as PaidIcon } from "assets/icons/home/paid-rank.svg";
import { ReactComponent as PaymentIcon } from "assets/icons/home/payment-icon.svg";
import { ReactComponent as PcRankIcon } from "assets/icons/home/pc-rank.svg";
import { ReactComponent as ProfileIcon } from "assets/icons/home/profile-icon.svg";
import { ReactComponent as PvRankIcon } from "assets/icons/home/pv-rank.svg";
import { ReactComponent as SubscribeIcon } from "assets/icons/home/subscribe-icon.svg";

export default function AccountDetailsSectionSkeleton(props) {
  return (
    <Row gutter={[10, 10]} className="skeleton-section">
      <Col xs={24}>
        <div className="profile-p-box">
          <div className="profile-title">Profile</div>
          <SettingIcon />
        </div>
      </Col>
      <Col xs={24} md={12} className="left-details-container">
        <div className="left-box">
          <div className="personal-details-container">
            <img src={NoUserImage} />
            <div className="user-title">- - - - - -</div>
            <div className="user-id">- - - - - -</div>
          </div>
          <div className="rank-plus-section">
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12}>
                <div className="rank-plus-box">
                  <div className="rank-plus-flex">
                    <div className="rank-plus-title">Current Rank</div>
                    <div className="rank-plus-value">- - - - - -</div>
                  </div>
                  <CRankIcon />
                </div>
              </Col>
              <Col xs={24} sm={12}>
                <div className="rank-plus-box">
                  <div className="rank-plus-flex">
                    <div className="rank-plus-title">Paid Rank</div>
                    <div className="rank-plus-value">- - - - - -</div>
                  </div>
                  <PaidIcon />
                </div>
              </Col>
              <Col xs={24} sm={12}>
                <div className="rank-plus-box">
                  <div className="rank-plus-flex">
                    <div className="rank-plus-title">Binary Qualified</div>
                    <div className="rank-plus-value">- - - - - -</div>
                  </div>
                  <BRankIcon />
                </div>
              </Col>
              <Col xs={24} sm={12}>
                <div className="rank-plus-box">
                  <div className="rank-plus-flex">
                    <div className="rank-plus-title">Personal Volume</div>
                    <div className="rank-plus-value">- - - - - -</div>
                  </div>
                  <PvRankIcon />
                </div>
              </Col>
              <Col xs={24} sm={12}>
                <div className="rank-plus-box">
                  <div className="rank-plus-flex">
                    <div className="rank-plus-title">
                      Personal Customer Vol.
                    </div>
                    <div className="rank-plus-value">- - - - - -</div>
                  </div>
                  <PcRankIcon />
                </div>
              </Col>
              <Col xs={24} sm={12}>
                <div className="rank-plus-box">
                  <div className="rank-plus-flex">
                    <div className="rank-plus-title">Enroller Tree Volume</div>
                    <div className="rank-plus-value">- - - - - -</div>
                  </div>
                  <CvRankIcon />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
      <Col xs={24} md={12} className="right-details-container">
        <div>
          <Row gutter={[15, 10]} className="plot-container">
            <Col span={24}>
              <div className="iquid-container">
                <GuagePlot percent="0" />
              </div>
            </Col>
          </Row>
          <div className="right-box">
            <div className="account-and-profile-container">
              <div className="account-section">
                <div className="account-c-section">
                  <AccountIcon />
                  <div>
                    <div className="title">Account Details</div>
                    <div className="profile-section">
                      <div className="title">
                        Complete all of your account details and settings
                      </div>
                    </div>
                  </div>
                </div>
                <div className="percentage">-</div>
              </div>
            </div>

            <div className="account-and-profile-container">
              <div className="account-section">
                <div className="account-c-section">
                  <ProfileIcon />
                  <div>
                    <div className="title">Your Profile</div>
                    <div className="profile-section">
                      <div className="title">
                        Upload a profile image and complete your profile
                      </div>
                    </div>
                  </div>
                </div>
                <div className="percentage">-</div>
              </div>
            </div>

            <div className="account-and-profile-container">
              <div className="account-section">
                <div className="account-c-section">
                  <PaymentIcon />
                  <div>
                    <div className="title">Setup Payment Option</div>
                    <div className="profile-section">
                      <div className="title">
                        Set your payment option to receive your commissions
                      </div>
                    </div>
                  </div>
                </div>
                <div className="percentage">-</div>
              </div>
            </div>

            <div className="account-and-profile-container">
              <div className="account-section">
                <div className="account-c-section">
                  <SubscribeIcon />
                  <div>
                    <div className="title">Subscribe To Save</div>
                    <div className="profile-section">
                      <div className="title">
                        Maximize your savings with a subscription
                      </div>
                    </div>
                  </div>
                </div>
                <div className="percentage">-</div>
              </div>
            </div>
          </div>
        </div>
      </Col>
      {props.isLoading && (
        <Col xs={24} className="loading-spin-container">
          <Spin spinning={true} />
        </Col>
      )}
    </Row>
  );
}

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import html2canvas from "html2canvas";
import {
  Row, Col,
  Spin, Alert,
  Dropzone,
  SuccessNotification,
} from "components/common";
import { callGetApiWithAuth, callPostApiWithAuth } from "utils/api";
import Preview from "./Preview";
import Lightbox from "./Lightbox";
import { ReactComponent as UploadImg } from "assets/icons/Upload-icon.svg";

export default function RecogCard() {
  const myUser = useSelector((state) => state.auth.user);
  const [maxRank, setMaxRank] = useState({
    id: 1,
    name: "Unranked",
  });
  const [photo, setPhoto] = useState(undefined);
  const [recogs, setRecogs] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRecog, setSelectedRecog] = useState(undefined);

  const onGetMaxRank = (res) => {
    setMaxRank(res.data);
  };
  const onFailMaxRank = () => {};
  const getMaxRank = () => {
    callGetApiWithAuth(
      `my_rank/rank_recognition/max_rank`,
      onGetMaxRank,
      onFailMaxRank
    );
  };

  const onGetRecogs = (res) => {
    setRecogs(res.data);
    setIsLoading(false);
  };
  const onFailRecogs = () => {
    setIsLoading(false);
  };
  const loadRecogs = () => {
    setIsLoading(true);
    callGetApiWithAuth(
      `my_rank/rank_recognition/recogs`,
      onGetRecogs,
      onFailRecogs
    );
  };
  const handleOpenImage = async (files) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);
    fileReader.onload = () => {
      setPhoto(fileReader.result);
    };
  };
  const onUploadDone = (res) => {
    setIsUploading(false);
    SuccessNotification(res.message);
    loadRecogs();
  };
  const onUploadFail = () => {
    setIsUploading(false);
  };
  const onOpenedImage = async () => {
    const canvas = await html2canvas(document.getElementById("recogPreview"));
    let image = canvas.toDataURL("image/png", 1.0);
    image = image.replace("data:image/png;base64,", "");
    callPostApiWithAuth(
      `my_rank/rank_recognition/upload_recog`,
      { image },
      onUploadDone,
      onUploadFail
    );
  };

  useEffect(() => {
    if (photo) {
      setIsUploading(true);
      setTimeout(() => {
        onOpenedImage();
      }, [1000]);
    }
  }, [photo]);

  useEffect(() => {
    getMaxRank();
    loadRecogs();
  }, []);

  return (
    <Wrapper>
      <Row gutter={[24, 24]}>
        <Col xs={24} md={12} xl={8}>
          <div className="uploader-root white-rounded-bg">
            <h4>Upload Your Photo</h4>
            {maxRank.id >= 5 && (
              <p>
                Congratulations! Upload your photo and our system will
                auto-create your rank image.
              </p>
            )}
            <div className="alert-root">
              <Alert
                type="warning"
                showIcon
                style={{ alignItems: "baseline" }}
                message={
                  "Uploading rank photos is not possible currently through a mobile device. Please use a desktop or full screen your browser to upload your rank photo."
                }
              />
            </div>
            {isUploading ? (
              <div className="loadingRoot">
                <Spin spinning={true} />
                <p>Uploading now...</p>
              </div>
            ) : maxRank.id >= 5 ? (
              <div className="file-outroot">
                <Dropzone onDrop={handleOpenImage} accept="image/*">
                  {({ getRootProps, getInputProps }) => (
                    <div className="file-container" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <UploadImg />
                    </div>
                  )}
                </Dropzone>
                <ol>
                  <li>
                    Upload a professional image of yourself with the 
                    background removed as a transparent .PNG file. 
                    You can use free tools such as&nbsp;
                    <a href="https://www.canva.com" 
                      target="_blank"
                      rel="noreferrer"
                    >
                      www.canva.com
                    </a>&nbsp;or&nbsp;
                    <a href="https://www.remove.bg" 
                      target="_blank"
                      rel="noreferrer"
                    >
                      www.remove.bg
                    </a>&nbsp;to remove the background of your image 
                    in just 5 seconds.
                  </li>
                  <li>
                    Ensure no parts of the body are cropped out or 
                    cut off from the image.
                  </li>
                  <li>
                    For best results, use an image of just yourself and 
                    not multiple subjects, with no obstructions or blurry 
                    objects in front of you, from the waist up. 
                    Images where the subject is too far zoomed out may result 
                    in lower quality resolution of your image.
                  </li>
                  <li>
                    Recommended image dimensions: 800x800 pixels or higher, 
                    72ppi.
                  </li>
                </ol>
                {/* <p className="format">
                  Upload no-background image in PNG file format.
                </p>
                <p className="removeBg">
                  You can remove image backgrounds automatically in 5 seconds
                  with just one click here:{" "}
                  <a href="https://www.remove.bg/" target="_blank">
                    https://www.remove.bg/
                  </a>
                </p> */}
              </div>
            ) : (
              <Alert
                type="warning"
                showIcon
                style={{ alignItems: "baseline" }}
                message={
                  "You should have at least 1K Rank to edit recognition photo."
                }
              />
            )}
          </div>
        </Col>
        <Col xs={24} md={12} xl={16}>
          <div className="history-root white-rounded-bg">
            <h4>History({recogs.length})</h4>
            {isLoading ? (
              <div className="loadingRoot">
                <Spin spinning={true} />
              </div>
            ) : (
              <div className="recog-list">
                <Row gutter={[20, 20]}>
                  {recogs.map((el) => (
                    <Col xs={8} xl={4}>
                      <img
                        src={el.image}
                        onClick={() => setSelectedRecog(el)}
                      />
                    </Col>
                  ))}
                </Row>
              </div>
            )}
            {selectedRecog && (
              <Lightbox
                selectedRecog={selectedRecog}
                setSelectedRecog={setSelectedRecog}
                loadRecogs={loadRecogs}
              />
            )}
          </div>
        </Col>
      </Row>

      {maxRank.id >= 5 && (
        <div className="white-rounded-bg preview-outroot">
          <div className="preview-root">
            <Preview user={myUser} photo={photo} maxRank={maxRank} />
          </div>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .preview-root {
    margin: 0 auto;
    width: 1000px;
    height: 1000px;
  }
  .preview-outroot {
    margin-top: 24px;
    h4 {
      font-size: 18px;
      font-weight: 700;
    }
    @media (max-width: 1430px) {
      display: none !important;
    }
  }
  .alert-root {
    display: none;
    margin-top: 24px;
    @media (max-width: 1430px) {
      display: block;
    }
  }
  .uploader-root {
    height: 100%;
    h4 {
      font-size: 18px;
      font-weight: 700;
      color: #212b36;
    }
    p {
      font-size: 14px;
      color: #637381;
      margin: 0px;
    }
    ol {
      text-align: left;
    }
    .file-outroot {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #637381;
      text-align: center;
      .format {
        font-size: 12px;
        margin-bottom: 24px;
      }
      .removeBg {
        a {
          color: #1ea9ea;
        }
        max-width: 400px;
      }
      @media (max-width: 1430px) {
        display: none;
      }
    }
    .file-container {
      cursor: pointer;
      margin: 24px;
      .title {
        margin-top: 10px;
        font-size: 16px;
        color: ${(props) => props.theme.palette.secondaryText};
      }
    }
    .loadingRoot {
      width: 300px;
      height: 180px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  .history-root {
    height: 100%;
    h4 {
      font-size: 18px;
      font-weight: 700;
      color: #212b36;
      margin-bottom: 24px;
    }
    .loadingRoot {
      width: 100%;
      height: 250px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .recog-list {
      height: 350px;
      overflow-x: hidden;
      overflow-y: auto;
      img {
        width: 100%;
        cursor: pointer;
      }
      @media (max-width: 768px) {
        height: auto;
      }
    }
  }
  .sample-img {
    width: 100%;
    margin-top: 20px;
    img {
      width: 70%;
      border-radius: 4px;
    }
  }
`;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Tooltip, Progress, Alert } from "components/common";
import { callGetApiWithAuth } from "utils/api";
import { asUsdPrice, asVolume } from "utils/text";
import { ReactComponent as TickIcon } from "assets/icons/tick.svg";
import { ReactComponent as ThreeTickFrameIcon } from "assets/icons/three-circle-frame-3.svg";
import { ReactComponent as InfoIcon } from "assets/icons/dashboard/info.svg";

export default function BiStatCard(props) {
  const myUser = useSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const [isQualified, setIsQualified] = useState(false);

  const onGetStatData = (res) => {
    setAmount(res.data.amount);
    setIsQualified(res.data.is_qualified);
    setIsLoading(false);
    props.onSetTotalData("biAmount", res.data.amount);
  };
  const onFailStatData = () => {
    setIsLoading(false);
  };
  const loadStatData = () => {
    setIsLoading(true);
    callGetApiWithAuth(
      `report/bonus_tracker/bi_stat`,
      onGetStatData,
      onFailStatData
    );
  };

  useEffect(() => {
    loadStatData();
  }, []);

  return (
    <Wrapper className="white-rounded-bg">
      <div className="binary-container">
        <div className="binary-label">
          Binary Qualified
          <Tooltip title="To be Binary Qualified, you must have a minimum of 40 Personal Volume (PV), 90 Business Volume (BV) from personally enrolled Affiliates/Preferred Customers on each your left and right leg, and a minimum of 300 Business Volume (BV) on your lesser leg.">
            <InfoIcon />
          </Tooltip>
        </div>
      </div>
      <div className="outer-wrapper">
        <div className="diagram-root">
          <div className="unilevel-box">
            <div className="binary-frame-container">
              <div className="top-text">
                <Tooltip
                  title={() => (
                    <div>
                      Personal Volume: {asVolume(myUser.qualification.pv)}
                      <br />
                      Personal Customer Volume:{" "}
                      {asVolume(myUser.qualification.pecv)}
                    </div>
                  )}
                >
                  <div>
                    {myUser.qualification.pv >= myUser.qualification.pecv
                      ? `${asVolume(myUser.qualification.pv)}/40`
                      : `${asVolume(myUser.qualification.pecv)}/80`}
                  </div>
                </Tooltip>
              </div>
              <ThreeTickFrameIcon className="three-svg" />
              <div className="left-text">
                <Tooltip title={"Left Binary Personal Volume"}>
                  <div>{asVolume(myUser.qualification.l_bpev)}/90</div>
                </Tooltip>
                {/*
                  <Tooltip title={'Left Binary Business Volume'}>  
                    <div>
                      {asVolume(myUser.qualification.l_bgv)}/300
                    </div>
                  </Tooltip>
                  */}
              </div>
              <div className="right-text">
                <Tooltip title={"Right Binary Personal Volume"}>
                  <div>{asVolume(myUser.qualification.r_bpev)}/90</div>
                </Tooltip>
                {/*
                  <Tooltip title={'Right Binary Business Volume'}>  
                    <div>
                      {asVolume(myUser.qualification.r_bgv)}/300
                    </div>
                  </Tooltip>
                  */}
              </div>
            </div>
          </div>
        </div>
        <div className="alert-root">
          <Alert
            type="info"
            message={
              <>
                Your current rank is <strong>{myUser.rank.name}</strong> and
                your lesser team binary volume is &nbsp;
                <strong>
                  {asVolume(
                    myUser.qualification.l_bgv * 1 +
                      myUser.qualification.l_carry * 1 <
                      myUser.qualification.r_bgv * 1 +
                        myUser.qualification.r_carry * 1
                      ? myUser.qualification.l_bgv * 1 +
                          myUser.qualification.l_carry * 1
                      : myUser.qualification.r_bgv * 1 +
                          myUser.qualification.r_carry * 1
                  )}
                </strong>
                . Your current estimated binary commission is{" "}
                <strong className="ant-typography ant-typography-success">
                  {asUsdPrice(amount)}
                </strong>
                .
              </>
            }
            showIcon
            style={{ margin: "5px 0px 5px", alignItems: "baseline" }}
          />
          {!isQualified && (
            <Alert
              type="warning"
              message={
                <>
                  You are not binary qualified yet. If you are not binary
                  qualified by the end of this month, you can not earn binary
                  commissions.
                </>
              }
              showIcon
              style={{ margin: "5px 0px 5px", alignItems: "baseline" }}
            />
          )}
          <Alert
            type="info"
            message={
              <>
                Your lesser team is your&nbsp;
                <strong>
                  {myUser.qualification.l_bgv * 1 +
                    myUser.qualification.l_carry * 1 <
                  myUser.qualification.r_bgv * 1 +
                    myUser.qualification.r_carry * 1
                    ? "Left "
                    : "Right "}
                </strong>
                leg. We recommend you to set your Enrollment Settings to&nbsp;
                <strong>
                  {myUser.qualification.l_bgv * 1 +
                    myUser.qualification.l_carry * 1 <
                  myUser.qualification.r_bgv * 1 +
                    myUser.qualification.r_carry * 1
                    ? "Left "
                    : "Right "}
                </strong>
                in your Account Settings.
              </>
            }
            showIcon
            style={{ margin: "5px 0px 5px", alignItems: "baseline" }}
          />
        </div>
      </div>
      <div className="binary-progress-chart">
        <div className="line-progress-container">
          <div className="line-progress-box">
            <div className="line-progress-title">
              Lesser Leg:{" "}
              {myUser.qualification.l_bgv * 1 +
                myUser.qualification.l_carry * 1 <=
              myUser.qualification.r_bgv * 1 + myUser.qualification.r_carry * 1
                ? `Left`
                : `Right`}
            </div>
            <div className="line-progress-value">
              BV:
              {myUser.qualification.l_bgv * 1 +
                myUser.qualification.l_carry * 1 <=
              myUser.qualification.r_bgv * 1 + myUser.qualification.r_carry * 1
                ? asVolume(
                    myUser.qualification.l_bgv * 1 +
                      myUser.qualification.l_carry * 1
                  )
                : asVolume(
                    myUser.qualification.r_bgv * 1 +
                      myUser.qualification.r_carry * 1
                  )}
              /300
            </div>
          </div>
          <Progress
            percent={
              (Math.min(
                myUser.qualification.l_bgv * 1 +
                  myUser.qualification.l_carry * 1 <=
                  myUser.qualification.r_bgv * 1 +
                    myUser.qualification.r_carry * 1
                  ? myUser.qualification.l_bgv * 1 +
                      myUser.qualification.l_carry * 1
                  : myUser.qualification.r_bgv * 1 +
                      myUser.qualification.r_carry * 1,
                300
              ) /
                300) *
              100
            }
            size="small"
            strokeWidth={10}
            strokeColor="#00B8D9"
            showInfo={false}
          />
        </div>
      </div>
      {isQualified && (
        <div className="congratulation-text">
          <span>Congratulations!</span> You are now Binary Qualified!
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
  > .cardRoot {
    height: 100%;
  }
  h4 {
    font-size: 18px;
    color: ${(props) => props.theme.palette.text};
    margin-bottom: 0;
  }
  .card-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }
  @media (max-width: 1045px) {
    padding: 15px 0px 0px;
  }
  .outer-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
  }
  .diagram-root {
    width: 400px;
    @media (max-width: 1536px) {
      width: 100%;
      margin-bottom: 12px;
    }
  }
  .alert-root {
    width: calc(100% - 412px);
    @media (max-width: 1536px) {
      width: 100%;
    }
    @media (max-width: 600px) {
      margin-top: -60px;
      margin-bottom: 20px;
    }
  }
  .binary-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .binary-label {
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      color: #212b36;
      display: flex;
      align-items: center;
      svg {
        margin-left: 12px;
      }
    }
    .binary-tick {
    }
  }
  .binary-frame-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .top-text {
      color: #212b36;
      font-weight: normal;
      font-size: 14px;
      position: relative;
      top: 72px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      line-height: 1.2em;
    }
    .left-text {
      color: #212b36;
      font-weight: normal;
      font-size: 14px;
      position: relative;
      left: -128px;
      top: -50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .right-text {
      color: #212b36;
      font-weight: normal;
      font-size: 14px;
      position: relative;
      right: -131px;
      top: -68px;
      margin-bottom: -10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  .unilevel-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100% - 20px);
  }
  .congratulation-text {
    font-weight: 600;
    font-size: 14px;
    color: #637381;
    width: 100%;
    margin-top: 4px;
    span {
      font-weight: 600;
      color: #1ea9ea;
    }
  }
  .bottom-chart-root {
    width: 100%;
    .line-chart {
      background-color: #e4f1ff;
      height: 10px;
      border-radius: 10.5px;
      overflow: hidden;
      .chart-progressed {
        background-color: rgb(116, 68, 196);
        height: 100%;
      }
    }
  }
  .binary-progress-chart {
    width: 100%;
    .line-progress-container {
      .line-progress-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: -4px;
        .line-progress-title {
          font-weight: 600;
          font-size: 14px;
          line-height: 22px;
          color: #212b36;
        }
        .line-progress-value {
          font-weight: 600;
          font-size: 14px;
          line-height: 22px;
          text-align: right;
          color: #212b36;
        }
      }
    }
  }
  @media (max-width: 550px) {
    .three-svg {
      width: 281px;
    }
    .top-text {
      top: 99px !important;
    }
    .left-text {
      left: -98px !important;
      top: -80px !important;
    }
    .right-text {
      right: -100px !important;
      top: -102px !important;
    }
  }
`;
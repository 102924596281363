import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { 
  TablePanel, UserAvatar, Alert, 
} from 'components/common'
import { callGetApiWithAuth } from 'utils/api'
import { asDate } from 'utils/text'
import { varKey, varLabel } from 'common/var'

export default function PromoTicketReportPage() {
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [statData, setStatData] = useState({
    pea: 0, pec: 0
  })

  const onGetTableData = (res) => {
    setTableData(res.data.users)
    setStatData({
      pea: res.data.pea, 
      pec: res.data.pec
    })
    setIsLoading(false)
  }
  const onFailTableData = () => {
    setIsLoading(false)
  }
  const loadTableData = () => {
    setIsLoading(true)
    callGetApiWithAuth(`report/promo_ticket_report/list`, 
      onGetTableData, onFailTableData
    )
  }

  useEffect(() => {
    loadTableData()
  }, [])

  return (
    <Wrapper>
      <div className='banner'>
        <img src={'https://d2511r1bjh2ay3.cloudfront.net/tool/96576cff5a2dc80e5ed01d96a786b2e4.jpg'} />
      </div>
      <div className='mobile-banner'>
        <img src={'https://d2511r1bjh2ay3.cloudfront.net/tool/d48eba7a25390d8cd7e77e4a75cc13f2.jpg'} />
      </div>
      <Alert type='info'
        message={
          <span>
            You must complete 10&amp;2 within a single calendar month during the months of <strong>July, August, or September</strong>. You have 3 months to complete the promotion.
          </span>
        }
        showIcon
        style={{ margin: "5px 0px 5px", alignItems: 'baseline' }}
      />
      <div style={{height: 15}} />
      <TablePanel
        title={`Personally Enrolled (with more than 100BV order from September 1, 2022)`}
        data={tableData}
        loading={isLoading}
        paginationParam={false}
        toolbar={
          <div className='d-flex align-items-center'>
            <div>
              Affiliates: {statData.pea}
            </div>
            <span style={{width: 8}} />
            <div>
              Customers: {statData.pec}
            </div>
          </div>
        }
        columns={[
          {
            title: 'User ID',
            key: 'uuid',
            render: (_, record) => (
              <span>{record.uuid}</span>
            )
          },
          {
            title: 'User Type',
            key: 'type',
            render: (_, record) => (
              <span>{varLabel('user.type', record.type)}</span>
            )
          },
          {
            title: 'Username',
            key: 'username',
            render: (_, record) => (
              <span>{record.username}</span>
            )
          },
          {
            title: 'Name',
            key: 'name',
            render: (_, record) => (
              <UserAvatar 
                image={record.image}
                title={`${record.first_name} ${record.last_name}`}
                status={varKey('user.status', record.status)}
              />
            )
          },
          {
            title: 'Join Date',
            key: 'created_at',
            render: (_, record) => (
              <span>
                {asDate(record.created_at)}
              </span>
            )
          }
        ]}
      />  
    </Wrapper>
  )
}

const Wrapper = styled.div`
  @media (max-width: 768px) {
    padding-top: 60px;
  }
  .banner {
    width: 100%;
    margin-bottom: 15px;
    @media (max-width: 768px) {
      display: none;
    }
    img {
      width: 100%;
    }
  }
  .mobile-banner {
    display: none;
    margin-bottom: 15px;
    @media (max-width: 768px) {
      display: block;
    }
    img {
      width: 100%;
    }
  }
  .ant-table-content {
    min-width: 800px;
  }
  @media (max-width: 768px) {
    .panelHead {
      flex-wrap: wrap;
    }
  }
`

import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Empty, Select } from "components/common";
import { callGetApiWithAuth } from "utils/api";
import { asPrice, asVolume } from "utils/text";
import { Wrapper } from "./ProductSalesSection.styled";
import NoImage from "assets/images/no_image.png";

export default function IndexPage() {
  const [topFiveProducts, setTopFiveProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParam, setSearchParam] = useState({
    type: 1, // yearly
  });

  const onGetSuccessTopFiveProducts = ({ data }) => {
    setIsLoading(false);
    setTopFiveProducts(data);
  };

  const onGetFailedTopFiveProducts = (err) => {
    setIsLoading(false);
  };

  const loadTopFiveProducts = () => {
    setIsLoading(true);
    const params = {
      ...searchParam,
    };
    const q = Object.keys(params)
      .map((k) => k + "=" + params[k])
      .join("&");
    callGetApiWithAuth(
      `dashboard/top5_product_sales?${q}`,
      onGetSuccessTopFiveProducts,
      onGetFailedTopFiveProducts
    );
  };

  const onChangeType = (v) => {
    setSearchParam({
      ...searchParam,
      type: v,
    });
  };

  useEffect(() => {
    loadTopFiveProducts();
  }, [searchParam]);

  return (
    <Wrapper>
      <div className="sales-title-container">
        <div className="sales-title">Top 5 Selling Products</div>
        <div className="sales-date">
          <Select value={searchParam.type}
            onChange={v=>onChangeType(v)}
            options={[
              { value: 1, label: 'Last 12 Months' },
              { value: 4, label: 'Last 6 Months' },
              { value: 2, label: 'Last Month' },
              { value: 3, label: 'This Month' },
            ]}
          />
        </div>
      </div>
      <div className="mbl-responsive-products">
        <div className="mbl-responsive-list">
          <Row gutter={[24, 24]}>
            {isLoading ? (
              <Spin spinning={true} />
            ) : (
              <>
                {topFiveProducts.length > 0 ? (
                  topFiveProducts.map((el, index) => (
                    <Col span={24} key={index}>
                      <div className="sales-product-container">
                        <div className="p-image-container">
                          <div className="product-image">
                            <img
                              src={
                                el.product.image_path
                                  ? el.product.image_path
                                  : NoImage
                              }
                            />
                          </div>
                          <Row gutter={[10, 10]} style={{ width: "100%" }}>
                            <Col xs={24}>
                              <Row gutter={[20, 5]}>
                                <Col xs={24} sm={17}>
                                  <div className="label-container">
                                    <div className="label">
                                      {el.product.title}
                                    </div>
                                    <div className="brand-container">
                                      Brand:{" "}
                                      <span>
                                        {el.product.product_brand.title}
                                      </span>
                                    </div>
                                    <div className="price-container">
                                      {asPrice(el.product.member_price)}{" "}
                                      <span className="price">
                                        {asPrice(el.product.recurring_price)}
                                      </span>{" "}
                                      <span className="subscription">
                                        (Subscription Price)
                                      </span>
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={24} sm={7}>
                                  <div className="product-price-container">
                                    {asVolume(el.sales_amount * 1)} BV
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  ))
                ) : (
                  <Col span={24}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </Col>
                )}
              </>
            )}
          </Row>
        </div>
      </div>
    </Wrapper>
  );
}

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from 'styled-components';
import { asUsdPrice, asNumber } from "utils/text";
import { callGetApiWithAuth } from "utils/api";
import { ReactComponent as LineChartIcon } from "assets/icons/home/line-chart.svg";

export default function EarningSection() {
  const [statData, setStatData] = useState({
    last_7: 0,
    last_30: 0,
    this_year: 0,
    total: 0,
  });

  const onGetStatData = (res) => {
    setStatData(res.data);
  };
  const onFailStatData = () => {};
  const loadStatData = () => {
    callGetApiWithAuth(`earning/stats`, onGetStatData, onFailStatData);
  };

  useEffect(() => {
    loadStatData();
  }, []);

  return (
    <Wrapper>
      <h2>Earnings</h2>
      <div className='w-card'>
        <div>
          <h4>Last 7 Days</h4>
          <p>{asUsdPrice(statData.last_7)}</p>
        </div>
        <LineChartIcon />
      </div>
      <div className='w-card'>
        <div>
          <h4>Last 30 Days</h4>
          <p>{asUsdPrice(statData.last_30)}</p>
        </div>
        <LineChartIcon className='red' />
      </div>
      <div className='w-card'>
        <div>
          <h4>This Year</h4>
          <p>{asUsdPrice(statData.this_year)}</p>
        </div>
        <LineChartIcon className='yellow' />
      </div>
      <div className='w-card'>
        <div>
          <h4>Lifetime</h4>
          <p>{asUsdPrice(statData.total)}</p>
        </div>
        <LineChartIcon className='green' />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  box-shadow: 0px 0px 2px rgb(145 158 171 / 20%), 0px 12px 24px -4px rgb(145 158 171 / 12%);
  border-radius: 16px;
  height: 100%;
  padding: 24px;
  h2 {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #212b36;
    margin-bottom: 24px;
  }
  .w-card {
    border: 0.62642px solid rgba(145,158,171,0.24);
    border-radius: 10.0227px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0;
    h4 {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #919eab;
    }
    p {
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      margin: 0;
    }
    svg {
      color: #1EA9EA;
      fill: #1EA9EA;
      &.red {
        color: #EA1ED6;
        fill: #EA1ED6;
      }
      &.yellow {
        color: #EAA51E;
        fill: #EAA51E;
      }
      &.green {
        color: #27B204;
        fill: #27B204;
      }
    }
  }
`

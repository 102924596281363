import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Row,
  Col,
  TextArea,
  Button,
  Input,
  Dropzone,
  Switch,
  Popconfirm,
  SuccessNotification,
  Select,
  WarningNotification,
  Spin,
} from "components/common";
import NoPhotoIcon from "assets/images/user.svg";
import BannerImg from "assets/images/profile_card_banner.png";
import {
  UploadOutlined,
  SmileOutlined,
  MinusCircleFilled,
  PlusCircleFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaYoutube,
  FaLinkedinIn,
} from "react-icons/fa";
import { callGetApiWithAuth, callPostApiWithAuth } from "utils/api";
import PreviewModal from "./PreviewModal";
import { Wrapper } from "./EnrollerCardSubPage.styled";
import EmojiPicker from "emoji-picker-react";
import { uiVarOptions } from "common/uiVar";
import SelectBannersSection from "./SelectBannersSection";
import { ReactComponent as FilledIcon } from "assets/icons/filled-info.svg";
import { ReactComponent as UploadIcon } from "assets/icons/account/upload.svg";
import { ReactComponent as FacebookIcon } from "assets/icons/account/f.svg";
import { ReactComponent as InstaIcon } from "assets/icons/account/inst.svg";
import { ReactComponent as TwitterIcon } from "assets/icons/account/twitt.svg";
import { ReactComponent as YoutubeIcon } from "assets/icons/account/you.svg";
import { ReactComponent as LinkedlnIcon } from "assets/icons/account/ln.svg";

export default function EnrollerCardSubPage() {
  const myUser = useSelector((state) => state.auth.user);
  const [banners, setBanners] = useState([]);
  const [selectedEmojiIdx, setSelectedEmojiIdx] = useState({
    idx: -1,
    opened: false,
  });
  const [productOptions, setProductOptions] = useState([]);
  const [formData, setFormData] = useState({
    facebook: "",
    instagram: "",
    twitter: "",
    youtube: "",
    banner: "",
    linkedin: "",
    my_story: "",
    p_youtube: "",
    interests: [],
    show_rank_info: 2,
    show_profile_info: 1,
  });
  const [userInfo, setUserInfo] = useState({
    first_name: "",
    last_name: "",
    country: "",
    email: "",
    phone: "",
    image: undefined,
  });
  const [showPreview, setShowPreview] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    my_story: "",
    interests: "",
    product1_id: "",
    product2_id: "",
    product3_id: "",
    p_youtube: "",
  });

  const onGetProductOptions = (res) => {
    setProductOptions(res.data);
  };

  const loadProductOptions = () => {
    callGetApiWithAuth(`common/my_products`, onGetProductOptions);
  };

  const onSuccessGetEnrollerCardData = ({ data }) => {
    setIsLoading(false);
    if (data) {
      setFormData(data);
    }
  };

  const loadEnrollerCardData = () => {
    setIsLoading(true);
    callGetApiWithAuth("my_account/profile_card", onSuccessGetEnrollerCardData);
  };

  const onGetBanners = (res) => {
    setBanners(res.data);
  };
  const loadBanners = () => {
    callGetApiWithAuth(`my_account/profile_card/banners`, onGetBanners);
  };

  const onAddInterestRow = () => {
    const arrayData = [
      ...formData.interests,
      {
        icon: "",
        title: "",
      },
    ];
    setFormData({
      ...formData,
      interests: arrayData,
    });
  };

  const onRemoveInterestRow = (index) => {
    const arrayData = [...formData.interests];
    arrayData.splice(index, 1);
    setFormData({
      ...formData,
      interests: arrayData,
    });
  };

  const onChangeInterests = (index, field, value) => {
    if (field === "title" && value.length > 20) {
      WarningNotification("Please input a string of less than 20 characters.");
      return;
    }
    let interests = formData.interests;
    interests[index] = {
      ...interests[index],
      [field]: value,
    };
    setFormData({
      ...formData,
      interests: interests,
    });
  };

  const handleClickEmojiIcon = (index) => {
    if (selectedEmojiIdx.idx * 1 === index * 1) {
      setSelectedEmojiIdx({
        idx: -1,
        opened: !selectedEmojiIdx.opened,
      });
    } else {
      setSelectedEmojiIdx({
        idx: index,
        opened: !selectedEmojiIdx.opened,
      });
    }
  };

  const onChangeEmoji = (event, emojiObject, index) => {
    let interests = formData.interests;
    interests[index] = {
      ...interests[index],
      icon: emojiObject.emoji,
    };
    setFormData({
      ...formData,
      interests: interests,
    });
    setSelectedEmojiIdx({
      idx: -1,
      opened: !selectedEmojiIdx.opened,
    });
  };

  const onChangeFile = (files) => {
    if (files.length === 0) return;

    const fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);
    fileReader.onload = () => {
      setFormData({
        ...formData,
        banner: fileReader.result,
      });
    };
  };

  const validate = () => {
    let errors0 = {
      my_story: "",
      interests: "",
      product1_id: "",
      product2_id: "",
      product3_id: "",
    };
    if (!formData.my_story) {
      errors0.my_story = "Please input my story";
    }
    if (!formData.interests || formData.interests.length === 0) {
      errors0.interests = "Please input top interests";
    }
    if (!formData.product1_id) {
      errors0.product1_id = "Please select your first top product";
    }
    if (!formData.product2_id) {
      errors0.product2_id = "Please input second top product";
    }
    if (!formData.product3_id) {
      errors0.product3_id = "Please input third top product";
    }

    let emptyInterestCnt = 0;

    for (let interest of formData.interests) {
      if (!interest.title) {
        emptyInterestCnt++;
      }
    }

    if (emptyInterestCnt !== 0) {
      errors0.interests = "Please input all interests";
      WarningNotification("Please input all interests.");
    }

    setErrors(errors0);

    if (
      errors0.my_story ||
      errors0.interests ||
      errors0.product1_id ||
      errors0.product2_id ||
      errors0.product3_id
    ) {
      return false;
    }

    return true;
  };

  const onUpdateDone = () => {
    setIsUpdating(false);
    SuccessNotification("Your profile card has been updated successfully.");
  };

  const onUpdateFail = (res) => {
    setIsUpdating(false);
  };

  const handleUpdate = () => {
    // Validation
    if (!validate()) return;

    const body = {};
    Object.keys(formData).map((k) => {
      if (k==='image' || k==='phone') {
        return;
      }
      if (formData[k]) {
        body[k] = formData[k];
      } else {
        body[k] = '';
      }
    });
    setIsUpdating(true);
    callPostApiWithAuth(
      `my_account/profile_card`,
      body,
      onUpdateDone,
      onUpdateFail
    );
  };

  const handleClickPreview = () => {
    //Validate
    if (!validate()) return;
    setShowPreview(true);
  };

  useEffect(() => {
    if (!myUser) return;
    setUserInfo({
      first_name: myUser.first_name,
      last_name: myUser.last_name,
      country: myUser.country,
      image: myUser.image,
      email: myUser.email,
      phone: myUser.phone,
    });
  }, [myUser]);

  useEffect(() => {
    loadProductOptions();
    loadEnrollerCardData();
    loadBanners();
  }, []);

  useEffect(() => {
    if (banners.length > 0 && !formData.banner) {
      setFormData({
        ...formData,
        banner: banners[0].image,
      });
    }
  }, [banners]);

  return (
    <Wrapper>
      {isLoading && (
        <div className="loading-spin-section">
          <Spin spinning={true} className="loading-spin" />
        </div>
      )}
      <div className="banner-img-section">
        <img
          src={formData.banner ? formData.banner : BannerImg}
          className="banner-img"
        />
        <img className="photo-img" src={userInfo.image || NoPhotoIcon} />
      </div>
      <div className="enroller-card-main-container">
        <div className="select-banner-section settings-row">
          <div className="ban-container">
            <h5>
              Banner Images
              <FilledIcon />
            </h5>
            <Dropzone onDrop={onChangeFile} accept="image/*">
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Button icon={<UploadIcon />} className="upload-banner">
                    Banner Image
                  </Button>
                </div>
              )}
            </Dropzone>
          </div>
          {banners.length > 0 ? (
            <SelectBannersSection
              banners={banners}
              formData={formData}
              setFormData={setFormData}
            />
          ) : (
            ""
          )}
        </div>
        <Row gutter={[30, 20]} className="my-story">
          <Col xs={24}>
            <h5>
              My Story
              <FilledIcon />
            </h5>
            <TextArea
              label="Type your bio here"
              rows={2}
              maxLength={500}
              value={formData.my_story}
              onChange={(e) =>
                setFormData({ ...formData, my_story: e.target.value })
              }
            />
            {(errors && errors.my_story)?
              <p className='error-message'>
                Required
              </p>
            : ''}
          </Col>
        </Row>
        <Row gutter={[30, 20]} className="my-story">
          <Col xs={24} xl={13}>
            <h5 className="custom-h5">Youtube Video</h5>
            <Input
              placeholder="https://www.youtube.com/embed/HWrmY6XumCA"
              value={formData.p_youtube}
              onChange={(e) =>
                setFormData({ ...formData, p_youtube: e.target.value })
              }
            />
          </Col>
        </Row>
        <Row gutter={[15, 15]} className="my-story interests-section">
          <Col xs={24}>
            <h5 className="custom-h5" style={{ marginBottom: "-6px" }}>
              Top Interests
              <PlusCircleFilled
                className="add-interest-btn"
                onClick={onAddInterestRow}
              />
            </h5>
            {(errors && errors.interests)?
              <p className='error-message'>
                Required
              </p>
            : ''}
          </Col>
          {formData.interests.map((el, index) => (
            <Col xs={24} sm={12} md={6} key={index}>
              <div className="select-container">
                <div className="select-box">
                  <Input
                    prefix={
                      el.icon ? (
                        <span
                          className="emoji-icon"
                          onClick={() => handleClickEmojiIcon(index)}
                        >
                          {el.icon}
                        </span>
                      ) : (
                        <SmileOutlined
                          className="emoji-icon"
                          onClick={() => handleClickEmojiIcon(index)}
                        />
                      )
                    }
                    suffix={
                      <MinusCircleFilled
                        className="remove-interest"
                        onClick={() => onRemoveInterestRow(index)}
                      />
                    }
                    value={el.title}
                    onChange={(e) =>
                      onChangeInterests(index, "title", e.target.value)
                    }
                  />
                  {selectedEmojiIdx.idx * 1 === index * 1 &&
                    selectedEmojiIdx.opened && (
                      <EmojiPicker
                        onEmojiClick={(event, emojiObject) =>
                          onChangeEmoji(event, emojiObject, index)
                        }
                      />
                    )}
                </div>
              </div>
            </Col>
          ))}
        </Row>

        <div className="my-story connect-section">
          <h5 style={{ marginBottom: "5px" }}>Connect</h5>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={24} lg={24} xl={12} xxl={12}>
              <div className="connect-container">
                <div className="connect-input new-connect">
                  <Input
                    inputPrefix={
                      <FacebookIcon className="connect-social-icon" />
                    }
                    value={formData.facebook}
                    onChange={(e) =>
                      setFormData({ ...formData, facebook: e.target.value })
                    }
                    placeholder="https://www.facebook.com/officialmydailychoice/"
                  />
                </div>
              </div>
            </Col>
            <Col xs={24} md={24} lg={24} xl={12} xxl={12}>
              <div className="connect-container">
                <div className="connect-input new-connect">
                  <Input
                    inputPrefix={<InstaIcon className="connect-social-icon" />}
                    value={formData.instagram}
                    onChange={(e) =>
                      setFormData({ ...formData, instagram: e.target.value })
                    }
                    placeholder="https://instagram.com/officialmydailychoice?utm_medium=copy_link"
                  />
                </div>
              </div>
            </Col>
            <Col xs={24} md={24} lg={24} xl={12} xxl={12}>
              <div className="connect-container">
                <div className="connect-input new-connect">
                  <Input
                    inputPrefix={
                      <TwitterIcon className="connect-social-icon" />
                    }
                    value={formData.twitter}
                    onChange={(e) =>
                      setFormData({ ...formData, twitter: e.target.value })
                    }
                    placeholder="https://mobile.twitter.com/mydailychoice"
                  />
                </div>
              </div>
            </Col>
            <Col xs={24} md={24} lg={24} xl={12} xxl={12}>
              <div className="connect-container">
                <div className="connect-input new-connect">
                  <Input
                    inputPrefix={
                      <YoutubeIcon className="connect-social-icon" />
                    }
                    value={formData.youtube}
                    onChange={(e) =>
                      setFormData({ ...formData, youtube: e.target.value })
                    }
                    placeholder="https://www.youtube.com/c/MyDailyChoiceOfficial"
                  />
                </div>
              </div>
            </Col>
            <Col xs={24} md={24} lg={24} xl={12} xxl={12}>
              <div className="connect-container">
                <div className="connect-input new-connect">
                  <Input
                    inputPrefix={
                      <LinkedlnIcon className="connect-social-icon" />
                    }
                    value={formData.linkedin}
                    onChange={(e) =>
                      setFormData({ ...formData, linkedin: e.target.value })
                    }
                    placeholder="https://www.linkedin.com/company/mydailychoice-hempworx"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <Row gutter={[16, 16]} className="my-story">
          <Col xs={24}>
            <div className="story-div">
              <h5 style={{ marginBottom: "0px" }}>My Top Products</h5>
              <span className={"product-detail"}>
                What are your top 3 favorite MDC products?
              </span>
            </div>
          </Col>
          <Col xs={24} lg={8}>
            <Select
              value={formData.product1_id}
              onChange={(v) => setFormData({ ...formData, product1_id: v })}
              options={productOptions.map((el2) => ({
                label: el2.title,
                value: el2.id,
                key: el2.id,
              }))}
              style={{ width: "100%" }}
              showSearch
              optionFilterProp="label"
              placeholder="Product 1"
            />
            {(errors && errors.product1_id)?
              <p className='error-message'>
                Required
              </p>
            : ''}
          </Col>
          <Col xs={24} lg={8}>
            <Select
              value={formData.product2_id}
              onChange={(v) => setFormData({ ...formData, product2_id: v })}
              options={productOptions.map((el2) => ({
                label: el2.title,
                value: el2.id,
                key: el2.id,
              }))}
              style={{ width: "100%" }}
              showSearch
              optionFilterProp="label"
              placeholder="Product 2"
            />
            {(errors && errors.product2_id)?
              <p className='error-message'>
                Required
              </p>
            : ''}
          </Col>
          <Col xs={24} lg={8}>
            <Select
              value={formData.product3_id}
              onChange={(v) => setFormData({ ...formData, product3_id: v })}
              options={productOptions.map((el2) => ({
                label: el2.title,
                value: el2.id,
                key: el2.id,
              }))}
              style={{ width: "100%" }}
              showSearch
              optionFilterProp="label"
              placeholder="Product 3"
            />
            {(errors && errors.product3_id)?
              <p className='error-message'>
                Required
              </p>
            : ''}
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="my-story connect-section">
          <Col xs={24}>
            <div className="story-div">
              <h5 style={{ marginBottom: 5 }}>Contact</h5>
            </div>
          </Col>
          <Col xs={24} md={24} xl={16} xxl={12}>
            <div className="connect-container">
              <div className="connect-input">
                <Input label="Email" value={userInfo.email} disabled />
              </div>
            </div>
          </Col>
          <Col xs={24} md={24} xl={16} xxl={12}>
            <div className="connect-container">
              <div className="connect-input">
                <Input label="Phone Number" value={userInfo.phone} disabled />
              </div>
            </div>
          </Col>
        </Row>
        <Row
          gutter={[20, 20]}
          className="settings-row"
          style={{ marginTop: "24px" }}
        >
          <Col xs={24}>
            <div className="new-connect-container">
              <Switch
                checked={formData.show_rank_info === 1}
                onChange={(checked) =>
                  setFormData({ ...formData, show_rank_info: checked ? 1 : 2 })
                }
                className="contact-info-visibility-switch"
              />
              <div className="new-connect-title">Show My Rank Badges</div>
            </div>
          </Col>
        </Row>
        <Row gutter={[20, 20]} className="settings-row">
          <Col xs={24}>
            <div className="new-connect-container">
              <Switch
                checked={formData.show_profile_info === 1}
                onChange={(checked) =>
                  setFormData({
                    ...formData,
                    show_profile_info: checked ? 1 : 2,
                  })
                }
                className="contact-info-visibility-switch"
              />
              <div className="new-connect-title">
                Show Profile Card in Contact Enroller
              </div>
            </div>
          </Col>
        </Row>
        <div className="s-container0">
          <div className="change-button">
            <Button onClick={handleClickPreview}>Preview</Button>
          </div>
          <div className="save-button">
            <Popconfirm onConfirm={handleUpdate}>
              <Button>
                {isUpdating ? <LoadingOutlined /> : "Save Changes"}
              </Button>
            </Popconfirm>
          </div>
        </div>
      </div>
      {showPreview && (
        <PreviewModal
          setShowPreview={setShowPreview}
          data={formData}
          userInfo={userInfo}
          productOptions={productOptions}
        />
      )}
    </Wrapper>
  );
}

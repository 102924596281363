import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { callGetApiWithAuth } from 'utils/api'
import TreeView from 'components/treeView/TreeView'
import styled from 'styled-components'

export default function BinaryTreePage() {
  const dispatch = useDispatch()
  const nodeCount = 5
  const [isLoading, setIsLoading] = useState(false)
  const [treeData, setTreeData] = useState(undefined)  
  const shouldSearchAgain = useSelector(state=>state.binaryTree.shouldSearchAgain)
  const searchingUserId = useSelector(state=>state.binaryTree.searchingUserId)  
  const bottomLeg = useSelector(state=>state.binaryTree.bottomLeg)  

  const onGetTreeData = (res) => {
    setIsLoading(false)
    
    setTreeData(res.data)
    dispatch({ type: 'binaryTree.SEARCH_DONE' })
  }
  const onFailTreeData = () => {
    setIsLoading(false)
    dispatch({ type: 'binaryTree.SEARCH_DONE' })
  }
  const loadTreeData = (userId) => {
    setIsLoading(true)
    setTreeData(undefined)
    const params = {
      user_id: userId,
      nodes: nodeCount,       
    }
    const q = Object.keys(params).map(k=>k+'='+params[k]).join('&')
    callGetApiWithAuth(`my_team/binary_tree/tree?${q}`, onGetTreeData, onFailTreeData)
  }
  const loadRoot = () => {
    loadTreeData('')
    dispatch({ type: 'binaryTree.INIT', payload: { tree_type: 'binary' } })
  }
  const loadParent = () => {
    if (treeData.parent_id) {
      loadTreeData(treeData.parent_id)
    }
  }
  const search = (userId) => {
    loadTreeData(userId)
  }
  
  const loadTreeBottomData = (user_id, leg_position) => {
    setIsLoading(true)
    setTreeData(undefined)
    const params = {
      user_id,
      leg_position,
      nodes: nodeCount,
    }
    const q = Object.keys(params).map(k=>k+'='+params[k]).join('&')
    callGetApiWithAuth(`my_team/binary_tree/tree_bottom?${q}`, onGetTreeData, onFailTreeData)
  }

  useEffect(() => {
    if (shouldSearchAgain) {
      if (bottomLeg) {
        loadTreeBottomData(searchingUserId, bottomLeg)
      } else {
        loadTreeData(searchingUserId)
      }
    }
  }, [shouldSearchAgain])  

  useEffect(() => {
    loadTreeData('')
    dispatch({ type: 'binaryTree.INIT', payload: { tree_type: 'binary' } })
  }, [])

  return (
    <Wrapper className='binary_tree_card'>
      <TreeView 
        treeData={treeData} 
        isLoading={isLoading} 
        loadRoot={loadRoot}
        loadParent={loadParent}
        search={search}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .container {
    height: 200px !important;
    width: 165px !important;
    .userRow {
      font-size: 14px !important;
    }
    .userid {
      font-size: 12px;
      margin-bottom: 10px;
    }
    .aboutInfo {
      margin-top: 0px;
      padding-bottom: 5px;
    }
    .no-rank-qualification {
      display: none;
    }
    .no-show-rank {
      display: none;
    }
  }
`

import React, { useState } from "react";
import { Tabs } from "components/common";
import { getUser } from "utils/auth";
import AffiliateStreamSubPage from "./affiliate/AffiliateStreamSubPage";
import CompanyStreamSubPage from "./company/CompanyStreamSubPage";
import MyStreamSubPage from "./mine/MyStreamSubPage";
import GuideSubPage from "./guide/GuideSubPage";
import { Wrapper } from "./LiveStreamPage.styled";

const { TabPane } = Tabs;

export default function LiveStreamPage() {
  const [selectedTab, setSelecetedTab] = useState("Affiliate Live Streams");
  const myUser = getUser();

  return (
    <Wrapper>
      <div className="main-container">
        <div className="subscriptions-tabs">
          <Tabs activeKey={selectedTab} onChange={(e) => setSelecetedTab(e)}>
            <TabPane
              tab={
                <span
                  className={
                    selectedTab == "Affiliate Live Streams"
                      ? "active-tab"
                      : "inactive-tab"
                  }
                >
                  Affiliate Live Streams
                </span>
              }
              key="Affiliate Live Streams"
            />
            <TabPane
              tab={
                <span
                  className={
                    selectedTab == "Company Live Streams"
                      ? "active-tab"
                      : "inactive-tab"
                  }
                >
                  Company Live Streams
                </span>
              }
              key="Company Live Streams"
            />
            <TabPane
              tab={
                <span
                  className={
                    selectedTab == "My Live Streams"
                      ? "active-tab"
                      : "inactive-tab"
                  }
                >
                  My Live Streams
                </span>
              }
              key="My Live Streams"
            />
            <TabPane
              tab={
                <span
                  className={
                    selectedTab == "Guidelines"
                      ? "active-tab"
                      : "inactive-tab"
                  }
                >
                  Guidelines
                </span>
              }
              key="Guidelines"
            />
          </Tabs>
        </div>
        {selectedTab === "Affiliate Live Streams" &&
          <AffiliateStreamSubPage />
        }
        {selectedTab === "Company Live Streams" &&
          <CompanyStreamSubPage />
        }
        {selectedTab === "My Live Streams" &&
          <MyStreamSubPage />
        }        
        {selectedTab === "Guidelines" &&
          <GuideSubPage />
        }
      </div>
    </Wrapper>
  );
}

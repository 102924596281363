import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import styled, { createGlobalStyle } from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment-timezone";
import { Layout, Drawer, ErrorNotification } from "components/common";
import { isAuthedUser, getSudo, saveStorage, getToken } from "utils/auth";
import { callGetApiWithAuth } from "utils/api";
import { hasPageAccess } from "common/menu";
import BaseLayout from "../base/BaseLayout";
import TopHeader from "./topHeader/TopHeader";
import Sidebar from "./sidebar/Sidebar";
import PopUpModal from "./popup/PopUpModal";
import { varIs } from "common/var";
import { CometChat } from "@cometchat-pro/chat";

const { Header, Content } = Layout;

export default function AuthedLayout({ children, fullLayout, pathname }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const myUser = useSelector((state) => state.auth.user);
  const sudoName = useSelector((state) => state.auth.sudo_name);
  const isCollapse = useSelector((state) => state.ui.isCollapse);
  const errorMessage = useSelector((state) => state.api.errorMessage);
  const needReload = useSelector((state) => state.auth.needReload);
  const orderError = useSelector((state) => state.checkout.orderError);
  const [visible, setVisible] = useState(false);
  const [isOpenedPopUp, setIsOpenedPopUp] = useState(false);
  const cookies = new Cookies();
  const popUp = cookies.get("popUp");
  const [promoCountTitle, setPromoCountTitle] = useState('')
  let promoCountInterval = undefined;

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const onGetProfile = (res) => {
    dispatch({ type: "auth.RELOAD_DONE" });
    saveStorage(res.data, getToken(), getSudo());
    dispatch({
      type: "auth.SET_USER",
      payload: {
        user: res.data,
        sudoName: getSudo(),
      },
    });
  };
  const onFailProfile = () => {
    dispatch({ type: "auth.RELOAD_DONE" });
  };

  const onGetCurrency = (res) => {
    if (res.data) {
      dispatch({
        type: "common.SET_CURRENCY",
        payload: {
          currency: res.data,
        },
      });
    }
  };

  const calcPromoCount = () => {
    const endTime0 = moment
      .tz(
        `2024-04-30 23:55:00`,
        "YYYY-MM-DD HH:mm:ss",
        "America/Los_Angeles"
      )
      .local();
    const diffDa = endTime0.diff(moment().clone(), "days");
    const diffHr = endTime0.diff(moment().clone(), "hours") % 24;
    const diffMi = endTime0.diff(moment().clone(), "minutes") % 60;
    const diffSe = endTime0.diff(moment().clone(), "seconds") % 60;
    setPromoCountTitle(
      diffDa + ' Days ' 
      + diffHr + ' Hours ' 
      + diffMi + ' Minutes Left!' 
      // + diffSe + ' Seconds Left'
    );
  };

  useEffect(() => {
    document.getElementById("myapp").scroll(0, 0);
    if (!isAuthedUser()) {
      history.push("/logout");
      return;
    }

    if (
      history?.location?.pathname === "/publisher" ||
      history?.location?.pathname === "/viewer"
    ) {
      dispatch({
        type: "ui.SET_COLLAPSE",
        payload: {
          isCollapse: true,
        },
      });
    } else {
      dispatch({
        type: "ui.SET_COLLAPSE",
        payload: {
          isCollapse: false,
        },
      });
    }
  }, [history?.location?.pathname]);

  useEffect(() => {
    if (errorMessage) {
      ErrorNotification(errorMessage);
      dispatch({
        type: "api.CLEAR_ERROR_MESSAGE",
      });
    }
  }, [errorMessage]);

  useEffect(() => {
    setTimeout(() => {
      callGetApiWithAuth(`common/currency`, onGetCurrency);
      callGetApiWithAuth(`common/profile`, onGetProfile, onFailProfile);
    }, 1500);
  }, []);

  /*
  useEffect(() => {
    if (
      myUser &&
      varIs("user.isSchatEnabled", myUser.is_schat_enabled, "yes")
    ) {
      var appSetting = new CometChat.AppSettingsBuilder()
        .subscribePresenceForAllUsers()
        .setRegion(process.env.REACT_APP_COMETCHAT_REGION)
        .build();
      CometChat.init(process.env.REACT_APP_COMETCHAT_APP_ID, appSetting).then(
        () => {
          if (CometChat.setSource) {
            CometChat.setSource("ui-kit", "web", "reactjs");
          }

          CometChat.login(
            `mdc_user_${myUser?.uuid}`,
            process.env.REACT_APP_COMETCHAT_AUTH_KEY
          )
            .then((user) => {})
            .catch((error) => {
              if (error.code === "ERR_UID_NOT_FOUND") {
                let uid = `mdc_user_${myUser?.uuid}`;
                let name = `${myUser?.first_name} ${myUser?.last_name}`;
                let image = myUser?.image;
                var user = new CometChat.User(uid);
                user.setName(name);
                if (image) {
                  user.setAvatar(image);
                }
                CometChat.createUser(
                  user,
                  process.env.REACT_APP_COMETCHAT_AUTH_KEY
                ).then(
                  (user) => {
                    CometChat.login(
                      `mdc_user_${myUser?.uuid}`,
                      process.env.REACT_APP_COMETCHAT_AUTH_KEY
                    ).then((user_) => {});
                  },
                  (error) => {}
                );
              }
            });

          dispatch({
            type: "ui.SET_COMET_CHAT_INITIALIZED",
            payload: {
              isCometChatInitiliazed: true,
            },
          });
        },
        (error) => {}
      );
    }
  }, [myUser]);
  */

  useEffect(() => {
    if (needReload) {
      callGetApiWithAuth(`common/profile`, onGetProfile, onFailProfile);
    }
  }, [needReload]);

  useEffect(() => {
    // Check Page Access
    if (myUser && history.location.pathname) {
      if (!hasPageAccess(history.location.pathname, myUser, sudoName)) {
        if (varIs("user.taxStatus", myUser.tax_status, "requested")) {
          history.push("/tax_form");
          return;
        }
        if (
          varIs(
            "user.verificationStatus",
            myUser.verification_status,
            "requested"
          )
        ) {
          history.push("/verification");
          return;
        }

        history.push("/");
      }
    }
  }, [myUser, history.location.pathname]);

  useEffect(() => {
    if (orderError) {
      ErrorNotification(orderError);
      dispatch({
        type: "checkout.CLEAR_ORDER_ERROR",
      });
    }
  }, [orderError]);

  const closePopUp = () => {
    setIsOpenedPopUp(false);
    cookies.set(
      "popUp",
      { ...popUp, isNew: 2 },
      { path: "/", maxAge: 2592000 }
    );
  };

  useEffect(() => {
    if (popUp?.isNew === 1) {
      setTimeout(() => {
        setIsOpenedPopUp(true);
      }, 3000);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (document.getElementsByTagName("body").length > 0) {
        let bodySelector = document.getElementsByTagName("body")[0];
        bodySelector.classList.remove("login-page");
      }
    }
  }, []);

  useEffect(() => {
    calcPromoCount();
    promoCountInterval = setInterval(() => {
      calcPromoCount();
    }, [1000]);
    return () => {
      if (promoCountInterval) {
        clearInterval(promoCountInterval);
      }
    };
  }, [])

  return myUser ? (
    <BaseLayout>
      <GlobalStyle />
      <LayoutWrapper>
        {/**
         * IncentiveTrip Bar
         **/}
        {/* <div className='top-head-bar'>
          <p onClick={() => {
            history.push('/event/incentive_trip')
          }}>
            QUALIFY TO GO TO CABO!
          </p>
          <p style={{ width: 6 }} />
          <p>
            {promoCountTitle}
          </p>
          <p style={{ width: 6 }} />
          <p className='detail-lnk' onClick={() => {
            history.push('/event/incentive_trip')
          }}>
            See Details
          </p>
        </div> */}
        <Layout>
          <Sidebar />
          <Layout
            className={
              isCollapse ? "site-layout collapse-layout" : "site-layout"
            }
          >
            <Header
              className={
                isCollapse ? "isSideBarCollapse" : "isSideBarNotCollapse"
              }
              style={{ padding: 0 }}
            >
              <TopHeader pathname={pathname} showDrawer={showDrawer} />
            </Header>
            <Content className="site-layout-background auth-layout-children">
              <Wrapper className={"pageContent"}>
                {fullLayout ? (
                  <div
                    style={{
                      height: "100%",
                    }}
                  >
                    {children}
                  </div>
                ) : (
                  <div
                    className={"container"}
                    style={{
                      height: "100%",
                    }}
                  >
                    {children}
                  </div>
                )}
              </Wrapper>
            </Content>
            {isOpenedPopUp && (
              <PopUpModal
                isOpened={isOpenedPopUp}
                closeEdition={closePopUp}
                data={popUp}
              />
            )}
          </Layout>
        </Layout>
      </LayoutWrapper>
      <Drawer title={null} placement="left" onClose={onClose} visible={visible}>
        <Sidebar width={"256px"} />
      </Drawer>
    </BaseLayout>
  ) : (
    ""
  );
}

const Wrapper = styled.div`
  height: 100%;
`;
const LayoutWrapper = styled.div`
  height: 100%;
  // padding-top: 24px;
  // @media (max-width: 768px) {
  //   padding-top: 48px;
  // }
  .ant-layout-header {
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2),
      0px 4px 8px -4px rgba(145, 158, 171, 0.08);
    height: 68px !important;
    position: fixed;
    z-index: 7;
    width: calc(100% - 300px);
  }
  .isSideBarCollapse {
    width: calc(100% - 81px);
    z-index: 1 !important;
  }
  .isSideBarNotCollapse {
    width: calc(100% - 300px);
  }
  .site-layout {
    margin-left: 300px;
    transition: margin-left 0.7s;
    transition-timing-function: ease-in-out;
  }
  .collapse-layout {
    margin-left: 80px;
    transition: margin-left 0.7s;
    transition-timing-function: ease-in-out;
  }
  .sidebar-collapsed {
    width: 81px !important;
    transition: width 0.7s;
    border-right: 1px dashed rgba(145, 158, 171, 0.24);
    transition-timing-function: ease-in-out;
    .ant-menu-item {
      padding: 0px 0px 23px 8px !important;
      margin: 7px 0px;
    }
    .ant-menu-submenu {
      .ant-menu-submenu-title {
        padding: 0px 0px 23px 8px !important;
      }
    }
  }
  .auth-layout-children {
    padding: 24px 40px;
    margin-top: 68px;
  }
  @media (max-width: 900px) {
    .isSideBarNotCollapse {
      width: 100%;
    }
    .auth-layout-children {
      padding: 24px 20px;
    }
    .sidebar-wrapper {
      display: none;
    }
    .site-layout {
      margin-left: 0px;
    }
  }
  @media (max-width: 600px) {
    .avatar-icons {
      width: 20px !important;
    }
    .react-country-flag {
      margin-right: 10px !important;
      font-size: 18px !important;
    }
    .route-title {
      font-size: 17px !important;
    }
    .avatar-box {
      margin-right: 0px !important;
      .user-container {
        .user-title {
          font-size: 10px !important;
          line-height: 30px !important;
        }
        .user-id {
          font-size: 8px !important;
        }
      }
    }
  }
  .top-head-bar {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 10;
    background: #19B5FE;
    padding: 5px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    p {
      color: #fff;
      margin: 0;
      text-align: center;
      cursor: pointer;
    }
    .detail-lnk {
      text-decoration: underline;
      @media (max-width: 768px) {
        display: none;
      }
    }
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
`;
const GlobalStyle = createGlobalStyle`
  .weglot-container {
    display: none !important;
  }
`

import React, { useState } from "react";
import { Select } from "components/common";
import ReceiveTable from "./tables/ReceiveTable";
import ConsumeTable from "./tables/ConsumeTable";
import { Wrapper } from "./LogSubPage.styled";

export default function LogSubPage() {
  const [showTableFor, setShowTableFor] = useState('receive');
  return (
    <Wrapper>
      <div className="container-box">
        <div className='header d-flex align-items-center justify-content-between'>
          <div className="title">Loyalty Rewards Logs</div>
          <div className="date-picker">
            <Select
              value={showTableFor}
              onChange={(val) => setShowTableFor(val)}
              options={[
                {
                  label: "Received Histories",
                  value: 'receive',
                },
                {
                  label: "Purchased Histories",
                  value: 'consume',
                },
              ]}
            />
          </div>
        </div>
        <div className="table-container">
          {showTableFor === 'receive' && <ReceiveTable />}
          {showTableFor === 'consume' && <ConsumeTable />}
        </div>
      </div>
    </Wrapper>
  );
}

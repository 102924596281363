import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import copy from 'copy-to-clipboard';
import {
  Row, Col, Menu,
  Button, Badge, Dropdown,
  SuccessNotification,
} from "components/common";
import { callDeleteApiWithAuth, callPutApiWithAuth } from "utils/api";
import { uiVarLabel, uiVarList } from "common/uiVar";
import { varIs, varIsIn, varKey, varLabel } from "common/var";
import EditModal from "./EditModal";
import UploadRecordModal from "./UploadRecordModal";
import VideoModal from "./VideoModal";
import RecordVimeoModal from "./RecordVimeoModal";
import {
  LoadingOutlined, StopOutlined,
  EditOutlined, ClockCircleOutlined,
  PauseCircleOutlined, PlayCircleOutlined,
  CheckCircleOutlined, VideoCameraOutlined,
  DoubleRightOutlined,
} from "@ant-design/icons";
import { ReactComponent as ThreeDotsIcon } from "assets/icons/bi_three-dots.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";
import DummyUserImage from "assets/images/user.svg";

export default function StreamCard({ data, ...props }) {
  const history = useHistory();
  const myUser = useSelector((state) => state.auth.user);
  const [isUpdating, setIsUpdating] = useState(false);
  const [modalMode, setModalMode] = useState(undefined);
  const [hasVideo, setHasVideo] = useState(false);
  const [isStartable, setIsStartable] = useState(false);

  const onDeleteDone = (res) => {
    SuccessNotification(res.message);
    setIsUpdating(false);
    props.handleSearch();
  };
  const onDeleteFail = () => {
    setIsUpdating(false);
  };
  const handleDelete = (record) => {
    setIsUpdating(true);
    callDeleteApiWithAuth(
      `live_stream/${record.id}/delete`,
      onDeleteDone,
      onDeleteFail
    );
  };

  const handleCopyUrl = (url) => {
    copy(`${process.env.REACT_APP_LIVE_STREAM_URL}/${url}/${
      myUser ? myUser.username : ""
    }`)
    SuccessNotification("Successfully copied to clipboard");
  };

  const onStatusChanged = (res) => {
    SuccessNotification(res.message);
    setIsUpdating(false);
    props.handleSearch();
  };
  const onStatusChangeFail = () => {
    setIsUpdating(false);
  };
  const handlePublic = (record) => {
    setIsUpdating(true);
    callPutApiWithAuth(
      `live_stream/${record.id}/set_public`,
      {},
      onStatusChanged,
      onStatusChangeFail
    );
  };
  const handlePrivate = (record) => {
    setIsUpdating(true);
    callPutApiWithAuth(
      `live_stream/${record.id}/set_private`,
      {},
      onStatusChanged,
      onStatusChangeFail
    );
  };
  const handleDraft = (record) => {
    setIsUpdating(true);
    callPutApiWithAuth(
      `live_stream/${record.id}/set_draft`,
      {},
      onStatusChanged,
      onStatusChangeFail
    );
  };

  const setVimeoRecord = (record) => {
    setIsUpdating(true)
    callPutApiWithAuth(
      `live_stream/${record.id}/set_vimeo_record`, {} ,
      onStatusChanged, onStatusChangeFail
    )
  }

  const onClickTitle = () => {
    if (hasVideo) {
      setModalMode("video");
    }
    // if (isStartable) {
    //   history.push({
    //     pathname: "/publisher",
    //     state: { streamData: data },
    //   });
    // }
  };

  useEffect(() => {
    if (
      varIsIn("stream.recordStatus", data.record_status, [
        "recorded",
        "in_review",
      ]) &&
      ( data.video_link || data.vimeo_record_id)
    ) {
      setHasVideo(true);
    }
    if (
      varIsIn("stream.status", data.status, ["public", "private"]) &&
      !data.is_ended
    ) {
      setIsStartable(true);
    }
  }, [data]);

  return (
    <div className="payment-cards white-rounded-bg">
      <Dropdown
        overlay={
          <SubMenu
            data={data}
            setModalMode={setModalMode}
            handleDelete={() => handleDelete(data)}
            handlePublic={() => handlePublic(data)}
            handlePrivate={() => handlePrivate(data)}
            handleDraft={() => handleDraft(data)}
            setVimeoRecord={() => setVimeoRecord(data)}
          />
        }
        placement="bottomRight"
        overlayClassName="live-stream-sub-menu-dropdown"
      >
        <div className="three-dot-container">
          <div className="three-dot-box">
            {isUpdating ? <LoadingOutlined /> : <ThreeDotsIcon />}
          </div>
        </div>
      </Dropdown>
      <div
        className={`training-background ${hasVideo ? "has-video" : ""} ${
          isStartable ? "is-startable" : ""
        }`}
        style={{ backgroundImage: `url(${data.preview_image})` }}
        onClick={onClickTitle}
      ></div>
      <div
        className={`training-title ${hasVideo ? "has-video" : ""} ${
          isStartable ? "is-startable" : ""
        }`}
        onClick={onClickTitle}
      >
        {/* {hasVideo ? <PlayCircleOutlined style={{ marginRight: 8 }} /> : ""}
        {isStartable ? <ClockCircleOutlined style={{ marginRight: 8 }} /> : ""} */}
        {data.title}
      </div>
      <div className="name-details-container">
        <div className="user-name">
          <img src={data.user.image || DummyUserImage} />
          <div className="name-position">
            <span>
              {data.user.first_name} {data.user.last_name}
            </span>
            <div>{uiVarLabel("liveStream.topics", data.topic)}</div>
          </div>
        </div>
        <div className="language-title">
          {uiVarLabel("liveStream.languages", data.lang)}
        </div>
      </div>
      <div className="start-row">
        <div className="start-value">
          {moment(new Date(data.start_at.replace(" ", "T"))).format("LL")}
          &nbsp;
        </div>
        <div className="start-value">
          {moment(new Date(data.start_at.replace(" ", "T"))).format("LT")}
          &nbsp;
          {
            uiVarList("timezones.list").filter(
              (item) => item.text === data.timezone
            )[0]?.abbr
          }
          &nbsp;{"-"}&nbsp;
          {moment(new Date(data.end_at.replace(" ", "T"))).format("LT")}&nbsp;
          {
            uiVarList("timezones.list").filter(
              (item) => item.text === data.timezone
            )[0]?.abbr
          }
        </div>
      </div>
      <div className="training-content">{data.description}</div>
      {(!data.is_ended &&
        varIsIn("stream.status", data.status, ["public", "private"])) ||
      varIsIn("stream.recordStatus", data.record_status, ["recorded"]) ? (
        <div className="copy-container">
          <Row gutter={[10, 10]}>
            <Col span={18}>
              <div className="copy-url">
                {process.env.REACT_APP_LIVE_STREAM_URL}/{data.path}/
                {myUser.username}
              </div>
            </Col>
            <Col span={6}>
              <Button onClick={() => handleCopyUrl(data.path)}>Copy</Button>
            </Col>
          </Row>
        </div>
      ) : (
        ""
      )}
      {/*
      !data.is_ended &&
      varIsIn("stream.status", data.status, ["public", "private"]) ? (
        <div className="start-container">
          <Button
            icon={<DoubleRightOutlined />}
            onClick={onClickTitle}
          >
            Start
          </Button>
        </div>
      ) : (
        ""
      )*/}
      {varIsIn("stream.recordStatus", data.record_status, [
        "recorded",
        "in_review",
      ]) && data.video_link ? (
        <div className="copy-container">
          <Button
            icon={<PlayCircleOutlined />}
            onClick={onClickTitle}
          >
            Play
          </Button>
        </div>
      ) : (
        ""
      )}
      {modalMode === "edit" && (
        <EditModal
          data={data}
          setModalMode={setModalMode}
          handleSearch={props.handleSearch}
        />
      )}
      {modalMode === "upload_record" && (
        <UploadRecordModal
          data={data}
          setModalMode={setModalMode}
          handleSearch={props.handleSearch}
        />
      )}
      {modalMode === "video" && (
        <VideoModal data={data} setModalMode={setModalMode} />
      )}
      {modalMode === 'record_vimeo' && (
        <RecordVimeoModal data={data} setModalMode={setModalMode} 
          handleSearch={props.handleSearch}
        />
      )}
    </div>
  );
}

const SubMenu = (props) => {
  const myUser = useSelector((state) => state.auth.user);

  return (
    <Menu>
      {varIs("user.isStreamCreatable", myUser.is_stream_creatable, "yes") &&
      ((varIsIn("stream.status", props.data.status, ["public", "private"]) &&
        !props.data.is_ended) ||
        varIsIn("stream.status", props.data.status, ["draft"])) ? (
        <Menu.Item key={"edit"}>
          <a
            onClick={() => props.setModalMode("edit")}
            className="live-stream-sub-menu-item"
          >
            <div
              style={{
                fontSize: "12px",
                color: "#7f8fa3",
                display: "flex",
                alignItems: "center",
              }}
            >
              <EditOutlined
                style={{
                  marginRight: "10px",
                }}
              />
              Edit
            </div>
          </a>
        </Menu.Item>
      ) : (
        ""
      )}
      {varIsIn("stream.status", props.data.status, ["public", "private"]) &&
      !props.data.is_ended ? (
        <Menu.Item key={"draft"}>
          <a onClick={props.handleDraft} className="live-stream-sub-menu-item">
            <div
              style={{
                fontSize: "12px",
                color: "#7f8fa3",
                display: "flex",
                alignItems: "center",
              }}
            >
              <PauseCircleOutlined
                style={{
                  marginRight: "10px",
                }}
              />
              Draft
            </div>
          </a>
        </Menu.Item>
      ) : (
        ""
      )}
      {varIsIn("stream.status", props.data.status, ["private", "draft"]) &&
      !props.data.is_ended &&
      (myUser.pay_rank_id >= 8 || // 25K rank
        varIs(
          "user.isStreamSpecialCreator",
          myUser.is_stream_special_creator,
          "yes"
        ) ||
        varIs(
          "user.isStreamCompanyUser",
          myUser.is_stream_company_user,
          "yes"
        )) ? (
        <Menu.Item key={"public"}>
          <a onClick={props.handlePublic} className="live-stream-sub-menu-item">
            <div
              style={{
                fontSize: "12px",
                color: "#7f8fa3",
                display: "flex",
                alignItems: "center",
              }}
            >
              <CheckCircleOutlined
                style={{
                  marginRight: "10px",
                }}
              />
              Public
            </div>
          </a>
        </Menu.Item>
      ) : (
        ""
      )}
      {varIsIn("stream.status", props.data.status, ["public", "draft"]) &&
      !props.data.is_ended ? (
        <Menu.Item key={"private"}>
          <a
            onClick={props.handlePrivate}
            className="live-stream-sub-menu-item"
          >
            <div
              style={{
                fontSize: "12px",
                color: "#7f8fa3",
                display: "flex",
                alignItems: "center",
              }}
            >
              <StopOutlined
                style={{
                  marginRight: "10px",
                }}
              />
              Private
            </div>
          </a>
        </Menu.Item>
      ) : (
        ""
      )}
      {(
        props.data.is_ended &&
        varIsIn("stream.status", props.data.status, ["public", "private"]) &&
        varIsIn("stream.recordStatus", props.data.record_status, [
          "scheduled",
          "in_review",
        ])
      ) ? (
        <Menu.Item key={"record_vimeo"}>
          <a
            onClick={() => props.setModalMode("record_vimeo")}
            className="live-stream-sub-menu-item"
          >
            <div
              style={{
                fontSize: "12px",
                color: "#7f8fa3",
                display: "flex",
                alignItems: "center",
              }}
            >
              <VideoCameraOutlined
                style={{
                  marginRight: "10px",
                  color: "#f5222d",
                }}
              />
              Update Recorded Video
            </div>
          </a>
        </Menu.Item>
      ) : (
        ""
      )}
      {/*
      props.data.is_ended &&
      varIsIn("stream.status", props.data.status, ["public", "private"]) &&
      varIsIn("stream.recordStatus", props.data.record_status, [
        "scheduled",
        "in_review",
      ]) && 
      varIs('stream.videoType', props.data.video_type, 'zoom') ? (
        <Menu.Item key={"upload_record"}>
          <a
            onClick={() => props.setModalMode("upload_record")}
            className="live-stream-sub-menu-item"
          >
            <div
              style={{
                fontSize: "12px",
                color: "#7f8fa3",
                display: "flex",
                alignItems: "center",
              }}
            >
              <VideoCameraOutlined
                style={{
                  marginRight: "10px",
                  color: "#f5222d",
                }}
              />
              Upload Recording
            </div>
          </a>
        </Menu.Item>
      ) : (
        ""
      )
      */}
      {/*
      props.data.is_ended &&
      varIsIn("stream.status", props.data.status, ["public", "private"]) &&
      varIsIn("stream.recordStatus", props.data.record_status, [
        "scheduled",
        "in_review",
      ]) && 
      varIs('stream.videoType', props.data.video_type, 'vimeo') ? (
        <Menu.Item key={"set_vimeo_record"}>
          <a
            onClick={props.setVimeoRecord}
            className="live-stream-sub-menu-item"
          >
            <div
              style={{
                fontSize: "12px",
                color: "#7f8fa3",
                display: "flex",
                alignItems: "center",
              }}
            >
              <VideoCameraOutlined
                style={{
                  marginRight: "10px",
                  color: "#f5222d",
                }}
              />
              Set Video Recorded
            </div>
          </a>
        </Menu.Item>
      ) : (
        ""
      )
      */}
      <Menu.Item
        style={{
          minWidth: 140,
        }}
        key={"delete"}
      >
        <a onClick={props.handleDelete} className="live-stream-sub-menu-item">
          <div
            style={{
              fontSize: "12px",
              color: "#7f8fa3",
            }}
          >
            <DeleteIcon
              style={{
                marginRight: "10px",
              }}
            />
            Delete
          </div>
        </a>
      </Menu.Item>
    </Menu>
  );
};

import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import moment from "moment";
import { 
  TablePanel, Modal, Table, 
  Input, StartEndDatePicker, Button, 
} from 'components/common'
import { varIs } from "common/var";
import { callGetApiWithAuth } from 'utils/api'
import { asDate, asVolume, asCurrencyPrice } from "utils/text";

export default function CustomerReportPage() {
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [paginationParam, setPaginationParam] = useState({
    currentPage: 1,
    perPage: 15,
    total: 0,
  })
  const [searchParam, setSearchParam] = useState({
    uuid: '',
    email: '',
    level: '',
    date_range: `${moment().startOf('year').format('YYYY-MM-DD')}|${moment().format('YYYY-MM-DD')}`,
    startDate: moment().startOf('year'),
    endDate: moment(),
  })

  const loadTableData = (paginationParam0, searchParam0) => {
    setIsLoading(true)
    const params = {
      ...searchParam0,
      page: paginationParam0.currentPage,
      per_page: paginationParam0.perPage,
    }
    const q = Object.keys(params).map(k=>k+'='+params[k]).join('&')
    callGetApiWithAuth(`report/customer_report/list?${q}`, 
      ({ data }) => {
        setTableData(data.data)
        setPaginationParam({
          ...paginationParam,
          currentPage: data.current_page,
          total: data.total,
        })
        setIsLoading(false)
      }, () => {
        setIsLoading(false)
      } 
    )
  }
  const handleSearch = () => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: 1,
    }
    loadTableData(paginationParam0, searchParam)
  }
  const onPageChange = (page) => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: page,
    }
    loadTableData(paginationParam0, searchParam)
  }

  useEffect(() => {
    handleSearch()
  }, [])

  return (
    <Wrapper>
      <TablePanel
        title={'Customer Report'}
        data={tableData}
        loading={isLoading}
        paginationParam={paginationParam}
        onPageChange={onPageChange}
        toolbar={
          <div className='d-flex align-items-center flex-wrap'
            style={{ gap: '8px' }}
          >
            <div>
              <Input value={searchParam.level}
                onChange={e => {
                  setSearchParam({
                    ...searchParam,
                    level: e.target.value
                  })
                }}
                placeholder={'Level'}
                style={{ width: 100 }}
              />
            </div>
            <div>
              <Input value={searchParam.uuid}
                onChange={e => {
                  setSearchParam({
                    ...searchParam,
                    uuid: e.target.value
                  })
                }}
                placeholder={'UserID,Username'}
                style={{ width: 200 }}
              />
            </div>
            <div>
              <Input value={searchParam.email}
                onChange={e => {
                  setSearchParam({
                    ...searchParam,
                    email: e.target.value
                  })
                }}
                placeholder={'Email,Phone,First Name,Last Name'}
                style={{ width: 320 }}
              />
            </div>
            <div>
              <StartEndDatePicker
                startDate={searchParam.startDate}
                endDate={searchParam.endDate}
                onChange={(v) => {
                  if (v) {
                    setSearchParam({
                      ...searchParam,
                      startDate: v[0],
                      endDate: v[1],
                      date_range: `${moment(v[0]).format("YYYY-MM-DD")}|${moment(v[1]).format(
                        "YYYY-MM-DD"
                      )}`,
                    });
                  } else {
                    setSearchParam({
                      ...searchParam,
                      startDate: "",
                      endDate: "",
                      date_range: "",
                    });
                  }
                }}
              />
            </div>
            <div>
              <Button loading={isLoading}
                onClick={handleSearch}
              >
                Search
              </Button>
            </div>
          </div>
        }
        columns={[
          {
            title: 'Level',
            render: (_, record) => (
              <span>
                {record.level}
              </span>
            )
          },
          {
            title: 'User ID',
            render: (_, record) => (
              <span>
                {record.user.uuid}
              </span>
            )
          },
          {
            title: 'Username',
            render: (_, record) => (
              <span>
                {record.user.username}
              </span>
            )
          },
          {
            title: 'Name',
            render: (_, record) => (
              <span>
                {record.user.first_name}&nbsp;
                {record.user.last_name}
              </span>
            )
          },
          {
            title: 'Email',
            render: (_, record) => (
              <span>
                {record.user.email}
              </span>
            )
          },
          {
            title: 'Phone',
            render: (_, record) => (
              <span>
                {record.user.phone}
              </span>
            )
          },
          {
            title: 'This Month PV',
            render: (_, record) => (
              <span>
                {asVolume(record.this_month_pv)}
              </span>
            )
          },
          {
            title: 'Last Month PV',
            render: (_, record) => (
              <span>
                {asVolume(record.last_month_pv)}
              </span>
            )
          },
          {
            title: 'Join Date',
            render: (_, record) => (
              <span>
                {asDate(record.user.created_at)}
              </span>
            )
          },
          {
            title: 'Order History',
            render: (_, record) => (
              <OrderListTd data={record} />
            )
          }
        ]}
      />
    </Wrapper>
  )
}

const OrderListTd = ({ data }) => {
  const [modalMode, setModalMode] = useState(undefined)

  return (
    <>
      {data.last_orders.length>0 && 
        <a href='#' onClick={()=>setModalMode('orders')}>
          View Details
        </a>
      }
      {modalMode==='orders' && 
        <Modal title={'Order History'}
          width={1024}
          onCancel={()=>setModalMode(undefined)}
        >
          <ModalWrap>
            <Table
              pagination={false}
              dataSource={data.last_orders}
              columns={[
                {
                  title: 'Order Number',
                  render: (_, record) => (
                    <span>
                      {record.order_number}
                    </span>
                  )
                },
                {
                  title: 'Date',
                  render: (_, record) => (
                    <span>
                      {record.qualified_at?
                        asDate(record.qualified_at)
                      : '-'}
                    </span>
                  )
                },
                {
                  title: 'Total Amount',
                  render: (_, record) => (
                    <span>
                      {varIs("userBillingDetail.payType", record.pay_type, "coin") ? 
                      `${record.merchant_amount || ""} ${
                        record.coin_type ? record.coin_type.toUpperCase() : ""
                      }` : 
                      asCurrencyPrice(
                        record.local_order_total_amount,
                        record.currency_code
                      )}
                    </span>
                  )
                },
                {
                  title: 'Total Volume',
                  render: (_, record) => (
                    <span>
                      {asVolume(record.order_total_pv)}
                    </span>
                  )
                },
                {
                  title: 'Products',
                  render: (_, record) => (
                    <div>
                      {record.details.map(el => 
                      <div>
                        {`${el.quantity} x ${el.title}`}
                      </div>
                      )}
                    </div>
                  )
                }
              ]}
            />
          </ModalWrap>
        </Modal>
      }
    </>
  )
}

const Wrapper = styled.div`
  .ant-picker-range {
    height: 46px !important;
  }
`
const ModalWrap = styled.div`
  margin: -24px -24px 0 -24px;
  .ant-table-wrapper {
    overflow-x: auto;
  }
  .ant-table-container {
    min-width: 900px;
  }
`

import styled from "styled-components";

export const Wrapper = styled.div`
  margin: -24px -40px -24px -40px;
  @media (max-width: 900px) {
    margin: -24px -20px !important;
    // height: 100% !important;
    .cometchat {
      border: none !important;
      .chats__sidebar {
        box-shadow: none !important;
      }
    }
  }
  .main-container {
    height: 100%;
    .loading-spin {
      width: 100vw;
      height: calc(100vh - 120px);
    }
  }
  .chat__header {
    padding: 16px 16px 16px 0px;
  }
  .chat__details {
    width: 100%;
  }
  .chat__options {
    display: none;
  }
  .button__emoji,
  .button__reactions {
    display: none;
  }
  .input__sticky__attachment {
    visibility: hidden;
  }
  .receiver__message__container {
    .message__actions {
      display: none;
    }
  }
  .message__actions {
    .action__group:first-child {
      display: none;
    }
  }
`;

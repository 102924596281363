import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  TablePanel,
  Input,
  Button,
  Select,
  UserAvatar,
  Tooltip,
  RankBadge,
  CountryFlag,
} from "components/common";
import { callGetApiWithAuth } from "utils/api";
import { asVolume } from "utils/text";
import InfoIcon from "assets/icons/info.svg";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { ReactComponent as SearchOutlinedIcon } from "assets/icons/search-icon.svg";

export default function TableView() {
  const [tableData, setTableData] = useState([]);
  const [paginationParam, setPaginationParam] = useState({
    currentPage: 1,
    perPage: 15,
    total: 0,
  });
  const [searchParam, setSearchParam] = useState({
    uuid: "",
    sort_by: "level_asc",
    active_only: "",
    qual_only: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const onFormInput = (field, value) => {
    setSearchParam({
      ...searchParam,
      [field]: value,
    });
  };

  const onGetTableData = (res) => {
    setIsLoading(false);
    setTableData(res.data.data);
    setPaginationParam({
      ...paginationParam,
      currentPage: res.data.current_page,
      total: res.data.total,
    });
  };
  const onFailTableData = () => {
    setIsLoading(false);
  };
  const loadTableData = (paginationParam0, searchParam0) => {
    setIsLoading(true);
    const params = {
      ...searchParam0,
      page: paginationParam0.currentPage,
      per_page: paginationParam0.perPage,
    };
    const q = Object.keys(params)
      .map((k) => k + "=" + params[k])
      .join("&");
    callGetApiWithAuth(
      `report/binary_qual/list?${q}`,
      onGetTableData,
      onFailTableData
    );
  };

  const onPageChange = (page) => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: page,
    };
    loadTableData(paginationParam0, searchParam);
  };

  const handleSearch = () => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: 1,
    };
    loadTableData(paginationParam0, searchParam);
  };

  useEffect(() => {
    handleSearch();
  }, []);

  return (
    <Wrapper>
      <TablePanel
        data={tableData}
        loading={isLoading}
        paginationParam={paginationParam}
        onPageChange={onPageChange}
        toolbar={
          <div className="searchActionContainer">
            <div className="searchField search_bar">
              <Input
                value={searchParam.uuid}
                onChange={(e) => onFormInput("uuid", e.target.value)}
                label={"Search by User ID"}
                inputPrefix={<SearchOutlinedIcon />}
              />
            </div>
            <div className="searchField">
              <Select
                label="Filter by"
                value={searchParam.qual_only}
                onChange={(v) => onFormInput("qual_only", v)}
                options={[
                  { label: "All", value: "" },
                  { label: "Qualified Only", value: "y" },
                ]}
              />
            </div>
            <div className="searchField">
              <Select
                label="Filter by"
                value={searchParam.active_only}
                onChange={(v) => onFormInput("active_only", v)}
                options={[
                  { label: "All", value: "" },
                  { label: "Active Only", value: "y" },
                ]}
              />
            </div>
            <div className="searchField">
              <Select
                label="Sort By"
                value={searchParam.sort_by}
                onChange={(v) => onFormInput("sort_by", v)}
                options={[
                  { label: "Level Desc", value: "level_desc" },
                  { label: "Level Asc", value: "level_asc" },
                  { label: "Rank Desc", value: "rank_desc" },
                  { label: "Rank Asc", value: "rank_asc" },
                  { label: "Country Desc", value: "country_desc" },
                  { label: "Country Asc", value: "country_asc" },
                ]}
              />
            </div>
            <Button loading={isLoading} onClick={handleSearch}>
              Search
            </Button>
          </div>
        }
        columns={[
          {
            title: "User ID",
            key: "uuid",
            render: (_, record) => <span>{record.user.uuid}</span>,
          },
          {
            title: "Name",
            key: "user",
            render: (_, record) => (
              <UserAvatar
                image={record.user.image}
                title={`${record.user.first_name} ${record.user.last_name}`}
              />
            ),
          },
          {
            title: "Country",
            key: "country",
            render: (_, record) => (
              <CountryFlag country={record.user.country} />
            ),
          },
          {
            title: "Level",
            align: "center",
            key: "level",
            render: (_, record) => <span>{record.level}</span>,
          },
          {
            title: "Rank",
            align: "center",
            key: "rank_id",
            render: (_, record) => <RankBadge label={record.user.rank.name} />,
          },
          {
            title: () => (
              <span>
                Is Qualified? (Yes/No)
                <Tooltip
                  title={() => (
                    <div>
                      To be Binary Qualified, you must have a minimum of 40
                      Personal Volume (PV), 90 Business Volume (BV) from
                      personally enrolled Affiliates/Preferred Customers on each
                      your left and right leg, and a minimum of 300 Business
                      Volume (BV) on your lesser leg.
                    </div>
                  )}
                >
                  <ExclamationCircleFilled className="info-icon" />
                </Tooltip>
              </span>
            ),
            align: "center",
            key: "is_qualified",
            render: (_, record) => {
              const isQualified =
                record.user.rank_id >= 2 &&
                (record.pv >= 40 || record.pecv >= 80) &&
                record.l_bpev >= 90 &&
                record.r_bpev >= 90 &&
                record.l_bgv * 1 + record.l_carry * 1 >= 300 &&
                record.r_bgv * 1 + record.r_carry * 1 >= 300;

              return (
                <span
                  className={`qual-badge ${
                    isQualified ? "active" : "inactive"
                  }`}
                >
                  {isQualified ? "Yes" : "No"}
                </span>
              );
            },
          },
          {
            title: () => <span>PV</span>,
            key: "pv",
            render: (_, record) => <span>{asVolume(record.pv)}</span>,
          },
          {
            title: () => <span>PECV</span>,
            key: "pecv",
            render: (_, record) => <span>{asVolume(record.pecv)}</span>,
          },
          {
            title: () => <span>Left Leg Personal Volume</span>,
            key: "l_bpev",
            render: (_, record) => <span>{asVolume(record.l_bpev)}</span>,
          },
          {
            title: () => <span>Right Leg Personal Volume</span>,
            key: "r_bpev",
            render: (_, record) => <span>{asVolume(record.r_bpev)}</span>,
          },
          {
            title: () => <span>Left Leg Business Volume</span>,
            key: "l_bgv",
            render: (_, record) => <span>{asVolume(record.l_bgv)}</span>,
          },
          {
            title: () => <span>Right Leg Business Volume</span>,
            key: "r_bgv",
            render: (_, record) => <span>{asVolume(record.r_bgv)}</span>,
          },
        ]}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .qual-badge {
    width: 45px;
    padding: 5px 10px;
    display: inline-block;
    font-size: 10px;
    background: rgba(145, 158, 171, 0.08);
    color: #212b36;
    text-transform: uppercase;
    border-radius: 4px;
    font-weight: 700;
    &.active {
      background: rgba(0, 184, 217, 0.16);
      color: #00b8d9;
    }
  }
  .info-icon {
    width: 12px;
    height: 12px;
    margin-left: 4px;
    color: #1ea9ea;
    bottom: 3px;
    position: relative;
  }
  .table-new-wrapper {
    overflow-x: auto;
    .ant-table-container {
      @media (max-width: 1600px) {
        width: 1800px !important;
      }
    }
  }
  .panelHead {
    flex-wrap: wrap;
    .searchActionContainer {
      display: flex;
      align-items: center;
      gap: 16px;
      flex-wrap: wrap;
      .searchField {
        .input-prefix {
          svg {
            width: 15px;
            height: 15px;
            margin-bottom: 5px;
          }
        }
        .ant-input {
          height: 40px !important;
        }
        .ant-select-selector {
          height: 40px !important;
          width: 180px !important;
          padding-top: 4px !important;
        }
        .ant-select {
          height: 40px !important;
          color: #212b36;
        }
      }
    }
  }
  @media (max-width: 600px) {
    .toolbar {
      .searchActionContainer {
        gap: 24px !important;
        .searchField,
        .actionButton {
          width: 100% !important;
        }
        button {
          width: 100%;
        }
      }
    }
    .ant-select-selector {
      width: 100% !important;
    }
  }
`;

import React, { useEffect, useState } from "react";
import {
  Row, Col, Modal,  
  FormItem, VideoUploader, Button, 
  Popconfirm, SuccessNotification
} from "components/common";
import { callPostApiWithAuth } from "utils/api";
import { Wrapper } from "./EditModal.styled";

export default function UploadRecordModal(props) {
  const [videos, setVideos] = useState([])
  const [isUploading, setIsUploading] = useState(false)
  const [errors, setErrors] = useState([])

  const onRemoveVideo = () => {
    setVideos([])
  };

  const onSelectVideo = (files) => {
    setVideos([files[0]])
  };

  const onUploadDone = (res) => {
    setIsUploading(false)
    SuccessNotification(res.message);
    props.handleSearch();
  }
  const onUploadFail = () => {
    setIsUploading(false)
  }

  const handleUpload = (status) => {
    let errors0 = []

    if (videos.length===0) {
      errors0.push({
        field: 'video',
        message: 'Please select video'
      })
    }
    setErrors(errors0);

    if (errors0.length>0) {
      return
    }

    let body = new FormData();
    body.append('video', videos[0])
    
    setIsUploading(true)

    callPostApiWithAuth(`live_stream/${props.data.id}/upload_record`, body, 
      onUploadDone, onUploadFail
    );
  };

  return (
    <Modal width={600}
      onCancel={() => props.setModalMode(undefined)}
    >
      <Wrapper>
        <div className="modal-title-container">
          Upload Recording Stream Video File
        </div>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <div className="input-container single-container-uploader">
              <FormItem
                label={"Recorded Video"}
                errorMessages={errors.filter(el => 
                  el.field==='video'
                )}
              >
                <VideoUploader
                  className="upload-container-product"
                  files={videos}
                  onChangeFile={onSelectVideo}
                  onDeleteFile={onRemoveVideo}
                  width="100%"
                />
              </FormItem>
            </div>
          </Col>
          <Col span={24}>
            <div className="action-btn">
              <Popconfirm onConfirm={handleUpload}>
                <Button loading={isUploading}>
                  Upload
                </Button>
              </Popconfirm>
            </div>
          </Col>
        </Row>
      </Wrapper>
    </Modal>
  );
}

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from 'styled-components';
import { Row, Col } from "components/common";
import { asUsdPrice, asNumber } from "utils/text";
import { ReactComponent as CreditIcon } from "assets/icons/home/credit.svg";
import { ReactComponent as BrandBuckIcon } from "assets/icons/home/brand-bucks.svg";
import { ReactComponent as LoyaltyIcon } from "assets/icons/home/loyalty.svg";
import { ReactComponent as GiftIcon } from "assets/icons/home/gift.svg";

export default function WalletSection() {
  const myUser = useSelector((state) => state.auth.user);

  return (
    <Wrapper>
      <h2>Wallets</h2>
      <div className='w-card'>
        <div>
          <h4>Credit Wallet</h4>
          <p>{asUsdPrice(myUser?.wallet.current_balance)}</p>
        </div>
        <CreditIcon />
      </div>
      <div className='w-card'>
        <div>
          <h4>Brand Bucks</h4>
          <p>{asUsdPrice(myUser?.bucket_wallet.amount)}</p>
        </div>
        <BrandBuckIcon />
      </div>
      <div className='w-card'>
        <div>
          <h4>Loyalty Rewards</h4>
          <p>{asUsdPrice(myUser?.loyalty_wallet.amount)}</p>
        </div>
        <LoyaltyIcon />
      </div>
      <div className='w-card'>
        <div>
          <h4>Gift Wallet</h4>
          <p>{asUsdPrice(myUser?.gift_wallet.amount)}</p>
        </div>
        <GiftIcon />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  box-shadow: 0px 0px 2px rgb(145 158 171 / 20%), 0px 12px 24px -4px rgb(145 158 171 / 12%);
  border-radius: 16px;
  height: 100%;
  padding: 24px;
  h2 {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #212b36;
    margin-bottom: 24px;
  }
  .w-card {
    border: 0.62642px solid rgba(145,158,171,0.24);
    border-radius: 10.0227px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
    h4 {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #919eab;
    }
    p {
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      margin: 0;
    }
  }
`

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import LogoImg from "assets/images/loginLogo.png";
import LoyaltyProgramImg from "assets/images/loyalty/loyalty_reward_program.png";
import ArrowUp from "assets/icons/arrow_up_black.svg";
import ArrowDown from "assets/icons/arrow_down_black.svg";
import { callGetApiWithAuth } from "utils/api";

export default function LoyaltyDescriptionSection() {
  const [isLoading, setIsLoading] = useState(false);
  const [faqs, setFaqs] = useState([]);

  const onGetSuccessFaqs = ({ data }) => {
    setIsLoading(false);
    setFaqs(data && data["Loyalty Rewards"] ? data["Loyalty Rewards"] : []);
  };

  const onGetFailedFaqs = () => {
    setIsLoading(false);
  };

  const loadFaqs = () => {
    setIsLoading(true);
    callGetApiWithAuth(`faqs?is_loyalty=1`, onGetSuccessFaqs, onGetFailedFaqs);
  };

  useEffect(() => {
    loadFaqs();
  }, []);

  return (
    <Wrapper>
      <div className="row loyalty-program-description">
        <div className="col-xl-6 order-md-2 text-info-section">
          <img src={LogoImg} className="logo-img" />
          <h5>Loyalty Rewards Program</h5>
          <p className="text-para">
            Subscribe to Save is a convenient way to have your favorite
            MyDailyChoice products coming to your doorstep each and every month
            without having to place a manual order. This is the smartest way to
            shop and ensure that you are never running low on our products.
          </p>
          <p className="text-para">
            For setting up your Subscription, MyDailyChoice rewards you with our
            Loyalty Rewards Program. Each month, you will receive up to $30 in
            Loyalty Rewards which can be redeemed on MyDailyChoice products.
          </p>
        </div>
        <div className="col-xl-6 order-md-1 img-info-section">
          <img src={LoyaltyProgramImg} />
        </div>
      </div>
      <div className="row table-section">
        <div className="col-md-6 col-xl-3" style={{ marginBottom: 10 }}>
          <div className="row info-table">
            <div className="col-12 table-title">
              <span>Spend $100 Monthly</span>
            </div>
            <div className="col-6 table-head">
              <span>Month 3</span>
            </div>
            <div className="col-6 table-value">
              <span>$10</span>
            </div>
            <div className="col-6 table-head">
              <span>Month 4</span>
            </div>
            <div className="col-6 table-value">
              <span>$10</span>
            </div>
            <div className="col-6 table-head">
              <span>Month 5</span>
            </div>
            <div className="col-6 table-value">
              <span>$10</span>
            </div>
            <div className="col-6 table-head">
              <span>Month 6</span>
            </div>
            <div className="col-6 table-value">
              <span>$10</span>
            </div>
            <div className="col-6 table-head">
              <span>Month 7</span>
            </div>
            <div className="col-6 table-value">
              <span>$10</span>
            </div>
            <div className="col-6 table-head">
              <span>Month 8</span>
            </div>
            <div className="col-6 table-value">
              <span>$10</span>
            </div>
            <div className="col-6 table-head">
              <span>Month 9</span>
            </div>
            <div className="col-6 table-value">
              <span>$10</span>
            </div>
            <div className="col-6 table-head">
              <span>Month 10</span>
            </div>
            <div className="col-6 table-value">
              <span>$10</span>
            </div>
            <div className="col-6 table-head">
              <span>Month 11</span>
            </div>
            <div className="col-6 table-value">
              <span>$10</span>
            </div>
            <div className="col-6 table-head">
              <span>Month 12</span>
            </div>
            <div className="col-6 table-value">
              <span>$10</span>
            </div>
            <div className="col-12 table-footer">
              <p>Total</p>
              <span>$100 in Loyalty Rewards</span>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xl-3" style={{ marginBottom: 10 }}>
          <div className="row info-table">
            <div className="col-12 table-title">
              <span>Spend $150 Monthly</span>
            </div>
            <div className="col-6 table-head">
              <span>Month 3</span>
            </div>
            <div className="col-6 table-value">
              <span>$15</span>
            </div>
            <div className="col-6 table-head">
              <span>Month 4</span>
            </div>
            <div className="col-6 table-value">
              <span>$15</span>
            </div>
            <div className="col-6 table-head">
              <span>Month 5</span>
            </div>
            <div className="col-6 table-value">
              <span>$15</span>
            </div>
            <div className="col-6 table-head">
              <span>Month 6</span>
            </div>
            <div className="col-6 table-value">
              <span>$15</span>
            </div>
            <div className="col-6 table-head">
              <span>Month 7</span>
            </div>
            <div className="col-6 table-value">
              <span>$15</span>
            </div>
            <div className="col-6 table-head">
              <span>Month 8</span>
            </div>
            <div className="col-6 table-value">
              <span>$15</span>
            </div>
            <div className="col-6 table-head">
              <span>Month 9</span>
            </div>
            <div className="col-6 table-value">
              <span>$15</span>
            </div>
            <div className="col-6 table-head">
              <span>Month 10</span>
            </div>
            <div className="col-6 table-value">
              <span>$15</span>
            </div>
            <div className="col-6 table-head">
              <span>Month 11</span>
            </div>
            <div className="col-6 table-value">
              <span>$15</span>
            </div>
            <div className="col-6 table-head">
              <span>Month 12</span>
            </div>
            <div className="col-6 table-value">
              <span>$15</span>
            </div>
            <div className="col-12 table-footer">
              <p>Total</p>
              <span>$150 in Loyalty Rewards</span>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xl-3" style={{ marginBottom: 10 }}>
          <div className="row info-table">
            <div className="col-12 table-title">
              <span>Spend $200 Monthly</span>
            </div>
            <div className="col-6 table-head">
              <span>Month 3</span>
            </div>
            <div className="col-6 table-value">
              <span>$20</span>
            </div>
            <div className="col-6 table-head">
              <span>Month 4</span>
            </div>
            <div className="col-6 table-value">
              <span>$20</span>
            </div>
            <div className="col-6 table-head">
              <span>Month 5</span>
            </div>
            <div className="col-6 table-value">
              <span>$20</span>
            </div>
            <div className="col-6 table-head">
              <span>Month 6</span>
            </div>
            <div className="col-6 table-value">
              <span>$20</span>
            </div>
            <div className="col-6 table-head">
              <span>Month 7</span>
            </div>
            <div className="col-6 table-value">
              <span>$20</span>
            </div>
            <div className="col-6 table-head">
              <span>Month 8</span>
            </div>
            <div className="col-6 table-value">
              <span>$20</span>
            </div>
            <div className="col-6 table-head">
              <span>Month 9</span>
            </div>
            <div className="col-6 table-value">
              <span>$20</span>
            </div>
            <div className="col-6 table-head">
              <span>Month 10</span>
            </div>
            <div className="col-6 table-value">
              <span>$20</span>
            </div>
            <div className="col-6 table-head">
              <span>Month 11</span>
            </div>
            <div className="col-6 table-value">
              <span>$20</span>
            </div>
            <div className="col-6 table-head">
              <span>Month 12</span>
            </div>
            <div className="col-6 table-value">
              <span>$20</span>
            </div>
            <div className="col-12 table-footer">
              <p>Total</p>
              <span>$200 in Loyalty Rewards</span>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xl-3" style={{ marginBottom: 10 }}>
          <div className="row info-table">
            <div className="col-12 table-title">
              <span>Spend $300 Monthly</span>
            </div>
            <div className="col-6 table-head">
              <span>Month 3</span>
            </div>
            <div className="col-6 table-value">
              <span>$30</span>
            </div>
            <div className="col-6 table-head">
              <span>Month 4</span>
            </div>
            <div className="col-6 table-value">
              <span>$30</span>
            </div>
            <div className="col-6 table-head">
              <span>Month 5</span>
            </div>
            <div className="col-6 table-value">
              <span>$30</span>
            </div>
            <div className="col-6 table-head">
              <span>Month 6</span>
            </div>
            <div className="col-6 table-value">
              <span>$30</span>
            </div>
            <div className="col-6 table-head">
              <span>Month 7</span>
            </div>
            <div className="col-6 table-value">
              <span>$30</span>
            </div>
            <div className="col-6 table-head">
              <span>Month 8</span>
            </div>
            <div className="col-6 table-value">
              <span>$30</span>
            </div>
            <div className="col-6 table-head">
              <span>Month 9</span>
            </div>
            <div className="col-6 table-value">
              <span>$30</span>
            </div>
            <div className="col-6 table-head">
              <span>Month 10</span>
            </div>
            <div className="col-6 table-value">
              <span>$30</span>
            </div>
            <div className="col-6 table-head">
              <span>Month 11</span>
            </div>
            <div className="col-6 table-value">
              <span>$30</span>
            </div>
            <div className="col-6 table-head">
              <span>Month 12</span>
            </div>
            <div className="col-6 table-value">
              <span>$30</span>
            </div>
            <div className="col-12 table-footer">
              <p>Total</p>
              <span>$300 in Loyalty Rewards</span>
            </div>
          </div>
        </div>
      </div>
      <div className="row  loyalty-faq-section">
        <div className="col-12">
          <h3 className="inner-h3 loyalty-faq-title">Loyalty Rewards FAQ</h3>
        </div>
        {faqs.map((el, index) => (
          <div className="col-sm-12" key={index}>
            <div className="faq">
              <div
                className={`faq-title ${index === 0 ? "" : "collapsed"}`}
                data-bs-toggle="collapse"
                href={`#faqCollapse${index}`}
                role="button"
                aria-expanded="false"
                aria-controls={`faqCollapse${index}`}
              >
                <h5>{el.title}</h5>
              </div>
              <div
                className={`faq-content collapse ${
                  index === 0 ? "active show" : ""
                }`}
                id={`faqCollapse${index}`}
              >
                <span dangerouslySetInnerHTML={{ __html: el.content }} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </Wrapper>
  );
}

export const Wrapper = styled.div`
  margin-top: 40px;
  .loyalty-program-description {
  }
  .logo-img {
    width: 170px;
    margin-bottom: 24px;
  }
  h5 {
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    color: #212b36;
    margin-bottom: 24px;
  }
  .text-info-section {
  }
  .img-info-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .img-info-section img {
    height: 100%;
    width: 100%;
    border-radius: 16px;
    object-fit: cover;
  }
  .table-section {
    margin-top: 24px;
    padding: 0px;
  }
  .table-section .info-table {
    padding: 0 10px;
  }
  .table-title {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(145, 158, 171, 0.24);
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #212b36;
    padding: 16px;
    border-radius: 8px 8px 0px 0px;
  }
  .table-title span {
    text-align: center;
  }
  .table-head {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
    padding: 0;
  }
  .text-para {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #637381;
  }
  .table-head span {
    padding: 16px;
    background: #f9fafb;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #637381;
    width: 100%;
    height: 100%;
    margin-right: 4px;
    text-align: center;
  }
  .table-value {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
    padding: 0;
  }
  .table-value span {
    padding: 16px;
    background: #f4f6f8;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #212b36;
    width: 100%;
    height: 100%;
    margin-left: 4px;
    text-align: center;
  }
  .table-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: rgba(145, 158, 171, 0.24);
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: #212b36;
    padding: 16px;
    border-radius: 0px 0px 8px 8px;
    margin-top: 8px;
  }
  .table-footer p {
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: #212b36;
    margin: 0;
  }
  .table-footer span {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #212b36;
    text-align: center;
  }

  .loyalty-faq-section {
    width: 100%;
    padding: 0px;
    margin-top: 30px;
    position: relative;
    left: 10px;
  }

  .loyalty-faq-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #212b36;
    margin-bottom: 24px;
  }

  .faq-title.collapsed h5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #212b36;
  }

  .faq-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border: unset;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #212b36;
    background: #f4f6f8;
    border-radius: 8px 8px 0px 0px;
    h5 {
      font-weight: 600;
      margin: 0px;
      font-size: 16px;
      line-height: 24px;
      color: #212b36;
    }
  }

  .faq-content {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #212b36;
    padding: 16px;
    p {
      margin: 0px;
    }
  }

  .faq-title.collapsed {
    background: white;
    border-radius: 0px;
    border-top: 1px solid rgba(145, 158, 171, 0.24);
  }

  .faq-title:after {
    content: "";
    background: url(${ArrowUp});
    width: 10px;
    height: 10px;
    background-size: 100%;
    background-repeat: no-repeat;
    float: right;
  }

  .faq-title.collapsed:after {
    content: "";
    background: url(${ArrowDown});
    background-size: 100%;
    background-repeat: no-repeat;
    width: 10px;
    height: 10px;
  }
  .faq-content.show {
    background: #f4f6f8;
    border-radius: 0px 0px 8px 8px;
    margin-bottom: 16px;
  }
  .faq {
    padding: 0;
  }

  .faqs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media only screen and (max-width: 767px) {
    .table-section .info-table {
      // margin-top: 30px;
    }
  }

  @media (max-width: 1300px) {
    .loyalty-program-description {
      flex-direction: column !important;
      .text-info-section {
        width: 100% !important;
        img {
          margin-top: 24px;
        }
      }
      .img-info-section {
        width: 100% !important;
      }
    }
  }

  @media (max-width: 767px) {
    .loyalty-program-description {
      flex-direction: column-reverse !important;
    }
  }
`;

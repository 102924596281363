import React from "react";
import styled from "styled-components";
import { Modal, TablePanel } from "components/common";
import { varIs } from "common/var";
import { asCurrencyPrice } from "utils/text";
import { countryName } from "common/country";

export default function DetailModal(props) {
  return (
    <Modal width={800} onCancel={() => props.setSelectedOrder(undefined)}>
      <Wrapper>
        <div className="modal-new-title">{`Order #${props.selectedOrder.order_number}`}</div>
        <div className={"d-flex align-items-center justify-content-between"}>
          <div>
            {props.selectedOrder.tracking_number ? (
              <a
                href={props.selectedOrder.tracking_url}
                target={"_blank"}
                rel="noreferrer"
              >
                {props.selectedOrder.tracking_number}
              </a>
            ) : (
              ""
            )}
          </div>
        </div>
        <h4>Order Details</h4>
        <div className="table-order">
          <TablePanel
            data={props.selectedOrder.details}
            pagination={false}
            columns={[
              {
                title: "#",
                key: "sno",
                render: (_, __, index) => <span>{index + 1}</span>,
              },
              {
                title: "Name",
                key: "product",
                render: (_, record) => <span>{record.title}</span>,
              },
              {
                title: "Price",
                key: "price",
                render: (_, record) => (
                  <span>
                    {asCurrencyPrice(
                      record.local_price,
                      props.selectedOrder.currency_code
                    )}
                  </span>
                ),
              },
              {
                title: "PV",
                key: "total_amount",
                render: (_, record) => <span>{record.pv}</span>,
              },
              {
                title: "Qty",
                key: "quantity",
                render: (_, record) => <span>{record.quantity}</span>,
              },
              {
                title: "Line Total",
                key: "total_amount",
                render: (_, record) => (
                  <span>
                    {asCurrencyPrice(
                      record.local_total_amount,
                      props.selectedOrder.currency_code
                    )}
                  </span>
                ),
              },
            ]}
          />
        </div>
        <h4>Shipping Details</h4>
        <div className="table-order">
          <TablePanel
            data={props.selectedOrder.shipping_details}
            pagination={false}
            columns={[
              {
                title: "#",
                key: "sno",
                render: (_, __, index) => <span>{index + 1}</span>,
              },
              {
                title: "Name",
                key: "product",
                render: (_, record) => <span>{record.title}</span>,
              },
              {
                title: "Qty",
                key: "quantity",
                render: (_, record) => <span>{record.quantity}</span>,
              },
            ]}
          />
        </div>
        <h4>
          Total:&nbsp;
          {varIs("order.payType", props.selectedOrder.pay_type, "coin")
            ? `${
                props.selectedOrder.merchant_amount
              } ${props.selectedOrder.coin_type.toUpperCase()}`
            : asCurrencyPrice(
                props.selectedOrder.local_order_total_amount,
                props.selectedOrder.currency_code
              )}
          &nbsp;&nbsp;&nbsp; Tax:&nbsp;
          {asCurrencyPrice(
            props.selectedOrder.local_tax_amount,
            props.selectedOrder.currency_code
          )}
          &nbsp;&nbsp;&nbsp; Shipping Price:&nbsp;
          {asCurrencyPrice(
            props.selectedOrder.local_shipping_price,
            props.selectedOrder.currency_code
          )}
          {props.selectedOrder.local_duty_price*1>0 && 
          <>
            &nbsp;&nbsp;&nbsp;{'Customs & Duties'}:&nbsp;
            {asCurrencyPrice(
              props.selectedOrder.local_duty_price,
              props.selectedOrder.currency_code
            )}
          </>}
          &nbsp;&nbsp;&nbsp; PV:&nbsp;
          {props.selectedOrder.order_total_pv}
          &nbsp;&nbsp;&nbsp;
        </h4>
        {props.selectedOrder.shipping_address && (
          <h4>
            Ship To:&nbsp;
            {props.selectedOrder.shipping_address
              ? props.selectedOrder.shipping_address + ", "
              : ""}
            {props.selectedOrder.shipping_address_line2
              ? props.selectedOrder.shipping_address_line2 + ", "
              : ""}
            {props.selectedOrder.shipping_city
              ? props.selectedOrder.shipping_city + ", "
              : ""}
            {props.selectedOrder.shipping_state
              ? props.selectedOrder.shipping_state + ", "
              : ""}
            {props.selectedOrder.shipping_zipcode
              ? props.selectedOrder.shipping_zipcode + ", "
              : ""}
            {props.selectedOrder.shipping_country
              ? countryName(props.selectedOrder.shipping_country)
              : ""}
          </h4>
        )}
      </Wrapper>
    </Modal>
  );
}

const Wrapper = styled.div`
  h4 {
    font-size: 16px;
  }
  .modal-new-title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #212b36;
    margin-bottom: 20px;
  }
  .panelHead {
    display: none;
  }
  .table-order {
    margin: 12px 0px 45px;
  }

  @media (max-width: 800px) {
    .ant-table {
      overflow: auto;
      .ant-table-container {
        width: 900px;
      }
    }
  }
`;

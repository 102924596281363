import styled from "styled-components";

export const Wrapper = styled.div`
  .wallet-alert {
    margin-bottom: 20px;
  }
  .info-container {
    box-shadow: 0px 0px 2px rgb(145 158 171 / 20%),
      0px 12px 24px -4px rgb(145 158 171 / 12%);
    border-radius: 16px;
    padding: 24px;
  }
  .title {
    font-weight: 700;
    font-size: 14px;
    line-height: 28px;
    text-transform: uppercase;
    color: #637381;
    margin-bottom: 16px;
  }
  .ant-select {
    width: 100% !important;
    input {
      // height: 38px !important;
    }
  }
  .user-country {
    margin-bottom: 20px;
  }
  .user-type {
    margin-bottom: 20px;
  }
  .input-form {
  }
  .products {
  }
  .error {
    color: #ff4d4d;
  }
  .quantity {
    .ant-input-number-group {
      display: flex;
      .ant-input-number-group-addon {
        cursor: pointer;
        padding: 0;
        width: auto;
        span {
          width: 30px;
          height: 100%;
          padding: 0 11px;
          display: flex;
          align-items: center;
          justify-content: center;
          user-select: none;
        }
      }
    }
    input {
      height: 38px;
      width: 90px;
      text-align: center;
    }
  }
  .add-to-cart-section {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 7px;
  }
  .add-cart-btn {
    height: 40px;
    margin-left: 15px;
  }
  .user-form {
    input {
      height: 40px;
    }
    .password-input {
      input {
        height: 30px;
      }
    }
    .password-visibility {
      cursor: pointer;
    }
    .dob-input {
      width: 100% !important;
      height: 40px !important;
    }
  }
  .purchase-btn {
    height: 48px !important;
    margin-top: 20px;
  }
  @media (max-width: 600px) {
    .ant-col {
      width: 100%;
    }
  }
  .pur1-btn {
    display: flex;
    align-items: center;
    justify-content: end;
    button {
      margin-top: 40px;
      background: #1ea9ea;
      border-radius: 8px;
      padding: 8px 22px;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      border: none;
      color: #ffffff;
    }
  }
  @media only screen and (max-width: 767px) {
    .quantity {
      input {
        width: 48px;
      }
    }
  }
  .qty-root {
    position: relative;
    width: 96px;
    .anticon {
      cursor: pointer;
      position: absolute;
      top: 12px;
      left: 12px;
      z-index: 12;
      &.plus-icon {
        left: auto;
        right: 12px;
      }
    }
    .ant-input {
      text-align: center;
      padding: 0 30px 0px 39px;
      height: 38px !important;
    }
  }
  .add-cart-btn-1 {
    background: #ffab00;
    border-radius: 8px;
    border: none;
    font-weight: 700;
    font-size: 15px;
    line-height: 26px;
    color: #212b36;
    height: 38px;
    margin-left: 16px;
    padding: 0px 22px;
    svg {
      height: 20px;
      min-width: 20px;
      path {
        fill: #212b36;
      }
    }
  }
  @media (max-width: 600px) {
    .add-to-cart-section {
      .qty-root,
      .add-cart-btn-1 {
        width: 50% !important;
      }
    }
    .pur1-btn {
      button {
        width: 100%;
      }
    }
  }
`;

import React from 'react'
import TableView from './table/TableView'

export default function TicketReportPage() {
  return (
    <>
      <TableView />
    </>
  )
}

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ReactAudioPlayer from "react-audio-player";
import { Select, List, Spin } from "components/common";
import { callGetApiWithAuth } from "utils/api";

export default function EventAudioPage() {
  const [languages, setLanguages] = useState([]);
  const [products, setProducts] = useState([]);
  const [searchParam, setSearchParam] = useState({
    product_id: "",
    language_id: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const onGetProducts = (res) => {
    setProducts(res.data);
    if (res.data.length > 0) {
      setSearchParam({
        ...searchParam,
        product_id: res.data[0].id,
      });
    }
  };
  const onGetLanguages = (res) => {
    setLanguages(res.data);
    if (res.data.length > 0) {
      setSearchParam({
        ...searchParam,
        language_id: res.data[0].id,
      });
    }
  };

  const onGetListData = (res) => {
    setListData(res.data);
    setIsLoading(false);
  };
  const onFailListData = () => {
    setIsLoading(false);
  };
  const loadListData = (searchParam0) => {
    const params = {
      ...searchParam0,
    };
    const q = Object.keys(params)
      .map((k) => k + "=" + params[k])
      .join("&");
    setIsLoading(true);
    callGetApiWithAuth(`event_audio/list?${q}`, onGetListData, onFailListData);
  };

  useEffect(() => {
    callGetApiWithAuth(`event_audio/products`, onGetProducts);
  }, []);

  useEffect(() => {
    if (searchParam.product_id) {
      setSearchParam({
        ...searchParam,
        language_id: "",
      });
      setLanguages([]);
      const params = {
        product_id: searchParam.product_id,
      };
      const q = Object.keys(params)
        .map((k) => k + "=" + params[k])
        .join("&");
      setLanguages([]);
      callGetApiWithAuth(`event_audio/languages?${q}`, onGetLanguages);
    }
  }, [searchParam.product_id]);

  useEffect(() => {
    if (searchParam.product_id && searchParam.language_id) {
      loadListData(searchParam);
    }
  }, [searchParam.language_id]);

  return (
    <Wrapper>
      <div className="headerSearch">
        <div className="productField">
          <Select
            label="Event"
            value={searchParam.product_id}
            onChange={(v) =>
              setSearchParam({
                ...searchParam,
                product_id: v,
              })
            }
            options={products.map((el) => ({
              label: el.title,
              value: el.id,
            }))}
          />
        </div>
        <div style={{ width: 12 }} />
        <div className="langField">
          <Select
            label="Language"
            value={searchParam.language_id}
            onChange={(v) =>
              setSearchParam({
                ...searchParam,
                language_id: v,
              })
            }
            options={languages.map((el) => ({
              label: el.title,
              value: el.id,
            }))}
          />
        </div>
      </div>
      <List
        className="cardList"
        dataSource={listData}
        grid={{ gutter: [20, 20], xs: 1, sm: 2, md: 2, lg: 4, xl: 4, xxl: 4 }}
        renderItem={(item) => (
          <List.Item className="card white-rounded-bg">
            <CardView data={item} />
          </List.Item>
        )}
        loading={{
          spinning: isLoading,
          indicator: <Spin spinning={true} className={`spinner`} />,
        }}
      />
    </Wrapper>
  );
}

const CardView = (props) => {
  return (
    <div className="cardInner">
      <div className="imgTitle">
        <img src="https://www.bensound.com/bensound-img/slowmotion.jpg" />
      </div>
      <div className="titleRoot">{props.data.title}</div>
      <div className="audioRoot">
        <ReactAudioPlayer
          src={`${props.data.file}`}
          controls
          style={{ width: "100%" }}
        />
      </div>
    </div>
  );
};

const Wrapper = styled.div`
  .headerSearch {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    .ant-select {
      width: 100%;
      height: 40px !important;
    }
    label {
      margin-right: 6px;
    }
  }
  .productField {
    width: 280px;
    .ant-select {
      width: 100%;
    }
  }
  .langField {
    width: 180px;
    .ant-select {
      width: 100%;
    }
  }
  .cardList {
    .ant-col {
      height: auto;
      .card {
        height: 100%;
      }
    }
  }

  .titleRoot {
    font-weight: 600;
    font-size: 16px;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0px;
  }
  .audioRoot {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .imgTitle {
    position: relative;
    width: 100%;
    height: 200px;
    overflow: hidden;
    border-radius: 20px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 20px;
    }
  }
  .cardInner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px 5px;
    border-radius: 20px;
    color: white;
    font-weight: 100;
    background: #ffffff;
    overflow: hidden;
  }
`;

import React, { useState } from "react";
import styled from "styled-components";
import TableView from "./table/TableView";
import RankRecogView from "./recog/RankRecogView";

export default function RankReportPage() {
  const [viewMode, setViewMode] = useState("table");
  const [curUser, setCurUser] = useState(undefined);

  return (
    <Wrapper>
      {viewMode === "table" && (
        <TableView setViewMode={setViewMode} setCurUser={setCurUser} />
      )}
      {viewMode === "rank_recog" && curUser && (
        <RankRecogView setViewMode={setViewMode} curUser={curUser} />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-bottom: 80px;
`;

import React, { useState, useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { TablePanel, OutlineBtn, Image } from "components/common";
import { callGetApiWithAuth } from "utils/api";
import { ReactComponent as EditIcon } from "assets/icons/edit-icon.svg";
import { asDate, asDateTime } from "utils/text";
import UpdateCustomersModal from "./UpdateCustomersModal";

export default function TableView() {
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const onGetTableData = (res) => {
    setTableData(res.data);
    setIsLoading(false);
  };

  const onFailTableData = () => {
    setIsLoading(false);
  };

  const loadTableData = () => {
    setIsLoading(true);
    callGetApiWithAuth("event_ticket/list", onGetTableData, onFailTableData);
  };

  const handleSearch = () => {
    loadTableData();
  };

  useEffect(() => {
    loadTableData();
  }, []);

  return (
    <Wrapper>
      <GlobalStyle />
      <TablePanel
        data={tableData}
        loading={isLoading}
        columns={[
          {
            title: "Ticket Number",
            key: "order_num",
            render: (_, record) => (
              <div className="d-flex align-items-center">
                <span>{record.order_num ? `#${record.order_num}` : "-"}</span>
                <span style={{ width: "12px" }} />
                {record.qr_image ? (
                  <Image
                    src={record.qr_image}
                    width={40}
                    preview={{
                      maskClassName: "preview-root",
                    }}
                  />
                ) : (
                  ""
                )}
              </div>
            ),
          },
          {
            title: "Customer Names",
            key: "customer_names",
            render: (_, record) => (
              <div className="d-flex align-items-center">
                <span>{record.customer_names.replaceAll(",", ", ")}</span>
                <span style={{ width: 8 }} />
                <EditBtn data={record} handleSearch={handleSearch} />
              </div>
            ),
          },
          {
            title: "Event",
            key: "ticket_type",
            render: (_, record) => (
              <span>
                {/* <span>{(record?.product?.sku && record.product.sku === "TICKET-6-ONLINE") ? "Online Ticket" : "In-Person Ticket"}</span> */}
                {record.product?.title}
              </span>
            ),
          },
          {
            title: "Create At",
            dataIndex: "created_at",
            key: "created_at",
            render: (text) => <span>{text ? asDate(text) : "-"}</span>,
          },
          {
            title: "Start At",
            key: "start_at",
            render: (_, record) => <span>{asDateTime(record.start_at)}</span>,
          },
        ]}
      />
    </Wrapper>
  );
}

const EditBtn = (props) => {
  const [modalMode, setModalMode] = useState(undefined);

  return (
    <>
      <div className="editIcon" onClick={() => setModalMode("edit")}>
        <EditIcon />
      </div>
      {modalMode === "edit" && (
        <UpdateCustomersModal
          data={props.data}
          handleSearch={props.handleSearch}
          setModalMode={setModalMode}
        />
      )}
    </>
  );
};

const Wrapper = styled.div`
  .qr-image {
    cursor: pointer;
    width: 40px;
  }
  .editIcon {
    cursor: pointer;
    svg {
      path {
        fill: #00b8d9;
      }
    }
  }
`;
const GlobalStyle = createGlobalStyle`
  .ant-image-preview-img {
    background-color: #fff;
  }
`;

import { createReducer } from 'redux-create-reducer'

const initialState = {
  orderFrom: 2, // 2: shop, 4: subscription_resume
  subscriptionIds: [], // use only for subscription_resume, subscription_manual
  orderDetails: [],
  orderError: undefined,
  shippingInfo: undefined,
  isBucketPay: false,
}

export default createReducer(initialState, {
  [`checkout.ADD_CART`](state, action) {
    let orderFrom = 2
    let orderDetails = [...state.orderDetails]
    if (state.orderFrom===4) {
      orderDetails = []
    }

    let isExist = false
    let product = action.payload.product
    let orderError_ = ""

    // ! 11/16 custom filter rule Nov.
    const pSkus = ['9945'];
    const isPsku = pSkus.indexOf(product.sku)>=0;
    let hasPsku = false
    let hasNopsku = false
    orderDetails.forEach(el => {
      if (pSkus.indexOf(el.product.sku)>=0) {
        hasPsku = true
      } else {
        hasNopsku = true
      }
    })
    if (!isPsku && hasPsku) {
      orderError_ = 'Sorry! This product cant be purchased with current products on cart'
      return {
        ...state,
        orderError: orderError_
      }
    }
    if (isPsku && hasNopsku) {
      orderError_ = 'Sorry! This product cant be purchased with current products on cart'
      return {
        ...state,
        orderError: orderError_
      }
    }

    let hasBucket = false;
    orderDetails.forEach(el => {
      if (el.product.type*1===4 && // prepaid_code
        el.product.type_tag=='bucket'
      ) {
        hasBucket = true
      }
    })
    if (product.type*1===4 && 
      product.type_tag==='bucket' && 
      !hasBucket && 
      orderDetails.length>0
    ) {
      orderError_ = `Please clear your carts before your purchase Brand Bucks`
      return {
        ...state,
        orderError: orderError_
      }
    }
    if (
      !(product.type*1===4 && 
      product.type_tag==='bucket') && 
      hasBucket
    ) {
      orderError_ = `You can not purchase Brand Bucks and other products at one time`
      return {
        ...state,
        orderError: orderError_
      }
    }
    
    for (let item of orderDetails) {
      if (item.product.id == product.id && 
        item.pay_cycle*1 === action.payload.pay_cycle*1
      ) {
        isExist = true

        if ((item.quantity*1 +  action.payload.quantity*1) > item.product.max_order_quantity*1) {
          orderError_ = `You can purchase ${item.product.title} at most ${item.product.max_order_quantity} quantity per order.`
          return {
            ...state,
            orderError: orderError_
          }
        }

        item.quantity = item.quantity*1 + action.payload.quantity*1
        item.pay_cycle = action.payload.pay_cycle
        item.contained_products = action.payload.contained_products || []
      }
    }

    if (!isExist) {
      if (action.payload.quantity*1 > product.max_order_quantity*1) {
        orderError_ = `You can purchase ${product.title} at most ${product.max_order_quantity} quantity per order.`
        return {
          ...state,
          orderError: orderError_
        }
      }

      orderDetails.push({
        product,
        quantity: action.payload.quantity,
        pay_cycle: action.payload.pay_cycle,
        contained_products: action.payload.contained_products || []
      })
    }

    return {
      ...state,
      orderFrom,
      orderDetails,
      subscriptionIds: ''
    }
  },
  [`checkout.SET_ORDER_DETAILS`](state, action) {
    let orderDetails = action.payload
    
    return {
      ...state,
      orderDetails
    }
  },
  [`checkout.REMOVE_CART`](state, action) {
    let orderDetails = [...state.orderDetails]

    return {
      ...state,
      orderDetails: orderDetails.filter((el) => (
        el.product.id*1 !== action.payload.product_id*1 || 
        el.pay_cycle*1 !== action.payload.pay_cycle*1)
      )
    }
  },
  [`checkout.CLEAR_CART`](state, action) {
    return {
      ...state,
      orderDetails: [],
      subscriptionIds: ''
    }
  },
  [`checkout.CLEAR_ORDER_ERROR`](state, action) {
    return {
      ...state,
      orderError: undefined
    }
  },
  [`checkout.ADD_RESUME_CART`](state, action) {
    let orderFrom = 4
    const { 
      orderDetail,
      subscriptionIds
    } = action.payload
    let orderDetails = [orderDetail]

    return {
      ...state,
      orderFrom,
      orderDetails,
      subscriptionIds,
      orderError: undefined
    }
  },
  [`checkout.ADD_RESUME_ALL_CART`](state, action) {
    let orderFrom = 4
    const { 
      orderDetails,
      subscriptionIds
    } = action.payload

    return {
      ...state,
      orderFrom,
      orderDetails,
      subscriptionIds,
      orderError: undefined
    }
  },
  [`checkout.ADD_SUBPAY_CART`](state, action) {
    let orderFrom = 5
    const { 
      orderDetail,
      subscriptionIds
    } = action.payload
    let orderDetails = [orderDetail]

    return {
      ...state,
      orderFrom,
      orderDetails,
      subscriptionIds,
      orderError: undefined
    }
  },
  [`checkout.ADD_SUBPAY_ALL_CART`](state, action) {
    let orderFrom = 5
    const { 
      orderDetails,
      subscriptionIds
    } = action.payload

    return {
      ...state,
      orderFrom,
      orderDetails,
      subscriptionIds,
      orderError: undefined
    }
  },
  [`checkout.SET_SHIPPING_INFO`](state, action) {
    const {  shippingInfo } = action.payload

    return {
      ...state,
      shippingInfo
    }
  },
  [`checkout.CLEAR_SHIPPING_INFO`](state, action) {
    return {
      ...state,
      shippingInfo: undefined
    }
  },
  [`checkout.SET_IS_BUCKET_PAY`](state, action) {
    const { isBucketPay } = action.payload;

    return {
      ...state,
      isBucketPay,
    }
  }
})

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import {
  Modal,
  Row,
  Col,
  FormItem,
  Radio,
  Button,
  AutoComplete,
  TextArea,
  Input,
  Popconfirm,
  SuccessNotification,
} from "components/common";
import { varValue, varIs } from "common/var";
import { callGetApiWithAuth, callPostApiWithAuth } from "utils/api";

export default function SendModal(props) {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    receiver_type: varValue("tgiftTransferRequest.receiverType", "existing"),
    receiver_first_name: "",
    receiver_last_name: "",
    receiver_email: "",
    receiver_phone: "",
    receiver_id: "",
    card_amount: 1,
  });
  const [isUpdating, setIsUpdating] = useState(false);
  const [users, setUsers] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);

  const onFormInput = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const onSearchDone = (res) => {
    setUsers(res.data);
  };
  const onSearchUser = (v) => {
    callGetApiWithAuth(`hlt/search_receiver?uuid=${v}`, onSearchDone);
  };

  const onSelectUser = (_, option) => {
    let receiver = null;
    users.forEach((el) => {
      if (el.id == option.key) {
        receiver = el;
      }
    });
    setFormData({
      ...formData,
      receiver_id: receiver.id,
    });
  };

  const onSubmitDone = (res) => {
    setIsUpdating(false);
    SuccessNotification(res.message);
    dispatch({ type: "auth.RELOAD" });
    props.setShouldLoad(true);
    props.setModalMode(undefined);
  };
  const onSubmitFail = () => {
    setIsUpdating(false);
  };
  const handleSubmit = () => {
    // Validation
    const errorMessages0 = [];
    if (isNaN(formData.card_amount) || formData.card_amount <= 0) {
      errorMessages0.push({
        type: "card_amount",
        message: "Please input card amount as number",
      });
    }
    if (
      varIs("tgiftTransferRequest.receiverType", formData.receiver_type, "new")
    ) {
      if (!formData.receiver_first_name) {
        errorMessages0.push({
          type: "receiver_first_name",
          message: "Please input first name",
        });
      }
      if (!formData.receiver_last_name) {
        errorMessages0.push({
          type: "receiver_last_name",
          message: "Please input last name",
        });
      }
      if (!formData.receiver_phone) {
        errorMessages0.push({
          type: "receiver_phone",
          message: "Please input phone",
        });
      }
      if (!formData.receiver_email) {
        errorMessages0.push({
          type: "receiver_email",
          message: "Please input email",
        });
      }
    } else {
      if (!formData.receiver_id) {
        errorMessages0.push({
          type: "receiver_id",
          message: "Please select receiver",
        });
      }
    }

    setErrorMessages(errorMessages0);
    if (errorMessages0.length > 0) return;

    const body = {
      ...formData,
      amount: formData.card_amount * 100,
      receiver_first_name: formData.receiver_first_name.trim(),
      receiver_last_name: formData.receiver_last_name.trim(),
      receiver_email: formData.receiver_email.toLowerCase().trim(),
      receiver_phone: formData.receiver_phone.trim(),
    };
    setIsUpdating(true);
    callPostApiWithAuth(`hlt/transfer`, body, onSubmitDone, onSubmitFail);
  };

  return (
    <Modal onCancel={() => props.setModalMode(undefined)}>
      <Wrapper>
        <div className="modal-new-title">Transfer Card Balance</div>
        <Row gutter={[12, 12]}>
          {/* <Col xs={24}>
          <FormItem label={'Receiver Type'}>
            <Radio.Group value={formData.receiver_type}
              onChange={e=>onFormInput('receiver_type', e.target.value)}
            >
              <Radio value={varValue('tgiftTransferRequest.receiverType', 'new')}>
                New
              </Radio>
              <Radio value={varValue('tgiftTransferRequest.receiverType', 'existing')}>
                Existing
              </Radio>
            </Radio.Group>
          </FormItem>
        </Col> */}
          {varIs(
            "tgiftTransferRequest.receiverType",
            formData.receiver_type,
            "new"
          ) && (
            <Col xs={12}>
              <FormItem
                errorMessages={errorMessages.filter(
                  (el) => el.type == "receiver_first_name"
                )}
              >
                <Input
                  label={"Receiver First Name"}
                  value={formData.receiver_first_name}
                  onChange={(e) =>
                    onFormInput("receiver_first_name", e.target.value)
                  }
                />
              </FormItem>
            </Col>
          )}
          {varIs(
            "tgiftTransferRequest.receiverType",
            formData.receiver_type,
            "new"
          ) && (
            <Col xs={12}>
              <FormItem
                errorMessages={errorMessages.filter(
                  (el) => el.type == "receiver_last_name"
                )}
              >
                <Input
                  label={"Receiver Last Name"}
                  value={formData.receiver_last_name}
                  onChange={(e) =>
                    onFormInput("receiver_last_name", e.target.value)
                  }
                />
              </FormItem>
            </Col>
          )}
          {varIs(
            "tgiftTransferRequest.receiverType",
            formData.receiver_type,
            "new"
          ) && (
            <Col xs={12}>
              <FormItem
                errorMessages={errorMessages.filter(
                  (el) => el.type == "receiver_email"
                )}
              >
                <Input
                  label={"Receiver Email"}
                  value={formData.receiver_email}
                  onChange={(e) =>
                    onFormInput("receiver_email", e.target.value)
                  }
                />
              </FormItem>
            </Col>
          )}
          {varIs(
            "tgiftTransferRequest.receiverType",
            formData.receiver_type,
            "new"
          ) && (
            <Col xs={12}>
              <FormItem
                errorMessages={errorMessages.filter(
                  (el) => el.type == "receiver_phone"
                )}
              >
                <Input
                  label={"Receiver Phone"}
                  value={formData.receiver_phone}
                  onChange={(e) =>
                    onFormInput("receiver_phone", e.target.value)
                  }
                />
              </FormItem>
            </Col>
          )}
          {varIs(
            "tgiftTransferRequest.receiverType",
            formData.receiver_type,
            "existing"
          ) && (
            <Col xs={24}>
              <FormItem
                errorMessages={errorMessages.filter(
                  (el) => el.type == "receiver_id"
                )}
              >
                <AutoComplete
                  label={"Receiver"}
                  onChange={onSearchUser}
                  options={users.map((el) => ({
                    key: el.id,
                    label: `${el.first_name} ${el.last_name}`,
                    value: `${el.first_name} ${el.last_name}`,
                  }))}
                  style={{ width: "100%" }}
                  onSelect={onSelectUser}
                />
              </FormItem>
            </Col>
          )}
          <Col xs={24}>
            <FormItem
              errorMessages={errorMessages.filter(
                (el) => el.type == "card_amount"
              )}
            >
              <Input
                label={"Card Amount(1 Card: $100.00)"}
                type="number"
                value={formData.card_amount}
                onChange={(e) => onFormInput("card_amount", e.target.value)}
              />
            </FormItem>
          </Col>
        </Row>{" "}
        <div className="action-btn">
          <Button
            className="cancel-btn"
            onClick={() => props.setModalMode(undefined)}
          >
            Cancel
          </Button>
          <Popconfirm onConfirm={handleSubmit}>
            <Button loading={isUpdating} className="create-btn">Send</Button>
          </Popconfirm>
        </div>
      </Wrapper>
    </Modal>
  );
}

const Wrapper = styled.div`
  .modal-new-title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #212b36;
    margin-bottom: 20px;
  }
  .rece-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #637381;
    margin: -5px 0px 3px;
  }
  .label {
    display: none;
  }
  .ant-input,
  .ant-select-selector {
    height: 40px !important;
  }
  .ant-form-item-label {
    top: 9px;
  }
  .ant-form-item-label-active {
    transform: translateY(-17px) scale(1) !important;
  }
  .action-btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 18px;
    .cancel-btn {
      border-radius: 8px !important;
      height: 40px;
      padding: 0px 15px;
      margin-left: 12px;
      border: 1px solid rgba(145, 158, 171, 0.32);
      background: white;
      color: black;
    }
    .create-btn {
      background: #1ea9ea;
      border-radius: 8px !important;
      height: 40px;
      padding: 0px 15px;
      margin-left: 12px;
    }
  }
  .ant-select-selection-search-input {
    position: relative;
    top: 3px;
  }
`;

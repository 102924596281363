import { varIs, varIsIn } from "common/var";
import { ReactComponent as HomeIcon } from "assets/icons/new-sidebar/home.svg";
import { ReactComponent as LiveStreamIcon } from "assets/icons/new-sidebar/live-stream.svg";
import { ReactComponent as DashboardIcon } from "assets/icons/new-sidebar/dashboard.svg";
import { ReactComponent as LeaderboardIcon } from "assets/icons/new-sidebar/leaderboard.svg";
import { ReactComponent as RankIcon } from "assets/icons/new-sidebar/rank.svg";
import { ReactComponent as ReportsIcon } from "assets/icons/new-sidebar/reports.svg";
import { ReactComponent as MyWebsiteIcon } from "assets/icons/new-sidebar/my-website.svg";
import { ReactComponent as WalletIcon } from "assets/icons/new-sidebar/wallet.svg";
import { ReactComponent as TeamMessengerChatIcon } from "assets/icons/new-sidebar/team-messenger-chat.svg";
import { ReactComponent as ProspectChatIcon } from "assets/icons/new-sidebar/prospect-chat.svg";
import { ReactComponent as MyTeamIcon } from "assets/icons/new-sidebar/my-team.svg";
import { ReactComponent as MyAccountIcon } from "assets/icons/new-sidebar/my-account.svg";
import { ReactComponent as ToolsIcon } from "assets/icons/new-sidebar/tools.svg";
import { ReactComponent as SupportIcon } from "assets/icons/new-sidebar/support.svg";
import { ReactComponent as ShopIcon } from "assets/icons/new-sidebar/shop.svg";
import { ReactComponent as TrainingIcon } from "assets/icons/new-sidebar/training.svg";
import { ReactComponent as ContactManagerIcon } from "assets/icons/new-sidebar/contact-manager.svg";
import { ReactComponent as AkashxIcon } from "assets/icons/new-sidebar/akashx.svg";
import { ReactComponent as HighLifeTravelIcon } from "assets/icons/new-sidebar/high-life-travel.svg";
import { ReactComponent as IdcardIcon } from "assets/icons/sidebar/id-card.svg";
import { ReactComponent as TaxIcon } from "assets/icons/sidebar/tax-icon.svg";
import { ReactComponent as EventIcon } from "assets/icons/new-sidebar/events.svg";
import { ReactComponent as DigitalIcon } from "assets/icons/new-sidebar/digital.svg";
import { ReactComponent as SetcIcon } from 'assets/icons/new-sidebar/setc.svg';
import TravelIcon from 'assets/icons/new-sidebar/travel.png';
import TravelSelectedIcon from 'assets/icons/new-sidebar/travel_selected.png';

export const getMenus = (myUser, isMobile = false) => {
  let menus = [];
  let menu = null;
  let submenus = [];
  let submenu = null;
  if (
    varIs("user.type", myUser.type, "affiliate") &&
    varIsIn("user.status", myUser.status, [
      "active",
      "inactive",
      "hold_temp",
      "purged"
    ]) &&
    !varIs("user.taxStatus", myUser.tax_status, "requested") &&
    !varIs("user.verificationStatus", myUser.verification_status, "requested")
  ) {
    menu = {
      label: "Home",
      route: "/",
      icon: <HomeIcon />,
    };
    menus.push(menu);
    menu = {
      label: "Runway Tracker",
      route: "/event/runway_trip",
      icon: <img src={TravelIcon} />,
      selectedIcon: <img src={TravelSelectedIcon} />,
    };
    menus.push(menu);
    menu = {
      label: "Training",
      route: "/training",
      icon: <TrainingIcon />,
    };
    menus.push(menu);
    menu = {
      label: "Company News",
      route: "/company_news",
      icon: <LiveStreamIcon />,
    };
    submenus = [];
    submenu = {
      label: "CEO Weekly Call",
      route: "/mdc_live",
    };
    submenus.push(submenu);
    submenu = {
      label: "Notifications",
      route: "/notification",
    };
    submenus.push(submenu);
    submenu = {
      label: "Telegram Channel",
      route: "/telegram_channel",
    };
    submenus.push(submenu);
    submenu = {
      label: "Blog",
      route: "/blog",
    };
    submenus.push(submenu);
    menu['submenus'] = submenus;
    menus.push(menu);
    menu = {
      label: "Events",
      route: "/event_menu",
      icon: <EventIcon />,
    };
    submenus = [];
    submenu = {
      label: "Upcoming Events",
      route: "/event",
    };
    submenus.push(submenu);
    submenu = {
      label: "My Tickets",
      route: "/ticket/my_ticket",
    };
    submenus.push(submenu);
    submenu = {
      label: "Incentive Trip",
      route: "/event/incentive_trip",
    };
    submenus.push(submenu);
    if (myUser.has_event_audio) {
      submenu = {
        label: "Event Audio",
        route: "/ticket/event_audio",
      };
      submenus.push(submenu);
    }
    menu['submenus'] = submenus;
    menus.push(menu);
    menu = {
      label: "My Account",
      route: "/my_account",
      icon: <MyAccountIcon />,
    };
    submenus = [];
    submenu = {
      label: "Payout Options",
      route: "/my_account/payment_option",
    };
    submenus.push(submenu);
    submenu = {
      label: "Account Settings",
      route: "/my_account/setting",
    };
    submenus.push(submenu);
    submenu = {
      label: "Earnings",
      route: "/earning",
    };
    submenus.push(submenu);
    submenu = {
      label: "Subscriptions",
      route: "/my_account/subscription",
    };
    submenus.push(submenu);
    submenu = {
      label: "Order History",
      route: "/my_account/order_history",
    };
    submenus.push(submenu);
    if (varIs("user.status", myUser.status, "hold_temp")) {
      submenu = {
        label: "Verification",
        route: "/my_account/verification",
      };
      submenus.push(submenu);
    }
    menu["submenus"] = submenus;
    menus.push(menu);
    menu = {
      label: "Wallet",
      route: "/walllet",
      icon: <WalletIcon />,
    };
    submenus = [];
    submenu = {
      label: "Credit Wallet",
      route: "/my_account/credit_wallet",
    };
    submenus.push(submenu);
    submenu = {
      label: "Gift Wallet",
      route: "/my_account/gift_wallet",
    };
    submenus.push(submenu);
    submenu = {
      label: "Loyalty Rewards",
      route: "/my_account/loyalty_wallet",
    };
    submenus.push(submenu);
    submenu = {
      label: "Brand Bucks",
      route: "/my_account/brand_buck_wallet",
    };
    submenus.push(submenu);
    menu["submenus"] = submenus;
    menus.push(menu);
    menu = {
      label: "My Team",
      route: "/my_team",
      icon: <MyTeamIcon />,
    };
    submenus = [];
    submenu = {
      label: "Binary List",
      route: "/my_team/binary_list",
    };
    submenus.push(submenu);
    submenu = {
      label: "Binary Tree",
      route: "/my_team/binary_tree",
    };
    submenus.push(submenu);
    submenu = {
      label: "Enroller List",
      route: "/my_team/unilevel_list",
    };
    submenus.push(submenu);
    submenu = {
      label: "Enroller Tree",
      route: "/my_team/unilevel_tree",
    };
    submenus.push(submenu);
    submenu = {
      label: "Holding Tank",
      route: "/my_team/holding_tank",
    };
    submenus.push(submenu);
    submenu = {
      label: "Contact Manager",
      route: "/contact_manager",
    };
    submenus.push(submenu);
    menu["submenus"] = submenus;
    menus.push(menu);
    menu = {
      label: "My Website",
      route: "/my_website",
      icon: <MyWebsiteIcon />,
    };
    submenus = [];
    submenu = {
      label: "Replicated Website",
      route: "/my_website/replicated",
    };
    submenus.push(submenu);
    submenu = {
      label: "Brand Websites",
      route: "/my_website/brand",
    };
    submenus.push(submenu);
    submenu = {
      label: "Capture Pages",
      route: "/my_website/capture",
    };
    submenus.push(submenu);
    // Temporarilly hold
    submenu = {
      label: "Product Funnels",
      route: "/my_website/product",
    };
    submenus.push(submenu);
    submenu = {
      label: "Shareable Carts",
      route: "/my_website/share_cart",
    };
    submenus.push(submenu);
    // ! Stop now
    // submenu = {
    //   'label': 'Traffic Rotator',
    //   'route': '/my_website/rotator',
    // }
    // submenus.push(submenu)
    menu["submenus"] = submenus;
    menus.push(menu);
    menu = {
      label: "Tool Suite",
      route: "/tool_suite",
      icon: <ToolsIcon />,
    };
    submenus = [];
    submenu = {
      label: "Team Messenger Chat",
      route: "/team_chat",
    };
    submenus.push(submenu);
    // ! 1123: ProspectChat
    /**
     * Currently just hide UBO prospect chat only
     * Does it need to remove all prospect chat features 
     * from UBO and CBO?
     */
    // if (varIs("user.isSchatEnabled", myUser.is_schat_enabled, "yes")) {
    //   submenu = {
    //     label: "Prospect Chat",
    //     route: "/support_chat",
    //   };
    //   submenus.push(submenu);
    // }
    // submenu = {
    //   label: "Live Stream",
    //   route: "/live_stream",
    // };
    // submenus.push(submenu);
    submenu = {
      label: "Marketing Assets",
      route: "/tool/marketing_asset",
    };
    submenus.push(submenu);
    // submenu = {
    //   label: "Compliance Corner",
    //   route: "/tool/compliance_corner",
    // };
    // submenus.push(submenu);
    submenu = {
      label: "Leads & Traffic",
      route: "/lead_traffic",
    };
    submenus.push(submenu);
    menu["submenus"] = submenus;
    menus.push(menu);
    menu = {
      label: "Leaderboard",
      route: "/leaderboard",
      icon: <LeaderboardIcon />,
    };
    menus.push(menu);
    menu = {
      label: "Reports",
      route: "/report",
      icon: <ReportsIcon />,
    };
    submenus = [];
    submenu = {
      label: "Rank Snapshot",
      route: "/my_rank/rank_snapshot",
    };
    submenus.push(submenu);
    submenu = {
      label: "Rank Recognition",
      route: "/my_rank/rank_recognition",
    };
    submenus.push(submenu);
    submenu = {
      label: "Rank Report",
      route: "/report/rank_report",
    };
    submenus.push(submenu);
    submenu = {
      label: "New Rank Ups",
      route: "/report/new_rank_up",
    };
    submenus.push(submenu);
    submenu = {
      label: "Commission Calculator",
      route: "/report/commission_calculator",
    };
    submenus.push(submenu);
    submenu = {
      label: "Decline Report",
      route: "/report/decline_report",
    };
    submenus.push(submenu);
    submenu = {
      label: "Binary Qualification Report",
      route: "/report/binary_qual",
    };
    submenus.push(submenu);
    submenu = {
      label: "Customer Mastery",
      route: "/report/customer_mastery",
    };
    submenus.push(submenu);
    // ! ended 10&2 promo, 7,8,9 month
    // submenu = {
    //   'label': 'Convention Promo',
    //   'route': '/report/promo_ticket_report',
    // }
    // submenus.push(submenu)
    submenu = {
      label: "MDC Optimus",
      route: "/report/telegram_report",
    };
    submenus.push(submenu);
    submenu = {
      label: "Ticket Report",
      route: "/report/ticket_report",
    };
    submenus.push(submenu);
    submenu = {
      label: "Abandon Carts",
      route: "/report/abandon_cart",
    };
    submenus.push(submenu);
    submenu = {
      label: "Customer Report",
      route: "/report/customer_report",
    };
    submenus.push(submenu);
    submenu = {
      label: "Affiliate Report",
      route: "/report/affiliate_report",
    };
    submenus.push(submenu);
    // submenu = {
    //   label: "Refunded Orders",
    //   route: "/report/refunded_report",
    // };
    // submenus.push(submenu);
    if (myUser.has_dti) {
      submenu = {
        label: "DTI Get 4 Pay No More",
        route: "/report/dti_4free_report",
      };
      submenus.push(submenu);
      submenu = {
        label: "DTI Points Report",   
        route: "/report/dti_points_report",
      };
      submenus.push(submenu);
      submenu = {
        label: "DTI VIP Promo Report",   
        route: "/report/dti_vip_promo_report",
      };
      submenus.push(submenu);
    }
    menu["submenus"] = submenus;
    menus.push(menu);
    menu = {
      label: "Shop",
      route: "/shop",
      icon: <ShopIcon />,
    };
    menus.push(menu);
    menu = {
      label: "Digital Products",
      route: "/digital_products",
      icon: <DigitalIcon />,
    };
    submenus = [];
    submenu = {
      label: "High Life Travel",
      route: "/hlt",
    };
    submenus.push(submenu);
    if (myUser.has_dti) {
      submenu = {
        label: "DreamTrips",
        route: "/dti",
      };
      submenus.push(submenu);
    }
    if (myUser.has_akashx) {
      submenu = {
        label: "Akashx",
        route: "/akashx",
      };
      submenus.push(submenu);
    }
    // if (myUser.ican_setting && 
    //   varIs('userIcanSetting.status', myUser.ican_setting.status, 'active')
    // ) {
    //   submenu = {
    //     label: "ICAN Institute",
    //     route: "/ican_institue",
    //   };
    //   submenus.push(submenu);
    // }
    // if (myUser.ican2_setting && 
    //   varIs('userIcanSetting.status', myUser.ican2_setting.status, 'active')
    // ) {
    //   submenu = {
    //     label: "ICAN Start",
    //     route: "/ican_start",
    //   };
    //   submenus.push(submenu);
    // }
    // if (myUser.ican3_setting && 
    //   varIs('userIcanSetting.status', myUser.ican3_setting.status, 'active')
    // ) {
    //   submenu = {
    //     label: "ICAN Business Mindset",
    //     route: "/ican_mindset",
    //   };
    //   submenus.push(submenu);
    // }
    menu["submenus"] = submenus;
    menus.push(menu);
    menu = {
      label: "Support",
      route: "/support",
      icon: <SupportIcon />,
    };
    menus.push(menu);
    menu = {
      label: "SETC Tax Credits",
      route: "/setc",
      icon: <SetcIcon />,
    };
    submenus = [];
    submenu = {
      label: 'How It Works',
      route: '/setc/about'
    }
    submenus.push(submenu);
    submenu = {
      label: 'Apply',
      route: '/setc/apply'
    }
    submenus.push(submenu);
    submenu = {
      label: 'Share',
      route: '/setc/share'
    }
    submenus.push(submenu);
    submenu = {
      label: 'Leaderboard',
      route: '/setc/leaderboard'
    }
    submenus.push(submenu);
    menu["submenus"] = submenus;
    menus.push(menu);
  }
  if (
    varIs("user.type", myUser.type, "affiliate") &&
    varIsIn("user.status", myUser.status, [
      "active",
      "inactive",
      "hold_temp",
    ]) &&
    varIs("user.taxStatus", myUser.tax_status, "requested")
  ) {
    menu = {
      label: "Tax Form",
      route: "/tax_form",
      icon: <TaxIcon />,
    };
    menus.push(menu);
  }
  if (
    varIs("user.type", myUser.type, "affiliate") &&
    varIsIn("user.status", myUser.status, [
      "active",
      "inactive",
      "hold_temp",
    ]) &&
    varIs("user.verificationStatus", myUser.verification_status, "requested")
  ) {
    menu = {
      label: "Verification",
      route: "/verification",
      icon: <IdcardIcon />,
    };
    menus.push(menu);
  }

  return menus;
};

export const hasPageAccess = (pathname, myUser, sudoName) => {
  const menus = getMenus(myUser);
  let isAble = false;
  menus.forEach((el) => {
    if (el.route === pathname) {
      isAble = true;
    }
    if (el.submenus && el.submenus.length > 0) {
      el.submenus.forEach((el2) => {
        if (el2.route === pathname) {
          isAble = true;
        }
      });
    }
  });
  // --- external urls
  if (
    varIs("user.type", myUser.type, "affiliate") &&
    varIsIn("user.status", myUser.status, [
      "active",
      "inactive",
      "hold_temp",
    ]) &&
    !varIs("user.taxStatus", myUser.tax_status, "requested") &&
    !varIs(
      "user.verificationStatus",
      myUser.verification_status,
      "requested"
    ) &&
    [
      "/my_account/order_receipt",
      "/publisher",
      "/notification",
      "/checkout",
      "/viewer",
      "/report/commission_calculator",
      "/hlt_access",
      '/my_account/savvi_buy',
    ].indexOf(pathname) >= 0
  ) {
    isAble = true;
  }
  if (pathname.includes("/training/watchList/")) {
    isAble = true;
  }

  return isAble;
};

export const getPageTitle = (pathname, myUser) => {
  let pageTitle = null;
  const menus = getMenus(myUser);
  menus.forEach((menu0) => {
    if (menu0.route === pathname) {
      pageTitle = menu0.label;
    }
    if (menu0.submenus) {
      menu0.submenus.forEach((submenu0) => {
        if (submenu0.route === pathname) {
          pageTitle = submenu0.label;
        }
      });
    }
  });
  if (pathname === "/publisher") {
    pageTitle = "Live Stream";
  }

  return pageTitle;
};

import React from "react";
import { Area } from "@ant-design/plots";
import { asNumber } from "utils/text";
import styled from "styled-components";

export default function AreaChart(props) {
  const config = {
    data: props.data,
    xField: props.xField,
    yField: props.yField,
    smooth: true,
    yAxis: {
      label: {
        formatter: (v) => `${asNumber(v)}`,
      },
    },
    xAxis: {
      label: {
        formatter: (v) => v,
      },
    },
    xAxis: {
      range: [0, 1],
      tickCount: 5,
    },
    tooltip: {
      formatter: (datum) => {
        return {
          name: props.tooltipText,
          value: asNumber(datum[props.yField]),
        };
      },
    },
    color: props.line,
    line: {
      color: props.line,
    },
    areaStyle: () => {
      return {
        fill: props.fill,
      };
    },
  };
  return (
    <Wrapper>
      <Area {...config} />
    </Wrapper>
  );
}
export const Wrapper = styled.div`
  .g2-html-annotation {
    color: #212529;
    font-size: 14px !important;
  }
`;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Select, Alert, SuccessNotification } from "components/common";
import { varOptions } from "common/var";
import { callPutApiWithAuth } from "utils/api";
import { Wrapper } from "./EnrollSettingSubPage.styled";

export default function EnrollSettingSubPage() {
  const dispatch = useDispatch();
  const myUser = useSelector((state) => state.auth.user);
  const [powerLeg, setPowerLeg] = useState(4);

  const onUpdatedPowerLeg = (res) => {
    SuccessNotification(res.message);
    dispatch({ type: "auth.RELOAD" });
  };
  const onChangePowerLeg = (v) => {
    setPowerLeg(v);
    callPutApiWithAuth(
      `my_account/setting/power_leg`,
      { power_leg: v },
      onUpdatedPowerLeg
    );
  };

  useEffect(() => {
    setPowerLeg(myUser.power_leg);
  }, [myUser]);

  return (
    <Wrapper>
      <div className="enrollment-settings-description">
        {" "}
        <div className="alert-container-section">
          <Alert
            type="info"
            message="Your Enrollment Settings Put YOU In Control!"
            description={
              <span
                dangerouslySetInnerHTML={{
                  __html: `
            <p><strong>By setting your enrollment settings to the LEFT / RIGHT LEG</strong>, all of your personally enrolled users will be placed at the bottom of your LEFT leg. Similarly, if set your enrollment settings to the RIGHT leg, all of your personally enrolled users will be placed at the bottom of your RIGHT leg.</p>
            <p><strong>By setting your enrollment settings to LESSER LEG</strong>, all of your personally enrolled users will be placed on the leg that has the least volume.</p>
            <p><strong>By setting your enrollment settings to HOLDING TANK</strong>, your personally enrolled users will go into your Holding Tank page where you can manually place them wherever you’d like in the binary. You have 7 days to place your users before they auto-flush to your lesser leg. All users in the holding tank will auto-flush the last day of the month by 10PM PST.</p>`,
                }}
              />
            }
            showIcon
          />
        </div>
      </div>
      <div className="enorllment-container">
        <div className="select-container">
          <div className="label">Enrollment Settings</div>
          <div className="select-box">
            <Select
              value={powerLeg}
              options={varOptions("user.powerLeg")}
              onChange={onChangePowerLeg}
              disabled={!myUser.parent_id && myUser.id * 1 !== 1}
            />
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

import styled from "styled-components";

export const Wrapper = styled.div`
  padding-bottom: 80px;
  .main-container {
    margin: -24px -40px 24px;
    background: rgba(145, 158, 171, 0.08);
    padding: 24px 40px 0px;
    @media (max-width: 900px) {
      margin: -24px -20px 24px;
      padding: 24px 20px 0px;
    }
    .subscriptions-tabs {
      .ant-tabs-nav {
        margin-bottom: 0px;
        margin-top: 0px;
      }
      .ant-tabs-tab-active {
        padding: 12px;
        background: white;
        border-radius: 8px 8px 0px 0px;
      }
      .active-tab {
        font-weight: 600 !important;
        font-size: 15px !important;
        line-height: 24px !important;
        color: #1ea9ea !important;
      }
      .inactive-tab {
        font-weight: 600 !important;
        font-size: 15px !important;
        line-height: 24px !important;
        color: #637381 !important;
      }
      .ant-tabs-ink-bar {
        background: transparent !important;
      }
    }
  }

  @media (max-width: 1620px) {
    .responsive-payment-cards {
      flex: 0 0 33.33333333% !important;
      max-width: 33.33333333% !important;
    }
  }

  @media (max-width: 1300px) {
    .responsive-payment-cards {
      flex: 0 0 50% !important;
      max-width: 50% !important;
    }
  }

  @media (max-width: 960px) {
    .responsive-payment-cards {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
  }
  @media (max-width: 900px) {
    .responsive-payment-cards {
      flex: 0 0 50% !important;
      max-width: 50% !important;
    }
  }
  @media (max-width: 690px) {
    .responsive-payment-cards {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
  }
`;

import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100%;

  .main-container {
    height: 100%;
    padding-bottom: 80px;
  }
  .settings-tabs {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 16px 20px;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #637381;
    svg {
      width: 20px;
      margin-right: 8px;
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .setting-active {
    color: #1ea9ea;
    border-right: 2px solid #1ea9ea;
    svg {
      path {
        fill: #1ea9ea;
      }
    }
  }
  .table-row {
    height: 100%;
  }
  .acc-settings-tab {
    box-shadow: 0px 0px 2px rgb(145 158 171 / 20%),
      0px 12px 24px -4px rgb(145 158 171 / 12%);
    border-radius: 16px;
    padding: 8px 0px;
  }

  @media (max-width: 1200px) {
    .acc-settings-tab {
      height: 100%;
    }
  }
`;

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Select,
  TablePanel,
  Badge,
  Button,
  Popconfirm,
  SuccessNotification,
  Tooltip,
} from "components/common";
import { varKey, varLabel, varIs } from "common/var";
import { callGetApiWithAuth, callDeleteApiWithAuth } from "utils/api";
import { asDate, asUsdPrice } from "utils/text";
import styled from "styled-components";

export default function HistorySection(props) {
  const [tableMode, setTableMode] = useState("sent");
  const [tableData, setTableData] = useState([]);
  const [paginationParam, setPaginationParam] = useState({
    currentPage: 1,
    perPage: 5,
    total: 0,
  });
  const [columns, setColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const onGetTableData = (res) => {
    setTableData(res.data.data);

    setPaginationParam({
      ...paginationParam,
      currentPage: res.data.current_page,
      total: res.data.total,
    });
    setIsLoading(false);
    props.setShouldLoad(false);
  };
  const onFailTableData = () => {
    setIsLoading(false);
    props.setShouldLoad(false);
  };
  const loadTableData = (paginationParam0) => {
    setIsLoading(true);
    const params = {
      page: paginationParam0.currentPage,
      per_page: paginationParam0.perPage,
    };
    const q = Object.keys(params)
      .map((k) => k + "=" + params[k])
      .join("&");
    if (tableMode === "purchase") {
      callGetApiWithAuth(
        `my_account/gift_wallet/purchase_histories?${q}`,
        onGetTableData,
        onFailTableData
      );
    } else if (tableMode === "sent") {
      callGetApiWithAuth(
        `my_account/gift_wallet/sent_histories?${q}`,
        onGetTableData,
        onFailTableData
      );
    } else if (tableMode === "received") {
      callGetApiWithAuth(
        `my_account/gift_wallet/received_histories?${q}`,
        onGetTableData,
        onFailTableData
      );
    } else if (tableMode === "consume") {
      callGetApiWithAuth(
        `my_account/gift_wallet/consume_histories?${q}`,
        onGetTableData,
        onFailTableData
      );
    }
  };

  const onPageChange = (page) => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: page,
    };
    loadTableData(paginationParam0);
  };

  const handleSearch = () => {
    loadTableData(paginationParam);
  };

  useEffect(() => {
    if (tableMode === "purchase") {
      setColumns([
        {
          title: "Date",
          key: "created_at",
          render: (_, record) => <span>{asDate(record.created_at)}</span>,
        },
        {
          title: "Product",
          key: "product",
          render: (_, record) => <span>{record.product.title}</span>,
        },
        {
          title: "Amount",
          key: "amount",
          render: (_, record) => <span>{asUsdPrice(record.amount)}</span>,
        },
        {
          title: "Description",
          key: "comment",
          render: (_, record) => (
            <Tooltip title={record.comment}>
              <span className="tooltip-description">{record.comment}</span>
            </Tooltip>
          ),
        },
      ]);
    } else if (tableMode === "sent") {
      setColumns([
        {
          title: "Date",
          key: "date",
          render: (_, record) => <span>{asDate(record.created_at)}</span>,
        },
        {
          title: "Amount",
          key: "amount",
          render: (_, record) => <span>{asUsdPrice(record.amount)}</span>,
        },
        {
          title: "Receiver",
          key: "receiver",
          render: (_, record) => (
            <span>
              {record.receiver
                ? `${record.receiver.first_name} ${record.receiver.last_name}`
                : `${record.receiver_first_name} ${record.receiver_last_name}`}
            </span>
          ),
        },
        {
          title: "Status",
          key: "status",
          render: (_, record) => (
            <span>
              <Badge
                type={"giftTransferHistoryStatus"}
                keyName={varKey("giftTransferHistory.status", record.status)}
                label={varLabel("giftTransferHistory.status", record.status)}
              />
            </span>
          ),
        },
        {
          title: "Description",
          key: "s_comment",
          render: (_, record) => (
            <Tooltip title={record.s_comment}>
              <span className="tooltip-description">{record.s_comment}</span>
            </Tooltip>
          ),
        },
        {
          title: "Action",
          key: "action",
          render: (_, record) => (
            <div className="d-flex align-items-center">
              {varIs(
                "giftTransferHistory.status",
                record.status,
                "pending"
              ) && <CancelBtn data={record} handleSearch={handleSearch} />}
            </div>
          ),
        },
      ]);
    } else if (tableMode === "received") {
      setColumns([
        {
          title: "Date",
          key: "date",
          render: (_, record) => <span>{asDate(record.created_at)}</span>,
        },
        {
          title: "Amount",
          key: "amount",
          render: (_, record) => <span>{asUsdPrice(record.amount)}</span>,
        },
        {
          title: "Sender",
          key: "sender",
          render: (_, record) => (
            <span>
              {`${record.sender.first_name} ${record.sender.last_name}`}
            </span>
          ),
        },
        {
          title: "Status",
          key: "status",
          render: (_, record) => (
            <span>
              <Badge
                type={"giftTransferHistoryStatus"}
                keyName={varKey("giftTransferHistory.status", record.status)}
                label={varLabel("giftTransferHistory.status", record.status)}
              />
            </span>
          ),
        },
        {
          title: "Description",
          key: "r_comment",
          render: (_, record) => (
            <Tooltip title={record.r_comment}>
              <span className="tooltip-description">{record.r_comment}</span>
            </Tooltip>
          ),
        },
      ]);
    } else if (tableMode === "consume") {
      setColumns([
        {
          title: "Date",
          key: "date",
          render: (_, record) => <span>{asDate(record.created_at)}</span>,
        },
        {
          title: "Type",
          key: "type",
          render: (_, record) => (
            <span>{varLabel("giftConsumeHistory.type", record.type)}</span>
          ),
        },
        {
          title: "Amount",
          key: "amount",
          render: (_, record) => <span>{asUsdPrice(record.amount)}</span>,
        },
        {
          title: "Comment",
          key: "comment",
          render: (_, record) => (
            <Tooltip title={record.comment}>
              <span className="tooltip-description">{record.comment}</span>
            </Tooltip>
          ),
        },
      ]);
    }

    setTableData([]);
    const paginationParam0 = {
      ...paginationParam,
      currentPage: 1,
    };
    loadTableData(paginationParam0);
  }, [tableMode]);

  useEffect(() => {
    if (props.shouldLoad) {
      loadTableData(paginationParam);
    }
  }, [props.shouldLoad]);

  return (
    <Wrapper>
      <TablePanel
        title={"Histories"}
        toolbar={
          <Select
            value={tableMode}
            onChange={(v) => setTableMode(v)}
            options={[
              { label: "Gift Card Purchases", value: "purchase" },
              { label: "Gift Cards Sent", value: "sent" },
              { label: "Gift Cards Received", value: "received" },
              { label: "Product Purchases", value: "consume" },
            ]}
            style={{ width: "180px" }}
          />
        }
        columns={columns}
        data={tableData}
        paginationParam={paginationParam}
        onPageChange={onPageChange}
        loading={isLoading}
      />
    </Wrapper>
  );
}

const CancelBtn = (props) => {
  const dispatch = useDispatch();
  const [isUpdating, setIsUpdating] = useState(false);

  const onCancelDone = (res) => {
    SuccessNotification(res.message);
    setIsUpdating(false);
    dispatch({ type: "auth.RELOAD" });
    props.handleSearch();
  };
  const onCancelFail = () => {
    setIsUpdating(false);
  };
  const handleCancel = () => {
    callDeleteApiWithAuth(
      `my_account/gift_wallet/${props.data.id}/cancel_transfer`,
      onCancelDone,
      onCancelFail
    );
  };

  return (
    <Popconfirm onConfirm={handleCancel}>
      <Button loading={isUpdating}>Cancel</Button>
    </Popconfirm>
  );
};

const Wrapper = styled.div`
  .panelHead {
    box-shadow: 0px 0px 2px rgb(145 158 171 / 20%),
      0px 12px 24px -4px rgb(145 158 171 / 12%);
    border-radius: 16px 16px 0px 0px;
    padding: 24px;
  }
  .table-new-wrapper tr th:first-child {
    border-radius: 0px !important;
  }
  .table-new-wrapper tr th:last-child {
    border-radius: 0px !important;
  }
  .ant-select-selector {
    height: 40px !important;
    .ant-select-selection-item {
      top: -2px;
    }
  }
  .panelRoot {
    margin-bottom: 0px;
  }
  .tooltip-description {
    max-width: 400px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
`;

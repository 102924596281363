import React, { useState, useEffect } from "react"
import { Modal, Input, notification, Popconfirm, Select, Spin, Button } from "components/common"
import { EditOutlined, LoadingOutlined } from "@ant-design/icons"
import { callGetApiWithAuth, callPostApiWithAuth, callPutApiWithAuth } from "utils/api"
import { countryStates, statesByCountry } from "common/country"
import styled from "styled-components"

export default function CheckoutModal(props) {
  const [creditBillingOptions, setCreditBillingOptions] = useState([])
  const [isEditable, setIsEditable] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [creditFormErrors, setCreditFormErrors] = useState({
    billing_country_error: "",
    billing_state_error: "",
    billing_city_error: "",
    billing_address_error: "",
    billing_zipcode_error: "",
    cc_name_error: "",
    cc_type_error: "",
    cc_number_error: "",
    cc_cvv_error: "",
    cc_expiry_month_error: "",
    cc_expiry_year_error: ""
  })

  var d = new Date()
  var currentYear = d.getFullYear().toString().substr(-2) * 1
  var years = []
  for (let i = currentYear; i <= currentYear + 20; i++) {
    years.push(i)
  }

  const onChangeCreditForm = (field, value) => {
    let creditFormData_ = { ...props.creditFormData, [field]: value }
    props.setCreditFormData(creditFormData_)
  }
  
  const checkValid = () => {
    let creditFormErrors_ = {
      billing_country_error: "",
      billing_state_error: "",
      billing_city_error: "",
      billing_address_error: "",
      billing_zipcode_error: "",
      cc_name_error: "",
      cc_type_error: "",
      cc_number_error: "",
      cc_cvv_error: "",
      cc_expiry_month_error: "",
      cc_expiry_year_error: ""
    }

    let regex = /^[0-9]{3,4}$/i
    
    if (!props.creditFormData.cc_name.trim()) {
      creditFormErrors_["cc_name_error"] = "Required"
    }
    if (!props.creditFormData.cc_type) {
      creditFormErrors_["cc_type_error"] = "Required"
    }
    if (!props.creditFormData.cc_number.trim()) {
      creditFormErrors_["cc_number_error"] = "Required"
    }
    if (!props.creditFormData.cc_cvv.trim()) {
      creditFormErrors_["cc_cvv_error"] = "Required"
    } else if (regex.exec(props.creditFormData.cc_cvv) == null) {
      creditFormErrors_["cc_cvv_error"] = "CVV Number should be 3 or 4 digit numbers"
    }
    if (!props.creditFormData.cc_expiry_month) {
      creditFormErrors_["cc_expiry_month_error"] = "Required"
    }
    if (!props.creditFormData.cc_expiry_year) {
      creditFormErrors_["cc_expiry_year_error"] = "Required"
    }
    if (!props.creditFormData.billing_country) {
      creditFormErrors_["billing_country_error"] = "Required"
    }
    if (!props.creditFormData.billing_state && 
      statesByCountry(props.creditFormData.billing_country).length>0
    ) {
      creditFormErrors_["billing_state_error"] = "Required"
    }
    if (!props.creditFormData.billing_city.trim()) {
      creditFormErrors_["billing_city_error"] = "Required"
    } else if (props.creditFormData.billing_city.length > 190) {
      creditFormErrors_["billing_city_error"] = "Billing City length too long"
    }
    if (!props.creditFormData.billing_address.trim()) {
      creditFormErrors_["billing_address_error"] = "Required"
    } else if (props.creditFormData.billing_address.length > 190) {
      creditFormErrors_["billing_address_error"] = "Billing address length too long"
    }
    if (!props.creditFormData.billing_zipcode.trim()) {
      creditFormErrors_["billing_zipcode_error"] = "Required"
    }
    
    if (
      creditFormErrors_.billing_country_error ||
      creditFormErrors_.billing_state_error ||
      creditFormErrors_.billing_city_error ||
      creditFormErrors_.billing_address_error ||
      creditFormErrors_.billing_zipcode_error ||
      creditFormErrors_.cc_name_error ||
      creditFormErrors_.cc_type_error ||
      creditFormErrors_.cc_number_error ||
      creditFormErrors_.cc_cvv_error ||
      creditFormErrors_.cc_expiry_month_error ||
      creditFormErrors_.cc_expiry_year_error
    ) {
      setCreditFormErrors(creditFormErrors_)
      return false
    }

    return true
  }

  const onSuccessUpdated = () => {
    setIsUpdating(false)
    setIsUpdated(true)
    notification.success({
      message: "Success",
      description: "Billing info has been updated."
    })
    setCreditFormErrors({
      billing_country_error: "",
      billing_state_error: "",
      billing_city_error: "",
      billing_address_error: "",
      billing_zipcode_error: "",
      cc_name_error: "",
      cc_type_error: "",
      cc_number_error: "",
      cc_cvv_error: "",
      cc_expiry_month_error: "",
      cc_expiry_year_error: ""
    })
    loadBillingInfos()
  }

  const onFailedUpdate = (err) => {
    setIsUpdating(false)
    notification.error({
      message: "Failed",
      description: err
    })
  }

  const handleUpdate = () => {
    if (!checkValid()) return

    setIsUpdating(true)
    if (props.creditFormData.id) {
      callPutApiWithAuth(
        `my_account/setting/cc_billing_detail/${props.creditFormData.id}`,
        {
          ...props.creditFormData,
          cc_exp_date: `${props.creditFormData.cc_expiry_month}/${props.creditFormData.cc_expiry_year}`
        },
        onSuccessUpdated,
        onFailedUpdate
      )
    } else {
      callPostApiWithAuth(
        `my_account/setting/cc_billing_detail`,
        {
          ...props.creditFormData,
          cc_exp_date: `${props.creditFormData.cc_expiry_month}/${props.creditFormData.cc_expiry_year}`
        },
        onSuccessUpdated,
        onFailedUpdate
      )
    }
  }

  const handleClickEditIcon = () => {
    setIsEditable(!isEditable)
    setCreditFormErrors({
      billing_country_error: "",
      billing_state_error: "",
      billing_city_error: "",
      billing_address_error: "",
      billing_zipcode_error: "",
      cc_name_error: "",
      cc_type_error: "",
      cc_number_error: "",
      cc_cvv_error: "",
      cc_expiry_month_error: "",
      cc_expiry_year_error: ""
    })
  }
  
  const onGetSuccessBillingInfos = ({ data }) => {
    if (data && data[1] && data[1].length === 2) {
      setCreditBillingOptions(data[1].map((el) => ({
        ...el,
        value: el.id,
        label: el.name
      })))
    } else if (data && data[1] && data[1].length === 1) {
      setCreditBillingOptions([
        ...data[1].map((el) => ({
          ...el,
          value: el.id,
          label: el.name
        })),
        {
          value: "",
          key: "new",
          label: "New Billing Info",
          id: "",
          billing_address: '', 
          billing_address_line2: '',
          billing_city: '', 
          billing_state: '',
          billing_zipcode: '', 
          billing_country: '',
          cc_name: '', 
          cc_type: '',
          cc_number: '', 
          cc_cvv: '',
          cc_expiry_month: '', 
          cc_expiry_year: ''
        }
      ])
    } else if (!data || (data && !data[1])) {
      setCreditBillingOptions([
        {
          value: "",
          key: "new",
          label: "New Billing Info",
          id: "",
          billing_address: '', 
          billing_address_line2: '',
          billing_city: '', 
          billing_state: '',
          billing_zipcode: '', 
          billing_country: '',
          cc_name: '', 
          cc_type: '',
          cc_number: '', 
          cc_cvv: '',
          cc_expiry_month: '', 
          cc_expiry_year: ''
        }
      ])
    }
  }

  const onGetFailedBillingInfos = () => {
    setCreditBillingOptions([
      {
        value: "",
        key: "new",
        label: "New Billing Info",
        id: "",
        billing_address: '', 
        billing_address_line2: '',
        billing_city: '', 
        billing_state: '',
        billing_zipcode: '', 
        billing_country: '',
        cc_name: '', 
        cc_type: '',
        cc_number: '', 
        cc_cvv: '',
        cc_expiry_month: '', 
        cc_expiry_year: ''
      }
    ])
  }

  const loadBillingInfos = () => {
    callGetApiWithAuth("shop/get_billing_details", onGetSuccessBillingInfos, onGetFailedBillingInfos)
  }

  useEffect(() => {
    if (
      creditBillingOptions.length > 0 && 
      creditBillingOptions.filter((el) => el.is_primary*1 === 1).length > 0
    ) {
      let creditFormData_ = creditBillingOptions.filter((el) => el.is_primary*1 === 1)[0]
      props.setCreditFormData({
        ...creditFormData_,
        cc_number: props.creditFormData.cc_type === 4 ? `**** ****** *****`:`**** **** **** ****`, 
        cc_cvv: props.creditFormData.cc_type === 4 ? '****' : '***',
        cc_expiry_month: creditFormData_.cc_exp_date.split("/")[0],
        cc_expiry_year: creditFormData_.cc_exp_date.split("/")[1]
      })
    } else {
      props.setCreditFormData({
        id: '',
        billing_address: '', 
        billing_address_line2: '',
        billing_city: '', 
        billing_state: '',
        billing_zipcode: '', 
        billing_country: '',
        cc_name: '', 
        cc_type: '',
        cc_number: '', 
        cc_cvv: '',
        cc_expiry_month: '', 
        cc_expiry_year: '',
        pay_type: 1
      })
    }
    setIsEditable(false)
  }, [creditBillingOptions])

  useEffect(() => {
    if (
      creditBillingOptions.length > 0 && 
      creditBillingOptions.filter((el) => el.value*1 === props.creditFormData.id*1)[0] && 
      creditBillingOptions.filter((el) => el.value*1 === props.creditFormData.id*1)[0].id
    ) {
      let creditFormData_ = creditBillingOptions.filter((el) => el.value*1 === props.creditFormData.id*1)[0]
      props.setCreditFormData({
        ...creditFormData_,
        cc_number: props.creditFormData.cc_type === 4 ? `**** ****** *****`:`**** **** **** ****`, 
        cc_cvv: props.creditFormData.cc_type === 4 ? '****' : '***',
        cc_expiry_month: creditFormData_.cc_exp_date.split("/")[0],
        cc_expiry_year: creditFormData_.cc_exp_date.split("/")[1]
      })
    } else {
      props.setCreditFormData({
        id: "",
        billing_address: '', 
        billing_address_line2: '',
        billing_city: '', 
        billing_state: '',
        billing_zipcode: '', 
        billing_country: '',
        cc_name: '', 
        cc_type: '',
        cc_number: '', 
        cc_cvv: '',
        cc_expiry_month: '', 
        cc_expiry_year: ''
      })
    }
    setIsEditable(false)
  }, [props.creditFormData.id])

  useEffect(() => {
    if (isEditable) {
      setIsUpdated(false)
      props.setCreditFormData({
        ...props.creditFormData,
        cc_number: '', 
        cc_cvv: '',
      })
    } else {
      setIsUpdated(true)
      if (props.creditFormData.id) {
        props.setCreditFormData({
          ...props.creditFormData,
          cc_number: props.creditFormData.cc_type === 4 ? `**** ****** *****`:`**** **** **** ****`, 
          cc_cvv: props.creditFormData.cc_type === 4 ? '****' : '***',
        })
      }
    }
  }, [isEditable])

  useEffect(() => {
    loadBillingInfos()
  }, [props.myUser])

  return (
    <Modal width={600}
      onCancel={props.handleCloseCheckoutModal}
    >
      <Wrapper>
        <div className="modal-title-container">Checkout Ticket</div>
        <div className="billing-form">
          <div className='row'>
            <div className='col-xs-12 col-sm-6'>
              <label>Select Your Billing Info*</label><br/>
              <Select 
                value={props.creditFormData.id}
                onChange={(value)=>props.setCreditFormData({ ...props.creditFormData, id: value })}
                size='large'
                options={creditBillingOptions}
              />
            </div>
            {props.creditFormData.id &&
              <div className='col-sm-6 edit mt-3'>
                {isEditable ?
                  <Button
                    type="primary"
                    className='btn btn-primary'
                    onClick={handleClickEditIcon}
                  >
                    Cancel
                  </Button>
                  :
                  <Button
                    type="primary"
                    className='btn btn-primary'
                    onClick={handleClickEditIcon}
                    icon={<EditOutlined />}
                  >
                    Edit
                  </Button>
                }
                <br/>
              </div>
            }
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6 mt-3">
              <label>Name on Card*</label>
              <br />
              <Input
                type="text"
                value={props.creditFormData.cc_name}
                onChange={(e) => onChangeCreditForm("cc_name", e.target.value)}
                disabled={props.creditFormData.id && !isEditable}
              />
              {creditFormErrors.cc_name_error && (
                <label className="input-error">{creditFormErrors.cc_name_error}</label>
              )}
            </div>
            <div className="col-xs-12 col-sm-6 mt-3">
              <label>Card Type*</label>
              <br />
              <Select
                value={props.creditFormData.cc_type}
                onChange={(value) => onChangeCreditForm("cc_type", value)}
                size="large"
                disabled={props.creditFormData.id && !isEditable}
              >
                <option value=""></option>
                <option value={1}>Visa</option>
                <option value={2}>Mastercard</option>
                <option value={3}>Discover</option>
                <option value={4}>Amex</option>
              </Select>
              {creditFormErrors.cc_type_error && (
                <label className="input-error">{creditFormErrors.cc_type_error}</label>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-9 mt-3">
              <label>Credit Card Number*</label>
              <br />
              <Input
                type="text"
                value={props.creditFormData.cc_number}
                onChange={(e) => onChangeCreditForm("cc_number", e.target.value)}
                disabled={props.creditFormData.id && !isEditable}
              />
              {creditFormErrors.cc_number_error && (
                <label className="input-error">{creditFormErrors.cc_number_error}</label>
              )}
            </div>
            <div className="col-sm-3 mt-3">
              <label>CVV*</label>
              <br />
              <Input
                type="text"
                value={props.creditFormData.cc_cvv}
                onChange={(e) => onChangeCreditForm("cc_cvv", e.target.value)}
                disabled={props.creditFormData.id && !isEditable}
              />
              {creditFormErrors.cc_cvv_error && (
                <label className="input-error">{creditFormErrors.cc_cvv_error}</label>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6 mt-3">
              <label>Exp Month*</label>
              <br />
              <Select
                value={props.creditFormData.cc_expiry_month}
                onChange={(value) => onChangeCreditForm("cc_expiry_month", value)}
                placeholder="Month"
                size="large"
                showSearch
                disabled={props.creditFormData.id && !isEditable}
              >
                <option value=""></option>
                {[...Array(12).keys()].map((i) => (
                  <option value={i < 9 ? "0" + (i + 1) : i + 1} key={i}>
                    {i + 1}
                  </option>
                ))}
              </Select>
              {creditFormErrors.cc_expiry_month_error && (
                <label className="input-error">
                  {creditFormErrors.cc_expiry_month_error}
                </label>
              )}
            </div>
            <div className="col-xs-12 col-sm-6 mt-3">
              <label>Exp Year*</label>
              <br />
              <Select
                value={props.creditFormData.cc_expiry_year}
                onChange={(value) => onChangeCreditForm("cc_expiry_year", value)}
                placeholder="Year"
                size="large"
                showSearch
                disabled={props.creditFormData.id && !isEditable}
              >
                <option value=""></option>
                {years.map((y) => (
                  <option value={y} key={y}>
                    20{y}
                  </option>
                ))}
              </Select>
              {creditFormErrors.cc_expiry_year_error && (
                <label className="input-error">{creditFormErrors.cc_expiry_year_error}</label>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6 mt-3">
              <label>Address Line 1*</label>
              <br />
              <Input
                type="text"
                value={props.creditFormData.billing_address}
                onChange={(e) => onChangeCreditForm("billing_address", e.target.value)}
                disabled={props.creditFormData.id && !isEditable}
              />
              {creditFormErrors.billing_address_error && (
                <label className="input-error">
                  {creditFormErrors.billing_address_error}
                </label>
              )}
            </div>
            <div className="col-xs-12 col-sm-6 mt-3">
              <label>Address Line 2</label>
              <br />
              <Input
                type="text"
                value={props.creditFormData.billing_address_line2}
                onChange={(e) => onChangeCreditForm("billing_address_line2", e.target.value)}
                disabled={props.creditFormData.id && !isEditable}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6 mt-3">
              <label>City*</label>
              <br />
              <Input
                type="text"
                value={props.creditFormData.billing_city}
                onChange={(e) => onChangeCreditForm("billing_city", e.target.value)}
                disabled={props.creditFormData.id && !isEditable}
              />
              {creditFormErrors.billing_city_error && (
                <label className="input-error">
                  {creditFormErrors.billing_city_error}
                </label>
              )}
            </div>
            <div className="col-xs-12 col-sm-6 mt-3">
              <label>Zip/Postal code*</label>
              <br />
              <Input
                type="text"
                value={props.creditFormData.billing_zipcode}
                onChange={(e) => onChangeCreditForm("billing_zipcode", e.target.value)}
                disabled={props.creditFormData.id && !isEditable}
              />
              {creditFormErrors.billing_zipcode_error && (
                <label className="input-error">
                  {creditFormErrors.billing_zipcode_error}
                </label>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6 mt-3">
              <label>Country*</label>
              <br />
              <Select
                value={props.creditFormData.billing_country}
                onChange={(value) => onChangeCreditForm("billing_country", value)}
                size="large"
                showSearch
                disabled={props.creditFormData.id && !isEditable}
                options={[
                  { 
                    label: 'Select Country', 
                    value: ''
                  },
                  ...countryStates.map(el => ({
                    label: el.name,
                    value: el.code2
                  }))
                ]}
                optionFilterProp="label"
              />
              {creditFormErrors.billing_country_error && (
                <label className="input-error">
                  {creditFormErrors.billing_country_error}
                </label>
              )}
            </div>
            <div className="col-xs-12 col-sm-6 mt-3">
              <label>State/Province*</label>
              <br />
              <Select
                value={props.creditFormData.billing_state}
                onChange={(value) => onChangeCreditForm("billing_state", value)}
                size="large"
                showSearch
                disabled={props.creditFormData.id && !isEditable}
                options={[
                  { 
                    label: 'Select State', 
                    value: ''
                  },
                  ...statesByCountry(props.creditFormData.billing_country).map(el => ({
                    label: el.name,
                    value: el.name
                  }))
                ]}
                optionFilterProp="label"
              />
              {creditFormErrors.billing_state_error && (
                <label className="input-error">
                  {creditFormErrors.billing_state_error}
                </label>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-between action-row mt-3">
            <div className="d-flex align-items-center">
              {(isUpdated && props.creditFormData.id) ?
                <Popconfirm
                  onConfirm={props.handleCheckout}
                  okText="Yes"
                  cancelText="No"
                  title="Are you sure?"
                  disabled={props.isPurchasing}
                >
                  <Button
                    className="btn btn-primary"
                    disabled={props.isPurchasing}
                  >
                    {props.isPurchasing ? <LoadingOutlined /> : "Purchase"}
                  </Button>
                </Popconfirm>
                :
                <Button
                  loading={isUpdating}
                  type="primary"
                  className='btn btn-primary'
                  onClick={handleUpdate}
                  disabled={isUpdating}
                >
                  Update
                </Button>
              }
            </div>
          </div>
        </div>
      </Wrapper>
    </Modal>    
  );
}

const Wrapper = styled.div`
  .modal-title-container {
    font-weight: normal;
    font-size: 22px;
    margin: -10px 0px 24px;
    border-bottom: 1px solid #b1bbc7;
    padding-bottom: 12px;
  }
  .mt-15 {
    margin-top: 15px;
  }
  .edit {
    display: flex;
    align-items: flex-end;
    button {
      height: 40px;
    }
  }
  .ant-input {
    width: 100%;
    border-radius: unset;
    height: 40px;
  }
  .ant-select {
    width: 100%;
  }
  .ant-select .ant-select-selector {
    border-radius: unset;
  }
  .input-error {
    color: #ff4d4f;
    margin-top: 5px;
  }
  .btn-primary {
    height: 40px;
    width: 100px;
  }
  @media (max-width: 575px) {
    .edit {
      margin-bottom: 0;
      margin-top: 12px;
    }
  }
`;

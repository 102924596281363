import styled from "styled-components";

export const Wrapper = styled.div`
  .main-container {
    padding-bottom: 80px;
    .subscriptions-tabs {
      margin: -24px -40px 24px;
      background: rgba(145, 158, 171, 0.08);
      padding: 24px 40px 0px;
      @media (max-width: 900px) {
        margin: -24px -20px 24px;
        padding: 24px 20px 0px;
      }
      .ant-tabs-nav {
        margin-bottom: 0px;
        margin-top: 0px;
      }
      .ant-tabs-tab-active {
        padding: 12px;
        background: white;
        border-radius: 8px 8px 0px 0px;
      }
      .active-tab {
        font-weight: 600 !important;
        font-size: 15px !important;
        line-height: 24px !important;
        color: #1ea9ea !important;
      }
      .inactive-tab {
        font-weight: 600 !important;
        font-size: 15px !important;
        line-height: 24px !important;
        color: #637381 !important;
      }
      .ant-tabs-ink-bar {
        background: transparent !important;
      }
    }
  }
  .live-stream-action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  @media (max-width: 1000px) {
    .payment-header {
      span {
        font-size: 18px;
      }
    }
    .subscriptions-tabs {
      .active-tab {
        font-size: 14px;
      }
      .inactive-tab {
        font-size: 14px;
      }
    }
  }
  @media (max-width: 600px) {
    .stream-header {
      display: flex !important;
      flex-direction: column;
      .subscriptions-tabs {
        padding: 0px;
      }
    }
  }
`;

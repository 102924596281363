/* eslint-disable react/prop-types */
import { DatePicker } from "antd";
import React from "react";
import moment from "moment";
import styled from "styled-components";

const { RangePicker } = DatePicker;

const StartEndDatePicker = ({
  startDate,
  endDate,
  height,
  width,
  ...props
}) => {
  return (
    <Wrapper>
      <RangePicker
        value={startDate && endDate ? [moment(startDate), moment(endDate)] : ""}
        style={{
          height: height || "34px",
          width: width || "auto",
        }}
        {...props}
      />
    </Wrapper>
  );
};

export default StartEndDatePicker;

const Wrapper = styled.div`
  .ant-picker {
    border: 1px solid rgba(145,158,171,0.32);
    border-radius: 8px !important;
  }
  .ant-picker:hover,
  .ant-picker-focused {
    box-shadow: none !important;
  }
`;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Card, TablePanel, Alert, UserAvatar } from "components/common";
import { callGetApiWithAuth } from "utils/api";
import { asUsdPrice, asVolume } from "utils/text";

export default function JsListCard(props) {
  const myUser = useSelector((state) => state.auth.user);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationParam, setPaginationParam] = useState({
    currentPage: 1,
    perPage: 5,
    total: 0,
  });
  const [searchParam, setSearchParam] = useState({});
  const [period, setPeriod] = useState({
    from: "",
    to: "",
  });
  const [totalAmount, setTotalAmount] = useState(0);
  const isCollapse = useSelector((state) => state.ui.isCollapse);

  const onGetTableData = (res) => {
    setPaginationParam({
      ...paginationParam,
      currentPage: res.data.current_page * 1,
      total: res.data.total * 1,
    });
    setTableData(res.data.data);
    setPeriod({
      from: res.data.period_from,
      to: res.data.period_to,
    });
    setTotalAmount(res.data.total_amount);
    props.onSetTotalData("jsAmount", res.data.total_amount);
    props.onSetTotalData("weekFrom", res.data.period_from);
    props.onSetTotalData("weekTo", res.data.period_to);
    setIsLoading(false);
  };
  const onFailTableData = () => {
    setIsLoading(false);
  };
  const loadTableData = (paginationParam0, searchParam0) => {
    setIsLoading(true);
    const params = {
      ...searchParam0,
      page: paginationParam0.currentPage,
      per_page: paginationParam0.perPage,
    };
    const q = Object.keys(params)
      .map((k) => k + "=" + params[k])
      .join("&");
    callGetApiWithAuth(
      `report/bonus_tracker/js_list?${q}`,
      onGetTableData,
      onFailTableData
    );
  };

  const onPageChange = (page) => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: page,
    };
    loadTableData(paginationParam0, searchParam);
  };

  useEffect(() => {
    loadTableData(paginationParam, searchParam);
  }, []);

  console.log("isCollapseisCollapse", isCollapse);

  return (
    <Wrapper isCollapse={isCollapse}>
      <div className="jump-bonus-container">
        <div className="top-panel">
          <div>
            <div className="title d-flex justify-content-between align-items-center">
              <h4>Jump Start Bonus</h4>
              <p>{`${period.from} ~ ${period.to}`}</p>
            </div>
          </div>
          <div>
            <Alert
              type="info"
              message={
                <>
                  Your paid rank is <strong>{myUser?.pay_rank.name}</strong>.
                  You can earn up to{" "}
                  <strong>
                    {myUser?.pay_rank_id * 1 >= 13
                      ? "10 "
                      : myUser?.pay_rank_id * 1 >= 12
                      ? "9  "
                      : myUser?.pay_rank_id * 1 >= 10
                      ? "8 "
                      : myUser?.pay_rank_id * 1 >= 8
                      ? "7 "
                      : myUser?.pay_rank_id * 1 >= 7
                      ? "6 "
                      : myUser?.pay_rank_id * 1 >= 5
                      ? "5 "
                      : myUser?.pay_rank_id * 1 >= 4
                      ? "4 "
                      : myUser?.pay_rank_id * 1 >= 3
                      ? "2 "
                      : "1 "}
                  </strong>
                  levels of Jump Start Bonuses from new Affiliate orders in your
                  organization. Total estimated jump start bonus is{" "}
                  <strong className="ant-typography ant-typography-success">
                    {asUsdPrice(totalAmount)}
                  </strong>
                  .
                </>
              }
              showIcon
              style={{ alignItems: "baseline" }}
            />
          </div>
        </div>
        <TablePanel
          title="Jump Start Bonus"
          data={tableData}
          loading={isLoading}
          paginationParam={paginationParam}
          onPageChange={onPageChange}
          columns={[
            {
              title: "User",
              key: "user",
              render: (_, record) => (
                <UserAvatar
                  image={record.user.image}
                  title={`${record.user.first_name} ${record.user.last_name}`}
                />
              ),
            },
            {
              title: "User ID",
              key: "uuid",
              render: (_, record) => <span>{record.user.uuid}</span>,
            },
            {
              title: "Level",
              key: "level",
              render: (_, record) => <span>{record.level}</span>,
            },
            {
              title: "BV",
              key: "pv",
              render: (_, record) => <span>{asVolume(record.pv)}</span>,
            },
            {
              title: "Jump Start BV",
              key: "pv2",
              render: (_, record) => <span>{asVolume(record.pv2)}</span>,
            },
            {
              title: "Percentage",
              key: "percent",
              render: (_, record) => <span>{record.percent * 100 + "%"}</span>,
            },
            {
              title: "Bonus Amount",
              key: "amount",
              render: (_, record) => <span>{asUsdPrice(record.amount)}</span>,
            },
          ]}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
  .jump-bonus-container {
    background: #ffffff !important;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2),
      0px 12px 24px -4px rgba(145, 158, 171, 0.12) !important;
    border-radius: 16px !important;
    height: 100%;
  }
  .top-panel {
    padding: 24px;
    .title {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 24px;
      h4 {
        font-size: 18px;
        font-weight: 700;
        color: #212b36;
      }
      p {
        margin-bottom: 0;
        color: #637381;
      }
    }
  }
  .table-card {
    @media (min-width: 1715px) {
      height: calc(100% - 173.59px);
    }
    @media (max-width: 1714px) and (min-width: 1600px) {
      height: ${(props) =>
        !props.isCollapse ? "calc(100% - 195.59px)" : "calc(100% - 173.59px)"};
    }
    .panelRoot {
      margin-bottom: 0px !important;
      height: 100%;
    }
  }
  .panelHead {
    display: none !important;
  }
  .table-new-wrapper {
    height: 100%;
    .tableWrapper {
      @media (min-width: 1600px) {
        height: calc(100% - 33px);
      }
    }
    .ant-table-container {
      @media (max-width: 1350px) {
        width: 800px;
      }
    }
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    .ant-table {
      border-radius: 0px !important;
    }
    .ant-table-content {
      thead {
        th:first-child,
        th:last-child {
          border-radius: 0px !important;
        }
      }
    }
  }
`;
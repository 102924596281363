import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import copy from 'copy-to-clipboard';
import {
  TablePanel, 
  Button, Popconfirm,
  SuccessNotification,
} from 'components/common'
import { 
  callGetApiWithAuth,
  callDeleteApiWithAuth, 
} from 'utils/api'
import { asNumber } from 'utils/text'
import CreateModal from './CreateModal'

export default function RotatorSubPage() {
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [modalMode, setModalMode] = useState(undefined)

  const onGetTableData = (res) => {
    setIsLoading(false)
    setTableData(res.data)
  }
  const onFailTableData = () => {
    setIsLoading(false)
  }
  const loadTableData = () => {
    setIsLoading(true)
    callGetApiWithAuth(`tool/traffic_rotator/rotators`, 
      onGetTableData, onFailTableData
    )
  }

  const handleSearch = () => {
    loadTableData()
  }

  useEffect(() => {
    handleSearch()
  }, [])

  return (
    <Wrapper>
      <TablePanel title='Traffic Rotators'
        data={tableData}
        loading={isLoading}
        toolbar={
          <div className='d-flex align-items-center'>
            <Button onClick={()=>setModalMode('add')}>
              Create
            </Button>
            {modalMode==='add' &&
              <CreateModal 
                setModalMode={setModalMode}
                handleSearch={handleSearch}
              />
            }
          </div>
        }
        columns={[
          {
            title: 'Path',
            key: 'path',
            render: (_, record) => (
              <span>
                {`${process.env.REACT_APP_CORP_URL}/traffic-rotator/${record.path}`}
              </span>
            )
          },
          {
            title: 'Rotated Users',
            key: 'username',
            render: (_, record) => (
              <span>
                {record.rotate_users.map(el => (
                  el.username
                )).join(', ')}
              </span>
            )
          },
          {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
              <div className='d-flex align-items-center'>
                <Button
                  onClick={() => {
                    SuccessNotification("Successfully copied to clipboard");
                    copy(`${process.env.REACT_APP_CORP_URL}/traffic-rotator/${record.path}`);
                  }}
                >
                  Copy Url
                </Button>
                <span style={{ width: 8 }} />
                <EditBtn data={record}
                  handleSearch={handleSearch}
                />
                <span style={{ width: 8 }} />
                <DeleteBtn data={record}
                  handleSearch={handleSearch}
                />
              </div>
            )
          }
        ]}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
`

const DeleteBtn = (props) => {
  const [isUpdating, setIsUpdating] = useState(false)

  const onSubmitDone = (res) => {
    SuccessNotification(res.message)
    setIsUpdating(false)
    props.handleSearch()
  }
  const onSubmitFail = () => {
    setIsUpdating(false)
  }
  const handleSubmit = () => {
    setIsUpdating(true)
    callDeleteApiWithAuth(
      `tool/traffic_rotator/${props.data.path}/delete`, 
      onSubmitDone, onSubmitFail
    )
  }

  return (
    <Popconfirm onConfirm={handleSubmit}>
      <Button danger loading={isUpdating}>
        Delete
      </Button>
    </Popconfirm>
  )
}

const EditBtn = (props) => {
  const [modalMode, setModalMode] = useState(undefined)

  return (
    <>
      <Button onClick={() => setModalMode('edit')}>
        Edit
      </Button>
      {modalMode==='edit' && 
        <CreateModal
          data={props.data}
          handleSearch={props.handleSearch}
          setModalMode={setModalMode}
        />
      }
    </>
  )
}

import { Alert as Alert_ } from "antd";
import styled from "styled-components";

export const Alert = ({ ...props }) => {
  return (
    <Wrapper>
      <Alert_ {...props} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .ant-alert {
    align-items: flex-start !important;
    .anticon {
      margin-top: 4px !important;
    }
  }
  .ant-alert-success {
    margin-bottom: -3px;
    background: rgba(0,171,85,0.08) ;
    border-radius: 8px;
    padding: 15px 18px;
    border: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #7a0916;
    path {
      fill: #36b37e;
    }
    .anticon-exclamation-circle {
      margin-right: 14px !important;
    }
    .ant-alert-content {
      margin-top: -3px;
    }
    .ant-alert-message {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #36b37e;
    }
  }
  .ant-alert-warning {
    margin-bottom: -3px;
    background: #ffe9d5;
    border-radius: 8px;
    padding: 15px 18px;
    border: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #7a0916;
    path {
      fill: #ff5630;
    }
    .anticon-exclamation-circle {
      margin-right: 14px !important;
    }
    .ant-alert-message {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #7a0916;
    }
  }
  .ant-alert-info {
    margin-bottom: -3px;
    background: #fff5cc;
    border-radius: 8px;
    padding: 15px 18px;
    border: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #7a4100;
    path {
      fill: #ffab00;
    }
    .anticon-info-circle {
      margin-right: 14px !important;
    }
    .ant-alert-message {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #7a4100;
    }
  }
`;

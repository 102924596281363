import React, { useState, useEffect } from 'react'
import moment from 'moment'
import {
  TablePanel, Space,
  Button, Input,
} from 'components/common'
import { 
  callGetApiWithAuth
} from 'utils/api'
import PositionTd from './PositionTd'
import PlacementTd from './PlacementTd'
import PowerLegTd from './PowerLegTd'
import ActionTd from './ActionTd'

export default function TableView() {
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [paginationParam, setPaginationParam] = useState({
    currentPage: 1,
    perPage: 15,
    total: 0
  })
  const [searchParam, setSearchParam] = useState({
    uuid: '',
    name: '',
  })

  const onGetTableData = ({ data }) => {
    setIsLoading(false)
    setTableData(data.data)
    setPaginationParam({
      ...paginationParam,
      currentPage: data.current_page,
      total: data.total,
    })
  }
  const onFailTableData = () => {
    setIsLoading(false)
  }
  const loadTableData = (paginationParam0, searchParam0) => {
    setTableData([])
    setIsLoading(true)
    const params = {
      ...searchParam,
      page: paginationParam0.currentPage,
      per_page: paginationParam0.perPage
    }
    const q = Object.keys(params).map(k=>k+'='+params[k]).join('&')
    callGetApiWithAuth(`my_team/holding_tank/holdings?${q}`, onGetTableData, onFailTableData)
  }
  const handleSearch = () => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: 1
    }
    loadTableData(paginationParam0, searchParam)
  }
  const onPageChange = (currentPage) => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage,
    }
    loadTableData(paginationParam0, searchParam)
  }

  useEffect(() => {
    handleSearch()
  }, [])

  return (
    <TablePanel
      data={tableData}
      loading={isLoading}
      paginationParam={paginationParam}
      onPageChange={onPageChange}
      toolbar={
        <Space size={10}>
          <div style={{ width: 250 }}>
            <Input value={searchParam.uuid}
              onChange={e=>{
                setSearchParam({...searchParam, uuid: e.target.value})
              }}
              placeholder={'User ID or Username'}
            />
          </div>
          <div style={{ width: 250 }}>
            <Input value={searchParam.name}
              onChange={e=>{
                setSearchParam({...searchParam, name: e.target.value})
              }}
              placeholder={'Name'}
            />
          </div>
          <div>
            <Button onClick={handleSearch}>
              Search
            </Button>
          </div>
        </Space>
      }
      columns={[
        {
          title: 'User ID',
          key: 'uuid',
          render: (_, record) => (
            <span>{record.uuid}</span>
          )
        }, 
        {
          title: 'Name',
          key: 'name',
          render: (_, record) => (
            <span>
              {`${record.first_name} ${record.last_name}`}
            </span>
          )
        },
        {
          title: 'PV',
          key: 'pv',
          dataIndex: 'pv'
        },
        {
          title: 'Clear Date',
          key: 'clear_date',
          render: (_, record) => (
            <span>
              {record.has_order
              ? record.clear_date
              : '-'}
            </span>
          )
        },
        {
          title: 'Time Left',
          key: 'remain_days',
          render: (_, record) => (
            <span>
              {record.has_order
              ? (
                record.is_autoplacing
                ? `Auto-placing`
                : `${Math.max(record.remain_days, 0)} Days Left`
              ) : '-'}
            </span>
          )
        },
        {
          title: 'Position',
          key: 'position',
          render: (_, record, index) => (
            <PositionTd
              data={record}
              index={index}
              tableData={tableData}
              setTableData={setTableData}
            />
          )
        },
        {
          title: 'New Placement Sponsor',
          key: 'placement',
          render: (_, record, index) => (
            <PlacementTd 
              data={record}
              index={index}
              tableData={tableData}
              setTableData={setTableData}
            />
          )
        },
        {
          title: 'Leg Position',
          key: 'power_leg',
          render: (_, record, index) => (
            <PowerLegTd
              data={record}
              index={index}
              tableData={tableData}
              setTableData={setTableData}
            />
          )
        },
        {
          title: 'Action',
          key: 'action',
          render: (_, record) => (
            <ActionTd 
              data={record}
              loadTableData={loadTableData}
            />
          )
        }
      ]}
    />
  )
}

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import copy from 'copy-to-clipboard';
import {
  TablePanel,
  Table, Badge, Popover,
  Button, Popconfirm,  
  SuccessNotification,
  message,
} from "components/common";
import { asPrice } from "utils/text";
import { 
  callGetApiWithAuth, callPutApiWithAuth, 
  callDeleteApiWithAuth 
} from "utils/api";
import { varKey, varLabel, varIs, varValue } from "common/var";
import { ReactComponent as EditIcon } from "assets/icons/edit-icon.svg";
import { ReactComponent as CopyIcon } from "assets/icons/copy-icon.svg";

export default function TableView(props) {
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParam, setSearchParam] = useState({
    status: "",
  });

  const onGetListData = (res) => {
    setTableData(res.data);
    setIsLoading(false);
    props.setShouldLoad(false);
  };
  const onFailListData = () => {
    setIsLoading(false);
    props.setShouldLoad(false);
  };
  const loadListData = () => {
    setIsLoading(true);
    const params = {
      ...searchParam,
    };
    const q = Object.keys(params)
      .map((k) => k + "=" + params[k])
      .join("&");
    callGetApiWithAuth(
      `my_website/share_cart/list?${q}`,
      onGetListData,
      onFailListData
    );
  };

  useEffect(() => {
    if (props.shouldLoad) loadListData();
  }, [props.shouldLoad]);

  return (
    <Wrapper>
      <TablePanel
        data={tableData}
        loading={isLoading}
        toolbar={
          <div className="toolbar">
            <Button onClick={() => props.setModalMode("add")}>
              Create Cart
            </Button>
          </div>
        }
        columns={[
          {
            title: "Title",
            key: "title",
            render: (_, record) => <span>{record.title}</span>,
          },
          {
            title: "Total Amount",
            key: "amount",
            render: (_, record) => {
              let amount = 0;
              record.details.forEach((el) => {
                if (el.pay_cycle === 0) {
                  amount += el.product.member_price * el.quantity;
                } else {
                  amount += el.product.recurring_price * el.quantity;
                }
              });
              return <span>{asPrice(amount)}</span>;
            },
          },
          {
            title: "Products",
            key: "products",
            render: (_, record) => (
              <Popover
                content={() => <DetailsTable data={record} />}
                title={null}
              >
                {record.details.length}
              </Popover>
            ),
          },
          {
            title: "Orders",
            key: "orders",
            render: (_, record) => (
              <span>{record.share_cart_orders.length}</span>
            ),
          },
          {
            title: "Sales Amount",
            key: "sales_amount",
            render: (_, record) => <span>{asPrice(record.sales_amount)}</span>,
          },
          {
            title: "Status",
            key: "status",
            render: (_, record) => (
              <div className="statusButton">
                <Badge
                  type={"shareCartStatus"}
                  keyName={varKey("shareCart.status", record.status)}
                  label={varLabel("shareCart.status", record.status)}
                />
              </div>
            ),
          },
          {
            title: "Action",
            key: "action",
            render: (_, record) => (
              <div className="carts-actions d-flex align-items-center">
                <Button
                  onClick={() => {
                    props.setModalMode("edit");
                    props.setSelected(record);
                  }}
                  className="edit-button"
                >
                  <EditIcon />
                </Button>
                <span style={{ width: 8 }} />
                <Button
                  onClick={() => {
                    copy(`${process.env.REACT_APP_CORP_URL}/share-cart?h=${record.hash_code}`);
                    message.success('Copied')
                  }}
                  className="copy-button"
                >
                  <CopyIcon />
                </Button>
                <ActiveBtn data={record} setShouldLoad={props.setShouldLoad} />
                <span style={{ width: 8 }} />
                <DeleteBtn data={record} setShouldLoad={props.setShouldLoad} />
              </div>
            ),
          },
        ]}
      />
    </Wrapper>
  );
}

const DetailsTable = (props) => {
  return (
    <Table
      dataSource={props.data.details}
      loading={false}
      pagination={false}
      columns={[
        {
          title: "Product",
          key: "product",
          render: (_, record) => <span>{record.product.title}</span>,
        },
        {
          title: "Unit Price",
          key: "price",
          render: (_, record) => (
            <span>
              {asPrice(
                record.pay_cycle === 0
                  ? record.product.recurring_price
                  : record.product.member_price
              )}
            </span>
          ),
        },
        {
          title: "Quantity",
          key: "quantity",
          render: (_, record) => <span>{record.quantity}</span>,
        },
        {
          title: "Subscription Frequency",
          key: "pay_cycle",
          render: (_, record) => (
            <span>
              {varLabel("shareCartDetail.payCycle", record.pay_cycle)}
            </span>
          ),
        },
      ]}
    />
  );
};

const ActiveBtn = (props) => {
  const [isUpdating, setIsUpdating] = useState(false);

  const onUpdateDone = (res) => {
    setIsUpdating(false);
    SuccessNotification(res.message);
    props.setShouldLoad(true);
  };
  const onUpdateFail = () => {
    setIsUpdating(false);
  };
  const handleUpdate = (status) => {
    callPutApiWithAuth(
      `my_website/share_cart/${props.data.id}/status`,
      { status },
      onUpdateDone,
      onUpdateFail
    );
  };

  return varIs("shareCart.status", props.data.status, "active") ? (
    <Popconfirm
      onConfirm={() => handleUpdate(varValue("shareCart.status", "inactive"))}
    >
      <Button bgColor="#f56b6e" size="small" loading={isUpdating}>
        Deactivate
      </Button>
    </Popconfirm>
  ) : (
    <Popconfirm
      onConfirm={() => handleUpdate(varValue("shareCart.status", "active"))}
    >
      <Button bgColor="#79b779" size="small" loading={isUpdating}>
        Activate
      </Button>
    </Popconfirm>
  );
};

const DeleteBtn = (props) => {
  const [isUpdating, setIsUpdating] = useState(false)

  const onUpdateDone = (res) => { 
    setIsUpdating(false)
    SuccessNotification(res.message)
    props.setShouldLoad(true)
  }
  const onUpdateFail = () => {
    setIsUpdating(false)
  }
  const handleDelete = (status) => {
    callDeleteApiWithAuth(`my_website/share_cart/${props.data.id}/delete`, onUpdateDone, onUpdateFail
    )
  }

  return (
    <Popconfirm onConfirm={handleDelete}>
      <Button bgColor="#f56b6e"
        loading={isUpdating}
        size="small"
      >
        Delete
      </Button>
    </Popconfirm>
  )
}

export const Wrapper = styled.div`
  .statusButton {
    .statusTag {
      min-width: auto !important;
      color: white;
    }
  }
  .chart-container {
    width: 140px;
  }
  .carts-actions {
    .edit-button, .copy-button {
      background: none;
      border: none;
      box-shadow: none;
      padding-left: 0px;
      path {
        fill: #36b37e;
      }
    }
    .copy-button {
      path {
        fill: #1EA9EA;
      }
    }
  }
  @media (max-width: 1250px) {
    .ant-table {
      overflow-x: scroll;
      .ant-table-container {
        width: 900px;
      }
    }
  }
  @media (max-width: 600px) {
    .panelHead {
      flex-direction: column;
      align-items: flex-start;
      h4 {
        padding-bottom: 24px;
      }
      .toolbar {
        width: 100%;
        button {
          width: 100%;
        }
      }
    }
  }
`;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import {
  TablePanel, Modal, 
  FormItem, Input,
  Button, Popconfirm,
  SuccessNotification,
} from 'components/common'
import { 
  callGetApiWithAuth, callPostApiWithAuth,
  callPutApiWithAuth, callDeleteApiWithAuth, 
} from 'utils/api';
import { countryName } from 'common/country';
import { SearchOutlined, CheckOutlined } from '@ant-design/icons';

export default function RotatorPage() {
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const onGetTableData = ({ data }) => {
    setTableData(data)
    setIsLoading(false)
  }
  const onFailTableData = () => {
    setIsLoading(false)
  }
  const handleSearch = () => {
    setIsLoading(true)
    callGetApiWithAuth(`my_website/rotator/list`,
      onGetTableData, onFailTableData
    )
  }

  useEffect(() => {
    handleSearch()
  }, [])

  return (
    <Wrapper>
      <TablePanel
        title={'Traffic Rotators'}
        data={tableData}
        loading={isLoading}
        toolbar={
          <div className='toolbar-container'>
            <div className='toolbar-sub-container'>
             <CreateBtn handleSearch={handleSearch} />
            </div>
          </div>
        }
        columns={[
          {
            title: 'Name',
            key: 'name',
            render: (_, record) => (
              <span>
                {`${record.tuser.first_name} ${record.tuser.last_name}`}
              </span>
            )
          },
          {
            title: 'Username',
            key: 'username',
            render: (_, record) => (
              <span>
                {record.tuser.username}
              </span>
            )
          },
          {
            title: 'User ID',
            key: 'uuid',
            render: (_, record) => (
              <span>
                {record.tuser.uuid}
              </span>
            )
          },
          {
            title: 'Country',
            key: 'country',
            render: (_, record) => (
              <span>
                {countryName(record.tuser.country)}
              </span>
            )
          },
          {
            title: 'Traffic Chance(%)',
            key: 'chance',
            render: (_, record) => (
              <span>
                {record.chance}
              </span>
            )
          },
          {
            title: 'Total Reach Count',
            key: 'total_clicks',
            render: (_, record) => (
              <span>
                {record.total_clicks}
              </span>
            )
          },
          {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
              <div className='d-flex align-items-center'>
                <EditBtn data={record}
                  handleSearch={handleSearch}
                />
                <div style={{ width: 12 }} />
                <DeleteBtn data={record}
                  handleSearch={handleSearch}
                />
              </div>
            )
          }
        ]}
      />
    </Wrapper>
  )
}

const CreateBtn = (props) => {
  const [modalMode, setModalMode] = useState(undefined)
  const [formData, setFormData] = useState({
    username: '',
    tuser_name: '',
    tuser_id: '',
    chance: '',
  })
  const [errorMessages, setErrorMessages] = useState([])
  const [isUpdating, setIsUpdating] = useState(false)

  const onGetDownlines = ({ data }) => {
    if (data.length>0) {
      setFormData({
        ...formData,
        tuser_name: `${data[0].uuid} ${data[0].first_name} ${data[0].last_name}`,
        tuser_id: data[0].id,
      })
    }
  }
  const searchDownline = () => {
    if (!formData.username) return;
    const params = {
      username: formData.username
    }
    const q = Object.keys(params).map(k=>k+'='+params[k]).join('&')
    callGetApiWithAuth(
      `my_website/rotator/search_downline?${q}`, 
      onGetDownlines
    )
  }
  const onSubmitDone = (res) => {
    SuccessNotification(res.message)
    setIsUpdating(false)
    props.handleSearch()
    setModalMode(undefined)
  }
  const onSubmitFail = () => {
    setIsUpdating(false)
  }
  const handleSubmit = () => {
    let errorMessages0 = [];
    ['tuser_id', 'chance'].forEach(k => {
      if (!formData[k]) {
        errorMessages0.push({
          field: k,
          message: 'Required'
        })
      }
    })
    setErrorMessages(errorMessages0)
    if (errorMessages0.length>0) return;

    const body = {...formData}
    setIsUpdating(true)
    callPostApiWithAuth(`my_website/rotator/create`, body, 
      onSubmitDone, onSubmitFail
    )
  }

  return (
    <>
      <Button onClick={()=>setModalMode('add')}>
        Add
      </Button>
      {modalMode === 'add' && 
      <Modal title={'Add'}
        onCancel={()=>setModalMode(undefined)}
      >
        <FormItem label={'User ID'}
          errorMessages={errorMessages.filter(el=>el.field==='tuser_id')}
        >
          <div className='d-flex align-items-center'>
            {formData.tuser_name && 
            <>
              <CheckOutlined />&nbsp;
              {formData.tuser_name}&nbsp;&nbsp;
            </>
            }
            <Input value={formData.username}
              onChange={e=>setFormData({
                ...formData,
                username: e.target.value
              })}
              placeholder={'Username, User ID'}
            />
            <span style={{ width: 8 }} />
            <Button onClick={searchDownline}>
              <div className='d-flex align-items-center'>
                <SearchOutlined />
              </div>
            </Button>
          </div>
        </FormItem>
        <FormItem label={'Traffic Chance(%)'}
          errorMessages={errorMessages.filter(el=>el.field==='chance')}
        >
          <Input value={formData.chance}
            onChange={e=>setFormData({
              ...formData,
              chance: e.target.value
            })}
            type={'numeric'}
          />
        </FormItem>
        <div style={{ height: 12 }} />
        <div className='d-flex justify-content-center'>
          <Popconfirm onConfirm={handleSubmit}>
            <Button success loading={isUpdating}>
              Create
            </Button>
          </Popconfirm>
        </div>
      </Modal>
      }
    </>
  )
}

const EditBtn = (props) => {
  const [modalMode, setModalMode] = useState(undefined)
  const [formData, setFormData] = useState({
    chance: '',
  })
  const [errorMessages, setErrorMessages] = useState([])
  const [isUpdating, setIsUpdating] = useState(false)

  const onSubmitDone = (res) => {
    SuccessNotification(res.message)
    setIsUpdating(false)
    props.handleSearch()
    setModalMode(undefined)
  }
  const onSubmitFail = () => {
    setIsUpdating(false)
  }
  const handleSubmit = () => {
    let errorMessages0 = [];
    ['chance'].forEach(k => {
      if (!formData[k]) {
        errorMessages0.push({
          field: k,
          message: 'Required'
        })
      }
    })
    setErrorMessages(errorMessages0)
    if (errorMessages0.length>0) return;

    const body = {...formData}
    setIsUpdating(true)
    callPutApiWithAuth(`my_website/rotator/${props.data.id}/update`, body, 
      onSubmitDone, onSubmitFail
    )
  }

  useEffect(() => {
    if (props.data) {
      setFormData({
        ...formData,
        chance: props.data.chance,
      })
    }
  }, [props.data])

  return (
    <>
      <Button onClick={()=>setModalMode('edit')}>
        Edit
      </Button>
      {modalMode === 'edit' && 
      <Modal title={'Edit'}
        onCancel={()=>setModalMode(undefined)}
      >
        <FormItem label={'Traffic Chance(%)'}
          errorMessages={errorMessages.filter(el=>el.field==='chance')}
        >
          <Input value={formData.chance}
            onChange={e=>setFormData({
              ...formData,
              chance: e.target.value
            })}
            type={'numeric'}
          />
        </FormItem>
        <div style={{ height: 12 }} />
        <div className='d-flex justify-content-center'>
          <Popconfirm onConfirm={handleSubmit}>
            <Button success loading={isUpdating}>
              Update
            </Button>
          </Popconfirm>
        </div>
      </Modal>
      }
    </>
  )
}

const DeleteBtn = (props) => {
  const [isUpdating, setIsUpdating] = useState(false)

  const onSubmitDone = (res) => {
    SuccessNotification(res.message)
    setIsUpdating(false)
    props.handleSearch()
  }
  const onSubmitFail = () => {
    setIsUpdating(false)
  }
  const handleSubmit = () => {
    setIsUpdating(true)
    callDeleteApiWithAuth(`my_website/rotator/${props.data.id}/delete`,
      onSubmitDone, onSubmitFail
    )
  }

  return (
    <Popconfirm onConfirm={handleSubmit}>
      <Button danger loading={isUpdating}>
        Delete
      </Button>
    </Popconfirm>
  )
}

const Wrapper = styled.div`
`

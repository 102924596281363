import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Card, LiquidPlot, Progress, Spin } from "components/common";
import { asVolume, asPercent } from "utils/text";
import { callGetApiWithAuth } from "utils/api";
import { Wrapper } from "./RankCard.styled";
import { ReactComponent as UnRankedIcon } from "assets/icons/dashboard/unranked.svg";
import { ReactComponent as BuilderIcon } from "assets/icons/dashboard/builder.svg";

export default function RankCard(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [statData, setStatData] = useState({
    rank: { name: "Unranked" },
    next_rank: { name: "Director" },
    has_qv_limit: false,
    pv: 0,
    next_pv: 40,
    pv_percent: 0,
    pecv: 0,
    next_pecv: 80,
    pecv_percent: 0,
    pe: 0,
    next_pe: 0,
    pe_percent: 0,
    qv: 0,
    next_qv: 0,
    qv_percent: 0,
    total_percent: 0,
  });

  const onGetStatData = (res) => {
    setStatData(res.data);
    setIsLoaded(true);
  };
  const onFailStatData = () => {
    setIsLoaded(true);
  };

  useEffect(() => {
    if (props.viewMode == "current") {
      setIsLoaded(false);
      callGetApiWithAuth(
        "my_rank/rank_snapshot/rank_stats",
        onGetStatData,
        onFailStatData
      );
    } else if (props.viewMode == "last") {
      setIsLoaded(false);
      callGetApiWithAuth(
        "my_rank/rank_snapshot/last_rank_stats",
        onGetStatData,
        onFailStatData
      );
    }
  }, [props.viewMode]);

  return (
    <Wrapper className="white-rounded-bg">
      {isLoaded ? (
        <Row gutter={[90, 24]}>
          <Col xs={24} xl={7} className="responsive-rank">
            <div className="liquid-container">
              <Progress
                type="circle"
                percent={statData.total_percent * 100}
                strokeWidth={9}
                size="large"
                format={(percent) => {
                  return asPercent(percent / 100);
                }}
                strokeColor={{
                  "0%": "#7FCFF4",
                  "25%": "#1EA9EA",
                  "50%": "#1EA9EA",
                  "75%": "#1EA9EA",
                  "100%": "#7FCFF4",
                }}
              />
            </div>
          </Col>
          <Col xs={24} xl={7}>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <div className="rank-box">
                  <UnRankedIcon />
                  <div style={{ textAlign: "right" }}>
                    <div className="rank-para">Current Rank</div>
                    <div className="rank-head">{statData.rank.name}</div>
                  </div>
                </div>
              </Col>
              <Col span={24}>
                <div className="rank-box">
                  <BuilderIcon />
                  <div style={{ textAlign: "right" }}>
                    <div className="rank-para">Next Rank</div>
                    <div className="rank-head">{statData.next_rank.name}</div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={24} xl={10}>
            <div>
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <div className="line-progress-container">
                    <div className="line-progress-box">
                      <div className="line-progress-title">Personal Volume</div>
                      <div className="line-progress-value">
                        ({asVolume(statData.pv)}/{asVolume(statData.next_pv)})
                      </div>
                    </div>
                    <Progress
                      percent={100 * statData.pv_percent}
                      size="small"
                      strokeWidth={4}
                      strokeColor="#00B8D9"
                      showInfo={false}
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="line-progress-container">
                    <div className="line-progress-box">
                      <div className="line-progress-title">
                        Personal Customer Volume
                      </div>
                      <div className="line-progress-value">
                        ({asVolume(statData.pecv)}/{asVolume(statData.next_pecv)})
                      </div>
                    </div>
                    <Progress
                      percent={100 * statData.pecv_percent}
                      size="small"
                      strokeWidth={4}
                      strokeColor="#FFAB00"
                      showInfo={false}
                    />
                  </div>
                </Col>
                {statData.has_qv_limit ? (
                  <Col span={24}>
                    <div className="line-progress-container">
                      <div className="line-progress-box">
                        <div className="line-progress-title">
                          Personally Enrolled
                        </div>
                        <div className="line-progress-value">
                          {asVolume(statData.pe)}/{asVolume(statData.next_pe)}
                        </div>
                      </div>
                      <Progress
                        percent={100 * statData.pe_percent}
                        size="small"
                        strokeWidth={4}
                        strokeColor="#36B37E"
                        showInfo={false}
                      />
                    </div>
                  </Col>
                ) : (
                  ""
                )}
                {statData.has_qv_limit ? (
                  <Col span={24}>
                    <div className="line-progress-container">
                      <div className="line-progress-box">
                        <div className="line-progress-title">
                          Qualified Rank Volume
                        </div>
                        <div className="line-progress-value">
                          {asVolume(statData.qv)}/{asVolume(statData.next_qv)}
                        </div>
                      </div>
                      <Progress
                        percent={100 * statData.qv_percent}
                        size="small"
                        strokeWidth={4}
                        strokeColor="#FF5630"
                        showInfo={false}
                      />
                    </div>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            </div>
          </Col>
        </Row>
      ) : (
        <div style={{ height: 300 }}>
          <Spin spinning={true} className="loading-icon" />
        </div>
      )}
    </Wrapper>
  );
}

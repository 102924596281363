import React from "react";
import { useSelector } from "react-redux";
import { Row, Col, SalesIQ } from "components/common";
import BannerSection from "./banner/BannerSection";
import AlertSection from "./alert/AlertSection";
import Alert2Section from "./alert/Alert2Section";
import SocialLinkSections from "./socialLink/SocialLinkSections";
import AccountDetailsSection from "./account/AccountDetailsSection";
import StoreSection from "./store/StoreSection";
import TrainingSection from "./training/TrainingSection";
import BestProductList from "./selling/BestProductList";
import RankupWheelModal from "./rankup/RankupWheelModal";
import WalletSection from "./WalletSection";
import EarningSection from "./EarningSection";
import OnetreeSection from "./OnetreeSection";
import RankSection from "./rank/RankSection";
import ProductSalesSection from "./productSales/ProductSalesSection";
import BinarySection from "./binary/BinarySection";
import { Wrapper } from "./HomePage.styled";

export default function IndexPage() {
  const myUser = useSelector((state) => state.auth.user);

  return (
    <Wrapper>
      <div className="main-container">
        <Row gutter={[24, 24]}>
          <RankupWheelModal />
          <Col span={24}>
            <div className="alert-container-section">
              <AlertSection />
              {myUser && myUser.ubo_alert2.visibility === "show" && (
                <Alert2Section />
              )}
            </div>
          </Col>
          <Col xs={24} xxl={24}>
            <BannerSection />
          </Col>
          {/* <Col xs={24} xxl={8}>
            <TrainingSection />
          </Col> */}
          <Col xs={24} xxl={16}>
            <AccountDetailsSection />
          </Col>
          <Col xs={24} xxl={8}>
            <StoreSection />
          </Col>
          <Col xs={24} xxl={8}>
            <WalletSection />
          </Col>
          <Col xs={24} xxl={8}>
            <EarningSection />
          </Col>
          <Col xs={24} xxl={8}>
            <OnetreeSection />
          </Col>
          <Col xs={24} xxl={12}>
            <BinarySection />
          </Col>
          <Col xs={24} xxl={12}>
            <Row gutter={[24, 24]} style={{ height: "100%" }}>
              <Col span={24}>
                <RankSection />
              </Col>
              <Col span={24}>
                <ProductSalesSection />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <BestProductList />
          </Col>

          <Col xs={24}>
            <SalesIQ />
          </Col>
        </Row>
      </div>
    </Wrapper>
  );
}

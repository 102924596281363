import styled from "styled-components";

export const Wrapper = styled.div`
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2),
    0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  border-radius: 16px;
  margin-bottom: 24px;
  .loading-spin-section {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 1;
  }
  .loading-spin {
    height: auto;
    margin-top: 50%;
  }
  .enroller-card-main-container {
    padding: 0 24px 24px;
    margin-top: -75px;
    position: relative;
  }
  .banner-img-section {
    position: relative;
    margin-bottom: 95px;
    .banner-img {
      width: 100%;
      max-height: 300px;
      min-height: 200px;
      object-fit: cover;
      border-radius: 16px;
    }
    .photo-img {
      position: absolute;
      border: 2px solid #ffffff;
      width: 128px;
      height: 128px;
      border-radius: 50%;
      left: 24px;
      object-fit: cover;
      bottom: 24px;
    }
  }
  .image-upload-section {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .photo-img {
      width: 150px;
      height: 150px;
      object-fit: cover;
      margin-right: 20px;
      border-radius: 50%;
      background-color: #ffffff;
    }
    .user-upload .ant-upload > button {
      border-radius: 2px;
      background: #ffffff;
      color: #384971;
      border: 1px solid rgba(127, 143, 163, 0.4);
      display: flex;
      align-items: center;
      font-size: 14px;
      padding: 5px 27px;
      height: 38px;
    }
    .user-upload {
      .ant-upload-list {
        display: none;
      }
    }
  }
  .select-container {
    .label {
      font-size: 15px;
      color: #7f8fa3;
      margin-bottom: 3px;
    }
    .select-box {
      font-weight: 500;
      font-size: 15px;
      color: black;
      dispalay: flex;
      align-items: center;
      .ant-input-affix-wrapper:hover{
        border: 1px solid rgba(145,158,171,0.32);
      }
      .ant-switch {
        margin-left: 10px;
      }
    }
  }
  .connect-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .connect-title {
      font-size: 15px;
      color: #7f8fa3;
    }
    .connect-input {
      width: 100%;
      svg {
        width: 13px;
      }
      .date-picker-dob {
        width: 100% !important;
      }
      
    }
    }
    .connect-social-icon {
      color: #00b2ff;
    }
  }
  .interests-section {
    h5 {
      display: flex;
      align-items: center;
      mar
    }
    .select-container {
      .select-box {
        position: relative;
        .emoji-picker-react {
          position: absolute;
          z-index: 1;
        }
      }
    }
  }
  .add-interest-btn {
    cursor: pointer;
    color: #1ea9ea;
    svg {
      width: 22px;
      font-size: 21px;
    }
  }
  .emoji-icon {
    cursor: pointer;
    margin-right: 5px;
  }
  .remove-interest {
    cursor: pointer;
    color: #ef6a6a;
  }
  .enroller-card-header {
    background: #f5fcff;
    padding: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    h5 {
      color: #00b2ff;
    }
    .action-btns {
      display: flex;
      flex-direction: row;
    }
  }
  .email-verify-btn {
    margin-top: 15px;
  }
  .input-error {
    color: red;
  }
  @media (max-width: 500px) {
    .enroller-card-header {
      .action-btns {
        flex-direction: column;
      }
    }
    .change-button {
      margin-bottom: 20px;
    }
    .banner-img-section .banner-img {
      max-height: 200px;
    }
  }
  .ban-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h5 {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #212b36;
      margin: 0px;
      svg {
        margin-left: 10px;
      }
    }
    .upload-banner {
      background: #1ea9ea;
      border-radius: 8px !important;
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0px 17px;
      svg {
        margin-right: 10px;
      }
    }
  }
  .my-story {
    margin-top: 24px;
    h5 {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #212b36;
      margin: 0px 0px 13px;
      svg {
        margin-left: 10px;
      }
    }
    .product-detail{
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #637381;
    }
    .custom-h5 {
      margin: 0px 0px 5px;
    }
    .story-div{
      margin-bottom: -10px;
    }
  }
  .new-connect-container{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    .ant-switch-checked{
      background-color: #1EA9EA !important;
    }
    .new-connect-title{
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #212B36;
      margin-left: 10px;

    }
  }.s-container0 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;
    .save-button {
      button {
        background: #1ea9ea;
        border-radius: 8px !important;
        width: auto;
        height: 48px;
        padding: 0px 15px;
        margin-left: 16px;
      }
    }
    .change-button {
      button {
        background: white;
        border-radius: 8px !important;
        width: auto;
        height: 50px !important;
        padding: 0px 15px;
        border: 1px solid rgba(145, 158, 171, 0.24);
        border-radius: 8px;
        color: black;
      }
    }
  }@media (max-width: 600px) {
    .ban-container{
      flex-direction: column;
      h5{
        width: 100%;
      }
      div{
        width: 100% !important;
        button{
        width: 100% !important;
        margin-top: 24px;
        justify-content: center;
        }
      }
    }
    .s-container0 {
      flex-direction: column-reverse !important;
      .change-button {
        width: 100%;
        button {
          width: 100%;
        }
      }
      .save-button {
        width: 100% !important;
        button {
          width: 100% !important;
          margin: 0px;
          margin-bottom: 24px !important;
        }
      }
    }
  }
  .error-message {
    color: #ef6a6a;
    font-size: 12px;
  }
`;

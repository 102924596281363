import React, { useState } from 'react'
import {
  AutoComplete,
} from 'components/common'
import {
  callGetApiWithAuth
} from 'utils/api'
import styled from 'styled-components'

export default function PlacementTd(props) {
  const [placementOptions, setPlacementOptions] = useState([])

  const onSearchDone = (res) => {
    if (res.data) {
      setPlacementOptions([
        res.data
      ])
    }    
  }
  const onSearchPlacement = (v) => {
    callGetApiWithAuth(`my_team/holding_tank/search_placement/${v}`, onSearchDone)
  }

  const onSelectPlacement = (_, option) =>{
    let placement0 = null
    placementOptions.forEach(el => {
      if (el.id==option.key) {
        placement0 = el
      }
    })
    let tableData0 = [
      ...props.tableData
    ]
    tableData0[props.index] = {
      ...props.data,
      placement: placement0
    }
    props.setTableData(tableData0)
  }

  return (
    <Wrapper className='d-flex align-itmes-center'
      style={{width: 200}}
    >
      {(props.data.pos_type && 
        props.data.pos_type=='placement'
      ) && 
        <AutoComplete 
          onChange={onSearchPlacement}
          options={placementOptions.map(el => ({
            key: el.id,
            label: `${el.first_name} ${el.last_name}`,
            value: `${el.first_name} ${el.last_name}`,
          }))}
          style={{width: 250}}
          onSelect={onSelectPlacement}
          placeholder={'Search by User ID'}
        />
      }      
    </Wrapper>    
  )
}

const Wrapper = styled.div`
width:200px;
.ant-select-selector {
  border: 1px solid rgba(145,158,171,0.32) !important;
  border-radius: 8px !important;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 10px !important;
  height: 48px !important;
}
`
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Row, Col,
  Alert, message,
  SuccessNotification,
  Input, Radio, Select,
  Popconfirm, Button,
} from "components/common";
import { callPutApiWithAuth } from "utils/api";
import { countryStates, statesByCountry } from "common/country";

export default function KeBatchOptionCard({
  isActive,
  provider,
  loadListData,
}) {
  const sudoName = useSelector((state) => state.auth.sudo_name);
  const myUser = useSelector((state) => state.auth.user);
  const [formData, setFormData] = useState({
    phone: '',
    channel: '',
  });
  const [isUpdating, setIsUpdating] = useState(false);

  const onFormInput = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const onSaveDone = () => {
    setIsUpdating(false);
    SuccessNotification("Kenya Payout has been set as your payout provider.");
    loadListData();
  };

  const handleSave = () => {
    // check validation
    if (!formData.phone || 
      !formData.channel
    ) {
      message.error("Input all required fields");
      return;
    }
    if (formData.phone) {
      var phoneRegx = /254[0-9]{9}$/;
      if (phoneRegx.test(formData.phone) === false) {
        message.error("Invalid phone number");
        return;
      }
    }

    setIsUpdating(true);
    const body = {
      form_info: {
        ...formData,
      },
      sudo_name: sudoName,
    }
    callPutApiWithAuth(
      `my_account/payment_option/${provider.id}/activate`,
      body,
      onSaveDone,
      () => setIsUpdating(false)
    );
  };

  useEffect(() => {
    if (provider && myUser) {
      if (provider.form_info) {
        setFormData({
          ...formData,
          ...provider.form_info,
        });
      } else {
        // setFormData({
        //   ...formData,
        //   phone: myUser.phone,
        // });
        // if (myUser.shipping_details.length > 0) {
        //   const shippingDetail = myUser.shipping_details[0];
          
        // } else if (myUser.billing_details.length > 0) {
        //   const billingDetail = myUser.billing_details[0];
        //   setFormData({
        //     ...formData,
        //     phone: myUser.phone,
        //     country: billingDetail.billing_country || "",
        //     state: billingDetail.billing_state || "",
        //     city: billingDetail.billing_city || "",
        //     address: billingDetail.billing_address || "",
        //     address_line2: billingDetail.billing_address_line2 || "",
        //     zipcode: billingDetail.billing_zipcode || "",
        //   });
        // }
      }
    }
  }, [provider, myUser]);

  return (
    <div className="payment-card-section">
      <div className="payment-title">
        <span>Direct Deposit - Kenya Only</span>
        {isActive && <span className="active-badge">ACTIVE</span>}
      </div>
      <div className="payment-form-text" style={{ marginBottom: 10 }}>
        Please provide your bank account information.
      </div>
      <div className="payment-form">
        <Row gutter={[12, 8]}>
          <Col xs={24} sm={8}>
            <div className="form-group">
              <Select
                label="Mobile Money Channel*"
                value={formData.channel}
                onChange={(v) => onFormInput("channel", v)}
                options={[
                  { label: '', value: '' },
                  { label: 'mpesa', value: 'mpesa' },
                  { label: 'airtelmoney', value: 'airtelmoney' },
                ]}
              />
            </div>
          </Col>
          <Col xs={24} sm={8}>
            <div className="form-group">
              <Input
                label="Phone*"
                value={formData.phone}
                onChange={(e) => onFormInput("phone", e.target.value)}
              />
              <p>Format: 254XXXXXXXXX</p>
            </div>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col xs={24}>
            <div style={{ height: 24 }} />
            <div className="action-group">
              <Popconfirm onConfirm={handleSave}>
                <button loading={isUpdating}>
                  {provider.account ? `Activate` : `Save & Activate`}
                </button>
              </Popconfirm>
            </div>
          </Col>
        </Row>
      </div>
      <div className="alert-container-section">
        <div className="alert-section">
          <Alert
            style={{ margin: "5px 0px 5px", alignItems: "baseline" }}
            message={`Please note: Make sure ALL of your information is 100% accurate. If you input the wrong account information, your commissions may get sent to the wrong bank and we will be unable to recover your funds. If you decide to receive your funds in KES, your commissions will be sent at a 150 KES to $1 USD exchange rate. Please allow 5-7 business days to receive your funds.`}
            type={"info"}
            showIcon
          />{" "}
        </div>
      </div>
    </div>
  );
}

import styled from "styled-components";

export const Wrapper = styled.div`
  .label-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      color: #212b36;
    }
    .add-container {
      display: flex;
      align-items: center;
      .select-box {
        .ant-input {
          width: 256px;
          margin: 0px 10px;
        }
        .ant-select {
          width: 130px;
          .ant-select-selector {
            height: 34px;
          }
        }
      }
      .icon-span {
        width: auto;
        border: none;
        background: #1ea9ea;
        border-radius: 8px;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #ffffff;
        padding: 8px 25px;
        svg {
          width: 15px;
          margin-right: 16px;
        }
      }
    }
  }
  .my-account-checking-no-data {
    width: 100%;
    height: calc(100vh - 250px);
  }
  @media (max-width: 1600px) {
    .contact-table {
      .ant-table {
        overflow-x: scroll;
        .ant-table-container {
          width: 1000px;
        }
      }
    }
  }
  .checking-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100% - 70px);
    svg {
      max-width: 100%;
    }
  }
  .comment-icons {
    display: flex;
    align-items: center;
    > span {
      font-size: 22px;
      cursor: pointer;
    }
    .edit-btn {
      svg {
        path {
          fill: #36b37e;
        }
      }
      &:hover {
        color: ${(props) => props.theme.palette.primary};
      }
    }
    .delete-btn {
      margin-left: 20px;
      svg {
        path {
          fill: #ff5630;
        }
      }
      &:hover {
        color: ${(props) => props.theme.palette.error};
      }
    }
    .active-btn {
      margin-right: 20px;
      svg {
        path {
          fill: #ffab00;
        }
      }
      &:hover {
        color: ${(props) => props.theme.palette.success};
      }
    }
    .actived-btn {
      margin-right: 20px;
      color: ${(props) => props.theme.palette.success};
    }
  }
  .no-text {
    text-align: center;
    color: #00b4ee;
    font-size: 24px;
  }
  .panelHead {
    display: none;
  }
  @media (max-width: 1600px) {
    .ant-table {
      overflow: auto;
      .ant-table-container {
        width: 1200px !important;
      }
    }
  }
  @media (max-width: 500px) {
    .label-header-container {
      .title {
        font-size: 15px !important;
      }
      .icon-span {
        padding: 8px 15px !important;
      }
    }
  }
`;

import React, { useState, useEffect } from "react";
import copy from 'copy-to-clipboard';
import {
  Row, Col, Modal,
  Button, Popconfirm,
  FormItem, Input, Select,
  SuccessNotification,
  message,
} from "components/common";
import { varIs } from "common/var";
import { callGetApiWithAuth, callPostApiWithAuth } from "utils/api";
import { asPrice } from "utils/text";
import { Wrapper } from "./AddModal.styled";
import { ReactComponent as AddIcon } from "assets/icons/addRound.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/deleteRed.svg";

export default function AddModal(props) {
  const [formData, setFormData] = useState({
    title: "",
  });
  const [details, setDetails] = useState([
    {
      product: undefined,
      product_id: "",
      quantity: 1,
      pay_cycle: 0,
    },
  ]);
  const [productOptions, setProductOptions] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);

  const onGetProductOptions = (res) => {
    setProductOptions(res.data);
  };
  const loadProductOptions = () => {
    callGetApiWithAuth(`my_website/share_cart/products`, onGetProductOptions);
  };

  const addRow = () => {
    const details0 = [...details];
    details0.push({
      product: undefined,
      product_id: "",
      quantity: 1,
      pay_cycle: 0,
      contained_products: [],
    });
    setDetails(details0);
  };

  const removeRow = (index) => {
    const details0 = details.filter((_, index0) => index != index0);
    setDetails(details0);
  };

  const onDetailInput = (index, field, value) => {
    let details0 = [...details];
    details0[index][field] = value;
    if (field === "product_id") {
      let product = undefined;
      productOptions.forEach((el) => {
        if (el.id === value) {
          product = el;
        }
      });
      details0[index]["product"] = product;
      if (product && !varIs("product.type", product.type, "physical")) {
        details0[index]["quantity"] = 1;
      }
      if (varIs("product.isPack", product.is_pack, "yes")) {
        details0[index]["contained_products"] =
          product.product_pack.pack_contained_products.map((el) => ({
            product_id: el.product_id,
            quantity: el.fixed_quantity,
          }));
      }
    }
    setDetails(details0);
  };

  const onSubmitDone = (res) => {
    setIsUpdating(false);
    SuccessNotification(res.message);
    copy(`${process.env.REACT_APP_CORP_URL}/share-cart?h=${res.data.hash_code}`);
    props.setShouldLoad(true);
    props.setModalMode(undefined);
  };
  const onSubmitFail = () => {
    setIsUpdating(false);
  };
  const handleSubmit = () => {
    // Validation
    if (!formData.title) {
      message.error("Please input title");
      return;
    }
    for (let el of details) {
      if (!el.product) {
        message.error("Please select product");
        return;
      }
      if (!isNaN(el.quantity) && el.quantity <= 0) {
        message.error("Please input qty greater than zero");
        return;
      }
      if (
        varIs(
          "product.purchaseType",
          el.product.purchase_type,
          "subscription"
        ) &&
        (!el.pay_cycle || el.pay_cycle === 0)
      ) {
        message.error("Please select frequency");
        return;
      }
    }

    setIsUpdating(true);
    const body = {
      ...formData,
      details,
    };
    callPostApiWithAuth(
      `my_website/share_cart`,
      body,
      onSubmitDone,
      onSubmitFail
    );
  };

  useEffect(() => {
    loadProductOptions();
  }, []);

  return (
    <Modal
      width={900}
      onCancel={() => props.setModalMode(undefined)}
      closable={false}
    >
      <Wrapper>
        <div className="modalHeader">Create Shareable Cart</div>
        <Row gutter={[24, 24]}>
          <Col xs={24} xl={10}>
            <div className="input-container">
              <FormItem>
                <Input
                  value={formData.title}
                  label={"Cart name"}
                  onChange={(e) =>
                    setFormData({ ...formData, title: e.target.value })
                  }
                />
              </FormItem>
            </div>
          </Col>
          <Col xs={24}>
            {details.map((el, index) => (
              <Row gutter={[15, 15]} className="product-row">
                <Col xs={24} sm={12} xl={10}>
                  <div className="input-container">
                    <FormItem>
                      <Select
                        label={"Product"}
                        value={el.product_id}
                        onChange={(v) => onDetailInput(index, "product_id", v)}
                        options={productOptions.map((el2) => ({
                          label: el2.title,
                          value: el2.id,
                          key: el2.id,
                        }))}
                        style={{ width: "100%" }}
                        showSearch
                        optionFilterProp="label"
                      />
                    </FormItem>
                  </div>
                </Col>
                <Col xs={12} sm={6} xl={3}>
                  <div className="input-container">
                    <FormItem>
                      <Input
                        label={"Unit Price"}
                        value={asPrice(
                          el.product
                            ? el.pay_cycle === 0
                              ? el.product.member_price
                              : el.product.recurring_price
                            : 0
                        )}
                        disabled
                      />
                    </FormItem>
                  </div>
                </Col>{" "}
                <Col xs={12} sm={6} xl={3}>
                  <div className="input-container">
                    <FormItem>
                      <Input
                        label={"Quantity"}
                        type="number"
                        value={el.quantity}
                        onChange={(e) =>
                          onDetailInput(index, "quantity", e.target.value)
                        }
                        disabled={
                          details &&
                          details.length > 0 &&
                          details[index] &&
                          details[index].product &&
                          !varIs(
                            "product.type",
                            details[index].product.type,
                            "physical"
                          )
                            ? true
                            : false
                        }
                      />
                    </FormItem>
                  </div>
                </Col>
                <Col xs={18} sm={20} xl={6}>
                  <div className="input-container">
                    <FormItem>
                      <Select
                        label={"Subscription Frequency"}
                        value={el.pay_cycle * 1}
                        options={
                          el.product &&
                          !varIs(
                            "product.purchaseType",
                            el.product.purchase_type,
                            "onetime"
                          )
                            ? [
                                { label: "None", value: 0 },
                                ...el.product.pay_cycles.map((el2) => ({
                                  label:
                                    el2 * 1 === 1
                                      ? "Every Year"
                                      : el2 * 1 === 30
                                      ? "Every Month"
                                      : el2 * 1 === 60
                                      ? "Every 2 Months"
                                      : el2 * 1 === 90
                                      ? "Every 3 Months"
                                      : "",
                                  value: el2 * 1,
                                })),
                              ]
                            : [{ label: "None", value: 0 }]
                        }
                        onChange={(v) => onDetailInput(index, "pay_cycle", v)}
                        style={{ width: "100%" }}
                      />
                    </FormItem>
                  </div>
                </Col>
                <Col xs={6} sm={4} xl={2}>
                  <div className="icon-container">
                    {index === details.length - 1 ? (
                      <>
                        <DeleteIcon onClick={() => removeRow(index)} />
                        <AddIcon onClick={() => addRow()} />
                      </>
                    ) : (
                      <DeleteIcon onClick={() => removeRow(index)} />
                    )}
                  </div>
                </Col>
              </Row>
            ))}
          </Col>{" "}
          <Col span={24}>
            <div className="action-btn">
              <Button bgColor="#fff" border color="#212B36" onClick={() => props.setModalMode(undefined)}>Cancel</Button>
              <Popconfirm onConfirm={handleSubmit}>
                <Button loading={isUpdating}>Create and Copy Url</Button>
              </Popconfirm>
            </div>
          </Col>
        </Row>
      </Wrapper>
    </Modal>
  );
}

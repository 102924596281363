import styled from "styled-components";

export const Wrapper = styled.div`
  box-shadow: 0px 0px 2px rgb(145 158 171 / 20%),
    0px 12px 24px -4px rgb(145 158 171 / 12%);
  border-radius: 16px;
  padding: 24px;
  height: 100%;
  .p-image-container {
    display: flex;
    align-items: center;
  }
  .sales-title-container {
    display: flex;
    margin-bottom: 24px;
    align-items: center;
    justify-content: space-between;
    .sales-title {
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      color: #212b36;
    }
    .sales-date {
      font-weight: normal;
      font-size: 14px;
      color: #7f8fa3;
    }
    .type-label {
      cursor: pointer;
      background: rgba(145, 158, 171, 0.16);
      border-radius: 14px;
      padding: 5px 10px;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #637381;
      margin-left: 12px;
      &.active {
        background: #1ea9ea;
        color: #ffffff;
      }
    }
  }
  .divider {
    height: 1px;
    background: #eff3f6;
    margin: 12px 0px 20px;
  }
  .ant-select {
    width: 170px;
  }
  .ant-select-selector {
    height: 40px !important;
    .ant-select-selection-item {
      position: relative;
      top: -2px;
    }
  }
  .sales-product-container {
    .ant-row {
      height: 100%;
    }
    .product-image {
      width: 66px;
      height: 66px;
      border-radius: 12px;
      margin-right: 16px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 12px;
      }
    }
    .label-container {
      .label {
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: #212b36;
      }
      .brand-container {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #637381;
        span {
          color: #1ea9ea;
        }
      }
      .price-container {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #637381;
        .price {
          font-weight: 600;
          font-size: 14px;
          line-height: 22px;
          color: #1ea9ea;
        }
        .subscription {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #637381;
        }
      }
    }
    .product-price-container {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: #212b36;
    }
  }
  @media (max-width: 600px) {
    .mbl-responsive-products {
      overflow: auto;
      .mbl-responsive-list {
        width: 600px;
      }
    }
  }
  @media (max-width: 576px) {
    .sales-title-container {
      /*
      flex-direction: column;
      */
      .sales-title {
        width: 100%;
      }
      .sales-date {
        width: 100%;
        /*
        text-align: start;
        margin-top: 24px;
        */
        .type-label {
          margin-left: 0px;
          margin-right: 12px;
        }
      }
    }
  }
`;

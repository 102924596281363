import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  Input, Switch,
  Button, Popconfirm,
  SuccessNotification,
  Alert,
} from "components/common";
import { callPutApiWithAuth } from "utils/api";
import {
  FaFacebookMessenger,
  FaTelegramPlane,
  FaWhatsapp,
} from "react-icons/fa";
import { ReactComponent as FacebookIcon } from "assets/icons/account/facebook.svg";
import { ReactComponent as TelegramIcon } from "assets/icons/account/telegram.svg";
import { ReactComponent as WhatsappIcon } from "assets/icons/account/whatsapp.svg";

export default function SocialMessengerSubPage() {
  const myUser = useSelector((state) => state.auth.user);
  const [formData, setFormData] = useState({
    facebook_link: "",
    facebook_enabled: 1,
    telegram_link: "",
    telegram_enabled: 1,
    whatsapp_link: "",
    whatsapp_enabled: 1,
  });
  const [errorMessages, setErrorMessages] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);

  const onFormInput = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const onFormCheck = (field, checked) => {
    setFormData({
      ...formData,
      [field]: checked ? 1 : 2,
    });
  };

  const onSubmitDone = (res) => {
    setIsUpdating(false);
    SuccessNotification(res.message);
  };
  const onSubmitFail = () => {
    setIsUpdating(false);
  };
  const handleSubmit = () => {
    setIsUpdating(true);
    const body = {
      ...formData,
    };
    callPutApiWithAuth(
      `my_account/social_messenger`,
      body,
      onSubmitDone,
      onSubmitFail
    );
  };

  useEffect(() => {
    if (myUser && myUser.social_messenger) {
      setFormData(myUser.social_messenger);
    }
  }, [myUser]);

  return (
    <Wrapper>
      <div className="form-root">
        <h2>Social Messenger</h2>
        <Alert
          message={
          <span>
            Please copy and paste your Facebook Messenger, WhatsApp, and Telegram contact URLs so your upline can contact you on these channels.
          </span>}
          type={"info"}
          showIcon
        />
        <div style={{ height: 16 }} />
        <div className="form-group">
          <div className="form-input">
            <Switch
              checked={formData.facebook_enabled * 1 === 1}
              onChange={(chk) => onFormCheck("facebook_enabled", chk)}
              className="form-switch"
            />
            <div className="switch-input">
              <Input
                value={formData.facebook_link}
                onChange={(e) => onFormInput("facebook_link", e.target.value)}
                inputPrefix={<FacebookIcon className="connect-social-icon" />}
                placeholder={"https://m.me/USERNAME"}
              />
            </div>
          </div>
          <div className="input-error">
            {errorMessages
              .filter((el) => el.field === "facebook_link")
              .map((el) => (
                <p>{el.message}</p>
              ))}
          </div>
        </div>
        <div className="form-group">
          <div className="form-input">
            <Switch
              checked={formData.telegram_enabled * 1 === 1}
              onChange={(chk) => onFormCheck("telegram_enabled", chk)}
              className="form-switch"
            />
            <div className="switch-input">
              <Input
                value={formData.telegram_link}
                onChange={(e) => onFormInput("telegram_link", e.target.value)}
                inputPrefix={<TelegramIcon className="connect-social-icon" />}
                placeholder={"https://t.me/USERNAME"}
              />
            </div>
          </div>
          <div className="input-error">
            {errorMessages
              .filter((el) => el.field === "telegram_link")
              .map((el) => (
                <p>{el.message}</p>
              ))}
          </div>
        </div>
        <div className="form-group">
          <div className="form-input">
            <Switch
              checked={formData.whatsapp_enabled * 1 === 1}
              onChange={(chk) => onFormCheck("whatsapp_enabled", chk)}
              className="form-switch"
            />
            <div className="switch-input">
              <Input
                value={formData.whatsapp_link}
                onChange={(e) => onFormInput("whatsapp_link", e.target.value)}
                inputPrefix={<WhatsappIcon className="connect-social-icon" />}
                placeholder={"https://wa.me/PHONENUMBER"}
              />
            </div>
          </div>
          <div className="input-error">
            {errorMessages
              .filter((el) => el.field === "whatsapp_link")
              .map((el) => (
                <p>{el.message}</p>
              ))}
          </div>
        </div>
        <div className="form-action">
          <Popconfirm onConfirm={handleSubmit}>
            <button loading={isUpdating}>Submit</button>
          </Popconfirm>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  box-shadow: 0px 0px 2px rgb(145 158 171 / 20%),
    0px 12px 24px -4px rgb(145 158 171 / 12%);
  border-radius: 16px;
  padding: 24px;
  h2 {
    font-weight: 500;
    font-size: 16px;
  }
  .form-group {
    margin: 0px 0 16px;
  }
  .form-input {
    display: flex;
    align-items: center;
    .ant-input {
      width: 500px;
    }
  }
  .form-action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 12px;
    button {
      background: #1ea9ea;
      border-radius: 8px;
      width: auto;
      height: 48px;
      padding: 0px 22px;
      border: none;
      color: white;
    }
  }
  .ant-switch {
    margin-right: 16px;
  }
  .ant-switch-checked {
    background-color: #00ab55;
  }
  @media (max-width: 700px) {
    .switch-input {
      width: calc(100% - 62px);
      div, input {
        width: 100% !important;
      }
    }
    .form-action{
      button{
        width: 100% !important;
      }
    }
  }
`;

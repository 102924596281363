import styled from "styled-components";

export const Wrapper = styled.div`
  .main-container {
    height: 100%;
    padding-bottom: 80px;
  }
  .top-card-section {
    background: #cafdf5;
    border-radius: 8px;
    border: none;
    padding: 16px;
    display: flex;
    align-items: flex-start;
    svg {
      margin-right: 11px;
    }
    .card-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #003768;
      margin-bottom: 0px;
    }
    .card-description {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #003768;
    }
  }
  .payment-card-section {
    box-shadow: 0px 0px 2px rgb(145 158 171 / 20%),
      0px 12px 24px -4px rgb(145 158 171 / 12%);
    border-radius: 16px;
    padding: 24px;
    margin-top: 24px;
    .payment-title {
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      color: #212b36;
      display: flex;
      align-items: center;
      margin-bottom: 24px;
    }
    .payment-description {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #637381;
      margin-top: 10px;
      .disclaimer {
        color: #ff5050;
      }
      .highlight {
        color: #00b4ee;
      }
    }
    .payment-radio {
      display: flex;
      align-items: flex-start;
      margin-top: 10px;
      font-weight: 500;
      font-size: 14px;
    }
    .payment-form-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #637381;
    }
    .payment-highlight-text {
      font-weight: 500;
      font-size: 14px;
      color: #00b4ee;
      margin-top: 20px;
    }
  }
  .payment-form {
    width: 100%;
    .form-group {
      margin-bottom: 10px;
    }
    .input-label {
      font-weight: 500;
      font-size: 14px;
      color: #7f8fa3;
      margin-bottom: 2px;
    }
    .ant-select {
      width: 100%;
    }
    .ant-select-selector {
      height: 34px;
    }
    .ant-picker {
      width: 100% !important;
    }
    button {
      min-width: 100px;
    }
    .action-group {
      margin: 0px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 24px;
      button {
        background: #1ea9ea;
        border-radius: 8px;
        border: none;
        height: 48px;
        color: white;
        padding: 0px 17px;
      }
    }
    .action-grp-new {
      justify-content: flex-start !important;
    }
  }
  .inp-btn {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    button {
      background: #1ea9ea;
      border-radius: 8px;
      border: none;
      height: 40px;
      color: white;
      padding: 0px 17px;
      height: 48px;
      margin-left: 10px;
    }
  }
  .reg-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-decoration-line: underline;
    color: #1ea9ea;
  }
  .withdraws-tag {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    span {
      font-weight: 700;
      font-size: 15px;
      line-height: 26px;
      padding: 11px 22px;
      border-radius: 8px;
      margin-bottom: 8px;
      margin-right: 8px;
    }
    .t1 {
      background: rgba(145, 158, 171, 0.08);
      color: #212b36;
    }
    .t2 {
      background: rgba(0, 171, 85, 0.16);
      color: #007b55;
    }
    .t3 {
      background: rgba(51, 102, 255, 0.16);
      color: #1939b7;
    }
    .t4 {
      background: rgba(0, 184, 217, 0.16);
      color: #006c9c;
    }
    .t5 {
      background: rgba(54, 179, 126, 0.16);
      color: #1b806a;
    }
    .t6 {
      background: rgba(255, 171, 0, 0.16);
      color: #b76e00;
    }
    .t7 {
      background: rgba(255, 86, 48, 0.16);
      color: #b71d18;
    }
  }
  .active-badge {
    background-color: ${(props) => props.theme.palette.success};
    color: white;
    font-size: 12px;
    font-weight: bold;
    margin-left: 12px;
    padding: 4px 8px;
    border-radius: 4px;
  }
  .form-validation {
    font-size: 12px;
    margin-bottom: 4px;
    &.valid-success {
      color: ${(props) => props.theme.palette.success};
    }
    &.valid-error {
      color: ${(props) => props.theme.palette.error};
    }
  }
  @media (max-width: 1300px) {
    .payment-card-section {
      padding: 15px 24px 15px 24px !important;
    }
    .payment-form {
      width: 100%;
    }
  }
  .dob-input {
    width: 100% !important;
    height: 48px !important;
  }
  .dob-outlined {
    .ant-picker {
      padding: 0px;
      .ant-picker-input {
        border: 1px solid rgba(145, 158, 171, 0.32);
        border-radius: 8px !important;
        height: 48px !important;
        padding: 0px 14px;
      }
    }
    .ant-picker-clear {
      right: 12px !important;
    }
    position: relative;
    .ant-input {
      height: 48px !important;
      border: 1px solid rgba(145, 158, 171, 0.32);
      border-radius: 8px !important;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #212b36;
      padding: 12px 14px;
    }
    .ant-input-affix-wrapper.ant-input-affix-wrapper-outlined {
      border-radius: 4px;
      position: relative;
    }

    .ant-input-affix-wrapper.ant-input-affix-wrapper-outlined
      .ant-input:not(:first-child) {
      border-radius: 4px;
      padding-top: 26px;
    }

    .ant-input-affix-wrapper.ant-input-affix-wrapper-outlined .ant-input:focus,
    .ant-input-affix-wrapper.ant-input-affix-wrapper-outlined
      .ant-input-focused {
      border-color: #1890ff;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }

    .ant-input-affix-wrapper.ant-input-affix-wrapper-outlined .ant-input:hover {
      border-color: #40a9ff;
    }

    .ant-input-affix-wrapper.ant-input-affix-wrapper-outlined
      .ant-input:not(:first-child)::placeholder {
      opacity: 0.6;
    }

    .ant-input-affix-wrapper.ant-input-affix-wrapper-outlined
      .ant-input:not(:first-child):focus::placeholder,
    .ant-input-affix-wrapper.ant-input-affix-wrapper-outlined
      .ant-input-focused::placeholder {
      opacity: 0.4;
    }

    .ant-form-item-label {
      position: absolute;
      top: 12px;
      left: 14px;
      transition: all 0.2s ease-out;
      pointer-events: none;
      color: #919eab;
    }

    .ant-form-item-label-active {
      transform: translateY(-20px) scale(1);
      font-weight: 400;
      font-size: 12px;
      color: #919eab;
      background: white;
      z-index: 4;
      padding: 0px 1px;
    }
  }
  .alert-container-section {
    margin-top: 24px;
    margin-bottom: -10px;
  }
  .alert-section {
    margin-bottom: 10px;
  }
  @media (max-width: 700px) {
    .inp-btn {
      flex-direction: column !important;
      button {
        margin: 24px 0px 0px 0px !important;
        width: 100% !important;
      }
      div {
        width: 100% !important;
      }
    }
    .ant-input {
      width: 100% !important;
    }
    .action-group {
      button {
        width: 100% !important;
      }
    }
  }
`;

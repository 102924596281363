import React, { useState } from "react";
import styled from "styled-components";
import { Row, Col, Card, Tabs } from "components/common";
import StatView from "./StatView";
import TableView from "./TableView";
import { ReactComponent as LogoIcon } from "assets/icons/new-sidebar/my-daily-web-logo.svg";
import MaPromoImg from "assets/images/maPromo.png";

const { TabPane } = Tabs;

export default function CustomerMasteryPage() {
  const [viewMode, setViewMode] = useState("Current Month");
  console.log("viewModeviewMode", viewMode);

  return (
    <Wrapper>
      <div className="subscriptions-tabs">
        <Tabs activeKey={viewMode} onChange={(e) => setViewMode(e)}>
          <TabPane
            tab={
              <span
                className={
                  viewMode == "Current Month" ? "active-tab" : "inactive-tab"
                }
              >
                Current Month
              </span>
            }
            key="Current Month"
          />
          <TabPane
            tab={
              <span
                className={
                  viewMode == "Last Month" ? "active-tab" : "inactive-tab"
                }
              >
                Last Month
              </span>
            }
            key="Last Month"
          />
        </Tabs>
      </div>
      <div className="mastery-content-container">
        <div className="white-rounded-bg">
          <h4 className={"maTitle"}>Customer Mastery Bonus </h4>
          <p className={"maDesc"}>
            Master the art of Customer Acquisition and Customer Retention in
            your organization to earn up to $20,000 in customer mastery bonuses.
            Customers are the driving force of your business and what better
            time than now to introduce MDC products to the world and create
            additional bonuses!
          </p>
          <StatView viewMode={viewMode} />
        </div>
        <TableView viewMode={viewMode} />
        <Row className={"promoRoot"}>
          <Col xs={24} sm={10}>
            <img src={MaPromoImg} className={"promoImage"} />
          </Col>
          <Col xs={24} sm={13}>
            <div className={"promoContent"}>
              <LogoIcon />
              <h4 className={"promoTitle"}>Disclaimer:</h4>
              <p>
                To earn the Customer Mastery Bonus Affiliates must be
                active and in good standing with the company to participate.
                Bonuses shown in USD. “Bonus Buying” is not allowed in this or
                any other MDC promotion. Accounts must be valid with a complete
                account information to qualify and are subject to verification.
                Accounts with duplicate email addresses, names, or other
                identifiable information will be disqualified. Affiliates may
                count 75% of the total customer volume from each leg in the
                Enroller Tree towards this bonus.
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-bottom: 80px;
  .subscriptions-tabs {
    margin: -24px -40px 24px;
    background: rgba(145, 158, 171, 0.08);
    padding: 24px 40px 0px;
    @media (max-width: 900px) {
      margin: -24px -20px 24px;
      padding: 24px 20px 0px;
    }
    .ant-tabs-nav {
      margin-bottom: 0px;
      margin-top: 0px;
    }
    .ant-tabs-tab-active {
      padding: 12px;
      background: white;
      border-radius: 8px 8px 0px 0px;
    }
    .active-tab {
      font-weight: 600 !important;
      font-size: 15px !important;
      line-height: 24px !important;
      color: #1ea9ea !important;
    }
    .inactive-tab {
      font-weight: 600 !important;
      font-size: 15px !important;
      line-height: 24px !important;
      color: #637381 !important;
    }
    .ant-tabs-ink-bar {
      background: transparent !important;
    }
  }
  .mastery-content-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .maTitle {
    font-weight: 700;
    font-size: 18px;
    color: #212b36;
    margin-bottom: 2px;
  }
  .maDesc {
    font-size: 14px;
    line-height: 22px;
    color: #212b36;
    margin-bottom: 24px;
  }

  .panelRoot {
    margin-bottom: 0px !important;
    .panelHead {
      padding: 24px;
      h4 {
        line-height: 1.2 !important;
      }
    }
    .table-new-wrapper {
      .ant-table-container {
        @media (max-width: 1350px) {
          width: 800px;
        }
      }
      overflow-x: auto;
      border-top-left-radius: 0px !important;
      border-top-right-radius: 0px !important;
      .ant-table {
        border-radius: 0px !important;
      }
      .ant-table-content {
        thead {
          th:first-child,
          th:last-child {
            border-radius: 0px !important;
          }
        }
      }
    }
  }

  .promoRoot {
    display: flex;
    padding: 24px;
    gap: 24px;
    background: rgba(30, 169, 234, 0.2);
    border-radius: 16px;
    .promoImage {
      width: 100%;
      border-radius: 8px;
    }
    .promoContent {
      .promoTitle {
        font-weight: 700;
        font-size: 22px;
        color: #003768;
        margin: 24px 0px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        color: #003768;
      }
    }
  }
`;

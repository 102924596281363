import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Alert, Spin } from "components/common";
import { callGetApiWithAuth } from 'utils/api'

export default function AlertSection() {
  const [lastPvDate, setLastPvDate] = useState(undefined)
  const [lastQvDate, setLastQvDate] = useState(undefined)
  const [isLoading, setIsLoading] = useState(false)

  const onGetData = (res) => {
    setIsLoading(false)
    setLastPvDate(res.data.pv_date)
    setLastQvDate(res.data.qv_date)
  }
  const onFailData = () => {
    setIsLoading(false)
  }

  useEffect(() => {
    setIsLoading(true)
    callGetApiWithAuth(`dashboard/pv_date`, onGetData, onFailData)
  }, [])

  return (
    <Wrapper>
      {isLoading ? 
        <div clasName='loadingRoot'>
          <Spin spinning={true} />
        </div>
      : (lastPvDate && lastQvDate)?
        <Alert
          type="info"
          message={
            <>
              PV, PECV, Enroller Tree Volume, and Qualified Volume updates every hour (Last Updated At: {lastPvDate} PST)<br/>
              Binary BV and Rank Qualification Last Updated At: {lastQvDate} PST
            </>
          }
          showIcon
          style={{ margin: "5px 0px 5px", alignItems: 'baseline' }}
        />
      : ''}
    </Wrapper>
  )
}

const Wrapper = styled.div`  
  .loadingRoot {
    display: flex;
    width: 100%;
    height: 200px;
    justify-content: center;
    align-items: center;
  }
`

import React, { useState } from "react";
import styled from "styled-components";
import {
  Modal,
  FormItem,
  Input,
  Button,
  Popconfirm,
  SuccessNotification,
  message,
} from "components/common";
import { callPutApiWithAuth } from "utils/api";

export default function PasswordModal(props) {
  const [formData, setFormData] = useState({
    old_password: "",
    password: "",
    passwordConfirm: "",
  });
  const [isUpdating, setIsUpdating] = useState(false);

  const onUpdateDone = (res) => {
    setIsUpdating(false);
    SuccessNotification(res.message);
    props.setModalMode(undefined);
  };
  const onUpdateFail = () => {
    setIsUpdating(false);
  };
  const handleUpdate = () => {
    // Validation
    if (
      !formData.old_password ||
      !formData.password ||
      !formData.passwordConfirm
    ) {
      message.error("Please input all required fields");
      return;
    }
    if (formData.password != formData.passwordConfirm) {
      message.error("Please input password same as confirm");
      return;
    }
    const regex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/;
    if (regex.exec(formData.password) == null) {
      message.error(
        "Password should contain uppercase, lowercase, numeric, special characters and longer than 8 characters."
      );
      return;
    }

    setIsUpdating(true);
    callPutApiWithAuth(
      `my_account/setting/password`,
      formData,
      onUpdateDone,
      onUpdateFail
    );
  };

  return (
    <Modal onCancel={() => props.setModalMode(undefined)}>
      <Wrapper>
        <div className="modal-new-title">Change Password</div>
        <FormItem>
          <Input
            label={"Current Password*"}
            type="password"
            value={formData.old_password}
            onChange={(e) =>
              setFormData({ ...formData, old_password: e.target.value })
            }
          />
        </FormItem>
        <FormItem>
          <Input
            type="password"
            label={"New Password*"}
            value={formData.password}
            onChange={(e) =>
              setFormData({ ...formData, password: e.target.value })
            }
          />
        </FormItem>
        <FormItem>
          <Input
            type="password"
            label={"New Password(Confirm)*"}
            value={formData.passwordConfirm}
            onChange={(e) =>
              setFormData({ ...formData, passwordConfirm: e.target.value })
            }
          />
        </FormItem>
        <div className="action-btn">
          <Button
            className="cancel-btn"
            onClick={() => props.setModalMode(undefined)}
          >
            Cancel
          </Button>
          <Popconfirm onConfirm={handleUpdate}>
            <Button loading={isUpdating} className="create-btn">
              Update
            </Button>
          </Popconfirm>
        </div>
      </Wrapper>
    </Modal>
  );
}

const Wrapper = styled.div`
  .modal-new-title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #212b36;
    margin-bottom: 20px;
  }
  .label {
    display: none;
  }
  .ant-input,
  .ant-select-selector {
    height: 40px !important;
  }
  .action-btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .cancel-btn {
      border-radius: 8px !important;
      height: 40px;
      padding: 0px 15px;
      margin-left: 12px;
      border: 1px solid rgba(145, 158, 171, 0.32);
      background: white;
      color: black;
    }
    .create-btn {
      background: #1ea9ea;
      border-radius: 8px !important;
      height: 40px;
      padding: 0px 15px;
      margin-left: 12px;
    }
  }
  .ant-form-item-label {
    top: 8px;
  }
  .ant-form-item-label-active {
    transform: translateY(-16px) scale(1);
  }
  .form-group {
    margin-bottom: 18px;
  }
`;

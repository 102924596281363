import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Row, Col } from "components/common";
import { callGetApiWithAuth } from "utils/api";
import { asVolume, asUsdPrice } from "utils/text";
import TickIcon from "assets/icons/tick.svg";
import CustomerVolumeCard from "components/common/customerVolumeCard/CustomerVolumeCard";
import { ReactComponent as VolumeBlue } from "assets/icons/customer_volume/customerVolume_Blue.svg";
import { ReactComponent as VolumeRed } from "assets/icons/customer_volume/customerVolume_Red.svg";
import { ReactComponent as VolumeYellow } from "assets/icons/customer_volume/customerVolume_Yellow.svg";
import { ReactComponent as VolumeGreen } from "assets/icons/customer_volume/customerVolume_Green.svg";

export default function StatView(props) {
  const [bonusList, setBonusList] = useState([]);
  const [tcv, setTcv] = useState(0);
  const [qcv, setQcv] = useState(0);
  const [currentTier, setCurrentTier] = useState(0);
  const [nextTier, setNextTier] = useState(5000);

  const onGetStatData = (res) => {
    setBonusList(res.data.bonus_list);
    setTcv(res.data.tcv);
    setQcv(res.data.qcv);
    setCurrentTier(res.data.current_tier);
    setNextTier(res.data.next_tier);
  };
  const onFailStatData = () => {};
  const loadStatData = () => {
    callGetApiWithAuth(
      `report/customer_mastery/stats`,
      onGetStatData,
      onFailStatData
    );
  };

  const loadLastStatData = () => {
    callGetApiWithAuth(
      `report/customer_mastery/last_stats`,
      onGetStatData,
      onFailStatData
    );
  };

  useEffect(() => {
    if (props.viewMode === "Current Month") {
      loadStatData();
    } else if (props.viewMode === "Last Month") {
      loadLastStatData();
    }
  }, [props.viewMode]);

  return (
    <Wrapper>
      <div className="table-container">
        <table>
          <thead>
            {bonusList.map((item, index) => {
              return (
                <th key={index} align={"center"}>
                  {asVolume(item.qcv)}
                  <br />
                  Customer
                  <br />
                  Volume
                </th>
              );
            })}
          </thead>
          <tbody>
            <tr>
              {bonusList.map((item, index) => (
                <td key={index} align={"center"}>
                  {asUsdPrice(item.amount)}
                  {item.is_qualified && (
                    <img src={TickIcon} className={"tickIcon"} />
                  )}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
      <div className="customerVolumeCard">
        <Row gutter={[24, 24]}>
          <Col xs={24} xl={6}>
            <CustomerVolumeCard
              title="Total Customer Volume"
              value={asVolume(tcv)}
              icon={<VolumeBlue />}
            />
          </Col>
          <Col xs={24} xl={6}>
            <CustomerVolumeCard
              title="Qualified Customer Volume"
              value={asVolume(qcv)}
              icon={<VolumeYellow />}
            />
          </Col>
          <Col xs={24} xl={6}>
            <CustomerVolumeCard
              title="Current Tier"
              value={asVolume(currentTier)}
              icon={<VolumeGreen />}
            />
          </Col>
          <Col xs={24} xl={6}>
            <CustomerVolumeCard
              title="Next Tier"
              value={asVolume(nextTier)}
              icon={<VolumeRed />}
            />
          </Col>
        </Row>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .table-container {
    width: 100%;
    overflow-x: auto;
    table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0;
      @media (max-width: 1400px) {
        width: 1200px;
      }

      thead {
        th {
          :first-child {
            border-radius: 8px 0px 0px 0px;
          }
          :last-child {
            border-radius: 0px 8px 0px 0px;
          }
        }
      }
      tbody {
        tr {
          td {
            :first-child {
              border-radius: 0px 0px 0px 8px;
            }
            :last-child {
              border-radius: 0px 0px 8px 0px;
            }
          }
        }
        .stepSubtitle {
          color: ${(props) => props.theme.palette.secondary};
          font-size: 12px;
        }
      }

      th {
        position: relative;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        text-align: left;
        background: #fafafa;
        border: 1px solid #f2f2f2;
        transition: background 0.3s ease;
        padding: 4px 8px;
        text-align: center;
        font-size: 13px;
      }

      td {
        position: relative;
        text-align: center;
        border-bottom: 1px solid #f2f2f2;
        border-left: 1px solid #f2f2f2;
        border-right: 1px solid #f2f2f2;
        transition: background 0.3s;
        padding: 4px 0;
        color: #212b36;
        font-weight: 700;
      }
    }
  }

  .customerVolumeCard {
    margin-top: 24px;
  }
`;

import React, { useState } from 'react'
import styled from 'styled-components'
import { Row, Col, Spin } from 'components/common'
import TotalCard from './total/TotalCard'
import RaListCard from './ra/RaListCard'
import ReListCard from './re/ReListCard'
import GpStatCard from './gp/GpStatCard'
import BiStatCard from './bi/BiStatCard'
import CmListCard from './cm/CmListCard'
import MaStatCard from './ma/MaStatCard'
import CaListCard from './ca/CaListCard'
import JsListCard from './js/JsListCard'

export default function CommissionCalculatorPage() {
  const [raAmount, setRaAmount] = useState(0)
  const [reAmount, setReAmount] = useState(0)
  const [gpAmount, setGpAmount] = useState(0)
  const [biAmount, setBiAmount] = useState(0)
  const [cmAmount, setCmAmount] = useState(0)
  const [maAmount, setMaAmount] = useState(0)
  const [caAmount, setCaAmount] = useState(0)
  const [jsAmount, setJsAmount] = useState(0)
  const [weekFrom, setWeekFrom] = useState('')
  const [weekTo, setWeekTo] = useState('')

  const onSetTotalData = (field, value) => {
    switch (field) {
      case 'raAmount':
        setRaAmount(value)
        break;
      case 'reAmount':
        setReAmount(value)
        break;
      case 'gpAmount':
        setGpAmount(value)
        break;
      case 'biAmount':
        setBiAmount(value)
        break;
      case 'cmAmount':
        setCmAmount(value)
        break;
      case 'maAmount':
        setMaAmount(value)
        break;
      case 'caAmount':
        setCaAmount(value)
        break;
      case 'jsAmount':
        setJsAmount(value)
        break;
      case 'weekFrom':
        setWeekFrom(value)
        break;
      case 'weekTo':
        setWeekTo(value)
        break;
      default:
    }
  }

  return (
    <Wrapper>
      <Row gutter={[24, 24]}>
        <Col xs={24}>
          <TotalCard raAmount={raAmount} reAmount={reAmount} 
            gpAmount={gpAmount} biAmount={biAmount} 
            cmAmount={cmAmount} maAmount={maAmount} 
            caAmount={caAmount} jsAmount={jsAmount} 
            weekFrom={weekFrom} weekTo={weekTo} 
          />
        </Col>
        <Col xs={24}>
          <RaListCard 
            onSetTotalData={onSetTotalData} 
          />
        </Col>
        <Col xs={24}>
          <ReListCard onSetTotalData={onSetTotalData} 
          />
        </Col>
        <Col xs={24} xl={12}>
          <GpStatCard onSetTotalData={onSetTotalData} 
          />
        </Col>
        <Col xs={24} xl={12}>
          <BiStatCard onSetTotalData={onSetTotalData} 
          />
        </Col>
        <Col xs={24}>
          <CmListCard onSetTotalData={onSetTotalData} 
          />
        </Col>
        <Col xs={24}>
          <MaStatCard onSetTotalData={onSetTotalData} 
          />
        </Col>
        <Col xs={24} xl={24} xxl={12}>
          <CaListCard onSetTotalData={onSetTotalData} 
          />
        </Col>
        <Col xs={24} xl={24} xxl={12}>
          <JsListCard onSetTotalData={onSetTotalData} 
          />
        </Col>
      </Row>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding-bottom: 80px;
`
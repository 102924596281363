import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { Select, NoData, Spin, Pagination } from "components/common";
import { varOptionsWithDefault, varIs } from "common/var";
import { callGetApiWithAuth } from "utils/api";
import { Wrapper } from "../TrainingPage.styled";
import TopCard from "../topCard/TopCard";
import WatchCard from "./watchCard/WatchCard";

export default function WatchList() {
  const [isLoadingTrainingData, setIsLoadingTrainingData] = useState(false);
  const location = useLocation();
  const data = location.state.params;
  const [watchList, setWatchList] = useState([]);
  const [searchParam, setSearchParam] = useState({
    file_type: "",
    lang: "",
    asset_type: "",
  });
  const [paginationParam, setPaginationParam] = useState({
    currentPage: 1,
    perPage: 12, 
    total: 0,
  })

  const onGetSuccessWatchList = ({ data }) => {
    setIsLoadingTrainingData(false);
    setWatchList(data?.data);
    setPaginationParam({
      ...paginationParam,
      currentPage: data.current_page,
      total: data.total,
    })
  };

  const onGetFailedWatchList = () => {
    setIsLoadingTrainingData(false);
  };

  const loadWatchList = (paginationParam0, searchParam0) => {
    const params = {
      ...searchParam0,
      page: paginationParam0.currentPage,
      per_page: paginationParam0.perPage,
    };
    const q = Object.keys(params)
      .map((k) => k + "=" + params[k])
      .join("&");
    setIsLoadingTrainingData(true);
    callGetApiWithAuth(
      `training_video/list_by/${data?.id}?${q}`,
      onGetSuccessWatchList,
      onGetFailedWatchList
    );
  };

  const handleSearch  = () => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: 1,
    }
    loadWatchList(paginationParam0, searchParam);
  }

  const onPageChange = (page) => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: page
    }
    loadWatchList(paginationParam0, searchParam)
  }

  const onSelect = (field, value) => {
    const searchParam0 = {
      ...searchParam,
      [field]: value,
    };
    setSearchParam(searchParam0);
  };

  useEffect(() => {
    handleSearch();
  }, [searchParam]);

  return (
    <Wrapper>
      <div className="training-conta">
        <div className="training-title">Training</div>
        {!varIs("resource.assetType", searchParam.asset_type, "tir") && (
          <div className="header-search">
            <Select
              value={searchParam.lang}
              onChange={(v) => onSelect("lang", v)}
              options={varOptionsWithDefault(
                "resource.lang",
                "Select Language"
              )}
            />
            <Select
              value={searchParam.asset_type}
              onChange={(v) => onSelect("asset_type", v)}
              options={varOptionsWithDefault(
                "resource.assetType",
                "All Asset Types"
              ).filter((el) => el.label !== "TIR")}
            />
          </div>
        )}
      </div>
      <div className="watchList_topCard">
        <TopCard data={data} />
      </div>
      <div className="watchList">
        <div className='watchList-header'>
          <div className="watchList-title">Watch List</div>
          <Pagination
            current={paginationParam.currentPage}
            pageSize={paginationParam.perPage}
            total={paginationParam.total}
            onChange={onPageChange}
            responsive
            showSizeChanger={false}
            hideOnSinglePage
          />
        </div>
        {isLoadingTrainingData ? (
          <Spin spinning={true} className="loading-spin" />
        ) : watchList && watchList.length > 0 ? (
          watchList?.map((el, index) => (
            <WatchCard key={index} data={el} />
          ))
        ) : (
          <NoData title="There is no data." />
        )}
      </div>
    </Wrapper>
  );
}

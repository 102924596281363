import {
  Alert,
  Button,
  Col,
  DatePicker,
  Row,
  TablePanel,
  Progress,
  Tag
} from "components/common";
import moment from "moment";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { callGetApiWithAuth } from "utils/api";
import { asPrice, asDate } from "utils/text";
import { varIs, varLabel } from "common/var";

export default function Dti4freeReportPage() {
  const [tableData, setTableData] = useState([]);
  const [searchParam, setSearchParam] = useState({
    date: moment().format("YYYY-MM"),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [subscription, setSubscription] = useState();
  const [stats, setStats] = useState({
    total_order_amount: 0,
    qualified_amount: 0,
    pe_count: 0,
  });

  const [description, setDescription] = useState("");

  const onFormInput = (field, value) => {
    setSearchParam({
      ...searchParam,
      [field]: value,
    });
  };

  const onGetTableData = (res) => {
    setIsLoading(false);
    setSubscription(res.data.subscription);
    setTableData(res.data.data);
    setStats({
      total_order_amount: res.data.stats.total_order_amount,
      qualified_amount: res.data.stats.qualified_amount,
      pe_count: res.data.data.length,
    });
  };

  const onFailTableData = () => {
    setIsLoading(false);
  };

  const loadTableData = (searchParam0) => {
    setIsLoading(true);

    const params = {
      ...searchParam0,
    };

    const q = Object.keys(params)
      .map((k) => k + "=" + params[k])
      .join("&");

    callGetApiWithAuth(
      `report/dti_4free_report/list?${q}`,
      onGetTableData,
      onFailTableData
    );
  };

  const handleSearch = () => {
    loadTableData(searchParam);
  };

  useEffect(() => {
    setDescription(handleContent());
  }, [subscription, stats]);

  const handleContent = () => {
    if (!subscription) {
      return "Sorry. You don't have Dreamtrips membership yet.";
    }

    if (subscription.status !== 1) {
      return "You're so close. You need an active DreamTrips membership.";
    }

    if (stats.pe_count < 4) {
      if (stats.total_order_amount < stats.qualified_amount) {
        return `You’re so close. You need ${
          4 - stats.pe_count
        } more members and ${
          stats.qualified_amount - stats.total_order_amount
        } in personally enrolled volume to qualify!`;
      }

      return `You’re so close. You need ${4 - stats.pe_count} more members.`;
    }

    if (stats.total_order_amount < stats.qualified_amount) {
      return `You’re so close. You need ${
        stats.qualified_amount - stats.total_order_amount
      } in personally enrolled volume to qualify!`;
    }

    return `Congratulations 🎉 you’re qualified!`;
  };

  useEffect(() => {
    handleSearch();
  }, []);

  return (
    <Wrapper>
      <Row gutter={[24, 24]}>
        <Col xs={24}>
          <Alert
            style={{ margin: "5px 0px 5px", alignItems: "baseline" }}
            message={
              <div className="rank-alert">
                <span>{description}</span>
              </div>
            }
            type={"info"}
            showIcon
          />
        </Col>
      </Row>

      <div className="stats-section">
        <Row gutter={[24, 24]}>
          <Col xs={24} sm={12}>
            <div className="rank-progress-container">
              <div className="progress-label-container">
                <div className="label">Your Subscription</div>
                <div className='d-flex align-items-center gap-1'>
                  <div className="value">{subscription?.product.title}</div>
                  <div className="payment-tag">
                    {varIs("subscription.status", subscription?.status, "active") ? (
                      <Tag color="#87d068" className="active">
                        Active
                      </Tag>
                    ) : (
                      <Tag color="red" className="failed">
                        {varLabel("subscription.status", subscription?.status)}
                      </Tag>
                    )}
                  </div> 
                </div>
              </div>
            </div>
          </Col>

          <Col xs={24} sm={12}>
            <div className="rank-progress-container">
              <div className="progress-label-container">
                <div className="label">Next Billing Date</div>
                <div className="value">{subscription?.next_date}</div>
              </div>
            </div>
          </Col>

          <Col xs={24} sm={12}>
            <div className="rank-progress-container">
              <div className="progress-label-container">
                <div className="label">Personally Enrolled</div>
                <div className="value">({stats.pe_count}/4)</div>
              </div>
              <div className="rank-progress">
                <Progress
                  percent={100 * (stats.pe_count > 4 ? 1 : stats.pe_count / 4)}
                  showInfo={false}
                  strokeColor="#08B86E"
                  strokeWidth={10}
                />
              </div>
            </div>
          </Col>

          <Col xs={24} sm={12}>
            <div className="rank-progress-container">
              <div className="progress-label-container">
                <div className="label">Personally Enrolled Volume</div>
                <div className="value">
                  ({stats.total_order_amount}/{stats.qualified_amount})
                </div>
              </div>
              <div className="rank-progress">
                <Progress
                  percent={
                    100 *
                    (stats.total_order_amount > stats.qualified_amount
                      ? 1
                      : stats.total_order_amount / stats.qualified_amount)
                  }
                  showInfo={false}
                  strokeColor="#FFC427"
                  strokeWidth={10}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Row gutter={[24, 24]}>
        <Col xs={24}>
          <TablePanel
            data={tableData}
            loading={isLoading}
            toolbar={
              <div
                className="d-flex align-items-center flex-wrap"
                style={{ gap: "8px" }}
              >
                <DatePicker
                  onChange={(_, e) => onFormInput("date", e)}
                  picker="month"
                  value={moment(searchParam.date)}
                />

                <Button loading={isLoading} onClick={handleSearch}>
                  Search
                </Button>
              </div>
            }
            columns={[
              {
                title: "User ID",
                key: "uuid",
                render: (_, record) => <span>{record.uuid}</span>,
              },
              {
                title: "Name",
                key: "user",
                render: (_, record) => <span>{record.user}</span>,
              },
              {
                title: "Username",
                key: "username",
                render: (_, record) => <span>{record.username}</span>,
              },
              {
                title: "Product",
                key: "product",
                render: (_, record) => <span>{record.product}</span>,
              },
              {
                title: "Amount",
                key: "amount",
                render: (_, record) => <span>{asPrice(record.amount)}</span>,
              },
              {
                title: "Paid At",
                key: "paid_at",
                render: (_, record) => <span>{asDate(record.paid_at)}</span>,
              },
            ]}
          />
        </Col>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-bottom: 80px;

  .stats-section {
    box-shadow: 0px 0px 2px rgb(145 158 171 / 20%),
      0px 12px 24px -4px rgb(145 158 171 / 12%);
    border-radius: 16px;
    padding: 24px;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .rank-progress-container {
    margin-top: 20px;
    .progress-label-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .label {
        font-weight: normal;
        font-size: 14px;
        color: #7f8fa3;
      }
      .value {
        font-weight: 600;
        font-size: 14px;
        color: #00b4ee;
      }
      .payment-tag {
        .active {
          background: rgba(0, 171, 85, 0.08) !important;
          border-radius: 6px;
          font-weight: 700;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          color: #36b37e;
        }
        .failed {
          background: rgba(255, 86, 48, 0.08) !important;
          border-radius: 6px;
          font-weight: 700;
          border: none;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          color: #ff5630;
        }
      }
    }
    .rank-progress {
      .ant-progress-inner,
      .ant-progress-bg {
        border-radius: 0px;
      }
    }
  }
`;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Row, Col } from "components/common";
import { asUsdPrice } from "utils/text";
import { callGetApiWithAuth } from "utils/api";
import { ReactComponent as EarningIcon } from "assets/icons/earning-icon.svg";
import StatsCard from "components/common/statsCard/StatsCard";

export default function StatView() {
  const [statData, setStatData] = useState({
    last_7: 0,
    last_30: 0,
    total: 0,
    wallet: 0,
  });

  const onGetStatData = (res) => {
    setStatData(res.data);
  };
  const onFailStatData = () => {};
  const loadStatData = () => {
    callGetApiWithAuth(`earning/stats`, onGetStatData, onFailStatData);
  };

  useEffect(() => {
    loadStatData();
  }, []);

  return (
    <Wrapper>
      <Row gutter={[20, 20]}>
        <Col xs={24} lg={12} xl={6} className="responsive-stats">
          <StatsCard
            title="Earnings Last 7 Days"
            amount={asUsdPrice(statData.last_7)}
            icon={<EarningIcon />}
          />
        </Col>
        <Col xs={24} lg={12} xl={6} className="responsive-stats">
          <StatsCard
            title="Earnings Last 30 Days"
            amount={asUsdPrice(statData.last_30)}
            icon={<EarningIcon />}
            iconColor="#FFAB00"
          />
        </Col>
        <Col xs={24} lg={12} xl={6} className="responsive-stats">
          <StatsCard
            title="Total Earnings"
            amount={asUsdPrice(statData.total)}
            icon={<EarningIcon />}
            iconColor="#36B37E"
          />
        </Col>
        <Col xs={24} lg={12} xl={6} className="responsive-stats">
          <StatsCard
            title="Credit Wallet"
            amount={asUsdPrice(statData.wallet)}
            icon={<EarningIcon />}
            iconColor="#FF5630"
          />
        </Col>
      </Row>
    </Wrapper>
  );
}

export const Wrapper = styled.div``;

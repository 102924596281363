import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { callGetApiWithAuth } from "utils/api";
import { Card, Button } from "components/common";
import AmtSignupModal from "./AmtSignupModal";
import PortalImg from "assets/images/hlt_portal.png";
import Portal2Img from "assets/images/hlt/portal2.png";

export default function AccessPortal() {
  const myUser = useSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenedAmtSignup, setIsOpenedAmtSignup] = useState(false);

  const onLoginDone = (res) => {
    setIsLoading(false);
    const winUrl = URL.createObjectURL(
      new Blob([res.data.html_form], { type: "text/html" })
    );
    window.open(winUrl, "_blank");
  };
  const onLoginFail = () => {
    setIsLoading(false);
  };
  const handleLogin = () => {
    setIsLoading(true);
    callGetApiWithAuth(`hlt/tra_login`, onLoginDone, onLoginFail);
  };

  const onAmtLoginSuccess = (res) => {
    window.open(
      `https://deals.highlifetravel.com/director?cvt=${myUser.hlt_setting.amt_account}`,
      "_blank"
    );
  };
  const handleAmtLogin = () => {
    callGetApiWithAuth(`hlt/amt_login`, onAmtLoginSuccess);
  };

  return (
    <Wrapper>
      <div className="hlt-1">
        <div className="imageRoot">
          <img src={PortalImg} />
        </div>
        <h2>Access Your Portal Now</h2>
        <p>
          Click the button below to access your High Life Travel booking engine!
        </p>
        <div className="actionRoot">
          <Button
            loading={isLoading}
            onClick={handleLogin}
            disabled={!myUser.tra_vertex_setting}
            size="large"
          >
            Access
          </Button>
        </div>
      </div>
      <div className="hlt-2">
        <div className="amtRoot">
          <div className="imageRoot">
            <img src={Portal2Img} />
          </div>
          <div className="contentRoot">
            <h2>Select Savings Portal</h2>
          </div>
        </div>
        <div className="actionRoot">
          {myUser && myUser.tra_vertex_setting ? (
            <>
              {myUser.hlt_setting && myUser.hlt_setting.amt_account ? (
                <Button size="large" onClick={handleAmtLogin}>
                  Access Now
                </Button>
              ) : (
                <Button size="large" onClick={() => setIsOpenedAmtSignup(true)}>
                  Register
                </Button>
              )}
            </>
          ) : (
            <>
              <Button size="large" disabled>
                Register
              </Button>
            </>
          )}
        </div>
      </div>
      {isOpenedAmtSignup && (
        <AmtSignupModal setIsOpenedAmtSignup={setIsOpenedAmtSignup} />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .hlt-1 {
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2),
      0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
    padding: 24px;
    .imageRoot {
      margin-bottom: 24px;
      display: flex;
      justify-content: center;
      img {
        width: 80%;
      }
    }
    h2 {
      text-align: center;
      font-weight: 600;
      font-size: 18px;
      color: #212b36;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      text-align: center;
      color: #637381;
      margin: 0px;
    }
    .actionRoot {
      display: flex;
      margin-top: 24px;
      justify-content: center;
      button {
        width: 100%;
      }
      .ant-btn {
        background: #ff5630;
        color: white;
      }
      .ant-btn[disabled] {
        background: rgba(145, 158, 171, 0.24);
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 26px;
        color: rgba(145, 158, 171, 0.8);
        border: none;
      }
    }
  }
  .hlt-2 {
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2),
      0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
    padding: 24px;
    margin-top: 24px;
    margin-bottom: 24px;
    .actionRoot {
      display: flex;
      justify-content: center;
      button {
        width: 100%;
      }
      .ant-btn {
        background: #ff5630;
        color: white;
      }
      .ant-btn[disabled] {
        background: rgba(145, 158, 171, 0.24);
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 26px;
        color: rgba(145, 158, 171, 0.8);
        border: none;
      }
    }
  }

  .amtRoot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 24px;
    .contentRoot {
      width: calc(100% - 166px);
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      h2 {
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: #212b36;
        margin: 0;
      }
    }
    .imageRoot {
      display: flex;
      justify-content: center;
      width: 150px;
      margin-top: 0;
      img {
        width: 100%;
      }
    }
  }
`;

import React from "react";
import styled from "styled-components";
import { Button, Col, Row } from "components/common";
import { useHistory } from "react-router";

export default function TopCard({ data, recommended }) {
  const history = useHistory();

  const handleOnClick = () => {
    history.push(`/training/watchList/${data?.id}`, { params: data });
  };

  return (
    <Wrapper>
      <Row className="topCard-container">
        <Col xs={24} xl={8}>
          <div
            className="topCard-background"
            style={{ backgroundImage: `url(${data?.image})` }}
          ></div>
        </Col>
        <Col xs={24} xl={15}>
          <div className="topCard-details">
            {recommended && (
              <div className="recommended">
                <p>Recommended</p>
              </div>
            )}
            <div className="topCard-title">{data?.title}</div>
            <div className="topCard-content">
              {data.description}
            </div>
            {recommended && (
              <div className="topCard-learn">
                <Button onClick={handleOnClick}>Learn</Button>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Wrapper>
  );
}

export const Wrapper = styled.div`
  .topCard-container {
    background: white;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2),
      0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
    padding: 24px;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    .topCard-background {
      height: 300px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 8px;
    }
    .topCard-details {
      display: flex;
      flex-direction: column;
      height: 100%;
      .recommended {
        display: flex;
        justify-content: end;
        p {
          background: rgba(145, 158, 171, 0.08);
          border-radius: 8px;
          font-weight: 700;
          color: #212b36;
          font-size: 12px;
          padding: 5px 15px;
          margin: 0px;
        }
        @media (max-width: 1200px) {
          margin: 15px 0px;
        }
      }
    }
    .topCard-title {
      font-weight: 700;
      font-size: 30px;
      color: #212b36;
    }
    .topCard-content {
      font-size: 14px;
      max-height: 84px;
      color: #637381;
      line-height: 22px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      margin: 24px 0px 36px 0px;
    }
    .topCard-learn {
      width: 140px;
      button {
        width: 100%;
      }
    }
  }
`;

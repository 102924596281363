import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux'
import { Row, Col, VideoModal } from "components/common";
import { callGetApiWithAuth } from 'utils/api'
import SignupModal from './SignupModal'
import AmtSignupModal from './AmtSignupModal'
import { Wrapper } from "./HltOldPage.styled";
import FooterImage from "assets/images/hlt/footer_bg.png";
import LogoImg from "assets/images/hlt/image200.png";
import VideoImg from "assets/images/hlt/bne.png";
import PlayIcon from "assets/images/hlt/high_life_play.png";
import TopBgImg from "assets/images/hlt/Vecto19.png";
import Portal2 from "assets/images/hlt/portal2.png";

export default function HltPage() {
  const myUser = useSelector(state=>state.auth.user)
  const [isOpenVideoModal, setIsOpenVideoModal] = useState(false)
  const [isOpenedSignup, setIsOpenedSignup] = useState(false)
  const [isOpenedAmtSignup, setIsOpenedAmtSignup] = useState(false)
  const [pauseVideo, setPauseVideo] = useState(true)
  const [hasAccount, setHasAccount] = useState(false)
  const [hasAmtAccount, setHasAmtAccount] = useState(false)

  const handleOpenVideoModal = () => {
    setIsOpenVideoModal(true)
    setPauseVideo(false)
  }

  const handleCloseVideoModal = () => {
    setPauseVideo(true)
    setTimeout(() => {
      setIsOpenVideoModal(false)
    }, 500)
  }

  const onLoginSuccess = (res) => {
    window.open(res.data.url, '_blank')
  }
  const handleLogin = () => {
    callGetApiWithAuth(`hlt_old/login`, onLoginSuccess)
  }

  const onAmtLoginSuccess = (res) => {
    window.open(`https://deals.highlifetravel.com/director?cvt=${myUser.hlt_setting.amt_account}`, '_blank')
  }
  const handleAmtLogin = () => {
    callGetApiWithAuth(`hlt_old/amt_login`, onAmtLoginSuccess)
  }

  useEffect(() => {
    if (myUser && 
      myUser.hlt_setting
    ) {
      if (myUser.hlt_setting.account) {
        setHasAccount(true)
      }
      if (myUser.hlt_setting.amt_account) {
        setHasAmtAccount(true)
      }
    }
  }, [myUser])

  return (
    <Wrapper>
      <div className="main-container">
        <div className="HighLifeTravel">
          <section className="high-life-block">
            <div className="container">
              <div className="high-life-bg">
                <img src={TopBgImg} />
              </div>
              <div className="high-life-block-content">
                <div className="row align-items-center">
                  <div className="col-lg-6 order-lg-2">
                    <div className="high-life-block-text right-margi">
                      <span className="high-life">
                        <img src={LogoImg} />
                      </span>
                      <h3>Tired of being locked up?</h3>
                      <h3><span>A world awaits..</span></h3>
                      <p>From leisure to luxury, romantic to thrilling, family to friends and faraway places, High Life Travel puts a world of experiences in your hands. Our members not only do get access to incredible savings on flights, cruises, hotels, car rentals, weekly getaways, excursions and more. They also earn Reward Credits on every single booking to redeem towards future travel and gifts. It’s the one membership that pays you to travel the world.</p>
                    </div>
                  </div>
                  <div className="col-lg-6 order-lg-1">
                    <div className="high-life-block-img">
                      <img src={VideoImg} />
                      <img src={PlayIcon} className="video-play" onClick={handleOpenVideoModal} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="high-life-travel">
          <div className="container">
            <Row gutter={[20, 20]}>
              <Col xs={24} lg={12}>
                <img src={FooterImage} className="footer-bg" />
                <h3>Portal #1</h3>
                <div className='d-flex align-items-center justify-content-center'>
                  {hasAccount && 
                    <a className="access-btn" onClick={handleLogin}>Access Now</a>
                  }
                  {!hasAccount &&
                  <>
                    <span style={{width: 12}} />
                    <a className='access-btn' onClick={()=>setIsOpenedSignup(true)}>
                      Register
                    </a>
                  </>
                  }
                </div>
              </Col>
              <Col xs={24} lg={12}>
                <img src={Portal2} className="footer-bg" />
                <h3>Portal #2</h3>
                <div className='d-flex align-items-center justify-content-center'>
                  {hasAmtAccount && 
                    <a className="access-btn" onClick={handleAmtLogin}>Access Now</a>
                  }
                  {!hasAmtAccount &&
                  <>
                    <span style={{width: 12}} />
                    <a className='access-btn' onClick={()=>setIsOpenedAmtSignup(true)}>
                      Register
                    </a>
                  </>
                  }
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      {isOpenVideoModal &&
        <VideoModal
          visible={true}
          width="1200px"
          onCancel={handleCloseVideoModal}
          src={`https://videos.sproutvideo.com/embed/709dd8b51a1ae5c5f8/e048cd53b01ceb9f?autoPlay=false&loop=true&pause=${pauseVideo}`}
        />
      }
      {isOpenedSignup && 
        <SignupModal
          setIsOpenedSignup={setIsOpenedSignup}
        />
      }
      {isOpenedAmtSignup && 
        <AmtSignupModal
          setIsOpenedAmtSignup={setIsOpenedAmtSignup}
        />
      }
    </Wrapper>
  );
}

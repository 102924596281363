import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100%;
  box-shadow: 0px 0px 2px rgb(145 158 171 / 20%),
    0px 12px 24px -4px rgb(145 158 171 / 12%);
  border-radius: 16px;
  padding: 24px;
  .line-progress-container {
    .line-progress-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: -4px;
      .line-progress-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: #212b36;
      }
      .line-progress-value {
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        text-align: right;
        color: #212b36;
      }
    }
  }
  .rank-progress-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-progress-text {
      font-weight: 700;
      font-size: 27px;
      line-height: 48px;
      text-align: center;
      color: #212b36;
    }
    .ant-progress-inner {
      width: 182px !important;
      height: 182px !important;
    }
  }
  .rank-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #212b36;
    margin-bottom: 48px;
  }
  .unranked-container {
    .rank-box {
      border: 1px solid rgba(145, 158, 171, 0.24);
      border-radius: 16px;
      padding: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .rank-para {
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: #637381;
        mix-blend-mode: normal;
        opacity: 0.72;
        margin-top: 16px;
        margin-bottom: -5px;
        @media (max-width: 1724px) {
          font-size: 12px;
        }
      }
      .rank-head {
        font-weight: 700;
        font-size: 23px;
        line-height: 36px;
        color: #212b36;
      }
    }
  }
  .liquid-container > div {
    height: 240px;
  }
  .liquid-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .rank-container {
    border-left: 1px solid #eff3f6;
    height: 100%;
    padding-left: 20px;
    .rank-label {
      font-weight: normal;
      font-size: 14px;
      margin-bottom: 5px;
      color: #7f8fa3;
    }
    .rank-badge {
      font-weight: 600;
      font-size: 22px;
      color: #00b4ee;
      display: flex;
      align-items: center;
      img {
        width: 22px;
        height: 22px;
        object-fit: cover;
        margin-right: 8px;
      }
    }
  }
  .rank-progress-container {
    margin-top: 20px;
    .progress-label-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .label {
        font-weight: normal;
        font-size: 14px;
        color: #7f8fa3;
      }
      .value {
        font-weight: 600;
        font-size: 14px;
        color: #00b4ee;
      }
    }
    .rank-progress {
      .ant-progress-inner,
      .ant-progress-bg {
        border-radius: 0px;
      }
    }
  }
  @media (max-width: 1130px) {
    .responsive-rank {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
    .rank-container {
      padding-left: 0px;
      border-left: none;
    }
  }

  @media (max-width: 800px) {
    .rank-para {
      font-size: 11px;
    }
    .rank-head {
      font-size: 18px;
    }
  }
`;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { TablePanel, Card, Alert, UserAvatar } from "components/common";
import { callGetApiWithAuth } from "utils/api";
import { asUsdPrice, asVolume, asNumber } from "utils/text";

export default function CmListCard(props) {
  const myUser = useSelector((state) => state.auth.user);
  const [tableData, setTableData] = useState([]);
  const [paginationParam, setPaginationParam] = useState({
    currentPage: 1,
    perPage: 5,
    total: 0,
  });
  const [searchParam, setSearchParam] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const onGetTableData = (res) => {
    setIsLoading(false);
    setTableData(res.data.data);
    setPaginationParam({
      ...paginationParam,
      currentPage: res.data.current_page * 1,
      total: res.data.total * 1,
    });
    setTotalAmount(res.data.total_amount);
    setTotalCount(res.data.total_count);
    props.onSetTotalData("cmAmount", res.data.total_amount);
  };
  const onFailTableData = () => {
    setIsLoading(false);
  };
  const loadTableData = (paginationParam0, searchParam0) => {
    setIsLoading(true);
    const params = {
      ...searchParam0,
      page: paginationParam0.currentPage,
      per_page: paginationParam0.perPage,
    };
    const q = Object.keys(params)
      .map((k) => k + "=" + params[k])
      .join("&");
    callGetApiWithAuth(
      `report/bonus_tracker/cm_list?${q}`,
      onGetTableData,
      onFailTableData
    );
  };

  const onPageChange = (page) => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: page,
    };
    loadTableData(paginationParam0, searchParam);
  };

  const handleSearch = () => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: 1,
    };
    loadTableData(paginationParam0, searchParam);
  };

  useEffect(() => {
    handleSearch();
  }, []);

  return (
    <Wrapper>
      <div className="white-rounded-bg">
        <div className="top-panel">
          <h4>Check Matching Bonus</h4>
          {myUser?.rank_id * 1 >= 6 ? (
            <Alert
              type="info"
              message={
                <>
                  Your current rank is <strong>{myUser?.rank.name}</strong>. You
                  can earn up to&nbsp;
                  <strong>
                    {myUser?.rank_id * 1 >= 12
                      ? "10 "
                      : myUser?.rank_id * 1 >= 11
                      ? "9 "
                      : myUser?.rank_id * 1 >= 10
                      ? "8 "
                      : myUser?.rank_id * 1 >= 9
                      ? "7 "
                      : myUser?.rank_id * 1 >= 8
                      ? "6 "
                      : myUser?.rank_id * 1 >= 7
                      ? "5 "
                      : myUser?.rank_id * 1 >= 6
                      ? "4 "
                      : ""}
                  </strong>
                  levels of check matching on binary checks from your team.
                  <br />
                  Total estimated number of people binary qualified in your team
                  you will check match: <strong>
                    {asNumber(totalCount)}
                  </strong>{" "}
                  Affiliates.&nbsp; Total estimated leadership check match:{" "}
                  <strong className="ant-typography ant-typography-success">
                    {asUsdPrice(totalAmount)}
                  </strong>
                </>
              }
              showIcon
              style={{ margin: "5px 0px 5px", alignItems: "baseline" }}
            />
          ) : (
            <Alert
              type="info"
              message={
                <>
                  Your current rank is <strong>{myUser?.rank.name}</strong>. You
                  can get check matching bonus from <strong>5K</strong> rank.
                </>
              }
              showIcon
              style={{ alignItems: "baseline" }}
            />
          )}
        </div>
        <TablePanel
          data={tableData}
          loading={isLoading}
          paginationParam={paginationParam}
          onPageChange={onPageChange}
          columns={[
            {
              title: "User ID",
              key: "uuid",
              render: (_, record) => <span>{record.user.uuid}</span>,
            },
            {
              title: "Name",
              key: "user",
              render: (_, record) => (
                <UserAvatar
                  image={record.user.image}
                  title={`${record.user.first_name} ${record.user.last_name}`}
                />
              ),
            },
            {
              title: "Level",
              align: "center",
              key: "level",
              render: (_, record) => <span>{record.level}</span>,
            },
            {
              title: "Lesser Leg BV",
              key: "bgv",
              render: (_, record) => <span>{asVolume(record.bgv)}</span>,
            },
            {
              title: "Percentage",
              key: "percent",
              render: (_, record) => <span>{record.percent * 100 + "%"}</span>,
            },
            {
              title: "Estimated Check Matching",
              key: "cm_amount",
              render: (_, record) => (
                <span>{asUsdPrice(record.cm_amount)}</span>
              ),
            },
          ]}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .white-rounded-bg {
    padding: 0px !important;
    .top-panel {
      padding: 24px;
      h4 {
        font-size: 18px;
        font-weight: 700;
        color: #212b36;
        margin-bottom: 24px;
      }
    }
  }
  .table-card {
    .panelRoot {
      margin-bottom: 0px !important;
    }
  }
  .panelHead {
    display: none !important;
  }

  .table-new-wrapper {
    .ant-table-container {
      @media (max-width: 1100px) {
        width: 1000px;
      }
    }
    overflow-x: auto;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    .ant-table {
      border-radius: 0px !important;
    }
    .ant-table-content {
      thead {
        th:first-child,
        th:last-child {
          border-radius: 0px !important;
        }
      }
    }
  }
`;
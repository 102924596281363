import styled from "styled-components";
import SliderRight from "assets/icons/home/right-arrow.svg";
import SliderLeft from "assets/icons/home/left-arrow.svg";

export const Wrapper = styled.div`
  padding: 24px 24px 48px 24px;
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2),
    0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  border-radius: 16px;
  .slider-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    .slider-main-title {
      font-size: 18px;
      line-height: 28px;
      font-weight: 700;
      @media (max-width: 768px) {
        display: none;
      }
    }
    .slider-title {
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      color: #212b36;
      display: block;
      @media (max-width: 768px) {
        display: none;
      }
    }
    .slider-mobile-title {
      display: none;
      @media (max-width: 768px) {
        display: block;
      }
      .ant-select {
        width: 220px;
      }
      .ant-select-selector {
        height: 40px !important;
        .ant-select-selection-item {
          position: relative;
          top: -2px;
        }
      }
    }
    .slider-title-tab {
      padding: 8px 16px;
      cursor: pointer;
      font-size: 14px;
      border: 1px solid #E5E8EB;
      &.active {
        background: #1ea9ea;
        color: #fff;
      }
      &:first-child {
        border-radius: 10px 0 0 10px;
      }
      &:last-child {
        border-radius: 0 10px 10px 0;
      }
    }
    .slider-arrows {
      svg {
        margin-left: 22px;
        cursor: pointer;
      }
    }
  }
  .slider-box {
    margin: 0px -12px;
    position: relative;
  }
  .products-list {
    background: #ffffff;
    border: 1px solid rgba(145, 158, 171, 0.24);
    border-radius: 16px;
    padding: 8px 8px 24px 8px;
    box-sizing: border-box;
    text-align: center;
    position: relative;
    .products-img {
      position: relative;
      width: 100%;
      padding-top: 100%;
      a {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
      img {
        height: 100%;
        border-radius: 16px;
        width: 100%;
        object-fit: cover;
        object-position: top;
      }
    }
    .products-heading {
      h3 {
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        color: #212b36;
        padding: 24px 16px 0px 16px;
        text-align: start;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        min-height: 48px;
      }
      h5 {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: #212b36;
        text-align: end;
        padding-right: 16px;
        margin: 0px;
      }
    }
    .products-cart {
      margin: 20px 0 0;
      ul {
        display: inline-block;
        padding: 0 15px;
        width: 100%;
        li {
          width: 100%;
          float: left;
          padding: 0 2px;
          height: 38px;
          text-align: center;
          list-style: none;
          a {
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            display: inline-block;
            padding: 5px 20px;
            height: auto;
            width: 100%;
            border: 1px solid #00b2ff;
            border-radius: 20px;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
            border-bottom-left-radius: 20px;
            color: #00b2ff;
          }
        }
      }
    }
  }
  .react-multi-carousel-list {
    position: static;
  }
  .react-multiple-carousel__arrow--left {
    background: url(${SliderLeft});
    background-size: contain;
    left: auto;
    right: 56px;
    bottom: -24px;
    background-repeat: no-repeat;
    min-width: 11px;
    min-height: 18px;
    @media (max-width: 768px) {
      top: -56px;
    }
  }
  .react-multiple-carousel__arrow--right {
    background: url(${SliderRight});
    background-size: contain;
    right: 26px;
    bottom: -24px;
    background-repeat: no-repeat;
    min-width: 11px;
    min-height: 18px;
    @media (max-width: 768px) {
      top: -56px;
    }
  }
  .react-multiple-carousel__arrow::before {
    display: none;
  }
  .react-multi-carousel-item {
    padding: 12px;
  }
  @media (min-width: 1201px) and (max-width: 1400px) {
    .products-list {
      .products-heading {
        h3 {
          font-size: 15px;
        }
        h5 {
          font-size: 14px;
        }
      }
      .products-cart {
        ul {
          padding: 0;
        }
      }
    }
  }
`;

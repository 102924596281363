import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { notification, Popconfirm, Checkbox, Spin } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Input, Select, Button, Alert } from "components/common";
import {
  callGetApiWithAuth,
  callPostApiWithAuth,
  callPutApiWithAuth,
} from "utils/api";
import { countryStates, statesByCountry } from "common/country";
import { asPrice, asUsdPrice } from "utils/text";
import { varIs } from "common/var";

export default function BillingForm(props) {
  const orderDetails = useSelector((state) => state.checkout.orderDetails);
  const isBucketPay = useSelector(state => state.checkout.isBucketPay);
  const [creditBillingOptions, setCreditBillingOptions] = useState([]);
  const [bankBillingOptions, setBankBillingOptions] = useState([]);
  const [isEditable, setIsEditable] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [payTypeOptions, setPayTypeOptions] = useState([]);
  const [payTypeError, setPayTypeError] = useState("");
  const [creditFormErrors, setCreditFormErrors] = useState({
    billing_country_error: "",
    billing_state_error: "",
    billing_city_error: "",
    billing_address_error: "",
    billing_address_line2_error: "",
    billing_zipcode_error: "",
    cc_name_error: "",
    cc_type_error: "",
    cc_number_error: "",
    cc_cvv_error: "",
    cc_expiry_month_error: "",
    cc_expiry_year_error: "",
  });
  const [bankFormErrors, setBankFormErrors] = useState({
    billing_country_error: "",
    billing_state_error: "",
    billing_city_error: "",
    billing_address_error: "",
    billing_address_line2_error: "",
    billing_zipcode_error: "",
    bk_name_error: "",
    bk_bank_error: "",
    bk_account_error: "",
    bk_routing_error: "",
  });
  const [isConfirmedCoinPay, setIsConfirmedCoinPay] = useState(false);

  var d = new Date();
  var currentYear = d.getFullYear().toString().substr(-2) * 1;
  var years = [];
  for (let i = currentYear; i <= currentYear + 20; i++) {
    years.push(i);
  }

  const onChangeCreditForm = (field, value) => {
    let creditFormData_ = { ...props.creditFormData, [field]: value };
    props.setCreditFormData(creditFormData_);
  };

  const onChangeBankForm = (field, value) => {
    let bankFormData_ = { ...props.bankFormData, [field]: value };
    props.setBankFormData(bankFormData_);
  };

  const checkValid = () => {
    let creditFormErrors_ = {
      billing_country_error: "",
      billing_state_error: "",
      billing_city_error: "",
      billing_address_error: "",
      billing_address_line2_error: "",
      billing_zipcode_error: "",
      cc_name_error: "",
      cc_type_error: "",
      cc_number_error: "",
      cc_cvv_error: "",
      cc_expiry_month_error: "",
      cc_expiry_year_error: "",
    };
    let bankFormErrors_ = {
      billing_country_error: "",
      billing_state_error: "",
      billing_city_error: "",
      billing_address_error: "",
      billing_address_line2_error: "",
      billing_zipcode_error: "",
      bk_name_error: "",
      bk_bank_error: "",
      bk_account_error: "",
      bk_routing_error: "",
    };

    let regex = /^[0-9]{3,4}$/i;
    let ccRegex = /^[0-9]*$/;
    const englishRegex =
      /^[\u4e00-\u9fa5_A-Za-z0-9\s!@#$%^&*()_+=\-`~\\\]\[{}|';:/.,?><]*$/;

    if (
      props.payType === "1|" ||
      props.payType === "9|" ||
      props.payType === "10|" ||
      props.payType === "11|"
    ) {
      if (!props.creditFormData.cc_name.trim()) {
        creditFormErrors_["cc_name_error"] = "Required";
      } else if (
        props.creditFormData.cc_name &&
        englishRegex.exec(props.creditFormData.cc_name) == null
      ) {
        creditFormErrors_["cc_name_error"] = "Please enter only English";
      }
      if (!props.creditFormData.cc_type) {
        creditFormErrors_["cc_type_error"] = "Required";
      }
      if (!props.creditFormData.cc_number.trim()) {
        creditFormErrors_["cc_number_error"] = "Required";
      } else if (
        ccRegex.exec(
          props.creditFormData.cc_number.replace(/-/g, "").replace(/\s/g, "")
        ) == null
      ) {
        creditFormErrors_["cc_number_error"] = "CC Number is wrong format";
      }
      if (!props.creditFormData.cc_cvv.trim()) {
        creditFormErrors_["cc_cvv_error"] = "Required";
      } else if (regex.exec(props.creditFormData.cc_cvv) == null) {
        creditFormErrors_["cc_cvv_error"] =
          "CVV Number should be 3 or 4 digit numbers";
      }
      if (!props.creditFormData.cc_expiry_month) {
        creditFormErrors_["cc_expiry_month_error"] = "Required";
      }
      if (!props.creditFormData.cc_expiry_year) {
        creditFormErrors_["cc_expiry_year_error"] = "Required";
      }
      if (!props.creditFormData.billing_country) {
        creditFormErrors_["billing_country_error"] = "Required";
      }
      if (
        !props.creditFormData.billing_state &&
        statesByCountry(props.creditFormData.billing_country).length > 0
      ) {
        creditFormErrors_["billing_state_error"] = "Required";
      }
      if (!props.creditFormData.billing_city.trim()) {
        creditFormErrors_["billing_city_error"] = "Required";
      } else if (props.creditFormData.billing_city.length > 190) {
        creditFormErrors_["billing_city_error"] =
          "Billing City length too long";
      } else if (
        props.creditFormData.billing_city &&
        englishRegex.exec(props.creditFormData.billing_city) == null
      ) {
        creditFormErrors_["billing_city_error"] = "Please enter only English";
      }
      if (!props.creditFormData.billing_address.trim()) {
        creditFormErrors_["billing_address_error"] = "Required";
      } else if (props.creditFormData.billing_address.length > 190) {
        creditFormErrors_["billing_address_error"] =
          "Billing address length too long";
      } else if (
        props.creditFormData.billing_address &&
        englishRegex.exec(props.creditFormData.billing_address) == null
      ) {
        creditFormErrors_["billing_address_error"] =
          "Please enter only English";
      }
      if (
        props.creditFormData.billing_address_line2 &&
        englishRegex.exec(props.creditFormData.billing_address_line2) == null
      ) {
        creditFormErrors_["billing_address_line2_error"] =
          "Please enter only English";
      }
      if (!props.creditFormData.billing_zipcode.trim()) {
        creditFormErrors_["billing_zipcode_error"] = "Required";
      }
    }

    if (props.payType === "3|") {
      if (!props.bankFormData.bk_name.trim()) {
        bankFormErrors_["bk_name_error"] = "Required";
      } else if (
        props.bankFormData.bk_name &&
        englishRegex.exec(props.bankFormData.bk_name) == null
      ) {
        bankFormErrors_["bk_name_error"] = "Please enter only English";
      }
      if (!props.bankFormData.bk_bank) {
        bankFormErrors_["bk_bank_error"] = "Required";
      } else if (
        props.bankFormData.bk_bank &&
        englishRegex.exec(props.bankFormData.bk_bank) == null
      ) {
        bankFormErrors_["bk_bank_error"] = "Please enter only English";
      }
      if (!props.bankFormData.bk_account.trim()) {
        bankFormErrors_["bk_account_error"] = "Required";
      }
      if (!props.bankFormData.bk_routing.trim()) {
        bankFormErrors_["bk_routing_error"] = "Required";
      }
      if (!props.bankFormData.billing_country) {
        bankFormErrors_["billing_country_error"] = "Required";
      }
      if (
        !props.bankFormData.billing_state &&
        statesByCountry(props.bankFormData.billing_country).length > 0
      ) {
        bankFormErrors_["billing_state_error"] = "Required";
      }
      if (!props.bankFormData.billing_city.trim()) {
        bankFormErrors_["billing_city_error"] = "Required";
      } else if (props.bankFormData.billing_city.length > 190) {
        bankFormErrors_["billing_city_error"] = "Billing City length too long";
      } else if (
        props.bankFormData.billing_city &&
        englishRegex.exec(props.bankFormData.billing_city) == null
      ) {
        bankFormErrors_["billing_city_error"] = "Please enter only English";
      }
      if (!props.bankFormData.billing_address.trim()) {
        bankFormErrors_["billing_address_error"] = "Required";
      } else if (props.bankFormData.billing_address.length > 190) {
        bankFormErrors_["billing_address_error"] =
          "Billing address length too long";
      } else if (
        props.bankFormData.billing_address &&
        englishRegex.exec(props.bankFormData.billing_address) == null
      ) {
        bankFormErrors_["billing_address_error"] = "Please enter only English";
      }
      if (
        props.bankFormData.billing_address_line2 &&
        englishRegex.exec(props.bankFormData.billing_address_line2) == null
      ) {
        bankFormErrors_["billing_address_line2_error"] =
          "Please enter only English";
      }
      if (!props.bankFormData.billing_zipcode.trim()) {
        bankFormErrors_["billing_zipcode_error"] = "Required";
      }
    }

    if (
      (props.payType === "1|" ||
        props.payType === "9|" ||
        props.payType === "10|" ||
        props.payType === "11|") &&
      (creditFormErrors_.billing_country_error ||
        creditFormErrors_.billing_state_error ||
        creditFormErrors_.billing_city_error ||
        creditFormErrors_.billing_address_error ||
        creditFormErrors_.billing_address_line2_error ||
        creditFormErrors_.billing_zipcode_error ||
        creditFormErrors_.cc_name_error ||
        creditFormErrors_.cc_type_error ||
        creditFormErrors_.cc_number_error ||
        creditFormErrors_.cc_cvv_error ||
        creditFormErrors_.cc_expiry_month_error ||
        creditFormErrors_.cc_expiry_year_error)
    ) {
      setCreditFormErrors(creditFormErrors_);
      return false;
    } else if (
      props.payType === "3|" &&
      (bankFormErrors_.billing_country_error ||
        bankFormErrors_.billing_state_error ||
        bankFormErrors_.billing_city_error ||
        bankFormErrors_.billing_address_error ||
        bankFormErrors_.billing_address_line2_error ||
        bankFormErrors_.billing_zipcode_error ||
        bankFormErrors_.bk_name_error ||
        bankFormErrors_.bk_bank_error ||
        bankFormErrors_.bk_account_error ||
        bankFormErrors_.bk_routing_error)
    ) {
      setBankFormErrors(bankFormErrors_);
      return false;
    }

    return true;
  };

  const handleCheckout = () => {
    if (!props.payType) {
      setPayTypeError("Please select pay type");
      return;
    } else {
      setPayTypeError("");
    }
    props.handleCheckout();
  };

  const onSuccessUpdated = () => {
    setIsUpdating(false);
    setIsUpdated(true);
    notification.success({
      message: "Success",
      description: "Billing info has been updated.",
    });
    setCreditFormErrors({
      billing_country_error: "",
      billing_state_error: "",
      billing_city_error: "",
      billing_address_error: "",
      billing_address_line2_error: "",
      billing_zipcode_error: "",
      cc_name_error: "",
      cc_type_error: "",
      cc_number_error: "",
      cc_cvv_error: "",
      cc_expiry_month_error: "",
      cc_expiry_year_error: "",
    });
    setBankFormErrors({
      billing_country_error: "",
      billing_state_error: "",
      billing_city_error: "",
      billing_address_error: "",
      billing_address_line2_error: "",
      billing_zipcode_error: "",
      bk_name_error: "",
      bk_bank_error: "",
      bk_account_error: "",
      bk_routing_error: "",
    });
    loadBillingInfos();
  };

  const onFailedUpdate = (err) => {
    setIsUpdating(false);
  };

  const handleUpdate = () => {
    if (!checkValid()) return;

    setIsUpdating(true);
    if (
      props.creditFormData.id &&
      (props.payType === "1|" ||
        props.payType === "9|" ||
        props.payType === "10|" ||
        props.payType === "11|")
    ) {
      callPutApiWithAuth(
        `my_account/setting/cc_billing_detail/${props.creditFormData.id}`,
        {
          ...props.creditFormData,
          cc_exp_date: `${props.creditFormData.cc_expiry_month}/${props.creditFormData.cc_expiry_year}`,
          cc_number: props.creditFormData.cc_number
            .replace(/-/g, "")
            .replace(/\s/g, ""),
        },
        onSuccessUpdated,
        onFailedUpdate
      );
    } else if (props.bankFormData.id && props.payType === "3|") {
      callPutApiWithAuth(
        `my_account/setting/bank_billing_detail/${props.bankFormData.id}`,
        props.bankFormData,
        onSuccessUpdated,
        onFailedUpdate
      );
    } else {
      if (
        props.payType === "1|" ||
        props.payType === "9|" ||
        props.payType === "10|" ||
        props.payType === "11|"
      ) {
        callPostApiWithAuth(
          `my_account/setting/cc_billing_detail`,
          {
            ...props.creditFormData,
            cc_exp_date: `${props.creditFormData.cc_expiry_month}/${props.creditFormData.cc_expiry_year}`,
            cc_number: props.creditFormData.cc_number
              .replace(/-/g, "")
              .replace(/\s/g, ""),
          },
          onSuccessUpdated,
          onFailedUpdate
        );
      } else if (props.payType === "3|") {
        callPostApiWithAuth(
          `my_account/setting/bank_billing_detail`,
          props.bankFormData,
          onSuccessUpdated,
          onFailedUpdate
        );
      }
    }
  };

  const handleClickEditIcon = () => {
    setIsEditable(!isEditable);
    setCreditFormErrors({
      billing_country_error: "",
      billing_state_error: "",
      billing_city_error: "",
      billing_address_error: "",
      billing_address_line2_error: "",
      billing_zipcode_error: "",
      cc_name_error: "",
      cc_type_error: "",
      cc_number_error: "",
      cc_cvv_error: "",
      cc_expiry_month_error: "",
      cc_expiry_year_error: "",
    });
    setBankFormErrors({
      billing_country_error: "",
      billing_state_error: "",
      billing_city_error: "",
      billing_address_error: "",
      billing_address_line2_error: "",
      billing_zipcode_error: "",
      bk_name_error: "",
      bk_bank_error: "",
      bk_account_error: "",
      bk_routing_error: "",
    });
  };

  const onGetSuccessBillingInfos = ({ data }) => {
    if (data && data[1] && data[1].length === 2) {
      setCreditBillingOptions(
        data[1].map((el) => ({
          ...el,
          value: el.id,
          label: el.name,
        }))
      );
    } else if (data && data[1] && data[1].length === 1) {
      setCreditBillingOptions([
        ...data[1].map((el) => ({
          ...el,
          value: el.id,
          label: el.name,
        })),
        {
          value: "",
          key: "new",
          label: "New Billing Info",
          id: "",
          billing_address: "",
          billing_address_line2: "",
          billing_city: "",
          billing_state: "",
          billing_zipcode: "",
          billing_country: "",
          cc_name: "",
          cc_type: "",
          cc_number: "",
          cc_cvv: "",
          cc_expiry_month: "",
          cc_expiry_year: "",
        },
      ]);
    } else if (!data || (data && !data[1])) {
      setCreditBillingOptions([
        {
          value: "",
          key: "new",
          label: "New Billing Info",
          id: "",
          billing_address: "",
          billing_address_line2: "",
          billing_city: "",
          billing_state: "",
          billing_zipcode: "",
          billing_country: "",
          cc_name: "",
          cc_type: "",
          cc_number: "",
          cc_cvv: "",
          cc_expiry_month: "",
          cc_expiry_year: "",
        },
      ]);
    }

    if (data && data[3] && data[3].length === 2) {
      setBankBillingOptions(
        data[3].map((el) => ({
          ...el,
          value: el.id,
          label: el.name,
        }))
      );
    } else if (data && data[3] && data[3].length === 1) {
      setBankBillingOptions([
        ...data[3].map((el) => ({
          ...el,
          value: el.id,
          label: el.name,
        })),
        {
          value: "",
          key: "new",
          label: "New Billing Info",
          id: "",
          billing_address: "",
          billing_address_line2: "",
          billing_city: "",
          billing_state: "",
          billing_zipcode: "",
          billing_country: "",
          bk_name: "",
          bk_bank: "",
          bk_account: "",
          bk_routing: "",
        },
      ]);
    } else if (!data || (data && !data[3])) {
      setBankBillingOptions([
        {
          value: "",
          key: "new",
          label: "New Billing Info",
          id: "",
          billing_address: "",
          billing_address_line2: "",
          billing_city: "",
          billing_state: "",
          billing_zipcode: "",
          billing_country: "",
          bk_name: "",
          bk_bank: "",
          bk_account: "",
          bk_routing: "",
        },
      ]);
    }
  };

  const onGetFailedBillingInfos = () => {
    setCreditBillingOptions([
      {
        value: "",
        key: "new",
        label: "New Billing Info",
        id: "",
        billing_address: "",
        billing_address_line2: "",
        billing_city: "",
        billing_state: "",
        billing_zipcode: "",
        billing_country: "",
        cc_name: "",
        cc_type: "",
        cc_number: "",
        cc_cvv: "",
        cc_expiry_month: "",
        cc_expiry_year: "",
      },
    ]);
    setBankBillingOptions([
      {
        value: "",
        key: "new",
        label: "New Billing Info",
        id: "",
        billing_address: "",
        billing_address_line2: "",
        billing_city: "",
        billing_state: "",
        billing_zipcode: "",
        billing_country: "",
        bk_name: "",
        bk_bank: "",
        bk_account: "",
        bk_routing: "",
      },
    ]);
  };

  const loadBillingInfos = () => {
    callGetApiWithAuth(
      "shop/get_billing_details",
      onGetSuccessBillingInfos,
      onGetFailedBillingInfos
    );
  };

  useEffect(() => {
    if (
      props.payType === "1|" ||
      props.payType === "9|" ||
      props.payType === "10|" ||
      props.payType === "11|"
    ) {
      if (
        creditBillingOptions.length > 0 &&
        creditBillingOptions.filter((el) => el.is_primary * 1 === 1).length > 0
      ) {
        let creditFormData_ = creditBillingOptions.filter(
          (el) => el.is_primary * 1 === 1
        )[0];
        props.setCreditFormData({
          ...creditFormData_,
          cc_number: `**** **** **** ****`,
          cc_cvv: "***",
          cc_expiry_month: creditFormData_.cc_exp_date.split("/")[0],
          cc_expiry_year: creditFormData_.cc_exp_date.split("/")[1],
        });
      } else {
        props.setCreditFormData({
          id: "",
          billing_address: "",
          billing_address_line2: "",
          billing_city: "",
          billing_state: "",
          billing_zipcode: "",
          billing_country: "",
          cc_name: "",
          cc_type: "",
          cc_number: "",
          cc_cvv: "",
          cc_expiry_month: "",
          cc_expiry_year: "",
          pay_type: 1,
        });
      }
    } else if (props.payType === "3|") {
      if (
        bankBillingOptions.length > 0 &&
        bankBillingOptions.filter((el) => el.is_primary * 1 === 1).length > 0
      ) {
        props.setBankFormData({
          ...bankBillingOptions.filter((el) => el.is_primary * 1 === 1)[0],
          bk_account: `******`,
          bk_routing: `********`,
        });
      } else {
        props.setBankFormData({
          id: "",
          billing_address: "",
          billing_address_line2: "",
          billing_city: "",
          billing_state: "",
          billing_zipcode: "",
          billing_country: "",
          bk_name: "",
          bk_bank: "",
          bk_account: "",
          bk_routing: "",
          pay_type: 3,
        });
      }
    }
    setIsEditable(false);
  }, [props.payType, creditBillingOptions, bankBillingOptions]);

  useEffect(() => {
    if (
      props.payType === "1|" ||
      props.payType === "9|" ||
      props.payType === "10|" ||
      props.payType === "11|"
    ) {
      if (
        creditBillingOptions.length > 0 &&
        creditBillingOptions.filter(
          (el) => el.value * 1 === props.creditFormData.id * 1
        )[0] &&
        creditBillingOptions.filter(
          (el) => el.value * 1 === props.creditFormData.id * 1
        )[0].id
      ) {
        let creditFormData_ = creditBillingOptions.filter(
          (el) => el.value * 1 === props.creditFormData.id * 1
        )[0];
        props.setCreditFormData({
          ...creditFormData_,
          cc_number: `**** **** **** ****`,
          cc_cvv: "***",
          cc_expiry_month: creditFormData_.cc_exp_date.split("/")[0],
          cc_expiry_year: creditFormData_.cc_exp_date.split("/")[1],
        });
      } else {
        props.setCreditFormData({
          id: "",
          billing_address: "",
          billing_address_line2: "",
          billing_city: "",
          billing_state: "",
          billing_zipcode: "",
          billing_country: "",
          cc_name: "",
          cc_type: "",
          cc_number: "",
          cc_cvv: "",
          cc_expiry_month: "",
          cc_expiry_year: "",
        });
      }
    } else if (props.payType === "3|") {
      if (
        bankBillingOptions.length > 0 &&
        bankBillingOptions.filter(
          (el) => el.value * 1 === props.bankFormData.id * 1
        )[0] &&
        bankBillingOptions.filter(
          (el) => el.value * 1 === props.bankFormData.id * 1
        )[0].id
      ) {
        let bankFormData_ = bankBillingOptions.filter(
          (el) => el.value * 1 === props.bankFormData.id * 1
        )[0];
        props.setBankFormData({
          ...bankFormData_,
          bk_account: `******`,
          bk_routing: `********`,
        });
      } else {
        props.setBankFormData({
          id: "",
          billing_address: "",
          billing_address_line2: "",
          billing_city: "",
          billing_state: "",
          billing_zipcode: "",
          billing_country: "",
          bk_name: "",
          bk_bank: "",
          bk_account: "",
          bk_routing: "",
        });
      }
    }
    setIsEditable(false);
  }, [props.creditFormData.id, props.bankFormData.id]);

  useEffect(() => {
    if (isEditable) {
      setIsUpdated(false);
      if (
        props.payType === "1|" ||
        props.payType === "9|" ||
        props.payType === "10|" ||
        props.payType === "11|"
      ) {
        props.setCreditFormData({
          ...props.creditFormData,
          cc_number: "",
          cc_cvv: "",
        });
      } else if (props.payType === "3|") {
        props.setBankFormData({
          ...props.bankFormData,
          bk_account: "",
          bk_routing: "",
        });
      }
    } else {
      setIsUpdated(true);
      if (
        (props.payType === "1|" ||
          props.payType === "9|" ||
          props.payType === "10|" ||
          props.payType === "11|") &&
        props.creditFormData.id
      ) {
        props.setCreditFormData({
          ...props.creditFormData,
          cc_number: `**** **** **** ****`,
          cc_cvv: "***",
        });
      } else if (props.payType === "3|" && props.bankFormData.id) {
        props.setBankFormData({
          ...props.bankFormData,
          bk_account: `******`,
          bk_routing: `********`,
        });
      }
    }
  }, [isEditable]);

  useEffect(() => {
    loadBillingInfos();
  }, [props.myUser]);

  useEffect(() => {
    // let payTypeOptions0 = [
    //   {
    //     "value": "5|",
    //     "key": "coin",
    //     "label": "Bitcoin"
    //   }
    // ]
    if (isBucketPay) return;

    let payTypeOptions0 = [];
    let hasBucket = false;
    orderDetails.forEach(el => {
      if (el.product.type*1===4 &&  // prepaid_code
        el.product.type_tag=='bucket'
      ) {
        hasBucket = true;
      }
    });

    let hasIncentiveTrip = false;
    orderDetails.forEach(el => {
      if ([
        'incentive_trip_10k', 'incentive_trip_5k',
        'incentive_trip_1k'
      ].indexOf(el.product.sku)>=0) {
        hasIncentiveTrip = true;
      }
    });

    // ! 0510cf: UK->CCPay
    // * 0528cf: Stop BlueSnap CC Norway(NO).
    const euCountries = [
      "AX", "AL", "AD", "AT", "BY", "BE", "BA", "BG", "HR",
      "CY", "CZ", "DK", "EE", "FO", "FI", "FR", "DE", "GI", "GR",
      "GG", "VA", "HU", "IS", "IE", "IM", "IT", "JE", "LV", "LI",
      "LT", "LU", "MK", "MT", "MD", "MC", "ME", "NL", "PL",
      "PT", "XK", "RO", "RU", "SM", "RS", "SK", "SI", "ES", "SJ",
      "SE", "CH", "UA", "MQ", "GF", "RE", "GP", "YT",
    ];
    if (props.cCLimitPrice && props.cCLimitPrice * 1 > props.totalPrice * 1) {
      // * 0529cf: EU->Esquire
      // if (euCountries.indexOf(props.myUser.country) >= 0) {
      //   payTypeOptions0.push({
      //     value: "4|BlueSnapCc",
      //     key: "bluesnap_cc",
      //     label: "Credit Card",
      //   });
      //   props.setPayType("4|BlueSnapCc");
      // } else {
        payTypeOptions0.push({
          value: "1|",
          key: "cc",
          label: "Credit Card",
        });
        props.setPayType("1|");
      // }
    } else {
      props.setPayType("");
    }

    if (props.myUser) {
      if (props.myUser.country === "US") {
        // payTypeOptions0.push({
        //   value: "3|",
        //   key: "ach",
        //   label: "ACH",
        // });
      }
      if (props.myUser.type * 1 === 1) {
        payTypeOptions0.push({
          value: "2|",
          key: "wallet",
          label: "Credit Wallet",
        });
        payTypeOptions0.push({
          value: "9|",
          key: "cc+wallet",
          label: "Credit Wallet + Credit Card",
        });
      }
      if (!hasBucket && !hasIncentiveTrip) {
        payTypeOptions0.push({
          value: "7|",
          key: "gift_wallet",
          label: "Gift Wallet",
        });
        payTypeOptions0.push({
          value: "10|",
          key: "cc+gift_wallet",
          label: "Gift Wallet + Credit Card",
        });
      }
      let hasGiftCard = false;
      orderDetails.forEach((el) => {
        if (varIs("product.type", el.product.type, "prepaid_code")) {
          hasGiftCard = true;
        }
      });
      if (!hasGiftCard && !hasBucket && !hasIncentiveTrip) {
        payTypeOptions0.push({
          value: "8|",
          key: "loyalty_wallet",
          label: "Loyalty Rewards",
        });
      }
      if (!hasBucket && !hasIncentiveTrip) {
        payTypeOptions0.push({
          value: "14|",
          key: "bucket",
          label: "Brand Bucks",
        });
      }
      if (!hasIncentiveTrip) {
        if (props.myUser.country === "UA") {
          payTypeOptions0.push({
            value: "4|LiqPay",
            key: "liq_pay",
            label: "LiqPay",
          });
        }
        if (props.myUser.country === "TW") {
          payTypeOptions0.push({
            value: "4|SunTech",
            key: "sun_tech",
            label: "SunTech",
          });
  
          // If country is TW, set default payment option as **SunTech**
          props.setPayType("4|SunTech");
        }
      }
      const lbtCountries = [
        "AL", "DZ", "AS", "AD", "AI", "AQ", "AG", "AM", "AW", "AT",
        "AZ", "BS", "BD", "BB", "BY", "BZ", "BJ", "BM", "BT", "BO",
        "BA", "BW", "BV", "BR", "BF", "BI", "CM", "CA", "CV", "KY",
        "CF", "TD", "CL", "CN", "KM", "CG", "CD", "CK", "CR", "CI",
        "CY", "CZ", "DJ", "DM", "DO", "EG", "SV", "GQ", "ER", "EE",
        "ET", "FK", "FI", "FR", "GF", "PF", "TF", "GA", "GM", "GE",
        "DE", "GI", "GR", "GD", "GP", "GU", "GT", "GG", "GN", "GW",
        "GY", "HT", "HN", "ID", "IE", "IM", "IL", "IT", "JM", "JP",
        "JE", "JO", "KZ", "KE", "KR", "KG", "LV", "LS", "LR", "LI",
        "LT", "LU", "MK", "MG", "MW", "MV", "ML", "MT", "MH", "MQ",
        "MR", "MU", "YT", "FM", "MD", "MC", "ME", "MS", "MA", "MZ",
        "NA", "NP", "NL", "AN", "NC", "NZ", "NI", "NE", "NG", "NU",
        "MP", "NO", "OM", "PK", "PW", "PA", "PY", "PE", "PH", "PN",
        "PL", "PT", "PR", "RE", "RO", "RW", "SH", "KN", "LC", "MF",
        "PM", "VC", "SM", "ST", "SA", "SN", "RS", "SC", "SL", "SX",
        "SK", "SI", "SO", "ZA", "ES", "LK", "SR", "SJ", "SZ", "TJ",
        "TZ", "TH", "TG", "TK", "TT", "TN", "TM", "TC", "UG", "UA",
        "AE", "GB", "UY", "UZ", "VA", "VE", "VG", "VI", "WF", "EH",
        "ZM", "ZW",
      ];
      if (!hasIncentiveTrip) {
        if (lbtCountries.indexOf(props.myUser.country) >= 0) {
          // payTypeOptions0.push({
          //   value: "4|BlueSnapLbt",
          //   key: "bluesnap_lbt",
          //   label: "Local Bank Transfer",
          // });
        }
      }
      // const maCountries = [
      //   'GH', 'NG', 'ZA', 'KE', 'BF',
      //   'BW', 'BR', 'CM', 'CF', 'CN'
      // ]
      // if (maCountries.indexOf(props.myUser.country)>=0) {
      //   payTypeOptions0.push({
      //     "value": "4|MaxiCash",
      //     "key": "maxicash",
      //     "label": "Maxicash (Cards, Mobile Money, M-Pesa)"
      //   })
      // }
      if (!hasIncentiveTrip) {
        // * 0522cf: UK/RemoveBlueSnap3DS
        if (props.myUser.country !== 'GB') {
          // payTypeOptions0.push({
          //   value: "4|BlueSnapGooglePay",
          //   key: "bluesnap_googlepay",
          //   label: "Google Pay",
          // });
          // payTypeOptions0.push({
          //   value: "4|BlueSnapSkrill",
          //   key: "bluesnap_skrill",
          //   label: "Skrill",
          // });
          // payTypeOptions0.push({
          //   value: "4|BlueSnapApplePay",
          //   key: "bluesnap_applepay",
          //   label: "Apple Pay",
          // });
        }
      }
      // payTypeOptions0.push({
      //   "value": "5|",
      //   "key": "coin",
      //   "label": "BitPay"
      // })

      const iaCountries = ["KE", "UG", "TZ", "TG", "GH", "NG", "ZA"];
      if (!hasIncentiveTrip) {
        if (iaCountries.indexOf(props.myUser.country) >= 0) {
          payTypeOptions0.push({
            value: "4|IpayAfrica",
            key: "ipay_africa",
            label: "iPayAfrica",
          });
        }
        payTypeOptions0.push({
          value: "5|Crypto(BTC)",
          key: "coin_btc",
          label: "Crypto (BTC)",
        });
        payTypeOptions0.push({
          value: "5|Crypto(USDTERC20)",
          key: "coin_usdterc20",
          label: "Crypto (USDT ERC20)",
        });
        payTypeOptions0.push({
          value: "5|Crypto(USDTTRC20)",
          key: "coin_usdttrc20",
          label: "Crypto (USDT TRC20)",
        });
      }
    }
    setPayTypeOptions(payTypeOptions0);
  }, [props.myUser, orderDetails, props.cCLimitPrice, props.totalPrice, isBucketPay]);

  useEffect(() => {
    if (!props.hasPhysicalOrder) {
      props.getDigitalTaxPrice();
    }
  }, [props.hasPhysicalOrder]);

  return (
    <div className="checkout-form">
      <h4 className="checkout-title">Payment Information</h4>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 selectdiv no-padding">
          <Select
            label="Payment Type"
            value={props.payType}
            options={payTypeOptions}
            onChange={(value) => props.setPayType(value)}
            size="large"
          />
          {payTypeError && (
            <label className="input-error">{payTypeError}</label>
          )}
        </div>
      </div>
      {props.payType === "2|" && (
        <div className="row">
          <div className="col-xs-12 no-padding mt-4">
            <span>
              Your current credit wallet balance is{" "}
              <strong>{asPrice(props.myUser.wallet.current_balance)}</strong>.
            </span>
            <br />
            {props.myUser.wallet.current_balance * 1 < props.totalPrice * 1 && (
              <label className="input-error">
                Insufficient balance in your credit wallet.
              </label>
            )}
          </div>
        </div>
      )}
      {props.payType === "7|" && (
        <div className="row">
          <div className="col-xs-12 no-padding mt-4">
            <span>
              Your current gift wallet balance is{" "}
              <strong>{asPrice(props.myUser.gift_wallet.amount)}</strong>.
            </span>
            <br />
            {props.myUser.gift_wallet.amount * 1 < props.totalPrice * 1 && (
              <label className="input-error">
                Insufficient balance in your gift wallet.
              </label>
            )}
          </div>
        </div>
      )}
      {props.payType === "8|" && (
        <div className="row">
          <div className="col-xs-12 no-padding mt-4">
            <Alert
              message={
                <>
                  <span>
                    Your current loyalty wallet balance is{" "}
                    <strong>
                      {asUsdPrice(props.myUser.loyalty_wallet.amount)}
                    </strong>
                    .
                  </span>
                  <br />
                  {props.myUser.loyalty_wallet.amount * 1 <
                    props.totalPrice * 1 && (
                    <label className="input-error">
                      * Insufficient balance in your loyalty wallet.
                    </label>
                  )}
                </>
              }
            />
          </div>
        </div>
      )}
      {props.payType === "9|" && (
        <div className="row">
          <div className="col-xs-12 no-padding mt-4">
            <Alert
              message={
                <>
                  <span>
                    Your current credit wallet balance is{" "}
                    <strong>
                      {asUsdPrice(props.myUser.wallet.current_balance)}
                    </strong>
                    .
                  </span>
                  <br />
                  {props.myUser.wallet.current_balance * 1 > 0 ? (
                    <>
                      <span>
                        You will pay{" "}
                        {asUsdPrice(
                          Math.min(
                            props.totalPrice * 1,
                            props.myUser.wallet.current_balance * 1
                          )
                        )}{" "}
                        with your credit wallet
                      </span>
                      {props.totalPrice * 1 >
                      props.myUser.wallet.current_balance * 1 ? (
                        <span>
                          &nbsp;and{" "}
                          {asPrice(
                            props.totalPrice -
                              props.myUser.wallet.current_balance
                          )}{" "}
                          with your credit card.
                        </span>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <span>
                      You will pay {asUsdPrice(props.totalPrice)} with your
                      credit card
                    </span>
                  )}
                  .
                </>
              }
            />
          </div>
        </div>
      )}
      {props.payType === "10|" && (
        <div className="row">
          <div className="col-xs-12 no-padding mt-4">
            <Alert
              message={
                <>
                  <span>
                    Your current gift wallet balance is{" "}
                    <strong>
                      {asUsdPrice(props.myUser.gift_wallet.amount)}
                    </strong>
                    .
                  </span>
                  <br />
                  {props.myUser.gift_wallet.amount * 1 > 0 ? (
                    <>
                      <span>
                        You will pay{" "}
                        {asUsdPrice(
                          Math.min(
                            props.totalPrice * 1,
                            props.myUser.gift_wallet.amount * 1
                          )
                        )}{" "}
                        with your gift wallet
                      </span>
                      {props.totalPrice * 1 >
                      props.myUser.gift_wallet.amount * 1 ? (
                        <span>
                          &nbsp;and{" "}
                          {asPrice(
                            props.totalPrice - props.myUser.gift_wallet.amount
                          )}{" "}
                          with your credit card.
                        </span>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <span>
                      You will pay {asUsdPrice(props.totalPrice)} with your
                      credit card
                    </span>
                  )}
                  .
                </>
              }
            />
          </div>
        </div>
      )}
      {props.payType === "11|" && (
        <div className="row">
          <div className="col-xs-12 no-padding mt-4">
            <Alert
              message={
                <>
                  <span>
                    Your current loyalty wallet balance is{" "}
                    <strong>
                      {asUsdPrice(props.myUser.loyalty_wallet.amount)}
                    </strong>
                    .
                  </span>
                  <br />
                  {props.myUser.loyalty_wallet.amount * 1 > 0 ? (
                    <>
                      <span>
                        You will pay{" "}
                        {asUsdPrice(
                          Math.min(
                            props.totalPrice * 1,
                            props.myUser.loyalty_wallet.amount * 1
                          )
                        )}{" "}
                        with your loyalty wallet
                      </span>
                      {props.totalPrice * 1 >
                      props.myUser.loyalty_wallet.amount * 1 ? (
                        <span>
                          &nbsp;and{" "}
                          {asPrice(
                            props.totalPrice -
                              props.myUser.loyalty_wallet.amount
                          )}{" "}
                          with your credit card.
                        </span>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <span>
                      You will pay {asUsdPrice(props.totalPrice)} with your
                      credit card
                    </span>
                  )}
                  .
                </>
              }
            />
          </div>
        </div>
      )}
      {props.payType === "14|" && (
        <div className="row">
          <div className="col-xs-12 no-padding mt-4">
            <Alert
              message={
                <>
                  <span>
                    Your current brand bucks balance is{" "}
                    <strong>
                      {asUsdPrice(props.myUser.bucket_wallet.amount)}
                    </strong>
                    .
                  </span>
                  <br />
                  {props.myUser.bucket_wallet.amount * 1 <
                    props.totalPrice * 1 && (
                    <label className="input-error">
                      * Insufficient balance in your brand bucks.
                    </label>
                  )}
                </>
              }
            />
          </div>
        </div>
      )}
      {(props.payType === "5|Crypto(BTC)" ||
        props.payType === "5|Crypto(USDTERC20)" ||
        props.payType === "5|Crypto(USDTTRC20)") && (
        <>
          {/* <div className="bitcoin-description">
            <p>
              Bitcoin purchases are 
              non-refundable due to the fluctuation of the cryptocurrency
              market. Any refund requests will be sent in the form of a credit
              added to your e-wallet.
            </p>
          </div> */}
          <Checkbox
            checked={isConfirmedCoinPay}
            onChange={(e) => setIsConfirmedCoinPay(e.target.checked)}
          >
            I agree and understand that I'm using cryptocurrencies to pay for my
            MyDailyChoice order. Cryptocurrency payments are non-refundable due
            to the fluctuation and risk associated. MyDailyChoice is not
            responsible for any gains or losses that occurs with the use of
            cryptocurrencies.
          </Checkbox>
        </>
      )}
      {props.payType === "4|LiqPay" && (
        <>
          <div className="bitcoin-description">
            <p>
              <strong>Pay Using LiqPay (Ukraine)</strong>
            </p>
            <p>
              Upon confirming your information and agreeing to our policies, we
              will direct you to to the LiqPay site to complete your order.
            </p>
          </div>
        </>
      )}
      {props.payType === "4|SunTech" && (
        <>
          <div className="bitcoin-description">
            <p>
              <strong>Pay Using SunTech (Taiwan)</strong>
            </p>
            <p>
              Upon confirming your information and agreeing to our policies, we
              will direct you to to the SunTech site to complete your order.
            </p>
          </div>
        </>
      )}
      {props.payType === "4|BlueSnapLbt" && (
        <>
          <div className="bitcoin-description">
            <p>
              <strong>Pay Using Local Bank Transfer</strong>
            </p>
            <p>
              Upon confirming your information and agreeing to our policies, we
              will show our bank receipt information.
              <br />
              Once you have sent money to our bank, it will take 1-5 business
              days to clear.
              <br />
              After validated money, your order will be shipped.
            </p>
          </div>
        </>
      )}
      {props.payType === "4|MaxiCash" && (
        <>
          <div className="bitcoin-description">
            <p>
              <strong>Pay Using MaxiCash</strong>
            </p>
            <p>
              Please follow the instructions on the next page to complete your
              payment with Maxicash.
              <br />
              Once your payment has been verified and received, your order will
              be released.
            </p>
          </div>
        </>
      )}
      {props.payType === "4|Skrill" && (
        <>
          <div className="bitcoin-description">
            <p>
              <strong>Pay Using Skrill</strong>
            </p>
            <p>
              Please follow the instructions on the next page to complete your
              payment with Skrill.
              <br />
              Once your payment has been verified and received, your order will
              be released.
            </p>
          </div>
        </>
      )}
      {props.payType === "4|BlueSnapGooglePay" && (
        <>
          <div className="bitcoin-description">
            <p>
              <strong>Pay Using Google Pay</strong>
            </p>
            <p>
              Please follow the instructions on the next page to complete your
              payment with Google Pay.
              <br />
              Once your payment has been verified and received, your order will
              be released.
            </p>
          </div>
        </>
      )}
      {props.payType === "4|BlueSnapSkrill" && (
        <>
          <div className="bitcoin-description">
            <p>
              <strong>Pay Using Skrill</strong>
            </p>
            <p>
              Please follow the instructions on the next page to complete your
              payment with Skrill.
              <br />
              Once your payment has been verified and received, your order will
              be released.
            </p>
          </div>
        </>
      )}
      {props.payType === "4|BlueSnapApplePay" && (
        <>
          <div className="bitcoin-description">
            <p>
              <strong>Pay Using Apple Pay</strong>
            </p>
            <p>
              Please follow the instructions on the next page to complete your
              payment with Apple Pay.
              <br />
              Once your payment has been verified and received, your order will
              be released.
            </p>
          </div>
        </>
      )}
      {props.payType === "4|BlueSnapCc" && (
        <>
          <div className="bitcoin-description">
            <p>
              <strong>Pay Credit Card in Secured Payment Page</strong>
            </p>
            <p>
              Please follow the instructions on the next page to complete your
              payment in Secured Payment Page.
              <br />
              Once your payment has been verified and received, your order will
              be released.
            </p>
          </div>
        </>
      )}
      {props.payType === "4|IpayAfrica" && (
        <>
          <div className="bitcoin-description">
            <p>
              <strong>Pay Using iPayAfrica</strong>
            </p>
            <p>
              Please follow the instructions on the next page to complete your
              payment with iPayAfrica.
              <br />
              Once your payment has been verified and received, your order will
              be released.
            </p>
          </div>
        </>
      )}
      {(props.payType === "1|" ||
        props.payType === "9|" ||
        props.payType === "10|" ||
        props.payType === "11|") && (
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 selectdiv no-padding">
            <Select
              label="Select Your Billing Info*"
              value={props.creditFormData.id}
              onChange={(value) =>
                props.setCreditFormData({ ...props.creditFormData, id: value })
              }
              size="large"
              options={creditBillingOptions}
            />
          </div>
          {props.creditFormData.id && (
            <div className="col-sm-6 selectdiv no-padding edit">
              {isEditable ? (
                <Button size="large" onClick={handleClickEditIcon}>
                  Cancel
                </Button>
              ) : (
                <Button
                  size="large"
                  onClick={handleClickEditIcon}
                  icon={<EditOutlined />}
                >
                  Edit
                </Button>
              )}
              <br />
            </div>
          )}
        </div>
      )}
      {props.payType === "3|" && (
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 selectdiv no-padding">
            <Select
              label="Select Your Billing Info*"
              value={props.bankFormData.id}
              onChange={(value) =>
                props.setBankFormData({ ...props.bankFormData, id: value })
              }
              size="large"
              options={props.payType === "3|" ? bankBillingOptions : []}
            />
          </div>
          {props.bankFormData.id && (
            <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 selectdiv no-padding edit">
              <Button
                size="large"
                onClick={handleClickEditIcon}
                icon={<EditOutlined />}
              >
                Edit
              </Button>
              <br />
            </div>
          )}
        </div>
      )}
      {(props.payType === "1|" ||
        props.payType === "9|" ||
        props.payType === "10|" ||
        props.payType === "11|") && (
        <>
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 no-padding">
              <Input
                label="Name on Card*"
                type="text"
                value={props.creditFormData.cc_name}
                onChange={(e) => onChangeCreditForm("cc_name", e.target.value)}
                disabled={props.creditFormData.id && !isEditable}
              />
              {creditFormErrors.cc_name_error && (
                <label className="input-error">
                  {creditFormErrors.cc_name_error}
                </label>
              )}
            </div>
            <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 selectdiv no-padding">
              <Select
                label="Card Type*"
                value={props.creditFormData.cc_type}
                onChange={(value) => onChangeCreditForm("cc_type", value)}
                size="large"
                disabled={props.creditFormData.id && !isEditable}
              >
                <option value=""></option>
                <option value={1}>Visa</option>
                <option value={2}>Mastercard</option>
                <option value={3}>Discover</option>
                <option value={4}>Amex</option>
                <option value={5}>China Union Pay</option>
                <option value={6}>JCB</option>
              </Select>
              {creditFormErrors.cc_type_error && (
                <label className="input-error">
                  {creditFormErrors.cc_type_error}
                </label>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 no-padding">
              <Input
                type="text"
                label="Credit Card Number*"
                value={props.creditFormData.cc_number}
                onChange={(e) =>
                  onChangeCreditForm("cc_number", e.target.value)
                }
                disabled={props.creditFormData.id && !isEditable}
              />
              {creditFormErrors.cc_number_error && (
                <label className="input-error">
                  {creditFormErrors.cc_number_error}
                </label>
              )}
            </div>
            <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 no-padding">
              <Input
                type="text"
                label="CVV*"
                value={props.creditFormData.cc_cvv}
                onChange={(e) => onChangeCreditForm("cc_cvv", e.target.value)}
                disabled={props.creditFormData.id && !isEditable}
              />
              {creditFormErrors.cc_cvv_error && (
                <label className="input-error">
                  {creditFormErrors.cc_cvv_error}
                </label>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 selectdiv no-padding">
              <Select
                label="Exp Month*"
                value={props.creditFormData.cc_expiry_month}
                onChange={(value) =>
                  onChangeCreditForm("cc_expiry_month", value)
                }
                placeholder="Month"
                size="large"
                showSearch
                disabled={props.creditFormData.id && !isEditable}
              >
                <option value=""></option>
                {[...Array(12).keys()].map((i) => (
                  <option value={i < 9 ? "0" + (i + 1) : i + 1} key={i}>
                    {i + 1}
                  </option>
                ))}
              </Select>
              {creditFormErrors.cc_expiry_month_error && (
                <label className="input-error">
                  {creditFormErrors.cc_expiry_month_error}
                </label>
              )}
            </div>
            <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 selectdiv no-padding">
              <Select
                label="Exp Year*"
                value={props.creditFormData.cc_expiry_year}
                onChange={(value) =>
                  onChangeCreditForm("cc_expiry_year", value)
                }
                placeholder="Year"
                size="large"
                showSearch
                disabled={props.creditFormData.id && !isEditable}
              >
                <option value=""></option>
                {years.map((y) => (
                  <option value={y} key={y}>
                    20{y}
                  </option>
                ))}
              </Select>
              {creditFormErrors.cc_expiry_year_error && (
                <label className="input-error">
                  {creditFormErrors.cc_expiry_year_error}
                </label>
              )}
            </div>
          </div>
        </>
      )}
      {props.payType === "3|" && (
        <>
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 no-padding">
              <Input
                label="Name on Bank*"
                type="text"
                value={props.bankFormData.bk_name}
                onChange={(e) => onChangeBankForm("bk_name", e.target.value)}
                disabled={props.bankFormData.id && !isEditable}
              />
              {bankFormErrors.bk_name_error && (
                <label className="input-error">
                  {bankFormErrors.bk_name_error}
                </label>
              )}
            </div>
            <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 no-padding">
              <Input
                label="Bank Name*"
                type="text"
                value={props.bankFormData.bk_bank}
                onChange={(e) => onChangeBankForm("bk_bank", e.target.value)}
                disabled={props.bankFormData.id && !isEditable}
              />
              {bankFormErrors.bk_bank_error && (
                <label className="input-error">
                  {bankFormErrors.bk_bank_error}
                </label>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 no-padding">
              <Input
                label="Account Number*"
                type="text"
                value={props.bankFormData.bk_account}
                onChange={(e) => onChangeBankForm("bk_account", e.target.value)}
                disabled={props.bankFormData.id && !isEditable}
              />
              {bankFormErrors.bk_account_error && (
                <label className="input-error">
                  {bankFormErrors.bk_account_error}
                </label>
              )}
            </div>
            <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 no-padding">
              <Input
                label="Routing Number*"
                type="text"
                value={props.bankFormData.bk_routing}
                onChange={(e) => onChangeBankForm("bk_routing", e.target.value)}
                disabled={props.bankFormData.id && !isEditable}
              />
              {bankFormErrors.bk_routing_error && (
                <label className="input-error">
                  {bankFormErrors.bk_routing_error}
                </label>
              )}
            </div>
          </div>
        </>
      )}
      {(props.payType === "1|" ||
        props.payType === "9|" ||
        props.payType === "10|" ||
        props.payType === "11|") && (
        <>
          <h4 className="checkout-title billing-info">Billing Address</h4>
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 no-padding">
              <Input
                label="Address Line 1*"
                type="text"
                value={props.creditFormData.billing_address}
                onChange={(e) =>
                  onChangeCreditForm("billing_address", e.target.value)
                }
                disabled={props.creditFormData.id && !isEditable}
              />
              {creditFormErrors.billing_address_error && (
                <label className="input-error">
                  {creditFormErrors.billing_address_error}
                </label>
              )}
            </div>
            <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 no-padding">
              <Input
                label="Address Line 2"
                type="text"
                value={props.creditFormData.billing_address_line2}
                onChange={(e) =>
                  onChangeCreditForm("billing_address_line2", e.target.value)
                }
                disabled={props.creditFormData.id && !isEditable}
              />
              {creditFormErrors.billing_address_line2_error && (
                <label className="input-error">
                  {creditFormErrors.billing_address_line2_error}
                </label>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 no-padding">
              <Input
                label="City*"
                type="text"
                value={props.creditFormData.billing_city}
                onChange={(e) =>
                  onChangeCreditForm("billing_city", e.target.value)
                }
                disabled={props.creditFormData.id && !isEditable}
              />
              {creditFormErrors.billing_city_error && (
                <label className="input-error">
                  {creditFormErrors.billing_city_error}
                </label>
              )}
            </div>
            <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 no-padding">
              <Input
                label="Zip/Postal code*"
                type="text"
                value={props.creditFormData.billing_zipcode}
                onChange={(e) =>
                  onChangeCreditForm("billing_zipcode", e.target.value)
                }
                disabled={props.creditFormData.id && !isEditable}
              />
              {creditFormErrors.billing_zipcode_error && (
                <label className="input-error">
                  {creditFormErrors.billing_zipcode_error}
                </label>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 no-padding">
              <Select
                label="Country*"
                value={props.creditFormData.billing_country}
                onChange={(value) =>
                  onChangeCreditForm("billing_country", value)
                }
                size="large"
                showSearch
                disabled={props.creditFormData.id && !isEditable}
                options={[
                  {
                    label: "Select Country",
                    value: "",
                  },
                  ...countryStates.map((el) => ({
                    label: el.name,
                    value: el.code2,
                  })),
                ]}
                optionFilterProp="label"
              />
              {creditFormErrors.billing_country_error && (
                <label className="input-error">
                  {creditFormErrors.billing_country_error}
                </label>
              )}
            </div>
            {statesByCountry(props.creditFormData.billing_country).length >
            0 ? (
              <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 no-padding">
                <Select
                  label="State/Province*"
                  value={props.creditFormData.billing_state}
                  onChange={(value) =>
                    onChangeCreditForm("billing_state", value)
                  }
                  size="large"
                  showSearch
                  disabled={props.creditFormData.id && !isEditable}
                  options={[
                    {
                      label: "Select State",
                      value: "",
                    },
                    ...statesByCountry(
                      props.creditFormData.billing_country
                    ).map((el) => ({
                      label: el.name,
                      value: el.name,
                    })),
                  ]}
                  optionFilterProp="label"
                />
                {creditFormErrors.billing_state_error && (
                  <label className="input-error">
                    {creditFormErrors.billing_state_error}
                  </label>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      )}
      {props.payType === "3|" && (
        <>
          <h4 className="checkout-title billing-info mt-5">Billing Address</h4>
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 no-padding">
              <Input
                label="Address Line 1*"
                type="text"
                value={props.bankFormData.billing_address}
                onChange={(e) =>
                  onChangeBankForm("billing_address", e.target.value)
                }
                disabled={props.bankFormData.id && !isEditable}
              />
              {bankFormErrors.billing_address_error && (
                <label className="input-error">
                  {bankFormErrors.billing_address_error}
                </label>
              )}
            </div>
            <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 no-padding">
              <Input
                label="Address Line 2"
                type="text"
                value={props.bankFormData.billing_address_line2}
                onChange={(e) =>
                  onChangeBankForm("billing_address_line2", e.target.value)
                }
                disabled={props.bankFormData.id && !isEditable}
              />
              {bankFormErrors.billing_address_line2_error && (
                <label className="input-error">
                  {bankFormErrors.billing_address_line2_error}
                </label>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 no-padding">
              <Input
                label="City*"
                type="text"
                value={props.bankFormData.billing_city}
                onChange={(e) =>
                  onChangeBankForm("billing_city", e.target.value)
                }
                disabled={props.bankFormData.id && !isEditable}
              />
              {bankFormErrors.billing_city_error && (
                <label className="input-error">
                  {bankFormErrors.billing_city_error}
                </label>
              )}
            </div>
            <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 no-padding">
              <Input
                label="Zip/Postal code*"
                type="text"
                value={props.bankFormData.billing_zipcode}
                onChange={(e) =>
                  onChangeBankForm("billing_zipcode", e.target.value)
                }
                disabled={props.bankFormData.id && !isEditable}
              />
              {bankFormErrors.billing_zipcode_error && (
                <label className="input-error">
                  {bankFormErrors.billing_zipcode_error}
                </label>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 selectdiv no-padding">
              <Select
                label="Country*"
                value={props.bankFormData.billing_country}
                onChange={(value) => onChangeBankForm("billing_country", value)}
                size="large"
                showSearch
                disabled={props.bankFormData.id && !isEditable}
                options={[
                  {
                    label: "Select Country",
                    value: "",
                  },
                  ...countryStates.map((el) => ({
                    label: el.name,
                    value: el.code2,
                  })),
                ]}
              />
              {bankFormErrors.billing_country_error && (
                <label className="input-error">
                  {bankFormErrors.billing_country_error}
                </label>
              )}
            </div>
            {statesByCountry(props.bankFormData.billing_country).length > 0 ? (
              <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 selectdiv no-padding">
                <Select
                  label="State/Province*"
                  value={props.bankFormData.billing_state}
                  onChange={(value) => onChangeBankForm("billing_state", value)}
                  size="large"
                  showSearch
                  disabled={props.bankFormData.id && !isEditable}
                  options={[
                    {
                      label: "Select State",
                      value: "",
                    },
                    ...statesByCountry(props.bankFormData.billing_country).map(
                      (el) => ({
                        label: el.name,
                        value: el.name,
                      })
                    ),
                  ]}
                />
                {bankFormErrors.billing_state_error && (
                  <label className="input-error">
                    {bankFormErrors.billing_state_error}
                  </label>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      )}
      <div
        className="d-flex justify-content-between action-row"
        style={{ marginTop: 24 }}
      >
        {props.hasPhysicalOrder && (
          <div className="prev-button">
            <Button size="large" onClick={() => props.setStep("shipping-form")}>
              Prev
            </Button>
          </div>
        )}
        <div className="d-flex align-items-center j-end">
          {isUpdated &&
          ((props.creditFormData.id && props.payType === "1|") ||
            (props.creditFormData.id && props.payType === "9|") ||
            (props.creditFormData.id && props.payType === "10|") ||
            (props.creditFormData.id && props.payType === "11|") ||
            (props.bankFormData.id && props.payType === "3|") ||
            (props.payType !== "1|" &&
              props.payType !== "9|" &&
              props.payType !== "10|" &&
              props.payType !== "11|" &&
              props.payType !== "3|")) ? (
            <Popconfirm
              onConfirm={handleCheckout}
              okText="Yes"
              cancelText="No"
              title="Are you sure?"
              disabled={
                (props.payType === "2|" &&
                  props.myUser.wallet.current_balance * 1 <
                    props.totalPrice * 1) ||
                (props.payType === "7|" &&
                  props.myUser.gift_wallet.amount * 1 < props.totalPrice * 1) ||
                (props.payType === "8|" &&
                  props.myUser.loyalty_wallet.amount * 1 <
                    props.totalPrice * 1) ||
                (props.payType === "14|" &&
                  props.myUser.bucket_wallet.amount * 1 <
                    props.totalPrice * 1) ||
                ((props.payType === "5|Crypto(BTC)" ||
                  props.payType === "5|Crypto(USDTERC20)" ||
                  props.payType === "5|Crypto(USDTTRC20)") &&
                  !isConfirmedCoinPay)
              }
            >
              <Spin spinning={props.isSubmiting}>
                <button
                  className="purc-button"
                  disabled={
                    (props.payType === "2|" &&
                      props.myUser.wallet.current_balance * 1 <
                        props.totalPrice * 1) ||
                    (props.payType === "7|" &&
                      props.myUser.gift_wallet.amount * 1 <
                        props.totalPrice * 1) ||
                    (props.payType === "8|" &&
                      props.myUser.loyalty_wallet.amount * 1 <
                        props.totalPrice * 1) ||
                    (props.payType === "14|" &&
                      props.myUser.bucket_wallet.amount * 1 <
                        props.totalPrice * 1) ||
                    ((props.payType === "5|Crypto(BTC)" ||
                      props.payType === "5|Crypto(USDTERC20)" ||
                      props.payType === "5|Crypto(USDTTRC20)") &&
                      !isConfirmedCoinPay)
                  }
                >
                  Purchase
                </button>
              </Spin>
            </Popconfirm>
          ) : (
            <button
              loading={isUpdating}
              type="primary"
              className="purc-button"
              onClick={handleUpdate}
            >
              Update
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Select,
  TablePanel,
  Badge,
  Button,
  Popconfirm,
  SuccessNotification,
} from "components/common";
import { varKey, varLabel, varIs } from "common/var";
import { callGetApiWithAuth, callDeleteApiWithAuth } from "utils/api";
import { asDate, asUsdPrice } from "utils/text";
import styled from "styled-components";

export default function HistorySection(props) {
  const [tableMode, setTableMode] = useState("grant");
  const [tableData, setTableData] = useState([]);
  const [paginationParam, setPaginationParam] = useState({
    currentPage: 1,
    perPage: 5,
    total: 0,
  });
  const [columns, setColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const onGetTableData = (res) => {
    setTableData(res.data.data);
    setPaginationParam({
      ...paginationParam,
      currentPage: res.data.current_page,
      total: res.data.total,
    });
    setIsLoading(false);
    props.setShouldLoad(false);
  };
  const onFailTableData = () => {
    setIsLoading(false);
    props.setShouldLoad(false);
  };
  const loadTableData = (paginationParam0) => {
    setIsLoading(true);
    const params = {
      page: paginationParam0.currentPage,
      per_page: paginationParam0.perPage,
    };
    const q = Object.keys(params)
      .map((k) => k + "=" + params[k])
      .join("&");
    if (tableMode === "purchase") {
      callGetApiWithAuth(
        `hlt/purchase_histories?${q}`,
        onGetTableData,
        onFailTableData
      );
    } else if (tableMode === "grant") {
      callGetApiWithAuth(
        `hlt/grant_histories?${q}`,
        onGetTableData,
        onFailTableData
      );
    } else if (tableMode === "sent") {
      callGetApiWithAuth(
        `hlt/sent_histories?${q}`,
        onGetTableData,
        onFailTableData
      );
    }
  };

  const onPageChange = (page) => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: page,
    };
    loadTableData(paginationParam0);
  };

  const handleSearch = () => {
    loadTableData(paginationParam);
  };

  useEffect(() => {
    if (tableMode === "purchase") {
      setColumns([
        {
          title: "Date",
          key: "date",
          render: (_, record) => <span>{asDate(record.date)}</span>,
        },
        {
          title: "Product",
          key: "product",
          render: (_, record) => <span>{record.product.title}</span>,
        },
        {
          title: "Quantity",
          key: "quantity",
          render: (_, record) => <span>{record.quantity}</span>,
        },
        {
          title: "Travel Amount",
          key: "amount",
          render: (_, record) => <span>{asUsdPrice(record.amount)}</span>,
        },
        {
          title: "Description",
          key: "comment",
          render: (_, record) => (
            <div
              className="d-flex align-items-center"
              dangerouslySetInnerHTML={{ __html: record.comment }}
            />
          ),
        },
      ]);
    } else if (tableMode === "grant") {
      setColumns([
        {
          title: "Date",
          key: "date",
          render: (_, record) => <span>{asDate(record.date)}</span>,
        },
        {
          title: "Amount",
          key: "amount",
          render: (_, record) => <span>{asUsdPrice(record.amount)}</span>,
        },
        {
          title: "Sender",
          key: "sender",
          render: (_, record) => (
            <span>
              {record.sender
                ? `${record.sender.first_name} ${record.sender.last_name}`
                : ""}
            </span>
          ),
        },
        {
          title: "Receiver",
          key: "receiver",
          render: (_, record) => (
            <span>
              {record.receiver
                ? `${record.receiver.first_name} ${record.receiver.last_name}`
                : `${record.receiver_first_name} ${record.receiver_last_name}`}
            </span>
          ),
        },
        {
          title: "Receiver Email",
          key: "receiver_email",
          render: (_, record) => (
            <span>
              {record.receiver
                ? `${record.receiver.email}`
                : `${record.receiver_email}`}
            </span>
          ),
        },
        {
          title: "Status",
          key: "status",
          render: (_, record) => (
            <span>
              <Badge
                type={"tgiftGrantRequest"}
                keyName={varKey("tgiftGrantRequest.status", record.status)}
                label={varLabel("tgiftGrantRequest.status", record.status)}
              />
            </span>
          ),
        },
        {
          title: "Action",
          key: "action",
          render: (_, record) => (
            <div className="d-flex align-items-center">
              {varIs("tgiftGrantRequest.status", record.status, "pending") &&
                record.is_mine && (
                  <CancelBtn data={record} handleSearch={handleSearch} />
                )}
            </div>
          ),
        },
        {
          title: "Comment",
          key: "comment",
          render: (_, record) => (
            <div
              className="d-flex align-items-center"
              dangerouslySetInnerHTML={{ __html: record.comment }}
            />
          ),
        },
      ]);
    } else if (tableMode === "sent") {
      setColumns([
        {
          title: "Date",
          key: "date",
          render: (_, record) => <span>{asDate(record.date)}</span>,
        },
        {
          title: "Amount",
          key: "amount",
          render: (_, record) => <span>{asUsdPrice(record.amount)}</span>,
        },
        {
          title: "Sender",
          key: "sender",
          render: (_, record) => (
            <span>
              {record.sender
                ? `${record.sender.first_name} ${record.sender.last_name}`
                : ""}
            </span>
          ),
        },
        {
          title: "Receiver",
          key: "receiver",
          render: (_, record) => (
            <span>
              {record.receiver
                ? `${record.receiver.first_name} ${record.receiver.last_name}`
                : `${record.receiver_first_name} ${record.receiver_last_name}`}
            </span>
          ),
        },
        {
          title: "Receiver Email",
          key: "receiver_email",
          render: (_, record) => (
            <span>
              {record.receiver
                ? `${record.receiver.email}`
                : `${record.receiver_email}`}
            </span>
          ),
        },
        {
          title: "Status",
          key: "status",
          render: (_, record) => (
            <span>
              <Badge
                type={"tgiftTransferRequest"}
                keyName={varKey("tgiftTransferRequest.status", record.status)}
                label={varLabel("tgiftTransferRequest.status", record.status)}
              />
            </span>
          ),
        },
        // {
        //   title: 'Action',
        //   key: 'action',
        //   render: (_, record) => (
        //     <div className='d-flex align-items-center'>
        //       {(varIs('tgiftGrantRequest.status', record.status, 'pending') &&
        //        record.is_mine
        //       ) &&
        //         <CancelBtn data={record}
        //           handleSearch={handleSearch}
        //         />
        //       }
        //     </div>
        //   )
        // },
        {
          title: "Comment",
          key: "comment",
          render: (_, record) => (
            <div
              className="d-flex align-items-center"
              dangerouslySetInnerHTML={{ __html: record.comment }}
            />
          ),
        },
      ]);
    }

    setTableData([]);
    const paginationParam0 = {
      ...paginationParam,
      currentPage: 1,
    };
    loadTableData(paginationParam0);
  }, [tableMode]);

  useEffect(() => {
    if (props.shouldLoad) {
      loadTableData(paginationParam);
    }
  }, [props.shouldLoad]);

  return (
    <Wrapper>
      <TablePanel
        title={"Histories"}
        toolbar={
          <Select
            value={tableMode}
            onChange={(v) => setTableMode(v)}
            options={[
              { label: "Card Purchase History", value: "purchase" },
              { label: "Sent History", value: "grant" },
              { label: "Transfer History", value: "sent" },
            ]}
            style={{ width: "180px" }}
          />
        }
        columns={columns}
        data={tableData}
        paginationParam={paginationParam}
        onPageChange={onPageChange}
        loading={isLoading}
      />
    </Wrapper>
  );
}

const CancelBtn = (props) => {
  const dispatch = useDispatch();
  const [isUpdating, setIsUpdating] = useState(false);

  const onCancelDone = (res) => {
    SuccessNotification(res.message);
    setIsUpdating(false);
    dispatch({ type: "auth.RELOAD" });
    props.handleSearch();
  };
  const onCancelFail = () => {
    setIsUpdating(false);
  };
  const handleCancel = () => {
    callDeleteApiWithAuth(
      `hlt/${props.data.id}/cancel_grant`,
      onCancelDone,
      onCancelFail
    );
  };

  return (
    <Popconfirm onConfirm={handleCancel}>
      <Button loading={isUpdating}>Cancel</Button>
    </Popconfirm>
  );
};

const Wrapper = styled.div`
  .table-card {
    margin-bottom: 50px;
    .panelHead {
      box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2),
        0px 12px 24px -4px rgba(145, 158, 171, 0.12);
      border-radius: 16px 16px 0px 0px;
      padding: 24px 24px;
      .ant-select-selector {
        height: 40px !important;
        .ant-select-selection-item {
          position: relative;
          top: -2px;
        }
      }
    }
    table {
      th:first-child {
        border-radius: 0px !important;
      }
      th:last-child {
        border-radius: 0px !important;
      }
    }
  }

  @media (max-width: 600px) {
    .panelHead {
      flex-direction: column !important;
      h4 {
        width: 100% !important;
        margin-bottom: 15px;
      }
      .toolbar {
        width: 100% !important;
        div {
          width: 100% !important;
        }
      }
    }
  }

  @media (max-width: 1050px) {
    .ant-table {
      overflow: auto;
      .ant-table-container {
        width: 900px;
      }
    }
  }
`;

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Modal, Row, Col, FormItem, 
  Button, TextArea, Input,
  Popconfirm, SuccessNotification
} from 'components/common'
import { varValue } from 'common/var'
import { 
  callPostApiWithAuth 
} from 'utils/api'
import { asUsdPrice } from 'utils/text'

export default function SendGiftModal(props) {  
  const dispatch = useDispatch()
  const myUser = useSelector(state => state.auth.user)
  const [formData, setFormData] = useState({
    receiver_type: varValue('giftTransferHistory.receiverType', 'existing'),
    receiver_first_name: '',
    receiver_last_name: '',
    receiver_email: '',
    receiver_phone: '',
    receiver_id: '',
    amount: '',
    message: ''
  })
  const [isUpdating, setIsUpdating] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])

  const onFormInput = (field, value) => {
    setFormData({ 
      ...formData,
      [field]: value
    })
  }

  const onSubmitDone = (res) => {
    setIsUpdating(false)
    SuccessNotification(res.message)
    dispatch({ type: 'auth.RELOAD' })
    props.setModalMode(undefined)
  }
  const onSubmitFail = () => {
    setIsUpdating(false)
  }
  const handleSubmit = () => {
    // Validation
    let isValid = true
    const errorMessages0 = []
    if (isNaN(formData.amount) || 
      formData.amount<=0
    ) {
      isValid = false
      errorMessages0.push({
        type: 'amount',
        message: 'Please input amount as number'
      })
    }
    if (!formData.receiver_id) {
      isValid = false 
      errorMessages0.push({
        type: 'receiver_id',
        message: 'Please select receiver'
      })
    }
    if (!formData.message) {
      isValid = false 
      errorMessages0.push({
        type: 'message',
        message: 'Please input message'
      })
    }

    setErrorMessages(errorMessages0)
    if (!isValid) return

    setIsUpdating(true)
    callPostApiWithAuth(`my_account/gift_wallet/transfer`, formData, 
      onSubmitDone, onSubmitFail
    )
  }

  useEffect(() => {
    if (props.data) {
      setFormData({
        ...formData,
        receiver_id: props.data.id,
      })
    }
  }, [props.data])

  return (
    <Modal
      title={`Send Gift`}
      onCancel={()=>props.setModalMode(undefined)}
    >
      <Row gutter={[12, 12]}>
        <Col xs={12}>
          <FormItem label={'Receiver First Name'}
            errorMessages={errorMessages.filter(el=>el.type=='receiver_first_name')}
          >
            <strong>{props.data.first_name}</strong>
          </FormItem>
        </Col>
        <Col xs={12}>
          <FormItem label={'Receiver Last Name'}
          >
            <strong>{props.data.last_name}</strong>
          </FormItem>
        </Col>
        <Col xs={12}>
          <FormItem label={'Receiver Email'}
          >
            <strong>{props.data.email}</strong>
          </FormItem>
        </Col>
        <Col xs={24}>
          <FormItem label={'Current Gift Wallet'}
          >
            <strong>{asUsdPrice(myUser?.gift_wallet.amount)}</strong>
          </FormItem>
        </Col>
        <Col xs={24}>
          <FormItem label={'Amount'}
            errorMessages={errorMessages.filter(el=>el.type=='amount')}
          >
            <Input type='number'
              value={formData.amount}
              onChange={e=>onFormInput('amount', e.target.value)}
            />
          </FormItem>
        </Col>
        <Col xs={24}>
          <FormItem label={'Message'}
            errorMessages={errorMessages.filter(el=>el.type=='message')}
          >
            <TextArea
              value={formData.message}
              onChange={e=>onFormInput('message', e.target.value)}
            />
          </FormItem>
        </Col>
      </Row>
      <div className='action-root'>
        <Popconfirm onConfirm={handleSubmit}>
          <Button loading={isUpdating}>
            Send
          </Button>
        </Popconfirm>
      </div>
    </Modal>
  )
}

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Select,
  Button,
  InputNumber,
  Divider,
  DatePicker,
  Input,
  Popconfirm,
  WarningNotification,
  SuccessNotification,
  Alert,
} from "components/common";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { Wrapper } from "./CreateUserSubPage.styled";
import {
  countryStates,
  statesByCountry,
  regionByCountry,
} from "common/country";
import { varIs, varOptions, varValue } from "common/var";
import { callGetApiWithAuth, callPostApiWithAuth } from "utils/api";
import moment from "moment";
import {
  PlusOutlined as PlusIcon,
  MinusOutlined as MinusIcon,
} from "@ant-design/icons";
import { ReactComponent as CartIcon } from "assets/icons/ic_add_shopping_cart.svg";

export default function CreateUserSubPage() {
  const dispatch = useDispatch();
  const myUser = useSelector((state) => state.auth.user);
  const orderDetails = useSelector((state) => state.checkout.orderDetails);
  const [isEnoughCW, setIsEnoughCW] = useState(false);
  const [states, setStates] = useState([]);
  const [products, setProducts] = useState([]);
  const [userInfo, setUserInfo] = useState({
    enroller_id: "",
    type: "",
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    phone: "",
    company_name: "",
    dob: moment(new Date("1990-01-01")),
    state: "",
    country: "",
    password: "",
    passwordConfirm: "",
    vat_number: "", // only for EU users
  });
  const [shippingInfo, setShippingInfo] = useState({
    shipping_address: "",
    shipping_address_line2: "",
    shipping_city: "",
    shipping_zipcode: "",
    shipping_state: "",
    shipping_country: "",
  });
  const [selectedProduct, setSelectedProduct] = useState(undefined);
  const [quantity, setQuantity] = useState(1);
  const [showPwd, setShowPwd] = useState(false);
  const [isPurchasing, setIsPurchasing] = useState(false);
  const [errors, setErrors] = useState({
    product_error: "",
    type_error: "",
    first_name_error: "",
    last_name_error: "",
    username_error: "",
    email_error: "",
    phone_error: "",
    company_name_error: "",
    dob_error: "",
    password_error: "",
    passwordConfirm_error: "",
    shipping_address_error: "",
    shipping_address_line2_error: "",
    shipping_city_error: "",
    shipping_zipcode_error: "",
    shipping_state_error: "",
    shipping_country_error: "",
    vat_number_error: "",
  });

  const onChangeUserInfo = (field, value) => {
    setUserInfo({
      ...userInfo,
      [field]: value,
    });
  };

  const onChangeShippingInfo = (field, value) => {
    setShippingInfo({
      ...shippingInfo,
      [field]: value,
    });
  };

  const handleChangeQuantity = (action) => {
    let quantity0 = quantity;

    if (action === "plus") {
      quantity0++;
    } else if (action === "minus" && quantity0 > 1) {
      quantity0--;
    }

    setQuantity(quantity0);
  };

  const addCartValidation = () => {
    let errors0 = {
      type_error: "",
      product_error: "",
      shipping_address_error: "",
      shipping_address_line2_error: "",
      shipping_city_error: "",
      shipping_zipcode_error: "",
      shipping_state_error: "",
      shipping_country_error: "",
      vat_number_error: "",
    };
    const englishRegex =
      /^[\u4e00-\u9fa5_A-Za-z0-9\s!@#$%^&*()_+=\-`~\\\]\[{}|';:/.,?><]*$/;

    if (!selectedProduct) {
      errors0.product_error = "Please select product";
    }

    if (!userInfo.type) {
      errors0.type_error = "Please select type";
    }

    if (!shippingInfo.shipping_address.trim()) {
      errors0.shipping_address_error = "Please enter shipping address";
    } else if (
      shippingInfo.shipping_address &&
      englishRegex.exec(shippingInfo.shipping_address) == null
    ) {
      errors0.shipping_address_error = "Please enter only English";
    }

    if (
      shippingInfo.shipping_address_line2 &&
      englishRegex.exec(shippingInfo.shipping_address_line2) == null
    ) {
      errors0.shipping_address_line2_error = "Please enter only English";
    }

    if (!shippingInfo.shipping_city.trim()) {
      errors0.shipping_city_error = "Please enter city";
    } else if (
      shippingInfo.shipping_city &&
      englishRegex.exec(shippingInfo.shipping_city) == null
    ) {
      errors0.shipping_city_error = "Please enter only English";
    }

    if (!shippingInfo.shipping_zipcode.trim()) {
      errors0.shipping_zipcode_error = "Please enter zip/postal code";
    }

    if (!shippingInfo.shipping_country) {
      errors0.shipping_country_error = "Please select country";
    }

    setErrors(errors0);

    if (
      errors0.product_error ||
      errors0.type_error ||
      errors0.shipping_address_error ||
      errors0.shipping_address_line2_error ||
      errors0.shipping_city_error ||
      errors0.shipping_zipcode_error ||
      errors0.shipping_state_error ||
      errors0.shipping_country_error ||
      errors0.vat_number_error
    ) {
      return false;
    }

    return true;
  };

  const handleAddToCart = () => {
    if (!addCartValidation()) return;

    const productDetail = products.filter(
      (el) => el.id * 1 === selectedProduct * 1
    )[0];

    dispatch({
      type: "checkout.ADD_CART",
      payload: {
        product: productDetail,
        quantity: quantity,
        pay_cycle: 0,
        contained_products:
          varIs("product.isPack", productDetail.is_pack, "yes") &&
          productDetail.product_pack &&
          productDetail.product_pack.pack_contained_products &&
          productDetail.product_pack.pack_contained_products.length !== 0
            ? productDetail.product_pack.pack_contained_products.map((el) => ({
                ...el,
                quantity:
                  el.is_fixed == 1 ? el.fixed_quantity : el.min_quantity,
              }))
            : [],
      },
    });

    dispatch({
      type: "checkout.SET_SHIPPING_INFO",
      payload: {
        shippingInfo,
      },
    });
  };

  const checkoutValidate = () => {
    let errors0 = {
      product_error: "",
      type_error: "",
      first_name_error: "",
      last_name_error: "",
      username_error: "",
      email_error: "",
      phone_error: "",
      company_name_error: "",
      dob_error: "",
      password_error: "",
      passwordConfirm_error: "",
      shipping_address_error: "",
      shipping_address_line2_error: "",
      shipping_city_error: "",
      shipping_zipcode_error: "",
      shipping_state_error: "",
      shipping_country_error: "",
      vat_number_error: "",
    };

    let userNameRegex = /^[a-zA-Z0-9]+[a-zA-Z0-9_]+$/i;
    let emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const englishRegex =
      /^[\u4e00-\u9fa5_A-Za-z0-9\s!@#$%^&*()_+=\-`~\\\]\[{}|';:/.,?><]*$/;

    if (!orderDetails || (orderDetails && orderDetails.length === 0)) {
      WarningNotification("Please add products to cart");
      return false;
    }

    if (!userInfo.type) {
      errors0.type_error = "Please select type";
    }

    if (!userInfo.first_name.trim()) {
      errors0.first_name_error = "Please enter first name";
    }

    if (!userInfo.last_name.trim()) {
      errors0.last_name_error = "Please enter last name";
    }

    if (!userInfo.email.trim()) {
      errors0.email_error = "Please enter email";
    } else if (emailRegex.exec(userInfo.email) == null) {
      errors0.email_error = "Please enter valid email";
    }

    if (!userInfo.phone.trim()) {
      errors0.phone_error = "Please enter phone";
    }

    if (!userInfo.username.trim()) {
      errors0.username_error = "Please enter username";
    }

    if (!userInfo.username.trim()) {
      errors0.username_error = "Please enter username";
    } else if (userNameRegex.exec(userInfo.username) == null) {
      errors0.username_error =
        "Please do not include symbols or spaces in username.";
    } else if (userInfo.username.length > 20) {
      errors0.username_error = "Please input username less than 20 characters";
    }

    if (!userInfo.dob) {
      errors0.dob_error = "Please enter date of birth";
    } else if (moment().year() * 1 - moment(userInfo.dob).year() * 1 < 18) {
      errors0.dob_error = "You must be 18+ years old";
    }

    if (userInfo.type * 1 === 1 && !userInfo.company_name.trim()) {
      errors0.company_name_error = "Please enter company name";
    }

    let passwordRegex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/;

    if (!userInfo.password) {
      errors0.password_error = "Please enter password";
    } else if (passwordRegex.exec(userInfo.password) == null) {
      errors0.password_error =
        "Password should contain uppercase, lowercase, numeric, special characters and longer than 8 characters.";
    }

    if (!userInfo.passwordConfirm) {
      errors0.passwordConfirm_error = "Please enter confirm password";
    } else if (userInfo.password !== userInfo.passwordConfirm) {
      errors0.passwordConfirm_error = "Confirm password does not match";
    }

    if (!shippingInfo.shipping_address.trim()) {
      errors0.shipping_address_error = "Please enter shipping address";
    } else if (
      shippingInfo.shipping_address &&
      englishRegex.exec(shippingInfo.shipping_address) == null
    ) {
      errors0.shipping_address_error = "Please enter only English";
    }

    if (
      shippingInfo.shipping_address_line2 &&
      englishRegex.exec(shippingInfo.shipping_address_line2) == null
    ) {
      errors0.shipping_address_line2_error = "Please enter only English";
    }

    if (!shippingInfo.shipping_city.trim()) {
      errors0.shipping_city_error = "Please enter city";
    } else if (
      shippingInfo.shipping_city &&
      englishRegex.exec(shippingInfo.shipping_city) == null
    ) {
      errors0.shipping_city_error = "Please enter only English";
    }

    if (!shippingInfo.shipping_zipcode.trim()) {
      errors0.shipping_zipcode_error = "Please enter zip/postal code";
    }

    if (!shippingInfo.shipping_country) {
      errors0.shipping_country_error = "Please select country";
    }

    setErrors(errors0);

    if (
      errors0.product_error ||
      errors0.type_error ||
      errors0.first_name_error ||
      errors0.last_name_error ||
      errors0.username_error ||
      errors0.email_error ||
      errors0.phone_error ||
      errors0.company_name_error ||
      errors0.dob_error ||
      errors0.password_error ||
      errors0.passwordConfirm_error ||
      errors0.shipping_address_error ||
      errors0.shipping_address_line2_error ||
      errors0.shipping_city_error ||
      errors0.shipping_zipcode_error ||
      errors0.shipping_state_error ||
      errors0.shipping_country_error ||
      errors0.vat_number_error
    ) {
      return false;
    }

    return true;
  };

  const onSuccessCreatedUser = () => {
    setIsPurchasing(false);
    SuccessNotification("New user has been created successfully.");
    dispatch({
      type: "checkout.CLEAR_CART",
    });
    dispatch({
      type: "auth.RELOAD",
    });
  };

  const onFailedCreateUser = (err) => {
    setIsPurchasing(false);
  };

  const handlePurchase = () => {
    if (!checkoutValidate()) return;

    const data = {
      dist_center_id: "",
      orderFrom: varValue("order.orderFrom", "create_member"),
      user: {
        enroller_id: userInfo.enroller_id,
        type: userInfo.type,
        first_name: userInfo.first_name.trim(),
        last_name: userInfo.last_name.trim(),
        username: userInfo.username.toLowerCase().trim(),
        email: userInfo.email.toLowerCase().trim(),
        phone: userInfo.phone.trim(),
        company_name: userInfo.company_name.trim(),
        dob: moment(userInfo.dob).format("YYYY-MM-DD"),
        state: shippingInfo.shipping_state,
        country: shippingInfo.shipping_country,
        password: userInfo.password,
        vat_number: userInfo.vat_number,
      },
      shippingDetail: shippingInfo,
      orderDetails: orderDetails.map((el) => ({
        ...el,
        product_id: el.product.id,
      })),
    };

    setIsPurchasing(true);
    callPostApiWithAuth(
      "create_member",
      data,
      onSuccessCreatedUser,
      onFailedCreateUser
    );
  };

  const onSuccessGetProducts = ({ data }) => {
    setProducts(data);
  };

  const onGetFailedProducts = (err) => {
    setProducts([]);
  };

  const loadProducts = () => {
    const filter = `?country=${shippingInfo.shipping_country}&user_type=${userInfo.type}`;
    callGetApiWithAuth(
      `create_member/get_products/${filter}`,
      onSuccessGetProducts,
      onGetFailedProducts
    );
  };

  useEffect(() => {
    dispatch({
      type: "checkout.SET_SHIPPING_INFO",
      payload: {
        shippingInfo,
      },
    });
  }, [shippingInfo.shipping_state]);

  useEffect(() => {
    setProducts([]);
    setSelectedProduct(undefined);

    dispatch({
      type: "checkout.CLEAR_CART",
    });

    if (shippingInfo.shipping_country && userInfo.type) {
      loadProducts();
    }
  }, [shippingInfo.shipping_country, userInfo.type]);

  useEffect(() => {
    if (shippingInfo.shipping_country) {
      setStates(statesByCountry(shippingInfo.shipping_country));
    } else {
      setStates([]);
    }
  }, [shippingInfo.shipping_country]);

  useEffect(() => {
    if (myUser) {
      setUserInfo({
        ...userInfo,
        enroller_id: myUser.id,
      });
      if (myUser.wallet && myUser.wallet.current_balance * 1 > 0) {
        setIsEnoughCW(true);
      } else {
        setIsEnoughCW(false);
      }
    }
  }, [myUser]);

  useEffect(() => {
    dispatch({
      type: "checkout.CLEAR_CART",
    });
    dispatch({
      type: "checkout.CLEAR_SHIPPING_INFO",
    });
  }, []);

  return (
    <Wrapper>
      {!isEnoughCW && (
        <div className="wallet-alert">
          <Alert
            type="error"
            message="Your current wallet is not enough."
            showIcon
          />
        </div>
      )}
      <div className="info-container">
        <div className="title">1. User Information</div>
        <div className="user-form">
          <Row gutter={[16, 0]}>
            <Col xs={24} sm={12} xl={8}>
              <div className="user-type input-form">
                <Select
                  label="User Type*"
                  value={userInfo.type}
                  onChange={(value) => onChangeUserInfo("type", value)}
                  size="large"
                  showSearch
                  options={[
                    {
                      label: "Select User Type",
                      value: "",
                    },
                    ...varOptions("user.type"),
                  ]}
                  optionFilterProp="label"
                />
                <span className="error">
                  {errors.type_error && errors.type_error}
                </span>
              </div>
            </Col>
            <Col xs={24} sm={12} xl={8}>
              <div className="input-form">
                <Input
                  label="First Name*"
                  type="text"
                  value={userInfo.first_name}
                  onChange={(e) =>
                    onChangeUserInfo("first_name", e.target.value)
                  }
                />
                <span className="error">
                  {errors.first_name_error && errors.first_name_error}
                </span>
              </div>
            </Col>
            <Col xs={24} sm={12} xl={8}>
              <div className="input-form">
                <Input
                  label="Last Name*"
                  type="text"
                  value={userInfo.last_name}
                  onChange={(e) =>
                    onChangeUserInfo("last_name", e.target.value)
                  }
                />
                <span className="error">
                  {errors.last_name_error && errors.last_name_error}
                </span>
              </div>
            </Col>
            {regionByCountry(userInfo.country) === "Europe" && (
              <Col xs={24} sm={12} xl={8}>
                <div className="input-form">
                  <Input
                    label="VAT Number (Optional)"
                    type="text"
                    value={userInfo.vat_number}
                    onChange={(e) =>
                      onChangeUserInfo("vat_number", e.target.value)
                    }
                  />
                  <span className="error">
                    {errors.vat_number_error && errors.vat_number_error}
                  </span>
                </div>
              </Col>
            )}
            <Col xs={24} sm={12} xl={8}>
              <div className="input-form">
                <Input
                  label="Email*"
                  type="text"
                  value={userInfo.email}
                  onChange={(e) => onChangeUserInfo("email", e.target.value)}
                />
                <span className="error">
                  {errors.email_error && errors.email_error}
                </span>
              </div>
            </Col>
            <Col xs={24} sm={12} xl={8}>
              <div className="input-form">
                <Input
                  label="Phone*"
                  type="text"
                  value={userInfo.phone}
                  onChange={(e) => onChangeUserInfo("phone", e.target.value)}
                />
                <span className="error">
                  {errors.phone_error && errors.phone_error}
                </span>
              </div>
            </Col>
            <Col xs={24} sm={12} xl={8}>
              <div className="input-form">
                <Input
                  label="Username*"
                  type="text"
                  value={userInfo.username}
                  onChange={(e) => onChangeUserInfo("username", e.target.value)}
                />
                <span className="error">
                  {errors.username_error && errors.username_error}
                </span>
              </div>
            </Col>
            <Col xs={24} sm={12} xl={8}>
              <div className="input-form dob-outlined">
                <DatePicker
                  label="Date of Birth*"
                  value={userInfo.dob}
                  onChange={(value) => onChangeUserInfo("dob", value)}
                  size="large"
                />
                <span className="error">
                  {errors.dob_error && errors.dob_error}
                </span>
              </div>
            </Col>
            <Col xs={24} sm={12} xl={8}>
              <div className="input-form">
                <Input
                  label="Password*"
                  type={showPwd ? "text" : "password"}
                  value={userInfo.password}
                  onChange={(e) => onChangeUserInfo("password", e.target.value)}
                  inputSuffix={
                    <div onClick={() => setShowPwd(!showPwd)}>
                      {showPwd ? <MdVisibility /> : <MdVisibilityOff />}
                    </div>
                  }
                />
                <span className="error">
                  {errors.password_error && errors.password_error}
                </span>
              </div>
            </Col>
            <Col xs={24} sm={12} xl={8}>
              <div className="input-form">
                <Input
                  label="Confirm Password*"
                  type="password"
                  value={userInfo.passwordConfirm}
                  onChange={(e) =>
                    onChangeUserInfo("passwordConfirm", e.target.value)
                  }
                />
                <span className="error">
                  {errors.passwordConfirm_error && errors.passwordConfirm_error}
                </span>
              </div>
            </Col>
          </Row>
          {userInfo.type * 1 === 1 && (
            <Row gutter={[16, 0]}>
              <Col xs={24} sm={12} xl={8}>
                <div className="input-form">
                  <Input
                    label="Ownership Name*"
                    type="text"
                    value={userInfo.company_name}
                    onChange={(e) =>
                      onChangeUserInfo("company_name", e.target.value)
                    }
                  />
                  <span className="error">
                    {errors.company_name_error && errors.company_name_error}
                  </span>
                </div>
              </Col>
            </Row>
          )}
        </div>
        <div className="title" style={{ marginTop: 20 }}>
          2. Shipping Address
        </div>
        <div className="user-form">
          <Row gutter={[16, 0]}>
            <Col xs={24} sm={12} lg={states.length > 0 ? 8 : 12}>
              <div className="input-form">
                <Input
                  label="Address Line 1*"
                  type="text"
                  value={shippingInfo.shipping_address}
                  onChange={(e) =>
                    onChangeShippingInfo("shipping_address", e.target.value)
                  }
                />
                <span className="error">
                  {errors.shipping_address_error &&
                    errors.shipping_address_error}
                </span>
              </div>
            </Col>
            <Col xs={24} sm={12} lg={states.length > 0 ? 8 : 12}>
              <div className="input-form">
                <Input
                  label="Address Line 2"
                  type="text"
                  value={shippingInfo.shipping_address_line2}
                  onChange={(e) =>
                    onChangeShippingInfo(
                      "shipping_address_line2",
                      e.target.value
                    )
                  }
                />
                <span className="error">
                  {errors.shipping_address_line2_error &&
                    errors.shipping_address_line2_error}
                </span>
              </div>
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <div className="input-form">
                <Input
                  label="City*"
                  type="text"
                  value={shippingInfo.shipping_city}
                  onChange={(e) =>
                    onChangeShippingInfo("shipping_city", e.target.value)
                  }
                />
                <span className="error">
                  {errors.shipping_city_error && errors.shipping_city_error}
                </span>
              </div>
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <div className="input-form">
                <Input
                  label="Zip/Postal Code*"
                  type="text"
                  value={shippingInfo.shipping_zipcode}
                  onChange={(e) =>
                    onChangeShippingInfo("shipping_zipcode", e.target.value)
                  }
                />
                <span className="error">
                  {errors.shipping_zipcode_error &&
                    errors.shipping_zipcode_error}
                </span>
              </div>
            </Col>
            <Col xs={24} sm={12} lg={8}>
              <div className="input-form">
                <Select
                  label="Country*"
                  value={shippingInfo.shipping_country}
                  onChange={(value) =>
                    onChangeShippingInfo("shipping_country", value)
                  }
                  size="large"
                  showSearch
                  options={[
                    {
                      label: "Select Country",
                      value: "",
                    },
                    ...countryStates.map((el) => ({
                      label: el.name,
                      value: el.code2,
                    })),
                  ]}
                  optionFilterProp="label"
                />
                <span className="error">
                  {errors.shipping_country_error &&
                    errors.shipping_country_error}
                </span>
              </div>
            </Col>
            {states.length > 0 ? (
              <Col xs={24} sm={12} lg={8}>
                <div className="input-form">
                  <Select
                    label="State/Province*"
                    value={shippingInfo.shipping_state}
                    options={[
                      {
                        value: "",
                        key: "",
                        label: "Select State",
                      },
                      ...states.map((el) => ({
                        value: el.name,
                        key: el.code,
                        label: el.name,
                      })),
                    ]}
                    onChange={(value) =>
                      onChangeShippingInfo("shipping_state", value)
                    }
                    size="large"
                    showSearch
                  />
                  <span className="error">
                    {errors.shipping_state_error && errors.shipping_state_error}
                  </span>
                </div>
              </Col>
            ) : (
              ""
            )}
          </Row>
        </div>
        <div className="products-form">
          <div className="title" style={{ marginTop: 20 }}>
            3. Select Product
          </div>
          <Row gutter={[16, 0]}>
            <Col xs={24} sm={12}>
              <div className="products input-form">
                <Select
                  label="Products*"
                  value={selectedProduct}
                  onChange={(value) => setSelectedProduct(value)}
                  size="large"
                  showSearch
                  options={[
                    {
                      value: "",
                      key: "",
                      label: "Select Product",
                    },
                    ...products.map((el) => ({
                      value: el.id,
                      key: el.id,
                      label: el.title,
                    })),
                  ]}
                  optionFilterProp="label"
                />
                <span className="error">
                  {errors.product_error && errors.product_error}
                </span>
              </div>
            </Col>
            <Col xs={24} sm={12}>
              <div className="add-to-cart-section">
                <div className="qty-root">
                  <MinusIcon onClick={() => handleChangeQuantity("minus")} />
                  <Input type={"number"} value={quantity} disabled />
                  <PlusIcon
                    className={"plus-icon"}
                    onClick={() => handleChangeQuantity("plus")}
                  />
                </div>
                <Button
                  icon={<CartIcon />}
                  onClick={handleAddToCart}
                  className="add-cart-btn-1"
                >
                  Add To Cart
                </Button>
              </div>
            </Col>
          </Row>
        </div>

        {myUser && !varIs("user.status", myUser.status, "hold_temp") ? (
          <div className="p-c-1">
            <Popconfirm
              onConfirm={handlePurchase}
              okText="Yes"
              cancelText="No"
              title="Are you sure?"
              disabled={isPurchasing || !isEnoughCW}
            >
              <Button
                className="purchase-btn"
                size="large"
                disabled={isPurchasing || !isEnoughCW}
                loading={isPurchasing}
              >
                Purchase
              </Button>
            </Popconfirm>
          </div>
        ) : (
          ""
        )}
      </div>
    </Wrapper>
  );
}

/* eslint-disable react/prop-types */
import { TimePicker } from "antd";
import React from "react";
import moment from "moment";
import styled from "styled-components";

const { RangePicker } = TimePicker;

const StartEndTimePicker = ({
  startTime,
  endTime,
  format,
  height,
  width,
  ...props
}) => {
  return (
    <Wrapper>
      <label>Select a time range</label>
      <RangePicker
        className="material-input"
        value={
          startTime && endTime
            ? [moment(startTime, format), moment(endTime, format)]
            : ""
        }
        format={format}
        style={{
          borderRadius: 8,
          width: width || "auto",
        }}
        {...props}
      />
    </Wrapper>
  );
};

export default StartEndTimePicker;

const Wrapper = styled.div`
.ant-picker-focused {
  border: 1px solid #d9d9d9;
  box-shadow: none;
}
.ant-picker:hover {
  border: 1px solid #d9d9d9;
}
  position: relative;
  label {
    position: absolute;
    z-index: 1;
    top: -9px;
    left: 14px;
    background: white;
    padding: 0px 3px;
    font-size: 12px;
    font-weight: 400;
    color: #919eab;
  }
`;

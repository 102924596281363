import React, { useState } from "react";
import styled from "styled-components";
import { Row, Col, Tabs, Card } from "components/common";
import { ReactComponent as BinaryIcon } from "assets/icons/binary-icon.svg";
import { ReactComponent as UnilevelIcon } from "assets/icons/unilevel-icon.svg";
import { ReactComponent as BinaryActiveIcon } from "assets/icons/binary-icon-active.svg";
import { ReactComponent as UnilevelActiveIcon } from "assets/icons/unilevel-icon-active.svg";
import BinaryDiagram from "./BinaryDiagram";
import BinaryTable from "./BinaryTable";
import UnilevelTable from "./UnilevelTable";

export default function BinarySection() {
  const [selectedTab, setSelecetedTab] = useState("binary");
  const [period, setPeriod] = useState('current');

  return (
    <Wrapper>
      <Row gutter={[40, 40]}>
        <Col span={24} className="responsive-binary-qualified">
          <div className="b-diagram-container">
            <BinaryDiagram 
              period={period}
              setPeriod={setPeriod}
            />
          </div>
        </Col>
        <Col span={24} className="responsive-binary-qualified">
          <div className="binary-section">
            <div className="binary-tabs">
              <Tabs
                activeKey={selectedTab}
                onChange={(e) => setSelecetedTab(e)}
              >
                <Tabs.TabPane
                  tab={
                    <span
                      className={
                        selectedTab == "binary" ? "active-tab" : "inactive-tab"
                      }
                    >
                      {selectedTab == "binary" ? (
                        <BinaryActiveIcon />
                      ) : (
                        <BinaryIcon />
                      )}
                      Binary
                    </span>
                  }
                  key="binary"
                />

                <Tabs.TabPane
                  tab={
                    <span
                      className={
                        selectedTab == "unilevel"
                          ? "active-tab"
                          : "inactive-tab"
                      }
                    >
                      {selectedTab == "unilevel" ? (
                        <UnilevelActiveIcon />
                      ) : (
                        <UnilevelIcon />
                      )}
                      Unilevel
                    </span>
                  }
                  key="unilevel"
                />
              </Tabs>
            </div>
            <div className="binary-table">
              {selectedTab === "unilevel" && 
                <UnilevelTable 
                  period={period}
                />
              }
              {selectedTab === "binary" && 
                <BinaryTable 
                  period={period}
                />
              }
            </div>
          </div>
        </Col>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2),
    0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  border-radius: 16px;
  .b-diagram-container {
    padding: 24px 24px 0px 24px;
  }
  .binary-tabs {
    background: #f4f6f8;
    border-bottom: 1px solid rgba(145, 158, 171, 0.24);
    padding: 2px 24px 0px;
    .ant-tabs-nav {
      margin-bottom: 0px;
      .ant-tabs-tab {
        padding: 12px 0 13px;
      }
    }
    .active-tab {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #212b36;
      svg {
        margin-right: 12px;
      }
    }
    .inactive-tab {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #637381;
      svg {
        margin-right: 12px;
      }
    }
    .ant-tabs-tab {
      padding: 12px 0 9px;
    }
    .ant-tabs-ink-bar {
      background: #36b37e !important;
    }
  }
  .binary-section {
    height: 100%;
    .rankTag {
      border-radius: 6px !important;
      padding: 3px 10px 3px !important;
      font-weight: 700 !important;
      font-size: 12px !important;
      min-width: 90px !important;
    }
    .content-root {
      .title {
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 22px !important;
        color: #212b36 !important;
      }
    }
    .imageContainer {
      width: 28px !important;
      height: 20px !important;
      border-radius: 4px !important;
    }
    .ant-table {
      border-radius: 16px;
      @media (max-width: 550px) {
        width: 722px;
      }
    }
    .binary-table {
      th {
        border: none !important;
        padding: 16px 24px !important;
        background: #f4f6f8 !important;
        font-weight: 600 !important;
        font-size: 13px !important;
        line-height: 24px !important;
        color: #637381 !important;
      }
      th::before {
        background: none !important;
      }
      .td-label {
        font-weight: normal;
        padding: 15px;
        font-size: 14px;
        border-bottom: 1px solid #eff3f6;
      }
      td {
        padding: 8px 24px !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 22px !important;
        color: #212b36 !important;
        border: none !important;
      }
      tr:first-child td {
        padding: 16px 24px 8px !important;
      }
      tr:last-child td {
        padding: 8px 24px 16px !important;
      }
    }
  }

  @media (max-width: 1045px) {
    .responsive-binary-qualified {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
    .binary-section {
      border-left: none;
      padding-left: 0px;
      padding-bottom: 8px;
    }
  }
  @media (max-width: 550px) {
    .binary-table {
      overflow-x: scroll;
    }
    .responsive-binary-table {
      width: 700px;
      overflow-x: scroll;
    }
  }
`;

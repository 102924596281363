import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Button,
  Input,
  AutoComplete,
  Popconfirm,
  SuccessNotification,
  message,
} from "components/common";
import { varIs } from "common/var";
import { callGetApiWithAuth, callPostApiWithAuth } from "utils/api";
import { Wrapper } from "./TransferSubPage.styled";

export default function TransferSubPage(props) {
  const dispatch = useDispatch();
  const myUser = useSelector((state) => state.auth.user);
  const [downliners, setDownliners] = useState([]);
  const [formData, setFormData] = useState({
    amount: "",
    receiver_id: "",
  });
  const [isSending, setIsSending] = useState(false);

  const onSearchDone = (res) => {
    setDownliners(res.data);
  };
  const onSearchDownline = (v) => {
    callGetApiWithAuth(`my_account/wallet/search_receiver/${v}`, onSearchDone);
  };
  const onSelectDownline = (_, option) => {
    let receiver = null;
    downliners.forEach((el) => {
      if (el.id == option.key) {
        receiver = el;
      }
    });
    setFormData({
      ...formData,
      receiver_id: receiver.id,
    });
  };

  const onSentDone = (res) => {
    setIsSending(false);
    SuccessNotification(res.message);
    dispatch({ type: "auth.RELOAD" });
    props.setSelecetedTab("Credit Log");
  };
  const onSentFail = () => {
    setIsSending(false);
  };
  const handleSend = () => {
    // Validation
    if (!formData.amount || isNaN(formData.amount)) {
      message.error("Please input amount as number");
      return;
    }
    if (formData.amount <= 0) {
      message.error("Please input amount larger than zero");
      return;
    }
    if (!formData.receiver_id) {
      message.error("Please select receiver user");
      return;
    }

    // DO
    callPostApiWithAuth(
      `my_account/wallet/transfer`,
      formData,
      onSentDone,
      onSentFail
    );
    setIsSending(true);
  };

  return (
    <Wrapper>
      <div className="transfer-container">
        <Row gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <div className="issue-input-container">
              <AutoComplete
                label="Receiver Username"
                onChange={onSearchDownline}
                options={downliners.map((el) => ({
                  key: el.id,
                  label: `${el.first_name} ${el.last_name}`,
                  value: `${el.first_name} ${el.last_name}`,
                }))}
                style={{ width: 250 }}
                onSelect={onSelectDownline}
              />
            </div>
            <div className="issue-input-comment">
              {"* Receiver should be an Affiliate."}
            </div>
          </Col>

          <Col xs={24} md={12}>
            <Input
              label="Amount"
              type="number"
              value={formData.amount}
              onChange={(e) =>
                setFormData({ ...formData, amount: e.target.value })
              }
            />
          </Col>
        </Row>
        {myUser && !varIs("user.status", myUser.status, "hold_temp") ? (
          <div className="send-btn">
            <Popconfirm onConfirm={handleSend}>
              <Button size="large" loading={isSending}>Send</Button>
            </Popconfirm>
          </div>
        ) : (
          ""
        )}
      </div>
    </Wrapper>
  );
}

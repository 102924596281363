import React, { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import {
  Row, Col,
  Select, Input, StartEndDatePicker,
  Button,
} from "components/common";
import { callGetApiWithAuth } from "utils/api";
import { ReactComponent as SearchOutlinedIcon } from "assets/icons/search-icon.svg";

export default function SearchBar(props) {
  const [formData, setFormData] = useState({
    uuid: "",
    rank_id: "",
    pay_rank_id: "",
    created_at_range: "",
    status: "",
    createdFrom: "",
    createdUntil: "",
  });
  const [ranks, setRanks] = useState([]);

  const onGetRanks = (res) => {
    setRanks(res.data);
  };

  const onFormInput = (field, value) => {
    const formData0 = {
      ...formData,
      [field]: value,
    };
    setFormData(formData0);
  };

  const onFormDate = (v) => {
    let formData0 = {
      ...formData,
      createdFrom: "",
      createdUntil: "",
      created_at_range: "",
    };
    if (v) {
      formData0 = {
        ...formData,
        createdFrom: v[0],
        createdUntil: v[1],
        created_at_range: `${moment(v[0]).format("YYYY-MM-DD")}|${moment(
          v[1]
        ).format("YYYY-MM-DD")}`,
      };
    }
    setFormData(formData0);
  };

  useEffect(() => {
    setFormData({
      ...formData,
      ...props.searchParam,
    });
  }, [props.searchParam]);

  useEffect(() => {
    callGetApiWithAuth(`common/ranks`, onGetRanks);
  }, []);

  return (
    <Wrapper>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} xl={5}>
          <div className="search_bar">
            <Input
              value={formData.uuid}
              onChange={(e) => onFormInput("uuid", e.target.value)}
              label={"Search By"}
              inputPrefix={<SearchOutlinedIcon />}
            />
          </div>
        </Col>
        <Col xs={24} sm={12} xl={4}>
          <StartEndDatePicker
            label="Start Date & End Date"
            startDate={formData.createdFrom}
            endDate={formData.createdUntil}
            onChange={(v) => onFormDate(v)}
          />
        </Col>
        <Col xs={24} sm={12} xl={4}>
          <Select
            value={formData.rank_id}
            label="Select Rank"
            onChange={(v) => onFormInput("rank_id", v)}
            options={[
              { label: "Select Rank", value: "" },
              ...ranks.map((el) => ({
                label: el.name,
                value: el.id,
              })),
            ]}
          />
        </Col>
        <Col xs={24} sm={12} xl={4}>
          <Select
            value={formData.pay_rank_id}
            label="Select Pay Rank"
            onChange={(v) => onFormInput("pay_rank_id", v)}
            options={[
              { label: "Select Pay Rank", value: "" },
              ...ranks.map((el) => ({
                label: el.name,
                value: el.id,
              })),
            ]}
          />
        </Col>
        <Col xs={24} sm={12} xl={4}>
          <Select
            value={formData.status}
            label="Select Status"
            onChange={(v) => onFormInput("status", v)}
            options={[
              { label: "Select Status", value: "" },
              { label: "Active", value: "active" },
              { label: "Inactive", value: "inactive" },
              { label: "Closed", value: "closed" },
              { label: "Purged", value: "purged" },
            ]}
          />
        </Col>
        <Col xs={24} sm={24} xl={3}>
          <Button onClick={() => props.handleSearch(formData)}>Search</Button>
        </Col>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-bottom: 24px;
  .ant-picker {
    height: 40px !important;
    width: 100% !important;
    border: 1px solid rgba(145, 158, 171, 0.32);
    border-radius: 8px;
  }
  .input-prefix {
    svg {
      width: 15px;
      height: 15px;
      margin-bottom: 5px;
    }
  }
  .ant-input {
    height: 40px !important;
  }
  .ant-select-selector {
    height: 40px !important;
    padding-top: 4px !important;
  }
  .ant-select {
    height: 40px !important;
    color: #212b36;
  }
  .ant-btn {
    width: 100%;
  }
  .search_bar {
    .ant-form-item-label {
      top: 9px !important;
      left: 44px !important;
    }
    .ant-form-item-label-active {
      transform: translateY(-19px) scale(1) !important;
    }
  }
`;

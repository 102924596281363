import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Row, Col, Modal, Progress, Spin, Button } from "components/common";
import { asVolume, asPercent } from "utils/text";
import { callGetApiWithAuth } from "utils/api";
import { ReactComponent as UnRankedIcon } from "assets/icons/dashboard/unranked.svg";
import { ReactComponent as BuilderIcon } from "assets/icons/dashboard/builder.svg";

export default function DetailModal(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [statData, setStatData] = useState({
    rank: { name: "Unranked" },
    next_rank: { name: "Director" },
    has_qv_limit: false,
    pv: 0,
    next_pv: 40,
    pv_percent: 0,
    pecv: 0,
    next_pecv: 80,
    pecv_percent: 0,
    pe: 0,
    next_pe: 0,
    pe_percent: 0,
    qv: 0,
    next_qv: 0,
    qv_percent: 0,
    total_percent: 0,
  });

  const onGetStatData = (res) => {
    setStatData(res.data);
    setIsLoaded(true);
  };
  const onFailStatData = () => {
    setIsLoaded(true);
  };

  useEffect(() => {
    setIsLoaded(false);
    callGetApiWithAuth(
      `report/rank_report/rank_stats?user_id=${props.selected.user_id}`,
      onGetStatData,
      onFailStatData
    );
  }, []);

  return (
    <Modal onCancel={() => props.setSelected(undefined)} width={390}>
      <Wrapper>
        <div className="modal-new-title">Rank Qualification</div>
        {isLoaded ? (
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <div className="unranked-container">
                <Row gutter={[16, 16]}>
                  <Col xs={12} md={12}>
                    <div className="rank-box">
                      <UnRankedIcon />
                      <div className="rank-para">Current Rank</div>
                      <div className="rank-head">{statData.rank.name}</div>
                    </div>
                  </Col>
                  <Col xs={12} md={12}>
                    <div className="rank-box">
                      <BuilderIcon />
                      <div className="rank-para">Next Rank</div>
                      <div className="rank-head">{statData.next_rank.name}</div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={24}>
              <div className="line-progress-container">
                <div className="line-progress-box">
                  <div className="line-progress-title">Personal Volume</div>
                  <div className="line-progress-value">
                    ({asVolume(statData.pv)}/{asVolume(statData.next_pv)})
                  </div>
                </div>
                <Progress
                  percent={100 * statData.pv_percent}
                  size="small"
                  strokeWidth={4}
                  strokeColor="#00B8D9"
                  showInfo={false}
                />
              </div>
            </Col>
            {statData.has_qv_limit ? (
              <Col span={24}>
                <div className="line-progress-container">
                  <div className="line-progress-box">
                    <div className="line-progress-title">
                      Personally Enrolled
                    </div>
                    <div className="line-progress-value">
                      ({statData.pe}/{statData.next_pe})
                    </div>
                  </div>
                  <Progress
                    percent={100 * statData.pe_percent}
                    size="small"
                    strokeWidth={4}
                    strokeColor="#36B37E"
                    showInfo={false}
                  />
                </div>
              </Col>
            ) : (
              ""
            )}
            <Col span={24}>
              <div className="line-progress-container">
                <div className="line-progress-box">
                  <div className="line-progress-title">
                    Personal Customer Volume
                  </div>
                  <div className="line-progress-value">
                    {" "}
                    ({asVolume(statData.pecv)}/{asVolume(statData.next_pecv)})
                  </div>
                </div>
                <Progress
                  percent={100 * statData.pecv_percent}
                  size="small"
                  strokeWidth={4}
                  strokeColor="#FFAB00"
                  showInfo={false}
                />
              </div>
            </Col>
            {statData.has_qv_limit ? (
              <Col span={24}>
                <div className="line-progress-container">
                  <div className="line-progress-box">
                    <div className="line-progress-title">
                      Qualified Rank Vol.
                    </div>
                    <div className="line-progress-value">
                      ({asVolume(statData.qv)}/{asVolume(statData.next_qv)})
                    </div>
                  </div>
                  <Progress
                    percent={100 * statData.qv_percent}
                    size="small"
                    strokeWidth={4}
                    strokeColor="#FF5630"
                    showInfo={false}
                  />
                </div>
              </Col>
            ) : (
              ""
            )}
          </Row>
        ) : (
          <div style={{ height: 300 }}>
            <Spin spinning={true} className="loading-icon" />
          </div>
        )}
        <div className="cancelBtn">
          <Button
            onClick={() => props.setSelected(undefined)}
            bgColor="white"
            color="#212B36"
          >
            Cancel
          </Button>
        </div>
      </Wrapper>
    </Modal>
  );
}

const Wrapper = styled.div`
  .modal-new-title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #212b36;
    margin-bottom: 20px;
  }
  .line-progress-container {
    .line-progress-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: -4px;
      .line-progress-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: #212b36;
      }
      .line-progress-value {
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        text-align: right;
        color: #212b36;
      }
    }
  }
  .rank-progress-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-progress-text {
      font-weight: 700;
      font-size: 27px;
      line-height: 48px;
      text-align: center;
      color: #212b36;
    }
    .ant-progress-inner {
      width: 182px !important;
      height: 182px !important;
    }
  }
  .rank-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #212b36;
    margin-bottom: 48px;
  }
  .unranked-container {
    .rank-box {
      border: 1px solid rgba(145, 158, 171, 0.24);
      border-radius: 16px;
      padding: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .rank-para {
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: #637381;
        mix-blend-mode: normal;
        opacity: 0.72;
        margin-top: 16px;
        margin-bottom: -5px;
        @media (max-width: 1724px) {
          font-size: 12px;
        }
      }
      .rank-head {
        font-weight: 700;
        font-size: 23px;
        line-height: 36px;
        color: #212b36;
      }
    }
  }
  .liquid-container > div {
    height: 240px;
  }
  .liquid-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .rank-container {
    border-left: 1px solid #eff3f6;
    height: 100%;
    padding-left: 20px;
    .rank-label {
      font-weight: normal;
      font-size: 14px;
      margin-bottom: 5px;
      color: #7f8fa3;
    }
    .rank-badge {
      font-weight: 600;
      font-size: 22px;
      color: #00b4ee;
      display: flex;
      align-items: center;
      img {
        width: 22px;
        height: 22px;
        object-fit: cover;
        margin-right: 8px;
      }
    }
  }
  .rank-progress-container {
    margin-top: 20px;
    .progress-label-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .label {
        font-weight: normal;
        font-size: 14px;
        color: #7f8fa3;
      }
      .value {
        font-weight: 600;
        font-size: 14px;
        color: #00b4ee;
      }
    }
    .rank-progress {
      .ant-progress-inner,
      .ant-progress-bg {
        border-radius: 0px;
      }
    }
  }

  .cancelBtn {
    display: flex;
    justify-content: end;
    margin-top: 24px;
    width: 100%;
  }

  @media (max-width: 1130px) {
    .responsive-rank {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
    .rank-container {
      padding-left: 0px;
      border-left: none;
    }
  }
`;

import React, { useState, useEffect } from "react";
import copy from 'copy-to-clipboard';
import {
  Button,
  Select,
  VideoModal,
  SuccessNotification,
} from "components/common";
import { getUser } from "utils/auth";
import { callPutApiWithAuth } from "utils/api";
import { Wrapper } from "./YourWebsite.styled";
import { ReactComponent as CopyIcon } from "assets/icons/copy-icon.svg";
import { ReactComponent as PlayIcon } from "assets/icons/play-icon.svg";
import LinkedinIcon from "assets/icons/linkedin-icon.svg";
import TwitterIcon from "assets/icons/twitter-icon.svg";
import FacebookIcon from "assets/icons/facebook-icon.svg";
import MailIcon from "assets/icons/email-icon.svg";

export default function YourWebsite({ data, tourVideos }) {
  const myUser = getUser();
  const [videoId, setVideoId] = useState(undefined);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const copyWebsiteLink = () => {
    SuccessNotification("Successfully copied to clipboard");
    const url = data.path==='marketplace'?
      `https://mdcmarketplace.com/${myUser.username}`
    : `${process.env.REACT_APP_CAPTURE_URL}/${data.path}/${myUser.username}`;
    copy(`${url}`)
  };

  const shareLinkedin = () => {
    const url = data.path==='marketplace'?
      `https://mdcmarketplace.com/${myUser.username}`
    : `${process.env.REACT_APP_CAPTURE_URL}/${data.path}/${myUser.username}`;
    const shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`;
    window.open(shareUrl, "_blank");
  };

  const shareTwitter = () => {
    const url = data.path==='marketplace'?
      `https://mdcmarketplace.com/${myUser.username}`
    : `${process.env.REACT_APP_CAPTURE_URL}/${data.path}/${myUser.username}`;
    const shareUrl = `https://twitter.com/intent/tweet?&url=${encodeURIComponent(url)}`;
    window.open(shareUrl, "_blank");
  };

  const shareFacebook = () => {
    const url = data.path==='marketplace'?
      `https://mdcmarketplace.com/${myUser.username}`
    : `${process.env.REACT_APP_CAPTURE_URL}/${data.path}/${myUser.username}`;
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
    window.open(shareUrl, "_blank");
  };

  const shareEmail = () => {
    const url = data.path==='marketplace'?
      `https://mdcmarketplace.com/${myUser.username}`
    : `${process.env.REACT_APP_CAPTURE_URL}/${data.path}/${myUser.username}`;
    const shareUrl = `mailto:?subject=Invitation URL&body=${url}`;
    window.open(shareUrl);
  };

  const onUpdateDone = (res) => {
    setIsUpdating(false);
    SuccessNotification(res.message);
  };
  const onUpdateFail = () => {
    setIsUpdating(false);
  };
  const handleChangeVideo = (videoId0) => {
    setIsUpdating(true);
    const body = {
      tour_video_id: videoId0,
      capture_page_id: data.id,
    };
    setVideoId(videoId0);
    callPutApiWithAuth(
      "my_website/capture_page/tour_video_setting",
      body,
      onUpdateDone,
      onUpdateFail
    );
  };

  useEffect(() => {
    if (data) {
      setVideoId(data.tour_video?.id);
    }
  }, [data]);

  return (
    <Wrapper className="white-rounded-bg">
      <div className="website-details">
        <img src={data.thumbnail} className="website-image" alt={'Thumbnail'} />
        <div className="website-title">{data.title || "Your Website Link"}</div>
        <div className="website-url">
          {data.path==='marketplace'?
            `https://mdcmarketplace.com/${myUser.username}`
          : `${process.env.REACT_APP_CAPTURE_URL}/${data.path}/${myUser.username}`}
        </div>
        <div className="tour-video">
          <Select
            value={videoId}
            label="Tour Video"
            options={[
              ...tourVideos.map((el) => ({
                value: el.id,
                key: el.id,
                label: el.title,
              })),
            ]}
            onChange={(value) => handleChangeVideo(value)}
            wrapperClassName="select-wrapper"
            className="select-box"
          />
          {videoId &&
            tourVideos.filter((el) => el.id * 1 === videoId * 1).length > 0 &&
            tourVideos.filter((el) => el.id * 1 === videoId * 1)[0].file &&
            isPlaying && (
              <VideoModal
                onCancel={() => setIsPlaying(false)}
                src={
                  tourVideos.filter((el) => el.id * 1 === videoId * 1)[0].file
                }
                width={1200}
                isVimeo
              />
            )}
        </div>
        <div className="sharable-container">
          <div className="sharable-links">
            <span className="social-icon" onClick={shareLinkedin}>
              <img src={LinkedinIcon} alt={'Linkedin'} />
            </span>
            <span className="social-icon" onClick={shareTwitter}>
              <img src={TwitterIcon} alt={'Twitter'} />
            </span>
            <span className="social-icon" onClick={shareFacebook}>
              <img src={FacebookIcon} alt={'Facebook'} />
            </span>
            <span className="social-icon" onClick={shareEmail}>
              <img src={MailIcon} alt={'Email'} />
            </span>
          </div>
        </div>
      </div>
      <div className="website-button">
        <Button icon={<PlayIcon />} onClick={() => setIsPlaying(true)}>
          Play Video
        </Button>
        <Button icon={<CopyIcon />} onClick={copyWebsiteLink}>
          Website Link
        </Button>
      </div>
    </Wrapper>
  );
}

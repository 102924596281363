import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 24px;
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2),
    0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  border-radius: 16px;
  .slider-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    @media (max-width: 550px) {
      margin-bottom: 46px;
    }
    .slider-title {
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      color: #212b36;
    }
    .type-label {
      background: rgba(145, 158, 171, 0.16);
      border-radius: 14px;
      padding: 4px 10px;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #637381;
      cursor: pointer;
      margin-left: 8px;
      display: inline-block;
      &.active {
        background: #1ea9ea;
        color: #ffffff;
      }
    }
    .slider-arrows {
      svg {
        margin-left: 22px;
        cursor: pointer;
      }
    }
  }
  .slider-box {
    margin: 0px -9px;
  }
  .slick-slider {
    .slick-prev {
      position: absolute;
      top: -49.5px;
      left: calc(100% - 57px);
      transform: rotate(180deg);
      width: 7px;
    }
    .slick-next {
      position: absolute;
      top: -40px;
      right: 12px;
      width: 7px;
    }
  }
  .slick-slide > div {
    margin: 0px 12px;
  }
  .brand-container {
    background: #f4f6f8;
    border-radius: 16px;
    padding: 8px;
    img {
      width: 100%;
      height: 80px;
      border-radius: 16px;
      object-fit: cover;
    }
    .brand-title {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #212b36;
      padding: 24px 18px 1px 18px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .brand-price {
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      color: #1ea9ea;
      padding-left: 16px;
    }
  }
  .divider {
    height: 1px;
    background: #eff3f6;
    margin: 12px 0px 20px;
  }
  .ant-select {
    width: 170px;
  }
  .ant-select-selector {
    height: 40px !important;
    .ant-select-selection-item {
      position: relative;
      top: -2px;
    }
  }
  @media (max-width: 550px) {
    .s-container {
      /*
      flex-direction: column;
      */
      .width-container {
        width: 0px !important;
        margin-bottom: 24px;
      }
    }
    .slick-list {
      padding: 0px !important;
    }
    .slider-box {
      margin: 0px -1px 0px -3px !important;
    }
    .type-label {
      margin-left: 0px;
      margin-right: 8px;
    }
    .slick-slider {
      .slick-prev {
        top: -34.5px;
        left: calc(100% - 47px);
      }
      .slick-next {
        top: -25px;
        right: 3px;
      }
    }
  }
`;

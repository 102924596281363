import React, { useEffect, useState } from "react";
import {
  Row, Col,
  VideoModal, Alert,
  Select, Spin,
  SuccessNotification, ErrorNotification,
} from "components/common";
import YourWebsite from "./yourWebsite/YourWebsite";
import { getUser } from "utils/auth";
import { Wrapper } from "./CapturePage.styled";
import { callGetApiWithAuth, callPutApiWithAuth } from "utils/api";

export default function CapturePage() {
  const myUser = getUser();
  const [capturePages, setCapturePages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [languageId, setLanguageId] = useState(1);
  const [languages, setLanguages] = useState([]);
  const [tourVideos, setTourVideos] = useState([]);
  const [videoId, setVideoId] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  const [isSetting, setIsSetting] = useState(false);

  const onGetLanguages = (res) => {
    setLanguages(res.data);
  };
  const onFailLanguages = () => {};
  const loadLanguages = () => {
    callGetApiWithAuth(`common/languages`, onGetLanguages, onFailLanguages);
  };

  const onGetSuccessTourVideos = ({ data }) => {
    setTourVideos(data);
  };

  const onGetFailedTourVideos = () => {
    ErrorNotification("There is no video.");
  };

  const loadTourVideos = () => {
    callGetApiWithAuth(
      "my_website/capture_page/tour_video_list",
      onGetSuccessTourVideos,
      onGetFailedTourVideos
    );
  };

  const onGetSuccessSetting = ({ data }) => {
    if (data && data.tour_video_id) {
      setVideoId(data.tour_video_id);
    } else {
      setVideoId("");
    }
  };

  const onGetFailedSetting = () => {};

  const loadSetting = () => {
    callGetApiWithAuth(
      "my_website/capture_page/tour_video_setting",
      onGetSuccessSetting,
      onGetFailedSetting
    );
  };

  const onSetSuccessSetting = () => {
    setIsSetting(false);
    SuccessNotification("Tour video has been set successfully.");
    loadSetting();
    loadCapturePages();
  };

  const onFailedSetSetting = (err) => {
    setIsSetting(false);
    ErrorNotification(err);
  };

  const handleChangeVideo = (videoId0) => {
    if (!videoId0) {
      ErrorNotification("Please select video");
      return;
    }
    setIsSetting(true);
    const body = {
      tour_video_id: videoId0,
      capture_page_id: null,
    };
    callPutApiWithAuth(
      "my_website/capture_page/tour_video_setting",
      body,
      onSetSuccessSetting,
      onFailedSetSetting
    );
  };

  const onGetCapturePages = (res) => {
    setIsLoading(false);
    setCapturePages(res.data);
  };
  const onFailCapturePages = () => {
    setIsLoading(false);
  };
  const loadCapturePages = () => {
    setIsLoading(true);
    setCapturePages([]);
    callGetApiWithAuth(
      `my_website/capture_page/page_list`,
      onGetCapturePages,
      onFailCapturePages
    );
  };

  useEffect(() => {
    loadLanguages();
    loadTourVideos();
    loadSetting();
    loadCapturePages();
  }, []);

  return (
    <Wrapper>
      <div className="main-container">
        <Row gutter={[24, 24]}>
          <Col xs={24}>
            <Alert
              type="info"
              showIcon
              message={
                <div
                  dangerouslySetInnerHTML={{
                    __html: `MyDailyChoice makes it easy for Affiliates to drive traffic and get credit for sales. Please write down and save your capture page URLs exactly as they are written. Sending your traffic into the wrong website could result in incorrect enrollments. For questions, please email <a style="color: #1890ff; text-decoration: none" href="mailto:support@mydailychoice.com">support@mydailychoice.com</a>.`,
                  }}
                />
              }
              style={{ alignItems: "baseline" }}
            />
          </Col>
          <Col xs={24}>
            <div className="filter-options">
              <div className="page-title">&nbsp;</div>
              <Select
                value={languageId}
                options={languages.map((el) => ({
                  label: el.title,
                  value: el.id,
                }))}
                onChange={(v) => setLanguageId(v)}
                wrapperClassName="select-wrapper"
                className="select-box"
              />
            </div>
          </Col>
          {isLoading && (
            <div className="loading-root">
              <Spin spinning={true} />
            </div>
          )}
          {capturePages
            .filter((el) => el.language_id === languageId)
            .map((el) => (
              <Col xs={24} sm={24} md={12} xl={8} xxl={6} key={el.id}>
                <YourWebsite data={el} tourVideos={tourVideos} />
              </Col>
            ))}
        </Row>
      </div>
      {videoId &&
        tourVideos.filter((el) => el.id * 1 === videoId * 1).length > 0 &&
        tourVideos.filter((el) => el.id * 1 === videoId * 1)[0].file &&
        isPlaying && (
          <VideoModal
            onCancel={() => setIsPlaying(false)}
            src={tourVideos.filter((el) => el.id * 1 === videoId * 1)[0].file}
            width={1200}
            isVimeo
          />
        )}
    </Wrapper>
  );
}

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Card } from "components/common";
import { asUsdPrice } from "utils/text";
import { ReactComponent as Hlt1Icon } from "assets/icons/hlt-1.svg";
import { ReactComponent as Hlt2Icon } from "assets/icons/hlt-2.svg";

export default function BalanceSection(props) {
  const dispatch = useDispatch();
  const myUser = useSelector((state) => state.auth.user);
  const [wallet, setWallet] = useState({
    amount: 0,
    sent_card_amount: 0,
    grant_card_amount: 0,
  });

  const handleGetBalance = () => {
    dispatch({
      type: "auth.RELOAD",
    });
  };

  useEffect(() => {
    handleGetBalance();
  }, []);

  useEffect(() => {
    if (myUser) {
      setWallet(myUser.tgift_wallet);
    }
  }, [myUser]);

  return (
    <Wrapper>
      <div className="gift-c-1">
        <div className="cardInner">
          <div>
            <h6>TOTAL CARDS SENT</h6>
            <span className={"cardAmount"}>{wallet.grant_card_amount}</span>
          </div>
          <div>
            <Hlt1Icon />
          </div>
        </div>
      </div>
      <div className="gift-c-2">
        <div className="cardInner">
          <div>
            <h6>TRAVEL CARD BALANCE</h6>
            <span className={"amount"}>{asUsdPrice(wallet.amount)}</span>
          </div>
          <div>
            <Hlt2Icon />
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .gift-c-1 {
    padding: 24px;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2),
      0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
    margin-bottom: 24px;
  }
  .gift-c-2 {
    padding: 24px;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2),
      0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 16px;
    margin-bottom: 24px;
  }
  .cardInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  h6 {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #637381;
  }
  .amount {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #36B37E;
  }
  .cardAmount {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #FF5630;
  }
`;
